import { DialogContent } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { Box, Button, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import BulkApplyCollectionForm from "../../../../components/Form/Collection/BulkApplyCollectionForm";
import CollectionFilterForm from "../../../../components/Form/Collection/CollectionFilterForm";
import CollectionTable from "../../../../components/Table/Collection/CollectionTable";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import usePaginationModel from "../../../../hooks/pagination/usePaginationModel";
import AuthContext from "../../../../store/AuthContext";
import DataContext from "../../../../store/DataContext";

const BulkApplyAction = () => {
  const [open, setOpen] = useState(false);

  const { data: invoices, onGet } = useHttpGet("/api/invoice/open");

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Bulk Apply Collections</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <BulkApplyCollectionForm invoices={invoices} onReload={onGet} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Bulk Apply</Button>
    </>
  );
}

const VerifyAction = ({ onVerify, loading }) => {
  return (
    <LoadingButton loading={loading} variant="contained" color="secondary" onClick={onVerify}>Verify</LoadingButton>
  );
}

VerifyAction.propTypes = {
  onVerify: PropTypes.func,
  loading: PropTypes.bool,
}

export default function CollectionTab() {
  const authCtx = useContext(AuthContext);
  const dataCtx = useContext(DataContext);

  const [selectionModel, setSelectionModel] = useState([]);

  const {
    paginationModel,
    sortModel,
    handlePageSizeChange,
    handlePageChange,
    handleSortModelChange,
  } = usePaginationModel([]);

  const { data, loading, onGet } = useHttpGet("/api/collection/paginated", true, paginationModel);
  const { onPost, loading: verifyLoading } = useHttpPost("/api/collection/verify");
  const { onPost: onGeneratePost, loading: generateLoading } = useHttpPost("/api/collection/generate");

  const onVerify = async e => {
    e.preventDefault();
    try {
      await onPost(selectionModel);
      await onGet();
      dataCtx.setSnackbarConfig({ open: true, message: "Collection verified!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  const onGenerate = async e => {
    e.preventDefault();
    try {
      for (let i = 0; i < selectionModel.length; i += 10) {
        // eslint-disable-next-line no-await-in-loop
        await onGeneratePost(selectionModel.slice(i, i + 10));
      }
      dataCtx.setSnackbarConfig({ open: true, message: "Receipt generated successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Collection Listing</Typography>
        <Stack direction="row" spacing={1}>
          {authCtx.userDept === "superadmin" && <LoadingButton variant="contained" onClick={onGenerate} loading={generateLoading}>Generate</LoadingButton>}
          <BulkApplyAction onReload={onGet}/>
          <VerifyAction onVerify={onVerify} loading={verifyLoading}/>
        </Stack>
      </Stack>
      <Box mb={3}>
        <CollectionFilterForm onReload={onGet} paginationModel={paginationModel} sortModel={sortModel} />
      </Box>
      <CollectionTable
        paginatedData={data}
        loading={loading} onReload={onGet}
        sortModel={sortModel}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        handleSortModelChange={handleSortModelChange}
        selectionModel={selectionModel} setSelectionModel={setSelectionModel}
      />
    </>
  );
}