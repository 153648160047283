import dayjs from "dayjs";
import React, {useContext, useState} from 'react';
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from './DataImportForm';

const ImportVehicleUsageRecordsForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);

  const { onPost, loading } = useHttpPost('/api/vehicles/import/usage');

  const columns = [
    { field: 'bookingNo', headerName: 'Booking No.', width: 150 },
    { field: 'vehicleNumber', headerName: 'Vehicle Number', width: 150 },
    { field: 'startDate', headerName: 'Start Date', width: 250 },
    { field: 'endDate', headerName: 'End Date', width: 250 },
    { field: 'rentalPrice', headerName: 'Rental Rate', width: 150 },
    { field: 'originalRentalPrice', headerName: 'Original Rental Rate', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    startDate: row?.startDate ? dayjs(row.startDate).toISOString() : null,
    endDate: row?.endDate ? dayjs(row.endDate).toISOString() : null,
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs(row.updatedAt).toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Vehicle Usage Records" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
};

export default ImportVehicleUsageRecordsForm;
