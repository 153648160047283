import {Grid, Stack, Typography} from "@mui/material";
import {getAuth} from "firebase/auth";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useContext, useEffect} from "react";
import * as Yup from "yup";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import {getOutstandingAmount} from "../../../utils/bookingAccounts";
import SignatureBoard from "../../SignatureBoard";
import {
  FcDateField,
  FcFileDropzone,
  FcSelectField,
  FcTextArea,
  FcTextField,
  FcToggleButton,
  SubmitBtnGroup
} from "../FormFields";

export default function AccountRefundVoucherForm({ booking, onReload, onClose }) {
  const method = "Create";
  const dataCtx = useContext(DataContext);

  const { data: ownersData } = useHttpGet("/api/vehicles/owner");
  const { onPost } = useHttpPost("/api/refund-voucher");

  const paymentMethods = ["Cash", "PayNow", "Bank Transfer", "NETS", "Internal Transfer", "Others"].map(p => ({
    label: p, value: p,
  }));
  const owners = ownersData?.map(owner => ({ label: owner.name, value: owner.id })) ?? [];
  const outstanding = getOutstandingAmount(booking);
  console.log("outstanding:", outstanding);

  const formik = useFormik({
    initialValues: {
      userId: getAuth().currentUser.uid,
      title: "",
      reason: "",
      amount: 0,
      refundDate: moment(),
      customerId: booking?.customerId ?? "",
      customerName: booking?.customer?.name ?? "",
      customerAddress: booking?.customer?.address ?? "",
      customerPostal: booking?.customer?.postal ?? "",
      companyId: "",
      companyName: "",
      companyAddress: "",
      companyPostal: "",
      companyUen: "",
      isDeposit: false,
      bookingNo: booking?.bookingNo ?? "",
      bookingId: booking?.id ?? "",
      paymentMethod: "",
      evidenceAttachment: null,
      customerSignature: null,
      salesSignature: null,
      remarks: "",
    },
    validationSchema: Yup.object({
      userId: Yup.string().required("Please select responsibility person!"),
      title: Yup.string().required("Please fill in the title!"),
      reason: Yup.string().required("Please fill in the reason!"),
      amount: Yup.number().required("Please fill in the amount!")
        .max(outstanding < 0 ? -outstanding : 0),
      refundDate: Yup.date().required("Please select refund date!"),
      customerId: Yup.string().required("Customer is not exists!"),
      customerName: Yup.string().required("Customer is not exists!"),
      customerAddress: Yup.string().required("Customer is not exists!"),
      customerPostal: Yup.string().required("Customer is not exists!"),
      companyId: Yup.string().required("Please select refund company!"),
      companyName: Yup.string().required("Please select refund company!"),
      companyAddress: Yup.string().required("Please select refund company!"),
      companyPostal: Yup.string().required("Please select refund company!"),
      companyUen: Yup.string().required("Please select refund company!"),
      isDeposit: Yup.boolean().required("Is this come from deposit?"),
      bookingNo: Yup.string().required("Booking is not exists!"),
      bookingId: Yup.string().required("Booking is not exists!"),
      paymentMethod: Yup.string().required("Please select payment method!"),
      evidenceAttachment: Yup.string().nullable(),
      customerSignature: Yup.string().nullable(),
      salesSignature: Yup.string().nullable(),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost(values);
        await onReload();
        onClose();
        dataCtx.setSnackbarConfig({ open: true, message: "Create Refund Voucher Successfully!", severity: "success" });
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);

  useEffect(() => {
    const company = ownersData?.find(owner => owner.id === formik.values.companyId);
    if (company) {
      formik.setFieldValue("companyName", company.name);
      formik.setFieldValue("companyAddress", company.address);
      formik.setFieldValue("companyPostal", company.postal);
      formik.setFieldValue("companyUen", company.uenNo);
    }
  }, [formik.values.companyId]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Available refund amount: {outstanding < 0 ? -outstanding : 0}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FcTextField formik={formik} name="title" label="Title"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FcTextField formik={formik} name="reason" label="Reason"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="refundDate" label="Refund Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="amount" label="Refund Amount" type="number"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="paymentMethod" label="Payment Method" items={paymentMethods}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="companyId" label="Company" items={owners}/>
          </Grid>
          <Grid item xs={12}>
            <FcFileDropzone formik={formik} name="evidenceAttachment" label="Evidence Attachment"/>
          </Grid>
          {formik.values.paymentMethod === "Cash" && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Customer Signature</Typography>
                <SignatureBoard field="customerSignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.salesSignature}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Sales Signature</Typography>
                <SignatureBoard field="salesSignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.salesSignature}/>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <FcToggleButton formik={formik} name="isDeposit" label="Refund Deposit"/>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

AccountRefundVoucherForm.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.object,
  onClose: PropTypes.func,
}