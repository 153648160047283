import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from "prop-types";
import useCloudFuncGet from '../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../cloudFunctionApiConfig';

function AppointmentAcc({ apmt }) {
  return (
    <Accordion defaultExpanded elevation={10}>
      <AccordionSummary>
        <Typography fontWeight={700}>
          {dayjs(apmt.time).format("hA")} — {dayjs(apmt.time).add(1, 'hour').format("hA")} | {apmt.vehicleNumber}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography fontSize={18}><b>Customer Name:</b> {apmt.customerName}</Typography>
        <Typography fontSize={18}><b>Customer Phone:</b> {apmt.customerPhone}</Typography>
        <Typography fontSize={18}><b>Main Issue:</b> {apmt.mainIssue}</Typography>
        <Typography fontSize={18}><b>Salesperson:</b> {apmt.salesPerson}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

AppointmentAcc.propTypes = {
  apmt: PropTypes.object,
}

export default function AppointmentListing() {
  const [date, setDate] = useState(new Date(new Date().toDateString()).getTime());

  const getSearchQuery = () => {
    return {
      lowerBoundTime: date,
      upperBoundTime: date + 86400000
    };
  }

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/workshop/appointment`, true, getSearchQuery());

  useEffect(() => {
    onGet(getSearchQuery());
  }, [date]);

  return (
    <Box sx={{ padding: { xs: 1, md: 0 } }}>
      <Typography
        variant="h4"
        gutterBottom
        mb={3}
        sx={{ textAlign: { xs: "center", md: "start" }, fontSize: { xs: 22, md: 24 } }}
      >
        Appointment Listing
      </Typography>

      <ButtonGroup variant="contained" fullWidth sx={{ marginBottom: 1 }}>
        <Button onClick={() => setDate(date - 86400000)}>Last Day</Button>
        <Button onClick={() => setDate(date + 86400000)}>Next Day</Button>
      </ButtonGroup>

      <Accordion defaultExpanded elevation={10}>
        <AccordionSummary>
          <Typography variant="h5">{new Date(date).toDateString()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            (data.data ?? [])
            .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
            .map((apmt) => <AppointmentAcc key={apmt.time} apmt={apmt} />)
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}