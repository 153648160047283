import dayjs from 'dayjs';
import { Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DataContext from '../../../store/DataContext';
import useCloudFuncDelete from '../../../hooks/firebase/cloudFunction/useCloudFuncDelete';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import AuthContext from '../../../store/AuthContext';

function AppointmentDetails({ apmt }) {
  const details = [
    { label: "Sales Person", value: apmt.salesPerson },
    { label: "Customer Name", value: apmt.customerName },
    { label: "Customer Phone", value: apmt.customerPhone },
    { label: "Vehicle Number", value: apmt.vehicleNumber },
    { label: "Main Issue", value: apmt.mainIssue },
    { label: "Time", value: dayjs(apmt.time).format("ddd, DD MMM HH:mm A") },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({ label, value }, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
    </Grid>
  );
}

AppointmentDetails.propTypes = {
  apmt: PropTypes.object,
}

export default function WorkshopAppointmentDialogItem({ apmt, onReload }) {
  const authCtx = useContext(AuthContext);
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();
  const [openDetails, setOpenDetails] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const { onDelete } = useCloudFuncDelete(`${cloudFunctionPath}/workshop/appointment/${apmt.id}`);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const deleteOnClick = async () => {
    try {
      await onDelete();
      onOpenSnackbar("Appointment Deleted Successfully")
      onReload();
    } catch (e) {
      console.log(e);
      onOpenSnackbar(JSON.parse(e.message).message, "error");
    }
  }

  const openDialog = (fn) => () => fn(true);
  const closeDialog = (fn) => () => fn(false);

  return (
    <>
      <Dialog open={openWarning} onClose={closeDialog(setOpenWarning)}>
        <DialogTitle>
          <Typography variant="h5">Confirmation</Typography>
        </DialogTitle>
        <DialogContent>This action is irreversible</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog(setOpenWarning)}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={deleteOnClick}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDetails} onClose={closeDialog(setOpenDetails)} fullWidth maxWidth="lg">
        <DialogTitle>
          <Typography variant="h5">Appointment Details</Typography>
        </DialogTitle>
        <DialogContent>
          <AppointmentDetails apmt={apmt} />
        </DialogContent>
        <DialogActions>
          {authCtx.name === apmt.salesPerson || authCtx.userDept === "operation" || authCtx.userDept === "superadmin" ? (
            <>
              <Button variant="contained" onClick={() => navigate(`./edit/${apmt.id}`)}>
                Edit
              </Button>
              <Button variant="contained" color="error" onClick={openDialog(setOpenWarning)}>
                Delete
              </Button>
            </>
          ) : (
            <Typography fontStyle="italic">
              Contact this sales person or operation to edit or delete
            </Typography>
          )}
        </DialogActions>
      </Dialog>

      <Typography onClick={openDialog(setOpenDetails)}>
        {apmt.vehicleNumber} {apmt.salesPerson}
      </Typography>
    </>
  );
}

WorkshopAppointmentDialogItem.propTypes = {
  apmt: PropTypes.object,
  onReload: PropTypes.func,
}