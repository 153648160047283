import dayjs from "dayjs";
import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportAgreementForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);
  const { onPost, loading } = useHttpPost("/api/agreement/import");

  const columns = [
    { field: "agreementType", headerName: "Agreement Type", width: 150 },
    { field: "agreementState", headerName: "Agreement State", width: 150 },
    { field: "agreementNo", headerName: "Agreement No", width: 150 },
    { field: "userId", headerName: "User", width: 150 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "rentalUsage", headerName: "Rental Usage", width: 150 },
    { field: "bookingNo", headerName: "Booking No", width: 150 },
    { field: "customerType", headerName: "Customer Type", width: 150 },
    { field: "companyName", headerName: "Company Name", width: 150 },
    { field: "companyAddress", headerName: "Company Address", width: 150 },
    { field: "companyPostal", headerName: "Company Postal", width: 150 },
    { field: "companyEmail", headerName: "Company Email", width: 150 },
    { field: "companyIdentity", headerName: "Company Identity", width: 150 },
    { field: "customerName", headerName: "Customer Name", width: 150 },
    { field: "customerAddress", headerName: "Customer Address", width: 150 },
    { field: "customerPostal", headerName: "Customer Postal", width: 150 },
    { field: "customerEmail", headerName: "Customer Email", width: 150 },
    { field: "customerIdentity", headerName: "Customer Identity", width: 150 },
    { field: "customerBirthDate", headerName: "Customer Birth Date", width: 150 },
    { field: "customerDrivingLicenseEffectiveDate", headerName: "Customer DL Effective Date", width: 150 },
    { field: "vehicleCompanyName", headerName: "Vehicle Company Name", width: 150 },
    { field: "vehicleCompanyUen", headerName: "Vehicle Company UEN", width: 150 },
    { field: "vehicleCompanyBank", headerName: "Vehicle Company Bank", width: 150 },
    { field: "vehicleCompanyBankAccount", headerName: "Vehicle Company Bank Account", width: 150 },
    { field: "vehicleCompanyAddress", headerName: "Vehicle Company Address", width: 150 },
    { field: "vehicleCompanyPostal", headerName: "Vehicle Company Postal", width: 150 },
    { field: "vehicleCompanySignature", headerName: "Vehicle Company Signature", width: 150 },
    { field: "vehicleCompanyRegisterNo", headerName: "Vehicle Company Register No.", width: 150 },
    { field: "vehicleNumber", headerName: "Vehicle No.", width: 150 },
    { field: "vehicleColor", headerName: "Vehicle Color", width: 150 },
    { field: "vehicleModelName", headerName: "Vehicle Model Name", width: 150 },
    { field: "vehiclePetrol", headerName: "Vehicle Petrol", width: 150 },
    { field: "vehicleMakeName", headerName: "Vehicle Make Name", width: 150 },
    { field: "startDateTime", headerName: "Start Date Time", width: 250 },
    { field: "endDateTime", headerName: "End Date Time", width: 250 },
    { field: "deposit", headerName: "Deposit", width: 150 },
    { field: "rentalPrice", headerName: "Rental Price", width: 150 },
    { field: "cdwPrice", headerName: "CDW Price", width: 150 },
    { field: "totalUpfront", headerName: "Total Upfront", width: 150 },
    { field: "pricePeriodName", headerName: "Price Period Name", width: 150 },
    { field: "period", headerName: "Period", width: 150 },
    { field: "paymentPeriod", headerName: "Payment Period", width: 150 },
    { field: "paymentMethod", headerName: "Payment Method", width: 150 },
    { field: "attachment", headerName: "Attachment", width: 150 },
    { field: "termsAndConditionsAttachment", headerName: "T&C Attachment", width: 150 },
    { field: "companySignature", headerName: "Company Signature", width: 250 },
    { field: "customerSignature", headerName: "Customer Signature", width: 250 },
    { field: "salesSignature", headerName: "Sales Signature", width: 150 },
    { field: "vehicleConditionImage", headerName: "Vehicle Condition Image", width: 150 },
    { field: "isRefundDeposit", headerName: "Refund Deposit", width: 150 },
    { field: "reasonOfReturn", headerName: "Reason Of Return", width: 150 },
    { field: "petrol", headerName: "Petrol", width: 150 },
    { field: "currentMileage", headerName: "Current Mileage", width: 150 },
    { field: "nextServiceMileage", headerName: "Next Service Mileage", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "remarks", headerName: "Remarks", width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    startDateTime: row?.startDateTime ? dayjs(row.startDateTime).toISOString() : null,
    endDateTime: row?.endDateTime ? dayjs(row.endDateTime).toISOString() : null,
    customerBirthDate: row?.customerBirthDate ? dayjs(row.customerBirthDate).toISOString() : null,
    customerDrivingLicenseEffectiveDate: row?.customerDrivingLicenseEffectiveDate ? dayjs(row.customerDrivingLicenseEffectiveDate).toISOString() : null,
    isRefundDeposit: row?.isRefundDeposit === "TRUE",
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs(row.updatedAt).toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Agreements" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

export default ImportAgreementForm;