import PropTypes from "prop-types"
import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';

export default function LowerUpperBoundDateField({ startDate, setStartDate, endDate, setEndDate }) {
  return (
    <Grid container spacing={2} display="flex" alignItems="center" mb={2}>
      <Grid item xs={2} md="auto">
        <Typography variant="h5">From: </Typography>
      </Grid>
      <Grid item xs={10} md="auto">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(props) => <TextField fullWidth {...props} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2} md="auto">
        <Typography variant="h5">To: </Typography>
      </Grid>
      <Grid item xs={10} md="auto">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End Date"
            value={endDate}
            inputFormat="DD/MM/YYYY"
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(props) => <TextField fullWidth {...props} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

LowerUpperBoundDateField.propTypes = {
  startDate: PropTypes.object,
  setStartDate: PropTypes.func,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func
}