import dayjs from "dayjs";
import React, {useContext, useState} from 'react';
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from './DataImportForm';

const ImportVehiclesForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);

  const { onPost, loading } = useHttpPost('/api/vehicles/import');

  const columns = [
    { field: 'vehicleNumber', headerName: 'Vehicle Number', width: 150 },
    { field: 'modelName', headerName: 'Model Name', width: 150 },
    { field: 'modelPetrolType', headerName: 'Petrol Type', width: 150 },
    { field: 'vehicleStatus', headerName: 'Vehicle Status', width: 150 },
    { field: 'inspectionDueDate', headerName: 'Inspection Due Date', width: 250 },
    { field: 'manufacturingYear', headerName: 'Manufacturing Year', width: 250 },
    { field: 'registerDate', headerName: 'Register Date', width: 250 },
    { field: 'origRegisterDate', headerName: 'Original Register Date', width: 250 },
    { field: 'roadTaxDueDate', headerName: 'Road Tax Due Date', width: 250 },
    { field: 'vehicleColor', headerName: 'Vehicle Color', width: 150 },
    { field: 'vehicleShape', headerName: 'Vehicle Shape', width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    inspectionDueDate: row?.inspectionDueDate ? dayjs(row.inspectionDueDate).toISOString() : null,
    manufacturingYear: row?.manufacturingYear ? dayjs(row.manufacturingYear).toISOString() : null,
    registerDate: row?.registerDate ? dayjs(row.registerDate).toISOString() : null,
    origRegisterDate: row?.origRegisterDate ? dayjs(row.origRegisterDate).toISOString() : null,
    roadTaxDueDate: row?.roadTaxDueDate ? dayjs(row.roadTaxDueDate).toISOString() : null,
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs(row.updatedAt).toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Vehicles" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
};

export default ImportVehiclesForm;
