import {DialogContent} from "@material-ui/core";
import {Box, Button, Dialog, DialogTitle, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import LongTermPointForm from "../../../../components/Form/Sales/OTR/LongTermPointForm";
import LongTermPointTable from "../../../../components/Table/OTR/LongTermPointTable";
import PreviewLongTermPointTable from "../../../../components/Table/OTR/PreviewLongTermPointTable";
import useHttpGet from "../../../../hooks/http/useHttpGet";

const AddAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Add Missing Contract</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <LongTermPointForm onReload={onReload} onClose={() => setOpen(false)} />
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Add</Button>
    </>
  );
}

AddAction.propTypes = {
  onReload: PropTypes.func,
}

export default function LongTermPoints({ user, date }) {
  const { data: longTermPoints, onGet } = useHttpGet("/api/booking/commission/long-term", false);
  const { data: previewLongTermPoints, onGet: onPreviewGet } = useHttpGet("/api/booking/commission/long-term/preview", false);

  const onReload = () => {
    onGet({ userId: user?.uid ?? "", startDate: dayjs(date).toISOString() });
    onPreviewGet({ userId: user?.uid ?? "", startDate: dayjs(date).toISOString() });
  }

  useEffect(() => {
    onReload();
  }, [user, date]);

  return (
    <>
      <Typography variant="h4" mb={3}>Preview Contracts</Typography>
      <PreviewLongTermPointTable data={previewLongTermPoints} onReload={onReload} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" my={3}>
        <Typography variant="h4">Added Contracts</Typography>
        <AddAction onReload={onReload} />
      </Stack>
      <LongTermPointTable data={longTermPoints} onReload={onReload} />
    </>
  );
}

LongTermPoints.propTypes = {
  user: PropTypes.object,
  date: PropTypes.object,
}