import moment from "moment";
import React, {useContext, useEffect} from "react";
import * as Yup from "yup";
import {Box, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {getAuth} from "firebase/auth";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import DataContext from "../../../../store/DataContext";
import {getMomentValue} from "../../../../utils/date";
import BookingVehicleDetails from "../../../Details/Vehicle/BookingVehicleDetails";
import SignatureBoard from "../../../SignatureBoard";
import {FcDateField, FcSelectField, FcTextArea, FcTextField, FcTimeField, SubmitBtnGroup} from "../../FormFields";
import CarImageCanvas from "../CarImageCanvas";

const initialData = (booking, addendumType, rentalAgreement = null) => {
  let records = booking ? [...booking?.vehicleUsageRecords] : [];
  records = records.filter(record => record.rentalPrice === 0)
    .sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
  const record = records.length > 0 ? records[0] : null;

  const handoverAddendums = rentalAgreement?.addendums
    ?.filter(addendum => addendum.addendumType === "Handover Replacement Vehicle")
    ?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)) ?? [];
  const handoverAddendum = handoverAddendums.length > 0 ? handoverAddendums[0] : null;

  return {
    agreementId: rentalAgreement?.id,
    addendumType: addendumType,
    userId: getAuth().currentUser.uid,
    reason: "",
    bookingId: booking?.id,
    bookingNo: booking?.bookingNo ?? "",
    customerType: booking?.company?.customerType ?? booking?.customer?.customerType,
    companyId: booking?.companyId,
    companyName: booking?.company?.name,
    companyAddress: booking?.company?.address,
    companyPostal: booking?.company?.postal,
    companyPhone: booking?.company?.phone,
    companyEmail: booking?.company?.email,
    companyIdentity: booking?.company?.identity,
    customerId: booking?.customerId,
    customerName: booking?.customer?.name,
    customerAddress: booking?.customer?.address,
    customerPostal: booking?.customer?.postal,
    customerPhone: booking?.customer?.phone,
    customerEmail: booking?.customer?.email,
    customerIdentity: booking?.customer?.identity,
    customerBirthDate: booking?.customer?.birthDate,
    customerDrivingLicenseEffectiveDate: booking?.customer?.drivingLicenseEffectiveDate,
    vehicleId: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleId : record?.vehicleId,
    vehicleColor: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleColor : record?.vehicle?.vehicleColor ?? "",
    vehicleModelId: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleModelId : record?.vehicle?.modelId,
    vehicleModelName: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleModelName : record?.vehicle?.model?.name ?? "",
    vehiclePetrol: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehiclePetrol : record?.vehicle?.model?.petrolType ?? "",
    vehicleMakeId: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleMakeId : record?.vehicle?.model?.makeId,
    vehicleMakeName: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleMakeName : record?.vehicle?.model?.make?.name ?? "",
    vehicleNumber: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleNumber : record?.vehicle?.vehicleNumber ?? "",
    vehicleCompanyId: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanyId : record?.vehicle?.logcard?.vehicleOwnerId ?? "",
    vehicleCompanyName: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanyName : record?.vehicle?.logcard?.vehicleOwner?.name ?? "",
    vehicleCompanyUen: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanyUen : record?.vehicle?.logcard?.vehicleOwner?.uenNo ?? "",
    vehicleCompanyBank: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanyBank : record?.vehicle?.logcard?.vehicleOwner?.bankName ?? "",
    vehicleCompanyBankAccount: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanyBankAccount : record?.vehicle?.logcard?.vehicleOwner?.bankAccountNo ?? "",
    vehicleCompanyAddress: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanyAddress : record?.vehicle?.logcard?.vehicleOwner?.address ?? "",
    vehicleCompanyPostal: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanyPostal : record?.vehicle?.logcard?.vehicleOwner?.postal ?? "",
    vehicleCompanySignature: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanySignature : record?.vehicle?.logcard?.vehicleOwner?.signature ?? "",
    vehicleCompanyRegisterNo: addendumType === "Return Replacement Vehicle" ? handoverAddendum?.vehicleCompanyRegisterNo : record?.vehicle?.logcard?.vehicleOwner?.registerNo ?? "",
    vehicleConditions: [],
    rentalPrice: 0,
    totalUpfront: 0,
    petrol: 0,
    currentMileage: "",
    nextServiceMileage: "",
    vehicleConditionImage: null,
    pricePeriodId: null,
    startDateTime: addendumType === "Return Replacement Vehicle" && handoverAddendum ? getMomentValue(handoverAddendum.startDateTime) : getMomentValue(record?.startDate),
    time: moment().format("HH:mm"),
    endDateTime: addendumType === "Return Replacement Vehicle" && handoverAddendum ? getMomentValue(record?.endDate) : getMomentValue(record?.endDate),
    customerSignature: null,
    salesSignature: null,
    remarks: "",
  };
};

const VehicleStatusDetails = ({ formik }) => {
  const petrolOptions = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(p => ({ label: `${p}%`, value: p }));

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);
  const handleConditionCheckboxClick = id => {
    const conditions = formik.values.vehicleConditions.map(condition => {
      if (condition.vehicleConditionTypeId === id) return { ...condition, checked: !condition.checked };
      return condition;
    });
    formik.setFieldValue("vehicleConditions", conditions);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <CarImageCanvas
          objectName={formik.values.vehicleConditionImage}
          field="vehicleConditionImage" handleUploadCanvas={handleUploadCanvas}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {formik.values.vehicleConditions?.map(({vehicleConditionTypeId, name, checked}, index) => (
          <Box key={index}>
            <FormControlLabel
              label={name}
              control={<Checkbox checked={checked} onClick={() => handleConditionCheckboxClick(vehicleConditionTypeId)}/>}
            />
          </Box>
        ))}
      </Grid>
      {formik.values.addendumType === "Return Replacement Vehicle" && <Grid item xs={12} sm={6}>
        <FcDateField formik={formik} label="Return Date" name="endDateTime"/>
      </Grid>}
      <Grid item xs={12} sm={6} md={4} lg={formik.values.addendumType === "Return Replacement Vehicle" ? 6 : 3}>
        <FcTimeField formik={formik} label={formik.values.addendumType === "Return Replacement Vehicle" ? "Return Time" : "Pick up Time"} name="time"/>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={formik.values.addendumType === "Return Replacement Vehicle" ? 4 : 3}>
        <FcSelectField formik={formik} label="Petrol" name="petrol" items={petrolOptions}/>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={formik.values.addendumType === "Return Replacement Vehicle" ? 4 : 3}>
        <FcTextField formik={formik} label="Current Mileage" name="currentMileage"/>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={formik.values.addendumType === "Return Replacement Vehicle" ? 4 : 3}>
        <FcTextField formik={formik} label="Next Service Mileage" name="nextServiceMileage"/>
      </Grid>
    </Grid>
  );
}

VehicleStatusDetails.propTypes = {
  formik: PropTypes.object,
}

export default function VehicleReplacementForm({ addendumType, booking, rentalAgreement, onReload, onClose }) {
  const method = "Create";

  const dataCtx = useContext(DataContext);

  const records = booking ? [...booking?.vehicleUsageRecords?.filter(record => record.rentalPrice === 0)] : [];
  records.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
  const record = records?.length > 0 ? records[0] : null;

  const { onPost } = useHttpPost("/api/addendum");
  const { data: conditions } = useHttpGet("/api/vehicles/condition/type");

  const formik = useFormik({
    initialValues: initialData(booking, addendumType, rentalAgreement),
    validationSchema: Yup.object({
      time: Yup.string().required("Please fill in the pick up/return time!"),
      reason: Yup.string().required("Reason is required!"),
      petrol: Yup.number().required("Petrol is required!"),
      currentMileage: Yup.string().required("Current Mileage is required!"),
      nextServiceMileage: Yup.string().required("Next Service Mileage is required!"),
      vehicleConditionImage: Yup.string().required("Vehicle condition image is required!"),
      customerSignature: Yup.string().required("Customer signature is required!"),
      salesSignature: Yup.string().required("Sales signature is required!"),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (addendumType === "Handover Replacement Vehicle") {
          await onPost({
            ...values,
            startDateTime: moment(`${values.startDateTime.format("YYYY-MM-DD")}T${values.time}:00+08:00`).toISOString(),
            endDateTime: values.endDateTime.toISOString(),
          });
        } else if (addendumType === "Return Replacement Vehicle") {
          await onPost({
            ...values,
            startDateTime: values.startDateTime.toISOString(),
            endDateTime: moment(`${values.endDateTime.format("YYYY-MM-DD")}T${values.time}:00+08:00`).toISOString(),
          });
        }
        await onReload();
        onClose();
        dataCtx.setSnackbarConfig({ open: true, message: "Replacement Vehicle addendum created successfully", severity: "success" });
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);

  useEffect(() => {
    if (conditions) {
      formik.setFieldValue("vehicleConditions", conditions.map(condition => ({
        vehicleConditionTypeId: condition.id,
        name: condition.name,
        checked: false,
      })));
    }
  }, [conditions]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" mb={3}>Vehicle Details</Typography>
            <BookingVehicleDetails vehicle={record?.vehicle}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">Start Date</Typography>
            {formik.values.startDateTime.format("DD MMM YYYY")}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">End Date</Typography>
            {formik.values.endDateTime.format("DD MMM YYYY")}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Vehicle Status</Typography>
          </Grid>
          <Grid item xs={12}>
            <VehicleStatusDetails formik={formik}/>
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="reason" label="Reason"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Customer Signature</Typography>
            <SignatureBoard field="customerSignature" objectName={formik.values.customerSignature} handleUploadCanvas={handleUploadCanvas}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Sales Signature</Typography>
            <SignatureBoard field="salesSignature" objectName={formik.values.salesSignature} handleUploadCanvas={handleUploadCanvas}/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

VehicleReplacementForm.propTypes = {
  addendumType: PropTypes.string,
  booking: PropTypes.object,
  rentalAgreement: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}