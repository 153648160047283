import { Typography } from '@mui/material';
import React from 'react';
import WorkshopRequestForm from '../../../components/Form/Workshop/WorkshopRequestForm';
import BackButton from '../../../components/BackButton';

export default function NewJobRequest() {
  return (
    <>
      <BackButton />

      <Typography
        variant="h4"
        gutterBottom
        mb={3}
        sx={{ textAlign: { xs: "center", md: "start" }, fontSize: { xs: 26, md: 24 } }}
      >
        Creating Workshop Request
      </Typography>

      <WorkshopRequestForm />
    </>
  );
}