import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import {getAuth} from "firebase/auth";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import * as Yup from "yup";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import DataContext from "../../../store/DataContext";
import SignatureBoard from "../../SignatureBoard";
import {
  FcDateField,
  FcFileDropzone,
  FcSelectField,
  FcTextArea,
  FcTextField,
  SubmitBtnGroup
} from "../FormFields";
import CreditNoteItemForm from "./CreditNoteItemForm";
import CreditNoteItemTableForm from "./CreditNoteItemTableForm";
import InvoiceItemCollectionTableForm from "./InvoiceItemCollectionTableForm";

const CustomerDetails = ({ formik }) => {
  const details = [
    { label: "Customer Name", value: formik.values.customerName },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Customer Address</Typography>
        {formik.values.customerAddress}
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="h6">Customer Postal</Typography>
        {formik.values.customerPostal}
      </Grid>
    </Grid>
  );
}

CustomerDetails.propTypes = {
  formik: PropTypes.object,
}

const AddCreditNoteItemAction = ({ formik }) => {
  const [open, setOpen] = useState(false);

  const onAddItem = values => formik.setFieldValue("creditNoteItems", [...formik.values.creditNoteItems, {...values, id: formik.values.creditNoteItems.length + 1}]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Add Credit Note Item</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <CreditNoteItemForm onAdd={onAddItem} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Add</Button>
    </>
  );
}

AddCreditNoteItemAction.propTypes = {
  formik: PropTypes.object,
}

export default function AccountCollectionForm({ data, booking, isFinalized = false, onReload, onClose, type = "collection" }) {
  let method;
  if (data) method = "Update";
  else method = "Create";

  const paymentMethods = ["Cash", "PayNow", "Bank Transfer", "NETS", "Internal Transfer", "Others"].map(p => ({
    label: p, value: p,
  }));

  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/collection");
  const { onPut } = useHttpPut(`/api/collection/${data?.id}`);
  const { data: owners } = useHttpGet("/api/vehicles/owner");
  const accounts = owners?.map(owner => ({ label: owner.name, value: owner.id }));

  const initialPaymentMethod = {
    "credit-note": "Credit Note",
    "refund-deposit": "Refund Deposit",
    "collection": data?.paymentMethod ?? "PayNow",
  };

  const onRemoveItem = id => formik.setFieldValue("creditNoteItems", formik.values.creditNoteItems.filter(item => item.id !== id));
  const formik = useFormik({
    initialValues: {
      bookingNo: data?.bookingNo ?? booking?.bookingNo ?? "",
      bookingId: data?.bookingId ?? booking?.id ?? "",
      userId: getAuth().currentUser.uid,
      isFinalized: isFinalized,
      gstRate: data?.gstRate ?? 0,
      gstAmount: data?.gstAmount ?? 0,
      amount: data?.amount ?? 0,
      currencyCode: "SGD",
      paymentMethod: initialPaymentMethod[type],
      collectDate: data?.collectDate ? moment(data.collectDate) : moment(),
      customerName: data?.customerName ?? booking?.company?.name ?? booking?.customer?.name ?? "",
      customerAddress: data?.customerAddress ?? booking?.company?.address ?? booking?.customer?.address ?? "",
      customerPostal: data?.customerPostal ?? booking?.company?.postal ?? booking?.customer?.postal ?? "",
      customerId: data?.customerId ?? booking?.companyId ?? booking?.customerId,
      vehicleCompanyName: data?.vehicleCompanyName ?? booking?.vehicleOwner?.name ?? "",
      vehicleCompanyAddress: data?.vehicleCompanyAddress ?? booking?.vehicleOwner?.address ?? "",
      vehicleCompanyPostal: data?.vehicleCompanyPostal ?? booking?.vehicleOwner?.postal ?? "",
      vehicleCompanyUen: data?.vehicleCompanyUen ?? booking?.vehicleOwner?.uenNo ?? "",
      vehicleOwnerId: data?.vehicleOwnerId ?? booking?.vehicleOwnerId ?? "",
      invoiceItemCollections: data?.invoiceItemCollections ?? [],
      creditNoteItems: data?.creditNoteItems ?? [],
      customerSignature: data?.customerSignature ?? null,
      salesSignature: data?.salesSignature ?? null,
      evidenceAttachment: data?.evidenceAttachment ?? null,
      remarks: data?.remarks ?? "",
    },
    validationSchema: Yup.object({
      gstRate: Yup.number().required("GST Rate is required"),
      gstAmount: Yup.number().required("GST amount is required"),
      amount: Yup.number()
        .required("Amount is required!")
        .test("apply", "Applied amount is exceed collect amount",
          (value, context) => {
            return context.parent.invoiceItemCollections.reduce((total, cur) => total + cur.amountApplied, 0) <= value;
          },
        ),
      isFinalized: Yup.boolean().required("Please select collection will finalize or not!"),
      currencyCode: Yup.string().required("Currency code is required!"),
      paymentMethod: Yup.string().required("Payment Method is required!"),
      collectDate: Yup.date().required("Collect Date is required!"),
      invoiceItemCollections: Yup.array().of(
        Yup.object({
          amountApplied: Yup.number().required("Amount Applied is required!"),
          invoiceItemId: Yup.string().required("Invoice Item is required!"),
        })
      ),
      customerSignature: Yup.string().nullable(),
      salesSignature: Yup.string().nullable(),
      evidenceAttachment: Yup.string().nullable(),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onPost({ ...values, collectDate: values.collectDate.toISOString() });
        else await onPut({ ...values, collectDate: values.collectDate.toISOString() });
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Collection created successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const handleAutoApply = () => {
    let { amount } = formik.values;
    const items = formik.values.invoiceItemCollections;
    for (let i = 0; i < items.length; i++) {
      if (items[i].outstanding > 0) {
        items[i].amountApplied = Math.min(amount, items[i].outstanding);
        amount -= items[i].amountApplied;
      }
    }
    formik.setFieldValue("invoiceItemCollections", [...items]);
  }

  useEffect(() => {
    if (accounts) {
      const owner = owners.find(owner => owner.id === formik.values.vehicleOwnerId);
      if (owner?.isRegisterGst) {
        const currentGSTRate = dataCtx.gstRateList.find(gst => gst.dateEnd === null)?.rate ?? 0;
        formik.setFieldValue("gstRate", currentGSTRate ?? 0);
        formik.setFieldValue("gstAmount", formik.values.amount * (currentGSTRate ?? 0) / 100);
      } else {
        formik.setFieldValue("gstRate", 0);
        formik.setFieldValue("gstAmount", 0);
      }
      formik.setFieldValue("vehicleCompanyName", owner?.name);
      formik.setFieldValue("vehicleCompanyAddress", owner?.address);
      formik.setFieldValue("vehicleCompanyPostal", owner?.postal);
      formik.setFieldValue("vehicleCompanyUen", owner?.uenNo);
    }
  }, [formik.values.vehicleOwnerId, formik.values.amount]);

  useEffect(() => {
    if (booking) {
      const items = [];
      booking?.invoices?.filter(invoice => invoice.status === "Active")?.forEach(invoice => {
        invoice.items.forEach(item => {
          const invoiceItemCollection = data?.invoiceItemCollections.find(invoiceItemCollection => invoiceItemCollection.invoiceItemId === item.id);
          items.push({
            id: invoiceItemCollection?.id ?? item.id,
            invoiceNo: invoice.invoiceNo,
            issueDate: invoice.issueDate,
            invoiceItemId: item.id,
            description: item.description,
            amount: item.unitPrice * item.quantity,
            amountPaid: item.amountPaid - (invoiceItemCollection?.amountApplied ?? 0),
            outstanding: item.unitPrice * item.quantity - item.amountPaid + (invoiceItemCollection?.amountApplied ?? 0),
            amountApplied: invoiceItemCollection?.amountApplied ?? 0,
          });
        });
      });
      formik.setFieldValue("invoiceItemCollections", [...items]);
    }
  }, [data, booking]);

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);
  const handleApplyCommit = ({ id, value }) => {
    const updatedRows = formik.values.invoiceItemCollections.map(row => {
      if (row.id === id) {
        row.amountApplied = Number(value);
        return { ...row };
      }
      return row;
    });
    formik.setFieldValue("invoiceItemCollections", updatedRows);
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Customer Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomerDetails formik={formik}/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{type === "collection" ? "Collection" : "Credit"} Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="amount" label={type === "collection" ? "Collect Amount" : "Credit Amount"} type="number"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="collectDate" label={type === "collection" ? "Collect Date" : "Credit Date"}/>
          </Grid>
          {type === "collection" && (
            <>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FcSelectField formik={formik} name="paymentMethod" label="Payment Method" items={paymentMethods}/>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField
              formik={formik} name="vehicleOwnerId"
              label={type === "collection" ? "Receive Account" : "Credit Account"}
              items={accounts ?? []}
            />
          </Grid>
          {type === "credit-note" && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" mb={2}>
                <Typography variant="h6">Credit Note Items</Typography>
                <AddCreditNoteItemAction formik={formik}/>
              </Stack>
              <CreditNoteItemTableForm data={formik.values.creditNoteItems || []} onRemove={onRemoveItem}/>
            </Grid>
          )}
          {type !== "refund-deposit" && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" mb={2}>
                <Typography variant="h6">Apply Amount</Typography>
                <Button variant="contained" onClick={handleAutoApply}>Auto Apply</Button>
              </Stack>
              <InvoiceItemCollectionTableForm data={formik.values.invoiceItemCollections} handleApplyCommit={handleApplyCommit}/>
            </Grid>
          )}
          {type !== "refund-deposit" && <Grid item xs={12} sm={formik.values.paymentMethod === "Cash" ? 6 : 12} md={formik.values.paymentMethod === "Cash" ? 4 : 12}>
            <FcFileDropzone formik={formik} name="evidenceAttachment" label="Evidence Attachment"/>
          </Grid>}
          {formik.values.paymentMethod === "Cash" && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Customer Signature</Typography>
                <SignatureBoard field="customerSignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.salesSignature}/>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6">Sales Signature</Typography>
                <SignatureBoard field="salesSignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.salesSignature}/>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

AccountCollectionForm.propTypes = {
  data: PropTypes.object,
  booking: PropTypes.object,
  isFinalized: PropTypes.bool,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
  type: PropTypes.string,
}