import moment from "moment";
import { useContext, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from "formik";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import {
  FcDateField,
  FcPdfUpload,
  FcSelectField,
  FcTextArea,
  FcTextField,
  SubmitBtnGroup
} from "../FormFields";
import DataContext from '../../../store/DataContext';

export default function VehicleLogcardForm({ vehicleId, data, owners, onReload, onClose }) {
  const dataCtx = useContext(DataContext);

  const method = data ? "Update" : "Create";
  const { id } = useParams();
  if (vehicleId === undefined) {
    vehicleId = id;
  }

  const { onPost } = useHttpPost("/api/logcard");
  const { onPut } = useHttpPut(`/api/logcard/${data?.id}`);

  const types = [
    { label: "Other", value: "Other" },
    { label: "Private Hire", value: "Private Hire" },
  ];
  const coeTypes = [
    { label: "PARF", value: true },
    { label: "COE", value: false },
  ];
  const statusTypes = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onCreate = async (values) => {
    await onPost({
      ...values,
      vehicleId,
      coeExpiryDate: moment(values.coeExpiryDate).toISOString(),
      coeRenewalDate: values?.coeRenewalDate ? moment(values.coeRenewalDate).toISOString() : null,
    });
    onOpenSnackbar("Logcard created successfully!");
    await onReload();
    onClose();
  }

  const onUpdate = async (values) => {
    await onPut({
      ...values,
      id: data?.id,
      vehicleId,
      coeExpiryDate: moment(values.coeExpiryDate).toISOString(),
      coeRenewalDate: values?.coeRenewalDate ? moment(values.coeRenewalDate).toISOString() : null,
    });
    onOpenSnackbar("Logcard updated successfully!");
    await onReload();
    onClose();
  }

  const formik = useFormik({
    initialValues: {
      vehicleOwnerId: "",
      isParf: false,
      coeExpiryDate: null,
      coeRenewalDate: null,
      vehicleType: "",
      iuNumber: "",
      logcard: "",
      isActive: false,
      remarks: "",
    },
    validationSchema: Yup.object({
      vehicleOwnerId: Yup.string().required("Please select a Company"),
      isParf: Yup.boolean().required("Please select PARF / COE"),
      coeExpiryDate: Yup.date().required("Please select the COE Expiry Date").typeError("Please select the COE Expiry Date"),
      coeRenewalDate: Yup.date().nullable().typeError("Please select the COE Renewal Date correctly"),
      vehicleType: Yup.string().required("Please select Vehicle Type"),
      iuNumber: Yup.string().nullable(),
      logcard: Yup.string().required("Please upload Logcard"),
      isActive: Yup.boolean().required("Please select Logcard Active or Inactive"),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onCreate(values);
        else await onUpdate(values);
      } catch (error) {
        onOpenSnackbar(error.message, "error");
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("vehicleOwnerId", data.vehicleOwnerId);
      formik.setFieldValue("isParf", data.isParf);
      formik.setFieldValue("isActive", data.isActive);
      formik.setFieldValue("coeExpiryDate", data.coeExpiryDate);
      formik.setFieldValue("coeRenewalDate", data.coeRenewalDate);
      formik.setFieldValue("vehicleType", data.vehicleType);
      formik.setFieldValue("iuNumber", data.iuNumber);
      formik.setFieldValue("logcard", data?.logcard);
      formik.setFieldValue("remarks", data?.remarks);
    }
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={4} sx={{ marginTop: 0, marginBottom: 3 }}>
          {owners?.length > 0 && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FcSelectField
                formik={formik}
                name="vehicleOwnerId"
                label="Company"
                items={owners?.map(owner => ({label: owner.name, value: owner.id}))}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="coeExpiryDate" label="COE Expiry Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="coeRenewalDate" label="COE Renewal Date"/>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField
              formik={formik}
              name="vehicleType"
              label="Vehicle Type"
              items={types}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="iuNumber" label="IU Number"/>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="isParf" label="PARF / COE" items={coeTypes}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="isActive" label="Status" items={statusTypes}/>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcPdfUpload formik={formik} name="logcard" label="Logcard"/>
          </Grid>

          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
        </Grid>
        <SubmitBtnGroup method={method} formik={formik} onCancel={onClose}/>
      </Form>
    </FormikProvider>
  );
}

VehicleLogcardForm.propTypes = {
  vehicleId: PropTypes.string,
  data: PropTypes.object,
  owners: PropTypes.array,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}