import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const ExportJson = ({ data, label }) => {
  const buttonRef = useRef(null);
  const onDownload = async (e) => {
    e.preventDefault();

    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    buttonRef.current.href = URL.createObjectURL(blob);
    buttonRef.current.download = `${label}.json`;
    buttonRef.current.click();

    URL.revokeObjectURL(buttonRef.current.href);
  };
  return (<>
    <Button variant="contained" color="secondary" onClick={onDownload}>
      JSON Export
    </Button>
    <a ref={buttonRef} style={{ display: 'none' }} href="#">
      Download
    </a>
  </>);
};

ExportJson.propTypes = { data: PropTypes.array, label: PropTypes.string };

export default ExportJson;
