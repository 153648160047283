import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { GridToolbar } from '@mui/x-data-grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import DataContext from '../../../store/DataContext';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import { StyledDataGrid } from '../../../pages/Vehicle/styles';

export default function ViewPriceTableForm({ startDate, dayjs }) {
  const dataCtx = useContext(DataContext);
  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const [date, setDate] = useState(dayjs(startDate).startOf('month').toISOString());
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/otr/price`, true, { date });

  // prettier-ignore
  const columns = [{
    field: 'id', headerName: 'No.', width: 10, headerAlign: 'center', align: 'center'
  }, {
    field: 'make', headerName: 'Car Make', align: 'left', headerAlign: 'left', minWidth: 100
  }, {
    field: 'model', headerName: 'Car Model', align: 'left', headerAlign: 'left', minWidth: 180
  }, {
    field: 'petrolType', headerName: 'Petrol Type', align: 'center', headerAlign: 'center', minWidth: 100
  }, { field: 'purchaseType', headerName: 'Purchase Type', align: 'center', headerAlign: 'center', minWidth: 130 }, {
    field: 'price.Daily',
    headerName: 'Daily',
    align: 'center',
    headerAlign: 'center',
    minWidth: 40,
    renderCell: ({ row }) => row.price.Daily
  }, {
    field: 'price.Weekend',
    headerName: 'Weekend',
    align: 'center',
    headerAlign: 'center',
    minWidth: 100,
    renderCell: ({ row }) => row.price.Weekend
  }, {
    field: 'price.Monthly',
    headerName: 'Monthly',
    align: 'center',
    headerAlign: 'center',
    minWidth: 100,
    renderCell: ({ row }) => row.price.Monthly
  }, {
    field: 'price.Weekly',
    headerName: 'Weekly',
    align: 'center',
    headerAlign: 'center',
    minWidth: 100,
    renderCell: ({ row }) => row.price.Weekly
  }, {
    field: 'price.WeeklyAtLeast3Months',
    headerName: 'Weekly at least 3 months',
    align: 'center',
    headerAlign: 'center',
    minWidth: 200,
    renderCell: ({ row }) => row.price.WeeklyAtLeast3Months
  }];

  const DateField = ({ label, defaultView = 'month' }) => {
    const format = defaultView === 'year' ? 'YYYY' : 'MMMM YYYY';
    const [view, setView] = useState(defaultView);

    const onViewChange = (view) => {
      if (defaultView === 'year') return;
      setView(view);
    };

    return (<LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        disabled={loading}
        view={view}
        onViewChange={onViewChange}
        disableMaskedInput
        label={label}
        inputFormat={format}
        value={date}
        onChange={(newValue) => {
          setDate(dayjs(newValue).toISOString());
        }}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </LocalizationProvider>);
  };

  DateField.propTypes = {
    label: PropTypes.string, defaultView: PropTypes.string
  };

  const onDateChange = async () => {
    setLoading(true);
    setRowData([]);

    try {
      await onGet({ date });
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    onDateChange();
  }, [date]);

  useEffect(() => {
    setRowData([]);

    if (initialized) {
      if (data?.data) {
        const priceTable = data.data;

        const mappedData = [];

        Object.entries(priceTable).forEach(([make, models]) => {
          Object.entries(models).forEach(([model, purchaseTypes]) => {
            Object.entries(purchaseTypes).forEach(([purchaseType, petrolTypes]) => {
              Object.entries(petrolTypes).forEach(([petrolType, price]) => {
                mappedData.push({ make, model, purchaseType, petrolType, price });
              });
            });
          });
        });

        setRowData(mappedData);
        // onOpenSnackbar('Price Table fetch successfully!');
      } else {
        onOpenSnackbar(`No ${dayjs(date).format('MMMM YYYY')} Price Table found`, 'error');
      }
    }
    setInitialized(true);
    setLoading(false);
  }, [data]);

  return (<Stack spacing={2} py={2} k>
    <DateField label="Effective Date" />
    <StyledDataGrid rows={rowData.map((row, index) => ({ ...row, id: index + 1 }))} columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 20, 50]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    density="compact"
                    autoHeight />
  </Stack>);
}

ViewPriceTableForm.propTypes = {
  startDate: PropTypes.string, dayjs: PropTypes.func
};
