import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";

export default function BulkApplyCollectionTable({ data, loading }) {
  const columns = [
    { field: "invoiceNo", headerName: "Invoice No", align: "center", headerAlign: "center", width: 150 },
    { field: "bookingNo", headerName: "Booking No", align: "center", headerAlign: "center", width: 150 },
    { field: "rentalAgreementNo", headerName: "Agreement No", align: "center", headerAlign: "center", width: 150 },
    { field: "customerName", headerName: "Hirer", align: "center", headerAlign: "center", width: 250 },
    { field: "companyName", headerName: "Company", align: "center", headerAlign: "center", width: 150 },
    {
      field: "totalBillAmount", headerName: "Bill Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => (Math.round(row.totalBillAmount * 100) / 100).toFixed(2),
    },
    {
      field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => (Math.round(row.outstanding * 100) / 100).toFixed(2),
    },
    {
      field: "applyAmount", headerName: "Apply Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row?.applyAmount ? (Math.round(row.applyAmount * 100) / 100).toFixed(2) : "",
    },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={data ?? []}
      loading={loading}
      autoHeight
      disableSelectionOnClick
    />
  );
}

BulkApplyCollectionTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
}