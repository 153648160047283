import React, { useEffect, useState } from 'react';
import {
  Button, Container, Grid,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import {cloudFunctionPath} from "../../cloudFunctionApiConfig";
import JobDateNameAcc from '../../components/Workshop/Job/JobDateNameAcc';
import LowerUpperBoundDateField from '../../components/Workshop/LowerUpperBoundDateField';
import workshopJobJsonToExcel from '../../utils/workshopJobJsonToExcel';
import useCloudFuncGet from '../../hooks/firebase/cloudFunction/useCloudFuncGet';

export default function ViewJobAdmin() {
  const [startDate, setStartDate] = useState(dayjs(new Date().toDateString()));
  const [endDate, setEndDate] = useState(dayjs(new Date().toDateString()));

  const getSearchParams = () => {
    return {
      lowerBoundTime: startDate.unix() * 1000,
      upperBoundTime: endDate.unix() * 1000
    }
  }

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/workshop/job/admin`, true, getSearchParams());

  const onReload = async () => { await onGet(getSearchParams()); }

  useEffect(() => {
    onGet({ lowerBoundTime: startDate.unix() * 1000, upperBoundTime: endDate.unix() * 1000 });
  }, [startDate, endDate]);

  return (
    <Container maxWidth="xl">
      <Typography
        variant="h4"
        gutterBottom
        mb={3}
        sx={{ textAlign: { xs: 'center', md: 'start' }, fontSize: { xs: 30, md: 24 } }}
      >
        Viewing Jobs
      </Typography>

      <Grid container mb={2} spacing={2}>
        <Grid item>
          <LowerUpperBoundDateField
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Grid>

        <Grid item ml={2} mt={1}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => workshopJobJsonToExcel(data.data, true)}
          >Print</Button>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ marginBottom: 2 }}>Total: ${data.data?.reduce((x, y) => x + y.cost, 0).toFixed(2)}</Typography>

      <JobDateNameAcc data={data.data ?? []} showPrice hideChinese onReload={onReload} />
    </Container>
  );
}