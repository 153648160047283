import { Link } from 'react-router-dom';
import moment from "moment";
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function InsuranceDialogDetails({ row }) {
  const details = [
    { label: "Company Name", value: row?.company?.name },
    { label: "Start Date", value: moment(row.startDate).format("DD MMM YYYY") },
    { label: "End Date", value: moment(row.endDate).format("DD MMM YYYY") },
    { label: "Fleet / Individual", value: row.isFleet ? "Fleet" : "Individual" },
    { label: "Insurance Type", value: row.isZ10Z11 ? "Z10/Z11 (PHV Cars)" : "Others" },
    { label: "Min Age Coverage", value: row?.minAgeCoverage },
    { label: "Max Age Coverage", value: row?.maxAgeCoverage },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({label, value}, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      {row?.remarks && <Grid item xs={12}>
        <Typography variant="h6">Remarks</Typography>
        {row.remarks}&nbsp;
      </Grid>}
    </Grid>
  );
}

InsuranceDialogDetails.propTypes = {
  row: PropTypes.object,
}