import {Grid, TextField, Typography} from "@mui/material";
import PropTypes from "prop-types";
import useHttpGet from "../../../../hooks/http/useHttpGet";

const CustomerInfo = ({ identity }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>Customer Details</Typography>
      </Grid>
    </Grid>
  );
}

CustomerInfo.propTypes = {
  identity: PropTypes.string,
}

export default function CustomerIdentityQuestionForm({ type, identity, handleIdentityChange }) {
  const customerTitle = type === "driver" ? "Additional Driver" : "Customer";
  const title = type === "driver" ? "NRIC/FIN" : "NRIC/FIN/ACRA"

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" mb={2}>Fill in {customerTitle} {title}</Typography>
        <TextField fullWidth label={title} value={identity} onChange={handleIdentityChange}/>
      </Grid>
    </Grid>
  );
}

CustomerIdentityQuestionForm.propTypes = {
  type: PropTypes.string,
  identity: PropTypes.string,
  handleIdentityChange: PropTypes.func,
}