import { Container, Typography } from '@mui/material';
import React from 'react';
import BackButton from '../../../components/BackButton';
import WorkshopReturnJobForm from '../../../components/Form/Workshop/WorkshopReturnJobForm';

export default function NewReturnJob() {
  return (
    <Container maxWidth="xl">
      <BackButton />

      <Typography variant="h4" gutterBottom mb={3}>
        Creating New Return Job
      </Typography>
      
      <WorkshopReturnJobForm />
    </Container>
  )
}