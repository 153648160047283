import {LoadingButton} from "@material-ui/lab";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import BookingCollectionForm from "../../../../components/Form/Collection/BookingCollectionForm";
import BookingStatementOfAccount from "../../../../components/Table/Booking/BookingStatementOfAccount";
import BookingCollectionTable from "../../../../components/Table/Collection/BookingCollectionTable";
import BookingInvoiceTable from "../../../../components/Table/Invoice/BookingInvoiceTable";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import DataContext from "../../../../store/DataContext";
import {getOutstandingAmount} from "../../../../utils/bookingAccounts";
import {getDayjsValue} from "../../../../utils/date";

const StatementOfAccount = ({booking}) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const invoices = booking?.invoices ?? [];
  const collections = booking?.collections ?? [];
  const refundVouchers = booking?.refundVouchers ?? [];

  const {onPost, loading} = useHttpPost(`/api/booking/${booking?.id}/soa`);

  const generateStatementOfAccountData = () => {
    let data = invoices.map(invoice => {
      return {
        id: invoice.id,
        date: getDayjsValue(invoice.issueDate),
        transaction: "Invoice",
        refNo: invoice.invoiceNo,
        amount: invoice.totalAmount,
        payment: null,
      };
    });

    data = data.concat(collections.map(collection => {
      return {
        id: collection.id,
        date: getDayjsValue(collection.collectDate),
        transaction: "Payment Received",
        refNo: collection.receiptNo,
        amount: null,
        payment: collection.amount,
      }
    }));

    data = data.concat(refundVouchers.map(refundVoucher => {
      return {
        id: refundVoucher.id,
        date: getDayjsValue(refundVoucher.refundDate),
        transaction: "Refund",
        refNo: refundVoucher.refundNo,
        amount: refundVoucher.amount,
        payment: null,
      }
    }));

    data.sort((a, b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return 0;
    });

    let balance = 0;
    for (let i = 0; i < data.length; i++) {
      balance += data[i].amount ?? 0;
      balance -= data[i].payment ?? 0;
      data[i].balance = balance;
    }

    return data;
  }

  const onPrint = async e => {
    e.preventDefault();
    try {
      const blob = await onPost();
      window.open(URL.createObjectURL(blob), "_blank");
    } catch (error) {
      dataCtx.setSnackbarConfig({open: true, message: error.message, severity: "error"});
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Statement Of Account</DialogTitle>
        <DialogContent>
          <BookingStatementOfAccount data={generateStatementOfAccountData()}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" onClick={onPrint}>Print</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>SOA</Button>
    </>
  );
}

StatementOfAccount.propTypes = {
  booking: PropTypes.object,
}

const CreateCollectionAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Collect Money</DialogTitle>
        <DialogContent>
          <Box pt={2}>
            <BookingCollectionForm booking={booking} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Collect</Button>
    </>
  );
}

CreateCollectionAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

export default function BillingDetailsAccordion({ data, onReload }) {
  const getCollectionNotFinalized = (collections) => collections?.reduce((sum, collection) => sum + !collection.isFinalized, 0) ?? 0;

  const details = [
    { label: "Bill Amount", value: `$${data?.totalBillAmount}` },
    { label: "Collect Amount", value: `$${data?.totalReceivedAmount}` },
    { label: "Waiver Amount", value: `$${data?.totalCreditAmount}` },
    { label: "Refund Credit Amount", value: `$${data?.totalRefundCreditAmount}` },
    { label: "Outstanding", value: `$${getOutstandingAmount(data)}` },
    { label: "Deposit Amount", value: `$${data?.deposit}` },
    { label: "Refund Amount", value: `$${data?.totalRefundAmount}` },
    { label: "Pending Finalized Collections", value: getCollectionNotFinalized(data?.collections) },
  ];

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h6">Account Summary</Typography>
      </Stack>
      <Box border={1} borderRadius={2} p={2} mb={3}>
        <Grid container spacing={3}>
          {details.map(({label, value}, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="h6">{label}</Typography>
              {value}
            </Grid>
          ))}
        </Grid>
      </Box>

      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h6">Invoices Listing</Typography>
        <Stack direction="row" spacing={2}>
          <CreateCollectionAction booking={data} onReload={onReload}/>
          <StatementOfAccount booking={data}/>
        </Stack>
      </Stack>
      <BookingInvoiceTable data={data?.invoices}/>

      <Typography variant="h6" my={3}>Collection Listing</Typography>
      <BookingCollectionTable data={data?.collections} onReload={onReload}/>
    </Box>
  );
}

BillingDetailsAccordion.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
}