import {getAuth} from "firebase/auth";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Grid, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import {useContext} from "react";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import DataContext from "../../../../store/DataContext";
import {getMomentValue} from "../../../../utils/date";
import SignatureBoard from "../../../SignatureBoard";
import {FcDateField, FcTextField, SubmitBtnGroup} from "../../FormFields";

export default function ReplacementExtensionForm({ booking, rentalAgreement, onReload, onClose }) {
  const method = "Create";
  
  const dataCtx = useContext(DataContext);
  
  const { onPost } = useHttpPost("/api/addendum");

  const handoverAddendums = [...rentalAgreement?.addendums?.filter(addendum => addendum.addendumType === "Handover Replacement Vehicle")];
  const handoverAddendum = handoverAddendums.length > 0 ? handoverAddendums[0] : null;

  const formik = useFormik({
    initialValues: {
      addendumType: "Extension Replacement Vehicle",
      userId: getAuth().currentUser.uid,
      reason: "",
      startDateTime: getMomentValue(handoverAddendum?.startDateTime),
      endDateTime: getMomentValue(handoverAddendum?.endDateTime),
      bookingNo: booking?.bookingNo,
      bookingId: booking?.id,
      customerType: booking?.company?.customerType ?? booking?.customer?.customerType,
      companyName: booking?.company?.name,
      companyAddress: booking?.company?.address,
      companyPostal: booking?.company?.postal,
      companyPhone: booking?.company?.phone,
      companyEmail: booking?.company?.email,
      companyIdentity: booking?.company?.identity,
      companyId: booking?.companyId,
      customerName: booking?.customer?.name,
      customerAddress: booking?.customer?.address,
      customerPostal: booking?.customer?.postal,
      customerPhone: booking?.customer?.phone,
      customerEmail: booking?.customer?.email,
      customerIdentity: booking?.customer?.identity,
      customerBirthDate: booking?.customer?.birthDate,
      customerDrivingLicenseEffectiveDate: booking?.customer?.drivingLicenseEffectiveDate,
      customerId: booking?.customerId,
      vehicleCompanyName: booking?.vehicle?.logcard?.vehicleOwner?.name,
      vehicleCompanyUen: booking?.vehicle?.logcard?.vehicleOwner?.uenNo,
      vehicleCompanyBank: booking?.vehicle?.logcard?.vehicleOwner?.bankName,
      vehicleCompanyBankAccount: booking?.vehicle?.logcard?.vehicleOwner?.bankAccountNo,
      vehicleCompanyAddress: booking?.vehicle?.logcard?.vehicleOwner?.address,
      vehicleCompanyPostal: booking?.vehicle?.logcard?.vehicleOwner?.postal,
      vehicleCompanySignature: booking?.vehicle?.logcard?.vehicleOwner?.signature,
      vehicleCompanyRegisterNo: booking?.vehicle?.logcard?.vehicleOwner?.registerNo,
      vehicleCompanyId: booking?.vehicle?.logcard?.vehicleOwnerId,
      vehicleNumber: handoverAddendum?.vehicleNumber,
      vehicleColor: handoverAddendum?.vehicleColor,
      vehicleModelName: handoverAddendum?.vehicleModelName,
      vehiclePetrol: handoverAddendum?.vehiclePetrol,
      vehicleMakeName: handoverAddendum?.vehicleMakeName,
      vehicleId: handoverAddendum?.vehicleId,
      vehicleModelId: handoverAddendum?.vehicleModelId,
      rentalPrice: 0,
      totalUpfront: 0,
      petrol: 0,
      agreementId: rentalAgreement?.id,
      salesSignature: "",
      remarks: "",
    },
    validationSchema: Yup.object({
      endDateTime: Yup.date().required("End Date is required"),
      reason: Yup.string().required("Reason is required!"),
      salesSignature: Yup.string().required("Sales signature is required!"),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost({ ...values, startDateTime: values.startDateTime.toISOString(), endDateTime: values.endDateTime.toISOString() });
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Extension created successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDateTime" label="End Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <FcTextField formik={formik} name="reason" label="Reason"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Extend Days</Typography>
            {formik.values.endDateTime.diff(getMomentValue(handoverAddendum.endDateTime), "days")} days
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Sales Signature</Typography>
            <SignatureBoard formik={formik} field="salesSignature" objectName={formik.values.salesSiganture} handleUploadCanvas={handleUploadCanvas}/>
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="remarks" label="Remarks"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

ReplacementExtensionForm.propTypes = {
  booking: PropTypes.object,
  rentalAgreement: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}