import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@material-ui/core';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import FcTable from '../FcTable';
import useCloudFuncDelete from '../../../hooks/firebase/cloudFunction/useCloudFuncDelete';
import DataContext from '../../../store/DataContext';

function ActionCol({ row, onReload }) {
  const dataCtx = useContext(DataContext);
  const { onDelete } = useCloudFuncDelete(`${cloudFunctionPath}/workshop/task/${row.id}`);
  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const deleteOnClick = async () => {
    try {
      await onDelete();
      onOpenSnackbar("Task Deleted Successfully");
      onReload();
      setOpen(false);
    } catch (error) {
      console.log(error.message);
      onOpenSnackbar(error.message, "error");
    }
  }

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <Button variant="contained" component={Link} to={`./edit/${row.id}`}>Edit</Button>
      <Button variant="contained" color="error" onClick={openDialog}>Delete</Button>

      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{`Delete ${row.name} Task?`}</DialogTitle>
        <DialogContent>This action cannot be undone</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={deleteOnClick}
          >Delete</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func
}

export default function WorkshopTaskTable({ data, onReload, action = true }) {
  const columns = [
    {
      field: 'name', headerName: "Name",
      align: "center", headerAlign: "center",
      minWidth: 250, sortable: true,
    },
    {
      field: 'chineseName', headerName: "Chinese Name",
      align: "center", headerAlign: "center",
      minWidth: 200, sortable: true,
    },
    {
      field: 'cost', headerName: "Labour Cost",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: true,
      renderCell: ({ row }) => `$${Number(row.cost).toFixed(2)}`
    },
    {
      field: '', headerName: "Specific",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: true,
      renderCell: ({ row }) => row.hasSpecific ? "Yes" : "No"
    }
  ]

  if (action) {
    columns.push({
      field: '', headerName: "Action",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload} />
    });
  }

  return (
    <FcTable rows={data ?? []} columns={columns} />
  )
}

WorkshopTaskTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
  action: PropTypes.bool
}