import {Box, Grid, Typography} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import {defaultTimezone} from "../../../utils/date";
import CreditNoteItemTable from "../../Table/Collection/CreditNoteItemTable";

dayjs.extend(utc);
dayjs.extend(timezone);

const CollectionApplyDetails = ({ collection }) => {
  const columns = [
    { field: "invoiceNo", headerName: "Invoice No.", align: "center", headerAlign: "center", width: 150 },
    {
      field: "description", headerName: "Description", align: "left", headerAlign: "center", width: 500,
      renderCell: ({ row }) => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>
          {row.description}
        </Box>
      )
    },
    { field: "billAmount", headerName: "Bill Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "amountApplied", headerName: "Amount Applied", align: "center", headerAlign: "center", width: 150 },
  ];

  const rows = collection.invoiceItemCollections?.map(invoiceItemCollection => ({
    id: invoiceItemCollection.id,
    invoiceNo: invoiceItemCollection?.invoiceItem?.invoice?.invoiceNo,
    description: invoiceItemCollection?.invoiceItem?.description,
    billAmount: invoiceItemCollection?.invoiceItem?.unitPrice * invoiceItemCollection?.invoiceItem?.quantity,
    amountApplied: invoiceItemCollection.amountApplied,
  }));

  return <StyledDataGrid columns={columns} rows={rows ?? []} autoHeight/>
}

CollectionApplyDetails.propTypes = {
  collection: PropTypes.object,
}

export default function CollectionDetails({ collection }) {
  const details = [
    { label: "Booking No.", value: collection?.bookingNo },
    { label: collection?.paymentMethod === "Credit Note" ? "Credit No." : "Receipt No.", value: collection?.receiptNo },
    { label: "Collect Date", value: dayjs(collection?.collectDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY") },
    { label: "Customer Name", value: collection?.customerName },
    { label: "Company Name", value: collection?.vehicleCompanyName },
    { label: "Amount", value: `S$${collection?.amount}` },
    { label: "Payment Method", value: collection?.paymentMethod },
    { label: "Remarks", value: collection?.remarks, props: { xs: 12 } },
  ];

  return (
    <Grid container spacing={3}>
      {details.filter(({ value }) => value !== "")
        .map(({ label, value, props = { xs: 12, sm: 6, md: 4, lg: 3 } }, index) => (
        <Grid item key={index} {...props}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      {collection?.creditNoteItems?.length > 0 && <Grid item xs={12}>
        <Typography variant="h6" mb={3}>Credit Note  Applied</Typography>
        <CreditNoteItemTable data={collection?.creditNoteItems}/>
      </Grid>}
      <Grid item xs={12}>
        <Typography variant="h6" mb={3}>Collection Applied</Typography>
        <CollectionApplyDetails collection={collection}/>
      </Grid>
    </Grid>
  );
}

CollectionDetails.propTypes = {
  collection: PropTypes.object,
}