export const BOOKING_PERMISSIONS = {
  VIEW: 'view_booking',
  CREATE: 'create_booking',
  UPDATE: 'update_booking',
  DELETE: 'delete_booking',
  VOID: 'void_booking',
};

export const AGREEMENT_PERMISSIONS = {
  VIEW: 'view_agreement',
  CREATE: 'create_agreement',
  UPDATE: 'update_agreement',
  DELETE: 'delete_agreement',
  VOID: 'void_agreement',
}

export const setupBookingRolePermissions = (ROLES) => ({
  [ROLES.SUPER_ADMIN]: [...Object.values(BOOKING_PERMISSIONS), ...Object.values(AGREEMENT_PERMISSIONS)],
  [ROLES.MANAGER]: [
    BOOKING_PERMISSIONS.VIEW,
    AGREEMENT_PERMISSIONS.VIEW,
  ],
  [ROLES.ACCOUNT]: [
    BOOKING_PERMISSIONS.VIEW,
    BOOKING_PERMISSIONS.VOID,
    AGREEMENT_PERMISSIONS.VIEW,
    AGREEMENT_PERMISSIONS.VOID,
  ],
  [ROLES.SALES]: [
    BOOKING_PERMISSIONS.VIEW,
    BOOKING_PERMISSIONS.CREATE,
    BOOKING_PERMISSIONS.UPDATE,
    AGREEMENT_PERMISSIONS.VIEW,
    AGREEMENT_PERMISSIONS.CREATE,
    AGREEMENT_PERMISSIONS.UPDATE,
  ],
  [ROLES.OPERATION]: [
    BOOKING_PERMISSIONS.VIEW,
    AGREEMENT_PERMISSIONS.VIEW,
  ],
  [ROLES.MARKETING]: [
    BOOKING_PERMISSIONS.VIEW,
    AGREEMENT_PERMISSIONS.VIEW,
  ],
});