import {setupPermissions} from "./permissions";

export const ROLES = {
  SUPER_ADMIN: 'superadmin',
  MANAGER: 'manager',
  ACCOUNT: 'accounts',
  SALES: 'sales',
  OPERATION: 'operation',
  MARKETING: 'marketing',
};

export const permissionRequired = (authContext, permission) => {
  const { userDept } = authContext;
  return setupPermissions(ROLES)[userDept].some(userPermission => userPermission === permission);
}