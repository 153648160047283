import { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Document,
  Font,
  Image,
  Page,
  PDFDownloadLink,
  PDFViewer,
  Text,
  StyleSheet,
  View
} from '@react-pdf/renderer';
import dayjs from 'dayjs';

import FcLogo from '../assets/fc-logo.png';
import './RefundVoucherViewer.css';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

Font.register({ family: 'Cardelina', src: 'https://fonts.cdnfonts.com/s/91519/Cardelina.woff' });

export const RefundVoucherViewer = (props) => {
  RefundVoucherViewer.propTypes = {
    data: PropTypes.object
  };

  return (
    <>
      {/* <PDFViewer height={window.innerHeight * 0.7} width={window.innerWidth * 0.4}> */}
      <PDFViewer height={window.innerHeight * 0.7} width="100%">
        <Quixote data={props.data} />
      </PDFViewer>
      <PDFDownloadLink document={<Quixote data={props.data} />} fileName={`${props.data.id}.pdf`}>
        {({ blob, url, loading, error }) =>
          loading ? (
            <Button fullWidth variant="contained">
              <CircularProgress />
            </Button>
          ) : (
            <Button fullWidth variant="contained">
              Download
            </Button>
          )
        }
      </PDFDownloadLink>
    </>
  );
};

const Quixote = (props) => {
  Quixote.propTopes = {
    data: PropTypes.object
  };
  const [tableData, setTableData] = useState({
    column: ['Date', 'Booking', 'Refund Mode', 'Amount'],
    data: [
      {
        // Date: moment(props.data.refundVoucherDate).format('D MMM yyyy'),
        Date: dayjs(props.data.refundVoucherDate).format('D MMM YYYY'),
        Booking: props.data.bookingNo,
        'Refund Mode': props.data.paymentMode,
        Amount: props.data.amount
      }
    ]
  });
  const styles = StyleSheet.create({
    rowView: {
      borderTop: '1px solid #EEE',
      display: 'flex',
      flexDirection: 'row',
      padding: 4,
      textAlign: 'left'
    },
    section: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20
    }
  });

  return (
    <Document>
      <Page
        style={{
          display: 'flex',
          fontFamily: 'Helvetica',
          fontSize: 12,
          lineHeight: 1.5,
          padding: 40
        }}
      >
        <View style={styles.section}>
          <View style={{ width: '15%' }}>
            <Image src={FcLogo} cache={false} />
          </View>
          <View style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Text>Refund Voucher: {props.data.id}</Text>
            <Text>
              Date: {dayjs(props.data.refundVoucherDate).format('D MMM YYYY')}
              <br />
              {/* Due: February 1, 2015 */}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Refund Voucher</Text>
            <Text>Fresh Cars Pte Ltd</Text>
            <Text>105 Kaki Bukit Avenue 1</Text>
            <Text>#03-03 Shun Li Industrial Park</Text>
            <Text>Singapore 415987</Text>
          </View>

          <View style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Pay To</Text>
            <Text>{props.data.hirer}</Text>
          </View>
        </View>

        <View style={{ ...styles.rowView, backgroundColor: '#EEEEEE', lineHeight: 1 }}>
          {tableData.column.map((c) => (
            <Text
              key={c}
              style={{
                fontFamily: 'Helvetica-Bold',
                width: `${100 / tableData.column.length}%`
              }}
            >
              {c}
            </Text>
          ))}
        </View>
        {tableData.data.map((rowData, i) => (
          <View key={i} style={styles.rowView}>
            {tableData.column.map((c, j) => (
              <Text key={j} style={{ width: `${100 / tableData.column.length}%` }}>
                {rowData[c]}
              </Text>
            ))}
          </View>
        ))}

        <View
          style={{
            flex: 1,
            display: 'flex',
            paddingBottom: 1
          }}
        />

        <View style={styles.section}>
          <View style={{ borderTop: '1px solid black' }}>
            <Text>Salesperson: {props.data.paidBy}</Text>
          </View>

          <View style={{ borderTop: '1px solid black' }}>
            <Text>Hirer: {props.data.hirer}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={{ borderTop: '1px solid black' }}>
            <Text>Accounts</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
