import {Grid, Stack} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {useContext, useEffect} from "react";
import * as Yup from "yup";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import useCloudFuncPut from "../../../hooks/firebase/cloudFunction/useCloudFuncPut";
import useGetCollections from "../../../hooks/firebase/useGetCollections";
import useGetDocument from "../../../hooks/firebase/useGetDocument";
import DataContext from "../../../store/DataContext";
import {FcDateField, FcSelectField, FcTextField, FcToggleButton, SubmitBtnGroup} from "../FormFields";
import {FcLeaveFileDropzone} from "./LeaveApplyForm";
import { cloudFunctionPath } from "../../../cloudFunctionApiConfig";

export default function CreateLeaveForm({ leaveData = null, onClose, onReload }) {
  const dataCtx = useContext(DataContext);
  const method = leaveData ? "Update" : "Create";

  const { data: users } = useGetCollections("fcadmin/fcadmin/users");

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const { data } = useGetDocument("fcadmin/leave");
  const leaveTypes = data?.leaveType ? Object.entries(data?.leaveType) : [];
  const halfDayTypes = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
  ];

  const { data: holidayData } = useCloudFuncGet(`${cloudFunctionPath}/holiday/list`);
  const holidays = holidayData?.data ?? [];

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/leave/manager/create`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/leave/manager/edit`);

  const onCreate = async (values) => {
    try {
      const data = {
        ...values,
        dateStart: values.dateStart.format("YYYY-MM-DD"),
        dateEnd: values.dateEnd.format("YYYY-MM-DD"),
      }
      await onPost(data);
      await onReload();
      onOpenSnackbar("Leave created successfully");
      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, "error");
      console.error(error);
    }
  }

  const onUpdate = async (values) => {
    try {
      const data = {
        leaveId: leaveData.id,
        leave: {
          ...values,
          dateStart: values.dateStart.format("YYYY-MM-DD"),
          dateEnd: values.dateEnd.format("YYYY-MM-DD"),
        }
      }
      await onPut(data);
      await onReload();
      onOpenSnackbar("User updated successfully");
      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, "error");
      console.error(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      userId: "",
      dateStart: moment().startOf("day"),
      dateEnd: moment().startOf("day"),
      isHalfDay: false,
      leaveType: "",
      halfDayType: "",
      medicalFee: 0,
      mcAttachment: "",
      receiptAttachment: "",
      totalDay: 1,
      remarks: "",
    },
    validationSchema: Yup.object({
      userId: Yup.string().required("Please select a user!"),
      dateStart: Yup.date().required("Please select the start date!"),
      dateEnd: Yup.date().required("Please select the end date!"),
      isHalfDay: Yup.boolean().required("Please select the leave is the half day or not!"),
      leaveType: Yup.string().required("Please select the leave type!"),
      halfDayType: Yup.string().nullable(),
      medicalFee: Yup.number().nullable(),
      mcAttachment: Yup.string().nullable(),
      receiptAttachment: Yup.string().nullable(),
      totalDay: Yup.number().required("Please fill in total days!"),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (method === "Create") await onCreate(values);
      else await onUpdate(values);
      setSubmitting(false);
    }
  });

  const calculateTotalDays = (startDate, endDate) => {
    startDate = moment(startDate);
    endDate = moment(endDate);

    const currentDate = startDate;
    const weekends = [0, 6];
    let totalDays = 0;
    while (currentDate.isSameOrBefore(endDate)) {
      const isWeekend = weekends.includes(currentDate.day());
      let isPublicHoliday = false;
      holidays.forEach(({startDate, endDate}) => {
        startDate = moment(startDate).startOf("day");
        endDate = moment(endDate).startOf("day");
        isPublicHoliday = isPublicHoliday || (currentDate.isBetween(startDate, endDate, null, '[]'));
      });
      if (!isWeekend && !isPublicHoliday) totalDays++;
      currentDate.add(1, "day");
    }

    return totalDays;
  }

  useEffect(() => {
    const { dateStart, dateEnd, isHalfDay } = formik.values;
    if (dateStart && dateEnd) {
      const dateDiff = calculateTotalDays(dateStart, dateEnd);
      const leaves = isHalfDay ? dateDiff / 2 : dateDiff;
      formik.setFieldValue("totalDay", leaves);
    }
  }, [formik.values]);

  useEffect(() => {
    if (leaveData) {
      formik.setFieldValue("userId", moment(leaveData.userId));
      formik.setFieldValue("dateStart", moment(leaveData.dateStart));
      formik.setFieldValue("dateEnd", moment(leaveData.dateEnd));
      formik.setFieldValue("isHalfDay", leaveData.isHalfDay);
      formik.setFieldValue("leaveType", leaveData.leaveType);
      formik.setFieldValue("halfDayType", leaveData.halfDayType);
      formik.setFieldValue("medicalFee", leaveData.medicalFee);
      formik.setFieldValue("mcAttachment", leaveData.mcAttachment);
      formik.setFieldValue("receiptAttachment", leaveData.receiptAttachment);
      formik.setFieldValue("totalDay", leaveData.totalDay);
      formik.setFieldValue("remarks", leaveData?.remarks);
    }
  }, [leaveData]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Stack spacing={2} py={2}>
          <FcSelectField
            formik={formik} name="userId" label="Staff Name"
            items={users.map(user => ({ label: user.name, value: user.uid }))}
          />

          <FcDateField formik={formik} name="dateStart" label="Start Date"/>
          <FcDateField formik={formik} name="dateEnd" label="End Date"/>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <FcToggleButton formik={formik} name="isHalfDay" label="is half day?"/>
            </Grid>
            {formik.values.isHalfDay && (
              <Grid item xs={12} sm={6}>
                <FcSelectField
                  formik={formik}
                  name="halfDayType"
                  label="Half Day Type"
                  items={halfDayTypes}
                />
              </Grid>
            )}
          </Grid>

          <FcSelectField
            formik={formik} name="leaveType" label="Leave Type"
            items={leaveTypes?.map(([val, department]) => ({ label: department, value: val }))}
          />

          {formik.values.leaveType === 'mc' && (
            <FcTextField formik={formik} name="medicalFee" label="Medical Fee (SGD)" type="number"/>
          )}

          {formik.values.leaveType === 'mc' && (
            <Stack direction="row" justifyContent="space-between" spacing={3}>
              <FcLeaveFileDropzone formik={formik} name="mcAttachment" label="MC Attachment (Optional)"/>
              <FcLeaveFileDropzone formik={formik} name="receiptAttachment" label="Receipt Attachment (Optional)"/>
            </Stack>
          )}

          <FcTextField formik={formik} name="totalDay" label="Total Leaves" type="number"/>
          <FcTextField formik={formik} name="remarks" label="Remarks"/>

          <SubmitBtnGroup formik={formik} method="Submit" onCancel={onClose}/>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

CreateLeaveForm.propTypes = {
  leaveData: PropTypes.object,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
}