import {DialogContent} from "@material-ui/core";
import {LoadingButton} from "@material-ui/lab";
import {Box, Button, Dialog, DialogTitle, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import ShellCardFilterForm from "../../../components/Form/ShellCard/ShellCardFilterForm";
import ShellCardForm from "../../../components/Form/ShellCard/ShellCardForm";
import ShellCardTable from "../../../components/Table/ShellCard/ShellCardTable";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import DataContext from "../../../store/DataContext";

const OrderAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} fullWidth>
        <DialogTitle>Order Shell Card</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <ShellCardForm onClose={() => setOpen(false)} onReload={onReload}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Order</Button>
    </>
  );
}

OrderAction.propTypes = {
  onReload: PropTypes.func,
}

const ImportAction = ({ onReload }) => {
  const dataCtx = useContext(DataContext);

  const { onPost, loading } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/import`);

  const onImport = async e => {
    e.preventDefault();
    try {
      await onPost();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Import successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <LoadingButton loading={loading} variant="contained" color="secondary" onClick={onImport}>
      Import
    </LoadingButton>
  );
}

ImportAction.propTypes = {
  onReload: PropTypes.func,
}

export default function ShellCardListingTab() {
  const { data, loading, onGet } = useCloudFuncGet(`${cloudFunctionPath}/wallet`);
  const cards = data?.data;

  return (
    <>
      <ShellCardFilterForm onReload={onGet}/>

      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="h4">Shell Card Listing</Typography>
        <Stack direction="row" spacing={1}>
          <ImportAction onReload={onGet}/>
          <OrderAction onReload={onGet}/>
        </Stack>
      </Stack>

      <ShellCardTable data={cards} loading={loading} onReload={onGet}/>
    </>
  );
}