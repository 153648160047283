// material
import { Container, Stack, Typography } from '@mui/material';
// components
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { doc, getDoc, query } from 'firebase/firestore';

import FirestoreContext from '../../store/FirestoreContext';
import DataTable from '../../components/DataTable';
import { a11yProps, TabPanel } from '../../components/TabPanel';

// ----------------------------------------------------------------------

export default function FcAppMailingList() {
  const fsCtx = useContext(FirestoreContext);
  const [appUsers, setAppUsers] = useState([]);

  const tableInitialSort = {
    sorting: {
      sortModel: [{ field: 'email', sort: 'desc' }]
    }
  };

  const fetchAppUsers = useCallback(async () => {
    const q = query(doc(fsCtx.fsObject, 'fcadmin', 'mailingList'));
    const querySnapshot = await getDoc(q);
    setAppUsers(querySnapshot.data().mailingList.map((el) => ({ id: el, email: el })));
  }, [fsCtx.fsObject]);

  useEffect(() => fetchAppUsers(), [fetchAppUsers]);

  // Message Details modal logic

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
      type: 'number'
    },
    {
      field: 'appUser',
      headerName: 'User',
      width: 90,
      type: 'string',
      sortable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      type: 'string',
      editable: false
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`
    // }
  ];

  // Tabs Navigation

  const [value, setValue] = useState(0);

  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          App | Mailing List
        </Typography>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={tabHandleChange}
            aria-label="basic tabs example"
            // variant="fullWidth"
          >
            <Tab label="Mailing List" {...a11yProps(0)} />
            <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <DataTable
            dataRows={appUsers}
            dataColumns={columns}
            tableInitialSort={tableInitialSort}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography variant="h5" gutterBottom>
            Current COE Prices in App
          </Typography>
        </TabPanel>

        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </Container>
  );
}
