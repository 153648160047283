// components
import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// ----------------------------------------------------------------------

const DataTable = (props) => (
  <div style={{ display: 'flex', flexGrow: 1, width: '100%' }}>
    {props.children}
    {/* <Box sx={{ height: windowDimensions.height - 350, width: '100%' }}> */}
    {/* <div> */}
    <DataGrid
      autoHeight
      rows={props.dataRows}
      rowHeight={props.rowHeight ?? 52}
      columns={props.dataColumns}
      pageSize={10}
      rowsPerPageOptions={[10, 50, 100]}
      initialState={props.tableInitialSort}
      filterModel={props.tableFilter}
      onFilterModelChange={props.onFilterModelChange}
      // checkboxSelection
      components={{
        Toolbar: GridToolbar
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 }
        }
      }}
      // disableSelectionOnClick
    />
    {/* </Box> */}
  </div>
);

DataTable.propTypes = {
  children: PropTypes.any,
  dataRows: PropTypes.array,
  dataColumns: PropTypes.array,
  tableInitialSort: PropTypes.object,
  tableFilter: PropTypes.object,
  onFilterModelChange: PropTypes.func,
  rowHeight: PropTypes.number
};

export default DataTable;
