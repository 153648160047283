// material
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { doc, getDoc, updateDoc, query, Timestamp } from 'firebase/firestore';
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Input,
  Modal,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import moment from 'moment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { a11yProps, TabPanel } from '../../components/TabPanel';
import DataTable from '../../components/DataTable';
import AppAdminSettings from '../../components/AppAdminSettings';

import FirestoreContext from '../../store/FirestoreContext';
import { fTitleCase } from '../../utils/formatTitleCase';
import errorHandler from '../../utils/errorHandler';

let csvCaltexPrices;
let csvEssoPrices;
let csvShellPrices;
let csvSinopecPrices;
let csvSpcPrices;
const fuelPriceDateInputDefault = moment(new Date()).format('YYYY-MM-DD');

// ----------------------------------------------------------------------

export default function FcAppAdmin() {
  const fsCtx = useContext(FirestoreContext);
  const [fcadminDoc, setFcadminDoc] = useState({});

  const [fuelPriceRows, setFuelPriceRows] = useState([]);
  const [coePrices, setCoePrices] = useState({
    date: fuelPriceDateInputDefault,
    exNo: '1st',
    catA: {
      amount: '',
      increase: true
    },
    catB: {
      amount: '',
      increase: true
    },
    catC: {
      amount: '',
      increase: true
    },
    catD: {
      amount: '',
      increase: true
    },
    catE: {
      amount: '',
      increase: true
    }
  });
  const [csvFuelPriceRows, setCsvFuelPriceRows] = useState([]);
  const [fuelPriceDate, setFuelPriceDate] = useState('');
  const fileInputRef = useRef();
  const [fuelPriceDateInput, setFuelPriceDateInput] = useState('');

  const tableInitialSort = {
    sorting: {
      sortModel: [{ field: 'msgDate', sort: 'desc' }]
    }
  };

  const fetchFuelPrices = useCallback(async () => {
    const q = query(doc(fsCtx.fsObject, 'fcadmin', 'fcadmin'));
    const querySnapshot = await getDoc(q);
    setFcadminDoc(querySnapshot.data());
    setFuelPriceDate(
      querySnapshot.data().fuelPrices.date.toDate().toLocaleDateString('en-SG', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    );

    const fbFuelPrices = querySnapshot.data().fuelPrices;

    setCoePrices({
      ...querySnapshot.data().coePrices,
      date: querySnapshot.data().coePrices.date.toDate()
    });

    setFuelPriceRows([
      {
        id: 'Caltex',
        diesel: fbFuelPrices.caltexPrices.diesel,
        ninetwo: fbFuelPrices.caltexPrices.ninetwo,
        ninefive: fbFuelPrices.caltexPrices.ninefive,
        nineeight: fbFuelPrices.caltexPrices.nineeight,
        premium: fbFuelPrices.caltexPrices.premium
      },
      {
        id: 'Esso',
        diesel: fbFuelPrices.essoPrices.diesel,
        ninetwo: fbFuelPrices.essoPrices.ninetwo,
        ninefive: fbFuelPrices.essoPrices.ninefive,
        nineeight: fbFuelPrices.essoPrices.nineeight,
        premium: fbFuelPrices.essoPrices.premium
      },
      {
        id: 'Shell',
        diesel: fbFuelPrices.shellPrices.diesel,
        ninetwo: fbFuelPrices.shellPrices.ninetwo,
        ninefive: fbFuelPrices.shellPrices.ninefive,
        nineeight: fbFuelPrices.shellPrices.nineeight,
        premium: fbFuelPrices.shellPrices.premium
      },
      {
        id: 'Sinopec',
        diesel: fbFuelPrices.sinopecPrices.diesel,
        ninetwo: fbFuelPrices.sinopecPrices.ninetwo,
        ninefive: fbFuelPrices.sinopecPrices.ninefive,
        nineeight: fbFuelPrices.sinopecPrices.nineeight,
        premium: fbFuelPrices.sinopecPrices.premium
      },
      {
        id: 'SPC',
        diesel: fbFuelPrices.spcPrices.diesel,
        ninetwo: fbFuelPrices.spcPrices.ninetwo,
        ninefive: fbFuelPrices.spcPrices.ninefive,
        nineeight: fbFuelPrices.spcPrices.nineeight,
        premium: fbFuelPrices.spcPrices.premium
      }
    ]);
  }, [fsCtx.fsObject]);

  useEffect(() => fetchFuelPrices(), [fetchFuelPrices]);

  // Message Details modal logic

  const columns = [
    {
      field: 'id',
      headerName: 'Brand',
      width: 100,
      type: 'string'
    },
    {
      field: 'diesel',
      headerName: 'Diesel',
      width: 90,
      type: 'string',
      sortable: false
    },
    {
      field: 'ninetwo',
      headerName: '92',
      width: 90,
      type: 'string',
      editable: false
    },
    {
      field: 'ninefive',
      headerName: '95',
      width: 90,
      type: 'string',
      editable: false
    },
    {
      field: 'nineeight',
      headerName: '98',
      width: 90,
      type: 'string',
      editable: false
    },
    {
      field: 'premium',
      headerName: 'Premium',
      width: 90,
      type: 'string',
      editable: false
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`
    // }
  ];

  // Tabs Navigation

  const [value, setValue] = useState(0);

  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const readFuelPrice = (e) => {
    // only trigger this if something was actually uploaded
    if (e.target.value) {
      const fr = new FileReader();
      fr.onload = function (event) {
        const temp = event.target.result.split('\n');

        // Validate the format of the csv file
        if (temp.length !== 6 && temp.length !== 7) {
          // check length == 7 because is user uses Excel to edit csv files, Excel always appends one empty line to the bottom of the file
          alert('There must be exactly 6 rows in the csv file! (1)');
          return;
        }
        if (temp.length === 7) {
          // check length == 7 because if user uses Excel to edit csv files, Excel always appends one empty line to the bottom of the file
          if (temp[6].trim().length !== 0) {
            alert(
              'There must be exactly 6 rows in the csv file. There is something in the last row (2)!'
            );
            return;
          }
          temp.pop();
        }
        for (let i = 0; i < temp.length - 1; i += 1) {
          if (temp[i].trim().length === 0) {
            alert(`Row ${i} is empty!`);
            return;
          }
        }
        const tempToArray = temp.map((el) => el.split(',')).slice(1);

        // check that every field contains either a valid number of N.A.
        // eslint-disable-next-line
        for (const el of tempToArray) {
          for (let i = 1; i < 6; i += 1) {
            if (!parseInt(el[i], 10) && !el[i].includes('N.A.')) {
              alert(
                `Every field must be either a number of "N.A."! One of the fields contain ${el[i]}.`
              );
              return;
            }
          }
        }

        for (let i = 0; i < temp.length; i += 1) {
          temp[i] = temp[i].replace('\r', '').split(',');
        }
        const caltexI = temp.findIndex((row) => row[0].toLowerCase().includes('caltex'));
        const essoI = temp.findIndex((row) => row[0].toLowerCase().includes('esso'));
        const shellI = temp.findIndex((row) => row[0].toLowerCase().includes('shell'));
        const sinopecI = temp.findIndex((row) => row[0].toLowerCase().includes('sinopec'));
        const spcI = temp.findIndex((row) => row[0].toLowerCase().includes('spc'));

        const dieselI = temp[0].findIndex((el) => el.toLowerCase().includes('diesel'));
        const ninetwoI = temp[0].findIndex((el) => el.toLowerCase().includes('92'));
        const ninefiveI = temp[0].findIndex((el) => el.toLowerCase().includes('95'));
        const nineeightI = temp[0].findIndex((el) => el.toLowerCase().includes('98'));
        const premiumI = temp[0].findIndex((el) => el.toLowerCase().includes('premium'));

        csvCaltexPrices = {
          id: 'Caltex',
          diesel: temp[caltexI][dieselI].toString(),
          ninetwo: temp[caltexI][ninetwoI].toString(),
          ninefive: temp[caltexI][ninefiveI].toString(),
          nineeight: temp[caltexI][nineeightI].toString(),
          premium: temp[caltexI][premiumI].toString()
        };
        csvEssoPrices = {
          id: 'Esso',
          diesel: temp[essoI][dieselI].toString(),
          ninetwo: temp[essoI][ninetwoI].toString(),
          ninefive: temp[essoI][ninefiveI].toString(),
          nineeight: temp[essoI][nineeightI].toString(),
          premium: temp[essoI][premiumI].toString()
        };
        csvShellPrices = {
          id: 'Shell',
          diesel: temp[shellI][dieselI].toString(),
          ninetwo: temp[shellI][ninetwoI].toString(),
          ninefive: temp[shellI][ninefiveI].toString(),
          nineeight: temp[shellI][nineeightI].toString(),
          premium: temp[shellI][premiumI].toString()
        };
        csvSinopecPrices = {
          id: 'Sinopec',
          diesel: temp[sinopecI][dieselI].toString(),
          ninetwo: temp[sinopecI][ninetwoI].toString(),
          ninefive: temp[sinopecI][ninefiveI].toString(),
          nineeight: temp[sinopecI][nineeightI].toString(),
          premium: temp[sinopecI][premiumI].toString()
        };
        csvSpcPrices = {
          id: 'SPC',
          diesel: temp[spcI][dieselI].toString(),
          ninetwo: temp[spcI][ninetwoI].toString(),
          ninefive: temp[spcI][ninefiveI].toString(),
          nineeight: temp[spcI][nineeightI].toString(),
          premium: temp[spcI][premiumI].toString()
        };

        setCsvFuelPriceRows([
          csvCaltexPrices,
          csvEssoPrices,
          csvShellPrices,
          csvSinopecPrices,
          csvSpcPrices
        ]);
      };
      fr.readAsText(e.target.files[0]);
      handleOpen();
    }
  };

  // Modal Logic ----------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setFuelPriceDateInput(fuelPriceDateInputDefault);
    setOpen(true);
  };
  const handleClose = () => {
    fileInputRef.current.value = '';
    setCsvFuelPriceRows([]);
    setOpen(false);
  };
  const cancelCsv = () => {
    if (window.confirm('Are you sure you want to cancel submission?')) {
      handleClose();
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

  // Submission Logic ----------------------------------------------------------------------

  const omitIdHandler = (obj, brand) =>
    (({ id, ...o }) => o)(obj.find((row) => row.id.toLowerCase().includes(brand)));

  const submitCsv = async () => {
    try {
      const fcadminDoc = doc(fsCtx.fsObject, 'fcadmin', 'fcadmin');
      const fuelPriceUpdate = {
        fuelPrices: {
          caltexPrices: omitIdHandler(csvFuelPriceRows, 'caltex'),
          essoPrices: omitIdHandler(csvFuelPriceRows, 'esso'),
          shellPrices: omitIdHandler(csvFuelPriceRows, 'shell'),
          sinopecPrices: omitIdHandler(csvFuelPriceRows, 'sinopec'),
          spcPrices: omitIdHandler(csvFuelPriceRows, 'spc'),
          date: Timestamp.fromDate(new Date(fuelPriceDateInput))
          // essoPrices: {
          //   ninetwo: csvFuelPriceRows[0].essoPrices.toString(),
          //   ninefive: csvFuelPriceRows[1].essoPrices.toString(),
          //   nineeight: csvFuelPriceRows[2].essoPrices.toString(),
          //   diesel: csvFuelPriceRows[3].essoPrices.toString(),
          //   premium: csvFuelPriceRows[4].essoPrices.toString()
          // },
          // shellPrices: {
          //   ninetwo: csvFuelPriceRows[0].shellPrices.toString(),
          //   ninefive: csvFuelPriceRows[1].shellPrices.toString(),
          //   nineeight: csvFuelPriceRows[2].shellPrices.toString(),
          //   diesel: csvFuelPriceRows[3].shellPrices.toString(),
          //   premium: csvFuelPriceRows[4].shellPrices.toString()
          // },
          // sinopecPrices: {
          //   ninetwo: csvFuelPriceRows[0].sinopecPrices.toString(),
          //   ninefive: csvFuelPriceRows[1].sinopecPrices.toString(),
          //   nineeight: csvFuelPriceRows[2].sinopecPrices.toString(),
          //   diesel: csvFuelPriceRows[3].sinopecPrices.toString(),
          //   premium: csvFuelPriceRows[4].sinopecPrices.toString()
          // },
          // spcPrices: {
          //   ninetwo: csvFuelPriceRows[0].spcPrices.toString(),
          //   ninefive: csvFuelPriceRows[1].spcPrices.toString(),
          //   nineeight: csvFuelPriceRows[2].spcPrices.toString(),
          //   diesel: csvFuelPriceRows[3].spcPrices.toString(),
          //   premium: csvFuelPriceRows[4].spcPrices.toString()
          // }
        }
      };
      await updateDoc(fcadminDoc, fuelPriceUpdate);
      setFuelPriceDate(fuelPriceDateInput);
      setFuelPriceRows(csvFuelPriceRows);
      alert(`Fuel prices updated!`);
      handleClose();
    } catch (error) {
      alert(`An error occurred! ${error.message}`);
    }
  };

  const testbn = async () => {
    const q = doc(fsCtx.fsObject, 'fcadmin', 'fcadmin');
    const docSnap = await getDoc(q);
    console.log(docSnap.data());
  };

  const updateFuelPriceDate = (e) => {
    setFuelPriceDateInput(e.target.value);
  };

  // FormikProvider

  const NewMessageSchema = Yup.object().shape({
    date: Yup.date()
      .default(() => new Date())
      .required('Please enter a value for date.'),
    exNo: Yup.string().required('Please select the exercise number.'),
    catA: Yup.object()
      .shape({
        amount: Yup.number().positive().required('Please enter a value for Cat A.'),
        increase: Yup.boolean().required('Increase/decrease is required.')
      })
      .required('Cat A information is required.'),
    catB: Yup.object()
      .shape({
        amount: Yup.number().positive().required('Please enter a value for Cat B.'),
        increase: Yup.boolean().required('Increase/decrease is required.')
      })
      .required('Cat B information is required.'),
    catC: Yup.object()
      .shape({
        amount: Yup.number().positive().required('Please enter a value for Cat C.'),
        increase: Yup.boolean().required('Increase/decrease is required.')
      })
      .required('Cat C information is required.'),
    catD: Yup.object()
      .shape({
        amount: Yup.number().positive().required('Please enter a value for Cat D.'),
        increase: Yup.boolean().required('Increase/decrease is required.')
      })
      .required('Cat D information is required.'),
    catE: Yup.object()
      .shape({
        amount: Yup.number().positive().required('Please enter a value for Cat E.'),
        increase: Yup.boolean().required('Increase/decrease is required.')
      })
      .required('Cat E information is required.')
  });

  const initialValues = {
    date: fuelPriceDateInputDefault,
    exNo: '1st',
    catA: {
      amount: '',
      increase: true
    },
    catB: {
      amount: '',
      increase: true
    },
    catC: {
      amount: '',
      increase: true
    },
    catD: {
      amount: '',
      increase: true
    },
    catE: {
      amount: '',
      increase: true
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NewMessageSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (window.confirm('Are you sure you want to update the COE prices?')) {
        let submission;
        try {
          submission = { ...values, date: new Date(values.date) };
          console.log({ ...values, date: new Date(values.date) });
          submitCoePrices(submission);
          resetForm();
        } catch (error) {
          errorHandler(submission, 'submitCoePrices', error);
        }
      }
      setSubmitting(false);
    }
  });

  const { errors, touched, isSubmitting, handleBlur, handleSubmit, handleReset, getFieldProps } =
    formik;

  const submitCoePrices = async (submittedCoePrices) => {
    const fcadminDoc = doc(fsCtx.fsObject, 'fcadmin', 'fcadmin');
    await updateDoc(fcadminDoc, {
      coePrices: submittedCoePrices
    });
    setCoePrices(submittedCoePrices);
    alert(`COE prices updated!`);
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          App | Admin
        </Typography>
        {/* <Button onClick={testbn} variant="contained">
          dwao
        </Button> */}

        <Modal
          open={open}
          onClose={cancelCsv}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Fuel Prices
              <br />
              <br />
            </Typography>
            <TextField
              id="date"
              onChange={updateFuelPriceDate}
              label="Price Date"
              type="date"
              defaultValue={fuelPriceDateInputDefault}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <br />
            <DataTable
              dataRows={csvFuelPriceRows}
              dataColumns={columns}
              tableInitialSort={tableInitialSort}
            />
            <br />

            <Button variant="contained" onClick={submitCsv}>
              Submit
            </Button>
            <Button variant="outlined" onClick={cancelCsv}>
              Cancel
            </Button>
          </Box>
        </Modal>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={tabHandleChange}
            aria-label="basic tabs example"
            // variant="fullWidth"
          >
            <Tab label="Fuel Prices" {...a11yProps(0)} />
            <Tab label="COE Prices" {...a11yProps(1)} />
            <Tab label="Settings" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Typography variant="h5" gutterBottom>
            Update Fuel Prices
          </Typography>
          Upload a CSV file containing fuel prices with the same format as the table below to
          update.
          <br />
          If the fuel type isn't sold by that petrol company, type "N.A." in that field.
          <br />
          <br />
          <Input
            slotProps={{ input: { accept: '.csv' } }}
            type="file"
            inputRef={fileInputRef}
            onChange={readFuelPrice}
          />
          <br />
          <br />
          <Typography variant="h5" gutterBottom>
            Current Fuel Prices in App
          </Typography>
          Latest Update: {moment(new Date(fuelPriceDate)).format('D MMM YYYY')}
          <br />
          <br />
          <Button
            variant="contained"
            // href="data:text/plain;charset=utf-8,%2Ccaltex%2Cesso%2Cshell%2Csinopec%2Cspc%0A92%2C3.14%2C3.09%2CN.A.%2CN.A.%2C3.04%0A95%2C3.19%2C3.13%2C3.19%2C3.13%2C3.07%0A98%2CN.A.%2C3.6%2C3.68%2C3.6%2C3.55%0Adiesel%2C3.05%2C2.96%2C3.08%2C2.95%2C2.89%0Apremium%2C3.85%2CN.A.%2C3.9%2C3.73%2CN.A."
            href={`data:text/plain;charset=utf-8,,Diesel,92,95,98,Premium\n${fuelPriceRows
              .map(
                (el) =>
                  `${el.id},${el.diesel},${el.ninetwo},${el.ninefive},${el.nineeight},${el.premium}`
              )
              .join('\n')}`}
            download={`fuel_prices_${fuelPriceDate}.csv`}
          >
            Download Current Fuel Prices File
          </Button>
          <br />
          <br />
          <DataTable
            dataRows={fuelPriceRows}
            dataColumns={columns}
            tableInitialSort={tableInitialSort}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography variant="h5" gutterBottom>
            Current COE Prices in App
          </Typography>
          Latest Update: {moment(new Date(coePrices.date)).format('D MMM YYYY')}
          <br />
          Exercise Number: {coePrices.exNo}
          <br />
          <br />
          <Stack direction="column" spacing={1}>
            {Object.entries(coePrices)
              .filter((el) => el[0] !== 'date' && el[0] !== 'exNo')
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map((el) => (
                <div key={el[0]}>
                  {fTitleCase(el[0].replace(/([a-z])([A-Z])/g, '$1 $2'))}: {el[1].amount}{' '}
                  {el[1].increase ? (
                    <ArrowUpwardIcon color="error" />
                  ) : (
                    <ArrowDownwardIcon color="success" />
                  )}
                </div>
              ))}
          </Stack>
          <br />
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography variant="h5" gutterBottom>
                  Update COE Prices
                </Typography>

                <TextField
                  id="date"
                  label="COE Price Date"
                  {...getFieldProps('date')}
                  type="date"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Stack direction="row" spacing={2} alignItems="center">
                  <div>Exercise Number</div>
                  <FormControl>
                    <ToggleButtonGroup
                      color="primary"
                      value={formik.values.exNo}
                      exclusive
                      onChange={(_, newVal) => {
                        formik.setFieldValue('exNo', newVal);
                      }}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="1st" aria-label="left aligned">
                        First
                      </ToggleButton>
                      <ToggleButton value="2nd" aria-label="centered">
                        Second
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {touched.exNo && <FormHelperText>{errors.exNo}</FormHelperText>}
                  </FormControl>
                </Stack>
                {Object.entries(initialValues).map((el) =>
                  el[0] === 'date' || el[0] === 'exNo' ? undefined : (
                    <Stack key={el[0]} direction="row" spacing={1}>
                      <TextField
                        type="number"
                        label={fTitleCase(el[0].replace(/([a-z])([A-Z])/g, '$1 $2'))}
                        {...getFieldProps(el[0])}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          formik.setFieldValue(el[0], {
                            amount: parseInt(event.target.value, 10),
                            increase: formik.values[el[0]].increase
                          });
                        }}
                        value={formik.values[el[0]].amount}
                        error={Boolean(touched[el[0]] && errors[el[0]])}
                        helperText={touched[el[0]] && errors[el[0]] && errors[el[0]].amount}
                      />
                      <ToggleButtonGroup
                        color="primary"
                        value={formik.values[el[0]].increase}
                        exclusive
                        onChange={(_, newVal) => {
                          formik.setFieldValue(el[0], {
                            amount: formik.values[el[0]].amount,
                            increase: newVal
                          });
                        }}
                        aria-label="text alignment"
                      >
                        <ToggleButton value aria-label="left aligned">
                          Increase
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="centered">
                          Decrease
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                  )
                )}
                <Box sx={{ '& button': { m: 1 } }}>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                  <Button size="large" variant="contained" onClick={handleReset}>
                    Clear
                  </Button>
                </Box>
              </Stack>
            </Form>
          </FormikProvider>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <AppAdminSettings fcadminDoc={fcadminDoc} />
        </TabPanel>
      </Box>
    </Container>
  );
}
