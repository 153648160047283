import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, DialogContentText, Divider, Grid, List, ListItemText, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { LoadingButton } from '@material-ui/lab';
import { Warning } from '@mui/icons-material';

import CreateMissingOTRForm from '../../Form/Sales/OTR/CreateMissingOTRForm';
import ProposeOTRForm from '../../Form/Sales/OTR/ProposeOTRForm';
import RejectOTRForm from '../../Form/Sales/OTR/RejectOTRForm';
import { FcTextField, SubmitBtnGroup } from '../../Form/FormFields';

import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';

import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import { fetchEnhcApi } from '../../../utils/fetchApi';

import FcTable from '../../Table/FcTable';
import DataContext from '../../../store/DataContext';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

import OTRReportExport from './OTRReportExport';
import { otrColumns, otrUndefinedColumns } from './ReportColumns';
import ExportJson from './ExportJson';

const Commission = ({
  startDate,
  endDate,
  salespersonList,
  updateParentLoadingState,
  currentSalesPerson,
  dayjs,
  convertRawDataToCustomersObject,
  carData,
  currentUserDept
}) => {
  const dataCtx = useContext(DataContext);

  const isManager = ['manager', 'superadmin'].includes(currentUserDept);
  const isSalesperson = ['sales', 'superadmin'].includes(currentUserDept);
  const isAccountOrManager = ['accounts', 'manager', 'superadmin'].includes(currentUserDept);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/otr/storeOtr`);
  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/otr/list`, true, { startDate });

  const [loading, setLoading] = useState(false);
  const [dbLoading, setDbLoading] = useState(false);
  // const [lockUpdate, setLockUpdate] = useState(true);
  const [shortTermRow, setShortTermRow] = useState([]);
  const [longTermRow, setLongTermRow] = useState([]);
  const [undefinedRow, setUndefinedRow] = useState([]);
  const [fetchFromDb, setFetchFromDb] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const onReload = async () => {
    await onGet({ startDate });
  };

  const AgreeAction = ({ row, onOpenSnackbar, onReload }) => {
    const [open, setOpen] = useState(false);

    const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/agree`);

    const onAgree = async (e) => {
      e.preventDefault();
      try {
        await onPut({
          otrRecordId: row.docId, status: 1, rentalAgreementNo: row.rentalAgreementNo
        });
        await onReload();
        onOpenSnackbar('OTR agreed successfully!');
      } catch (error) {
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
      setOpen(false);
    };

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">Agree with this OTR?</DialogTitle>
            <DialogContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Warning color="warning" sx={{ mr: 1 }} />
                <DialogContentText>
                  Warning: Once agreed, this step is irreversible.
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton variant="contained" onClick={onAgree} autoFocus loading={loading}>
                Agree
              </LoadingButton>
            </DialogActions>
          </Box>
        </Dialog>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          disabled={row.otrStatus !== 'pending'}
        >
          Agree
        </Button>
      </>);
  };

  AgreeAction.propTypes = {
    row: PropTypes.object, onOpenSnackbar: PropTypes.func, onReload: PropTypes.func
  };

  const DisagreeAction = ({ row, onOpenSnackbar, onReload }) => {
    const [open, setOpen] = useState(false);

    const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/agree`);

    const onDisagree = async (e) => {
      e.preventDefault();
      try {
        await onPut({
          otrRecordId: row.docId, status: 0, rentalAgreementNo: row.rentalAgreementNo
        });
        await onReload();
        onOpenSnackbar('OTR disagreed successfully!');
      } catch (error) {
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
      setOpen(false);
    };

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">Disagree with this OTR?</DialogTitle>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="error"
                onClick={onDisagree}
                autoFocus
                loading={loading}
              >
                Disagree
              </LoadingButton>
            </DialogActions>
          </Box>
        </Dialog>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpen(true)}
          disabled={row.otrStatus !== 'pending'}
        >
          Disagree
        </Button>
      </>);
  };

  DisagreeAction.propTypes = {
    row: PropTypes.object, onOpenSnackbar: PropTypes.func, onReload: PropTypes.func
  };

  const ManagerDisagreeAction = ({ row, onReload }) => {
    const [open, setOpen] = useState(false);
    const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/agree`);
    const onDisagree = async (values) => {
      try {
        const data = {
          ...values, otrRecordId: row.docId, status: 0, rentalAgreementNo: row.rentalAgreementNo
        };
        await onPut(data);
        await onReload();
        onOpenSnackbar('OTR request disagree successfully!');
        setOpen(false);
      } catch (error) {
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
    };

    const formik = useFormik({
      initialValues: {
        disagreeReason: ''
      }, validationSchema: Yup.object({
        disagreeReason: Yup.string().required('Please fill in the reason!')
      }), onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onDisagree(values);
        setSubmitting(false);
      }
    });
    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">
              Do you want to DISAGREE with this OTR?
            </DialogTitle>
            <FormikProvider value={formik}>
              <Form autoComplete="off">
                <DialogContent>
                  <FcTextField
                    multiline
                    formik={formik}
                    name="disagreeReason"
                    label="Disagree Reason*"
                  />
                </DialogContent>
                <DialogActions>
                  <SubmitBtnGroup
                    formik={formik}
                    method="Disagree"
                    color="error"
                    onCancel={() => setOpen(false)}
                  />
                </DialogActions>
              </Form>
            </FormikProvider>
          </Box>
        </Dialog>
        <Button variant="contained" color="error" onClick={() => setOpen(true)}>
          Disagree
        </Button>
      </>);
  };

  ManagerDisagreeAction.propTypes = {
    row: PropTypes.object, onReload: PropTypes.func
  };

  const ProposeAction = ({ row, onReload }) => {
    const [open, setOpen] = useState(false);

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">Propose a new OTR value?</DialogTitle>
            <DialogContent>
              <ProposeOTRForm onClose={() => setOpen(false)} onReload={onReload} row={row} />
            </DialogContent>
          </Box>
        </Dialog>
        <Button variant="contained" onClick={() => setOpen(true)} disabled={row.proposeDate}>
          Propose OTR
        </Button>
      </>);
  };

  ProposeAction.propTypes = { row: PropTypes.object, onReload: PropTypes.func };

  const ApproveAction = ({ row, onOpenSnackbar, onReload }) => {
    const [remarks, setRemarks] = useState('');
    const [open, setOpen] = useState(false);

    const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/approve`);

    const onApprove = async (e) => {
      e.preventDefault();
      try {
        await onPut({
          otrRecordId: row.docId, status: 1, remarks, rentalAgreementNo: row.rentalAgreementNo
        });
        await onReload();
        onOpenSnackbar('OTR approved successfully!');
      } catch (error) {
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
      setOpen(false);
    };

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">
              Do you want to approve this OTR request?
            </DialogTitle>
            <DialogContent>
              <Box py={1}>
                <TextField
                  fullWidth
                  multiline
                  label="Remarks"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton variant="contained" onClick={onApprove} autoFocus loading={loading}>
                Approve
              </LoadingButton>
            </DialogActions>
          </Box>
        </Dialog>
        <Button variant="contained" onClick={() => setOpen(true)} disabled={!row.proposeDate}>
          Approve
        </Button>
      </>);
  };

  ApproveAction.propTypes = {
    row: PropTypes.object, onOpenSnackbar: PropTypes.func, onReload: PropTypes.func
  };

  const RejectAction = ({ row, onReload }) => {
    const [open, setOpen] = useState(false);

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">
              Do you want to reject this OTR request?
            </DialogTitle>
            <RejectOTRForm onClose={() => setOpen(false)} onReload={onReload} row={row} />
          </Box>
        </Dialog>
        <Button
          disabled={!row.proposeDate}
          variant="contained"
          color="error"
          onClick={() => setOpen(true)}
        >
          Reject
        </Button>
      </>);
  };

  RejectAction.propTypes = { row: PropTypes.object, onReload: PropTypes.func };

  const ViewAction = ({ row }) => {
    const [open, setOpen] = useState(false);

    const bookingDetails = [
      { label: 'Rental Agreement No', value: row?.rentalAgreementNo },
      { label: 'Booking No', value: row?.bookingNo },
      { label: 'Start Date', value: dayjs(row?.startDate).format('DD MMM YYYY') },
      { label: 'End Date', value: dayjs(row?.endDate).format('DD MMM YYYY') },
      { label: 'Customer Name', value: row?.hirerName },
      { label: 'Customer Type', value: capitalizeFirstLetter(row?.customerType) },
      { label: 'New/Existing Customer', value: capitalizeFirstLetter(row?.newOrExistingCustomer) },
      { label: 'Type of Billing', value: capitalizeFirstLetter(row?.billingType) },
      { label: 'Deposit (SGD)', value: row?.depositAmount },
      { label: 'Rental Rate (SGD)', value: row?.rentalRate },
      { label: 'Total Upfront Amount (SGD)', value: row?.totalUpfront },
      { label: 'Sales', value: capitalizeFirstLetter(row?.staff) },
      { label: 'CarPlate', value: row?.carPlate }
    ];

    if (row?.osAmount) bookingDetails.push({ label: 'Outstanding Amount (SGD)', value: row?.osAmount });

    const otrDetails = [
      { label: 'OTR Status', value: capitalizeFirstLetter(row?.otrStatus) },
      { label: 'OTR', value: row?.points }
    ];

    if (row?.proposeRemarks) otrDetails.push({ label: 'Proposed Reasons', value: row.proposeRemarks });
    if (row?.approveDate) otrDetails.push({ label: 'Approved/Rejected Date', value: dayjs(row?.approveDate).format('DD MMM YYYY') });
    if (row?.approveRemarks) otrDetails.push({ label: 'Approved Reason', value: row.approveRemarks });
    if (row?.addedBy) otrDetails.push({ label: 'Missing Contract Added by', value: row.addedBy });

    return (
      <>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
          <Box p={2}>
            <DialogTitle>OTR Details</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                {bookingDetails?.map(({ label, value }, index) => (<Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogContent>
              <Grid container spacing={3}>
                {otrDetails?.map(({ label, value }, index) => (<Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
          View
        </Button>
      </>
    );
  };

  ViewAction.propTypes = { row: PropTypes.object };

  const UndefinedViewAction = ({ row }) => {
    const [open, setOpen] = useState(false);

    const bookingDetails = [
      { label: 'Rental Agreement No', value: row?.rentalAgreementNo },
      { label: 'Booking No', value: row?.bookingNo },
      { label: 'Start Date', value: dayjs(row?.startDate).format('DD MMM YYYY') },
      { label: 'End Date', value: dayjs(row?.endDate).format('DD MMM YYYY') },
      { label: 'Customer Name', value: row?.hirerName },
      { label: 'Customer Type', value: capitalizeFirstLetter(row?.customerType) },
      { label: 'New/Existing Customer', value: capitalizeFirstLetter(row?.newOrExistingCustomer) },
      { label: 'Type of Billing', value: capitalizeFirstLetter(row?.billingType) },
      { label: 'Deposit (SGD)', value: row?.depositAmount },
      { label: 'Rental Rate (SGD)', value: row?.rentalRate },
      { label: 'Total Upfront Amount (SGD)', value: row?.totalUpfront },
      { label: 'Sales', value: capitalizeFirstLetter(row?.staff) },
      { label: 'CarPlate', value: row?.carPlate }
    ];

    if (row?.osAmount) bookingDetails.push({ label: 'Outstanding Amount (SGD)', value: row?.osAmount });

    const otrDetails = [
      { label: 'OTR Status', value: capitalizeFirstLetter(row?.otrStatus) },
      { label: 'Original OTR', value: row?.originalOtr },
      { label: 'Proposed OTR', value: row?.proposedOtr },
      { label: 'Reasons', value: row?.proposeRemarks }
    ];

    if (row?.proposeDate) otrDetails.push({ label: 'Proposed Date', value: dayjs(row.proposeDate).format('DD MMM YYYY') });
    if (row?.proposeBy) otrDetails.push({ label: 'Proposed by', value: row.proposeBy });
    if (row?.rejectReason) otrDetails.push({ label: 'Rejection Reason', value: row.rejectReason });
    if (row?.addedBy) otrDetails.push({ label: 'Missing Contract Added by', value: row.addedBy });

    const disagreeDetails = [];
    if (row?.otrStatus === 'disagree' && row?.agreeDate)
      disagreeDetails.push({ label: 'Disagree Date', value: dayjs(row.agreeDate).format('DD MMM YYYY') });
    if (row?.otrStatus === 'disagree' && row?.agreeBy)
      disagreeDetails.push({ label: 'Disagree by', value: capitalizeFirstLetter(row.agreeBy) });
    if (row?.otrStatus === 'disagree' && row?.disagreeReason)
      disagreeDetails.push({ label: 'Disagree Reason', value: row.disagreeReason });

    return (
      <>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
          <Box p={2}>
            <DialogTitle>OTR Details</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                {bookingDetails?.map(({ label, value }, index) => (<Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogContent>
              <Grid container spacing={3}>
                {otrDetails?.map(({ label, value }, index) => (<Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            {row.otrStatus === 'disagree' && (<>
                <Divider />
                <DialogContent>
                  <Grid container spacing={3}>
                    {disagreeDetails?.map(({ label, value }, index) => (
                      <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                        <Typography variant="h6">{label}</Typography>
                        {value}
                      </Grid>))}
                  </Grid>
                </DialogContent>
              </>)}
            <DialogActions>
              <Button variant="contained" onClick={() => setOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
          View
        </Button>
      </>
    );
  };

  UndefinedViewAction.propTypes = { row: PropTypes.object };

  const AddAction = ({ onReload }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
          <Box p={2}>
            <DialogTitle>Add Missing Contract</DialogTitle>
            <DialogContent>
              <CreateMissingOTRForm
                dayjs={dayjs}
                onClose={() => setOpen(false)}
                onReload={onReload}
                currentSalesPerson={currentSalesPerson}
                date={startDate}
                carData={carData}
              />
            </DialogContent>
          </Box>
        </Dialog>

        <Button variant="contained" onClick={() => setOpen(true)}>
          Add Missing Contract
        </Button>
      </>
    );
  };

  AddAction.propTypes = { onReload: PropTypes.func };

  // prettier-ignore
  const columns = [
    { field: 'no', headerName: 'No.', minWidth: 10, headerAlign: 'center', align: 'center', showIndex: true, sticky: true },
    {
      field: 'startDate', headerName: 'Start Date', minWidth: 130, type: 'date', sortable: true,
      align: 'center', headerAlign: 'center', sticky: true,
      renderCell: ({ row }) => dayjs(row.startDate).format('DD MMM YYYY'),
    },
    { field: 'hirerName', headerName: 'Hirer', align: 'left', headerAlign: 'left', minWidth: 300, sticky: true },
    { field: 'carPlate', headerName: ' Car Plate', align: 'center', headerAlign: 'center', minWidth: 100, sticky: true },
    { field: 'rentalAgreementNo', headerName: 'Agreement No.', align: 'center', headerAlign: 'center', minWidth: 145 },
    {
      field: 'endDate', headerName: 'End Date', minWidth: 130, type: 'date', sortable: true, align: 'center', headerAlign: 'center',
      renderCell: ({ row }) => dayjs(row.endDate).format('DD MMM YYYY')
    },
    { field: 'bookingNo', headerName: 'Booking No.', align: 'center', headerAlign: 'center', minWidth: 125 },
    { field: 'staff', headerName: 'SalesPerson', align: 'center', headerAlign: 'center', minWidth: 120 },
    { field: 'newOrExistingCustomer', headerName: 'New/Existing Customer', align: 'center', headerAlign: 'center', minWidth: 195 },
    { field: 'customerType', headerName: 'Type', align: 'center', headerAlign: 'center', minWidth: 120 },
    {
      field: 'depositAmount', headerName: 'Deposit', align: 'center', headerAlign: 'center', minWidth: 100,
      renderCell: ({ row }) => Number(row.depositAmount).toFixed(2),
    },
    {
      field: 'rentalRate', headerName: 'Rental Rate', align: 'center', headerAlign: 'center', minWidth: 120,
      renderCell: ({ row }) => Number(row.rentalRate).toFixed(2)
    },
    { field: 'periodType', headerName: 'Period Type', align: 'center', headerAlign: 'center', minWidth: 120 },
    { field: 'billingType', headerName: 'Billing Type', align: 'center', headerAlign: 'center', minWidth: 120 },
    {
      field: 'osAmount', headerName: 'OS Amount', align: 'center', headerAlign: 'center', minWidth: 120,
      renderCell: ({ row }) => typeof row.osAmount !== 'undefined' ? Number(row.osAmount).toFixed(2) : ''
    },
    {
      field: 'totalUpfront', headerName: 'Total Upfront', align: 'center', headerAlign: 'center', minWidth: 130,
      renderCell: ({ row }) => Number(row.totalUpfront).toFixed(2)
    },
    { field: 'points', headerName: 'OTR', align: 'center', headerAlign: 'center', minWidth: 100 },
    {
      field: 'otrStatus', headerName: 'OTR Status', align: 'center', headerAlign: 'center', sortable: false, minWidth: 150,
      renderCell: ({ row }) => capitalizeFirstLetter(row.otrStatus || '')
    },
    {
      field: '', headerName: 'Action', headerAlign: 'center', align: 'center', sortable: false, minWidth: 100,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="center" spacing={2}>
          <ViewAction row={row} />
        </Stack>
      ),
    }
  ];

  if (isSalesperson) {
    columns.push({
      field: '', headerName: 'Action for Salesperson', headerAlign: 'center', align: 'center', sortable: false, minWidth: 200,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="center" spacing={2}>
          <AgreeAction row={row} onOpenSnackbar={onOpenSnackbar} onReload={onReload} />
          <DisagreeAction row={row} onOpenSnackbar={onOpenSnackbar} onReload={onReload} />
        </Stack>
      ),
    });
  }

  if (isAccountOrManager) {
    columns.push({
      field: '', headerName: 'For Manager/Accounts', headerAlign: 'center', align: 'center', sortable: false, minWidth: 200,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="center" spacing={2}>
          <ManagerDisagreeAction row={row} onReload={onReload} />
        </Stack>
      ),
    });
  }

  // prettier-ignore
  const undefinedColumns = [
    { field: 'no', headerName: 'No.', minWidth: 10, headerAlign: 'center', align: 'center', showIndex: true, sticky: true },
    {
      field: 'startDate', headerName: 'Start Date', minWidth: 130, type: 'date', sortable: true,
      align: 'center', headerAlign: 'center', sticky: true,
      renderCell: ({ row }) => dayjs(row.startDate).format('DD MMM YYYY'),
    },
    { field: 'hirerName', headerName: 'Hirer', align: 'left', headerAlign: 'left', minWidth: 300, sticky: true },
    { field: 'carPlate', headerName: ' Car Plate', align: 'center', headerAlign: 'center', minWidth: 100, sticky: true },
    { field: 'rentalAgreementNo', headerName: 'Agreement No.', align: 'center', headerAlign: 'center', minWidth: 145 },
    {
      field: 'endDate', headerName: 'End Date', minWidth: 130, type: 'date', sortable: true, align: 'center', headerAlign: 'center',
      renderCell: ({ row }) => dayjs(row.endDate).format('DD MMM YYYY')
    },
    { field: 'bookingNo', headerName: 'Booking No.', align: 'center', headerAlign: 'center', minWidth: 125 },
    { field: 'staff', headerName: 'SalesPerson', align: 'center', headerAlign: 'center', minWidth: 120 },
    { field: 'newOrExistingCustomer', headerName: 'New/Existing Customer', align: 'center', headerAlign: 'center', minWidth: 195 },
    { field: 'customerType', headerName: 'Type', align: 'center', headerAlign: 'center', minWidth: 120 },
    {
      field: 'depositAmount', headerName: 'Deposit', align: 'center', headerAlign: 'center', minWidth: 100,
      renderCell: ({ row }) => Number(row.depositAmount).toFixed(2),
    },
    {
      field: 'rentalRate', headerName: 'Rental Rate', align: 'center', headerAlign: 'center', minWidth: 120,
      renderCell: ({ row }) => Number(row.rentalRate).toFixed(2),
    },
    { field: 'periodType', headerName: 'Period Type', align: 'center', headerAlign: 'center', minWidth: 120 },
    { field: 'billingType', headerName: 'Billing Type', align: 'center', headerAlign: 'center', minWidth: 120 },
    {
      field: 'osAmount', headerName: 'OS Amount', align: 'center', headerAlign: 'center', minWidth: 120,
      renderCell: ({ row }) => typeof row.osAmount !== 'undefined' ? Number(row.osAmount).toFixed(2) : '',
    },
    {
      field: 'totalUpfront', headerName: 'Total Upfront', align: 'center', headerAlign: 'center', minWidth: 130,
      renderCell: ({ row }) => Number(row.totalUpfront).toFixed(2)
    },
    { field: 'originalOtr', headerName: 'Original OTR', align: 'center', headerAlign: 'center', minWidth: 120 },
    { field: 'proposedOtr', headerName: 'Proposed OTR', align: 'center', headerAlign: 'center', minWidth: 140 },
    { field: 'proposeRemarks', headerName: 'Reason', align: 'center', headerAlign: 'center', minWidth: 150 },
    {
      field: 'otrStatus', headerName: 'OTR Status', align: 'center', headerAlign: 'center', sortable: false, minWidth: 150,
      renderCell: ({ row }) => capitalizeFirstLetter(row.otrStatus || '')
    },
    {
      field: '', headerName: 'Action', headerAlign: 'center', align: 'center', sortable: false, minWidth: 100,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="center" spacing={2}>
          <UndefinedViewAction row={row} />
        </Stack>
      )
    }
  ];

  if (isSalesperson) {
    undefinedColumns.push({
      field: 'action', headerName: 'For Salesperson', headerAlign: 'center', align: 'center', sortable: false, minWidth: 200,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="center" spacing={2}>
          <ProposeAction row={row} onReload={onReload} />
        </Stack>
      ),
    });
  }

  if (isManager) {
    undefinedColumns.push({
      field: 'managerAction',
      headerName: 'For Manager Only',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 250,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="center" spacing={2}>
          <ApproveAction row={row} onOpenSnackbar={onOpenSnackbar} onReload={onReload} />
          <RejectAction row={row} onReload={onReload} />
        </Stack>
      ),
    });
  }

  const checkIfNewCustomer = (hirerName, checkCustomers, currentBookingStartDate) => {
    if (checkCustomers[hirerName]) {
      const bookingsArray = Object.values(checkCustomers[hirerName]);

      const latestEndDate = bookingsArray.reduce((latestDate, booking) => {
        const endDate = dayjs(booking.endDate);
        return endDate > latestDate ? endDate : latestDate;
      }, dayjs('1970-01-01'));

      // Check if there is any booking within the past 28 days
      const daysDifference = dayjs(currentBookingStartDate).diff(latestEndDate, 'day');
      return daysDifference >= 28 || daysDifference < 0;
    }

    return true;
  };

  const getAllBookings = (longTermData, shortTermData, checkCustomersData) => {
    const longBookings = convertRawDataToCustomersObject(longTermData);
    const shortBookings = convertRawDataToCustomersObject(shortTermData);
    const checkCustomers = convertRawDataToCustomersObject(checkCustomersData);
    // checkCustomersData is all the booking contract which have the enddate in the past 7 months

    // for filtering booking with start date that is in the frequency of 3 months ago (1 year max)
    const startMonth = dayjs(startDate).month();
    const startYear = dayjs(startDate).year();
    const targetMonths = [startMonth];
    // Calculate the target months for the current year
    for (let i = 1; i <= 4; i++) {
      const nextMonth = (startMonth + 3 * i) % 12;
      const nextYear = startYear + Math.floor((startMonth + 3 * i) / 12);
      if (nextYear > startYear + 1 || (nextYear === startYear + 1 && nextMonth >= startMonth)) break; // Limit to one year max
      targetMonths.push(nextMonth);
    }

    const longTermRows = Object.values(longBookings).flatMap((customer) => Object.values(customer)
      .filter((booking) => booking.endDate >= dayjs(endDate) && booking.periodType === 'LongTerm' && targetMonths.includes(dayjs(booking.startDate).month()))
      .map((booking) => {
        const newCustomer = checkIfNewCustomer(booking.hirerName, checkCustomers, booking.startDate);

        const sameMonth = dayjs(startDate).format('MMM YYYY') === dayjs(booking.startDate).format('MMM YYYY');
        const lessThanThreeMonths = booking.numberofMonths < 3;

        let points = 500;
        if (!newCustomer && sameMonth && lessThanThreeMonths) {
          points = 0;
        }

        // filter out points with 0 or lesser
        if (points > 0) {
          return {
            rentalAgreementNo: booking.rentalAgreementNo,
            bookingNo: booking.bookingNo,
            hirerName: booking.hirerName,
            staff: booking.staff,
            startDate: booking.startDate,
            endDate: booking.endDate,
            customerType: booking.customerType,
            carPlate: booking.carPlate,
            carMake: booking.carMake,
            carModel: booking.carModel,
            purchaseType: booking.purchaseType,
            periodType: booking.periodType,
            depositAmount: Number(booking.depositAmount),
            rentalRate: Number(booking.rentalRate),
            billingType: booking.billingType,
            osAmount: Number(booking.osAmount),
            totalUpfront: Number(booking.totalUpfront),
            newOrExistingCustomer: newCustomer ? 'New' : 'Existing',
            points: points,
            contractType: 'LongTerm',
            originalOtr: points
          };
        }
        return null;
      })
      .filter((booking) => booking !== null));

    const shortTermRows = Object.values(shortBookings).flatMap((customer) => Object.values(customer)
      .filter((booking) => booking.startDate >= dayjs(startDate) && booking.periodType === 'ShortTerm' && booking.rentalRate > 0 && booking.rentalRate < 500 && booking.billingType !== 'Weekend')
      .map((booking) => {
        const points = Math.min(booking.totalUpfront - booking.depositAmount, 320);

        if (points > 0) {
          return {
            rentalAgreementNo: booking.rentalAgreementNo,
            bookingNo: booking.bookingNo,
            hirerName: booking.hirerName,
            staff: booking.staff,
            startDate: booking.startDate,
            endDate: booking.endDate,
            customerType: booking.customerType,
            carPlate: booking.carPlate,
            carMake: booking.carMake,
            carModel: booking.carModel,
            purchaseType: booking.purchaseType,
            periodType: booking.periodType,
            depositAmount: Number(booking.depositAmount),
            rentalRate: Number(booking.rentalRate),
            billingType: booking.billingType,
            osAmount: Number(booking.osAmount),
            totalUpfront: Number(booking.totalUpfront),
            newOrExistingCustomer: checkIfNewCustomer(booking.hirerName, checkCustomers, booking.startDate) ? 'New' : 'Existing',
            points: points,
            contractType: 'ShortTerm',
            originalOtr: points
          };
        }
        return null;
      })
      .filter((booking) => booking !== null));

    const undefinedRows = Object.values(shortBookings).flatMap((customer) => Object.values(customer)
      .filter((booking) => booking.startDate >= dayjs(startDate) && booking.periodType === 'ShortTerm' && (booking.rentalRate < 1 || booking.rentalRate >= 500 || booking.billingType === 'Weekend'))
      .map((booking) => {
        return {
          rentalAgreementNo: booking.rentalAgreementNo,
          bookingNo: booking.bookingNo,
          hirerName: booking.hirerName,
          staff: booking.staff,
          startDate: booking.startDate,
          endDate: booking.endDate,
          customerType: booking.customerType,
          carPlate: booking.carPlate,
          carMake: booking.carMake,
          carModel: booking.carModel,
          purchaseType: booking.purchaseType,
          periodType: booking.periodType,
          depositAmount: Number(booking.depositAmount),
          rentalRate: Number(booking.rentalRate),
          billingType: booking.billingType,
          osAmount: Number(booking.osAmount),
          totalUpfront: Number(booking.totalUpfront),
          newOrExistingCustomer: checkIfNewCustomer(booking.hirerName, checkCustomers, booking.startDate) ? 'New' : 'Existing',
          points: 0,
          contractType: 'Undefined',
          originalOtr: 0
        };
      }));

    const allRows = [...undefinedRows, ...shortTermRows, ...longTermRows];
    return allRows.map((row) => ({ ...row, otrStatus: 'pending' }));
  };

  const handleButtonClick = async ({ onOpenSnackbar }) => {
    setLoading(true);
    updateParentLoadingState(true);
    setDbLoading(true);

    console.log('fetching from database...');

    const rowsRRData = await fetchAllContracts();

    if (rowsRRData.length > 0) {
      try {
        await Promise.all(salespersonList.map(async (salesperson) => {
          const { alias, uid } = salesperson;
          const specificRows = rowsRRData.filter((booking) => booking.staff === alias);
          const data = {
            data: specificRows, sales: alias, userId: uid, startDate
          };

          await onPost(data);
        }));

        // give some buffer time (1 second), before fetching it
        setTimeout(async () => {
          await onGet({ startDate });
          setFetchFromDb(false);
          setDbLoading(false);
          onOpenSnackbar(`OTR fetched and update in Firestore successfully!`);
        }, 1000);
      } catch (error) {
        setDbLoading(false);
        updateParentLoadingState(false);
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
    }
  };

  const fetchAllContracts = async () => {
    try {
      const promises = [];
      for (let date = dayjs(startDate).subtract(1, 'year'); date < dayjs(endDate); date = date.add(3, 'month')) {
        console.log('date in fetchContract:', dayjs(date).format('YYYY-MM-DD'));
        promises.push(fetchEnhcApi('GetBookingsRentalAgreementExists', {
          StartDate: dayjs(date).format('YYYY-MM-DD'), EndDate: dayjs(date).add(1, 'month').format('YYYY-MM-DD')
        }));
      }

      const oneYearContract = await Promise.all(promises);

      const data = await fetchEnhcApi('GetBookingsRentalAgreementExistsEndDate', {
        // fetch the contract which have endddate falls from any months in the past 7 months,
        // since long term contract may go up to 6, 9, 12 months contract, but so far the longest contract I have seen is around 6 months
        // so fetching for the past 7 months should be fine (where I still want the system to be quick)
        StartDate: dayjs(startDate).add(-7, 'month').format('YYYY-MM-DD'), EndDate: dayjs(endDate).format('YYYY-MM-DD')
      });

      const subData = await fetchEnhcApi('GetBookingsRentalAgreementExists', {
        StartDate: startDate, EndDate: endDate
      });

      const result = getAllBookings(await Promise.all(oneYearContract.flat()), subData, data);
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    setShortTermRow([]);
    setLongTermRow([]);
    setUndefinedRow([]);
    setFetchFromDb(false);

    await onGet({ startDate });
  }, [startDate]);

  useEffect(() => {
    setLoading(true);
    updateParentLoadingState(true);
    setShortTermRow([]);
    setLongTermRow([]);
    setUndefinedRow([]);
    setRowsData([]);

    if (initialized) {
      // Using flatmap to combine all the data arrays into one main array
      if (data?.data && data.data.length > 0) {
        setFetchFromDb(false);

        const combinedData = data.data.flatMap((currentValue) => currentValue.data.map((item) => ({
          ...item, docId: currentValue.id, staffId: currentValue.userId
        })));
        setRowsData(combinedData);
        // console.log('combined data:', combinedData);
        onOpenSnackbar(`${dayjs(startDate).format('MMMM')} OTR Data loaded successfully!`);
      } else {
        setFetchFromDb(true);
        console.log('no records in firestore!');
        onOpenSnackbar(`No data for ${dayjs(startDate).format('MMMM')}`, 'error');
        setLoading(false);
        updateParentLoadingState(false);
      }
    }

    setInitialized(true);
  }, [data]);

  useEffect(() => {
    setLongTermRow(rowsData
      .filter((booking) => booking.contractType === 'LongTerm')
      .map((row, index) => ({ ...row, id: index })));
    setShortTermRow(rowsData
      .filter((booking) => booking.contractType === 'ShortTerm')
      .map((row, index) => ({ ...row, id: index })));
    setUndefinedRow(rowsData
      .filter((booking) => booking.contractType === 'Undefined')
      .map((row, index) => ({ ...row, id: index })));
    setLoading(false);
    updateParentLoadingState(false);
  }, [rowsData]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          {!fetchFromDb && !loading && (
            <span>
              {dayjs(startDate).format('MMMM YYYY')}{' '}
              {currentSalesPerson ? `Total OTR for ${currentSalesPerson}: ` : 'OTR'}
              {currentSalesPerson && (
                <strong>
                  {shortTermRow.filter((booking) => booking.staff === currentSalesPerson)
                    .reduce((total, row) => total + row.points, 0) + longTermRow
                    .filter((booking) => booking.staff === currentSalesPerson)
                    .reduce((total, row) => total + row.points, 0)}
                </strong>
              )}
            </span>
          )}
          {fetchFromDb && !dbLoading && (
            <Stack direction="column" justifyContent="space-between">
              <span>No {dayjs(startDate).format('MMMM YYYY')} OTR in Firestore!</span>
              <Typography variant="body2" maxWidth={800}>
                The "Fetch From RR And Update In Firestore" button can only be clicked for
                calculating for the current selected month OTR Only.
              </Typography>
            </Stack>)}
          {fetchFromDb && dbLoading && 'Fetching Data from RRDB, please wait patiently...'}
        </Typography>

        {fetchFromDb && (
          <Button
            variant="contained" disabled={dbLoading || !isManager}
            onClick={() => handleButtonClick({ onOpenSnackbar })}
          >
            Fetch from RR and Update in Firestore
          </Button>
        )}

        {isManager && !fetchFromDb && !loading && <ExportJson data={rowsData} label="OTR" />}
      </Stack>

      {currentSalesPerson && rowsData.length > 0 && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={3}>
            <Typography variant="h6" gutterBottom>
              Long Term Contract (&gt; 1 Month)
              {currentSalesPerson && !fetchFromDb && (<>
                  {loading ? '' : ` - OTR: ${longTermRow
                    .filter((booking) => booking.staff === currentSalesPerson)
                    .reduce((total, row) => {
                      return total + row.points;
                    }, 0)}`}
                </>)}
            </Typography>
            <OTRReportExport
              columns={otrColumns}
              undefinedColumns={otrUndefinedColumns}
              rows={rowsData}
              dayjs={dayjs}
              startDate={startDate}
              label="OTR"
            />
          </Stack>
          <FcTable
            rows={longTermRow
              .filter((booking) => booking.staff === currentSalesPerson)
              .map((row, index) => ({ ...row, no: index }))}
            columns={columns}
          />
          <Typography variant="h6" gutterBottom mt={3}>
            Short Term Contract
            {currentSalesPerson && !fetchFromDb && (<>
                {loading ? '' : ` - OTR: ${shortTermRow
                  .filter((booking) => booking.staff === currentSalesPerson)
                  .reduce((total, row) => {
                    return total + row.points;
                  }, 0)}`}
              </>)}
          </Typography>
          <FcTable
            rows={shortTermRow
              .filter((booking) => booking.staff === currentSalesPerson)
              .map((row, index) => ({ ...row, no: index }))}
            columns={columns}
          />

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={3}>
            <Typography variant="h6">Undefined Contract</Typography>
            <AddAction onReload={onReload} />
          </Stack>
          <FcTable
            rows={undefinedRow
              .filter((booking) => booking.staff === currentSalesPerson)
              .map((row, index) => ({ ...row, no: index }))}
            columns={undefinedColumns}
          />
        </>
      )}

      <Box mt={2}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItemText
            primary="Notes"
            primaryTypographyProps={{ fontSize: 14, fontWeight: 'bold' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Existing contracts are captured only if their start dates fall within the last 3, 6, or 9 months from the currently selected month.*"
            primaryTypographyProps={{ fontSize: 12, fontWeight: 'light' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="New customers are defined as those who have not made any bookings in the past 30 days.*"
            primaryTypographyProps={{ fontSize: 12, fontWeight: 'light' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Contracts will only be captured if they are neither voided nor cancelled.*"
            primaryTypographyProps={{ fontSize: 12, fontWeight: 'light' }}
          />
        </List>
      </Box>
    </>
  );
};

Commission.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  salespersonList: PropTypes.array,
  updateParentLoadingState: PropTypes.func,
  currentSalesPerson: PropTypes.string,
  dayjs: PropTypes.func,
  convertRawDataToCustomersObject: PropTypes.func,
  carData: PropTypes.array,
  currentUserDept: PropTypes.string
};

export default Commission;
