import plusFill from "@iconify/icons-eva/plus-fill";
import {Icon} from "@iconify/react";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import {Button, Container, Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {GridToolbar} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import PriceColumnForm from "../../../components/Form/Vehicle/PriceColumnForm";
import useHttpGet from "../../../hooks/http/useHttpGet";
import {StyledDataGrid} from "../styles";

const AddAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create Price Table Column</DialogTitle>
        <DialogContent>
          <PriceColumnForm onReload={onReload} onClose={() => setOpen(false)}/>
        </DialogContent>
      </Dialog>
      <Button
        variant="contained" startIcon={<Icon icon={plusFill}/>}
        onClick={() => setOpen(true)}
      >
        Add Column
      </Button>
    </>
  );
}

AddAction.propTypes = {
  onReload: PropTypes.func,
}

const EditAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Price Table Column</DialogTitle>
        <DialogContent>
          <PriceColumnForm
            data={row} onReload={onReload}
            onClose={() => setOpen(false)}
          />
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Edit
      </Button>
    </>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" spacing={1}>
      <EditAction row={row} onReload={onReload}/>
      <Button variant="contained" color="error">Delete</Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function PriceTableColumns() {
  const navigate = useNavigate();
  const { data, onGet } = useHttpGet("/api/vehicles/price-period");

  const rentalPeriods = {
    1: 'Daily',
    3: 'Weekend',
    7: 'Weekly',
    30: 'Monthly',
  };

  const paymentPeriods = {
    0: 'One time',
    3: 'Weekend',
    7: 'Weekly',
    30: 'Monthly',
  };

  const columns = [
    {
      field: "id", headerName: "No.", align: "center", headerAlign: "center", width: 50, sortable: false,
      valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
    },
    { field: "columnName", headerName: "Column Name", align: "center", headerAlign: "center", width: 225, sortable: false },
    {
      field: "minRentalPeriod", headerName: "Min Rental Period", align: "center", headerAlign: "center", width: 150, sortable: false,
      valueGetter: ({ value }) => value ? `${value} ${value > 1 ? "days" : "day"}` : ""
    },
    {
      field: "period", headerName: "Rental Period", align: "center", headerAlign: "center", width: 150, sortable: false,
      valueGetter: ({ value }) => rentalPeriods[value],
    },
    {
      field: "paymentPeriod", headerName: "Payment Period", align: "center", headerAlign: "center", width: 150, sortable: false,
      valueGetter: ({ value }) => paymentPeriods[value],
    },
    { field: "paymentMethod", headerName: "Payment Method", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "description", headerName: "Description", align: "center", headerAlign: "center", width: 440, sortable: false },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 200, sortable: false,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onGet}/>
    },
  ];

  return (
    <Container maxWidth="xl">
      <Typography
        sx={{ cursor: "pointer", marginBottom: 3 }} variant="h4" gutterBottom
        onClick={() => navigate("../")}
      >
        <span style={{position: "relative", top: 3}}>
          <ArrowBackSharpIcon />
        </span>
        &nbsp;&nbsp;
        Back To Table
      </Typography>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Pricing Period List</Typography>
        <AddAction onReload={onGet}/>
      </Stack>
      <StyledDataGrid
        component={{ Toolbar: GridToolbar }}
        columns={columns} rows={data ?? []}
        pageSize={100} rowsPerPageOptions={[100]}
        disableSelectionOnClick
        sx={{ height: window.innerHeight * 0.7 }}
      />
    </Container>
  );
}