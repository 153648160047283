import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportBookingForm = ({ userMap }) => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);
  const { onPost, loading } = useHttpPost("/api/booking/import");

  const columns = [
    { field: "bookingNo", headerName: "Booking No", width: 150 },
    { field: "startDate", headerName: "Start Date", width: 250 },
    { field: "endDate", headerName: "End Date", width: 250 },
    { field: "customerName", headerName: "Customer Name", width: 150 },
    {
      field: "userId", headerName: "User", width: 150,
      renderCell: ({ row }) => userMap[row.userId]?.name,
    },
    { field: "rentalUsage", headerName: "Rental Usage", width: 150 },
    { field: "periodType", headerName: "Period Type", width: 150 },
    { field: "bookingCost", headerName: "Booking Cost", width: 150 },
    { field: "deposit", headerName: "Deposit", width: 150 },
    { field: "availableDeposit", headerName: "Available Deposit", width: 150 },
    { field: "cdwPrice", headerName: "CDW Price", width: 150 },
    { field: "rentalPrice", headerName: "Rental Price", width: 150 },
    { field: "originalRentalPrice", headerName: "Original Rental Price", width: 150 },
    { field: "totalUpfront", headerName: "Total Upfront", width: 150 },
    { field: "totalReceivedAmount", headerName: "Total Received Amount", width: 150 },
    { field: "totalBillAmount", headerName: "Total Bill Amount", width: 150 },
    { field: "totalRefundAmount", headerName: "Total Refund Amount", width: 150 },
    { field: "totalCreditAmount", headerName: "Total Credit Amount", width: 150 },
    { field: "totalRefundCreditAmount", headerName: "Total Refund Deposit Amount", width: 220 },
    { field: "pricePeriodName", headerName: "Price Period Name", width: 150 },
    { field: "diff", headerName: "Diff", width: 150 },
    { field: "paymentMethod", headerName: "Payment Method", width: 150 },
    { field: "vehicleNumber", headerName: "Vehicle No.", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "remarks", headerName: "Remarks", width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    availableDeposit: row?.availableDeposit ? Number(row.availableDeposit) : 0,
    bookingCost: row?.bookingCost ? Number(row.bookingCost) : 0,
    cdwPrice: row?.cdwPrice ? Number(row.cdwPrice) : 0,
    deposit: row?.deposit ? Number(row.deposit) : 0,
    originalRentalPrice: row?.originalRentalPrice ? Number(row.originalRentalPrice) : 0,
    rentalPrice: row?.rentalPrice ? Number(row.rentalPrice) : 0,
    totalBillAmount: row?.totalBillAmount ? Number(row.totalBillAmount) : 0,
    totalCreditAmount: row?.totalCreditAmount ? Number(row.totalCreditAmount) : 0,
    totalReceivedAmount: row?.totalReceivedAmount ? Number(row.totalReceivedAmount) : 0,
    totalRefundAmount: row?.totalRefundAmount ? Number(row.totalRefundAmount) : 0,
    totalRefundCreditAmount: row?.totalRefundCreditAmount ? Number(row.totalRefundCreditAmount) : 0,
    totalUpfront: row?.totalUpfront ? Number(row.totalUpfront) : 0,
    startDate: row?.startDate ? dayjs(row.startDate).toISOString() : null,
    endDate: row?.endDate ? dayjs(row.endDate).toISOString() : null,
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs(row.updatedAt).toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data.filter(row => row.startDate && row.endDate));
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Bookings" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

ImportBookingForm.propTypes = {
  userMap: PropTypes.object,
}

export default ImportBookingForm;