import {Container, Stack, TextField, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from 'yup';
import {LoadingButton} from "@material-ui/lab";
import {useNavigate} from "react-router-dom";
import {fetchEnhcApi} from "../../../utils/fetchApi";

export default function NewDisposalStatus() {
  const navigate = useNavigate();

  const createDisposalStatus = async (values) => {
    try {
      await fetchEnhcApi("CreateDisposalStatus", values)
      navigate("../");
    } catch (error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      Status: "",
    },
    validationSchema: Yup.object().shape({
      Status: Yup.string().required("Please fill in the status"),
    }),
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(false);
      await createDisposalStatus(values);
      setSubmitting(true);
    }
  });

  const {errors, touched, handleSubmit, isSubmitting, getFieldProps} = formik;
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom>
        Add Disposal Status
      </Typography>

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              variant="filled" fullWidth
              label="Status"
              {...getFieldProps("Status")}
              error={Boolean(touched.Status && errors.Status)}
              helperText={touched.Status && errors.Status}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Add Status
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Container>
  );
}
