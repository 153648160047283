import {Checkbox} from "@material-ui/core";
import {LoadingButton} from "@material-ui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import palette from "../../../theme/palette";
import {AGREEMENT_PERMISSIONS} from "../../../utils/authorization/permissions/bookingPermission";
import {INVOICE_PERMISSIONS} from "../../../utils/authorization/permissions/invoicePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import {getDayjsValue} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";
import MultipleTypeInvoiceForm from "../../Form/Invoice/MultipleTypeInvoiceForm";
import AgreementAddendumTable from "../Addendum/AgreementAddendumTable";
import FcTable from "../FcTable";

const getDuration = (start, end) => dayjs(end).diff(start, "day");
const BookingDetails = ({ row }) => {
  const details = [
    { label: "Agreement No.", value: row?.agreementNo },
    { label: "Booking No.", value: row?.bookingNo },
    { label: "Start Date Time", value: getDayjsValue(row.startDateTime).format("DD MMM YYYY hh:mm A") },
    { label: "End Date", value: getDayjsValue(row.endDateTime).format("DD MMM YYYY") },
    { label: "Duration", value: `${getDuration(getDayjsValue(row.startDateTime).startOf("day"), getDayjsValue(row.endDateTime).startOf("day"))} days` },
    { label: "Rental Usage", value: row?.rentalUsage },
    { label: "Deposit", value: row?.deposit ? `S$${row.deposit}` : "No deposit" },
    { label: "Rental Rate", value: `S$${row?.rentalPrice}` },
  ];

  if (row.totalUpfront > 0 && row.rentalUsage === "Rental")
    details.push({ label: "Total Upfront", value: `S$${row?.totalUpfront}` });

  return (
    <Grid container spacing={3}>
      {details?.map(({ value, label }, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      {row.surcharges.length > 0 && <Grid item xs={12}>
        <Typography variant="h6">Surcharges</Typography>
        {row.surcharges.map((surcharge, index) => (
          <Box key={index}>
            {surcharge?.surchargeType?.name}: S${surcharge.amount}
          </Box>
        ))}
      </Grid>}
      {row?.remarks && <Grid item xs={12}>
        <Typography variant="h6">Remarks</Typography>
        {row.remarks}
      </Grid>}
    </Grid>
  );
}

BookingDetails.propTypes = {
  row: PropTypes.object,
}

const CustomerDetails = ({ row }) => {
  const details = [
    { label: "Name", value: row?.customerName },
    { label: "Customer Type", value: row?.customerType },
    { label: "Phone", value: row?.customerPhone },
    { label: "Email", value: row?.customerEmail },
    { label: "NRIC/FIN/ACRA", value: row?.customerIdentity },
  ];

  if (row?.customerType === "Individual") {
    details.push({ label: "Birth Date", value: getDayjsValue(row?.customerBirthDate).format("DD MMM YYYY") });
    details.push({
      label: "Driving License Effective Date",
      value: getDayjsValue(row?.customerDrivingLicenseEffectiveDate).format("DD MMM YYYY")
    });
  }

  return (
    <Grid container spacing={3}>
      {details?.map(({ value, label }, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12} sm={12} md={9}>
        <Typography variant="h6">Address</Typography>
        {row?.customerAddress}
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Typography variant="h6">Postal Code</Typography>
        {row?.customerPostal}
      </Grid>
    </Grid>
  );
}

CustomerDetails.propTypes = {
  row: PropTypes.object,
}

const VehicleDetails = ({ row }) => {
  const details = [
    { label: "Vehicle No.", value: row?.vehicleNumber },
    { label: "Make / Model", value: `${row?.vehicleMakeName} ${row?.vehicleModelName}, ${row?.vehiclePetrol}` },
    { label: "Color", value: row?.vehicleColor },
    { label: "Owner Company", value: row?.vehicleCompanyName },
    { label: "Current Mileage", value: row?.currentMileage },
    { label: "Next Service Mileage", value: row?.nextServiceMileage },
  ];

  const vehicleConditionImageUrl = `${process.env.REACT_APP_BACKEND_ENTRY}/api/file/getFile?objectName=${encodeURI(row?.vehicleConditionImage)}`;
  return (
    <Grid container spacing={3}>
      {details.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant="h6">Vehicle Conditions</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box component="img" src={vehicleConditionImageUrl} alt="vehicle-condition"/>
      </Grid>
      <Grid item xs={12} sm={6}>
        {row?.vehicleConditions?.map(({vehicleConditionTypeId, name, checked}, index) => (
          <Box key={index}>
            <FormControlLabel label={name} control={<Checkbox checked={checked} readOnly/>}/>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}

VehicleDetails.propTypes = {
  row: PropTypes.object,
}

const PriceDetails = ({ row }) => {
  const details = [
    { label: "Deposit", value: `S$${row?.deposit}` },
    { label: "Rental Price", value: `S$${row?.rentalPrice}` },
    { label: "Total Upfront", value: `S$${row?.totalUpfront}` },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Price Details</Typography>
      </Grid>
      {details.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant="h6">Surcharges</Typography>
        {row?.surcharges?.map((surcharge, index) => (
          <Box key={index}>
            {surcharge?.surchargeType?.name}: S${surcharge?.amount}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}

PriceDetails.propTypes = {
  row: PropTypes.object,
}

const AgreementDetails = ({ row }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Booking Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <BookingDetails row={row}/>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ border: 1, borderColor: palette.divider }}/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{ row?.customerType === "Individual" ? "Customer" : "Company" } Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomerDetails row={row}/>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ border: 1, borderColor: palette.divider }}/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Vehicle Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <VehicleDetails row={row}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" mb={2}>Sales Signature</Typography>
        <Box component="img" alt="sales-signature" border={1} borderRadius={2} src={getFile(row?.salesSignature)}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" mb={2}>Customer Signature</Typography>
        <Box component="img" alt="customer-signature" border={1} borderRadius={2} src={getFile(row?.customerSignature)}/>
      </Grid>
    </Grid>
  );
}

AgreementDetails.propTypes = {
  row: PropTypes.object,
}

export const PrintAgreementAction = ({ row }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const addendums = row?.addendums ? [...row?.addendums] : [];
  addendums?.sort((a, b) => dayjs(a.createdAt) - dayjs(b.createdAt));

  const { onPost, loading } = useHttpPost("/api/email");

  const attachments = [
    { filename: `${row.agreementNo}.pdf`, path: row.attachment },
    ...addendums.map(addendum => ({ filename: `${addendum.addendumNo}.pdf`, path: addendum.attachment })),
  ];
  if (row.agreementType === "Rental" && row.tncAttachment) {
    attachments.push({ filename: `${row.agreementNo}_TNC.pdf`, path: row.tncAttachment });
  }

  const onEmail = async e => {
    e.preventDefault();

    try {
      const emailToList = [{ email: row.customerEmail, name: row.customerName }];
      if (row.customerType === "Company") row.push({ email: row.companyEmail, name: row.companyName });

      await onPost({
        subject: `${row.agreementType} Agreement`,
        body: `This is your ${row.agreementType} Agreement`,
        emailFrom: {
          email: "noreply@freshcars.sg",
          name: "Fresh Cars Pte Ltd",
        },
        emailToList,
        attachments,
        isBodyHtml: false,
      });
      dataCtx.setSnackbarConfig({ open: true, message: "Email sent successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Print {row.agreementType} Agreement</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Typography variant="h6">{row.agreementType} Agreement</Typography>
            {row?.attachment && <iframe src={getFile(row.attachment)} width="100%" height={1000} title="Rental Agreement" name="Rental Agreement"/>}
            {row?.termsAndConditionsAttachment && (
              <>
                <Typography variant="h6">Terms and Conditions</Typography>
                <iframe src={getFile(row.termsAndConditionsAttachment)} width="100%" height={1000} title="Terms and Conditions" name="Terms and Conditions"/>
              </>
            )}
            {addendums?.length > 0 ? (
              <>
                <Typography variant="h6">Addendums</Typography>
                {addendums.map((addendum, index) => (
                  <iframe key={index} src={getFile(addendum.attachment)} width="100%" height={1000} title={addendum.addendumNo} name={addendum.addendumNo}/>
                ))}
              </>
            ) : ""}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" onClick={onEmail}>Email</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Print</Button>
    </>
  );
}

PrintAgreementAction.propTypes = {
  row: PropTypes.object,
}

const ViewAddendumsAction = ({ agreement, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{agreement.agreementNo} Addendums</DialogTitle>
        <DialogContent>
          <AgreementAddendumTable data={agreement?.addendums} onReload={onReload}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>Addendums</Button>
    </>
  );
}

ViewAddendumsAction.propTypes = {
  agreement: PropTypes.object,
  onReload: PropTypes.func,
}

const ViewAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row.agreementNo} Details</DialogTitle>
        <DialogContent>
          <AgreementDetails row={row}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Close</Button>
          <PrintAgreementAction row={row}/>
          <ViewAddendumsAction agreement={row} onReload={onReload}/>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);
  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/booking/agreement/${row.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Agreement deleted successfully!", severity: "success" });
      setOpen(false);
    } catch (e) {
      dataCtx.setSnackbarConfig({ open: true, message: e.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Do you want to delete {row.agreementNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const VoidAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading } = useHttpPut(`/api/agreement/${row.id}/void`);

  const onVoid = async e => {
    e.preventDefault();
    try {
      await onPut();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Agreement voided successfully!", severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Do you want to void {row?.agreementNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained" color="error"
            onClick={onVoid}
          >
            Void
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained" color="error"
        onClick={() => setOpen(true)}
        disabled={row.status === "Voided"}
      >
        Void
      </Button>
    </>
  );
}

VoidAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const CreateInvoiceAction = ({ booking, row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>Create Invoice</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <MultipleTypeInvoiceForm booking={booking} agreement={row} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Create Invoice</Button>
    </>
  );
}

CreateInvoiceAction.propTypes = {
  booking: PropTypes.object,
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ booking, row, onReload, enableDelete }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" spacing={1} justifyContent="center">
      {permissionRequired(authCtx, AGREEMENT_PERMISSIONS.VIEW) && <ViewAction row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, INVOICE_PERMISSIONS.CREATE) && <CreateInvoiceAction booking={booking} row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, AGREEMENT_PERMISSIONS.VOID) && enableDelete && <VoidAction row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, AGREEMENT_PERMISSIONS.DELETE) && enableDelete && <DeleteAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  booking: PropTypes.object,
  row: PropTypes.object,
  onReload: PropTypes.func,
  enableDelete: PropTypes.bool,
}

export default function BookingAgreementTable({ booking, data, onReload }) {
  const rows = data.filter(row => row.status === "Active");
  const lastAgreementId = rows[0]?.id;

  const columns = [
    { field: "id", headerName: "No.", align: "center", headerAlign: "center", showIndex: true, minWidth: 100, maxWidth: 100 },
    { field: "username", headerName: "Staff", align: "center", headerAlign: "center", minWidth: 200, maxWidth: 200 },
    { field: "agreementNo", headerName: "Agreement No.", align: "center", headerAlign: "center", minWidth: 150, maxWidth: 150 },
    { field: "agreementType", headerName: "Agreement Type", align: "center", headerAlign: "center", minWidth: 150, maxWidth: 150 },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", minWidth: 150, maxWidth: 150 },
    { field: "vehicleNumber", headerName: "Vehicle No.", align: "center", headerAlign: "center", minWidth: 150, maxWidth: 150 },
    {
      field: "startDateTime", headerName: "Start Date Time", align: "center", headerAlign: "center", minWidth: 200, maxWidth: 200,
      renderCell: ({ row }) => getDayjsValue(row.startDateTime).format("DD MMM YYYY hh:mm A")
    },
    {
      field: "endDateTime", headerName: "End Date", align: "center", headerAlign: "center", minWidth: 150, maxWidth: 150,
      renderCell: ({ row }) => getDayjsValue(row.endDateTime).format("DD MMM YYYY")
    },
    {
      field: "addendums", headerName: "Addendums", align: "center", headerAlign: "center", minWidth: 150, maxWidth: 150,
      renderCell: ({ row }) => row.addendums?.length
    },
    {
      field: "createdAt", headerName: "Create Date", align: "center", headerAlign: "center", minWidth: 150, maxWidth: 150,
      renderCell: ({ row }) => getDayjsValue(row.createdAt).format("DD MMM YYYY")
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", minWidth: 400, maxWidth: 400,
      renderCell: ({ row }) => (
        <ActionCol
          booking={booking} row={row}
          onReload={onReload}
          enableDelete={row.status === "Voided" || row.id === lastAgreementId}
        />
      )
    },
  ];

  return (
    <FcTable columns={columns} rows={data ?? []}/>
  );
}

BookingAgreementTable.propTypes = {
  booking: PropTypes.object,
  data: PropTypes.array,
  onReload: PropTypes.func,
}