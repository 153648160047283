import PropTypes from 'prop-types';
import { Backdrop, Box, Button, Fade, Modal } from '@mui/material';

/**
 * @param {{openState: boolean, closeConfirmation: boolean, closeHandler: Function, code: Function, modalBody: Node}} props
 */

export const PopupModal = (props) => {
  const style = {
    position: 'absolute',
    overflow: 'auto',
    maxHeight: window.innerHeight,
    maxWidth: 900,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth * 0.9,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };
  PopupModal.propTypes = {
    openState: PropTypes.bool,
    closeConfirmation: PropTypes.bool,
    closeHandler: PropTypes.func,
    code: PropTypes.func,
    modalBody: PropTypes.node
  };

  const popupCloseHandler = () => {
    if (props.closeConfirmation) {
      if (window.confirm('Are you sure you want to close this window?')) {
        props.closeHandler();
      }
    } else {
      props.closeHandler();
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.openState}
      onClose={popupCloseHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={props.openState}>
        <Box sx={style}>
          {props.code === undefined ? '' : props.code()}
          {props.modalBody}
          <br />
          <Button fullWidth variant="contained" onClick={popupCloseHandler}>
            Close
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};
