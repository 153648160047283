import {LoadingButton} from "@material-ui/lab";
import {Button, Grid, Stack} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect} from "react";
import * as Yup from "yup";
import {FcDateField, FcSelectField, FcTextField} from "../FormFields";

export default function InvoiceFilterForm({ onReload, paginationModel, sortModel }) {
  const statusOptions = ["Active", "Voided"].map(status => ({ label: status, value: status }));

  const formik= useFormik({
    initialValues: {
      bookingNo: "",
      invoiceNo: "",
      customerName: "",
      status: "",
      startDate: null,
      endDate: null,
    },
    validationSchema: Yup.object({
      bookingNo: Yup.string().nullable(),
      invoiceNo: Yup.string().nullable(),
      customerName: Yup.string().nullable(),
      status: Yup.string().nullable(),
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onReload({
        ...values, ...paginationModel,
        startDate: values?.startDate?.toISOString() ?? "",
        endDate: values?.endDate?.toISOString() ?? "",
      });
      setSubmitting(false);
    }
  });

  useEffect(() => {
    const { values } = formik;
    let model = {};
    if (sortModel.length > 0) [model] = sortModel;
    onReload({
      ...values, ...paginationModel, ...model,
      startDate: values?.startDate?.toISOString() ?? "",
      endDate: values?.endDate?.toISOString() ?? "",
    });
  }, [paginationModel, sortModel]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDate" label="Start Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDate" label="End Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="bookingNo" label="Booking No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="invoiceNo" label="Invoice No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerName" label="Customer Name"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="status" label="Status" items={statusOptions}/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={formik.resetForm}>
                Clear
              </Button>
              <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                Search
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

InvoiceFilterForm.propTypes = {
  onReload: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.object,
}