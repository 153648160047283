import dayjs from "dayjs";
import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportCustomerForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);

  const { onPost, loading } = useHttpPost("/api/customer/import");

  const columns = [
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'address', headerName: 'Address', width: 250 },
    { field: 'postal', headerName: 'Postal', width: 150 },
    { field: 'birthDate', headerName: 'Birth Date', width: 250 },
    { field: 'drivingLicenseEffectiveDate', headerName: 'DL Effective Date', width: 250 },
    { field: 'isNotBlacklisted', headerName: 'Not Blacklisted', width: 150 },
    { field: 'isIdentityVerified', headerName: 'NRIC Verified', width: 150 },
    { field: 'isDrivingLicenseVerified', headerName: 'DL Verified', width: 150 },
    { field: 'customerType', headerName: 'Customer Type', width: 150 },
    { field: 'identity', headerName: 'NRIC/ACRA', width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    birthDate: row?.birthDate ? dayjs(row.birthDate).toISOString() : null,
    drivingLicenseEffectiveDate: row?.drivingLicenseEffectiveDate ? dayjs(row.drivingLicenseEffectiveDate).toISOString() : null,
    isNotBlacklisted: row.isNotBlacklisted === "TRUE",
    isIdentityVerified: row.isIdentityVerified === "TRUE",
    isDrivingLicenseVerified: row.isDrivingLicenseVerified === "TRUE",
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs(row.updatedAt).toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Customer" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

export default ImportCustomerForm;