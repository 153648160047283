import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  DialogContentText,
  Divider,
  Grid,
  List,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Warning } from '@mui/icons-material';
import { LoadingButton } from '@material-ui/lab';
import Box from '@mui/material/Box';
import { GridToolbar } from '@mui/x-data-grid';

import UploadPriceTableForm from '../../Form/Sales/UploadPriceTableForm';
import ViewPriceTableForm from '../../Form/Sales/ViewPriceTableForm';
import ProposePriceIncreaseForm from '../../Form/Sales/PriceIncrease/ProposePriceIncreaseForm';
import RejectPriceIncreaseForm from '../../Form/Sales/PriceIncrease/RejectPriceIncreaseForm';
import CreateMissingPriceIncreaseForm from '../../Form/Sales/PriceIncrease/CreateMissingPriceIncreaseForm';
import { FcTextField, SubmitBtnGroup } from '../../Form/FormFields';

import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';

import { fetchEnhcApi } from '../../../utils/fetchApi';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';

import { StyledDataGrid } from '../../../pages/Vehicle/styles';
import DataContext from '../../../store/DataContext';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

import OTRReportExport from './OTRReportExport';
import { priceIncreaseColumns, priceIncreaseUndefinedColumns } from './ReportColumns';
import ExportJson from './ExportJson';

const PriceIncrease = ({
                         startDate,
                         endDate,
                         updateParentLoadingState,
                         currentSalesPerson,
                         dayjs,
                         convertRawDataToCustomersObject,
                         carData,
                         currentUserDept
                       }) => {
  const dataCtx = useContext(DataContext);

  const isManager = ['manager', 'superadmin'].includes(currentUserDept);
  const isSalesperson = ['sales', 'superadmin'].includes(currentUserDept);
  const isAccountOrManager = ['accounts', 'manager', 'superadmin'].includes(currentUserDept);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/otr/storePriceIncrease`);
  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/otr/priceIncrease`, true, {
    startDate
  });
  const { data: pricelist } = useCloudFuncGet(`${cloudFunctionPath}/otr/pricelist`);

  const [loading, setLoading] = useState(false);
  const [dbLoading, setDbLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [priceIncreaseRow, setPriceIncreaseRow] = useState([]);
  const [undefinedRow, setUndefinedRow] = useState([]);
  const [fetchFromDb, setFetchFromDb] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [oneYearPriceTable, setOneYearPriceTable] = useState([]);

  const onReload = async () => {
    await onGet({ startDate });
  };

  const AddPriceTableAction = () => {
    const [open, setOpen] = useState(false);

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="lg"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">Upload Price Table</DialogTitle>
            <DialogContent>
              <UploadPriceTableForm onClose={() => setOpen(false)} dayjs={dayjs} />
            </DialogContent>
          </Box>
        </Dialog>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          startIcon={<Icon icon={plusFill} />}
        >
          Upload Price Table
        </Button>
      </>);
  };

  const ViewPriceTableAction = () => {
    const [open, setOpen] = useState(false);

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="lg"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">View Price Table</DialogTitle>
            <DialogContent>
              <ViewPriceTableForm startDate={startDate} dayjs={dayjs} />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Button variant="contained" onClick={() => setOpen(true)}>
          View Price Table
        </Button>
      </>);
  };

  const AgreeAction = ({ row, onOpenSnackbar, onReload }) => {
    const [open, setOpen] = useState(false);

    const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/agreePriceIncrease`);

    const onAgree = async (e) => {
      e.preventDefault();
      try {
        await onPut({
          priceIncreaseId: row.docId, status: 1, rentalAgreementNo: row.rentalAgreementNo
        });
        await onReload();
        onOpenSnackbar('Price Increase agreed successfully!');
      } catch (error) {
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
    };

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">Agree with this Price Increase?</DialogTitle>
            <DialogContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Warning color="warning" sx={{ mr: 1 }} />
                <DialogContentText>
                  Warning: Once agreed, this step is irreversible.
                </DialogContentText>
              </Box>{' '}
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton variant="contained" onClick={onAgree} autoFocus loading={loading}>
                Agree
              </LoadingButton>
            </DialogActions>
          </Box>
        </Dialog>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          disabled={row.priceIncreaseStatus !== 'pending'}
        >
          Agree
        </Button>
      </>);
  };

  AgreeAction.propTypes = {
    row: PropTypes.object, onOpenSnackbar: PropTypes.func, onReload: PropTypes.func
  };

  const DisagreeAction = ({ row, onOpenSnackbar, onReload }) => {
    const [open, setOpen] = useState(false);

    const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/agreePriceIncrease`);

    const onDisagree = async (e) => {
      e.preventDefault();
      try {
        await onPut({
          priceIncreaseId: row.docId, status: 0, rentalAgreementNo: row.rentalAgreementNo
        });
        await onReload();
        onOpenSnackbar('Price Increase disagreed successfully!');
      } catch (error) {
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
    };
    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">Disagree with this Price Increase?</DialogTitle>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="error"
                onClick={onDisagree}
                autoFocus
                loading={loading}
              >
                Disagree
              </LoadingButton>
            </DialogActions>
          </Box>
        </Dialog>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpen(true)}
          disabled={row.priceIncreaseStatus !== 'pending'}
        >
          Disagree
        </Button>
      </>);
  };

  DisagreeAction.propTypes = {
    row: PropTypes.object, onOpenSnackbar: PropTypes.func, onReload: PropTypes.func
  };

  const ManagerDisagreeAction = ({ row, onReload }) => {
    const [open, setOpen] = useState(false);
    const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/agreePriceIncrease`);

    const onDisagree = async (values) => {
      try {
        const data = {
          ...values, priceIncreaseId: row.docId, status: 0, rentalAgreementNo: row.rentalAgreementNo
        };
        await onPut(data);
        await onReload();
        onOpenSnackbar('Price Increase disagreed successfully!');
        setOpen(false);
      } catch (error) {
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
    };

    const formik = useFormik({
      initialValues: {
        disagreeReason: ''
      }, validationSchema: Yup.object({
        disagreeReason: Yup.string().required('Please fill in the reason!')
      }), onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onDisagree(values);
        setSubmitting(false);
      }
    });

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">
              Do you want to DISAGREE with this Price Increase?
            </DialogTitle>
            <FormikProvider value={formik}>
              <Form autoComplete="off">
                <DialogContent>
                  <FcTextField
                    multiline
                    formik={formik}
                    name="disagreeReason"
                    label="Disagree Reason*"
                  />
                </DialogContent>
                <DialogActions>
                  <SubmitBtnGroup
                    formik={formik}
                    method="Disagree"
                    color="error"
                    onCancel={() => setOpen(false)}
                  />
                </DialogActions>
              </Form>
            </FormikProvider>
          </Box>
        </Dialog>
        <Button variant="contained" color="error" onClick={() => setOpen(true)}>
          Disagree
        </Button>
      </>);
  };
  ManagerDisagreeAction.propTypes = { row: PropTypes.object, onReload: PropTypes.func };

  const ProposeAction = ({ row, onReload }) => {
    const [open, setOpen] = useState(false);

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">Propose a new Price Increase Points?</DialogTitle>
            <DialogContent>
              <ProposePriceIncreaseForm
                onClose={() => setOpen(false)}
                onReload={onReload}
                row={row}
              />
            </DialogContent>
          </Box>
        </Dialog>
        <Button variant="contained" onClick={() => setOpen(true)} disabled={row.proposeDate}>
          Propose Price Increase
        </Button>
      </>);
  };

  ProposeAction.propTypes = { row: PropTypes.object, onReload: PropTypes.func };

  const ApproveAction = ({ row, onOpenSnackbar, onReload }) => {
    const [remarks, setRemarks] = useState('');
    const [open, setOpen] = useState(false);

    const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/approvePriceIncrease`);

    const onApprove = async (e) => {
      e.preventDefault();
      try {
        await onPut({
          priceIncreaseId: row.docId, status: 1, remarks, rentalAgreementNo: row.rentalAgreementNo
        });
        await onReload();
        onOpenSnackbar('Price Increase approved successfully!');
      } catch (error) {
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
    };

    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">
              Do you want to approve this Price Increase Request?
            </DialogTitle>
            <DialogContent>
              <Box py={1}>
                <TextField
                  fullWidth
                  multiline
                  label="Remarks"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton variant="contained" onClick={onApprove} autoFocus loading={loading}>
                Approve
              </LoadingButton>
            </DialogActions>
          </Box>
        </Dialog>
        <Button variant="contained" onClick={() => setOpen(true)} disabled={!row.proposeDate}>
          Approve
        </Button>
      </>);
  };

  ApproveAction.propTypes = {
    row: PropTypes.object, onOpenSnackbar: PropTypes.func, onReload: PropTypes.func
  };

  const RejectAction = ({ row, onReload }) => {
    const [open, setOpen] = useState(false);
    return (<>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ p: 3 }}>
            <DialogTitle id="alert-dialog-title">
              Do you want to reject this Price Increase request?
            </DialogTitle>
            <RejectPriceIncreaseForm onClose={() => setOpen(false)} onReload={onReload} row={row} />
          </Box>
        </Dialog>
        <Button
          disabled={!row.proposeDate}
          variant="contained"
          color="error"
          onClick={() => setOpen(true)}
        >
          Reject
        </Button>
      </>);
  };

  RejectAction.propTypes = { row: PropTypes.object, onReload: PropTypes.func };

  const ViewAction = ({ row }) => {
    const [open, setOpen] = useState(false);
    const vehicleDetails = [{ label: 'CarPlate', value: row?.carPlate }, {
      label: 'Car Make', value: row?.carMake
    }, { label: 'Car Model', value: row?.carModel }, { label: 'PARF/COE', value: row?.purchaseType }];

    const bookingDetails = [{ label: 'Rental Agreement No', value: row?.rentalAgreementNo }, {
      label: 'Booking No', value: row?.bookingNo
    }, { label: 'Start Date', value: dayjs(row?.startDate).format('DD MMM YYYY') }, {
      label: 'End Date', value: dayjs(row?.endDate).format('DD MMM YYYY')
    }, { label: 'Customer Name', value: row?.hirerName }, {
      label: 'Customer Type', value: capitalizeFirstLetter(row?.customerType)
    }, {
      label: 'New/Existing Customer', value: capitalizeFirstLetter(row?.newOrExistingCustomer)
    }, { label: 'Duration (Months)', value: row?.numberOfMonths }, { label: 'Billing Type', value: row?.billingType }, {
      label: 'Deposit (SGD)', value: row.depositAmount ? Number(row?.depositAmount).toFixed(0) : '-'
    }, { label: 'Rental Rate (SGD)', value: Number(row?.rentalRate).toFixed(0) }, {
      label: 'Base Rental Rate (SGD)', value: row?.baseRentalRate
    }, { label: 'Price Increase Per Day (SGD)', value: row?.priceIncreaseRounded }, {
      label: 'Points Per Day', value: row?.pointsPerDay
    }, { label: 'Points', value: row?.points }
      // { label: 'Doc Id', value: row?.docId } //debug use
    ];

    const priceIncreaseDetails = [{
      label: 'Price Increase Status', value: capitalizeFirstLetter(row?.priceIncreaseStatus)
    }];

    // if (row?.agreeDate) {
    //   priceIncreaseDetails.push({
    //     label: 'Agree/Disagree Date',
    //     value: dayjs(row?.agreeDate).format('DD MMM YYYY')
    //   });
    // }

    // if (row?.agreeBy) {
    //   priceIncreaseDetails.push({
    //     label: 'Agree/Disagree by',
    //     value: capitalizeFirstLetter(row.agreeBy)
    //   });
    // }

    if (row?.approveDate) {
      priceIncreaseDetails.push({
        label: 'Approved Date', value: dayjs(row?.approveDate).format('DD MMM YYYY')
      });
    }

    if (row?.approveRemarks) {
      priceIncreaseDetails.push({ label: 'Approved Reason', value: row.approveRemarks });
    }

    if (row?.addedBy) {
      priceIncreaseDetails.push({ label: 'Missing Price Increase Added by', value: row.addedBy });
    }

    return (<>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
          <Box p={2}>
            <DialogTitle variant="h5">Agreement Details</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                {bookingDetails?.map(({ label, value }, index) => (<Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogContent>
              <Grid container spacing={3}>
                {vehicleDetails?.map(({ label, value }, index) => (<Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogContent>
              <Grid container spacing={3}>
                {priceIncreaseDetails?.map(({ label, value }, index) => (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
          View
        </Button>
      </>);
  };

  ViewAction.propTypes = { row: PropTypes.object };

  const UndefinedViewAction = ({ row }) => {
    const [open, setOpen] = useState(false);

    const bookingDetails = [{ label: 'Rental Agreement No', value: row?.rentalAgreementNo }, {
      label: 'Booking No', value: row?.bookingNo
    }, { label: 'Start Date', value: dayjs(row?.startDate).format('DD MMM YYYY') }, {
      label: 'End Date', value: dayjs(row?.endDate).format('DD MMM YYYY')
    }, { label: 'Customer Name', value: row?.hirerName }, {
      label: 'Customer Type', value: capitalizeFirstLetter(row?.customerType)
    }, {
      label: 'New/Existing Customer', value: capitalizeFirstLetter(row?.newOrExistingCustomer)
    }, { label: 'Duration (Months)', value: row?.numberOfMonths }, { label: 'Billing Type', value: row?.billingType }, {
      label: 'Deposit (SGD)', value: row.depositAmount ? Number(row?.depositAmount).toFixed(0) : '-'
    }, // { label: 'Rental Rate (SGD)', value: Number(row?.rentalRate).toFixed(0) },
      // { label: 'Base Rental Rate (SGD)', value: row?.baseRentalRate },
      // { label: 'Price Increase Per Day (SGD)', value: row?.priceIncreaseRounded },
      // { label: 'Points Per Day', value: row?.pointsPerDay },
      // { label: 'Points', value: row?.points },
      { label: 'CarPlate', value: row?.carPlate }
      // { label: 'Doc Id', value: row?.docId } //debug use
    ];

    const priceIncreaseDetails = [{
      label: 'Price Increase Status', value: capitalizeFirstLetter(row?.priceIncreaseStatus)
    }];

    if (row?.originalRentalRate) {
      priceIncreaseDetails.push({
        label: 'Rental Rate (Original)', value: Number(row.originalRentalRate).toFixed(0)
      });
    }

    if (row?.originalBaseRentalRate) {
      priceIncreaseDetails.push({
        label: 'Base Rental Rate (Original)', value: row.originalBaseRentalRate
      });
    }

    if (row?.originalPriceIncrease) {
      priceIncreaseDetails.push({
        label: 'Price Increase Points (Original)', value: row.originalPriceIncrease
      });
    }

    if (row?.proposeRemarks) {
      priceIncreaseDetails.push({ label: 'Proposed Remarks', value: row.proposeRemarks });
    }

    if (row?.proposedRentalRate) {
      priceIncreaseDetails.push({ label: 'Rental Rate (Proposed)', value: row.proposedRentalRate });
    }

    if (row?.proposedBaseRentalRate) {
      priceIncreaseDetails.push({
        label: 'Base Rental Rate (Proposed)', value: row.proposedBaseRentalRate
      });
    }

    if (row?.proposedPriceIncreasePoints) {
      priceIncreaseDetails.push({
        label: 'Price Increase Points (Proposed)', value: row.proposedPriceIncreasePoints
      });
    }

    if (row?.proposeDate) {
      priceIncreaseDetails.push({
        label: 'Proposed Date', value: dayjs(row.proposeDate).format('DD MMM YYYY')
      });
    }

    // if (row?.approveDate) {
    //   priceIncreaseDetails.push({
    //     label: 'Approved/Rejected Date',
    //     value: dayjs(row?.approveDate).format('DD MMM YYYY')
    //   });
    // }

    if (row?.priceIncreaseStatus === 'rejected' && row?.approveDate) {
      priceIncreaseDetails.push({
        label: 'Rejected Date', value: dayjs(row?.approveDate).format('DD MMM YYYY')
      });
    }

    if (row?.priceIncreaseStatus === 'rejected' && row?.rejectReason) {
      priceIncreaseDetails.push({ label: 'Rejection Reason', value: row.rejectReason });
    }

    if (row?.proposeBy) {
      priceIncreaseDetails.push({ label: 'Proposed by', value: row.proposeBy });
    }

    if (row?.addedBy) {
      priceIncreaseDetails.push({ label: 'Missing Price Increase Added by', value: row.addedBy });
    }

    const disagreeDetails = [];

    if (row?.priceIncreaseStatus === 'disagree' && row?.agreeDate) {
      disagreeDetails.push({
        label: 'Disagree Date', value: dayjs(row.agreeDate).format('DD MMM YYYY')
      });
    }

    if (row?.priceIncreaseStatus === 'disagree' && row?.agreeBy) {
      disagreeDetails.push({ label: 'Disagree by', value: capitalizeFirstLetter(row.agreeBy) });
    }

    if (row?.priceIncreaseStatus === 'disagree' && row?.disagreeReason) {
      disagreeDetails.push({ label: 'Disagree Reason', value: row.disagreeReason });
    }

    return (<>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
          <Box p={2}>
            <DialogTitle variant="h5">Agreement Details</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                {bookingDetails?.map(({ label, value }, index) => (<Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogContent>
              <Grid container spacing={3}>
                {priceIncreaseDetails?.map(({ label, value }, index) => (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Typography variant="h6">{label}</Typography>
                    {value}
                  </Grid>))}
              </Grid>
            </DialogContent>
            {row.priceIncreaseStatus === 'disagree' && (<>
                <Divider />
                <DialogContent>
                  <Grid container spacing={3}>
                    {disagreeDetails?.map(({ label, value }, index) => (
                      <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                        <Typography variant="h6">{label}</Typography>
                        {value}
                      </Grid>))}
                  </Grid>
                </DialogContent>
              </>)}
            <DialogActions>
              <Button variant="contained" onClick={() => setOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
          View
        </Button>
      </>);
  };

  UndefinedViewAction.propTypes = { row: PropTypes.object };

  const AddAction = ({ onReload }) => {
    const [open, setOpen] = useState(false);
    return (<>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
          <Box p={2}>
            <DialogTitle>Add Missing Price Increase</DialogTitle>
            <DialogContent>
              <CreateMissingPriceIncreaseForm
                dayjs={dayjs}
                onClose={() => setOpen(false)}
                onReload={onReload}
                currentSalesPerson={currentSalesPerson}
                date={startDate}
                carData={carData}
              />
            </DialogContent>
          </Box>
        </Dialog>

        <Button variant="contained" onClick={() => setOpen(true)}>
          Add Missing Price Increase
        </Button>
      </>);
  };

  AddAction.propTypes = { onReload: PropTypes.func };

  // prettier-ignore
  const columns = [{
    field: 'id',
    headerName: 'No.',
    width: 10,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
  }, {
    field: 'bookingNo', headerName: 'Booking No.', align: 'center', headerAlign: 'center', width: 125
  }, { field: 'rentalAgreementNo', headerName: 'Agreement No.', align: 'center', headerAlign: 'center', width: 135 }, {
    field: 'startDate',
    headerName: 'Start Date',
    width: 130,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => dayjs(params.value).format('DD MMM YYYY')
  }, {
    field: 'endDate',
    headerName: 'End Date',
    width: 130,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => dayjs(params.value).format('DD MMM YYYY')
  }, // { field: 'periodType', headerName: 'Period Type', align: 'center', headerAlign: 'center', width: 100 },
    {
      field: 'staff', headerName: 'SalesPerson', align: 'center', headerAlign: 'center', width: 100
    }, {
      field: 'carPlate', headerName: 'Car Plate', align: 'center', headerAlign: 'center', width: 95
    }, {
      field: 'carMake', headerName: 'Car Make', align: 'center', headerAlign: 'center', width: 100
    }, {
      field: 'carModel', headerName: 'Car Model', align: 'center', headerAlign: 'center', width: 150
    }, { field: 'purchaseType', headerName: 'PARF/COE', align: 'center', headerAlign: 'center', width: 115 }, {
      field: 'newOrExistingCustomer',
      headerName: 'New/Existing Customer',
      align: 'center',
      headerAlign: 'center',
      minWidth: 195
    }, // { field: 'customerType', headerName: 'Type', align: 'center', headerAlign: 'center', width: 100 },
    {
      field: 'numberOfMonths', headerName: 'Duration (Months)', align: 'center', headerAlign: 'center', width: 135
    }, { field: 'billingType', headerName: 'Billing Type', align: 'center', headerAlign: 'center', width: 100 }, {
      field: 'depositAmount',
      headerName: 'Deposit',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      valueFormatter: (params) => params.value ? Number(params.value).toFixed(2) : '-'
    }, {
      field: 'rentalRate',
      headerName: 'Rental Rate',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      valueFormatter: (params) => Number(params.value).toFixed(0)
    }, // { field: 'previousRentalRate', headerName: 'Previous Rental Rate', align: 'center', headerAlign: 'center', width: 100, valueFormatter: (params) => Number(params.value).toFixed(2) },
    {
      field: 'baseRentalRate', headerName: 'Base Rental Rate', align: 'center', headerAlign: 'center', width: 130
    }, // { field: 'priceIncrease', headerName: 'Price Increase Per Day', align: 'center', headerAlign: 'center', width: 165 },
    {
      field: 'priceIncreaseRounded',
      headerName: 'Price Increase Per Day',
      align: 'center',
      headerAlign: 'center',
      width: 165
    }, // { field: 'priceIncreaseRounded', headerName: 'Adj.', align: 'center', headerAlign: 'center', width: 10 },
    {
      field: 'pointsPerDay', headerName: 'Points Per Day', align: 'center', headerAlign: 'center', width: 110
    }, {
      field: 'points', headerName: 'Points', align: 'center', headerAlign: 'center', width: 100
    }, { field: 'hirerName', headerName: 'Hirer', align: 'left', headerAlign: 'left', width: 300 }, {
      field: 'priceIncreaseStatus',
      headerName: 'Price Increase Status',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 180,
      valueFormatter: (params) => capitalizeFirstLetter(params.value || '')
    }, {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 100,
      renderCell: ({ row }) => (<Stack direction="row" justifyContent="center" spacing={2}>
        <ViewAction row={row} />
      </Stack>)
    }];

  if (isSalesperson) {
    columns.push({
      field: 'salespersonAction',
      headerName: 'Action For Salesperson',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 200,
      renderCell: ({ row }) => (<Stack direction="row" justifyContent="center" spacing={2}>
          <AgreeAction row={row} onOpenSnackbar={onOpenSnackbar} onReload={onReload} />
          <DisagreeAction row={row} onOpenSnackbar={onOpenSnackbar} onReload={onReload} />
        </Stack>)
    });
  }

  if (isAccountOrManager) {
    columns.push({
      field: '',
      headerName: 'For Manager/Accounts',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 200,
      renderCell: ({ row }) => (<Stack direction="row" justifyContent="center" spacing={2}>
          <ManagerDisagreeAction row={row} onReload={onReload} />
        </Stack>)
    });
  }

  // prettier-ignore
  const undefinedColumns = [{
    field: 'id',
    headerName: 'No.',
    width: 10,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
  }, {
    field: 'bookingNo', headerName: 'Booking No.', align: 'center', headerAlign: 'center', width: 140
  }, { field: 'rentalAgreementNo', headerName: 'Agreement No.', align: 'center', headerAlign: 'center', width: 140 }, {
    field: 'startDate',
    headerName: 'Start Date',
    width: 125,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => dayjs(params.value).format('DD MMM YYYY')
  }, {
    field: 'endDate',
    headerName: 'End Date',
    width: 125,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => dayjs(params.value).format('DD MMM YYYY')
  }, {
    field: 'staff', headerName: 'SalesPerson', align: 'center', headerAlign: 'center', width: 100
  }, { field: 'carPlate', headerName: 'Car Plate', align: 'center', headerAlign: 'center', width: 100 }, {
    field: 'newOrExistingCustomer',
    headerName: 'New/Existing Customer',
    align: 'center',
    headerAlign: 'center',
    minWidth: 195
  }, {
    field: 'numberOfMonths', headerName: 'Duration (Months)', align: 'center', headerAlign: 'center', width: 135
  }, { field: 'billingType', headerName: 'Billing Type', align: 'center', headerAlign: 'center', width: 100 }, {
    field: 'originalRentalRate',
    headerName: 'Original Rental Rate',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    valueFormatter: (params) => Number(params.value).toFixed(0)
  }, {
    field: 'originalBaseRentalRate',
    headerName: 'Original Base Rental Rate',
    align: 'center',
    headerAlign: 'center',
    width: 190
  }, {
    field: 'originalPriceIncrease',
    headerName: 'Original Price Increase Per Day',
    align: 'center',
    headerAlign: 'center',
    width: 240
  }, {
    field: 'originalPriceIncreasePoints',
    headerName: 'Original Points',
    align: 'center',
    headerAlign: 'center',
    width: 120
  }, { field: 'hirerName', headerName: 'Hirer', align: 'left', headerAlign: 'left', width: 300 }, {
    field: 'priceIncreaseStatus',
    headerName: 'Price Increase Status',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    minWidth: 180,
    valueFormatter: (params) => capitalizeFirstLetter(params.value || '')
  }, {
    field: 'proposedPriceIncreasePoints',
    headerName: 'Proposed Points',
    align: 'center',
    headerAlign: 'center',
    width: 140
  }, {
    field: 'proposedBaseRentalRate',
    headerName: 'Base Rental Rate (Proposed)',
    align: 'center',
    headerAlign: 'center',
    width: 230
  }, {
    field: 'proposedRentalRate',
    headerName: 'Rental Rate (Proposed)',
    align: 'center',
    headerAlign: 'center',
    width: 180
  }, {
    field: 'proposeRemarks',
    headerName: 'Reason',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    minWidth: 200,
    valueFormatter: (params) => capitalizeFirstLetter(params.value || '')
  }, {
    field: 'action',
    headerName: 'Action',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    minWidth: 100,
    renderCell: ({ row }) => (<Stack direction="row" justifyContent="center" spacing={2}>
      <UndefinedViewAction row={row} />
    </Stack>)
  }];

  if (isSalesperson) {
    undefinedColumns.push({
      field: 'salespersonAction',
      headerName: 'For Salesperson',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 250,
      renderCell: ({ row }) => (<Stack direction="row" justifyContent="center" spacing={2}>
          <ProposeAction row={row} onReload={onReload} />
        </Stack>)
    });
  }

  if (isManager) {
    undefinedColumns.push({
      field: 'managerAction',
      headerName: 'For Manager Only',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 250,
      renderCell: ({ row }) => (<Stack direction="row" justifyContent="center" spacing={2}>
          <ApproveAction row={row} onOpenSnackbar={onOpenSnackbar} onReload={onReload} />
          <RejectAction row={row} onReload={onReload} />
        </Stack>)
    });
  }

  const checkIfNewCustomer = (hirerName, checkCustomers, currentBookingStartDate, carPlate) => {
    if (checkCustomers[hirerName]) {
      const bookingsArray = Object.values(checkCustomers[hirerName]);

      const latestBooking = bookingsArray.reduce((latest, booking) => {
        const endDate = dayjs(booking.endDate);
        return endDate > latest.endDate ? { endDate, booking } : latest;
      }, { endDate: dayjs('1970-01-01'), booking: null });
      const daysDifference = dayjs(currentBookingStartDate).diff(latestBooking.endDate, 'day');

      const rentalRate = latestBooking.booking ? latestBooking.booking.rentalRate : null;

      // Check if there is any booking within the past 28 days
      const isNewCustomer = daysDifference >= 28 || daysDifference < 0;
      const isSameCar = carPlate === latestBooking.booking.carPlate;
      return {
        isNewCustomer, rentalRate: !isNewCustomer && isSameCar ? rentalRate : 0
      };
    }

    return { isNewCustomer: true, rentalRate: null };
  };

  const filteredData = (data, customerData) => {
    const bookings = convertRawDataToCustomersObject(data);
    const checkCustomers = convertRawDataToCustomersObject(customerData);

    const calculatePriceIncreasePerDay = (billingType, rentalRate, baseRentalRate) => {
      const diff = Number(rentalRate) - Number(baseRentalRate);

      return Number(billingType === 'Weekly' ? diff / 7 : diff / 30).toFixed(2);
    };

    // filter booking with start date that falls on the current month only
    const filteredBookings = Object.values(bookings).flatMap((customer) => Object.values(customer)
      .filter((booking) => booking.startDate >= dayjs(startDate).add(-3, 'month') && booking.periodType === 'LongTerm' && booking.purchaseType !== '')
      .map((booking) => {
        const {
          isNewCustomer,
          rentalRate
        } = checkIfNewCustomer(booking.hirerName, checkCustomers, booking.startDate, booking.carPlate);

        let bookingFound = false;
        let baseRentalRate = 0;
        let priceIncrease = 0;
        let priceIncreaseRounded = 0;
        let pointsPerDay = 0;
        let contractType = booking.periodType;

        oneYearPriceTable.forEach((priceTable) => {
          const dateFrom = dayjs(priceTable.effectiveDate).startOf('month');
          const dateTo = dayjs(priceTable.effectiveDate).endOf('month');

          if (dateFrom <= dayjs(booking.startDate) && dayjs(booking.startDate) <= dateTo) {
            bookingFound = true;

            // debug
            // console.log('booking id:', bookings[key].bookingNo);
            // console.log('currentcar:', bookings[key].carMake, bookings[key].carModel);
            // console.log('purchaser tyopecar:', bookings[key].purchaseType, bookings[key].billingType);

            // found out some bookings that have "" as purchaseType will resulted in reading undefined
            // e.g. bookingnumber BK0124000221
            const priceData = priceTable.priceTable[booking.carMake]?.[booking.carModel]?.[booking.purchaseType]?.[booking.petrolType];

            // console.log(`${booking.carMake} - ${booking.carModel}`);
            // console.log('PriceData:', priceData);

            if (priceData) {
              if (booking.billingType === 'Weekly') {
                baseRentalRate = booking.numberofMonths > 2 ? priceData.WeeklyAtLeast3Months : priceData.Weekly;
              } else {
                baseRentalRate = priceData[booking.billingType];
              }

              if (baseRentalRate === '-') {
                baseRentalRate = 0;
                contractType = 'Undefined';
              }
              // priceIncrease = Number(booking.rentalRate) - baseRentalRate;
              priceIncrease = calculatePriceIncreasePerDay(booking.billingType, Number(booking.rentalRate), baseRentalRate);

              if (priceIncrease > 0) {
                priceIncreaseRounded = 3 * Math.floor(priceIncrease / 3);
                pointsPerDay = priceIncreaseRounded / 3;
              }
            } else {
              contractType = 'Undefined';
            }
          }
        });

        if (!bookingFound) {
          baseRentalRate = 'Not Available';
          priceIncrease = 'Not Available';
          priceIncreaseRounded = '-';
          contractType = 'Undefined';
        }

        // Price Increase Points only applicable for the first 3 months of the contract
        const points = booking.rentalRate > rentalRate ? pointsPerDay * (Math.min(booking.numberofMonths, 3) * 30) : 'Not Eligible';

        return {
          rentalAgreementNo: booking.rentalAgreementNo,
          bookingNo: booking.bookingNo,
          hirerName: booking.hirerName,
          staff: booking.staff,
          startDate: booking.startDate,
          endDate: booking.endDate,
          customerType: booking.customerType,
          carPlate: booking.carPlate,
          carMake: booking.carMake,
          carModel: booking.carModel,
          petrolType: booking.petrolType,
          purchaseType: booking.purchaseType,
          newOrExistingCustomer: isNewCustomer ? 'New' : 'Existing',
          periodType: booking.periodType,
          depositAmount: Number(booking.depositAmount),
          rentalRate: Number(booking.rentalRate),
          billingType: booking.billingType,
          numberOfMonths: booking.numberofMonths,
          baseRentalRate,
          priceIncrease: Number(priceIncrease),
          priceIncreaseRounded,
          pointsPerDay,
          points,
          priceIncreaseStatus: 'pending',
          contractType,
          originalPriceIncreasePoints: points,
          originalBaseRentalRate: baseRentalRate,
          originalRentalRate: Number(booking.rentalRate)
        };
      }));

    return filteredBookings.filter((booking) => booking.points !== 'Not Eligible' && booking.points !== 0);
  };

  const handleButtonClick = async () => {
    setLoading(true);
    updateParentLoadingState(true);
    setDbLoading(true);

    const rowsRRData = await fetchData();

    if (rowsRRData.length > 0) {
      try {
        const data = { data: rowsRRData, startDate };
        await onPost(data);

        // give some buffer time (1 second), before fetching it
        setTimeout(async () => {
          await onGet({ startDate });
          setFetchFromDb(false);
          setDbLoading(false);
          onOpenSnackbar(`Price Increase fetched and update in Firestore successfully!`);
        }, 1000);
      } catch (error) {
        setDbLoading(false);
        updateParentLoadingState(false);
        onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
        console.log(error);
      }
    }
  };

  const fetchData = async () => {
    try {
      const customerData = await fetchEnhcApi('GetBookingsRentalAgreementExistsEndDate', {
        StartDate: dayjs(startDate).add(-10, 'month').format('YYYY-MM-DD'),
        EndDate: dayjs(endDate).add(-3, 'month').format('YYYY-MM-DD')
      });

      const data = await fetchEnhcApi('GetBookingsRentalAgreementExists', {
        StartDate: dayjs(startDate).add(-3, 'month').format('YYYY-MM-DD'),
        EndDate: dayjs(endDate).add(-3, 'month').format('YYYY-MM-DD')
      });

      return filteredData(data, customerData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    await onGet({ startDate });
  }, [startDate]);

  useEffect(async () => {
    setOneYearPriceTable(pricelist.data);
  }, [pricelist]);

  useEffect(() => {
    setLoading(true);
    updateParentLoadingState(true);
    setRowData([]);

    if (initialized) {
      if (data?.data && data.data.length > 0) {
        setFetchFromDb(false);
        const combinedData = data.data.map((item) => ({
          ...item, docId: data.id
        }));
        setRowData(combinedData);
        onOpenSnackbar(`${dayjs(startDate).format('MMMM')} Price Increase loaded successfully!`);
      } else {
        setFetchFromDb(true);
        console.log('no records in firestore!');
        onOpenSnackbar(`No Price Increase data for ${dayjs(startDate).format('MMMM')}`, 'error');
      }
      setLoading(false);
      updateParentLoadingState(false);
    }
    setInitialized(true);
  }, [data]);

  useEffect(() => {
    setPriceIncreaseRow(rowData
      .filter((booking) => booking.contractType !== 'Undefined')
      .map((row, index) => ({ ...row, id: index })));

    setUndefinedRow(rowData
      .filter((booking) => booking.contractType === 'Undefined')
      .map((row, index) => ({ ...row, id: index })));
  }, [rowData]);

  return (<>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          {!fetchFromDb && !loading && (<>
              {dayjs(startDate).format('MMMM YYYY')}{' '}
              {currentSalesPerson ? `Price Increase Points for ${currentSalesPerson}: ` : ''}
              {currentSalesPerson && (<strong>
                  {priceIncreaseRow
                    .filter((booking) => booking.staff === currentSalesPerson)
                    .reduce((total, row) => {
                      const points = parseInt(row.points, 10);
                      return total + (Number.isNaN(points) ? 0 : points);
                      // return total + row.points;
                    }, 0)}
                </strong>)}
            </>)}
          {fetchFromDb && !dbLoading && (<Stack direction="column" justifyContent="space-between" gap={1}>
              <span>No {dayjs(startDate).format('MMMM YYYY')} Price Increase in Firestore!</span>
              <Typography variant="body2" maxWidth={800}>
                The "Fetch From RR And Update In Firestore" button should only be clicked when
                calculating the current month's Price Increase. For instance, if this month is May,
                we are calculating for April's Price Increase.
              </Typography>
              <Typography variant="body2" maxWidth={800} fontWeight="bold">
                *Make sure to upload the current month's Price Table before fetching from RRDB.
              </Typography>
            </Stack>)}
          {fetchFromDb && dbLoading && 'Fetching Data from RRDB, please wait patiently...'}
        </Typography>

        {fetchFromDb && (<Stack gap={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <AddPriceTableAction />
              <ViewPriceTableAction />
            </Stack>
            <Button
              variant="contained"
              onClick={() => handleButtonClick({ onOpenSnackbar })}
              disabled={dbLoading || !isManager}
            >
              Fetch from RR and Update in Firestore
            </Button>
          </Stack>)}

        {isManager && !fetchFromDb && !loading && (<ExportJson data={rowData} label="Price Increase" />)}
      </Stack>
      {currentSalesPerson && rowData.length > 0 && (<>
          <Stack direction="row" alignItems="end" justifyContent="space-between" mb={2}>
            <Typography variant="h6" gutterBottom>
              Price Increase Points
            </Typography>

            <Stack direction="column" gap={2}>
              <AddPriceTableAction />
              <ViewPriceTableAction />
              <OTRReportExport
                columns={priceIncreaseColumns}
                rows={rowData}
                undefinedColumns={priceIncreaseUndefinedColumns}
                dayjs={dayjs}
                startDate={startDate}
                label="Price Increase"
              />
            </Stack>
          </Stack>
          <StyledDataGrid
            components={{ Toolbar: GridToolbar }}
            rows={priceIncreaseRow
              .filter((booking) => booking.staff === currentSalesPerson)
              .map((row, index) => ({ ...row, id: index }))}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[10]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'endDate', sort: 'desc' }]
              }
            }}
            autoHeight
            getRowClassName={(params) => Number(params.row.baseRentalRate) > Number(params.row.rentalRate) || Number(params.row.baseRentalRate) === 0 || params.row.baseRentalRate === 'Not Available' ? 'highlighted-row' : ''}
          />
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={3}>
            <Typography variant="h6" gutterBottom>
              Undefined Price Increase
            </Typography>
            <AddAction onReload={onReload} />
          </Stack>
          <StyledDataGrid
            components={{ Toolbar: GridToolbar }}
            rows={undefinedRow
              .filter((booking) => booking.staff === currentSalesPerson)
              .map((row, index) => ({ ...row, id: index }))}
            columns={undefinedColumns}
            pageSize={100}
            rowsPerPageOptions={[10]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'endDate', sort: 'desc' }]
              }
            }}
            autoHeight
            getRowClassName={(params) => Number(params.row.baseRentalRate) > Number(params.row.rentalRate) || Number(params.row.baseRentalRate) === 0 || params.row.baseRentalRate === 'Not Available' ? 'highlighted-row' : ''}
          />
        </>)}
      <Box mt={2}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItemText
            primary="Notes"
            primaryTypographyProps={{ fontSize: 14, fontWeight: 'bold' }}
          />
        </List>
        <ListItemText
          sx={{ display: 'list-item', ml: 3 }}
          primary="Regardless of whether the contract duration is six months, nine months, or twelve months, the price increase will only be applied for the first three months of the contract.*"
          primaryTypographyProps={{ fontSize: 12, fontWeight: 'light' }}
        />
        <ListItemText
          sx={{ display: 'list-item', ml: 3 }}
          primary="Contracts that involve the same hirer within a 28-day period from the last contract will be considered as renewal contracts.*"
          primaryTypographyProps={{ fontSize: 12, fontWeight: 'light' }}
        />
        <ListItemText
          sx={{ display: 'list-item', ml: 3 }}
          primary="Renewal contracts will only be eligible for a price increase if the existing customer is renting the same car at a higher rate or renting a different vehicle compared to the previous contract.*"
          primaryTypographyProps={{ fontSize: 12, fontWeight: 'light' }}
        />
      </Box>
    </>);
};

PriceIncrease.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  updateParentLoadingState: PropTypes.func,
  currentSalesPerson: PropTypes.string,
  dayjs: PropTypes.func,
  convertRawDataToCustomersObject: PropTypes.func,
  carData: PropTypes.array,
  currentUserDept: PropTypes.string
};

export default PriceIncrease;
