import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import DataContext from "../../../../store/DataContext";
import {paymentPeriodTypes, periodTypes} from "../../../../utils/date";
import CustomerDetails from "../../../Details/Customer/CustomerDetails";
import {FcCustomerDocument} from "../../../Document/Documents";

const BookingDetails = ({ formik, vehicle }) => {
  console.log(formik.values);

  const details = [
    { label: "Rental Period Type", value: periodTypes[formik.values.periodType] },
    { label: "Rental Usage", value: formik.values.rentalUsage },
    { label: "Include CDW", value: formik.values.cdwPrice > 0 ? "Yes" : "No"},
    { label: "Vehicle Number", value: vehicle?.vehicleNumber },
    { label: "Start Date", value: moment(formik.values.startDate).format("DD MMM YYYY") },
    { label: "End Date", value: moment(formik.values.endDate).format("DD MMM YYYY") },
    { label: "Make / Model", value: `${vehicle?.model?.make?.name} ${vehicle?.model?.name}, ${vehicle?.model?.petrolType}` },
    { label: "Vehicle Type", value: vehicle?.logcard?.vehicleType },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Booking Details</Typography>
      </Grid>
      {details.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      {formik.values.remarks && (
        <Grid item xs={12}>
          <Typography variant="h6">Booking Remarks</Typography>
          {formik.values.remarks}
        </Grid>
      )}
    </Grid>
  );
}

BookingDetails.propTypes = {
  formik: PropTypes.object,
  vehicle: PropTypes.object,
}

const SurchargesTable = ({ surcharges }) => {
  const { data: surchargeTypes } = useHttpGet("/api/surcharge/type");

  const getSurchargeName = (id) => surchargeTypes?.find(s => s.id === id)?.name;

  return (
    <Grid container spacing={1}>
      {surcharges.map((surcharge, index) => (
        <Grid key={index} item xs={12}>
          {getSurchargeName(surcharge.surchargeTypeId)}: S${surcharge.amount} {surcharge?.waiveReason && `(${surcharge.waiveReason})`}
        </Grid>
      ))}
    </Grid>
  );
}

SurchargesTable.propTypes = {
  surcharges: PropTypes.array,
}

const PriceDetails = ({ formik, vehicle, pricePeriod }) => {
  const getDuration = () => {
    const startDate = moment(formik.values.startDate);
    const endDate = moment(formik.values.endDate);
    return endDate.diff(startDate, 'day') / pricePeriod?.period;
  }

  const getPaymentDuration = () => {
    const startDate = moment(formik.values.startDate);
    const endDate = moment(formik.values.endDate);
    const paymentPeriod = pricePeriod?.paymentPeriod === 0 ? 1 : pricePeriod?.paymentPeriod;
    return endDate.diff(startDate, 'day') / paymentPeriod;
  }

  const durationFormat = () => {
    const duration = getDuration();
    const rentalPeriods = { 1: "Day", 3: "Weekend", 7: "Week", 30: "Month" };
    return `${duration.toFixed(2)} ${rentalPeriods[pricePeriod?.period]}${duration > 1 ? 's' : ''}`;
  }

  const paymentDurationFormat = () => {
    const duration = getPaymentDuration();
    return `${duration.toFixed(2)} ${paymentPeriodTypes[pricePeriod?.paymentPeriod]}${duration > 1 ? 's' : ''}`;
  }

  const totalRentalPrice = () => Math.ceil(getPaymentDuration() * formik.values.rentalPrice);

  const totalUpfrontFormat = () => {
    const surcharges = formik.values.surcharges.map(s => `S$${s.amount}`).join(" + ");
    const deposit = `S$${formik.values.deposit}`;
    const upfrontFormat = [];
    if (formik.values.deposit > 0) upfrontFormat.push(`${deposit} (Deposit)`);
    if (surcharges.length > 0) upfrontFormat.push(`${surcharges} (Surcharges)`);
    if (formik.values.paymentMethod === "Prepaid") {
      if (pricePeriod?.paymentPeriod === 0) upfrontFormat.push(`S$${totalRentalPrice()} (Rental)`);
      else upfrontFormat.push(`S$${formik.values.rentalPrice} (Rental)`);
    }
    return upfrontFormat.join(" + ");
  }

  const details = [
    { label: "Deposit", value: formik.values.deposit === 0 ? "No Deposit" : `S$${formik.values.deposit}` },
    { label: "CDW Price", value: formik.values.cdwPrice === 0 ? "No CDW" : `S$${formik.values.cdwPrice}` },
    { label: "Booking Deposit", value: `S$${formik.values.bookingCost}` },
    { label: "Price Model", value: `S$${formik.values.rentalPrice}, ${pricePeriod?.columnName}` },
    { label: "Make / Model", value: `${vehicle?.model?.make?.name} ${vehicle?.model?.name}, ${vehicle?.model?.petrolType}` },
    { label: "Duration", value: durationFormat() },
    { label: "Payment Method", value: formik.values.paymentMethod },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Price Details</Typography>
      </Grid>
      {details.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Rental Price Details</Typography>
        {paymentDurationFormat()} X S${formik.values.rentalPrice} = S${totalRentalPrice()}
      </Grid>
      {formik.values.surcharges.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" mb={1}>Surcharges</Typography>
          <SurchargesTable surcharges={formik.values.surcharges}/>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h6" mb={1}>Total Upfront</Typography>
        {totalUpfrontFormat()} = S${formik.values.totalUpfront.toFixed(0)}
      </Grid>
    </Grid>
  );
}

PriceDetails.propTypes = {
  formik: PropTypes.object,
  vehicle: PropTypes.object,
  pricePeriod: PropTypes.object,
}

const CustomerDocuments = ({ customer }) => {
  const [open, setOpen] = useState(false);
  const { data, onGet } = useHttpGet(`/api/customer/${customer?.id}/documents`);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{customer?.name} Documents</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {data?.map((document, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <Typography variant="h6">{document.label}</Typography>
                <FcCustomerDocument document={document} onReload={onGet}/>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Documents
      </Button>
    </>
  );
}

CustomerDocuments.propTypes = {
  customer: PropTypes.object,
}

export default function BookingSummaryQuestionForm({ formik, vehicle, pricePeriod }) {
  const dataCtx = useContext(DataContext);
  const { data: customer } = useHttpGet(`/api/customer/${formik.values.customerId}`);
  const { data: company } = useHttpGet(`/api/customer/${formik.values.companyId}`);

  const onCopyMessage = e => {
    e.preventDefault();
    const startDate = moment(formik.values.startDate).format("DD MMM YYYY");
    const endDate = moment(formik.values.endDate).format("DD MMM YYYY");

    let message = "*Booking*\n";
    message += `${company?.name ? company.name : customer?.name}\n`;
    message += `${vehicle?.model?.make?.name} ${vehicle?.model?.name} ${vehicle?.vehicleNumber}\n`;
    message += `Rental Period: ${startDate} - ${endDate}\n`;
    message += `Deposit: S$${formik.values.deposit}\n`;
    message += `Rental Rates: S$${formik.values.rentalPrice} / ${pricePeriod?.columnName}\n`;

    if (formik.values?.remarks) message += `Remarks: ${formik.values.remarks}`;
    navigator.clipboard.writeText(message);
    dataCtx.setSnackbarConfig({ open: true, message: "Booking Summary copied to your clipboard successfully!", severity: "success" });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">Booking Summary</Typography>
          <Button variant="contained" onClick={onCopyMessage}>Copy Message</Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {company && <Box border={1} borderRadius={2} p={3}>
          <Stack direction="row" justifyContent="space-between" mb={3}>
            <Typography variant="h6">Company Details</Typography>
            <CustomerDocuments customer={company}/>
          </Stack>
          <CustomerDetails data={company}/>
        </Box>}
      </Grid>
      <Grid item xs={12}>
        {customer && <Box border={1} borderRadius={2} p={3}>
          <Stack direction="row" justifyContent="space-between" mb={3}>
            <Typography variant="h6">Customer Details</Typography>
            <CustomerDocuments customer={customer}/>
          </Stack>
          <CustomerDetails data={customer}/>
        </Box>}
      </Grid>
      <Grid item xs={12}>
        <Box border={1} borderRadius={2} p={3}>
          <BookingDetails formik={formik} vehicle={vehicle}/>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box border={1} borderRadius={2} p={3}>
          <PriceDetails formik={formik} vehicle={vehicle} pricePeriod={pricePeriod}/>
        </Box>
      </Grid>
    </Grid>
  );
}

BookingSummaryQuestionForm.propTypes = {
  formik: PropTypes.object,
  vehicle: PropTypes.object,
  pricePeriod: PropTypes.object,
}