import {
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  IconButton,
  Modal,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useCallback, useContext, useEffect, useState } from 'react';
import { collection, deleteDoc, doc, getDocs, query, updateDoc } from 'firebase/firestore';
import Icon from '@iconify/react';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';

import DataTable from '../../components/DataTable';
import FirestoreContext from '../../store/FirestoreContext';
import FcAppNewBanner from './FcAppNewBanner';
import { PopupModal } from '../../components/PopupModal';
import { a11yProps, TabPanel } from '../../components/TabPanel';

let isMounted = true;

// Banner Edit Modal

export const BannerModal = (props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'scroll',
    p: 4
  };
  BannerModal.propTypes = {
    openState: PropTypes.bool,
    closeHandler: PropTypes.func,
    modalInfoHandler: PropTypes.object
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.openState}
      onClose={props.closeHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={props.openState}>
        <Box sx={style}>
          <FcAppNewBanner
            initialFormData={{
              category: props.modalInfoHandler.category,
              description: props.modalInfoHandler.description,
              fileName: props.modalInfoHandler.fileName,
              link: '',
              bannerUrl: props.modalInfoHandler.bannerUrl,
              linkText: '',
              linkTo: '',
              linkUrl: '',
              title: props.modalInfoHandler.bannerTitle
            }}
            bannerEditMode
            bannerId={props.modalInfoHandler.id}
            closeHandler={props.closeHandler}
          />

          <Button variant="contained" onClick={props.closeHandler}>
            Cancel
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

const FcAppBanners = () => {
  const fsCtx = useContext(FirestoreContext);
  const [banners, setBanners] = useState([
    {
      id: 1,
      image: 'Snow',
      fileName: '',
      bannerTitle: 'Promo Banner',
      category: 'In App Screen',
      description: 'desc here',
      link: 'lala',
      bannerUrl:
        'https://images.squarespace-cdn.com/content/v1/5d42855bbf340100016d6a6d/1565024553429-2UUUQ2PXQVXSMMMFXBN3/%22The+Original%22+Bouncy+Cheesecake'
    }
  ]);

  const publishedChangeHandler = async (bannerId, bannerCurrentPublishedStatus) => {
    try {
      await updateDoc(doc(fsCtx.fsObject, 'fcadmin/fcadmin/carouselBanners', bannerId), {
        published: !bannerCurrentPublishedStatus
      });
      setBanners((old) =>
        old.map((element) => {
          if (element.id === bannerId) {
            const tempObj = element;
            tempObj.published = !bannerCurrentPublishedStatus;
            return tempObj;
          }
          return element;
        })
      );
      alert(`The banner is now ${!bannerCurrentPublishedStatus ? 'online' : 'offline'}.`);
    } catch (error) {
      alert(`An error occurred! ${error.message}`);
    }
  };

  const fetchBanners = useCallback(async () => {
    const q = query(collection(fsCtx.fsObject, 'fcadmin/fcadmin/carouselBanners'));
    const querySnapshot = await getDocs(q);
    const bannersDocs = querySnapshot.docs;

    if (isMounted)
      setBanners(
        bannersDocs.map((doc) => ({
          id: doc.id,
          image: 'Snow',
          bannerTitle: doc.data().title,
          category: doc.data().category,
          description: decodeURIComponent(doc.data().description),
          fileName: doc.data().fileName,
          link: doc.data().link,
          bannerUrl: doc.data().bannerUrl,
          published: doc.data().published
        }))
      );
  }, [fsCtx.fsObject]);

  useEffect(() => {
    isMounted = true;
    fetchBanners();
    return () => {
      isMounted = false;
    };
  }, [fetchBanners]);

  const columns = [
    {
      field: 'id',
      headerName: 'Actions',
      width: 110,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => {
              handleOpen2(
                <FcAppNewBanner
                  initialFormData={{
                    category: params.row.category,
                    description: params.row.description,
                    fileName: params.row.fileName,
                    link: params.row.link,
                    bannerUrl: params.row.bannerUrl,
                    title: params.row.bannerTitle
                  }}
                  bannerEditMode
                  bannerId={params.value}
                  closeHandler={handleClose2}
                />
              );
            }}
          >
            <Icon icon={edit2Fill} height={20} />
          </IconButton>
          <IconButton
            color="error"
            onClick={async () => {
              if (
                window.confirm(
                  `Are you sure you want to delete this banner "${params.row.bannerTitle}"?`
                )
              ) {
                await deleteBannerHandler(params.value);
              }
            }}
          >
            <Icon icon={trash2Fill} height={20} />
          </IconButton>
          {/* <Button
            variant="contained"
            color="primary"
            // onClick={console.log(params)}
          >
            <Icon icon={edit2Fill} height={20} />
          </Button>
          <Button
            variant="contained"
            color="error"
            // onClick={console.log(params)}
          >
            <Icon icon={trash2Fill} height={20} />
          </Button> */}
        </>
      ),
      type: 'number'
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 200,
      renderCell: (params) => (
        <a href={params.row.bannerUrl} target="_blank" rel="noopener noreferrer">
          <img src={params.row.bannerUrl} alt="" />
        </a>
      ),
      type: 'date',
      sortable: true
    },
    {
      field: 'bannerTitle',
      headerName: 'Banner Title',
      width: 250,
      type: 'string',
      editable: false
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 150,
      type: 'string',
      editable: false
    },
    {
      field: 'link',
      headerName: 'External URL',
      width: 250,
      type: 'string',
      editable: false
    },
    {
      field: 'published',
      headerName: 'Published',
      width: 80,
      renderCell: (params) => (
        <Switch
          inputProps={{ 'aria-label': 'Switch demo' }}
          checked={params.row.published}
          onChange={() => publishedChangeHandler(params.row.id, params.row.published)}
        />
      ),
      editable: false
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      type: 'string',
      editable: false
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`
    // }
  ];

  const tableInitialSort = {
    sorting: {
      sortModel: [{ field: 'msgDate', sort: 'desc' }]
    }
  };

  // Tabs Panel Logic

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Banner Details modal logic
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    category: '',
    description: '',
    link: '',
    bannerUrl: '',
    linkText: '',
    linkTo: '',
    linkUrl: '',
    title: ''
  });
  const handleOpen = (bannerId) => {
    setOpen(true);
    setModalInfo(banners.find((element) => element.id === bannerId));
  };
  const handleClose = () => setOpen(false);

  // Message Details modal logic
  const [open2, setOpen2] = useState(false);
  const [modalInfo2, setModalInfo2] = useState();
  const handleOpen2 = (component) => {
    setOpen2(true);
    setModalInfo2(component);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setModalInfo2();
  };

  const deleteBannerHandler = async (bannerId) => {
    try {
      await deleteDoc(doc(fsCtx.fsObject, 'fcadmin/fcadmin/carouselBanners', bannerId));
      setBanners((oldBanners) => oldBanners.filter((el) => el.id !== bannerId));
    } catch (error) {
      console.log(`Error: ${error}`);
      alert(`Unable to delete: ${error}`);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ margin: 0 }}>
      <PopupModal
        openState={open2}
        closeHandler={handleClose2}
        modalBody={modalInfo2}
        closeConfirmation
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          App | Banners
        </Typography>
        <Button
          variant="contained"
          onClick={() =>
            handleOpen2(
              <FcAppNewBanner
                initialFormData={{
                  category: '',
                  description: '',
                  link: '',
                  bannerUrl: '',
                  title: ''
                }}
                bannerEditMode={false}
              />
            )
          }
        >
          New Banner
        </Button>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            // variant="fullWidth"
          >
            <Tab label="Banner List" {...a11yProps(0)} />
            <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <DataTable dataRows={banners} dataColumns={columns} tableInitialSort={tableInitialSort} />
          <BannerModal openState={open} closeHandler={handleClose} modalInfoHandler={modalInfo} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          hi
        </TabPanel>

        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </Container>
  );
};

export default FcAppBanners;
