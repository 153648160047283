import React, {useContext, useState} from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import moment from "moment";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@material-ui/lab";
import VehicleInsuranceForm from "../../components/Form/Vehicle/VehicleInsuranceForm";
import useHttpDelete from "../../hooks/http/useHttpDelete";
import useHttpGet from "../../hooks/http/useHttpGet";
import InsuranceTable from "../../components/Table/Vehicle/InsuranceTable";
import LogcardTable from "../../components/Table/Vehicle/LogcardTable";
import useHttpPut from "../../hooks/http/useHttpPut";
import AuthContext from "../../store/AuthContext";
import {
  VEHICLE_INSURANCE_PERMISSIONS, VEHICLE_LOGCARD_PERMISSIONS,
  VEHICLE_PERMISSIONS
} from "../../utils/authorization/permissions/vehiclePermissions";
import {permissionRequired} from "../../utils/authorization/roles";
import {getPdf} from "../../utils/getFile";
import VehicleLogcardForm from "../../components/Form/Vehicle/VehicleLogcardForm";
import DataContext from "../../store/DataContext";

// If needed can set it true
const show = true;

const dateFormat = (date, format = "DD MMM YYYY") => date ? moment(date).format(format) : "None";

const Details = ({ data }) => {
  const details = [
    { label: "Vehicle No.", value: data?.vehicleNumber },
    { label: "Make", value: data?.model?.make?.name },
    { label: "Model", value: `${data?.model?.name}` },
    { label: "Fuel Type", value: data?.model?.petrolType },
    { label: "Inspection Due Date", value: moment(data?.inspectionDueDate).format("DD MMM YYYY") },
    { label: "Road Tax Due Date", value: moment(data?.roadTaxDueDate).format("DD MMM YYYY") },
    { label: "Vehicle Color", value: data?.vehicleColor },
    { label: "Vehicle Shape", value: data?.vehicleShape },
  ];

  return (
    <Box mb={3} p={3} sx={{ border: 1, borderRadius: 2 }}>
      <Grid container spacing={3}>
        {details.map(({label, value}, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Typography variant="h6">{label}</Typography>
            {value}
          </Grid>
        ))}
        {data?.remarks && <Grid item xs={12}>
          <Typography variant="h6">Remarks</Typography>
          {data?.remarks}
        </Grid>}
      </Grid>
    </Box>
  );
}

Details.propTypes = {
  data: PropTypes.object,
}

const LogcardDetails = ({ data }) => {
  const details = [
    { label: "Registration Date", value: dateFormat(data?.registerDate) },
    { label: "Original Registration Date", value: dateFormat(data?.origRegisterDate) },
    { label: "Year Of Manufacture", value: dateFormat(data?.manufacturingYear, "YYYY") },
    { label: "Owner Name", value: data?.logcard?.vehicleOwner?.name ?? "None" },
    { label: "IU Label No.", value: data?.logcard?.iuNo ?? "None" },
    { label: "Chassis No.", value: data?.logcard?.chassisNo ?? "None" },
    { label: "Engine No.", value: data?.logcard?.engineNo ?? "None" },
    {
      label: "Logcard",
      value: data?.logcard?.logcard ? (
        <Link to={getPdf(data?.logcard?.logcard)} target="_blank">
          View File
        </Link>
      ) : "None"
    },
  ];

  return (
    <Box mb={3} p={3} sx={{ border: 1, borderRadius: 2 }}>
      <Grid container spacing={3}>
        {details.map(({ label, value }, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Typography variant="h6">{label}</Typography>
            {value}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

LogcardDetails.propTypes = {
  data: PropTypes.object,
}

const InsuranceDetails = ({ data }) => {
  const details = [
    { label: "Company Name", value: data?.company?.name },
    { label: "Start Date", value: dateFormat(data?.startDate) },
    { label: "End Date", value: dateFormat(data?.endDate) },
    { label: "Fleet / Individual", value: data?.isFleet ? "Fleet" : "Individual" },
    { label: "Insurance Type", value: data?.isZ10Z11 ? "Z10 / Z11" : "Other" },
    { label: "Min Age Coverage", value: data?.minAgeCoverage ?? "None" },
    { label: "Max Age Coverage", value: data?.maxAgeCoverage ?? "None" },
    {
      label: "Insurance CI",
      value: data?.insuranceCi ? (
        <Link to={getPdf(data?.insuranceCi)} target="_blank">
          View File
        </Link>
      ) : "None"
    },
  ];

  return (
    <Box mb={3} p={3} sx={{ border: 1, borderRadius: 2 }}>
      <Grid container spacing={3}>
        {details.map(({ label, value }, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Typography variant="h6">{label}</Typography>
            {value}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

InsuranceDetails.propTypes = {
  data: PropTypes.object,
}

const DeleteDialog = ({ vehicle, open, onClose }) => {
  const dataCtx = useContext(DataContext);
  const { onDelete, loading } = useHttpDelete(`/api/vehicles/${vehicle?.id}`);
  const navigate = useNavigate();

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onClick = async e => {
    e.preventDefault();
    try {
      await onDelete();
      onOpenSnackbar("Vehicle deleted successfully!");
      setTimeout(() => navigate("../"), 1000);
    } catch (error) {
      onOpenSnackbar(error.message, "error");
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <DialogTitle>Do you want delete {vehicle?.vehicleNumber}?</DialogTitle>
        <DialogActions>
          <Button onClick={onClose} type="submit" variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            variant="contained" color="error"
            loading={loading} onClick={onClick}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  vehicle: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

const AddLogcard = ({ action, onReload }) => {
  const dataCtx = useContext(DataContext);
  const { data: owners } = useHttpGet("/api/vehicles/owner");

  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  return (
    <>
      <Dialog
        open={open} onClose={() => setOpen(false)}
        fullWidth maxWidth="lg"
      >
        <DialogTitle>Add Logcard</DialogTitle>
        <DialogContent>
          <VehicleLogcardForm
            onReload={onReload}
            owners={owners} onClose={() => setOpen(false)}
          />
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {action}
      </Button>
    </>
  );
}

AddLogcard.propTypes = {
  action: PropTypes.string,
  onReload: PropTypes.func,
}

const AddInsurance = ({ action, onReload }) => {
  const dataCtx = useContext(DataContext);
  const { data: companies } = useHttpGet("/api/insurances/company");
  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  return (
    <>
      <Dialog
        open={open} onClose={() => setOpen(false)}
        fullWidth maxWidth="lg"
      >
        <DialogTitle>Add Insurance</DialogTitle>
        <DialogContent>
          <Box py={1}>
            <VehicleInsuranceForm
              onReload={onReload} companies={companies}
              onClose={() => setOpen(false)}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {action}
      </Button>
    </>
  );
}

AddInsurance.propTypes = {
  action: PropTypes.string,
  onReload: PropTypes.func,
}

const PublishAction = ({ data, onReload }) => {
  const dataCtx = useContext(DataContext);

  const { onPut, loading } = useHttpPut(`/api/vehicles/${data?.id}/status`);
  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onClick = async e => {
    e.preventDefault();
    try {
      await onPut({ vehicleStatus: "Available" });
      onOpenSnackbar("Vehicle published successfully!");
      await onReload();
    } catch (error) {
      onOpenSnackbar(e.message, "error");
    }
    setOpen(false);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 2 }}>
          <DialogTitle>Do you want to delete insurance?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpen(false)} type="submit" variant="outlined">
              Cancel
            </Button>
            <LoadingButton variant="contained" loading={loading} onClick={onClick}>
              Publish
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Publish
      </Button>
    </>
  );
}

PublishAction.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
}

export default function VehicleDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const { data, onGet } = useHttpGet(`/api/vehicles/${id}`);
  const { data: logcards, onGet: onReloadLogcards } = useHttpGet(`/api/vehicles/${id}/logcards`);
  const { data: insurances, onGet: onReloadInsurances } = useHttpGet(`/api/vehicles/${id}/insurances`);

  const [openDelete, setOpenDelete] = useState(false);

  return (
    <Container maxWidth="xl">
      <DeleteDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        vehicle={data}
      />

      <Typography
        sx={{ cursor: "pointer", marginBottom: 3 }} variant="h4" gutterBottom
        onClick={() => navigate("../")}
      >
        <span style={{position: "relative", top: 3}}>
          <ArrowBackSharpIcon />
        </span>
        &nbsp;&nbsp;
        Back To Listing
      </Typography>

      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>Vehicle Details</Typography>
        <Stack direction="row" spacing={2}>
          {data?.vehicleStatus === "Unpublished" && <PublishAction data={data} onReload={onGet}/>}
          {permissionRequired(authCtx, VEHICLE_PERMISSIONS.UPDATE) && <Button variant="contained" component={Link} to="./edit">
            Edit
          </Button>}
          {permissionRequired(authCtx, VEHICLE_PERMISSIONS.DELETE) && (
            <Button variant="contained" color="error" onClick={() => setOpenDelete(true)}>
              Delete
            </Button>
          )}
        </Stack>
      </Stack>

      <Details data={data}/>

      <Typography variant="h4" mb={3}>Logcard Details</Typography>
      <LogcardDetails data={data}/>

      <Stack direction="row" justifyContent="space-between" mb={3}>
        {data?.insurance ? <Typography variant="h4">Insurance Details</Typography> : <Box/>}
        {/* <AddInsurance action={data?.insurance ? "Renew Insurance" : "Add Insurance"} onReload={onGet}/> */}
      </Stack>
      {data?.insurance && <InsuranceDetails data={data?.insurance}/>}

      {show && (
        <Box mb={3}>
          <Stack direction="row" justifyContent="space-between" mb={3}>
            <Typography variant="h6" gutterBottom>Insurances</Typography>
            {permissionRequired(authCtx, VEHICLE_INSURANCE_PERMISSIONS.CREATE) && <AddInsurance action="Add Insurance" onReload={onReloadInsurances}/>}
          </Stack>
          <InsuranceTable data={insurances ?? []} onReload={onReloadInsurances}/>
        </Box>
      )}

      {show && (
        <Box mb={3}>
          <Stack direction="row" justifyContent="space-between" mb={3}>
            <Typography variant="h6" gutterBottom>
              Logcards
            </Typography>
            {permissionRequired(authCtx, VEHICLE_LOGCARD_PERMISSIONS.CREATE) && <AddLogcard action="Add Logcard" onReload={onReloadLogcards}/>}
          </Stack>
          <LogcardTable data={logcards ?? []} onReload={onReloadLogcards}/>
        </Box>
      )}
    </Container>
  );
}