import {getAuth} from "firebase/auth";
import { useState } from "react";
import { apiFuncFetch } from "../../../services/api";

/*
 * data: object
 * loading: boolean
 * error: string
 * onPost:
 * - body: object
 */
export default function useCloudFilePost(path) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onPost = async (body) => {
    setLoading(true);
    try {
      const token = await getAuth().currentUser.getIdToken();
      const res = await apiFuncFetch(path, {
        method: "POST",
        headers: {
          Accept: "application/json",
          token,
        },
        body
      });
      console.log("res:", res);
      setData(res);
    } catch (err) {
      setError(err.message);
      throw err;
    }
    setLoading(false);
  }

  return { data, loading, error, onPost };
}