import { Container, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton';
import WorkshopReturnJobForm from '../../../components/Form/Workshop/WorkshopReturnJobForm';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

export default function EditReturnJob() {
  const params = useParams();
  const { data } = useCloudFuncGet(`${cloudFunctionPath}/workshop/return_job/${params.id}`)
  return (
    <Container maxWidth="xl">
      <BackButton />

      <Typography variant="h4" gutterBottom mb={3}>
        Editing {data.vehicleNumber} Return Job
      </Typography>

      <WorkshopReturnJobForm data={data.data} />
    </Container>
  )
}