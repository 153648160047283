import plusFill from "@iconify/icons-eva/plus-fill";
import {Icon} from "@iconify/react";
import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import ImportVehicleForm from "../../../components/Form/Vehicle/ImportVehicleForm";
import VehicleFilterForm from "../../../components/Form/Vehicle/VehicleFilterForm";
import VehicleTable from "../../../components/Table/Vehicle/VehicleTable";
import useHttpPost from "../../../hooks/http/useHttpPost";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import {VEHICLE_PERMISSIONS} from "../../../utils/authorization/permissions/vehiclePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";

const ImportVehicleAction = ({ onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);

  const { onPost, loading } = useHttpPost("/api/vehicles/import");

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onPost(rows);
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "vehicles has been imported!", severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Import Vehicle</DialogTitle>
        <DialogContent>
          <ImportVehicleForm rows={rows} setRows={setRows}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>Submit</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Import Vehicles
      </Button>
    </>
  )
}

ImportVehicleAction.propTypes = {
  onReload: PropTypes.func,
}

export default function AvailableVehicles({ data, onReload }) {
  const authCtx = useContext(AuthContext);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Vehicle Listing</Typography>
        <Stack direction="row" spacing={2}>
          {permissionRequired(authCtx, VEHICLE_PERMISSIONS.CREATE) && <ImportVehicleAction onReload={onReload}/>}
          {permissionRequired(authCtx, VEHICLE_PERMISSIONS.CREATE) && (
            <Button
              component={Link} to="./new"
              startIcon={<Icon icon={plusFill}/>}
              variant="contained" color="secondary"
            >
              Add Vehicle
            </Button>
          )}
        </Stack>
      </Stack>
      <VehicleTable data={data ?? []}/>
    </>
  );
}

AvailableVehicles.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}