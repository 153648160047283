import {Typography} from "@mui/material";
import PropTypes from "prop-types";
import DisposalVehicleTable from "../../../components/Table/Vehicle/DisposalVehicleTable";

export default function DisposalVehicles({ data, onReload }) {
  return (
    <>
      <Typography variant="h4" mb={3}>Disposal Vehicles</Typography>
      <DisposalVehicleTable data={data ?? []}/>
    </>
  );
}

DisposalVehicles.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}