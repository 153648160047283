import {LoadingButton} from "@material-ui/lab";
import {Container, Stack, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import DisposalTaskCheckTable from "../../../components/Table/Vehicle/Disposal/DisposalTaskCheckTable";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPut from "../../../hooks/http/useHttpPut";
import {getDayjsValue} from "../../../utils/date";

export default function DisposeCheckList() {
  const { id } = useParams();
  const { data: vehicle, onGet: onVehicleGet } = useHttpGet(`/api/vehicles/${id}`);
  const { data, onGet } = useHttpGet(`/api/vehicles/disposal/${id}`);
  const { onPut, loading } = useHttpPut(`/api/vehicles/${id}/status`);

  const onSubmit = async e => {
    e.preventDefault();
    await onPut({ vehicleStatus: "Disposed" });
    await onVehicleGet();
  }

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Vehicle Dispose Check List</Typography>
        <LoadingButton
          loading={loading}
          variant="contained"
          disabled={vehicle?.vehicleStatus === "Disposed"}
          onClick={onSubmit}
        >
          Mark Disposed
        </LoadingButton>
      </Stack>

      <Stack spacing={3} mb={3}>
        <Typography variant="h5">Vehicle No.: {vehicle?.vehicleNumber}</Typography>
        <Typography variant="h6">COE End Date: {getDayjsValue(vehicle?.logcard?.coeExpiryDate).format("DD MMM YYYY")}</Typography>
        <Typography variant="h6">Disposal Due Date: {getDayjsValue(vehicle?.logcard?.coeExpiryDate).add(1, "month").add(-1, "day").format("DD MMM YYYY")}</Typography>
      </Stack>

      <DisposalTaskCheckTable data={data} onReload={onGet}/>
    </Container>
  );
}