import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import PropTypes from "prop-types";

export default function WorkshopVehicleDetails({ vehicle }) {
  const details = [
    { label: "Vehicle Number", value: vehicle?.vehicleNumber ?? "" },
    { label: "Make / Model", value: `${vehicle?.make ?? ""} ${vehicle?.model ?? ""}` },
    { label: "Fuel", value: vehicle?.fuel ?? "" },
    { label: "Color", value: vehicle?.color ?? "" },
    { label: "Registration Date", value: dayjs(vehicle?.registrationDate ?? 0).format("DD MMM YYYY") },
    { label: "COE Expiry Date", value: dayjs(vehicle?.coeExpiryDate ?? 0).format("DD MMM YYYY") },
    { label: "Sales Person", value: !vehicle?.salesPerson ? "N.A." : vehicle?.salesPerson }
  ];

  return (
    <Box p={2} sx={{ border: 1, borderRadius: 2 }}>
      <Grid container spacing={1}>
        {
          details.map((detail) => (
            <Grid key={detail.label} item xs={6} sm={6} md={4} lg={3}>
              <Typography variant="h6">{detail.label}</Typography>
              {detail.value}
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
}

WorkshopVehicleDetails.propTypes = {
  vehicle: PropTypes.object
}