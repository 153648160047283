import PropTypes from "prop-types";
import {Grid, Typography} from "@mui/material";
import {FcTextArea} from "../../FormFields";

export default function BookingRemarksQuestionForm({ formik }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Do you have any remarks need to fill in?</Typography>
      </Grid>
      <Grid item xs={12}>
        <FcTextArea formik={formik} label="Remarks" name="remarks"/>
      </Grid>
    </Grid>
  );
}

BookingRemarksQuestionForm.propTypes = {
  formik: PropTypes.object,
}