import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import JobAcc from './JobAcc';

export default function JobDateAcc({ data, showPrice, hideAction, hideChinese, onReload }) {
  return (
    [... new Set(data.map((doc) => doc.createDay))].sort((a, b) => b - a).map((createDay) => {
      const filtered = data.filter((doc) => doc.createDay === createDay);
      return (
        <Accordion key={createDay} elevation={10} defaultExpanded={new Date(new Date().toDateString()).getTime() === new Date(createDay).getTime()}>
          <AccordionSummary>
            <Grid container>
              { showPrice && (
                <Grid item minWidth={110}>
                  <Typography fontSize={18} fontWeight={700} textAlign="start">
                    ${filtered.reduce((x, y) => x + y.cost, 0).toFixed(2)}
                  </Typography>
                </Grid>
              )}

              <Grid item>
                <Typography fontSize={18} fontWeight={700} textAlign="start">
                  {dayjs(createDay).format("ddd, D MMM YYYY")}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails sx={{ marginTop: -1.5 }}>
            {
              filtered
              .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
              .map((job) => <JobAcc key={job.id} data={job} showPrice={showPrice}
                                    hideChinese={hideChinese} hideAction={hideAction} onReload={onReload}/>)
            }
          </AccordionDetails>
        </Accordion>
      );
    })
  );
}

JobDateAcc.propTypes = {
  data: PropTypes.array,
  showPrice: PropTypes.bool,
  hideAction: PropTypes.bool,
  hideChinese: PropTypes.bool,
  onReload: PropTypes.func
}