import { Box, Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import {GridToolbar} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import {COLLECTION_PERMISSIONS} from "../../../utils/authorization/permissions/invoicePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import {getOutstandingAmount} from "../../../utils/bookingAccounts";
import {getDayjsValue} from "../../../utils/date";
import MultipleTypeCollectionForm from "../../Form/Collection/MultipleTypeCollectionForm";

const NewCollectionAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>New Collection</DialogTitle>
        <DialogContent>
          <Box pt={1.5}>
            <MultipleTypeCollectionForm booking={row} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Collect</Button>
    </>
  );
}

NewCollectionAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      {permissionRequired(authCtx, COLLECTION_PERMISSIONS.CREATE) && <NewCollectionAction row={row} onReload={onReload}/>}
      <Button component={Link} to={`./${row.bookingNo}`} target="_blank" variant="contained" color="secondary">
        View
      </Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function BookingTable({ paginatedData, loading, onReload, sortModel, handlePageChange, handlePageSizeChange, handleSortModelChange }) {
  const columns = [
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>
    },
    { field: "bookingNo", headerName: "Booking No.", align: "center", headerAlign: "center", width: 150 },
    { field: "username", headerName: "Staff", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "customer.name", headerName: "Customer Name", headerAlign: "center", width: 200, sortable: false,
      renderCell: ({ row }) => row?.company?.name ?? row?.customer?.name,
    },
    {
      field: "customer.phone", headerName: "Customer Phone", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => row?.company?.phone ?? row?.customer?.phone,
    },
    {
      field: "customer.customerType", headerName: "Customer Type", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => row?.company?.customerType ?? row?.customer?.customerType,
    },
    {
      field: "vehicle.vehicleNumber", headerName: "Vehicle Number", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => row?.vehicle?.vehicleNumber,
    },
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center", width: 125,
      renderCell: ({ row }) => getDayjsValue(row.startDate).format("DD MMM YYYY"),
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center", width: 125,
      renderCell: ({ row }) => getDayjsValue(row.endDate).format("DD MMM YYYY"),
    },
    { field: "deposit", headerName: "Deposit", align: "center", headerAlign: "center", width: 100, sortable: false },
    { field: "bookingCost", headerName: "Booking Deposit", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "rentalPrice", headerName: "Rental Price", align: "center", headerAlign: "center", width: 100, sortable: false },
    {
      field: "pricePeriod.columnName", headerName: "Price Model", align: "center", headerAlign: "center", width: 225, sortable: false,
      renderCell: ({ row }) => row.pricePeriod.columnName,
    },
    { field: "rentalUsage", headerName: "Rental Usage", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 175, sortable: false },
    {
      field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 100, sortable: false,
      renderCell: ({ row }) => getOutstandingAmount(row),
    },
    {
      field: "createdAt", headerName: "Create Date", align: "center", headerAlign: "center", width: 125,
      renderCell: ({ row }) => getDayjsValue(row.createdAt).format("DD MMM YYYY"),
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      rows={paginatedData?.data ?? []}
      columns={columns}
      autoHeight
      disableSelectionOnClick
      loading={loading}
      rowCount={paginatedData?.totalRows ?? 0}
      pageSizeOptions={[25, 50, 100]}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      paginationMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      sortingMode="server"
    />
  );
}

BookingTable.propTypes = {
  paginatedData: PropTypes.object,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
  sortModel: PropTypes.object,
  handlePageChange: PropTypes.func,
  handlePageSizeChange: PropTypes.func,
  handleSortModelChange: PropTypes.func,
}