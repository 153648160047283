import {LoadingButton} from "@material-ui/lab";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpDelete from "../../hooks/http/useHttpDelete";
import useHttpGet from "../../hooks/http/useHttpGet";
import useImageGet from "../../hooks/http/useImageGet";
import useFileGet from "../../hooks/http/useFileGet";
import DataContext from "../../store/DataContext";

export const FcImageDocument = ({ objectName, label = "" }) => {
  const { data } = useImageGet(`/api/file/getFile`, true, { objectName });

  return (
    <Box component="img" alt={label} src={data ? URL.createObjectURL(data) : ""}/>
  );
}

FcImageDocument.propTypes = {
  objectName: PropTypes.string,
  label: PropTypes.string,
}

export const FcPdfDocument = ({ objectName, label = "View File" }) => {
  const { data } = useFileGet(`/api/file/getFile`, true, { objectName });

  return (
    <Link to={data ? URL.createObjectURL(data) : ""} target="_blank">{label}</Link>
  );
}

FcPdfDocument.propTypes = {
  objectName: PropTypes.string,
  label: PropTypes.string,
}

export const FcDocument = ({ data, label = "" }) => {
  if (data?.type?.includes("image")) return (
    <Box component="img" alt={label} src={data ? URL.createObjectURL(data) : ""}/>
  );

  return (
    <Link to={data ? URL.createObjectURL(data) : ""} target="_blank">{label}</Link>
  );
}

FcDocument.propTypes = {
  data: PropTypes.string,
  label: PropTypes.string,
}

export const FcCustomerDocument = ({ document, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const { onDelete, loading } = useHttpDelete(`/api/customer/${document?.customerId}/documents/${document.id}`);
  const { data } = useFileGet(`/api/file/getFile`, true, { objectName: document.objectName });

  const style = {
    position: "relative",
    left: "95%",
    top: 20,
    backgroundColor: "#D0D0D0",
    '&:hover': {
      backgroundColor: "red",
      color: "white"
    }
  };

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onSubmit = async e => {
    try {
      e.preventDefault();
      await onDelete();
      onOpenSnackbar("Document deleted successfully!");
      await onReload();
      setOpen(false);
    } catch (error) {
      onOpenSnackbar(error.message, "error");
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Do you want delete {document.label}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained" color="error"
            onClick={onSubmit} loading={loading}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {data?.type?.includes("image") && <IconButton sx={style} onClick={() => setOpen(true)}>
        <ClearIcon sx={{ fontSize: 28 }}/>
      </IconButton>}
      <Stack direction="row" spacing={3} alignItems="center">
        <FcDocument data={data} label={document.label}/>
        {!data?.type?.includes("image") && <Button variant="contained" color="error" onClick={() => setOpen(true)}>
          Delete
        </Button>}
      </Stack>
    </>
  );
}

FcCustomerDocument.propTypes = {
  document: PropTypes.object,
  onReload: PropTypes.func,
}

export const FcCustomerDocuments = ({ customer }) => {
  const [open, setOpen] = useState(false);
  const { data, onGet } = useHttpGet(`/api/customer/${customer?.id}/documents`);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{customer?.name} Documents</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {data?.map((document, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <Typography variant="h6">{document.label}</Typography>
                <FcCustomerDocument document={document} onReload={onGet}/>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Documents
      </Button>
    </>
  );
}

FcCustomerDocuments.propTypes = {
  customer: PropTypes.object,
}