import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useContext, useEffect} from "react";
import * as Yup from "yup";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import DataContext from "../../../store/DataContext";
import {FcSelectField, FcTextField, SubmitBtnGroup} from "../FormFields";

export default function SurchargeTypeForm({ data, onReload, onClose }) {
  const dataCtx = useContext(DataContext);
  const method = data ? "Update" : "Create";
  const currentYear = new Date().getFullYear();

  const { data: holidays } = useHttpGet("/api/leave/public-holiday");
  const { onPost } = useHttpPost("/api/surcharge/type");
  const { onPut } = useHttpPut(`/api/surcharge/type/${data?.id}`);

  const onCreate = async values => {
    await onPost(values);
    await onReload();
    onClose();
    dataCtx.setSnackbarConfig({ open: true, message: "Surcharge Type create successfully!", severity: "success" });
  }

  const onUpdate = async values => {
    await onPut({ ...values, id: data?.id });
    await onReload();
    onClose();
    dataCtx.setSnackbarConfig({ open: true, message: "Surcharge Type create successfully!", severity: "success" });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      price: 0,
      publicHolidayId: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please fill in surcharge type!"),
      price: Yup.number().required("Please fill in surcharge price!"),
      publicHolidayId: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onCreate(values);
        else await onUpdate(values);
      } catch (e) {
        dataCtx.setSnackbarConfig({ open: true, message: e.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("name", data?.name);
      formik.setFieldValue("price", data?.price);
      formik.setFieldValue("publicHolidayId", data?.publicHolidayId);
    }
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3} py={1}>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="name" label="Surcharge Type Name"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="price" label="Surcharge Price"/>
          </Grid>
          <Grid item xs={12}>
            <FcSelectField
              formik={formik} name="publicHolidayId" label="Public Holiday"
              items={holidays?.filter(holiday => new Date(holiday.startDate).getFullYear() === currentYear)
                ?.map(holiday => ({ label: holiday.title, value: holiday.id })) ?? []}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

SurchargeTypeForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}