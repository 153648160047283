import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { getDayjsValue } from "../../../utils/date";
import RecurringGenerateInvoiceTable from "../../Table/Invoice/RecurringGenerateInvoiceTable";
import RecurringInvoiceItemTable from "../../Table/Invoice/RecurringInvoiceItemTable";

export default function RecurringInvoiceDetails({ data }) {
  const weekValues = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const details = [
    { label: "Title", value: data?.title },
    { label: "Company", value: data?.vehicleCompany?.name },
    { label: "Agreement No.", value: data?.agreement?.agreementNo },
    { label: "Status", value: data?.status },
    { label: "Start Date", value: getDayjsValue(data?.startDate).format("DD MMM YYYY") },
    { label: "End Date", value: getDayjsValue(data?.endDate).format("DD MMM YYYY") },
    { label: "Last Issued Date", value: data?.lastIssuedDate ? getDayjsValue(data.lastIssuedDate).format("DD MMM YYYY") : "None" },
    { label: "Next Issue Date", value: data?.nextIssueDate ? getDayjsValue(data.nextIssueDate).format("DD MMM YYYY") : "None" },
    { label: "Total Amount", value: `S$${data?.totalAmount}` },
    { label: "Payment Type", value: data?.paymentType },
    { label: "Schedule Type", value: data?.scheduleType },
    { label: "Day/Date", value: data?.scheduleType === "Weekly" ? weekValues[data?.scheduleValue] : data?.scheduleValue },
  ];

  if (data?.remarks) {
    details.push({ label: "Remarks", value: data.remarks, props: { xs: 12 } });
  }

  return (
    <Grid container spacing={3}>
      {details.map(({ label, value, props = { xs: 12, sm: 6, md: 4, lg: 3 } }, index) => (
        <Grid item key={index} {...props}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant="h6" mb={3}>Invoice Items</Typography>
        <RecurringInvoiceItemTable data={data?.items} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" mb={3}>Invoices Generated</Typography>
        <RecurringGenerateInvoiceTable data={data?.invoices} />
      </Grid>
    </Grid>
  );
}

RecurringInvoiceDetails.propTypes = {
  data: PropTypes.object,
}