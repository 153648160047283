import { useState } from "react";
import { getAuth } from "firebase/auth";
import { apiFuncFetch } from "../../../services/api";

/*
 * data: object
 * loading: boolean
 * error: string
 * onPut:
 * - body: object
 */
export default function useCloudFuncPut(path) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onPut = async (body) => {
    setLoading(true);
    try {
      const token = await getAuth().currentUser.getIdToken();
      const res = await apiFuncFetch(path, {
        method: "PUT",
        headers: {
          token,
          Accept: "application/json",
          "Content-Type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(body)
      });
      setData(res);
    } catch (err) {
      setError(err.message);
      setLoading(false);
      throw err;
    }
    setLoading(false);
  }

  return { data, loading, error, onPut };
}