import {Grid} from "@mui/material";
import {useContext} from "react";
import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import DataContext from "../../../store/DataContext";
import {FcTextField, SubmitBtnGroup} from "../FormFields";

export default function VehicleConditionTypeForm({ data, onReload, onClose }) {
  const method = data ? "Update" : "Create";

  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/vehicles/condition/type");
  const { onPut } = useHttpPut(`/api/vehicles/condition/type/${data?.id}`);

  const formik = useFormik({
    initialValues: {
      name: data?.name ?? "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onPost(values);
        else await onPut(values);
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: `${method}d Successfully!`, severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcTextField formik={formik} label="Name" name="name"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

VehicleConditionTypeForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}