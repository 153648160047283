import {Box, Button, Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useState} from "react";
import BookingVehicleDetails from "../../../../components/Details/Vehicle/BookingVehicleDetails";
import ChangeOfVehicleForm from "../../../../components/Form/Booking/ChangeOfVehicle/ChangeOfVehicleForm";
import BookingVehicleUsageRecordTable from "../../../../components/Table/Booking/BookingVehicleUsageRecordTable";

const CreateReplacementVehicleAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Add Replacement Vehicle</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <ChangeOfVehicleForm type="Replacement" booking={booking} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Add Replacement Vehicle</Button>
    </>
  );
}

CreateReplacementVehicleAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const ChangeNewVehicleAction = ({ disabled, booking, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Change Vehicle</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <ChangeOfVehicleForm booking={booking} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)} disabled={disabled}>Change Vehicle</Button>
    </>
  );
}

ChangeNewVehicleAction.propTypes = {
  disabled: PropTypes.bool,
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const getLastRecord = (records) => {
  const activeRecords = records?.filter(r => r.rentalPrice > 0);
  // activeRecords?.sort((a, b) => dayjs(b.startDate) - dayjs(a.startDate));
  return activeRecords?.length > 0 ? activeRecords[0] : null;
}

const getLastRentalAgreement = (agreements) => {
  const activeAgreements = agreements
    ?.filter(a => a.status === "Active")
    ?.filter(a => a.agreementType === "Rental");
  // activeAgreements?.sort((a, b) => dayjs(b.startDateTime) - dayjs(a.startDateTime));
  return activeAgreements?.length > 0 ? activeAgreements[0] : null;
}

export default function VehicleDetailsAccordion({ booking, agreements, onReload }) {
  booking?.vehicleUsageRecords?.sort((a, b) => dayjs(b.endDate) - dayjs(a.endDate));
  agreements?.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt));
  const lastRecord = getLastRecord(booking?.vehicleUsageRecords ?? []);
  const lastRentalAgreement = getLastRentalAgreement(agreements);

  const deletableRecord = (record) => {
    if (record?.rentalPrice === 0) {
      const rentalAgreements = agreements
        ?.filter(agreement => agreement.status === "Active")
        ?.filter(agreement => agreement.agreementType === "Rental") ?? [];
      let addendums = [];
      rentalAgreements?.forEach(agreement => {
        addendums = addendums.concat(agreement.addendums.filter(addendum => addendum.addendumType === "Handover Replacement Vehicle"));
      });
      addendums?.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt));
      const addendum = addendums?.length > 0 ? addendums[0] : null;

      return addendum === null || addendum?.vehicleId !== record?.vehicleId;
    }
    return lastRecord.id === record.id && lastRentalAgreement?.vehicleId !== lastRecord.vehicleId;
  }

  const returnableRecord = (record) => {
    const rentalAgreements = agreements ? [
      ...agreements
        ?.filter(agreement => agreement.status === "Active")
        ?.filter(agreement => agreement.agreementType === "Rental")
    ] : [];
    rentalAgreements.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt));
    const rentalAgreement = rentalAgreements.length > 0 ? rentalAgreements[0] : null;

    const addendums = rentalAgreement ? [...rentalAgreement?.addendums] : [];
    addendums.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt));
    const handoverAddendums = addendums
      ?.filter(addendum => addendum.addendumType === "Handover Replacement Vehicle" && addendum.status === "Active");
    const returnAddendums = addendums
      ?.filter(addendum => addendum.addendumType === "Return Replacement Vehicle" && addendum.status === "Active");

    const handoverAddendum = handoverAddendums.length > 0 ? handoverAddendums[0] : null;
    const returnAddendum = returnAddendums.length > 0 ? returnAddendums[0] : null;

    return record.vehicleId === handoverAddendum?.vehicleId &&
      (returnAddendum === null || handoverAddendum?.vehicleId !== returnAddendum?.vehicleId);
  }

  const records = booking?.vehicleUsageRecords
    ?.filter(record => record.rentalPrice > 0)
    ?.map(record => ({
      ...record,
      deletable: deletableRecord(record),
      returnable: false,
    })) ?? [];

  const disabledChangeNewVehicleAction = records.some(record => record.deletable);
  const replacementRecords = booking?.vehicleUsageRecords
    ?.filter(record => record.rentalPrice === 0)
    ?.map(record => ({
      ...record,
      deletable: deletableRecord(record),
      returnable: returnableRecord(record),
    })) ?? [];

  return (
    <Box>
      <Typography variant="h5" mb={3}>Current Vehicle Details</Typography>
      <BookingVehicleDetails vehicle={booking?.vehicle}/>
      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="h5">Vehicles Usage Records</Typography>
        <ChangeNewVehicleAction disabled={disabledChangeNewVehicleAction} booking={booking} onReload={onReload}/>
      </Stack>
      <BookingVehicleUsageRecordTable booking={booking} agreements={agreements} data={records} onReload={onReload}/>
      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="h5">Replacement Vehicles Usage Records</Typography>
        <CreateReplacementVehicleAction disabled={disabledChangeNewVehicleAction} booking={booking} onReload={onReload}/>
      </Stack>
      <BookingVehicleUsageRecordTable
        booking={booking} agreements={agreements}
        data={replacementRecords} onReload={onReload} type="replacement"
      />
    </Box>
  );
}

VehicleDetailsAccordion.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  onReload: PropTypes.func,
}