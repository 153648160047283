import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useContext, useEffect} from "react";
import * as Yup from "yup";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import DataContext from "../../../store/DataContext";
import {FcTextField, SubmitBtnGroup} from "../FormFields";

export default function ShellDiscountForm({ data, onReload }) {
  const dataCtx = useContext(DataContext);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/discount/default`);

  const formik = useFormik({
    initialValues: {
      rate: 0,
    },
    validationSchema: Yup.object().shape({
      rate: Yup.number().required("Default discount rate is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost(values);
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Discount rate updated successfully!", severity: "success" });
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data?.data) {
      formik.setFieldValue("rate", data.data?.defaultRate ?? 0);
    }
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Discount default rate (%)" name="rate" type="number"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Update" onCancel={formik.resetForm} cancelText="Clear"/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

ShellDiscountForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
}