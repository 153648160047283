import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Box, Container, Tab} from "@mui/material";
import {useState} from "react";
import ShellCardAssignRecordTab from "./Tabs/ShellCardAssignRecordTab";
import ShellCardDiscountTab from "./Tabs/ShellCardDiscountTab";
import ShellCardListingTab from "./Tabs/ShellCardListingTab";
import ShellRefuelTransactionListingTab from "./Tabs/ShellRefuelTransactionListingTab";
import ShellTransactionListingTab from "./Tabs/ShellTransactionListingTab";

export default function ShellCard() {
  const [tabValue, setTabValue] = useState("card");

  return (
    <Container maxWidth="xl">
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="Card" value="card"/>
            <Tab label="Card Assign Record" value="card-assign-record"/>
            <Tab label="Transaction" value="transaction"/>
            <Tab label="Discount" value="discount"/>
            <Tab label="Refuel Transaction (Shell)" value="refuel-transaction"/>
          </TabList>
        </Box>

        <TabPanel value="card">
          <ShellCardListingTab/>
        </TabPanel>
        <TabPanel value="card-assign-record">
          <ShellCardAssignRecordTab/>
        </TabPanel>
        <TabPanel value="transaction">
          <ShellTransactionListingTab/>
        </TabPanel>
        <TabPanel value="discount">
          <ShellCardDiscountTab/>
        </TabPanel>
        <TabPanel value="refuel-transaction">
          <ShellRefuelTransactionListingTab/>
        </TabPanel>
      </TabContext>
    </Container>
  );
}