import {LoadingButton} from "@material-ui/lab";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPut from "../../../hooks/http/useHttpPut";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import {COLLECTION_PERMISSIONS} from "../../../utils/authorization/permissions/invoicePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import {defaultTimezone} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";
import CollectionDetails from "../../Details/Collection/CollectionDetails";
import InvoiceDetails from "../../Details/Invoice/InvoiceDetails";
import RefundVoucherDetails from "../../Details/RefundVoucher/RefundVoucherDetails";
import AccountCollectionForm from "../../Form/Collection/AccountCollectionForm";

dayjs.extend(utc);
dayjs.extend(timezone);

const collectionTypes = ["Collection", "Credit Note", "Refund Deposit"];
const Details = ({ data, type }) => {
  return (
    <>
      {type === "Invoice" && data && <InvoiceDetails invoice={data}/>}
      {type === "Refund Voucher" && data && <RefundVoucherDetails refundVoucher={data}/>}
      {collectionTypes.includes(type) && data && <CollectionDetails collection={data}/>}
    </>
  );
}

Details.propTypes = {
  data: PropTypes.string,
  type: PropTypes.object,
}

const ViewAction = ({ id, type }) => {
  const [open, setOpen] = useState(false);

  let query;
  if (type === "Invoice") query = `/api/invoice/${id}`;
  else if (type === "Refund Voucher") query = `/api/refund-voucher/${id}`;
  else query = `/api/collection/${id}`;

  const { data } = useHttpGet(query);
  const attachment = collectionTypes.includes(type) ? data?.receiptAttachment : data?.attachment;

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>View {type}</DialogTitle>
        <DialogContent>
          <Details data={data} type={type}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)} sx={{ mr: 1 }}>Close</Button>
          <Button component={Link} to={getFile(attachment)} target="_blank" variant="contained">Print</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
}

const VoidAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  let query;
  if (row.type === "Invoice") query = `/api/invoice/${row?.id}/void`;
  else if (collectionTypes.includes(row.type)) query = `/api/collection/${row?.id}/void`;
  else if (row.type === "Refund Voucher") query = `/api/refund-voucher/${row?.id}/void`;

  const { onPut, loading } = useHttpPut(query);

  const onVoid = async e => {
    e.preventDefault();
    try {
      await onPut();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: `${row.type} voided successfully!`, severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Void this {row.type}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onVoid}>Void</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)} disabled={row.status === "Voided"}>
        Void
      </Button>
    </>
  );
}

VoidAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  let query;
  if (row.type === "Invoice") query = `/api/invoice/${row?.id}`;
  else if (collectionTypes.includes(row.type)) query = `/api/collection/${row?.id}`;
  else if (row.type === "Refund Voucher") query = `/api/refund-voucher/${row?.id}`;

  const { onDelete, loading } = useHttpDelete(query);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: `${row.type} deleted successfully!`, severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete {row?.refNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const EditAction = ({ booking, row, onReload }) => {
  const [open, setOpen] = useState(false);

  const { data } = useHttpGet(`/api/collection/${row?.id}`);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>Edit {row?.refNo}</DialogTitle>
        <DialogContent>
          <Box pt={1.5}>
            {data && <AccountCollectionForm data={data} booking={booking} onReload={onReload} onClose={() => setOpen(false)} isFinalized/>}
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Edit</Button>
    </>
  );
}

EditAction.propTypes = {
  booking: PropTypes.object,
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ booking, row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" spacing={1}>
      <ViewAction id={row.id} type={row.type}/>
      <Button component={Link} to={getFile(row.attachment)} target="_blank" variant="contained">Print</Button>
      {/* Finalize Action */}
      {collectionTypes.includes(row.type) && permissionRequired(authCtx, COLLECTION_PERMISSIONS.UPDATE) && <EditAction booking={booking} row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, COLLECTION_PERMISSIONS.VOID) && <VoidAction row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, COLLECTION_PERMISSIONS.DELETE) && <DeleteAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  booking: PropTypes.object,
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function AccountTable({ booking, data, onReload }) {
  const columns = [
    {
      field: "", headerName: "Action", headerAlign: "center", width: 400, sortable: false,
      renderCell: ({ row }) => <ActionCol booking={booking} row={row} onReload={onReload}/>
    },
    { field: "refNo", headerName: "Ref No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "type", headerName: "Type", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 100, sortable: false },
    { field: "company", headerName: "Company", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "date", headerName: "Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.date).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    { field: "description", headerName: "Description", align: "center", headerAlign: "center", width: 300, sortable: false },
    {
      field: "amount", headerName: "Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => `$${row.amount}`,
    },
    { field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 150 },
    { field: "paymentMode", headerName: "Payment Mode", align: "center", headerAlign: "center", width: 150, sortable: false },
  ];

  return (
    <StyledDataGrid rows={data ?? []} columns={columns} disableSelectionOnClick autoHeight/>
  );
}

AccountTable.propTypes = {
  booking: PropTypes.object,
  data: PropTypes.array,
  onReload: PropTypes.func,
}