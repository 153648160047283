import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import palette from "../../../theme/palette";
import { getDayjsValue, getMomentValue, paymentPeriodTypes } from "../../../utils/date";
import InvoiceItemTable from "../../Table/Invoice/InvoiceItemTable";
import {FcDateField, FcSelectField, FcTextArea, FcTextField, SubmitBtnGroup} from "../FormFields";
import InvoiceItemForm from "./InvoiceItemForm";

const AgreementDetails = ({ agreement }) => {
  const customerDetails = [
    { label: "Booking No.", value: agreement.bookingNo },
    { label: "Start Date", value: getDayjsValue(agreement.startDateTime).format("DD MMM YYYY") },
    { label: "End Date", value: getDayjsValue(agreement.endDateTime).format("DD MMM YYYY") },
    { label: "Customer Type", value: agreement.customerType },
    { label: "Customer Name", value: agreement?.customerType === "Company" ? agreement?.companyName : agreement?.customerName },
    { label: "Customer Email", value: agreement?.customerType === "Company" ? agreement?.companyEmail : agreement?.customerEmail },
    { label: "Customer Phone", value: agreement?.customerType === "Company" ? agreement?.companyPhone : agreement?.customerPhone },
    { label: "Customer NRIC/FIN/ACRA", value: agreement?.customerType === "Company" ? agreement?.companyIdentity : agreement?.customerIdentity },
  ];

  const paymentDetails = [
    { label: "Vehicle Number", value: agreement.vehicleNumber },
    { label: "Make / Model", value: `${agreement.vehicleMakeName} / ${agreement.vehicleModelName} ${agreement.vehiclePetrol}` },
    { label: "Deposit", value: agreement.deposit > 0 ? `S$${agreement.deposit}` : "No Deposit" },
    { label: "Rental Rate", value: `S$${agreement.rentalPrice} / ${paymentPeriodTypes[agreement.paymentPeriod].toLowerCase()}` },
  ];

  return (
    <Grid container spacing={3}>
      {customerDetails.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12} sm={9}>
        <Typography variant="h6">Customer Address</Typography>
        {agreement.customerType === "Company" ? agreement.companyAddress : agreement.customerAddress}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="h6">Customer Postal</Typography>
        {agreement.customerType === "Company" ? agreement.companyPostal : agreement.customerPostal}
      </Grid>
      {paymentDetails.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
    </Grid>
  );
}

AgreementDetails.propTypes = {
  agreement: PropTypes.object,
}

const OwnerDetails = ({ owner }) => {
  const paymentDetails = [
    { label: "Company", value: owner?.name },
    { label: "Company UEN", value: owner?.uenNo },
  ];

  return (
    <Grid container spacing={3}>
      {paymentDetails.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Company Address</Typography>
        {owner?.address} {owner?.postal}
      </Grid>
    </Grid>
  );
}

OwnerDetails.propTypes = {
  owner: PropTypes.object,
}

const AddItemAction = ({ agreement, onAdd }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Add Recurring Invoice Item</DialogTitle>
        <DialogContent>
          <Box>
            <Typography mb={2}>
              {"Placeholders {startDate} and {endDate} will be used by the system, and will be replaced with the actual start and end dates during processing."}
            </Typography>
            <InvoiceItemForm type="recurring" agreement={agreement} onClose={() => setOpen(false)} onAdd={onAdd}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Add</Button>
    </>
  );
}

AddItemAction.propTypes = {
  agreement: PropTypes.object,
  onAdd: PropTypes.func,
}

export default function RecurringInvoiceForm({ type = "normal", agreement, onReload, onClose }) {
  const dataCtx = useContext(DataContext);

  const paymentTypes = ["Prepaid", "Postpaid"].map(type => ({ label: type, value: type }));
  const scheduleTypes = ["Daily", "Weekly", "Monthly"].map(type => ({ label: type, value: type }));
  const weekValues = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    .map((day, index) => ({ label: day, value: index }));
  const monthValues = Array.from({length: 31}, (_, i) => ({ label: i + 1, value: i + 1 }));

  const { onPost } = useHttpPost("/api/invoice/recurring");
  const { data: owners } = useHttpGet("/api/vehicles/owner");

  const formik = useFormik({
    initialValues: {
      title: "",
      terms: 3,
      paymentType: "Prepaid",
      scheduleType: "Weekly",
      scheduleValue: 0,
      applyGst: false,
      totalAmount: 0,
      startDate: getMomentValue(agreement?.startDateTime).startOf("day") ?? null,
      endDate: getMomentValue(agreement?.endDateTime).startOf("day") ?? null,
      companyId: agreement?.vehicleCompanyId ?? "",
      agreementId: agreement?.id ?? "",
      items: [],
      remarks: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required!"),
      terms: Yup.number().required("Terms is required!"),
      paymentType: Yup.string().required("Payment Type is required!"),
      scheduleType: Yup.string().required("Schedule Type is required!"),
      scheduleValue: Yup.number().required("Schedule Value is required!"),
      applyGst: Yup.boolean().required("Please ensure this invoice is apply GST or not!"),
      totalAmount: Yup.number().required("Total Amount is required!"),
      startDate: Yup.date().required("Start Date is required!"),
      endDate: Yup.date().required("End Date is required!"),
      companyId: Yup.string().required("Company is required!"),
      agreementId: Yup.string().required("Agreement is required!"),
      items: Yup.array().of(
        Yup.object({
          description: Yup.string().required("Description is required!"),
          quantity: Yup.number().required("Quantity is required!"),
          unitPrice: Yup.number().required("Unit Price is required!"),
        }),
      ),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost(values);
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Recurring Invoice created successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const owner = owners?.find(owner => owner.id === formik.values.companyId);
  const cdwCompany = owners?.find(owner => owner.name === "Renty Pte Ltd");
  const onAddItem = values => formik.setFieldValue("items", [...formik.values.items, {...values, id: formik.values.items.length + 1}]);
  const onRemoveItem = id => formik.setFieldValue("items", formik.values.items.filter(item => item.id !== id));

  useEffect(() => {
    if (formik.values.paymentType === "Postpaid") {
      formik.setFieldValue("scheduleType", "Weekly");
      formik.setFieldValue("scheduleValue", 0);
    }
  }, [formik.values.paymentType]);

  useEffect(() => {
    formik.setFieldValue("totalAmount", formik.values.items.reduce((sum, cur) => sum += cur.quantity * cur.unitPrice, 0));
  }, [formik.values.items]);

  useEffect(() => {
    if (agreement && cdwCompany && owners) {
      if (type === "cdw") {
        formik.setFieldValue("applyGst", false);
        formik.setFieldValue("companyId", cdwCompany.id);
      } else {
        const owner = owners.find(owner => owner.id === agreement.vehicleCompanyId);
        formik.setFieldValue("applyGst", owner?.isRegisterGst);
        formik.setFieldValue("companyId", agreement.vehicleCompanyId);
      }
    }
  }, [agreement, cdwCompany, owners, type]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AgreementDetails agreement={agreement}/>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ border: 1, borderColor: palette.divider }}/>
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} label="Title" name="title"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} label="Start Date" name="startDate"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} label="End Date" name="endDate"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} label="Schedule Type" name="scheduleType" items={scheduleTypes}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} label="Day" name="scheduleValue" items={formik.values.scheduleType === "Weekly" ? weekValues : monthValues}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} label="Payment Type" name="paymentType" items={paymentTypes}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Terms" name="terms" type="number"/>
          </Grid>
          <Grid item xs={12}>
            <OwnerDetails owner={type === "cdw" ? cdwCompany : owner}/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Invoice Items</Typography>
              <AddItemAction agreement={agreement} onAdd={onAddItem}/>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <InvoiceItemTable data={formik.values.items || []} onRemove={onRemoveItem}/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} label="Remarks" name="remarks"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Create" onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

RecurringInvoiceForm.propTypes = {
  type: PropTypes.string,
  agreement: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}