// material
import { Container } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  return (
    <Page title="Car Models: Fresh Cars">
      <Container maxWidth="xl">
        <Outlet />
      </Container>
    </Page>
  );
}
