import { LoadingButton } from "@material-ui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import AccountCollectionForm from "../../../components/Form/Collection/AccountCollectionForm";
import MultipleTypeCollectionForm from "../../../components/Form/Collection/MultipleTypeCollectionForm";
import InvoiceForm from "../../../components/Form/Invoice/InvoiceForm";
import AccountRefundVoucherForm from "../../../components/Form/RefundVoucher/AccountRefundVoucherForm";
import AccountTable from "../../../components/Table/Accounting/AccountTable";
import BookingStatementOfAccount from "../../../components/Table/Booking/BookingStatementOfAccount";
import AccountCollectionTable from "../../../components/Table/Collection/AccountCollectionTable";
import AccountInvoiceTable from "../../../components/Table/Invoice/AccountInvoiceTable";
import AccountRefundVoucherTable from "../../../components/Table/RefundVoucher/AccountRefundVoucherTable";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPost from "../../../hooks/http/useHttpPost";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import { COLLECTION_PERMISSIONS, INVOICE_PERMISSIONS } from "../../../utils/authorization/permissions/invoicePermissions";
import { permissionRequired } from "../../../utils/authorization/roles";
import { getOutstandingAmount } from "../../../utils/bookingAccounts";
import { getDayjsValue } from "../../../utils/date";

const CreateRefundCreditAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Create Refund Credit</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <AccountCollectionForm booking={booking} onClose={() => setOpen(false)} onReload={onReload} type="refund-credit" isFinalized/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Create Refund Credit</Button>
    </>
  );
}

CreateRefundCreditAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const CreateCreditNoteAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>Create Credit Note</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <AccountCollectionForm booking={booking} onClose={() => setOpen(false)} onReload={onReload} type="credit-note"/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Create Credit Note</Button>
    </>
  );
}

CreateCreditNoteAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const CreateRefundVoucherAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Create Refund Voucher</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <AccountRefundVoucherForm booking={booking} onClose={() => setOpen(false)} onReload={onReload}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Create Refund Voucher</Button>
    </>
  );
}

CreateRefundVoucherAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const CreateCollectionAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>Create Collection</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <AccountCollectionForm booking={booking} onClose={() => setOpen(false)} onReload={onReload}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Create Collection</Button>
    </>
  );
}

CreateCollectionAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const StatementOfAccount = ({ booking }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const invoices = booking?.invoices ?? [];
  const collections = booking?.collections ?? [];
  const refundVouchers = booking?.refundVouchers ?? [];

  const { onPost, loading } = useHttpPost(`/api/booking/${booking?.id}/soa`);

  const generateStatementOfAccountData = () => {
    let data = invoices.map(invoice => {
      return {
        id: invoice.id,
        date: getDayjsValue(invoice.issueDate),
        transaction: "Invoice",
        refNo: invoice.invoiceNo,
        amount: invoice.totalAmount,
        payment: null,
      };
    });

    data = data.concat(collections.map(collection => {
      if (collection.paymentMethod === "Credit Note") {
        return {
          id: collection.id,
          date: getDayjsValue(collection.collectDate),
          transaction: "Credit Note",
          refNo: collection.receiptNo,
          amount: null,
          payment: collection.amount,
        }
      }
      return {
        id: collection.id,
        date: getDayjsValue(collection.collectDate),
        transaction: collection.paymentMethod === "Refund Credit" ? "Refund Credit" : "Payment Received",
        refNo: collection.receiptNo,
        amount: null,
        payment: collection.amount,
      }
    }));

    data = data.concat(refundVouchers.map(refundVoucher => {
      return {
        id: refundVoucher.id,
        date: getDayjsValue(refundVoucher.refundDate),
        transaction: "Refund",
        refNo: refundVoucher.refundNo,
        amount: refundVoucher.amount,
        payment: null,
      }
    }));

    data.sort((a,b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return 0;
    });

    let balance = 0;
    for (let i = 0; i < data.length; i++) {
      balance += data[i].amount ?? 0;
      balance -= data[i].payment ?? 0;
      data[i].balance = balance;
    }

    return data;
  }

  const onPrint = async e => {
    e.preventDefault();
    try {
      const blob = await onPost();
      window.open(URL.createObjectURL(blob), "_blank");
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Statement Of Account</DialogTitle>
        <DialogContent>
          <BookingStatementOfAccount data={generateStatementOfAccountData()}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" onClick={onPrint}>Print</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>SOA</Button>
    </>
  );
}

StatementOfAccount.propTypes = {
  booking: PropTypes.object,
}

const CategorizeView = ({ booking, onReload }) => {
  const authCtx = useContext(AuthContext);

  const collections = booking?.collections.filter(collection => collection.paymentMethod !== "Credit Note" && collection.paymentMethod !== "Refund Credit") ?? [];
  const creditNotes = booking?.collections.filter(collection => collection.paymentMethod === "Credit Note") ?? [];
  const refundCredits = booking?.collections.filter(collection => collection.paymentMethod === "Refund Credit") ?? [];

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mt={3} mb={2}>
        <Typography variant="h5">Invoices</Typography>
      </Stack>
      <AccountInvoiceTable data={booking?.invoices} onReload={onReload}/>

      <Stack direction="row" justifyContent="space-between" mt={3} mb={2}>
        <Typography variant="h5">Collections</Typography>
        {permissionRequired(authCtx, COLLECTION_PERMISSIONS.CREATE) && <CreateCollectionAction booking={booking} onReload={onReload}/>}
      </Stack>
      <AccountCollectionTable data={collections} booking={booking} onReload={onReload}/>

      <Stack direction="row" justifyContent="space-between" mt={3} mb={2}>
        <Typography variant="h5">Credit Notes</Typography>
        {permissionRequired(authCtx, COLLECTION_PERMISSIONS.CREATE) && <CreateCreditNoteAction booking={booking} onReload={onReload}/>}
      </Stack>
      <AccountCollectionTable data={creditNotes} booking={booking} onReload={onReload} type="credit-note"/>

      <Stack direction="row" justifyContent="space-between" mt={3} mb={2}>
        <Typography variant="h5">Refund Vouchers</Typography>
        {permissionRequired(authCtx, COLLECTION_PERMISSIONS.CREATE) && <CreateRefundVoucherAction booking={booking} onReload={onReload}/>}
      </Stack>
      <AccountRefundVoucherTable data={booking?.refundVouchers} onReload={onReload}/>

      <Stack direction="row" justifyContent="space-between" mt={3} mb={2}>
        <Typography variant="h5">Refund Deposit</Typography>
        {permissionRequired(authCtx, COLLECTION_PERMISSIONS.CREATE) && <CreateRefundCreditAction booking={booking} onReload={onReload}/>}
      </Stack>
      <AccountCollectionTable data={refundCredits} booking={booking} onReload={onReload} type="refund-credit"/>
    </>
  )
}

CategorizeView.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const AccountView = ({ booking, onReload }) => {
  const authCtx = useContext(AuthContext);

  const invoices = booking?.invoices;
  const collections = booking?.collections;
  const refundVouchers = booking?.refundVouchers;

  let records = [];
  const getInvoiceStatus = (invoice) => {
    if (invoice.status === "Voided") return invoice.status;
    const totalAppliedAmount = invoice.items.reduce((sum, cur) => sum + cur.amountPaid, 0);
    if (totalAppliedAmount === 0) return "Unpaid";
    if (invoice.totalAmount > totalAppliedAmount) return "Partially Paid";
    return "Fully Paid";
  }
  records = records.concat(invoices?.map(invoice => ({
    id: invoice.id,
    type: "Invoice",
    attachment: invoice.attachment,
    status: getInvoiceStatus(invoice),
    company: invoice.vehicleCompanyName,
    date: invoice.issueDate,
    refNo: invoice.invoiceNo,
    description: invoice.title,
    amount: invoice.totalAmount,
    outstanding: invoice.status === "Active" ? invoice.items.reduce((sum, cur) => sum + cur.unitPrice * cur.quantity - cur.amountPaid, 0) : "",
  })));

  const creditTypes = ["Credit Note", "Refund Deposit"];
  records = records.concat(collections?.map(collection => ({
    id: collection.id,
    type: creditTypes.includes(collection.paymentMethod) ? collection.paymentMethod : "Collection",
    attachment: collection.receiptAttachment,
    status: collection.status,
    company: collection.vehicleCompanyName,
    date: collection.collectDate,
    refNo: collection.receiptNo,
    description: collection.remarks,
    amount: collection.amount,
    paymentMode: collection.paymentMethod,
  })));

  records = records.concat(refundVouchers?.map(refundVoucher => ({
    id: refundVoucher.id,
    attachment: refundVoucher.attachment,
    type: "Refund Voucher",
    status: refundVoucher.status,
    company: refundVoucher.companyName,
    date: refundVoucher.refundDate,
    refNo: refundVoucher.refundNo,
    description: refundVoucher.title,
    amount: refundVoucher.amount,
    paymentMode: refundVoucher.paymentMethod,
  })));

  records.sort((a, b) => dayjs(b.date) - dayjs(a.date));

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <StatementOfAccount booking={booking}/>
        {permissionRequired(authCtx, COLLECTION_PERMISSIONS.CREATE) && <NewCollectionAction booking={booking} onReload={onReload}/>}
        {permissionRequired(authCtx, INVOICE_PERMISSIONS.CREATE) && <NewInvoiceAction booking={booking} onReload={onReload}/>}
      </Stack>
      <AccountTable booking={booking} data={records} onReload={onReload}/>
    </Stack>
  );
}

AccountView.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const NewCollectionAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>New Collection</DialogTitle>
        <DialogContent>
          <Box pt={1.5}>
            <MultipleTypeCollectionForm booking={booking} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>New</Button>
    </>
  );
}

NewCollectionAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const NewInvoiceAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);
  const [agreement, setAgreement] = useState(null);
  const [type, setType] = useState("normal");

  const { data: agreements } = useHttpGet(`/api/booking/${booking?.bookingNo}/agreement`);
  const invoiceTypes = [
    { label: "Normal", value: "normal" },
    { label: "CDW", value: "cdw" },
  ];

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>New Invoice</DialogTitle>
        <DialogContent>
          <Grid container pt={1} spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Agreements</InputLabel>
                <Select
                  label="Agreements"
                  value={agreement}
                  onChange={e => setAgreement(e.target.value)}
                >
                  {agreements?.map((agreement, index) => (
                    <MenuItem value={JSON.stringify(agreement)} key={index}>
                      {agreement.agreementNo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Invoice Type</InputLabel>
                <Select
                  label="Type"
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  {invoiceTypes.map(({ label, value }, index) => (
                    <MenuItem value={value} key={index}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {agreement && <Grid item xs={12}>
              <InvoiceForm agreement={JSON.parse(agreement)} type={type} onReload={onReload} onClose={() => setOpen(false)}/>
            </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>New Invoice</Button>
    </>
  );
}

NewInvoiceAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

export default function BookingAccountTab({ booking, onReload }) {
  const customer = booking?.company ?? booking?.customer;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Typography variant="h4">{customer?.name} - {booking?.bookingNo}</Typography>
        <Typography variant="h5">Outstanding: {getOutstandingAmount(booking)}</Typography>
      </Stack>

      {/* <CategorizeView booking={booking} onReload={onReload}/> */}
      {booking && <AccountView booking={booking} onReload={onReload}/>}
    </>
  );
}

BookingAccountTab.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}