import {Grid} from "@mui/material";
import PropTypes from "prop-types";
import {useContext} from "react";
import * as Yup from 'yup';
import {Form, FormikProvider, useFormik} from "formik";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import DataContext from "../../../store/DataContext";
import {FcTextField, SubmitBtnGroup} from "../FormFields";

export default function AssignShellCardForm({ cardId, onClose, onReload }) {
  const dataCtx = useContext(DataContext);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/assign`);

  const formik = useFormik({
    initialValues: {
      email: "",
      card_id: cardId,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Please fill in the email!"),
      card_id: Yup.string().required("Card is not exists!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost(values);
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Card assigned successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcTextField formik={formik} label="Email" name="email"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Assign" onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

AssignShellCardForm.propTypes = {
  cardId: PropTypes.string,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
}