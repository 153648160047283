import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import {defaultTimezone} from "../../../utils/date";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function BookingStatementOfAccount({ data }) {
  const columns = [
    {
      field: "date", headerName: "Date", align: "center", headerAlign: "center", width: 220,
      renderCell: ({ row }) => dayjs(row.date).utc(true).tz(defaultTimezone).format("DD MMM YYYY")
    },
    { field: "transaction", headerName: "Transactions", align: "center", headerAlign: "center", width: 300 },
    { field: "refNo", headerName: "Ref Number", align: "center", headerAlign: "center", width: 250 },
    {
      field: "amount", headerName: "Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row?.amount ?? ""
    },
    {
      field: "payment", headerName: "Payment", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row?.payment ?? ""
    },
    { field: "balance", headerName: "Balance", align: "center", headerAlign: "center", width: 150 },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={data ?? []}
      pageSize={100} rowsPerPageOptions={[100]}
      disableSelectionOnClick
      autoHeight
    />
  );
}

BookingStatementOfAccount.propTypes = {
  data: PropTypes.array,
}