import * as Yup from 'yup';
import { Grid } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { DateTimePicker , LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from "dayjs";
import DataContext from '../../../store/DataContext';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { FcAutocomplete, FcTextField, SubmitBtnGroup } from '../FormFields';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import authContext from '../../../store/AuthContext';

export default function WorkshopAppointmentForm({ data, time }) {
  const auth = useContext(authContext);
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();
  const method = data ? "Update" : "Create";

  const { data: vehicleData } = useCloudFuncGet(`${cloudFunctionPath}/workshop/vehicle`);
  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/appointment`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/workshop/appointment/${data?.id}`);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onCreate = async (values) => {
    await onPost(values);
    onOpenSnackbar("Appointment Created Successfully");
  }

  const onUpdate = async (values) => {
    await onPut(values);
    onOpenSnackbar("Appointment Updated Successfully");
  }

  const formik = useFormik({
    initialValues: {
      customerName: "",
      customerPhone: "",
      vehicleNumber: null,
      mainIssue: "",
      time: time ? dayjs(time) : null,
    },
    validationSchema: Yup.object({
      customerName: Yup.string().required("Please input customer name"),
      customerPhone: Yup.string().required("Please input customer phone"),
      vehicleNumber: Yup.string().required("Please input vehicle number"),
      mainIssue: Yup.string().required("Please input main issue"),
      time: Yup.date().required("Please input appointment time"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const uploadData = {
          customerName: formik.values.customerName,
          customerPhone: formik.values.customerPhone,
          vehicleNumber: formik.values.vehicleNumber,
          mainIssue: formik.values.mainIssue,
          time: formik.values.time,
          salesPerson: auth.name,
          userEmail: auth.email,
        }

        if (method === "Create") {
          await onCreate(uploadData);
        } else {
          await onUpdate(uploadData);
        }

        setTimeout(() => navigate("../"), 1000);
      } catch (e) {
        console.log(e.toString());
        onOpenSnackbar(JSON.parse(e.message).message, "error");
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("customerName", data.customerName);
      formik.setFieldValue("customerPhone", data.customerPhone);
      formik.setFieldValue("vehicleNumber", data.vehicleNumber);
      formik.setFieldValue("mainIssue", data.mainIssue);
      formik.setFieldValue("time", data.time);
    }
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={1.5} mb={3}>
          <Grid item xs={6}>
            <FcAutocomplete
              formik={formik}
              name="vehicleNumber"
              label="Vehicle Number"
              options={vehicleData.data?.map((vec) => vec.vehicleNumber) ?? []}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disabled={data}
                label="Appointment Time"
                views={['year', 'month', 'day', 'hours']}
                disablePast
                minTime={dayjs().set('hour', 9).startOf('hour')}
                maxTime={dayjs().set('hour', 17).startOf('hour')}
                format="DD-MM-YYYY HH"
                onChange={(val) => formik.setFieldValue("time", val)}
                value={formik.values.time}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <FcTextField formik={formik} name="customerName" label="Customer Name" />
          </Grid>
          <Grid item xs={6}>
            <FcTextField formik={formik} name="customerPhone" label="Customer Phone" />
          </Grid>
          <Grid item xs={12}>
            <FcAutocomplete
              formik={formik}
              name="mainIssue"
              label="Main Issue"
              options={ ["Servicing"] }
              freeSolo
            />
          </Grid>
        </Grid>

        <SubmitBtnGroup formik={formik} method={method} onCancel={() => navigate(-1)} />
      </Form>
    </FormikProvider>
  )
}

WorkshopAppointmentForm.propTypes = {
  data: PropTypes.object,
  time: PropTypes.object,
}