import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogActions, DialogTitle, Stack} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import DataContext from "../../../store/DataContext";
import FcTable from "../FcTable";

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/booking/price/cdw/${row?.id}`);

  const onSubmit = async e => {
    try {
      e.preventDefault();
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "CDW price deleted successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete CDW Price?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <DeleteAction row={row} onReload={onReload}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function CdwPriceTable({ data, onReload }) {
  const columns = [
    { field: "id", headerName: "No.", showIndex: true, align: "center", headerAlign: "center" },
    { field: "price", headerName: "Price (Per month)", align: "center", headerAlign: "center" },
    {
      field: '', headerName: 'Action', align: "center", headerAlign: "center", minWidth: 125, sortable: false,
      renderCell: ({row}) => <ActionCol row={row} onReload={onReload}/>
    },
  ];

  return (
    <FcTable columns={columns} rows={data ?? []}/>
  )
}

CdwPriceTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}