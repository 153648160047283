import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useContext, useEffect } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FcTextField, SubmitBtnGroup } from '../FormFields';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import DataContext from '../../../store/DataContext';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

export default function WorkshopTaskForm({ data, onCancel }) {
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();
  const method = data ? "Update" : "Create";

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/task`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/workshop/task/${data?.id}`);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onCreate = async (values) => {
    await onPost(values);
    onOpenSnackbar("Task Created Successfully");
  }

  const onUpdate = async (values) => {
    await onPut(values);
    onOpenSnackbar("Task Updated Successfully");
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      chineseName: "",
      cost: 0,
      hasSpecific: "No"
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please fill in Task Name"),
      chineseName: Yup.string().required("Please fill in Task Chinese Name"),
      cost: Yup.number().required("Please fill in Task Cost"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const postData = {
          name: formik.values.name,
          chineseName: formik.values.chineseName,
          cost: formik.values.cost,
          hasSpecific: formik.values.hasSpecific === "Yes"
        }

        if (method === "Create") {
          await onCreate(postData);
        } else {
          await onUpdate(postData);
        }
        setTimeout(() => navigate("../"), 1000);
      } catch (error) {
        console.log(error.message);
        onOpenSnackbar(JSON.parse(error.message).message, "error");
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("name", data?.name);
      formik.setFieldValue("chineseName", data?.chineseName);
      formik.setFieldValue("cost", data?.cost);

      let hasSpecific = "No";
      if (data?.hasSpecific) {
        hasSpecific = "Yes";
      }
      formik.setFieldValue("hasSpecific", hasSpecific);
    }
  }, [data]);
  
  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={1.5} mb={3}>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="name" label="Name" />
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="chineseName" label="Chinese Name" />
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="cost" label="Cost" />
          </Grid>
        </Grid>

        <Grid item xs={12} ml={1}>
          <Typography>Is this a task that contains specific field?</Typography>
          <RadioGroup
            row
            defaultValue="No"
            value={formik.values.hasSpecific}
            onChange={(event) => formik.setFieldValue("hasSpecific", event.target.value)}
          >
            <FormControlLabel value="No" label="No" control={<Radio />} />
            <FormControlLabel value="Yes" label="Yes" control={<Radio />} />
          </RadioGroup>
        </Grid>

        <SubmitBtnGroup method={method} formik={formik} onCancel={onCancel} />
      </Form>
    </FormikProvider>
  );
}

WorkshopTaskForm.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func
}