import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../../pages/Vehicle/styles";
import {defaultTimezone} from "../../../../utils/date";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function AgreementOutstandingInvoicesTable({ data }) {
  const columns = [
    { field: "invoiceNo", headerName: "Invoice No.", align: "center", headerAlign: "center", width: 200 },
    {
      field: "issueDate", headerName: "Issue Date", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => dayjs(row.issueDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    {
      field: "totalAmount", headerName: "Amount", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => `$${row.totalAmount}`,
    },
    {
      field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => `$${row.outstanding}`,
    },
    { field: "remarks", headerName: "Remarks", align: "center", headerAlign: "center", width: 400 },
  ];

  const invoices = data?.map(row => {
    return {
      ...row,
      outstanding: row?.items.reduce((total, curr) => total + curr.quantity * curr.unitPrice - curr.amountPaid, 0),
    };
  }) ?? [];
  const outstandingInvoices = invoices.filter(invoice => invoice.outstanding > 0);

  return (
    <StyledDataGrid
      columns={columns} rows={outstandingInvoices}
      autoHeight disableSelectionOnClick
      pageSize={100} rowsPerPageOptions={[100]}
    />
  );
}

AgreementOutstandingInvoicesTable.propTypes = {
  data: PropTypes.array,
}