import PropTypes from "prop-types";
import dayjs from 'dayjs';
import { Grid, Paper, Tooltip, Typography } from '@mui/material';
import React from 'react';

export default function ReturnJobDisplayItem({ returnJob }) {
  const title = returnJob
    .dates
    .sort()
    .map((date) => dayjs(date).format("DD MMM YYYY"))
    .reduce((a, b) => a === "" ? b : `${a}  \u00a0+\u00a0  ${b}`, "");

  return (
    <Grid item style={{ position: "sticky", bottom: "0%" }}>
      <Tooltip title={<p style={{ fontSize: 16}}>{title}</p>} PopperProps={{ style: {zIndex: 999999} }}>
        <Paper elevation={10} style={{ padding: 8, display: "inline-block" }}>
          <Typography fontSize={18} fontWeight={600}>{returnJob.vehicleNumber}</Typography>
        </Paper>
      </Tooltip>
    </Grid>
  );
}

ReturnJobDisplayItem.propTypes = {
  returnJob: PropTypes.object,
}