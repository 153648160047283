import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import WorkshopTaskForm from '../../../components/Form/Workshop/WorkshopTaskForm';
import BackButton from '../../../components/BackButton';

export default function NewWorkshopTask() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      <BackButton />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>Creating New Task</Typography>
      </Stack>

      <WorkshopTaskForm onCancel={() => navigate("../")} />
    </Container>
  );
}