import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import MenuIcon from '@mui/icons-material/Menu';
import {
  addDoc,
  doc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  updateDoc
} from 'firebase/firestore';

import AuthContext from '../../store/AuthContext';
import FirestoreContext from '../../store/FirestoreContext';

const drawerWidth = 300;

const userChatBubbleStyle = {
  borderRadius: 2,
  backgroundColor: 'Gainsboro',
  fontSize: 15,
  width: 'fit-content',
  marginTop: 1,
  padding: 1,
  textAlign: 'left',
  maxWidth: '70%'
};

const fcAdminChatBubbleStyle = {
  borderRadius: 2,
  backgroundColor: 'MediumAquaMarine',
  fontSize: 15,
  width: 'fit-content',
  marginTop: 1,
  padding: 1,
  textAlign: 'left',
  maxWidth: '70%'
};

const adminUserChatBubbleNameStyle = {
  color: 'Black',
  fontSize: 14,
  fontWeight: 'bold'
};

const chatBubbleTimeStyle = { color: 'DimGray', fontSize: 11 };

const myattr = {
  secondaryAction: (
    <div
      style={{
        height: '10px',
        width: '10px',
        backgroundColor: 'MediumAquaMarine',
        borderRadius: '50%',
        marginRight: '20px'
      }}
    />
  )
};

let globalChatDocs = [];

function ChatDrawer(props) {
  const authCtx = useContext(AuthContext);
  const fsCtx = useContext(FirestoreContext);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  //   const searchInputRef = useRef();
  const [searchQuery, setSearchQuery] = useState();
  const [chatList, setChatList] = useState();
  const [filteredChatList, setFilteredChatList] = useState();
  const [messages, setMessages] = useState([]);
  const [activeAppUser, setActiveAppUser] = useState('');
  const [unsubscribeFn, setUnsubscribeFn] = useState(() => () => {
    console.log('unsubscribed!');
  });
  const sendMessageInputRef = useRef();
  const msgRef = useRef();

  const fetchChatList = useCallback(async () => {
    const q = query(collection(fsCtx.fsObject, 'chat'), orderBy('adminUnreadMessages', 'desc'));
    const querySnapshot = await getDocs(q);
    const chatDocs = querySnapshot.docs;
    globalChatDocs = chatDocs;

    setChatList(chatDocs);

    setFilteredChatList(
      chatDocs.map((element) => (
        <ListItem
          disableGutters
          {...(element.data().adminUnreadMessages !== 0 ? myattr : null)}
          key={element.id}
        >
          <ListItemButton>
            <ListItemText
              // disableTypography
              // sx={{ fontWeight: 'bold' }}
              primary={element.data().username}
              secondary={element.data().lastMessageDate.toDate().toLocaleDateString('en-SG', {
                minute: 'numeric',
                hour: 'numeric',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })}
              onClick={() => {
                openMessageStream(element.data().userId);
                if (element.data().adminUnreadMessages !== 0) {
                  markMessageReadHandler(element.id);
                }
                setActiveAppUser({
                  appUserUid: element.data().userId,
                  appUserUsername: element.data().username
                });
                setMobileOpen(!mobileOpen);
              }}
            />
          </ListItemButton>
        </ListItem>
      ))
    );

    // setFuelPriceDate(
    //   querySnapshot.data().fuelPrices.date.toDate().toLocaleDateString('en-SG', {
    //     year: 'numeric',
    //     month: 'short',
    //     day: 'numeric'
    //   })
    // );
  }, [fsCtx.fsObject]);

  const openMessageStream = (uid) => {
    unsubscribeFn();
    const q = query(collection(fsCtx.fsObject, 'chat', uid, 'chat'), orderBy('createdAt', 'desc'));
    setUnsubscribeFn(() =>
      onSnapshot(q, (qSnapshot) => {
        msgRef.current?.scrollIntoView({ behavior: 'smooth' });
        setMessages(
          qSnapshot.docs.map((doc) => (
            <div
              {...(doc.data() === qSnapshot.docs[qSnapshot.docs.length - 1].data()
                ? { ref: msgRef }
                : null)}
              style={{ width: '100%' }}
              key={doc.data().createdAt}
            >
              <Box
                sx={
                  doc.data().userGroup === 'fcAdmin' ? fcAdminChatBubbleStyle : userChatBubbleStyle
                }
              >
                {doc.data().userGroup === 'fcAdmin' && (
                  <Box sx={adminUserChatBubbleNameStyle}>{doc.data().username}</Box>
                )}
                {doc.data().text}
                <br />
                <div style={chatBubbleTimeStyle}>
                  {doc.data().createdAt.toDate().toLocaleDateString('en-SG', {
                    minute: 'numeric',
                    hour: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  })}
                </div>
              </Box>
            </div>
          ))
        );
      })
    );
  };

  const markMessageReadHandler = async (chatId) => {
    try {
      await updateDoc(doc(fsCtx.fsObject, 'chat', chatId), { adminUnreadMessages: 0 });
      globalChatDocs[
        globalChatDocs.findIndex((element) => element.id === chatId)
      ]._document.data.value.mapValue.fields.adminUnreadMessages.integerValue = 0;

      // Object.assign(
      //   globalChatDocs[globalChatDocs.findIndex((element) => element.id === chatId)].data(),
      //   { adminUnreadMessages: 0 }
      // );

      setFilteredChatList(
        globalChatDocs.map((element) => (
          <ListItem
            disableGutters
            {...(element.data().adminUnreadMessages !== 0 ? myattr : null)}
            key={element.id}
          >
            <ListItemButton>
              <ListItemText
                // disableTypography
                // sx={{ fontWeight: 'bold' }}
                primary={element.data().username}
                secondary={element.data().lastMessageDate.toDate().toLocaleDateString('en-SG', {
                  minute: 'numeric',
                  hour: 'numeric',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
                onClick={() => {
                  openMessageStream(element.data().userId);
                  if (element.data().adminUnreadMessages !== 0) {
                    markMessageReadHandler(element.id);
                  }
                  setActiveAppUser({
                    appUserUid: element.data().userId,
                    appUserUsername: element.data().username
                  });
                  setMobileOpen(!mobileOpen);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))
      );
    } catch (e) {
      console.log(`Failed to update message read status! ${e}`);
    }
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(fsCtx.fsObject, 'chat', activeAppUser.appUserUid, 'chat'), {
        createdAt: Timestamp.now(),
        text: sendMessageInputRef.current.value,
        userGroup: 'fcAdmin',
        username: authCtx.authObj.currentUser.displayName,
        userId: authCtx.authObj.currentUser.uid
      });
      const docRef = doc(fsCtx.fsObject, 'chat', activeAppUser.appUserUid);
      await updateDoc(docRef, {
        lastMessageDate: Timestamp.now()
      });
      sendMessageInputRef.current.value = '';
    } catch (e) {
      alert(`An error occurred with sending your message: ${e}`);
    }
  };

  useEffect(() => fetchChatList(), [fetchChatList]);

  const chatListElements = (query) => {
    if (query === '' || query === null || query === undefined) {
      setFilteredChatList(
        globalChatDocs.map((element) => (
          <ListItem
            disableGutters
            {...(element.data().adminUnreadMessages !== 0 ? myattr : null)}
            key={element.id}
          >
            <ListItemButton>
              <ListItemText
                primary={element.data().username}
                secondary={element.data().lastMessageDate.toDate().toLocaleDateString('en-SG', {
                  minute: 'numeric',
                  hour: 'numeric',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
                onClick={() => {
                  openMessageStream(element.data().userId);
                  setActiveAppUser({
                    appUserUid: element.data().userId,
                    appUserUsername: element.data().username
                  });
                  setMobileOpen(!mobileOpen);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))
      );
    } else {
      setFilteredChatList(
        globalChatDocs
          .filter((element) => element.data().username.toLowerCase().includes(query.toLowerCase()))
          .map((element) => (
            <ListItem
              disableGutters
              {...(element.data().adminUnreadMessages !== 0 ? myattr : null)}
              key={element.id}
            >
              <ListItemButton>
                <ListItemText
                  primary={element.data().username}
                  secondary={element.data().lastMessageDate.toDate().toLocaleDateString('en-SG', {
                    minute: 'numeric',
                    hour: 'numeric',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                  onClick={() => {
                    openMessageStream(element.data().userId);
                    setActiveAppUser({
                      appUserUid: element.data().userId,
                      appUserUsername: element.data().username
                    });
                    setMobileOpen(!mobileOpen);
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))
      );
    }
  };

  const searchAutoComplete = (e) => {
    // setSearchQuery(e.target.value);
    chatListElements(e.target.value);
  };

  const drawerTopLeftComponent = () => (
    <Box>
      <br />
      <Box px={2}>
        <TextField
          //   inputRef={searchInputRef}
          fullWidth
          onChange={searchAutoComplete}
          id="outlined-basic"
          label="🔎 Search"
          variant="outlined"
        />
      </Box>
      <br />
      {searchQuery}
      <List>{filteredChatList}</List>
    </Box>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      {drawerTopLeftComponent()}
      {/* <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', position: 'absolute' }}>
      <CssBaseline />
      <AppBar
        position="absolute"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Chat{activeAppUser !== '' && ` | ${activeAppUser.appUserUsername}`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            // position: 'relative',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',

            '& .MuiPaper-root': {
              position: 'relative'
            },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column-reverse',
            alignContent: 'flex-end',
            p: 3,
            pt: 10,
            backgroundColor: 'AliceBlue',
            overflow: 'auto',
            width: '100%',
            // height: {
            //   sm: `calc(100% - ${drawerWidth}px)`
            // }
            height: '65vh'
          }}
        >
          {messages}
          <Typography paragraph sx={{ visibility: 'hidden' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
          {/* <Toolbar /> */}
        </Box>

        {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
        <div style={{ padding: '0px 25px' }}>
          <form onSubmit={sendMessage}>
            <FormControl
              sx={{ m: 1, visibility: activeAppUser === '' ? 'hidden' : 'visible' }}
              variant="outlined"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">Send a message</InputLabel>
              <OutlinedInput
                inputRef={sendMessageInputRef}
                fullWidth
                // inputProps={{onkeypress: sendMessage()}}
                id="outlined-adornment-password"
                type="text"
                onChange={null}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      color="primary"
                      sx={{ p: '10px' }}
                      aria-label="directions"
                    >
                      <ArrowForward />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </div>
      </Box>
    </Box>
  );
}

ChatDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default ChatDrawer;
