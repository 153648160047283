import {LoadingButton} from "@material-ui/lab";
import {
  Box,
  Button,
  Container, Dialog, DialogActions, DialogContent, DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import { Link } from "react-router-dom";
import PricingTable from "../../../components/Table/Vehicle/PricingTable";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";

const PublishAction = () => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const { onPost } = useHttpPost("/api/vehicles/price-table/publish");

  const onPublish = async e => {
    try {
      e.preventDefault();
      await onPost();
      dataCtx.setSnackbarConfig({ open: true, message: "Published Successfully!", severity: "success" });
      setOpen(false);
      window.location.reload();
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box p={2}>
          <DialogTitle>Publish Price Table</DialogTitle>
          <DialogContent>
            Do you want to publish the price table?
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onPublish}>
              Publish
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Publish
      </Button>
    </Box>
  );
}

const ModifyColAction = () => {
  return (
    <Button
      component={Link} to="./period"
      variant="contained" color="secondary"
    >
      Modify Column
    </Button>
  );
}

const ModifyTableAction = () => {
  return (
    <Button component={Link} to="./edit" variant="contained" color="info">
      Edit Price Table
    </Button>
  );
}

const BuildAction = ({ onReload }) => {
  const { onPost, loading } = useHttpPost("/api/vehicles/price/build");

  const onSubmit = async e => {
    e.preventDefault();
    await onPost();
    await onReload();
  }

  return (
    <LoadingButton variant="contained" loading={loading} onClick={onSubmit}>Build</LoadingButton>
  );
}

BuildAction.propTypes = {
  onReload: PropTypes.func,
}

export default function VehiclePricingTable() {
  const { data, onGet } = useHttpGet("/api/vehicles/price-table");
  const { data: originalData } = useHttpGet("/api/vehicles/price-table/latest");
  const { data: columns, onGet: onColsGet } = useHttpGet("/api/vehicles/price-period");
  const onReload = async () => {
    await onGet();
    await onColsGet();
  }

  let version = "";
  if (data?.length > 0 && data[0].prices?.length > 0)
    version = `, Version: ${data[0].prices[0].version}`;

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <BuildAction onReload={onReload} />
        <PublishAction/>
        <ModifyTableAction/>
        <ModifyColAction onReload={onReload} />
      </Stack>
      <Box mt={3}>
        <Typography variant="h4" mb={3}>
          Pricing Table {version}
        </Typography>
        <PricingTable data={data ?? []} originalData={originalData ?? []} cols={columns}/>
      </Box>
    </Container>
  );
}