import React, { useContext, useState } from 'react';
import {
  Alert,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import dayjs from 'dayjs';
import {cloudFunctionPath} from "../../../../cloudFunctionApiConfig";
import useCloudFuncPost from '../../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import { FcAutocomplete, FcDateField } from '../../FormFields';
import DataContext from '../../../../store/DataContext';

// Edit Feature disable, if want to disable -> Uncomplete -> Complete Again
export default function WorkshopMajorJobCompleteForm({ id, onSuccess }) {
  const dataCtx = useContext(DataContext);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/job/${id}/complete`);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const formik = useFormik({
    initialValues: {
      endDate: dayjs(),
      remark: "",
      parkingTicket: "No"
    },
    validationSchema: Yup.object({
      endDate: Yup.date().required("Please select an End Date").nullable("never"),
      remark: Yup.string()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const uploadData = {
          remark: formik.values.remark,
          endDate: formik.values.endDate.toDate(),
          parkingTicket: formik.values.parkingTicket === "Yes",
        };

        await onPost(uploadData);
        onOpenSnackbar(`Successfully Completed Job`);
        setTimeout(onSuccess, 1000);
      } catch (error) {
        console.log(error.message);
        onOpenSnackbar(error.message, "error");
      }
      setSubmitting(false);
    }
  })

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12}>
              <FcDateField formik={formik} name="endDate" label="End Date" />
            </Grid>

            <Grid item xs={12}>
              <FcAutocomplete
                formik={formik}
                name="remark"
                label="Special Remarks | 特别备注"
                options={ ["Some repair not done due to COE ending"] }
                freeSolo
              />
            </Grid>

            <Grid item xs={12} ml={1}>
              <Typography>Gave out Parking Ticket?</Typography>
              <RadioGroup
                row
                defaultValue="No"
                value={formik.values.parkingTicket}
                onChange={(event) => formik.setFieldValue("parkingTicket", event.target.value)}
              >
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel value="Yes" label="Yes" control={<Radio/>} />
              </RadioGroup>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
            sx={{ marginTop: 1.5, width: { xs: "100%", md: "auto" }}}
          >
            Complete
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}

WorkshopMajorJobCompleteForm.propTypes = {
  id: PropTypes.string,
  onSuccess: PropTypes.func,
}