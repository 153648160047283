import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Button,
  Container,
  Snackbar,
  Alert,
  Stack,
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Grid,
  FormControl,
  DialogContent,
  Checkbox
} from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@material-ui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Link } from 'react-router-dom';
import CreateLeaveForm from '../../../components/Form/Leave/CreateLeaveForm';
import FcTable from '../../../components/Table/FcTable';
import useGetDocument from '../../../hooks/firebase/useGetDocument';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

const DateIncludeFilter = ({ onReload }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const onFilter = async (e) => {
    e.preventDefault();
    await onReload({
      dateStart: moment(startDate).toISOString(),
      dateEnd: moment(endDate).toISOString()
    });
  };

  return (
    <Grid container spacing={3} sx={{ marginBottom: 5 }}>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              disableMaskedInput
              label="Start Date"
              inputFormat="DD/MM/YYYY"
              onChange={(val) => setStartDate(val)}
              value={startDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>

      <Grid item xs={2}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              disableMaskedInput
              label="End Date"
              inputFormat="DD/MM/YYYY"
              onChange={(val) => setEndDate(val)}
              value={endDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>

      <Grid item xs={7} />

      <Grid item xs={1} sx={{ textAlign: 'end' }}>
        <Button variant="contained" onClick={onFilter}>
          Filter
        </Button>
      </Grid>
    </Grid>
  );
};

DateIncludeFilter.propTypes = {
  onReload: PropTypes.func
};

const FilterGroup = ({ leaveType, onReload, filters, onFilterChange }) => {
  const allFilters = [{ name: 'All', value: 'all' }, ...filters];

  const [filter, setFilter] = useState('all');

  const onChange = (e, newFilter) => {
    setFilter(newFilter);
    onFilterChange(e, newFilter);
  };

  const leaveTypes = [
    { name: 'All', value: '' },
    { name: 'Annual Leave', value: 'al' },
    { name: 'Medical Leave', value: 'mc' }
  ];

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start">
      Quick Filters:&nbsp;&nbsp;
      <ToggleButtonGroup exclusive aria-label="leave types" value={leaveType} onChange={onReload}>
        {leaveTypes.map(({ name, value }, index) => (
          <ToggleButton
            sx={{
              '&.Mui-selected, &.Mui-selected:hover': {
                color: 'white !important',
                backgroundColor: '#00AB55 !important'
              }
            }}
            value={value}
            key={index}
          >
            {name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      &nbsp;&nbsp;
      <ToggleButtonGroup exclusive aria-label="leave filter" value={filter} onChange={onChange}>
        {allFilters.map((filter, index) => (
          <ToggleButton
            sx={{
              '&.Mui-selected, &.Mui-selected:hover': {
                color: 'white !important',
                backgroundColor: '#00AB55 !important'
              }
            }}
            key={index}
            value={filter.value === 'all' ? 'all' : JSON.stringify(filter.value)}
          >
            {filter.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};

FilterGroup.propTypes = {
  leaveType: PropTypes.string,
  onReload: PropTypes.func,
  filters: PropTypes.array,
  onFilterChange: PropTypes.func
};

const ApproveAction = ({ row, onOpenSnackbar, onReload }) => {
  const [remarks, setRemarks] = useState('');
  const [open, setOpen] = useState(false);
  const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/leave/approve`);

  const onApprove = async (e) => {
    e.preventDefault();
    try {
      await onPut({ leaveId: row.id, status: 1, remarks });
      await onReload();
      onOpenSnackbar('Leave request approved successfully!');
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 3 }}>
          <DialogTitle id="alert-dialog-title">Do you want to approve leave request?</DialogTitle>
          <DialogContent>
            <Box py={1}>
              <TextField
                fullWidth
                multiline
                label="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" onClick={onApprove} autoFocus loading={loading}>
              Approve
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        disabled={row.leaveType === 'mc' || row.leaveStatus !== 'pending'}
      >
        Approve
      </Button>
    </>
  );
};

ApproveAction.propTypes = {
  row: PropTypes.object,
  onOpenSnackbar: PropTypes.func,
  onReload: PropTypes.func
};

const RejectAction = ({ row, onOpenSnackbar, onReload }) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');
  const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/leave/approve`);

  const onReject = async () => {
    try {
      await onPut({ leaveId: row.id, status: 0, rejectReason: reason });
      await onReload();
      onOpenSnackbar('Leave request rejected successfully!');
      setOpen(false);
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 3 }}>
          <DialogTitle id="alert-dialog-title">Do you want to reject leave request?</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Reject Reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={onReject}
              autoFocus
              loading={loading}
            >
              Reject
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        disabled={row.leaveType === 'mc' || row.leaveStatus !== 'pending'}
        variant="contained"
        color="error"
        onClick={() => setOpen(true)}
      >
        Reject
      </Button>
    </>
  );
};

RejectAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
  onOpenSnackbar: PropTypes.func
};

const DocumentPropTypes = {
  url: PropTypes.string,
  label: PropTypes.string
};

const OtherDocument = ({ url }) => {
  return (
    <Button variant="contained" color="secondary" component={Link} to={url} target="_blank">
      View
    </Button>
  );
};

OtherDocument.propTypes = DocumentPropTypes;
const ImageDocument = ({ url, label }) => <Box component="img" alt={label} src={url} />;
ImageDocument.propTypes = DocumentPropTypes;

const ViewAction = ({ row }) => {
  const { data: leaveData } = useGetDocument('fcadmin/leave');
  const leaveTypes = leaveData?.leaveType;

  const [open, setOpen] = useState(false);

  const details = [
    { label: 'Name', value: row?.name },
    { label: 'Leave Type', value: leaveTypes ? leaveTypes[row?.leaveType] : '' },
    { label: 'Start Date', value: moment(row.dateStart).format('DD MMM YYYY') },
    { label: 'End Date', value: moment(row.dateEnd).format('DD MMM YYYY') },
    { label: 'Full / Half Day', value: row.isHalfDay ? 'Half' : 'Full' },
    { label: 'Total Days', value: `${row.totalDay} ${row.totalDay > 1 ? 'days' : 'day'}` },
    { label: 'Leave Status', value: capitalizeFirstLetter(row.leaveStatus) },
    { label: 'Leave Applied', value: moment(row.createdDate).format('DD MMM YYYY') }
  ];

  if (row?.approveDate) {
    details.push({
      label: 'Approved/Rejected Date',
      value: moment(row?.approveDate).format('DD MMM YYYY')
    });
  }

  if (row?.rejectReason) {
    details.push({ label: 'Rejection Reason', value: row.rejectReason });
  }

  if (row?.cancelReason) {
    details.push({ label: 'Cancelation Reason', value: row.cancelReason });
  }

  if (row?.medicalFee) {
    details.push({ label: 'Medical Fee', value: row.medicalFee });
  }

  const attachments = [];
  const renderAttachment = (url, label) => {
    const fileType = url.split('.').slice(-1)[0];
    const imageTypes = ['jpg', 'png', 'jpeg'];
    if (imageTypes.includes(fileType)) <ImageDocument url={url} label={label} />;
    return <OtherDocument url={url} />;
  };
  if (row?.mcAttachment) {
    attachments.push({ label: 'MC', value: renderAttachment(row.mcAttachment, 'MC') });
  }
  if (row?.receiptAttachment) {
    attachments.push({
      label: 'Receipt',
      value: renderAttachment(row.receiptAttachment, 'Receipt')
    });
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <Box p={2}>
          <DialogTitle>Leave Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              {details?.map(({ label, value }, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Typography variant="h6">{label}</Typography>
                  {value}
                </Grid>
              ))}
              {attachments?.map(({ label, value }, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Typography variant="h6">{label}</Typography>
                  {value}
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        View
      </Button>
    </>
  );
};

ViewAction.propTypes = {
  row: PropTypes.object
};

const CreateAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Create Leave Application</DialogTitle>
        <DialogContent>
          <CreateLeaveForm onClose={() => setOpen(false)} onReload={onReload} />
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Create Leave
      </Button>
    </>
  );
};

CreateAction.propTypes = {
  onReload: PropTypes.func
};

const LeaveApplicationList = () => {
  const { data: leaveData } = useGetDocument('fcadmin/leave');
  const leaveTypes = leaveData?.leaveType;

  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [leaveType, setLeaveType] = useState('');
  const [filterModel, setFilterModel] = useState({ items: [] });

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/leave/userlist`);

  const filters = [
    {
      name: 'Pending',
      value: { columnField: 'leaveStatus', operatorValue: 'equals', value: 'pending' }
    },
    {
      name: 'Approved',
      value: { columnField: 'leaveStatus', operatorValue: 'equals', value: 'approved' }
    },
    {
      name: 'Rejected',
      value: { columnField: 'leaveStatus', operatorValue: 'equals', value: 'rejected' }
    },
    {
      name: 'Cancelled',
      value: { columnField: 'leaveStatus', operatorValue: 'equals', value: 'canceled' }
    }
  ];

  const onFilterChange = (e, newFilter) => {
    if (newFilter !== 'all') {
      const data = JSON.parse(newFilter);
      setFilterModel({ items: [data] });
    } else {
      setFilterModel({ items: [] });
    }
  };

  const onOpenSnackbar = (message, type = 'success') => {
    setOpenSnackbar(true);
    setSnackbarInfo({ message, type });
  };

  const onReload = async (e) => {
    e.preventDefault();
    await onGet({ leaveType: e.target.value });
    setLeaveType(e.target.value);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      align: 'center',
      headerAlign: 'center',
      minWidth: 50,
      sortable: false,
      showIndex: true,
      sticky: true
      // renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    {
      field: 'name',
      headerName: 'Name',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      sticky: true
    },
    {
      field: 'leaveType',
      headerName: 'Leave Type',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      sticky: true,
      renderCell: ({ row }) => (leaveTypes ? leaveTypes[row?.leaveType] : '')
    },
    {
      field: 'dateStart',
      headerName: 'Leave Start Date',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      sticky: true,
      renderCell: ({ row }) => (row?.dateStart ? moment(row.dateStart).format('DD-MM-YYYY') : '')
    },
    {
      field: 'dateEnd',
      headerName: 'Leave End Date',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      sticky: true,
      renderCell: ({ row }) => (row?.dateEnd ? moment(row.dateEnd).format('DD-MM-YYYY') : '')
    },
    {
      field: 'totalDay',
      headerName: 'Total Day',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    },
    {
      field: 'leaveStatus',
      headerName: 'Leave Status',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 150,
      renderCell: ({ row }) => capitalizeFirstLetter(row.leaveStatus)
    },
    {
      field: 'isHalfDay',
      headerName: 'Half Day',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 100,
      renderCell: ({ row }) => <Checkbox checked={row.isHalfDay} />
    },
    {
      field: 'halfDayType',
      headerName: 'AM / PM',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 100
    },
    {
      field: 'approveDate',
      headerName: 'Date Approved / Rejected',
      align: 'center',
      headerAlign: 'center',
      minWidth: 200,
      renderCell: ({ row }) =>
        row?.approveDate ? moment(row.approveDate).format('DD-MM-YYYY') : ''
    },
    {
      field: 'createdDate',
      headerName: 'Date Applied',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      renderCell: ({ row }) =>
        row?.createdDate ? moment(row.createdDate).format('DD-MM-YYYY') : ''
    },
    { field: 'remarks', headerName: 'Remarks', sortable: false, minWidth: 200 },
    { field: 'rejectReason', headerName: 'Rejection Reason', sortable: false, minWidth: 200 },
    { field: 'cancelReason', headerName: 'Cancellation Reason', sortable: false, minWidth: 200 },
    {
      field: 'medicalFee',
      headerName: 'Medical Fee',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 150,
      renderCell: ({ row }) => (row?.medicalFee ? `${row.medicalFee} SGD` : '')
    },
    {
      field: '',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="center" spacing={2}>
          <ViewAction row={row} />
          <ApproveAction row={row} onReload={onGet} onOpenSnackbar={onOpenSnackbar} />
          <RejectAction row={row} onReload={onGet} onOpenSnackbar={onOpenSnackbar} />
        </Stack>
      )
    }
  ];

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Leave Application List
        </Typography>
      </Stack>
      <DateIncludeFilter onReload={onGet} />
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <FilterGroup
          leaveType={leaveType}
          onReload={onReload}
          filters={filters}
          onFilterChange={onFilterChange}
        />
        <Stack direction="row" spacing={2}>
          <CreateAction onReload={onGet} />
          <Button
            component={Link}
            to="/dashboard/leave/calendar"
            target="_blank"
            startIcon={<CalendarMonthIcon />}
            variant="contained"
            color="secondary"
          >
            Calendar
          </Button>
        </Stack>
      </Stack>

      <FcTable columns={columns} rows={data?.data ?? []} filterModel={filterModel} />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '100%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LeaveApplicationList;
