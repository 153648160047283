import {Button, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import useHttpGet from "../../../../hooks/http/useHttpGet";

export default function MalaysiaSurchargeQuestionForm({ formik }) {
  const costs = [0, 100, 150];

  const [isDriveToMalaysia, setDriveToMalaysia] = useState("no");
  const [onWaive, setOnWaive] = useState(false);

  const { data: surchargeTypes } = useHttpGet("/api/surcharge/type");
  const malaysiaSurchargeType = surchargeTypes?.find(s => s.name.includes("Malaysia"));
  const [malaysiaSurcharge, setMalaysiaSurcharge] = useState(formik.values.surcharges.find(s => s.surchargeTypeId === malaysiaSurchargeType?.id));

  const isIncludeMalaysiaSurcharge = () => {
    const { surcharges } = formik.values;
    return surcharges.filter(s => s.surchargeTypeId === malaysiaSurchargeType?.id).length > 0;
  }

  const handleSelect = e => {
    e.preventDefault();
    const driveToMalaysia = e.target.value === "yes";
    setDriveToMalaysia(e.target.value);

    if (!surchargeTypes || malaysiaSurchargeType === null) return;

    const { surcharges } = formik.values;
    if (driveToMalaysia && !isIncludeMalaysiaSurcharge()) {
      formik.setFieldValue("surcharges", [...surcharges, { surchargeTypeId: malaysiaSurchargeType.id, amount: malaysiaSurchargeType.price }]);
      setMalaysiaSurcharge({ surchargeTypeId: malaysiaSurchargeType.id, amount: malaysiaSurchargeType.price });
    } else if (!driveToMalaysia && isIncludeMalaysiaSurcharge()) {
      formik.setFieldValue("surcharges", surcharges.filter(s => s.surchargeTypeId !== malaysiaSurchargeType.id));
    }
  }

  const handleCostSelect = e => {
    e.preventDefault();

    const { surcharges } = formik.values;
    formik.setFieldValue("surcharges", [
      ...surcharges.filter(s => s.surchargeTypeId !== malaysiaSurchargeType.id),
      { surchargeTypeId: malaysiaSurchargeType.id, amount: Number(e.target.value), waiveReason: malaysiaSurcharge.waiveReason }
    ]);
    setMalaysiaSurcharge({ surchargeTypeId: malaysiaSurchargeType.id, amount: Number(e.target.value), waiveReason: malaysiaSurcharge.waiveReason });
  }

  const initialValue = () => {
    if (!surchargeTypes || malaysiaSurchargeType === null) return;
    if (isIncludeMalaysiaSurcharge()) {
      setDriveToMalaysia("yes");
      const surcharge = formik.values.surcharges.find(s => s.surchargeTypeId === malaysiaSurchargeType?.id);
      setMalaysiaSurcharge(surcharge);
      if (surcharge.amount === 0 || surcharge.waiveReason) setOnWaive(true);
    } else {
      setDriveToMalaysia("no");
    }
  }

  const handleOnWaive = (onWaive) => {
    if (!onWaive) {
      formik.setFieldValue("surcharges", [
        ...formik.values.surcharges.filter(s => s.surchargeTypeId !== malaysiaSurchargeType.id),
        { surchargeTypeId: malaysiaSurchargeType.id, amount: costs[1] },
      ]);
      setMalaysiaSurcharge({ surchargeTypeId: malaysiaSurchargeType.id, amount: costs[1] });
    }
    setOnWaive(onWaive);
  }

  const handleWaiveReason = (e) => {
    e.preventDefault();
    const surcharge = formik.values.surcharges.find(s => s.surchargeTypeId === malaysiaSurchargeType.id);
    formik.setFieldValue("surcharges", [
      ...formik.values.surcharges.filter(s => s.surchargeTypeId !== malaysiaSurchargeType.id),
      { ...surcharge, waiveReason: e.target.value },
    ]);
    setMalaysiaSurcharge({ ...malaysiaSurcharge, waiveReason: e.target.value });
  }

  useEffect(() => {
    initialValue();
  }, [surchargeTypes]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Is customer need drive to Malaysia?</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          <RadioGroup row value={isDriveToMalaysia} onChange={handleSelect}>
            <FormControlLabel control={<Radio/>} label="Yes" value="yes"/>
            <FormControlLabel control={<Radio/>} label="No" value="no"/>
          </RadioGroup>
          {isDriveToMalaysia === "yes" && (
            <Button variant="contained" onClick={() => handleOnWaive(!onWaive)}>
              {onWaive ? "Unwaive" : "Waive"}
            </Button>
          )}
        </Stack>
      </Grid>
      {isDriveToMalaysia === "yes" && (
        <>
          <Grid item xs={12}>
            <RadioGroup value={String(malaysiaSurcharge?.amount)} onChange={handleCostSelect}>
              {costs.filter(cost => onWaive || cost > 0).map((cost, index) => (
                <FormControlLabel key={index} control={<Radio/>} label={`$${cost}`} value={cost}/>
              ))}
            </RadioGroup>
          </Grid>
          {onWaive && <Grid item xs={12}>
            <TextField
              fullWidth
              label="Waive Reason"
              value={malaysiaSurcharge?.waiveReason ?? ""}
              onChange={handleWaiveReason}
            />
          </Grid>}
        </>
      )}
    </Grid>
  );
}

MalaysiaSurchargeQuestionForm.propTypes = {
  formik: PropTypes.object,
}