import {Button, Container, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {GridToolbar} from "@mui/x-data-grid";
import {StyledDataGrid} from "./styles";
import {fetchEnhcApi} from "../../utils/fetchApi";

export default function VehicleDisposingListing() {
  const pageOptions = [25, 50, 100];

  const [pageSize, setPageSize] = useState(25);
  const [data, setData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const logcardHost = `https://renty.com.sg/${process.env.REACT_APP_ROAD_RUNNER_ROUTE}`;
  const columns = [
    {
      field: 'id', headerName: 'No.', align: "center", headerAlign: "center", width: 50, sortable: false,
      valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
    },
    { field: 'VehicleNo', headerName: 'Car Plate', align: "center", headerAlign: "center", width: 100 },
    {
      field: 'VehicleModel', headerName: 'Make / Model', align: "center", headerAlign: "center", width: 150,
      renderCell: ({row}) => `${row.VehicleMake} ${row.VehicleModel}`
    },
    { field: 'CompanyName', headerName: 'Company', align: "center", headerAlign: "center", width: 150 },
    {
      field: 'RegDate', headerName: 'COE / PARF', align: "center", headerAlign: "center", width: 100,
      valueFormatter: ({ value }) => moment().diff(moment(value), "year") > 10 ? "COE" : "PARF"
    },
    {
      field: 'CarEndDate', headerName: 'COE Expiry', align: "center", headerAlign: "center", width: 100,
      valueFormatter: ({value}) => moment(value).format("DD-MM-YYYY")
    },
    {
      field: 'ActualEndDate', headerName: 'DISPOSAL DUE DATE', align: "center", headerAlign: "center", width: 175,
      valueFormatter: ({value}) => moment(value).format("DD-MM-YYYY")
    },
    {
      field: 'TaskName', headerName: 'Disposal Status', sortable: false, width: 200,
      valueFormatter: ({value}) => value ?? "COE Ended"
    },
    {
      field: 'DoneAt', headerName: 'Done Date', align: "center", headerAlign: "center", width: 125,
      valueFormatter: ({value}) => value ? moment(value).format("DD-MM-YYYY") : ""
    },
    {
      field: '', headerName: 'Action', align: "center", headerAlign: "center", width: 250, sortable: false, disableExport: true,
      renderCell: ({row}) => (
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained" color="secondary"
            component={Link} to={`${logcardHost}/documents/${row.LogCard}`} target="_blank"
          >
            Logcard
          </Button>
          <Button
            variant="contained"
            component={Link} to={`../disposal/${row.VehicleNo}`}
          >
            Disposal
          </Button>
        </Stack>
      )
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchEnhcApi("GetDisposingVehicleListing")
      .then(res => {
        setData(res.map(
          row => ({
            ...row,
            ActualEndDate: moment(row.CarEndDate)
              .add(30, "days")
              .format("YYYY-MM-DD")
          })
        ))
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
    fetchEnhcApi("GetVehicleDisposalTask")
      .then(res => setTasks(res))
      .catch(console.error);
  }, []);

  const themeSelect = (row) => {
    const currentDate = moment();
    const endDate = moment(row.ActualEndDate);
    const handoverTask = tasks.find(task => task.TaskName === "Handover car to exporter");
    const uploadTask = tasks.find(task => task.TaskName === "Upload disposal documents in Onemotoring");
    const diff = endDate.diff(currentDate, "days");

    if (diff <= 7 && row.TaskId < uploadTask?.id) return 'Ended';
    if (diff <= 14 && row.TaskId < handoverTask?.id) return 'Handover';
    return 'primary';
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom>
        Disposing Vehicle Listing
      </Typography>
      <StyledDataGrid
        components={{ Toolbar: GridToolbar }}
        getRowClassName={({row}) => `vehicle-theme--${themeSelect(row)}`}
        rows={data} columns={columns}
        loading={loading}
        pageSize={pageSize} rowsPerPageOptions={pageOptions}
        onPageSizeChange={size => setPageSize(size)}
        disableSelectionOnClick
        sx={{ height: window.innerHeight * 0.75 }}
      />
    </Container>
  );
}