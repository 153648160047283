import dayjs from 'dayjs';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';


export const otrColumns = [
  {
    field: 'no',
    headerName: 'No.',
    minWidth: 10,
    headerAlign: 'center',
    align: 'center',
    showIndex: true,
    sticky: true
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    minWidth: 130,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => dayjs(row.startDate).format('DD MMM YYYY'),
    sticky: true
  },
  {
    field: 'hirerName',
    headerName: 'Hirer',
    align: 'left',
    headerAlign: 'left',
    minWidth: 300,
    sticky: true
  },
  {
    field: 'carPlate',
    headerName: ' Car Plate',
    align: 'center',
    headerAlign: 'center',
    minWidth: 100,
    sticky: true
  },
  {
    field: 'rentalAgreementNo',
    headerName: 'Agreement No.',
    align: 'center',
    headerAlign: 'center',
    minWidth: 145
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 130,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => dayjs(row.endDate).format('DD MMM YYYY')
  },
  {
    field: 'bookingNo',
    headerName: 'Booking No.',
    align: 'center',
    headerAlign: 'center',
    minWidth: 125
  },
  {
    field: 'staff',
    headerName: 'SalesPerson',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'newOrExistingCustomer',
    headerName: 'New/Existing Customer',
    align: 'center',
    headerAlign: 'center',
    minWidth: 195
  },
  {
    field: 'customerType',
    headerName: 'Type',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'depositAmount',
    headerName: 'Deposit',
    align: 'center',
    headerAlign: 'center',
    minWidth: 100,
    renderCell: ({ row }) => Number(row.depositAmount).toFixed(2)
  },
  {
    field: 'rentalRate',
    headerName: 'Rental Rate',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120,
    renderCell: ({ row }) => Number(row.rentalRate).toFixed(2)
  },
  {
    field: 'periodType',
    headerName: 'Period Type',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'billingType',
    headerName: 'Billing Type',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'osAmount',
    headerName: 'OS Amount',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120,
    renderCell: ({ row }) =>
      typeof row.osAmount !== 'undefined' ? Number(row.osAmount).toFixed(2) : ''
  },
  {
    field: 'totalUpfront',
    headerName: 'Total Upfront',
    align: 'center',
    headerAlign: 'center',
    minWidth: 130,
    renderCell: ({ row }) => Number(row.totalUpfront).toFixed(2)
  },
  { field: 'points', headerName: 'OTR', align: 'center', headerAlign: 'center', minWidth: 100 },
  {
    field: 'otrStatus',
    headerName: 'OTR Status',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    minWidth: 150,
    renderCell: ({ row }) => capitalizeFirstLetter(row.otrStatus || '')
  }
];

export const otrUndefinedColumns = [
  {
    field: 'no',
    headerName: 'No.',
    minWidth: 10,
    headerAlign: 'center',
    align: 'center',
    showIndex: true,
    sticky: true
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    minWidth: 130,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => dayjs(row.startDate).format('DD MMM YYYY'),
    sticky: true
  },
  {
    field: 'hirerName',
    headerName: 'Hirer',
    align: 'left',
    headerAlign: 'left',
    minWidth: 300,
    sticky: true
  },
  {
    field: 'carPlate',
    headerName: ' Car Plate',
    align: 'center',
    headerAlign: 'center',
    minWidth: 100,
    sticky: true
  },
  {
    field: 'rentalAgreementNo',
    headerName: 'Agreement No.',
    align: 'center',
    headerAlign: 'center',
    minWidth: 145
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 130,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => dayjs(row.endDate).format('DD MMM YYYY')
  },
  {
    field: 'bookingNo',
    headerName: 'Booking No.',
    align: 'center',
    headerAlign: 'center',
    minWidth: 125
  },
  {
    field: 'staff',
    headerName: 'SalesPerson',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'newOrExistingCustomer',
    headerName: 'New/Existing Customer',
    align: 'center',
    headerAlign: 'center',
    minWidth: 195
  },
  {
    field: 'customerType',
    headerName: 'Type',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'depositAmount',
    headerName: 'Deposit',
    align: 'center',
    headerAlign: 'center',
    minWidth: 100,
    renderCell: ({ row }) => Number(row.depositAmount).toFixed(2)
  },
  {
    field: 'rentalRate',
    headerName: 'Rental Rate',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120,
    renderCell: ({ row }) => Number(row.rentalRate).toFixed(2)
  },
  {
    field: 'periodType',
    headerName: 'Period Type',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'billingType',
    headerName: 'Billing Type',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'osAmount',
    headerName: 'OS Amount',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120,
    renderCell: ({ row }) =>
      typeof row.osAmount !== 'undefined' ? Number(row.osAmount).toFixed(2) : ''
  },
  {
    field: 'totalUpfront',
    headerName: 'Total Upfront',
    align: 'center',
    headerAlign: 'center',
    minWidth: 130,
    renderCell: ({ row }) => Number(row.totalUpfront).toFixed(2)
  }, // { field: 'points', headerName: 'Current OTR', align: 'center', headerAlign: 'center', minWidth: 100 },
  {
    field: 'originalOtr',
    headerName: 'Original OTR',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120
  },
  {
    field: 'proposedOtr',
    headerName: 'Proposed OTR',
    align: 'center',
    headerAlign: 'center',
    minWidth: 140
  },
  {
    field: 'proposeRemarks',
    headerName: 'Reason',
    align: 'center',
    headerAlign: 'center',
    minWidth: 150
  },
  {
    field: 'otrStatus',
    headerName: 'OTR Status',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    minWidth: 150,
    renderCell: ({ row }) => capitalizeFirstLetter(row.otrStatus || '')
  }
];

export const priceIncreaseUndefinedColumns = [
  {
    field: 'id',
    headerName: 'No.',
    width: 10,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
  },
  {
    field: 'bookingNo',
    headerName: 'Booking No.',
    align: 'center',
    headerAlign: 'center',
    width: 140
  },
  {
    field: 'rentalAgreementNo',
    headerName: 'Agreement No.',
    align: 'center',
    headerAlign: 'center',
    width: 140
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 125,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => dayjs(params.value).format('DD MMM YYYY')
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 125,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => dayjs(params.value).format('DD MMM YYYY')
  },
  {
    field: 'staff',
    headerName: 'SalesPerson',
    align: 'center',
    headerAlign: 'center',
    width: 100
  },
  {
    field: 'carPlate',
    headerName: 'Car Plate',
    align: 'center',
    headerAlign: 'center',
    width: 100
  },
  {
    field: 'newOrExistingCustomer',
    headerName: 'New/Existing Customer',
    align: 'center',
    headerAlign: 'center',
    minWidth: 195
  },
  {
    field: 'numberOfMonths',
    headerName: 'Duration (Months)',
    align: 'center',
    headerAlign: 'center',
    width: 135
  },
  {
    field: 'billingType',
    headerName: 'Billing Type',
    align: 'center',
    headerAlign: 'center',
    width: 100
  },
  {
    field: 'originalRentalRate',
    headerName: 'Original Rental Rate',
    align: 'center',
    headerAlign: 'center',
    width: 150,
    valueFormatter: (params) => Number(params.value).toFixed(0)
  },
  {
    field: 'originalBaseRentalRate',
    headerName: 'Original Base Rental Rate',
    align: 'center',
    headerAlign: 'center',
    width: 190
  },
  {
    field: 'originalPriceIncrease',
    headerName: 'Original Price Increase Per Day',
    align: 'center',
    headerAlign: 'center',
    width: 240
  },
  {
    field: 'originalPriceIncreasePoints',
    headerName: 'Original Points',
    align: 'center',
    headerAlign: 'center',
    width: 120
  },
  { field: 'hirerName', headerName: 'Hirer', align: 'left', headerAlign: 'left', width: 300 },
  {
    field: 'priceIncreaseStatus',
    headerName: 'Price Increase Status',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    minWidth: 180,
    valueFormatter: (params) => capitalizeFirstLetter(params.value || '')
  },
  {
    field: 'proposedPriceIncreasePoints',
    headerName: 'Proposed Points',
    align: 'center',
    headerAlign: 'center',
    width: 140
  },
  {
    field: 'proposedBaseRentalRate',
    headerName: 'Base Rental Rate (Proposed)',
    align: 'center',
    headerAlign: 'center',
    width: 230
  },
  {
    field: 'proposedRentalRate',
    headerName: 'Rental Rate (Proposed)',
    align: 'center',
    headerAlign: 'center',
    width: 180
  },
  {
    field: 'proposeRemarks',
    headerName: 'Reason',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    minWidth: 200,
    valueFormatter: (params) => capitalizeFirstLetter(params.value || '')
  }
];

export const priceIncreaseColumns = [
  {
    field: 'id',
    headerName: 'No.',
    width: 10,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
  },
  {
    field: 'bookingNo',
    headerName: 'Booking No.',
    align: 'center',
    headerAlign: 'center',
    width: 125
  },
  {
    field: 'rentalAgreementNo',
    headerName: 'Agreement No.',
    align: 'center',
    headerAlign: 'center',
    width: 135
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 130,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => dayjs(params.value).format('DD MMM YYYY')
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 130,
    type: 'date',
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => dayjs(params.value).format('DD MMM YYYY')
  }, // { field: 'periodType', headerName: 'Period Type', align: 'center', headerAlign: 'center', width: 100 },
  {
    field: 'staff',
    headerName: 'SalesPerson',
    align: 'center',
    headerAlign: 'center',
    width: 100
  },
  {
    field: 'carPlate',
    headerName: 'Car Plate',
    align: 'center',
    headerAlign: 'center',
    width: 95
  },
  {
    field: 'carMake',
    headerName: 'Car Make',
    align: 'center',
    headerAlign: 'center',
    width: 100
  },
  {
    field: 'carModel',
    headerName: 'Car Model',
    align: 'center',
    headerAlign: 'center',
    width: 150
  },
  {
    field: 'purchaseType',
    headerName: 'PARF/COE',
    align: 'center',
    headerAlign: 'center',
    width: 115
  },
  {
    field: 'newOrExistingCustomer',
    headerName: 'New/Existing Customer',
    align: 'center',
    headerAlign: 'center',
    minWidth: 195
  }, // { field: 'customerType', headerName: 'Type', align: 'center', headerAlign: 'center', width: 100 },
  {
    field: 'numberOfMonths',
    headerName: 'Duration (Months)',
    align: 'center',
    headerAlign: 'center',
    width: 135
  },
  {
    field: 'billingType',
    headerName: 'Billing Type',
    align: 'center',
    headerAlign: 'center',
    width: 100
  },
  {
    field: 'depositAmount',
    headerName: 'Deposit',
    align: 'center',
    headerAlign: 'center',
    width: 100,
    valueFormatter: (params) => (params.value ? Number(params.value).toFixed(2) : '-')
  },
  {
    field: 'rentalRate',
    headerName: 'Rental Rate',
    align: 'center',
    headerAlign: 'center',
    width: 100,
    valueFormatter: (params) => Number(params.value).toFixed(0)
  }, // { field: 'previousRentalRate', headerName: 'Previous Rental Rate', align: 'center', headerAlign: 'center', width: 100, valueFormatter: (params) => Number(params.value).toFixed(2) },
  {
    field: 'baseRentalRate',
    headerName: 'Base Rental Rate',
    align: 'center',
    headerAlign: 'center',
    width: 130
  }, // { field: 'priceIncrease', headerName: 'Price Increase Per Day', align: 'center', headerAlign: 'center', width: 165 },
  {
    field: 'priceIncreaseRounded',
    headerName: 'Price Increase Per Day',
    align: 'center',
    headerAlign: 'center',
    width: 165
  }, // { field: 'priceIncreaseRounded', headerName: 'Adj.', align: 'center', headerAlign: 'center', width: 10 },
  {
    field: 'pointsPerDay',
    headerName: 'Points Per Day',
    align: 'center',
    headerAlign: 'center',
    width: 110
  },
  {
    field: 'points',
    headerName: 'Points',
    align: 'center',
    headerAlign: 'center',
    width: 100
  },
  { field: 'hirerName', headerName: 'Hirer', align: 'left', headerAlign: 'left', width: 300 },
  {
    field: 'priceIncreaseStatus',
    headerName: 'Price Increase Status',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    minWidth: 180,
    valueFormatter: (params) => capitalizeFirstLetter(params.value || '')
  }
];
