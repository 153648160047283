import { Box, List, ListItemText, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import FcTable from '../../Table/FcTable';
import DataContext from '../../../store/DataContext';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

const LongTerm = ({ startDate, updateParentLoadingState, currentSalesPerson, dayjs }) => {
  const dataCtx = useContext(DataContext);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/otr/list`, true, { startDate });

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lTRRow, setLTRRow] = useState([]);
  const [fetchFromFirebase, setFetchFromFirebase] = useState(false);

  // prettier-ignore
  const columns = [
    { field: 'no', headerName: 'No.', minWidth: 10, headerAlign: 'center', align: 'center', showIndex: true, sticky: true },
    {
      field: 'startDate', headerName: 'Start Date', minWidth: 125, align: 'center', headerAlign: 'center',
      type: 'date', sortable: true, sticky: true,
      renderCell: ({ row }) => dayjs(row.startDate).format('DD MMM YYYY'),
    },
    { field: 'hirerName', headerName: 'Hirer', align: 'left', headerAlign: 'left', minWidth: 350, sticky: true },
    { field: 'carPlate', headerName: ' Car Plate', align: 'center', headerAlign: 'center', minWidth: 120, sticky: true },
    { field: 'carMake', headerName: 'Car Make', align: 'center', headerAlign: 'center', minWidth: 100 },
    { field: 'carModel', headerName: 'Car Model', align: 'center', headerAlign: 'center', minWidth: 150 },
    { field: 'purchaseType', headerName: 'PARF/COE', align: 'center', headerAlign: 'center', minWidth: 115 },
    {
      field: 'endDate', headerName: 'End Date', minWidth: 125, type: 'date', sortable: true, align: 'center', headerAlign: 'center',
      renderCell: ({ row }) => dayjs(row.endDate).format('DD MMM YYYY')
    },
    { field: 'bookingNo', headerName: 'Booking No.', align: 'center', headerAlign: 'center', minWidth: 125 },
    { field: 'rentalAgreementNo', headerName: 'Rental Agreement No.', align: 'center', headerAlign: 'center', minWidth: 190 },
    { field: 'staff', headerName: 'SalesPerson', align: 'center', headerAlign: 'center', minWidth: 120 },
    { field: 'numberOfMonths', headerName: 'Duration (Months)', align: 'center', headerAlign: 'center', minWidth: 200 },
    { field: 'customerType', headerName: 'Type', align: 'center', headerAlign: 'center', minWidth: 120 },
    {
      field: 'depositAmount', headerName: 'Deposit', align: 'center', headerAlign: 'center', minWidth: 100,
      renderCell: ({ row }) => Number(row.depositAmount).toFixed(2)
    },
    {
      field: 'rentalRate', headerName: 'Rental Rate', align: 'center', headerAlign: 'center', minWidth: 130,
      renderCell: ({ row }) => Number(row.rentalRate).toFixed(2)
    },
    { field: 'billingType', headerName: 'Billing Type', align: 'center', headerAlign: 'center', minWidth: 130 },
    { field: 'longTerm', headerName: 'Points', align: 'center', headerAlign: 'center', minWidth: 100 }
  ];

  const onDateChange = async () => {
    setLTRRow([]);
    await onGet({ startDate });
  };
  useEffect(() => {
    onDateChange();
  }, [startDate]);


  useEffect(() => {
    updateParentLoadingState(true);
    setLoading(true);
    setLTRRow([]);

    // As observation during the first round, when the data is fetched
    // data turns out to be empty.
    // so we will just skip the first round by using a boolean
    if (initialized) {
      if (data?.data && data.data.length > 0) {
        setFetchFromFirebase(true);
        const combinedData = data.data
          .flatMap((currentValue) =>
            currentValue.data.map((item) => ({
              ...item,
              docId: currentValue.id,
              staffId: currentValue.userId
            }))
          )
          .filter(
            (booking) =>
              booking.contractType === 'LongTerm' &&
              booking.newOrExistingCustomer === 'New' &&
              dayjs(booking.startDate) >= dayjs(startDate).startOf('month')
          )
          .map((booking) => {
            const duration = dayjs(booking.endDate).diff(booking.startDate, 'day');
            const numberOfMonths = Math.floor(duration / 28);
            const longTerm = Math.min(numberOfMonths, 3);
            return { ...booking, numberOfMonths, longTerm };
          });

        setLTRRow(combinedData);
        onOpenSnackbar(`${dayjs(startDate).format('MMMM')} Long Term loaded successfully!`);
      } else {
        setFetchFromFirebase(false);
        console.log('no records in firestore!!');
        onOpenSnackbar(`No data for ${dayjs(startDate).format('MMMM')}`, 'error');
      }
    }
    setInitialized(true);
    setLoading(false);
    updateParentLoadingState(false);
  }, [data]);

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {fetchFromFirebase && (
          <>
            {currentSalesPerson ? (
              <span>
                Total Long Term Points for {currentSalesPerson}:&nbsp;
                {loading ? (
                  <strong>Fetching from DB...</strong>
                ) : (
                  <strong>
                    {lTRRow
                      .filter((booking) => booking.staff === currentSalesPerson)
                      .reduce((total, row) => {
                        return total + row.longTerm;
                      }, 0)}
                  </strong>
                )}
              </span>
            ) : (
              ''
            )}
          </>
        )}
        {!fetchFromFirebase && !loading && (
          <span>No {dayjs(startDate).format('MMMM YYYY')} Long Term Points in Firestore!</span>
        )}
      </Typography>

      {fetchFromFirebase && lTRRow.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Long Term Contract (&gt; 1 Month)
          </Typography>
          <FcTable
            rows={lTRRow
              .filter((booking) => booking.staff === currentSalesPerson)
              .map((row, index) => ({ ...row, no: index }))}
            columns={columns}
          />
        </>
      )}
      <Box mt={2}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItemText
            primary="Notes"
            primaryTypographyProps={{ fontSize: 14, fontWeight: 'bold' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Long-term points will be displayed only if there is OTR data available for that particular month.*"
            primaryTypographyProps={{ fontSize: 12, fontWeight: 'light' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Long-term points are determined by the contracts categorized as 'Long Term' listed under Commission Point - OTR.*"
            primaryTypographyProps={{ fontSize: 12, fontWeight: 'light' }}
          />
        </List>
      </Box>
    </>
  );
};

LongTerm.propTypes = {
  startDate: PropTypes.string,
  updateParentLoadingState: PropTypes.func,
  currentSalesPerson: PropTypes.string,
  dayjs: PropTypes.func
};

export default LongTerm;
