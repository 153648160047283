import {Grid} from "@mui/material";
import dayjs from "dayjs";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useContext} from "react";
import * as Yup from "yup";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import DataContext from "../../../store/DataContext";
import {FcSelectField, SubmitBtnGroup} from "../FormFields";

export default function CancelCardForm({ row, onReload, onClose }) {
  const dataCtx = useContext(DataContext);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/cancel`);

  const reasons = [
    { label: "Lost", value: 1 },
    { label: "Stolen", value: 2 },
    { label: "Card no longer required", value: 3 },
  ];

  const targetStatusList = [
    { label: "Temporary Block", value: "TemporaryBlock" },
    { label: "Unblock", value: "Unblock" },
    { label: "Block", value: "Block" },
    { label: "Damaged", value: "Damaged" },
  ];

  const formik = useFormik({
    initialValues: {
      pan: row?.pan,
      reasonId: 1,
      targetStatus: "",
      cardId: row?.id,
      expiryDate: row?.expiryDate ? dayjs(row.expiryDate).format("YYYYMMDD") : "",
    },
    validationSchema: Yup.object().shape({
      reasonId: Yup.number().required("Reason is required!"),
      targetStatus: Yup.string().required("Target status is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost(values);
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Card assigned successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcSelectField formik={formik} label="Reason" name="reasonId" items={reasons}/>
          </Grid>
          <Grid item xs={12}>
            <FcSelectField formik={formik} label="Target Status" name="targetStatus" items={targetStatusList}/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Submit" onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

CancelCardForm.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}