import { Divider, Grid, Paper, Typography } from '@mui/material';
import PropTypes from "prop-types";
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import WorkshopAppointmentDialogItem from '../../Details/Workshop/WorkshopAppointmentDialogItem';

function DayVerticalGrid({ date, data, onReload }) {
  const navigate = useNavigate();

  const emptyOnClick = (filtered, hour) => {
    if (filtered.length === 0) {
      navigate(`./new?time=${dayjs(date).add(hour, 'hour').toISOString()}`)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} height="40px" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h6">{dayjs(date).format("ddd, DD MMM")}</Typography>
      </Grid>
      {
        [9, 10, 11, 12, 13, 14, 15, 16, 17].map((hour) => {
          const filtered = data.filter((apmt) => new Date(apmt.time).getHours() === hour);

          return (
            <Grid
              item key={hour} xs={12} height="60px"
              bgcolor={hour % 2 !== 0 ? "#F3F5F8" : "white" } display="flex"
              justifyContent="center" alignItems="center"
              onClick={() => emptyOnClick(filtered, hour)}
            >
              { filtered.map((apmt) => <WorkshopAppointmentDialogItem key={apmt.time} apmt={apmt} onReload={onReload} />) }
            </Grid>
          );
        })
      }
    </Grid>
  );
}

DayVerticalGrid.propTypes = {
  date: PropTypes.object,
  data: PropTypes.array,
  onReload: PropTypes.func,
}

export default function WorkshopAppointmentGrid({ startOfWeek, data, onReload }) {
  const hours = [
    '',
    '09 - 10',
    '10 - 11',
    '11 - 12',
    '12 - 13',
    '13 - 14',
    '14 - 15',
    '15 - 16',
    '16 - 17',
    '17 - 18',
  ];

  const dates = [
    startOfWeek,
    startOfWeek + 86400000,
    startOfWeek + 2 * 86400000,
    startOfWeek + 3 * 86400000,
    startOfWeek + 4 * 86400000,
    startOfWeek + 5 * 86400000,
  ];

  return (
    <Grid container columns={12} spacing={2}>
      <Grid container xs={0.8} mt={2}>
        {hours.map((hour) =>
          <>
            <Grid item xs={12} key={hour} height="60px">
              <Typography textAlign="center" fontWeight={500}>{hour}</Typography>
            </Grid>
            <Divider color="black" />
          </>
        )}
      </Grid>
      {dates.map((date) =>
        <Grid item key={date} xs={1.85}>
          <Paper elevation={10}>
            <DayVerticalGrid
              date={date}
              data={data.filter((ampt) => new Date(ampt.time).toDateString() === new Date(date).toDateString())}
              onReload={onReload}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

WorkshopAppointmentGrid.propTypes = {
  startOfWeek: PropTypes.number,
  data: PropTypes.object,
  onReload: PropTypes.func
}