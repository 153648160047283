import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Button,
  Container,
  Snackbar,
  Alert,
  Stack,
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@material-ui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import LeaveApplyForm from '../../components/Form/Leave/LeaveApplyForm';
import FcTable from '../../components/Table/FcTable';
import useGetDocument from '../../hooks/firebase/useGetDocument';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import useCloudFuncGet from '../../hooks/firebase/cloudFunction/useCloudFuncGet';
import useCloudFuncPut from '../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { cloudFunctionPath } from '../../cloudFunctionApiConfig';

const FilterGroup = ({ filters, onFilterChange }) => {
  const allFilters = [{ name: 'All', value: 'all' }, ...filters];

  const [filter, setFilter] = useState('all');

  const onChange = (e, newFilter) => {
    setFilter(newFilter);
    onFilterChange(e, newFilter);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" my={3}>
      Quick Filters:&nbsp;&nbsp;
      <ToggleButtonGroup exclusive value={filter} onChange={onChange} aria-label="leave filter">
        {allFilters.map((filter, index) => (
          <ToggleButton
            sx={{
              '&.Mui-selected, &.Mui-selected:hover': {
                color: 'white !important',
                backgroundColor: '#00AB55 !important'
              }
            }}
            key={index}
            value={filter.value === 'all' ? 'all' : JSON.stringify(filter.value)}
          >
            {filter.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};

FilterGroup.propTypes = {
  filters: PropTypes.array,
  onFilterChange: PropTypes.func
};

const AddAction = ({ onOpenSnackbar, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <Box sx={{ p: 3 }}>
          <DialogTitle id="alert-dialog-title">Create Leave Application</DialogTitle>
          <DialogContent>
            <LeaveApplyForm
              onClose={() => setOpen(false)}
              onReload={onReload}
              onOpenSnackbar={onOpenSnackbar}
            />
          </DialogContent>
        </Box>
      </Dialog>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<Icon icon={plusFill} />}
      >
        Apply Leave
      </Button>
    </>
  );
};

AddAction.propTypes = {
  onOpenSnackbar: PropTypes.func,
  onReload: PropTypes.func
};

const EditAction = ({ row, onOpenSnackbar, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} maxWidth="lg" fullWidth>
        <Box sx={{ p: 3 }}>
          <DialogTitle id="alert-dialog-title">Update Leave Application</DialogTitle>
          <DialogContent>
            <DialogContentText>Fill in the form below.</DialogContentText>
            <LeaveApplyForm
              leaveData={row}
              onClose={() => setOpen(false)}
              onReload={onReload}
              onOpenSnackbar={onOpenSnackbar}
            />
          </DialogContent>
        </Box>
      </Dialog>
      <Button
        variant="contained"
        disabled={row.leaveStatus === 'canceled'}
        onClick={() => setOpen(true)}
      >
        Edit
      </Button>
    </>
  );
};

EditAction.propTypes = {
  row: PropTypes.object,
  onOpenSnackbar: PropTypes.func,
  onReload: PropTypes.func
};

const CancelAction = ({ row, onOpenSnackbar, onReload }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/leave/void`);

  const onVoid = async () => {
    try {
      setLoading(true);
      await onPut({ leaveId: row.id, cancelReason: reason });
      await onReload();
      onOpenSnackbar('Leave request canceled successfully!');
      setOpen(false);
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 3 }}>
          <DialogTitle id="alert-dialog-title">Do you want to void leave request?</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              value={reason}
              label="Cancel Reason"
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={onVoid}
              autoFocus
              loading={loading}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        disabled={row.leaveStatus === 'rejected' || row.leaveStatus === 'canceled'}
        variant="contained"
        color="error"
        onClick={() => setOpen(true)}
      >
        Cancel
      </Button>
    </>
  );
};

CancelAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
  onOpenSnackbar: PropTypes.func
};

const Details = () => {
  const { data: summary } = useCloudFuncGet(`${cloudFunctionPath}/leave/userSummary`);
  const data = summary?.data;

  const details = [
    {
      label: `Leave Entitlement (${new Date().getFullYear()})`,
      value: data?.curFinancialYearLeaveEntitlement
    },
    { label: `Leave Accrued`, value: data?.leaveAccrued },
    { label: `Leave Taken`, value: data?.leaveApplied },
    { label: `Leave Balance`, value: data?.leaveBalance },
    { label: `Leave To Be Cleared`, value: data?.leaveToBeClear },
    { label: `Service Year`, value: data?.serviceYear },
    {
      label: `Leave Carried Over (${new Date().getFullYear() - 1})`,
      value: data?.carryForwardLastYear
    }
  ];

  return (
    <Box sx={{ p: 3, marginBottom: 3, border: 1, borderRadius: 2 }}>
      <Grid container spacing={3}>
        {details.map(({ label, value }, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Typography variant="h6">{label}</Typography>
            {value}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const UserLeaveAppliedList = () => {
  const { data: leaveData } = useGetDocument('fcadmin/leave');
  const leaveTypes = leaveData?.leaveType;
  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/leave/list`);

  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const filters = [
    {
      name: 'Rejected',
      value: { columnField: 'leaveStatus', operatorValue: 'equals', value: 'rejected' }
    },
    {
      name: 'Approved',
      value: { columnField: 'leaveStatus', operatorValue: 'equals', value: 'approved' }
    },
    {
      name: 'Canceled',
      value: { columnField: 'leaveStatus', operatorValue: 'equals', value: 'canceled' }
    },
    {
      name: 'Pending',
      value: { columnField: 'leaveStatus', operatorValue: 'equals', value: 'pending' }
    }
  ];

  const onFilterChange = (e, newFilter) => {
    if (newFilter !== 'all') {
      const data = JSON.parse(newFilter);
      setFilterModel({ items: [data] });
    } else {
      setFilterModel({ items: [] });
    }
  };

  const onOpenSnackbar = (message, type = 'success') => {
    setOpenSnackbar(true);
    setSnackbarInfo({ message, type });
  };

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      align: 'center',
      headerAlign: 'center',
      minWidth: 75,
      sortable: false,
      showIndex: true,
      sticky: true
      // renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id),
    },
    {
      field: 'dateStart',
      headerName: 'Leave Start Date',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      sticky: true,
      renderCell: ({ row }) => (row?.dateStart ? moment(row.dateStart).format('DD MMM YYYY') : '')
    },
    {
      field: 'dateEnd',
      headerName: 'Leave End Date',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      sticky: true,
      renderCell: ({ row }) => (row?.dateEnd ? moment(row.dateEnd).format('DD MMM YYYY') : '')
    },
    {
      field: 'leaveType',
      headerName: 'Leave Type',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      renderCell: ({ row }) => (leaveTypes ? leaveTypes[row.leaveType] : row.leaveType)
    },
    {
      field: 'totalDay',
      headerName: 'Total Day',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100
    },
    {
      field: 'leaveStatus',
      headerName: 'Leave Status',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 150,
      renderCell: ({ row }) => capitalizeFirstLetter(row?.leaveStatus)
    },
    {
      field: 'isHalfDay',
      headerName: 'Half Day',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 100,
      renderCell: ({ row }) => <Checkbox checked={row.isHalfDay} />
    },
    {
      field: 'halfDayType',
      headerName: 'AM / PM',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 100
    },
    {
      field: 'approveDate',
      headerName: 'Date Approved / Rejected',
      align: 'center',
      headerAlign: 'center',
      minWidth: 225,
      renderCell: ({ row }) =>
        row?.approveDate ? moment(row.approveDate).format('DD MMM YYYY') : ''
    },
    {
      field: 'createdDate',
      headerName: 'Date Applied',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      renderCell: ({ row }) =>
        row?.createdDate ? moment(row.createdDate).format('DD MMM YYYY') : ''
    },
    { field: 'remarks', headerName: 'Remarks', sortable: false, minWidth: 200 },
    { field: 'rejectReason', headerName: 'Rejection Reason', sortable: false, minWidth: 200 },
    { field: 'cancelReason', headerName: 'Cancellation Reason', sortable: false, minWidth: 200 },
    {
      field: 'medicalFee',
      headerName: 'Medical Fee',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150
    },
    {
      field: '',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="row" justifyContent="center" spacing={2}>
          <EditAction row={row} onReload={onGet} onOpenSnackbar={onOpenSnackbar} />
          <CancelAction row={row} onReload={onGet} onOpenSnackbar={onOpenSnackbar} />
        </Stack>
      )
    }
  ];

  const onOpenCalendar = (e) => {
    e.preventDefault();
    window.open(`${window.location.href}/calendar`, '_blank', 'width=500,height=500');
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mb={3}>
        Leave Summary
      </Typography>
      <Details />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Leave Application List
        </Typography>
        <Stack direction="row" spacing={2}>
          <AddAction onOpenSnackbar={onOpenSnackbar} onReload={onGet} />
          <Button
            onClick={onOpenCalendar}
            startIcon={<CalendarMonthIcon />}
            variant="contained"
            color="secondary"
          >
            Calendar
          </Button>
        </Stack>
      </Stack>

      <FilterGroup filters={filters} onFilterChange={onFilterChange} />
      <FcTable columns={columns} rows={data?.data ?? []} filterModel={filterModel} />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '100%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserLeaveAppliedList;
