import {LoadingButton} from "@material-ui/lab";
import {Button, Grid, Stack} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {FcDateField, FcSelectField, FcTextField} from "../FormFields";

export default function RecurringInvoiceFilterForm({ onReload }) {
  const statusOptions = ["Active", "Voided"].map(status => ({ label: status, value: status }));

  const formik= useFormik({
    initialValues: {
      bookingNo: "",
      agreementNo: "",
      status: "",
      startDateFrom: null,
      startDateTo: null,
      endDateFrom: null,
      endDateTo: null,
    },
    validationSchema: Yup.object({
      bookingNo: Yup.string().nullable(),
      agreementNo: Yup.string().nullable(),
      status: Yup.string().nullable(),
      startDateFrom: Yup.date().nullable(),
      startDateTo: Yup.date().nullable(),
      endDateFrom: Yup.date().nullable(),
      endDateTo: Yup.date().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onReload({
        ...values,
        startDateFrom: values?.startDateFrom?.toISOString() ?? "",
        startDateTo: values?.startDateTo?.toISOString() ?? "",
        endDateFrom: values?.endDateFrom?.toISOString() ?? "",
        endDateTo: values?.endDateTo?.toISOString() ?? "",
      });
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDateFrom" label="Start Date From"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDateTo" label="Start Date To"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDateFrom" label="End Date From"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDateTo" label="End Date To"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="bookingNo" label="Booking No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="agreementNo" label="Agreement No."/>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="status" label="Status" items={statusOptions}/>
          </Grid> */}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={formik.resetForm}>
                Clear
              </Button>
              <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                Search
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

RecurringInvoiceFilterForm.propTypes = {
  onReload: PropTypes.func,
}