import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, getDocs, getFirestore, query } from 'firebase/firestore';
import React, { useState } from 'react';

import FcLoader from '../components/FcLoader';

/**
 * @param {{app: FirebaseApp}} props
 */

const AuthContext = React.createContext({
  id: '',
  email: '',
  userDept: '',
  isLoggedIn: false,
  authObj: null,
  onLogin: () => {},
  onLogout: () => {}
});

let authStateLoaded = false;

export const AuthContextProvider = (props) => {
  const auth = getAuth(props.app);
  const fs = getFirestore(props.app);
  const q = query(collection(fs, 'fcadmin/fcadmin/users'));

  const [authInfo, setAuthInfo] = useState({
    authState: false,
    loginStatus: false,
    userEmail: null,
    userName: '',
    userDept: ''
  });

  const loginHandler = () => {
    setAuthInfo((prevState) => ({ ...prevState, loginStatus: true }));
  };

  const handleAuthStateChanged = async (user) => {
    authStateLoaded = false;
    if (!authInfo.authState) {
      try {
        if (user) {
          const sessionTimeout = 1000 * 60 * 60 * 12; // 12 hour
          // const sessionTimeout = 1000 * 60 * 60 * 7; // 7 hour (tested)

          // use timeout function log the user out after 12 hours
          setTimeout(async () => {
            console.log('User session has expired, redirect to login page...');
            await logoutHandler();
          }, sessionTimeout);

          const qSnap = await getDocs(q);
          authStateLoaded = true;
          const userData = qSnap.docs.find((doc) => doc.data().uid === user.uid).data();
          setAuthInfo({
            authState: true,
            loginStatus: true,
            userEmail: user.email,
            userName: userData.alias,
            userDept: userData.department
          });
        } else {
          console.log("authCtx user doesn't exist");
          authStateLoaded = true;
          setAuthInfo({ authState: true, loginStatus: false, userEmail: null, userDept: '' });
        }
      } catch (error) {
        await logoutHandler();
        console.log(error.message);
        window.alert(`Please sign in. Error: ${error.message}`);
      }
    }
  };

  // Put an if statement here so this auth observer is added exactly once only
  if (!authStateLoaded) {
    onAuthStateChanged(auth, handleAuthStateChanged);
  }

  const logoutHandler = async () => {
    await signOut(auth);
    authStateLoaded = true;
    setAuthInfo({ authState: true, loginStatus: false, userEmail: null, userDept: '' });
    // Don't directly navigate to login page because login page doesn't exist in route while logged in. Instead, wait for Auth observer to register logout state and propagate this info to router, then router will know to push login routes instead.
  };

  const contextValue = {
    id: '',
    email: authInfo.userEmail,
    name: authInfo.userName,
    userDept: authInfo.userDept,
    isLoggedIn: authInfo.loginStatus,
    authObj: auth,
    onLogin: loginHandler,
    onLogout: logoutHandler
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {authStateLoaded ? (
        // eslint-disable-next-line
        props.children
      ) : (
        <FcLoader />
      )}
    </AuthContext.Provider>
  );
};

export default AuthContext;
