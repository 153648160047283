import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { FcTextField, SubmitBtnGroup } from '../../FormFields';
import DataContext from '../../../../store/DataContext';
import useCloudFuncPut from '../../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { cloudFunctionPath } from '../../../../cloudFunctionApiConfig';

export default function RejectOTRForm({ onClose, onReload, row }) {
  const dataCtx = useContext(DataContext);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/approve`);

  const onReject = async (values) => {
    try {
      const data = {
        ...values,
        otrRecordId: row.docId,
        status: 0,
        rentalAgreementNo: row.rentalAgreementNo
      };
      await onPut(data);
      await onReload();
      onOpenSnackbar('OTR request rejected successfully!');
      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      rejectReason: ''
    },
    validationSchema: Yup.object({
      rejectReason: Yup.string().required('Please fill in the reason!')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onReject(values);
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <DialogContent>
          <FcTextField multiline formik={formik} name="rejectReason" label="Reject Reason*" />
        </DialogContent>
        <DialogActions>
          <SubmitBtnGroup formik={formik} method="Reject" color="error" onCancel={onClose} />
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}

RejectOTRForm.propTypes = {
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  row: PropTypes.object
};
