import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LaunchIcon from '@mui/icons-material/Launch';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import BookingVehicleTable from "../../../Table/Booking/BookingVehicleTable";

const VehicleDetails = ({ vehicle, formik }) => {
  const price = vehicle?.model?.prices?.find(p => p.pricePeriodId === formik.values.pricePeriodId);
  const getDuration = () => {
    const startDate = moment(formik.values.startDate);
    const endDate = moment(formik.values.endDate);
    const paymentPeriod = price?.pricePeriod?.paymentPeriod === 0 ? 1 : price?.pricePeriod?.paymentPeriod;
    return endDate.diff(startDate, 'day') / paymentPeriod;
  }

  const durationFormat = () => {
    const duration = getDuration();
    const rentalPeriods = {
      0: 'Day',
      3: 'Weekend',
      7: 'Week',
      30: 'Month',
    };

    return `${duration.toFixed(2)} ${rentalPeriods[price?.pricePeriod?.paymentPeriod]}${duration > 1 ? 's' : ''}`;
  }

  const getPrice = () => Math.ceil(getDuration() * formik.values.rentalPrice);

  const getRentalPrice = (increase) => {
    const pricePeriod = price?.pricePeriod?.paymentPeriod === 0 ? 1 : price?.pricePeriod?.paymentPeriod;
    const pricePerDay = formik.values.originalRentalPrice / pricePeriod;
    return (pricePerDay + increase) * pricePeriod;
  }

  const getMonthlyRentalPrice = (increase) => formik.values.originalRentalPrice + increase;

  let prices = [
    { value: getRentalPrice(0), label: "+0" },
    { value: getRentalPrice(3), label: "+3" },
    { value: getRentalPrice(6), label: "+6" },
    { value: getRentalPrice(9), label: "+9" },
    { value: getRentalPrice(12), label: "+12" },
  ];
  if (price?.pricePeriod?.paymentPeriod === 30) {
    prices = [
      { value: getMonthlyRentalPrice(0), label: "+0" },
      { value: getMonthlyRentalPrice(100), label: "+100" },
      { value: getMonthlyRentalPrice(200), label: "+200" },
      { value: getMonthlyRentalPrice(300), label: "+300" },
      { value: getMonthlyRentalPrice(400), label: "+400" },
    ];
  }

  const details = [
    { label: "Vehicle Number", value: vehicle?.vehicleNumber },
    { label: "Make / Model", value: `${vehicle?.model?.make?.name} ${vehicle?.model?.name}, ${vehicle?.model?.petrolType}` },
    { label: "Inspection Due Date", value: moment(vehicle?.inspectionDueDate).format("DD MMM YYYY") },
    { label: "Vehicle Type", value: vehicle?.logcard?.vehicleType },
    { label: "Price Model", value: `S$${formik.values.rentalPrice}, ${price?.pricePeriod?.columnName}` },
    { label: "Start Date", value: moment(formik.values.startDate).format("DD MMM YYYY") },
    { label: "End Date", value: moment(formik.values.endDate).format("DD MMM YYYY") },
    { label: "Duration", value: durationFormat() },
  ];

  return (
    <Box p={3} border={1} borderRadius={2}>
      <Typography variant="h5" mb={3}>Vehicle Details</Typography>
      <Grid container spacing={3}>
        {details.map(({ label, value }, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">{label}</Typography>
            {value}
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="h6">Price Details</Typography>
          {durationFormat()} X S${formik.values.rentalPrice} = S${getPrice()}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Price Increase Per Day</Typography>
          <RadioGroup
            row
            name="rentalPrice" value={formik.values.rentalPrice}
            onChange={e => formik.setFieldValue("rentalPrice", Number(e.target.value))}
          >
            {prices.map(({ value, label }, index) => (
              <FormControlLabel key={index} control={<Radio />} label={label} value={value}/>
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
}

VehicleDetails.propTypes = {
  vehicle: PropTypes.object,
  formik: PropTypes.object,
}

export default function VehicleQuestionForm({ formik, vehicle, handleVehicleSelect }) {
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [vehicleNumber, setVehicleNumber] = useState("");

  const { data: vehicles, onGet } = useHttpGet("/api/booking/vehicle", false);
  const { data: makes } = useHttpGet("/api/vehicles/make");
  const { data: models } = useHttpGet("/api/vehicles/model");

  const date = moment(formik.values.startDate);
  const [month, year] = [date.format("MM"), date.format("YYYY")];

  const onSearch = async e => {
    e.preventDefault();

    const { startDate, endDate, rentalUsage } = formik.values;
    const searchParams = {
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
      rentalUsage: rentalUsage,
      includeVehicleId: formik.values.originalVehicleId ?? "",
    }
    if (model?.id) await onGet({ modelId: model.id, ...searchParams });
    else if (make?.id) await onGet({ makeId: make.id, ...searchParams });
    else await onGet(searchParams);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Select a vehicle</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Autocomplete
          value={make}
          onChange={(e, newValue) => {
            setMake(newValue);
            setModel(null);
          }}
          options={makes ?? []}
          getOptionLabel={make => make.name}
          renderInput={params => <TextField {...params} label="Make"/>}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Autocomplete
          value={model}
          onChange={(e, newValue) => {
            setModel(newValue);
          }}
          options={models?.filter(m => m.makeId === make?.id) ?? []}
          getOptionLabel={model => model.name}
          renderInput={params => <TextField {...params} label="Model"/>}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <TextField
          value={vehicleNumber} label="Vehicle Number"
          onChange={(e) => setVehicleNumber(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ height: "100%" }}>
          <Button startIcon={<SearchIcon/>} variant="contained" onClick={onSearch}>Search</Button>
          <Button
            component={Link} to="/dashboard/booking/price/rental" target="_blank"
            variant="contained" color="secondary" startIcon={<LaunchIcon/>}
          >
            Price Table
          </Button>
          <Button
            component={Link} to={`/dashboard/sales/booking-chart?month=${month}&year=${year}`} target="_blank"
            variant="contained" color="info" startIcon={<CalendarMonthIcon/>}
          >
            Gantt Chart
          </Button>
        </Stack>
      </Grid>

      {vehicle && (
        <Grid item xs={12}>
          <VehicleDetails vehicle={vehicle} formik={formik}/>
        </Grid>
      )}
      {vehicles && (
        <Grid item xs={12}>
          <BookingVehicleTable
            formik={formik} handleVehicleSelect={handleVehicleSelect}
            data={vehicles.filter(vehicle => vehicleNumber.length > 0 ? vehicle.vehicleNumber.includes(vehicleNumber) : true)}
          />
        </Grid>
      )}
    </Grid>
  );
}

VehicleQuestionForm.propTypes = {
  formik: PropTypes.object,
  vehicle: PropTypes.object,
  handleVehicleSelect: PropTypes.func,
}