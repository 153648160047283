import PropTypes from "prop-types";
import { Button, Grid, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogActions, DialogTitle } from '@material-ui/core';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '@material-ui/lab';
import Dialog from '@mui/material/Dialog';
import DataContext from '../../store/DataContext';
import { cloudFunctionPath } from '../../cloudFunctionApiConfig';
import useCloudFuncDelete from '../../hooks/firebase/cloudFunction/useCloudFuncDelete';

function ActionCol({ returnJob, onReload }) {
  const navigate = useNavigate();
  const dataCtx = useContext(DataContext);
  const { loading, onDelete } = useCloudFuncDelete(`${cloudFunctionPath}/workshop/return_job/${returnJob.id}`);
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const deleteOnClick = async () => {
    await onDelete();
    onReload();
    onOpenSnackbar(`${returnJob.vehicleNumber} Return Job Deleted Successfully`);
  }

  const openDeleteWarningDialog = () => setOpenDeleteWarning(true);
  const closeDeleteWarningDialog = () => setOpenDeleteWarning(false);
  
  return (
    <>
      <Dialog open={openDeleteWarning} onClose={closeDeleteWarningDialog}>
        <DialogTitle>{`Delete ${returnJob.vehicleNumber} Return Job?`}</DialogTitle>
        <DialogContent>This action is <b>irreversible.</b></DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDeleteWarningDialog}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="error"
            onClick={deleteOnClick}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      
      <Button
        variant="contained"
        sx={{ marginRight: 1 }}
        onClick={() => navigate(`./edit/${returnJob.id}`)}
      >
        Edit
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={openDeleteWarningDialog}
      >
        Delete
      </Button>
    </>
  );
}

ActionCol.propTypes = {
  returnJob: PropTypes.object,
  onReload: PropTypes.func,
}

export default function ReturnJobItem({ returnJob, onReload }) {
  return (
    <>
      <Paper elevation={10} sx={{ padding: 1.5 }}>
        <Grid key={returnJob.vehicleNumber} container alignItems="center">
          <Grid item xs={1}>
            <Typography variant="h6">{returnJob.vehicleNumber}</Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={18}>
              {
                returnJob
                  .dates
                  .sort()
                  .map((date) => dayjs(date).format("DD MMM YYYY"))
                  .reduce((a, b) => a === "" ? b : `${a}  \u00a0+\u00a0  ${b}`, "")
              }
            </Typography>
          </Grid>

          <Grid item>
            <ActionCol returnJob={returnJob} onReload={onReload} />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

ReturnJobItem.propTypes = {
  returnJob: PropTypes.object,
  onReload: PropTypes.func
}