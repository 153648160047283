import {Box, Button, Dialog, DialogContent, DialogActions, DialogTitle, Stack} from "@mui/material";
import {GridToolbar} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import {useState} from "react";
import {Link} from "react-router-dom";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import {getDayjsValue} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";
import RefundVoucherDetails from "../../Details/RefundVoucher/RefundVoucherDetails";

const ViewAction = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row?.refundNo} Details</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <RefundVoucherDetails refundVoucher={row}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <ViewAction row={row}/>
      <Button component={Link} to={getFile(row?.attachment)} target="_blank" variant="contained">Print</Button>
      <Button component={Link} to={`/dashboard/sales/booking/${row.bookingNo}`} target="_blank" variant="contained" color="secondary">
        Booking
      </Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function RefundVoucherTable({ data, onReload }) {
  const columns = [
    { field: "refundNo", headerName: "Refund No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "bookingNo", headerName: "Booking No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 100, sortable: false },
    { field: "reason", headerName: "Reason", align: "center", headerAlign: "center", width: 300, sortable: false },
    {
      field: "amount", headerName: "Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => `S$${row.amount}`,
    },
    { field: "paymentMethod", headerName: "Payment Method", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "refundDate", headerName: "Refund Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => getDayjsValue(row.refundDate).format("DD MMM YYYY"),
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 300,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>,
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      rows={data ?? []} columns={columns} pageSize={100}
      disableSelectionOnClick autoHeight
    />
  );
}

RefundVoucherTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}