import moment from "moment";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import DataContext from "../../../store/DataContext";
import {getMomentValue} from "../../../utils/date";
import {
  FcDateField,
  FcFileDropzone,
  FcSelectField,
  FcTextArea,
  FcTextField,
  FcToggleButton,
  SubmitBtnGroup
} from "../FormFields";

const VerifyField = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <Box p={1}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {content}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpen(false)}>Done</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {title}
      </Button>
    </>
  );
}

VerifyField.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
}

export default function CustomerForm({ data, onReload, onClose, identity = "" }) {
  const method = data ? "Update" : "Create";
  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/customer");
  const { onPut } = useHttpPut(`/api/customer/${data?.id}`);

  const onCreate = async (values) => {
    await onPost({
      ...values,
      birthDate: values?.birthDate?.toISOString(),
      drivingLicenseEffectiveDate: values?.drivingLicenseEffectiveDate?.toISOString(),
    });
    await onReload();
    dataCtx.setSnackbarConfig({ open: true, message: "Customer Created Successfully!", severity: "success" });
    onClose();
  }

  const onUpdate = async (values) => {
    await onPut({
      ...values,
      birthDate: values?.birthDate?.toISOString(),
      drivingLicenseEffectiveDate: values?.drivingLicenseEffectiveDate?.toISOString(),
    });
    await onReload();
    dataCtx.setSnackbarConfig({ open: true, message: "Customer Updated Successfully!", severity: "success" });
    onClose();
  }

  const customerTypes = [
    { label: "Individual", value: "Individual" },
    { label: "Company", value: "Company" },
  ];

  const formik = useFormik({
    initialValues: {
      customerType: data?.customerType ?? "",
      name: data?.name ?? "",
      phone: data?.phone ?? "",
      email: data?.email ?? "",
      address: data?.address ?? "",
      postal: data?.postal ?? "",
      identity: data?.identity ?? identity,
      birthDate: data?.birthDate ? getMomentValue(data?.birthDate) : null,
      drivingLicenseEffectiveDate: data?.drivingLicenseEffectiveDate ? getMomentValue(data?.drivingLicenseEffectiveDate) : null,
      isNotBlacklisted: data?.isNotBlacklisted ?? false,
      isIdentityVerified: data?.isIdentityVerified ?? false,
      isDrivingLicenseVerified: data?.isDrivingLicenseVerified ?? false,
      identityFront: data?.documents?.find(d => d.label === "Identity Front")?.objectName ?? "",
      identityBack: data?.documents?.find(d => d.label === "Identity Back")?.objectName ?? "",
      drivingLicenseFront: data?.documents?.find(d => d.label === "Driving License Front")?.objectName ?? "",
      drivingLicenseBack: data?.documents?.find(d => d.label === "Driving License Back")?.objectName ?? "",
      companyProfile: data?.documents?.find(d => d.label === "Company ACRA")?.objectName ?? "",
    },
    validationSchema: Yup.object({
      customerType: Yup.string().required("Please select customer type!"),
      name: Yup.string().required("Please fill in customer name!"),
      phone: Yup.string().required("Please fill in customer phone number!"),
      email: Yup.string().email("Invalid email!").required("Please fill in customer email!"),
      address: Yup.string().required("Please fill in customer address!"),
      postal: Yup.string().required("Please fill in customer postal code!"),
      identity: Yup.string().required("Please fill in customer identity!"),
      birthDate: Yup.date().nullable()
        .test(
          "required", "Please select the birth date!",
          (value, context) => context.parent.customerType === "Individual" ? value != null : true,
        ),
      drivingLicenseEffectiveDate: Yup.date().nullable()
        .test(
          "required", "Please select the driving license effective date!",
          (value, context) => context.parent.customerType === "Individual" ? value != null : true,
        ),
      isNotBlacklisted: Yup.boolean().required("Please verify customer is not blacklisted!"),
      isIdentityVerified: Yup.boolean().required("Please verify customer NRIC/FIN/ACRA!"),
      isDrivingLicenseVerified: Yup.boolean()
        .test(
          "required", "Please verify customer driving license!",
          (value, context) => context.parent.customerType === "Individual" ? value != null : true,
        ),
      identityFront: Yup.string()
        .test(
          "required", "NRIC/FIN Front Photo is required!",
          (value, context) => context.parent.customerType === "Individual" ? value != null : true,
        ),
      identityBack: Yup.string()
        .test(
          "required", "NRIC/FIN Back Photo is required!",
          (value, context) => context.parent.customerType === "Individual" ? value != null : true,
        ),
      drivingLicenseFront: Yup.string()
        .test(
          "required", "Driving License Front Photo is required!",
          (value, context) => context.parent.customerType === "Individual" ? value != null : true,
        ),
      drivingLicenseBack: Yup.string()
        .test(
          "required", "Driving License Back Photo is required!",
          (value, context) => context.parent.customerType === "Individual" ? value != null : true,
        ),
      companyProfile: Yup.string()
        .test(
          "required", "Company ACRA is required!",
          (value, context) => context.parent.customerType === "Company" ? value != null : true,
        ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onCreate(values);
        else await onUpdate(values);
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const verifyIdentity = () => {
    if (formik.values.customerType === "Company") return Boolean(formik.values.companyProfile);
    return Boolean(formik.values.identityFront && formik.values.identityBack);
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} label="Customer Type" name="customerType" items={customerTypes}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Name" name="name"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Phone" name="phone"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Email" name="email"/>
          </Grid>
          {formik.values.customerType === "Individual" && <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} label="Birth Date" name="birthDate"/>
          </Grid>}
          {formik.values.customerType === "Individual" && <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} label="Driving License Effective Date" name="drivingLicenseEffectiveDate"/>
          </Grid>}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="NRIC/FIN/ACRA" name="identity" disabled={identity !== ""}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Postal" name="postal"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} label="Address" name="address"/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Box>
                <VerifyField
                  title="Verify Customer not Blacklisted"
                  content={(
                    <Box>
                      Check customer is blacklisted or not from this <Link to="https://rentalblacklist.com.sg" target="_blank">website</Link>.
                      <FcToggleButton formik={formik} label="Ensure Customer not blacklisted" name="isNotBlacklisted"/>
                    </Box>
                  )}
                />
                {formik.values.isNotBlacklisted && <Typography p>Customer is not blacklisted</Typography>}
              </Box>
              <Box>
                <VerifyField
                  title="Verify Customer NRIC/FIN/ACRA"
                  content={(
                    <Stack spacing={3}>
                      <Box>Check customer identity is valid.</Box>
                      {formik.values.customerType === "Individual" && (
                        <>
                          <FcFileDropzone formik={formik} label="NRIC/FIN Front Photo" name="identityFront"/>
                          <FcFileDropzone formik={formik} label="NRIC/FIN Back Photo" name="identityBack"/>
                        </>
                      )}
                      {formik.values.customerType === "Company" && (
                        <FcFileDropzone formik={formik} label="ACRA PDF Document" name="companyProfile"/>
                      )}
                      <FcToggleButton formik={formik} label="Ensure NRIC/FIN/ACRA verified" name="isIdentityVerified"/>
                    </Stack>
                  )}
                />
                {formik.values.isIdentityVerified && verifyIdentity() && (
                  <Typography p>Customer identity verified</Typography>
                )}
              </Box>
              {formik.values.customerType === "Individual" && <Box>
                <VerifyField
                  title="Verify Customer Driving License"
                  content={(
                    <Stack spacing={3}>
                      <Box>Check customer driving license is valid.</Box>
                      <FcFileDropzone formik={formik} label="Driving License Front Photo" name="drivingLicenseFront"/>
                      <FcFileDropzone formik={formik} label="Driving License Back Photo" name="drivingLicenseBack"/>
                      <FcToggleButton formik={formik} label="Ensure Driving License verified" name="isDrivingLicenseVerified"/>
                    </Stack>
                  )}
                />
                {formik.values.isDrivingLicenseVerified && formik.values.drivingLicenseFront && formik.values.drivingLicenseBack && (
                  <Typography p>Customer driving license verified</Typography>
                )}
              </Box>}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} onCancel={onClose} method={method}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

CustomerForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
  identity: PropTypes.string,
}