import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { getOutstandingAmount } from "../../../utils/bookingAccounts";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { getDayjsValue, paymentPeriodTypes } from "../../../utils/date";
import LongTermPointDetails from "../../Details/OTR/LongTermPointDetails";
import LongTermPointForm from "../../Form/Sales/OTR/LongTermPointForm";
import FcTable from "../FcTable";

const ViewAction = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row.agreementNo} OTR Details</DialogTitle>
        <DialogContent>
          <LongTermPointDetails data={row} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        View
      </Button>
    </Stack>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const AddAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Add this OTR?</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <LongTermPointForm data={row} onReload={onReload} onClose={() => setOpen(false)} />
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Add
      </Button>
    </>
  );
}

AddAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <AddAction row={row} onReload={onReload} />
      <ViewAction row={row} />
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function PreviewLongTermPointTable({ data, onReload }) {
  const columns = [
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center", minWidth: 150, sticky: true,
      renderCell: ({ row }) => getDayjsValue(row?.booking?.startDate).format("DD MMM YYYY"),
    },
    {
      field: "customerName", headerName: "Customer", align: "center", headerAlign: "center", minWidth: 250, sticky: true,
      renderCell: ({ row }) => row?.booking?.company?.name ?? (row?.booking?.customer?.name ?? ""),
    },
    {
      field: "vehicleNumber", headerName: "Vehicle No.", align: "center", headerAlign: "center", minWidth: 150, sticky: true,
      renderCell: ({ row }) => row?.firstRentalAgreement?.vehicleNumber ?? "",
    },
    {
      field: "agreementNo", headerName: "Agreement No.", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.firstRentalAgreement?.agreementNo ?? "",
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => getDayjsValue(row?.booking?.endDate).format("DD MMM YYYY"),
    },
    {
      field: "bookingNo", headerName: "Booking No.", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.booking?.bookingNo,
    },
    { field: "username", headerName: "Sales", align: "center", headerAlign: "center", minWidth: 150 },
    {
      field: "isNewCustomer", headerName: "New / Existing", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.isNewCustomer ? "New" : "Existing",
    },
    {
      field: "rentalUsage", headerName: "Type", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.booking?.rentalUsage,
    },
    {
      field: "deposit", headerName: "Deposit", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => `S$${row?.booking?.deposit ?? 0}`,
    },
    {
      field: "rentalPrice", headerName: "Rental Price", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => `S$${row?.booking?.rentalPrice ?? 0}`,
    },
    {
      field: "pricePeriod.paymentPeriod", headerName: "Billing Type", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => paymentPeriodTypes[row?.booking?.pricePeriod?.paymentPeriod],
    },
    {
      field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => getOutstandingAmount(row?.booking),
    },
    {
      field: "totalUpfront", headerName: "Total Upfront", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.booking?.totalUpfront ?? 0,
    },
    { field: "longTermPoint", headerName: "Long Term Point", align: "center", headerAlign: "center", minWidth: 150 },
    {
      field: "status", headerName: "OTR Status", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => capitalizeFirstLetter(row?.status),
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload} />,
    },
  ];

  return (
    <FcTable columns={columns} rows={data ?? []}/>
  );
}

PreviewLongTermPointTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}