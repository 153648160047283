import {Typography} from "@mui/material";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import ShellCardAssignRecordTable from "../../../components/Table/ShellCard/ShellCardAssignRecordTable";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";

export default function ShellCardAssignRecordTab() {
  const { data, loading, onGet } = useCloudFuncGet(`${cloudFunctionPath}/wallet/card/assign/record`);
  const records = data?.data ?? [];

  return (
    <>
      <Typography variant="h4" mb={3}>Shell Card Assigned Records</Typography>
      <ShellCardAssignRecordTable data={records} loading={loading} onReload={onGet} />
    </>
  );
}