import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import WorkshopJobForm from '../../../components/Form/Workshop/Job/WorkshopJobForm';
import WorkshopMajorJobForm from '../../../components/Form/Workshop/Job/WorkshopMajorJobForm';
import BackButton from '../../../components/BackButton';

export default function NewJob() {
  const [tabValue, setTabValue] = useState("1");

  return (
    <Box sx={{ padding: { xs: 0, md: 0 } }}>
      <BackButton />

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: { xs: 1, md: 2 } }}>
          <TabList onChange={(event, newValue) => setTabValue(newValue)}>
            <Tab label="Normal Job" value="1" sx={{ width: "50%" }} />
            <Tab label="Major Job" value="2" sx={{ width: "50%" }} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: { xs: 2, md: 0 } }}>
          <Typography
            variant="h4"
            gutterBottom
            mb={3}
            sx={{ textAlign: { xs: 'center', md: 'start' }, fontSize: { xs: 26, md: 24 } }}
          >
            Creating New Normal Job
          </Typography>
          <WorkshopJobForm />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: { xs: 2, md: 0 } }}>
          <Typography
            variant="h4"
            gutterBottom
            mb={3}
            sx={{ textAlign: { xs: 'center', md: 'start' }, fontSize: { xs: 26, md: 24 } }}
          >
            Creating New Major Job
          </Typography>
          <WorkshopMajorJobForm />
        </TabPanel>
      </TabContext>
    </Box>
  );
}