export const CUSTOMER_PERMISSIONS = {
  VIEW: 'view_customer',
  CREATE: 'create_customer',
  UPDATE: 'update_customer',
  DELETE: 'delete_customer',
};

export const CUSTOMER_DOCUMENTS_PERMISSIONS = {
  VIEW: 'view_customer_document',
  CREATE: 'create_customer_document',
  UPDATE: 'update_customer_document',
  DELETE: 'delete_customer_document',
};

export const setupCustomerRolePermissions = (ROLES) => ({
  [ROLES.SUPER_ADMIN]: [...Object.values(CUSTOMER_PERMISSIONS), ...Object.values(CUSTOMER_DOCUMENTS_PERMISSIONS)],
  [ROLES.MANAGER]: [
    CUSTOMER_PERMISSIONS.VIEW,
    CUSTOMER_DOCUMENTS_PERMISSIONS.VIEW,
  ],
  [ROLES.ACCOUNT]: [
    CUSTOMER_PERMISSIONS.VIEW,
    CUSTOMER_DOCUMENTS_PERMISSIONS.VIEW,
  ],
  [ROLES.SALES]: [
    CUSTOMER_PERMISSIONS.VIEW,
    CUSTOMER_PERMISSIONS.CREATE,
    CUSTOMER_PERMISSIONS.UPDATE,
    CUSTOMER_DOCUMENTS_PERMISSIONS.VIEW,
    CUSTOMER_DOCUMENTS_PERMISSIONS.CREATE,
    CUSTOMER_DOCUMENTS_PERMISSIONS.UPDATE,
  ],
  [ROLES.OPERATION]: [
    CUSTOMER_PERMISSIONS.VIEW,
    CUSTOMER_DOCUMENTS_PERMISSIONS.VIEW,
  ],
  [ROLES.MARKETING]: [
    CUSTOMER_PERMISSIONS.VIEW,
    CUSTOMER_DOCUMENTS_PERMISSIONS.VIEW,
  ],
});