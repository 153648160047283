import React, { useCallback, useContext, useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Button, Container, Stack, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import authContext from '../../../store/AuthContext';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import WorkshopVehicleTable from '../../../components/Table/Workshop/WorkshopVehicleTable';
import { permissionRequired } from '../../../utils/authorization/roles';
import { VEHICLE_PERMISSIONS } from '../../../utils/authorization/permissions/vehiclePermissions';
import { xlsxToJson } from '../../../utils/xlsxToJson';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import DataContext from '../../../store/DataContext';

function convertToObject(data) {
  return data.map((item) => ({
    vehicleNumber: item.vehicleNumber,
    make: item.make,
    model: item.model,
    color: item.color,
    fuel: item.fuel,
    registrationDate: item.registrationDate,
    coeExpiryDate: item.coeExpiryDate,
    salesPerson: item.salesPerson,
  }));
}

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  textAlign: 'center',
};

function ImportVehicleAction({ open, setOpen }) {
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();
  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/vehicle/import`);
  const [data, setData] = useState([]);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const data = xlsxToJson(await file.arrayBuffer());
    const rows = convertToObject(data);
    setData(rows);
    console.log(data[0]);
  }, [])

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const submit = async () => {
    try {
      await onPost(data);
      onOpenSnackbar("All vehicle imported successfully");
      setTimeout(() => navigate(0), 1000);
    } catch (e) {
      onOpenSnackbar(e.message, "error")
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ multiple: false, onDrop })

  return (
    <>
      <Dialog open={open} onClose={closeDialog} maxWidth="lg">
        <DialogTitle>Importing Vehicles</DialogTitle>

        <DialogContent>
          <Typography mt={3} mb={3}>
            <b>Instruction:</b> Please make sure you have 7 columns named
            'vehicleNumber' (General), 'make' (General), 'model' (General), 'color' (General), 'fuel' (General),
            'registrationDate' (Date), 'coeExpiryDate' (Date) and 'salesPerson' (General)
            with the appropriate values.
          </Typography>
          <Box {...getRootProps({ style })} mb={3}>
            <input {...getInputProps()}/>
            {isDragActive ? <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files.</p>}
          </Box>
          <WorkshopVehicleTable data={data} action={false} />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>Cancel</Button>
          <Button variant="contained" onClick={submit}>Import</Button>
        </DialogActions>
      </Dialog>


      <Button variant="contained" onClick={openDialog}>Import Vehicles</Button>
    </>
  );
}

ImportVehicleAction.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}


export default function WorkshopVehicleListing() {
  const authCtx = useContext(authContext);
  const { data: getData, onGet } = useCloudFuncGet(`${cloudFunctionPath}/workshop/vehicle`);
  const vehicleData = getData.data;
  const [open, setOpen] = useState(false);

  const [data, setData] = useState(vehicleData);

  useEffect(() => {
    setData(vehicleData);
  }, [vehicleData]);

  const filterData = (str) => {
    setData(vehicleData.filter((vehicle) => vehicle.vehicleNumber.toLowerCase().includes(str)));
  }

  return (
    <Container maxWidth="xl">
      <Stack spacing={3} mb={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Workshop Vehicle Listing</Typography>
          <Stack direction="row" spacing={2}>
            {permissionRequired(authCtx, VEHICLE_PERMISSIONS.CREATE) &&
              <ImportVehicleAction open={open} setOpen={setOpen}/>
            }
            {permissionRequired(authCtx, VEHICLE_PERMISSIONS.CREATE) && <Button
              component={Link} to="./new"
              startIcon={<Icon icon={plusFill}/>}
              variant="contained" color="secondary"
            >
              Add Vehicle
            </Button>}
          </Stack>
        </Stack>
      </Stack>

      <TextField
        label="Search Vehicle Number"
        onChange={(e) => filterData(e.target.value.toLowerCase())}
        sx={{ mb: 2 }}
      />

      <WorkshopVehicleTable data={data} onReload={onGet} />
    </Container>
  );
}