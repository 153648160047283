import {LoadingButton} from "@material-ui/lab";
import {Button, Checkbox, Dialog, DialogActions, DialogTitle, Stack} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import {getAuth} from "firebase/auth";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import DataContext from "../../../store/DataContext";
import {defaultTimezone} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";
import FcTable from "../FcTable";

dayjs.extend(utc);
dayjs.extend(timezone);

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/collection/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "collection deleted", severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Do you want to delete collection?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" justifyContent="center">
      {row?.receiptAttachment && <Button component={Link} to={getFile(row.receiptAttachment)} target="_blank" variant="contained">Print</Button>}
      {!row.isFinalized && row.userId === getAuth().currentUser.uid && <DeleteAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function BookingCollectionTable({ data, onReload }) {
  const columns = [
    { field: "receiptNo", headerName: "Receipt No.", align: "center", headerAlign: "center", maxWidth: 150 },
    {
      field: "amount", headerName: "Collect Amount", align: "center", headerAlign: "center", maxWidth: 150,
      renderCell: ({ row }) => `$${row.amount}`
    },
    {
      field: "collectDate", headerName: "Collect Date", align: "center", headerAlign: "center", maxWidth: 150,
      renderCell: ({ row }) => dayjs(row.collectDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY")
    },
    { field: "paymentMethod", headerName: "Payment Method", align: "center", headerAlign: "center", maxWidth: 150 },
    { field: "vehicleCompanyUen", headerName: "Pay To", align: "center", headerAlign: "center", width: 150 },
    {
      field: "isFinalized", headerName: "Finalized", align: "center", headerAlign: "center", maxWidth: 100,
      renderCell: ({ row }) => <Checkbox checked={row.isFinalized}/>
    },
    { field: "remarks", headerName: "Remarks", align: "center", headerAlign: "center", maxWidth: 500 },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", maxWidth: 200,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>
    },
  ];

  return (
    <FcTable columns={columns} rows={data?.filter(row => row.paymentMethod !== "Refund Credit") ?? []}/>
  );
}

BookingCollectionTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}