import {getAuth} from "firebase/auth";
import {useEffect, useState} from "react";
import {apiFileFetch} from "../../services/api";

/*
 * data: object
 * loading: boolean
 * error: string
 * onGet: function
 */
export default function useImageGet(path, run = true, searchParams = {}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onGet = async (searchParams = {}) => {
    setLoading(true);
    try {
      const token = await getAuth().currentUser.getIdToken();
      const res = await apiFileFetch(path, {
        method: "GET",
        headers: {
          Accept: "images/*",
          Authorization: `Bearer ${token}`,
        }
      }, searchParams);
      setData(res);
    } catch (err) {
      setError(err.message);
      throw err;
    }
    setLoading(false);
  }

  useEffect(() => run && onGet(searchParams), []);

  return { data, loading, error, onGet };
}