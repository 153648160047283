import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import WorkshopRequestForm from '../../../components/Form/Workshop/WorkshopRequestForm';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';

export default function EditWorkshopRequest() {
  const params = useParams();
  const { data } = useCloudFuncGet(`/workshop/request/${params.id}`);
  
  return (
    <>
      <Typography variant="h4" mb={3}>Workshop Request Listing</Typography>
      
      <WorkshopRequestForm data={data} />
    </>
  );
}