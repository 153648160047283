import { FormControlLabel, Grid, List, ListItemText, Radio, RadioGroup, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function PeriodTypeQuestionForm({ formik }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">What is the Rental Period Type?</Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup row name="periodType" value={formik.values.periodType} onChange={formik.handleChange}>
          <FormControlLabel control={<Radio />} label="Daily" value={1} />
          <FormControlLabel control={<Radio />} label="Weekend" value={3} />
          <FormControlLabel control={<Radio />} label="Weekly" value={7} />
          <FormControlLabel control={<Radio />} label="Monthly" value={30} />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItemText
            primary="Less Than 1 Week:"
            primaryTypographyProps={{ variant: 'caption', fontWeight: 'bold' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Daily"
            primaryTypographyProps={{ variant: 'caption' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Weekend"
            primaryTypographyProps={{ variant: 'caption' }}
          />
        </List>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItemText
            primary="Exactly 1 Week or More:"
            primaryTypographyProps={{ variant: 'caption', fontWeight: 'bold' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Weekly"
            primaryTypographyProps={{ variant: 'caption' }}
          />
        </List>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItemText
            primary="Exactly 1 Month or More:"
            primaryTypographyProps={{ variant: 'caption', fontWeight: 'bold' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Monthly"
            primaryTypographyProps={{ variant: 'caption' }}
          />
        </List>
        <Typography variant="caption">Note: This is rental period and not payment period*.</Typography>
      </Grid>
    </Grid>
  );
}

PeriodTypeQuestionForm.propTypes = {
  formik: PropTypes.object
};