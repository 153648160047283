import React from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import useCloudFuncGet from '../../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import {dateIncludeRange, getDayjsValue} from '../../../../utils/date';
import { FcDateField } from '../../FormFields';
import { cloudFunctionPath } from '../../../../cloudFunctionApiConfig';

const HolidayPrompt = ({ endDate, handleChange }) => {
  const { data } = useCloudFuncGet(`${cloudFunctionPath}/holiday/list`);
  const holidays = data?.data;

  const isPublicHoliday = (date) => {
    if (date == null) return false;
    for (let i = 0; i < holidays?.length ?? 0; i++) {
      const { startDate, endDate } = holidays[i];
      if (dateIncludeRange(date, startDate, endDate)) return true;
    }
    return false;
  };

  const isHoliday = (date) => {
    const curDate = getDayjsValue(date);
    return curDate.day() === 0 || curDate.day() === 6 || isPublicHoliday(curDate);
  };

  const dates = [];
  for (let i = -5; i <= 5; i++) {
    if (i !== 0) {
      const date = getDayjsValue(endDate).add(i, 'day');
      if (!isHoliday(date)) dates.push(date);
    }
  }

  return (
    <Grid item xs={12}>
      <Typography>
        {isHoliday(endDate) && `${getDayjsValue(endDate).format('DD MMM YYYY')} is holiday. Please change the end date!`}
      </Typography>
      {isHoliday(endDate) && (
        <RadioGroup value={getDayjsValue(endDate)} onChange={handleChange}>
          {dates.map((date, index) => (
            <FormControlLabel
              key={index}
              control={<Radio />}
              label={date.format('DD MMM YYYY')}
              value={date.format()}
            />
          ))}
        </RadioGroup>
      )}
    </Grid>
  );
}

HolidayPrompt.propTypes = {
  endDate: PropTypes.any,
  handleChange: PropTypes.func
};

export default function RentalPeriodQuestionForm({ formik }) {
  const handleChange = (e) => formik.setFieldValue('endDate', moment(e.target.value));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">When customer need vehicle?</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FcDateField formik={formik} label="Start Date" name="startDate"/>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FcDateField formik={formik} label="End Date" name="endDate"/>
      </Grid>
      <HolidayPrompt endDate={formik.values.endDate} handleChange={handleChange} />
    </Grid>
  );
}

RentalPeriodQuestionForm.propTypes = {
  formik: PropTypes.object,
};
