import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle } from '@material-ui/core';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '@material-ui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncDelete from '../../../hooks/firebase/cloudFunction/useCloudFuncDelete';
import DataContext from '../../../store/DataContext';

function ActionCol({row, showPrice, onReload}) {
  const dataCtx = useContext(DataContext);
  const rowName = `${dayjs(row.createdAt).format("ddd, D MMM HH:mm:ss")} ${row.vehicleNumber}`;
  const { loading, onDelete } = useCloudFuncDelete(`${cloudFunctionPath}/workshop/job/${row.id}`);
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);

  const deleteAction = async () => {
    try {
      await onDelete();
      await onReload();
      onOpenSnackbar(`Successfully Deleted ${rowName}`);
    } catch (error) {
      console.log(error.message)
      onOpenSnackbar(error.message, "error");
    }
  }

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const openDeleteWarningDialog = () => setOpenDeleteWarning(true);
  const closeDeleteWarningDialog = () => setOpenDeleteWarning(false);

  return (
    <Box mt={2}>
      <Button
        variant="contained"
        component={Link}
        to={showPrice ? `../../workshop/edit/${row.id}` : `./edit/${row.id}`}
        sx={{ marginLeft: 1 }}
      >
        Edit
      </Button>

      <Button
        variant="contained"
        color="error"
        onClick={openDeleteWarningDialog} sx={{ marginLeft: 1 }}
      >
        Delete
      </Button>

      <Dialog open={openDeleteWarning} onClose={closeDeleteWarningDialog}>
        <DialogTitle>{`Delete ${rowName}?`}</DialogTitle>
        <DialogContent>This action is <b>irreversible.</b> <br /> 这个行为是不可逆的</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDeleteWarningDialog}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="error"
            onClick={deleteAction}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  showPrice: PropTypes.bool,
  onReload: PropTypes.func
}


export default function NormalJobAcc({ data, showPrice, hideAction, hideChinese, onReload }) {
  return (
    <Accordion elevation={10} defaultExpanded={!showPrice}>
      <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
        { showPrice &&
          <Typography variant="h6" width={110}>
            ${data.task.reduce((x, y) => x + y.cost, 0).toFixed(2)}
          </Typography>
        }

        <Grid container>
          <Grid item xs={12} display="flex" alignItems="center">
            <Typography fontSize={18} fontWeight={700} textAlign="start">
              {`${dayjs(data.createdAt).format("HH:mm:ss |")} ${data.vehicleNumber}`}
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <Typography fontWeight={700} color="grey" sx={{ mr: 1 }}>
              NORMAL
            </Typography>
          </Grid>

          {
            data.createdAt !== data.updatedAt &&
            <Grid item>
              <Typography>Updated at {dayjs(data.updatedAt).format('ddd, D MMM')}</Typography>
            </Grid>
          }
        </Grid>
      </AccordionSummary>

      <AccordionDetails sx={{ marginTop: -1.5 }}>
        <Grid container>
          {data.task.map((task) =>
            <Grid key={task.name} item xs={12} display="flex">
              {
                showPrice &&
                <Grid item xs={0.6} minWidth={50}>
                  <Typography fontSize={18}>${task.cost.toFixed(2)}</Typography>
                </Grid>
              }
              <Typography
                key={task.name}
                fontSize={18}
              >
                {task.name} { hideChinese ? "" : `| ${task.chineseName}` } { task.specific ? `(${task.specific})` : "" }
              </Typography>
            </Grid>
          )}
        </Grid>

        { data.other && <Typography fontSize={18} mt={0.5}><b>Other Tasks:</b> {data.other}</Typography> }
        { data.remark && <Typography fontSize={18} mt={0.5}><b>Special Remarks:</b> {data.remark}</Typography> }
        { data.parkingTicket && <Typography fontSize={18} fontWeight={700} mt={0.5}>Parking Ticket Used ({data.salesPerson})</Typography> }
        { hideAction || <ActionCol row={data} showPrice={showPrice} onReload={onReload} /> }
      </AccordionDetails>
    </Accordion>
  );
}

NormalJobAcc.propTypes = {
  data: PropTypes.object,
  showPrice: PropTypes.bool,
  hideAction: PropTypes.bool,
  hideChinese: PropTypes.bool,
  onReload: PropTypes.func
}