import {Button, Grid, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import dayjs from "dayjs";
import {getAuth} from "firebase/auth";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useRef} from "react";
import * as XLSX from "xlsx";
import * as Yup from "yup";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPost from "../../../hooks/http/useHttpPost";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import DataContext from "../../../store/DataContext";
import {xlsxDateToDate, xlsxToJson} from "../../../utils/xlsxToJson";
import {SubmitBtnGroup} from "../FormFields";

const AgreementTableForm = ({ formik, handleCommit }) => {
  const ActionCol = ({ formik, row }) => {
    const onMove = async e => {
      e.preventDefault();
      await formik.setFieldValue("items", formik.values.items.filter(item => item.id !== row.id));
      await formik.setFieldValue("exceptionRecords", [...formik.values.exceptionRecords, {
        id: row.id,
        row: row,
        customerName: row?.customerName,
        collectDate: row?.collectDate,
        companyName: row?.vehicleOwnerName,
        applyAmount: row?.applyAmount,
        reason: "Other",
      }]);
    }

    return (
      <Stack direction="row" spacing={1}>
        <Button variant="contained" onClick={onMove}>Move to Exception</Button>
      </Stack>
    );
  }

  ActionCol.propTypes = {
    formik: PropTypes.object,
    row: PropTypes.object,
  }

  const columns = [
    { field: "bookingNo", headerName: "Booking No", align: "center", headerAlign: "center", width: 150 },
    { field: "agreementNo", headerName: "Agreement No", align: "center", headerAlign: "center", width: 150 },
    { field: "invoiceNo", headerName: "Invoice No", align: "center", headerAlign: "center", width: 150 },
    { field: "customerName", headerName: "Customer", align: "center", headerAlign: "center", width: 200 },
    { field: "title", headerName: "Title", align: "center", headerAlign: "center", width: 200 },
    { field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 125 },
    { field: "billAmount", headerName: "Bill Amount", align: "center", headerAlign: "center", width: 125 },
    { field: "vehicleOwnerName", headerName: "Company Name", align: "center", headerAlign: "center", width: 150 },
    {
      field: "collectDate", headerName: "Collect Date", align: "center", headerAlign: "center", width: 125,
      renderCell: ({ row }) => dayjs(row.collectDate).format("DD MMM YYYY"),
    },
    { field: "applyAmount", headerName: "Apply Amount", align: "center", headerAlign: "center", width: 125 },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => <ActionCol formik={formik} row={row}/>
    },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={formik.values.items ?? []}
      onCellEditCommit={handleCommit}
      disableSelectionOnClick
      autoHeight
    />
  );
}

AgreementTableForm.propTypes = {
  formik: PropTypes.object,
  handleCommit: PropTypes.func,
}

const ExceptionAgreementTable = ({ formik, rows }) => {
  const ActionCol = ({ formik, row }) => {
    const onMove = async e => {
      e.preventDefault();
      await formik.setFieldValue("items", [...formik.values.items, row.row]);
      await formik.setFieldValue("exceptionRecords", formik.values.exceptionRecords.filter(record => record.id !== row.id));
    }

    return (
      <Stack direction="row" spacing={1}>
        {row.reason === "Other" && <Button variant="contained" onClick={onMove}>Revert</Button>}
      </Stack>
    );
  }

  ActionCol.propTypes = {
    formik: PropTypes.object,
    row: PropTypes.object,
  }

  const columns = [
    { field: "customerName", headerName: "Customer Name", align: "center", headerAlign: "center", width: 150 },
    {
      field: "collectDate", headerName: "Collect Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row?.collectDate ? dayjs(row.collectDate).format("DD MMM YYYY") : "",
    },
    { field: "collectAmount", headerName: "Collect Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "reason", headerName: "Reason", align: "center", headerAlign: "center", width: 300 },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => <ActionCol formik={formik} row={row}/>
    },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={rows ?? []}
      disableSelectionOnClick
      autoHeight
    />
  );
}

ExceptionAgreementTable.propTypes = {
  formik: PropTypes.object,
  rows: PropTypes.array,
}

const DownloadAction = () => {
  const buttonRef = useRef(null);

  const onDownload = async e => {
    e.preventDefault();

    const headers = ['customerName', 'collectDate', 'collectAmount'];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const wb = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wb], { type: "application/octet-stream" });

    buttonRef.current.href = URL.createObjectURL(blob);
    buttonRef.current.download = 'Template.xlsx';
    buttonRef.current.click();
  }

  return (
    <>
      <Button variant="contained" color="secondary" onClick={onDownload}>Download Template</Button>
      <a ref={buttonRef} style={{ display: "none" }}>...</a>
    </>
  );
}

const UploadAction = ({ handleUpload }) => {
  const VisuallyHiddenInput = styled('input')({
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    cursor: 'pointer',
  });

  return (
    <>
      <Button variant="contained" component="label">
        Upload
        <VisuallyHiddenInput type="file" accept="application/xlsx" onChange={handleUpload} />
      </Button>
    </>
  );
}

UploadAction.propTypes = {
  handleUpload: PropTypes.func,
}

const ExportAction = ({ formik }) => {
  const buttonRef = useRef(null);

  const onDownload = async e => {
    e.preventDefault();

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formik.values.collections);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const wb = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wb], { type: "application/octet-stream" });

    buttonRef.current.href = URL.createObjectURL(blob);
    buttonRef.current.download = 'Results.xlsx';
    buttonRef.current.click();
  }

  return (
    <>
      <Button variant="contained" color="info" onClick={onDownload}>
        Export
      </Button>
      <a ref={buttonRef} style={{ display: "none" }}>...</a>
    </>
  );
}

ExportAction.propTypes = {
  formik: PropTypes.object,
}

export default function BulkApplyCollectionForm({ invoices, onReload, onClose }) {
  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/collection/bulk-apply");

  const formik = useFormik({
    initialValues: {
      userId: getAuth().currentUser.uid,
      items: [],
      exceptionRecords: [],
      collections: [],
    },
    validationSchema: Yup.object({
      items: Yup.array().of(
        Yup.object({
          agreementId: Yup.string().required("Agreement is required!"),
          invoiceId: Yup.string().required("Invoice is required!"),
          paymentMethod: Yup.string().required("Payment method is required!"),
          vehicleOwnerId: Yup.string().required("Company is required!"),
          collectDate: Yup.date().required("Collect Date is required!"),
          applyAmount: Yup.number().required("Apply amount is required!"),
        })
      ),
      userId: Yup.string().required("User not defined!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost({ ...values, items: values.items.map(item => ({ ...item, collectDate: item.collectDate.toISOString() })) });
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Applied done!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const handleCommit = ({ id, value }) => {
    const updatedRows = formik.values.items.map(row => {
      if (row.id === id) row.applyAmount = Number(value);
      return { ...row };
    });
    formik.setFieldValue("items", updatedRows);
  }

  const handleUpload = async e => {
    e.preventDefault();
    const file = e.target.files[0];

    const collections = xlsxToJson(await file.arrayBuffer());

    const { items } = formik.values;
    const newItems = [];
    const issueItems = [];
    items.forEach(item => {
      const records = collections.filter(row => row.customerName === item.customerName);
      if (records.length !== 1) {
        issueItems.push({ ...item, reason: records.length === 0 ? "Customer not found" : "Same customer name" });
        return;
      }

      const record = records[0];
      if (Number(record.collectAmount) > item.outstanding) {
        issueItems.push({ ...record, id: item.id, reason: "Overpaid" });
        return;
      }

      newItems.push({
        id: item.id,
        agreementId: item.agreementId,
        bookingNo: item.bookingNo,
        agreementNo: item.agreementNo,
        customerName: item.customerName,
        invoiceId: item.invoiceId,
        invoiceNo: item.invoiceNo,
        title: item.title,
        outstanding: item.outstanding,
        billAmount: item.billAmount,
        applyAmount: Number(record.collectAmount),
        paymentMethod: "PayNow",
        vehicleOwnerId: item.vehicleOwnerId,
        vehicleOwnerName: item.vehicleOwnerName,
        collectDate: xlsxDateToDate(record.collectDate),
      });
    });

    await formik.setFieldValue("exceptionRecords", issueItems);
    await formik.setFieldValue("items", newItems);
  }

  const handleMultipleUpload = async e => {
    e.preventDefault();
    const file = e.target.files[0];

    const collections = xlsxToJson(await file.arrayBuffer());
    const { items: invoices } = formik.values;

    const exceptions = [];
    collections.forEach(collection => {
      const customerInvoices = invoices.filter(invoice => invoice.customerName === collection.customerName);
      const customerIds = new Set(customerInvoices.map(invoice => invoice.customerId));
      if (customerIds.size > 1) {
        collection.status = "rejected";
        collection.reason = "same customer name";
        exceptions.push({
          customerName: collection.customerName,
          collectDate: dayjs(collection.collectDate),
          collectAmount: collection.collectAmount,
          reason: "same customer name",
        });
        return;
      }

      collection.applyAmount = 0;
      customerInvoices.forEach(invoice => {
        if (invoice.outstanding - invoice.applyAmount >= collection.collectAmount - collection.applyAmount) {
          const applyAmount = collection.collectAmount - collection.applyAmount;
          invoice.applyAmount += applyAmount;
          collection.applyAmount += applyAmount;
          collection.invoiceId = invoice.id;
          collection.invoiceNo = invoice.invoiceNo;
          collection.status = "accepted";
        }
      });

      if (collection.collectAmount > collection.applyAmount) {
        collection.status = "rejected";
        collection.reason = "not fully applied";
        exceptions.push({
          customerName: collection.customerName,
          collectDate: dayjs(collection.collectDate),
          collectAmount: collection.collectAmount,
          reason: "not fully applied",
        });
      }
    });

    await formik.setFieldValue("collections", collections);
    await formik.setFieldValue("items", invoices);
    await formik.setFieldValue("exceptionRecords", exceptions.map((ex, index) => ({ ...ex, id: index })));
  }

  useEffect(() => {
    if (invoices) {
      const rows = invoices.map(invoice => ({
        id: invoice.id,
        agreementId: invoice.agreementId,
        bookingNo: invoice.bookingNo,
        agreementNo: invoice.agreementNo,
        customerName: invoice.customerName,
        customerId: invoice.customerId,
        invoiceId: invoice.id,
        invoiceNo: invoice.invoiceNo,
        title: invoice.title,
        outstanding: invoice.items?.reduce((sum, cur) => sum + cur.unitPrice * cur.quantity - cur.amountPaid, 0),
        vehicleOwnerId: invoice.vehicleOwnerId,
        vehicleOwnerName: invoice.vehicleCompanyName,
        billAmount: invoice.totalAmount,
        applyAmount: 0,
      }));
      formik.setFieldValue("items", rows);
    }
  }, [invoices]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <ExportAction formik={formik}/>
              <DownloadAction/>
              <UploadAction handleUpload={handleMultipleUpload}/>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <AgreementTableForm formik={formik} handleCommit={handleCommit} />
          </Grid>
          {formik.values.exceptionRecords.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Exception Records</Typography>
              </Grid>
              <Grid item xs={12}>
                <ExceptionAgreementTable formik={formik} rows={formik.values.exceptionRecords}/>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Apply" onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

BulkApplyCollectionForm.propTypes = {
  invoices: PropTypes.array,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}