import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import WorkshopJobForm from '../../../components/Form/Workshop/Job/WorkshopJobForm';
import BackButton from '../../../components/BackButton';
import WorkshopMajorJobForm from '../../../components/Form/Workshop/Job/WorkshopMajorJobForm';

export default function EditJob() {
  const params = useParams();
  const { data } = useCloudFuncGet(`${cloudFunctionPath}/workshop/job/${params.id}`)

  return (
    <Box sx={{ padding: { xs: 1, md: 0 } }}>
      <BackButton />

      <Typography
        variant="h4"
        gutterBottom
        mb={3}
        sx={{ textAlign: { xs: "center", md: "start" }, fontSize: { xs: 22, md: 24 } }}
      >
        Editing {`${dayjs(data.createdAt).format("ddd, D MMM HH:mm:ss")} ${data.data?.vehicleNumber}`}
      </Typography>

      { data.data?.startDate ? <WorkshopMajorJobForm data={data.data ?? []} /> : <WorkshopJobForm data={data.data ?? []} /> }
    </Box>
  );
}