import { Grid } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import useHttpGet from '../../../hooks/http/useHttpGet';
import { FcSelectField, FcTextField, SubmitBtnGroup } from '../FormFields';

export default function VehicleFilterForm({ onReload }) {
  const { data: makes } = useHttpGet('/api/vehicles/make');
  const { data: models } = useHttpGet('/api/vehicles/model');

  const formik = useFormik({
    initialValues: {
      vehicleNumber: '',
      makeId: '',
      modelId: ''
    },
    validationSchema: Yup.object({
      makeId: Yup.string().nullable(),
      modelId: Yup.string().nullable(),
      vehicleNumber: Yup.string().nullable()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onReload(values);
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField
              formik={formik}
              label="Make"
              name="makeId"
              items={makes?.map(make => ({ value: make.id, label: make.name })) ?? []} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField
              formik={formik}
              label="Model"
              name="modelId"
              items={
                models
                  ?.filter(model => formik.values.makeId ? model.makeId === formik.values.makeId : true)
                  ?.map(model => ({ value: model.id, label: model.name })) ?? []
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="Vehicle No." name="vehicleNumber" />
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Search" cancelText="Clear" onCancel={formik.resetForm} />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

VehicleFilterForm.propTypes = {
  onReload: PropTypes.func
};