import { Autocomplete, TextField } from '@mui/material';
import { fieldToTextField } from 'formik-mui';
import PropTypes from 'prop-types';

export default function ModelAutocomplete({
  // field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  textFieldProps,
  ...props
}) {
  const {
    form: { setTouched, setFieldValue }
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  ModelAutocomplete.propTypes = {
    props: PropTypes.object
  };
  return (
    <Autocomplete
      {...props}
      {...field}
      autoComplete
      autoHighlight
      autoSelect
      onChange={(_, value) => {
        setFieldValue(name, value);
      }}
      onBlur={() => {
        setTouched({ [name]: true });
      }}
      {...textFieldProps}
      getOptionLabel={(option) => option}
      renderInput={(props2) => (
        <TextField {...textFieldProps} {...props2} helperText={helperText} error={error} />
      )}
    />
  );
}
