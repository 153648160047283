import { Container, Typography } from '@mui/material';
import moment from 'moment/moment';
import LeaveCalendar from '../../components/LeaveCalendar';
import useCloudFuncGet from '../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../cloudFunctionApiConfig';

export default function FcLeaveCalendar() {
  const { data } = useCloudFuncGet(`${cloudFunctionPath}/leave/allLeaveList`);
  const events =
    data?.data?.map((e) => ({
      id: e.id,
      title: e.name,
      start: moment(e.start).toDate(),
      end: moment(e.end).toDate(),
      leaveType: e.leaveType,
      allDay: e.allDay,
      name: e.name,
      leaveStatus: e.leaveStatus,
      eventType: e.eventType,
      isHalfDay: e.isHalfDay,
      halfDayType: e.halfDayType
    })) ?? [];

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mb={3}>
        Leave Calendar
      </Typography>
      <LeaveCalendar events={events} />
    </Container>
  );
}
