import {useEffect} from "react";
import {Container, Stack, TextField, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from 'yup';
import {LoadingButton} from "@material-ui/lab";
import {useNavigate, useParams} from "react-router-dom";
import VehicleDisposalTask from "./VehicleDisposalTask";
import {fetchEnhcApi} from "../../../utils/fetchApi";

export default function EditDisposalStatus() {
  const navigate = useNavigate();
  const { id } = useParams();

  const updateDisposalStatus = async (values) => {
    try {
      await fetchEnhcApi("UpdateDisposalStatus", values)
      navigate("../");
    } catch (error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      Status: "",
    },
    validationSchema: Yup.object().shape({
      Status: Yup.string().required("Please fill in the status"),
    }),
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(false);
      await updateDisposalStatus({id, ...values});
      setSubmitting(true);
    }
  });

  useEffect(() => {
    fetchEnhcApi("GetDisposalStatus", { id })
      .then(res => formik.setFieldValue('Status', res[0].Status))
      .catch(console.error);
  }, []);

  const {errors, touched, handleSubmit, isSubmitting, getFieldProps} = formik;
  return (
    <Container maxWidth="xl">
      <Stack spacing={3}>
        <Typography variant="h4" gutterBottom>
          Edit Disposal Status
        </Typography>

        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                variant="filled" fullWidth
                label="Status"
                {...getFieldProps("Status")}
                error={Boolean(touched.Status && errors.Status)}
                helperText={touched.Status && errors.Status}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Update Status
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>

        <VehicleDisposalTask/>
      </Stack>
    </Container>
  );
}
