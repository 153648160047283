import { Container, Typography } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton';
import WorkshopAppointmentForm from '../../../components/Form/Workshop/WorkshopAppointmentForm';

export default function NewWorkshopAppointment() {
  const [params] = useSearchParams();

  return (
    <Container maxWidth="xl">
      <BackButton />

      <Typography variant="h4" gutterBottom mb={3}>
        Creating New Workshop Appointment
      </Typography>

      <WorkshopAppointmentForm time={params.get('time')} />
    </Container>
  )
}