import {Grid} from "@mui/material";
import PropTypes from "prop-types";
import {useContext} from "react";
import * as Yup from 'yup';
import {Form, FormikProvider, useFormik} from "formik";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import DataContext from "../../../store/DataContext";
import {FcTextField, SubmitBtnGroup} from "../FormFields";

export default function ShellCardForm({ onClose, onReload }) {
  const dataCtx = useContext(DataContext);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/order`);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please fill in the name!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost(values);
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Order Card Successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcTextField formik={formik} label="Name" name="name"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Create" onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

ShellCardForm.propTypes = {
  onClose: PropTypes.func,
  onReload: PropTypes.func,
}