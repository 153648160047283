import {useContext, useEffect, useState} from "react";
import {collection, getDocs, query} from "firebase/firestore";
import FirestoreContext from "../../store/FirestoreContext";

export default function useGetCollections(path) {
  const fsCtx = useContext(FirestoreContext);
  const db = fsCtx.fsObject;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onFetch = async () => {
    const q = query(collection(db, path));
    setLoading(true);
    try {
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(docs);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }

  useEffect(() => onFetch(), [db, path]);

  return { data, loading, error, onFetch };
}