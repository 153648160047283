import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import AgreementForm from "../../../../components/Form/Booking/Agreement/AgreementForm";
import ExtensionForm from "../../../../components/Form/Booking/Extension/ExtensionForm";
import BookingAgreementTable from "../../../../components/Table/Booking/BookingAgreementTable";
import AuthContext from "../../../../store/AuthContext";
import {AGREEMENT_PERMISSIONS} from "../../../../utils/authorization/permissions/bookingPermission";
import {permissionRequired} from "../../../../utils/authorization/roles";

const CreateAgreementAction = ({ data, rentalAgreement, onReload, agreementType, disabled, cov = false }) => {
  const [open, setOpen] = useState(false);

  let label = agreementType === "Rental" ? "Handover" : "Return";
  if (cov) label = "Return for Change";

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Create {agreementType} Agreement</DialogTitle>
        <DialogContent>
          <AgreementForm
            booking={data} rentalAgreement={rentalAgreement} agreementType={agreementType}
            onReload={onReload} onClose={() => setOpen(false)} isChangeOfVehicle={cov}
          />
        </DialogContent>
      </Dialog>
      <Button disabled={disabled} variant="contained" onClick={() => setOpen(true)}>
        {label}
      </Button>
    </>
  );
}

CreateAgreementAction.propTypes = {
  data: PropTypes.object,
  rentalAgreement: PropTypes.object,
  onReload: PropTypes.func,
  agreementType: PropTypes.string,
  disabled: PropTypes.bool,
  cov: PropTypes.bool,
}

const ExtendAction = ({ booking, agreements, onReload, disabled }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Create Extension</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <ExtensionForm booking={booking} agreements={agreements} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" disabled={disabled} onClick={() => setOpen(true)}>
        Extend
      </Button>
    </>
  );
}

ExtendAction.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  onReload: PropTypes.func,
  disabled: PropTypes.bool,
}

const getAgreementStatus = (booking, agreements) => {
  const records = booking?.vehicleUsageRecords ?? [];
  const activeRecords = records?.filter(record => record.rentalPrice > 0) ?? [];

  let agreementType = "Rental";
  let rentalAgreement = null;
  let contractEnd = false;
  let isReplace = false;
  if (agreements && agreements.length > 0) {
    const activeAgreements = agreements.filter(agreement => agreement.status === "Active")
      .sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt));

    if (activeAgreements.length > 0) {
      agreementType = activeAgreements[0].agreementType === "Rental" ? "Return" : "Rental";
      const rentalAgreements = activeAgreements.filter(agreement => agreement.agreementType === "Rental");
      const returnAgreements = activeAgreements.filter(agreement => agreement.agreementType === "Return");
      rentalAgreement = rentalAgreements.length > 0 ? rentalAgreements[0] : null;

      if (rentalAgreements.length === returnAgreements.length && rentalAgreements.length === activeRecords.length) {
        contractEnd = true;
      }
    }
  }

  if (rentalAgreement) {
    const { addendums } = rentalAgreement;
    const handoverAddendums = addendums.filter(addendum => addendum.addendumType === "Handover Replacement Vehicle");
    const returnAddendums = addendums.filter(addendum => addendum.addendumType === "Return Replacement Vehicle");
    if (handoverAddendums.length > returnAddendums.length) isReplace = true;
  }

  return { agreementType, rentalAgreement, contractEnd, isReplace };
}

export default function BookingDocumentsAccordion({ booking, agreements, onReload }) {
  const authCtx = useContext(AuthContext);

  const { agreementType, rentalAgreement, contractEnd, isReplace } = getAgreementStatus(booking, agreements);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Agreements</Typography>
          <Stack direction="row" spacing={1}>
            {/* agreementType === "Return" && permissionRequired(authCtx, AGREEMENT_PERMISSIONS.CREATE) && (
              <CreateAgreementAction data={booking} rentalAgreement={rentalAgreement} onReload={onReload} agreementType={agreementType} disabled={contractEnd || isReplace} cov/>
            ) */}
            {permissionRequired(authCtx, AGREEMENT_PERMISSIONS.CREATE) && (
              <CreateAgreementAction data={booking} rentalAgreement={rentalAgreement} onReload={onReload} agreementType={agreementType} disabled={contractEnd || isReplace}/>
            )}
            {permissionRequired(authCtx, AGREEMENT_PERMISSIONS.UPDATE) && (
              <ExtendAction booking={booking} agreements={agreements} onReload={onReload} disabled={contractEnd || isReplace}/>
            )}
            {permissionRequired(authCtx, AGREEMENT_PERMISSIONS.CREATE) && (
              <Button component={Link} to="./renew" target="_blank" variant="contained">Renew</Button>
            )}
          </Stack>
        </Stack>
        {agreements && <BookingAgreementTable booking={booking} data={agreements} onReload={onReload}/>}
      </Grid>
    </Grid>
  );
}

BookingDocumentsAccordion.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  onReload: PropTypes.func,
}