import { useCallback, useContext, useMemo, useState } from 'react';
import {
  Alert,
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import JSZip from 'jszip';
import MultiSelectChecksboxs from '../../components/MultiSelectCheckboxs';
import AdminLayout from '../../layouts/AdminLayout';
import FullScreenDialog from '../../components/FullScreenDialog';
import { FinanceDocument, pdfBase64StringGenerator } from '../../components/FinanceDocument';
import { PopupModal } from '../../components/PopupModal';
import DataContext from '../../store/DataContext';
import coConverter, { coConverterToLong } from '../../utils/coConverter';
import fetchApi, { fetchEnhcApi } from '../../utils/fetchApi';
import errorHandler from '../../utils/errorHandler';

const InvoiceListing = () => {
  const today = moment(new Date());
  const [fromValue, setFromValue] = useState(today);
  const [toValue, setToValue] = useState(today);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });
  const [invoiceListing, setInvoiceListing] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const dataCtx = useContext(DataContext);
  const handleModalOpen = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    setModalData({});
  }, [setModalOpen, setModalData]);

  const ModalContent = useCallback(
    () => (
      <>
        <Typography variant="h4" gutterBottom>
          Invoice
        </Typography>
        {modalData.data}
      </>
    ),
    [modalData.data]
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = useCallback(() => {
    if (window.confirm('Close without saving?')) {
      setDialogOpen(false);
    }
  }, [setDialogOpen]);
  const DialogContent = useCallback(
    () => (
      <Typography variant="h4" gutterBottom>
        dialogContentTitle
      </Typography>
    ),
    []
  );

  const viewPdf = useCallback(
    async (params) => {
      setOpenBackdrop(true);
      const invDetails2 = await fetchEnhcApi('GetInvoiceDetails', { invoicecode: params.value });

      handleModalOpen({
        data: (
          <FinanceDocument
            data={{
              columns: [
                { name: 'Description', length: 66 },
                { name: 'Qty', length: 8 },
                { name: 'Rate', length: 13 },
                { name: 'Amount', length: 13 }
              ],
              mainData: {
                address: invDetails2[0].invoiceaddress,
                postalCode: invDetails2[0].postalcode,
                remarks: `${invDetails2[0].invoicetitle}\n${invDetails2[0].invoiceMainRemarks}\nInvoice Reference No.: ${invDetails2[0].refinvoiceno}`,
                rentalAgreementNo: invDetails2[0].RentalAgreementNo
              },
              data: [
                ...invDetails2.map((el) => ({
                  Description: `${
                    el.ItemGroup === 'Rental Rate'
                      ? `${el.CarMake} ${el.CarModel} ${el.CarPlate}\nRental Period ${moment(
                          el.extraitemdate
                        ).format('DD/MM/yyyy')} - ${moment(el.itemdeptdate).format(
                          'DD/MM/yyyy'
                        )}\n${el.sevicedesc}`
                      : el.sevicedesc
                  }${el.lineItemRemarks !== null ? `\n${el.lineItemRemarks}` : ''}`,
                  ItemGroup: el.ItemGroup,
                  Qty: el.itemqty,
                  Rate: el.itemsellprice,
                  Amount: el.totalitemsellprice
                }))
              ],
              amount: params.row.amount,
              bookingNo: params.row.bookNo,
              company: params.row.company,
              docDate: params.row.date,
              docNo: params.row.documentNo,
              docType: 'Invoice',
              entity: params.row.hirer,
              gstRate: getGstRate(params.row.date),
              id: params.value,
              invoiceTerms: dataCtx.adminSettings.invoiceTerms,
              // invoiceterm: invDetails2[0].invoiceterm,
              // isGstReg: params.row.company === 'FC' && dataCtx.adminSettings.fcGst
              isGstReg: params.row.company === 'FC' || params.row.company === 'OC',
              ocGstRegDate: dataCtx.adminSettings.ocGst
            }}
          />
        ),
        docType: params.row.docType
      });
      setOpenBackdrop(false);
    },
    [dataCtx.adminSettings]
  );

  const tableColumns = [
    {
      field: 'id',
      headerName: 'Options',
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" color="primary" size="small" onClick={() => viewPdf(params)}>
          Print
        </Button>
      ),
      type: 'string'
    },
    {
      field: 'company',
      headerName: 'Co',
      width: 50,
      type: 'string',
      sortable: true
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 120,
      type: 'date',
      valueFormatter: (params) =>
        params.value.toLocaleDateString('en-sg', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }),
      sortable: true
    },
    {
      field: 'bookNo',
      headerName: 'Booking',
      width: 150,
      type: 'string',
      editable: false
    },
    {
      field: 'hirer',
      headerName: 'Hirer',
      width: 300,
      type: 'string',
      editable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      type: 'string',
      editable: false,
      renderCell: (params) => (
        <div style={{ color: params.value === 'VOIDED' ? 'red' : undefined }}>{params.value}</div>
      )
    },
    {
      field: 'documentNo',
      headerName: 'No.',
      width: 150,
      editable: false
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 330,
      type: 'string',
      editable: false
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 90,
      type: 'number',
      editable: false
    },
    {
      field: 'os',
      headerName: 'Outstanding',
      width: 90,
      type: 'number',
      editable: false
    },
    {
      field: 'gst',
      headerName: 'GST',
      width: 90,
      type: 'number',
      editable: false,
      renderCell: (params) =>
        (
          params.row.amount *
          (dataCtx.adminSettings.gstRate / (100 + dataCtx.adminSettings.gstRate))
        ).toFixed(2)
    }
  ];
  const getGstRate = (tempdate) => {
    const { gstRateList } = dataCtx;
    if (tempdate === '') {
      return 0;
    }
    const date = moment(tempdate);
    let currate = 0;

    for (let i = gstRateList.length; i > 0; i -= 1) {
      const { dateStart } = gstRateList[i - 1];
      const gstDate = moment(dateStart.seconds * 1000 + dateStart.nanoseconds / 1000000);
      const isAfter = date.isAfter(gstDate);
      const isSame = date.format('DD/MM/YYYY') === gstDate.format('DD/MM/YYYY');
      // console.log(gstDate.format('DD/MM/YYYY'));
      // console.log(date.format('DD/MM/YYYY'));
      if (isAfter || isSame) {
        currate = gstRateList[i - 1].rate;
        break;
      }
    }

    return currate;
  };

  const emailDownloadFn = useCallback(
    async (isEmail) => {
      setOpenBackdrop(true);
      const base64PromiseArray = [];

      try {
        // Fetch invoice details for every invoice selected
        const invMainReqArr = selectedRows.map((invoicecode) => ({
          method: 'GetInvoice',
          requestBody: JSON.stringify({ invoicecode })
        }));

        const invDetailsReqArr = selectedRows.map((invoicecode) => ({
          method: 'GetInvoiceDetails',
          requestBody: JSON.stringify({ invoicecode })
        }));

        const invMainArray = (
          await fetchApi([
            { method: 'GetInvoice', multipleRequests: true, requestBody: invMainReqArr }
          ])
        ).map((el) => el[0]);

        const invDetailsArray = (
          await fetchApi([
            { method: 'GetInvoiceDetails', multipleRequests: true, requestBody: invDetailsReqArr }
          ])
        ).map((el) =>
          el.filter((el2) => {
            const invFromMainListing = invoiceListing.find(
              (invEl) => invEl.documentNo === el[0].masterinvoicecode
            );
            const correctCarPlate = invFromMainListing.carplate;
            return el2.CarPlate === correctCarPlate;
          })
        );
        // Generate Base64 strings for all document PDFs
        const notSentList = [];
        const newSelectedRows = selectedRows.filter((el) => {
          // console.log(invDetailsArray);
          const invDetails = invDetailsArray.find((invDetailsEl) => {
            return invDetailsEl[0] && invDetailsEl[0].masterinvoicecode === el;
          });
          if (invDetails == null) {
            notSentList.push(el);
          }
          return invDetails != null;
        });

        newSelectedRows.forEach((el, index) => {
          const data = invoiceListing.find((invEl) => invEl.id === el);
          const invMain = invMainArray.find((invMainEl) => invMainEl.invoicecode === el);
          const invDetails = invDetailsArray.find(
            (invDetailsEl) => invDetailsEl[0] && invDetailsEl[0].masterinvoicecode === el
          );

          base64PromiseArray.push(
            pdfBase64StringGenerator({
              columns: [
                { name: 'Description', length: 70 },
                { name: 'Qty', length: 10 },
                { name: 'Rate', length: 10 },
                { name: 'Amount', length: 10 }
              ],
              mainData: {
                address: invMain.invoiceaddress,
                postalCode: invMain.postalcode,
                remarks: `${invMain.invoicetitle}\n${invMain.remarks}\nInvoice Reference No.: ${invMain.refinvoiceno}`,
                rentalAgreementNo: invMain.RentalAgreementNo
              },
              data: [
                ...invDetails.map((el) => ({
                  Description:
                    el.ItemGroup === 'Rental Rate'
                      ? `${el.CarMake} ${el.CarModel} ${el.CarPlate}\nRental Period ${el.extraitemdate} - ${el.itemdeptdate}\n${el.sevicedesc}`
                      : el.sevicedesc,
                  ItemGroup: el.ItemGroup,
                  Qty: el.itemqty,
                  Rate: el.itemsellprice,
                  Amount: el.totalitemsellprice
                }))
              ],
              amount: data.amount,
              bookingNo: data.bookNo,
              company: data.company,
              docDate: data.date,
              docNo: data.documentNo,
              docType: 'Invoice',
              entity: data.hirer,
              gstRate: getGstRate(data.date),
              invoiceTerms: dataCtx.adminSettings.invoiceTerms,
              id: el,
              isGstReg: data.company === 'FC' && dataCtx.adminSettings.fcGst
            })
          );
        });

        const result = await Promise.allSettled(base64PromiseArray);

        result.forEach((el) => {
          if (el.status !== 'fulfilled') {
            throw Error('Some promises of conversion to base 64 strings were not fulfilled.');
          }
        });

        const mappedResult = result.map((el, i) => {
          const invData = invoiceListing.find((invEl) => invEl.id === newSelectedRows[i]);
          return {
            company: coConverterToLong(invData.company),
            hirer: invData.hirer,
            email: invData.email,
            documentNo: newSelectedRows[i],
            pdfBase64String: el.value
          };
        });

        // const resp = fetchApi

        if (mappedResult.length > 0) {
          if (isEmail) {
            // console.log(mappedResult);
            const resp = await fetchApi([
              { method: 'SendInvoiceEmail', requestBody: mappedResult }
            ]);
            console.log(`Done sending emails: ${resp[0].value.d}`);
            handleSnackbar(`Your emails were successfully sent. ${resp[0].value.d}`, 'success');
          } else {
            const zip = new JSZip();
            mappedResult.forEach((el) => {
              zip.file(`${el.documentNo}-${el.hirer}.pdf`, el.pdfBase64String, { base64: true });
            });
            const zippedBase64 = await zip.generateAsync({ type: 'base64' });
            window.location.href = `data:application/zip;base64,${zippedBase64}`;
            handleSnackbar('Your download is ready.', 'success');
          }
        }
        if (notSentList.length > 0) {
          setTimeout(() => {
            handleSnackbar(
              `Something wrong. ${notSentList.join(
                ','
              )} emails are not sent or cant download the pdf`,
              'error'
            );
            console.log(
              `Something wrong. ${notSentList.join(
                ','
              )} emails are not sent or cant download the pdf`
            );
          }, 3000);
        }
      } catch (e) {
        errorHandler('Send All Invoice Emails', 'SendInvoiceEmails', e);
      }
      setOpenBackdrop(false);
    },
    [dataCtx.adminSettings, invoiceListing, setOpenBackdrop, selectedRows]
  );

  const tooltipNotes = ['1. x.'];
  const filters = [];
  const tabNames = [];
  const searches = useMemo(
    () => [
      {
        name: 'Email',
        disabled:
          selectedRows.length === 0 ||
          invoiceListing
            .filter((el) => selectedRows.findIndex((el2) => el2 === el.documentNo) >= 0)
            .findIndex((el3) => el3.status === 'VOIDED') >= 0,
        fn: () => emailDownloadFn(true)
      },
      {
        name: 'Download',
        disabled:
          selectedRows.length === 0 ||
          invoiceListing
            .filter((el) => selectedRows.findIndex((el2) => el2 === el.documentNo) >= 0)
            .findIndex((el3) => el3.status === 'VOIDED') >= 0,
        fn: () => emailDownloadFn(false)
      }
    ],
    [emailDownloadFn, invoiceListing, selectedRows]
  );

  const TopRightOptions = useCallback(
    () => (
      <ButtonGroup size="medium" variant="contained" aria-label="outlined primary button group">
        {searches.map((el) => (
          <Button disabled={el.disabled} key={el.name} onClick={el.fn}>
            {el.name}
          </Button>
        ))}
      </ButtonGroup>
    ),
    [searches]
  );

  const fetchInvoiceListing = useCallback(async () => {
    setOpenBackdrop(true);
    try {
      const response = await fetchApi([
        {
          method: 'GetInvoiceListing',
          requestBody: {
            BookNo: '',
            StartDate: moment(fromValue).format('DD/MM/yyyy'),
            EndDate: moment(toValue).format('DD/MM/yyyy'),
            InvoiceNo: '',
            Company: '',
            Hirer: '',
            CarPlate: '',
            Status: '',
            displaylength: 500
          }
        }
      ]);
      setInvoiceListing(
        response[0].aaData.map((el) => ({
          amount: el.totalsellprice,
          bookNo: el.bookno,
          carplate: el.carplate,
          id: el.invoicecode,
          company: coConverter(el.company),
          date: new Date(el.invoicedate),
          email: el.email,
          hirer: el.HirerName,
          documentNo: el.invoicecode,
          description: el.invoicetitle,
          os: el.amount,
          status: el.invoicestatus
        }))
      );
    } catch (e) {
      window.alert(`An error occurred: ${e}`);
    }
    setOpenBackdrop(false);
  }, [setOpenBackdrop, setInvoiceListing, fromValue, toValue]);

  const handleSnackbar = (message, type) => {
    setSnackbarInfo({ message, type });
    setOpenSnackbar(true);
  };

  const fromValueHandleChange = useCallback(
    (newValue) => {
      setFromValue(newValue);
      setInvoiceListing([]);
    },
    [setFromValue, setInvoiceListing]
  );
  const toValueHandleChange = useCallback(
    (newValue) => {
      setToValue(newValue);
      setInvoiceListing([]);
    },
    [setToValue, setInvoiceListing]
  );

  const code = (
    <>
      <Stack alignItems="center" direction="row" mt={2} mb={2} spacing={2}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            disableMaskedInput
            label="From"
            inputFormat="DD/MM/yyyy"
            value={fromValue}
            onChange={fromValueHandleChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <DesktopDatePicker
            disableMaskedInput
            label="To"
            inputFormat="DD/MM/yyyy"
            value={toValue}
            onChange={toValueHandleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Button
          onClick={async () => {
            if (!fromValue.isValid()) {
              window.alert('Please enter a valid from date.');
              return;
            }

            if (!toValue.isValid()) {
              window.alert('Please enter a valid to date.');
              return;
            }

            if (toValue.diff(fromValue, 'seconds') < 0) {
              window.alert('From date must be earlier than to date.');
              return;
            }

            await fetchInvoiceListing();
          }}
          variant="contained"
        >
          Search
        </Button>
      </Stack>
      <MultiSelectChecksboxs
        list={invoiceListing}
        selectAmount={100}
        SelectItem={setSelectedRows}
      />
      {/* {selectedRows.map(item=><span>{item}</span>)} */}
    </>
  );

  return (
    <>
      <FullScreenDialog
        dialogOpen={dialogOpen}
        handleDialogClose={closeDialog}
        data={{}}
        code={DialogContent}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '40%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PopupModal openState={modalOpen} closeHandler={handleModalClose} code={ModalContent} />
      <AdminLayout
        code={code}
        dataGridCheckbox
        title="Invoice Listing"
        tableColumns={tableColumns}
        data={invoiceListing}
        tooltipNotes={tooltipNotes}
        filters={filters}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tabNames={tabNames}
        topRightOptions={<TopRightOptions />}
      />
    </>
  );
};

export default InvoiceListing;
