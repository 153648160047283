import {
  Button,
  Container,
  Snackbar,
  Alert,
  Stack,
  Typography, Box,
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import React, { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@material-ui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from "prop-types";
import moment from "moment";
import DataContext from '../../store/DataContext';
import UserForm from "../../components/Form/User/UserForm";
import useDeleteDocument from "../../hooks/firebase/useDeleteDocument";
import useGetCollections from "../../hooks/firebase/useGetCollections";

const AddAction = ({ onReload, onOpenSnackbar }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <DialogContentText>Fill in the form below.</DialogContentText>
          <UserForm
            onClose={() => setOpen(false)}
            onReload={onReload}
            onOpenSnackbar={onOpenSnackbar}
          />
        </DialogContent>
      </Dialog>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<Icon icon={plusFill} />}
        sx={{ margin: 1 }}
      >
        Create New User
      </Button>
    </>
  );
}

AddAction.propTypes = {
  onReload: PropTypes.func,
  onOpenSnackbar: PropTypes.func,
}

const EditAction = ({ row, onOpenSnackbar, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open}>
        <Box sx={{ p: 3 }}>
          <DialogTitle id="alert-dialog-title">
            Update User
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Fill in the form below.</DialogContentText>
            <UserForm
              userData={row}
              onClose={() => setOpen(false)}
              onReload={onReload}
              onOpenSnackbar={onOpenSnackbar}
            />
          </DialogContent>
        </Box>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Edit
      </Button>
    </>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  onOpenSnackbar: PropTypes.func,
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onOpenSnackbar, onReload }) => {
  const [open, setOpen] = useState(false);
  const { loading, onDelete } = useDeleteDocument("fcadmin/fcadmin/users");

  const onDeleteUser = async () => {
    try {
      await onDelete(row);
      await onReload();
      onOpenSnackbar("User Document is deleted, please delete the auth email manually");
      setOpen(false);
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, "error");
      console.log(error);
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 3 }}>
          <DialogTitle id="alert-dialog-title">
            Do You want to delete user {row.name} document?
          </DialogTitle>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained" color="error"
              onClick={onDeleteUser}
              autoFocus loading={loading}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button
        variant="contained" color="error"
        onClick={() => setOpen(true)}
      >
        Delete
      </Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
  onOpenSnackbar: PropTypes.func,
}

const UsersList = () => {
  const dataCtx = useContext(DataContext);

  const { data, onFetch } = useGetCollections("fcadmin/fcadmin/users");

  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [users, setUsers] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const onOpenSnackbar = (message, type = "success") => {
    setOpenSnackbar(true);
    setSnackbarInfo({ message, type });
  }

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    { field: 'name', headerName: 'Name', align: "center", headerAlign: "center", sortable: false, width: 150 },
    { field: 'email', headerName: 'Email', align: "center", headerAlign: "center", sortable: false, width: 350 },
    {
      field: 'department', headerName: 'Department',
      align: "center", headerAlign: "center",
      sortable: false, width: 150,
      renderCell: ({row}) => dataCtx.departments[row.department],
    },
    {
      field: 'startDate', headerName: 'Start Working Date',
      align: "center", headerAlign: "center",
      width: 250,
      renderCell: ({row}) => row?.startDate ? moment(row.startDate).format("DD-MM-YYYY") : ""
    },
    {
      field: '',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 250,
      sortable: false,
      renderCell: ({row}) => (
        <Stack direction="row" spacing={2}>
          <EditAction
            row={row}
            onReload={onFetch}
            onOpenSnackbar={onOpenSnackbar}
          />
          <DeleteAction
            row={row}
            onReload={onFetch}
            onOpenSnackbar={onOpenSnackbar}
          />
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    if (data) setUsers(data);
  }, [data]);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User List
        </Typography>
        <AddAction onOpenSnackbar={onOpenSnackbar} onReload={onFetch}/>
      </Stack>

      <DataGrid
        rows={users}
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        disableSelectionOnClick
        sx={{ height: window.innerHeight * 0.7 }}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '100%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UsersList;
