import { LoadingButton } from "@material-ui/lab";
import React, {useCallback, useState} from 'react';
import { Stack, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { StyledDataGrid } from '../../../pages/Vehicle/styles';
import { xlsxToJson } from '../../../utils/xlsxToJson';

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  textAlign: 'center'
};

const convertToObject = (data) => {
  return data.map((item, index) => ({
    ...item,
    id: index
  }));
};

const DataImportForm = ({ title, columns, rows, setRows, loading, onSubmit }) => {
  const [pageSize, setPageSize] = useState(10);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const data = xlsxToJson(await file.arrayBuffer());
    const rows = convertToObject(data);
    setRows(rows);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ multiple: false, onDrop });

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>
        <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>Import</LoadingButton>
      </Stack>
      <Box {...getRootProps({ style: style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </Box>
      <StyledDataGrid
        columns={columns} rows={rows}
        pageSize={pageSize} rowsPerPageOptions={[10, 25, 50, 100]}
        onPageSizeChange={size => setPageSize(size)}
        autoHeight
        disableSelectionOnClick
      />
    </Stack>
  );
}

DataImportForm.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DataImportForm;
