import { LoadingButton } from "@material-ui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpPost from "../../../hooks/http/useHttpPost";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import { INVOICE_PERMISSIONS } from "../../../utils/authorization/permissions/invoicePermissions";
import { permissionRequired } from "../../../utils/authorization/roles";
import { getDayjsValue } from "../../../utils/date";
import { getFile } from "../../../utils/getFile";
import InvoiceDetails from "../../Details/Invoice/InvoiceDetails";

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const { onDelete, loading } = useHttpDelete(`/api/invoice/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Invoice deleted successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete {row.invoiceNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ViewAction = ({ row }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const { onPost, loading } = useHttpPost("/api/email");

  const onPrint = e => {
    e.preventDefault();
    window.open(getFile(row?.attachment), "_blank");
  }

  const onEmail = async e => {
    e.preventDefault();

    try {
      await onPost({
        subject: `${row.invoiceNo} Invoice`,
        body: `This is your ${row.invoiceNo} Invoice, please pay the invoice.`,
        emailFrom: {
          email: "noreply@freshcars.sg",
          name: "Fresh Cars Pte Ltd",
        },
        emailToList: [ { email: row.customerEmail, name: row.customerName } ],
        attachments: [ { filename: `${row.invoiceNo}.pdf`, path: row.attachment } ],
        isBodyHtml: false,
      });
      dataCtx.setSnackbarConfig({ open: true, message: "Email sent successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row.invoiceNo} Details</DialogTitle>
        <DialogContent>
          <InvoiceDetails invoice={row}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={onPrint}>Print</Button>
          <LoadingButton loading={loading} variant="contained" onClick={onEmail}>Email</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" spacing={1}>
      <ViewAction row={row}/>
      <Button component={Link} to={getFile(row?.attachment)} target="_blank" variant="contained">Print</Button>
      <Button component={Link} to={`/dashboard/sales/booking/${row.bookingNo}`} target="_blank" variant="contained" color="secondary">
        Booking
      </Button>
      {permissionRequired(authCtx, INVOICE_PERMISSIONS.DELETE) && <DeleteAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function InvoiceTable({ paginatedData, loading, onReload, sortModel, handlePageChange, handlePageSizeChange, handleSortModelChange, selectionModel, setSelectionModel }) {
  const columns = [
    { field: "customerName", headerName: "Customer", align: "center", headerAlign: "center", width: 200, sortable: false },
    { field: "invoiceNo", headerName: "Invoice No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "issueDate", headerName: "Invoice Date", align: "center", headerAlign: "center", width: 125,
      renderCell: ({ row }) => getDayjsValue(row.issueDate).format("DD MMM YYYY"),
    },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 100, sortable: false },
    { field: "bookingNo", headerName: "Booking No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "totalAmount", headerName: "Total Amount", align: "center", headerAlign: "center", width: 125, sortable: false,
      renderCell: ({ row }) => `S$${row.totalAmount}`,
    },
    { field: "title", headerName: "Title", align: "center", headerAlign: "center", width: 400, sortable: false },
    {
      field: "createdAt", headerName: "Create Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => getDayjsValue(row.createdAt).format("DD MMM YYYY"),
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 350,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>,
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      rows={paginatedData?.data ?? []}
      columns={columns}
      autoHeight
      disableSelectionOnClick
      checkboxSelection
      loading={loading}
      rowCount={paginatedData?.totalRows ?? 0}
      pageSizeOptions={[25, 50, 100]}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      paginationMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      sortingMode="server"
      onSelectionModelChange={newRowSelectionModel => setSelectionModel(newRowSelectionModel)}
      selectionModel={selectionModel}
    />
  );
}

InvoiceTable.propTypes = {
  paginatedData: PropTypes.object,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
  sortModel: PropTypes.object,
  handlePageChange: PropTypes.func,
  handlePageSizeChange: PropTypes.func,
  handleSortModelChange: PropTypes.func,
  selectionModel: PropTypes.array,
  setSelectionModel: PropTypes.func,
}