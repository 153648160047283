import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CollectionFilterForm from "../../../../components/Form/Collection/CollectionFilterForm";
import CollectionTable from "../../../../components/Table/Collection/CollectionTable";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import usePaginationModel from "../../../../hooks/pagination/usePaginationModel";

export default function CreditNoteTab() {
  const {
    paginationModel,
    sortModel,
    handlePageSizeChange,
    handlePageChange,
    handleSortModelChange,
  } = usePaginationModel([]);

  const [selectionModel, setSelectionModel] = useState([]);

  const { data, loading, onGet } = useHttpGet("/api/collection/paginated", true, {
    ...paginationModel,
    type: "credit-note"
  });

  return (
    <>
      <Typography variant="h4" mb={3}>Credit Note Listing</Typography>
      <Box mb={3}>
        <CollectionFilterForm onReload={onGet} paginationModel={paginationModel} sortModel={sortModel} type="credit-note" />
      </Box>
      <CollectionTable
        paginatedData={data}
        loading={loading} onReload={onGet}
        sortModel={sortModel}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        handleSortModelChange={handleSortModelChange}
        selectionModel={selectionModel} setSelectionModel={setSelectionModel}
        type="credit-note"
      />
    </>
  );
}