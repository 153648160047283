// material
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
  TextField
} from '@mui/material';
// components
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  collection,
  collectionGroup,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';

import * as yup from 'yup';
import moment from 'moment/moment';

import FirestoreContext from '../../store/FirestoreContext';
import DataTable from '../../components/DataTable';
import FcAppVoucherCategories from './FcAppVoucherCategories';
import { PopupModal } from '../../components/PopupModal';
import { a11yProps, TabPanel } from '../../components/TabPanel';
import NewVoucherCategory from './FcNewVoucherCategory';

// ----------------------------------------------------------------------

export const VoucherDetails = (props) => {
  const [formSuccess, setFormSuccess] = useState(false);
  VoucherDetails.propTypes = {
    voucherDetails: PropTypes.object
  };

  const validationSchema = yup.object({
    claimRef: yup
      .string('Enter a claim reference e.g. booking number, name, car plate etc.')
      .required('A claim reference is required.')
  });

  const fsCtx = useContext(FirestoreContext);

  const formik = useFormik({
    initialValues: {
      claimRef: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const q = query(
          collection(fsCtx.fsObject, 'users', props.voucherDetails.uid, 'vouchers'),
          where('code', '==', props.voucherDetails.code)
        );
        const qSnap = await getDocs(q);
        if (qSnap.docs.length > 1) throw Error('More than one voucher document with same code.');
        const voucherDoc = qSnap.docs.find((el) => el.data().code === props.voucherDetails.code);
        const voucherDocId = voucherDoc.id;

        // Sanity check. It might have been a while since the user fetched the voucher documents. Within this time span, the voucher might have already been redeemed by another fc admin user. Implement the check below to check once again if the voucher has been claimed already or not

        if (voucherDoc.data().claimed) throw Error('This voucher has already been redeemed.');

        await updateDoc(
          doc(fsCtx.fsObject, 'users', props.voucherDetails.uid, 'vouchers', voucherDocId),
          { claimedDate: new Date(), claimRef: values.claimRef, claimed: true }
        );
        setFormSuccess(true);
        alert(`Voucher ${props.voucherDetails.code} has been redeemed.`);
        window.location.reload();
      } catch (e) {
        console.error(`An error occurred submitting voucher claim: ${e}\n${e.stack}`);
        alert(`An error occurred: ${e}`);
      }
    }
  });

  return (
    <>
      {props.voucherDetails.expiry.toDate() < new Date() ? (
        <Box>This voucher has expired</Box>
      ) : (
        <>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Voucher Code: {props.voucherDetails.code}
          </Typography>
          <Typography id="transition-modal-description" sx={{ my: 2 }}>
            User Email: {props.voucherDetails.email}
          </Typography>
          <Typography id="transition-modal-description" sx={{ my: 2 }}>
            Issue Date: {moment(props.voucherDetails.issued.toDate()).format('D MMM YYYY')}
          </Typography>
          <Typography id="transition-modal-description" sx={{ my: 2 }}>
            Amount: ${props.voucherDetails.amount}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction="column" spacing={2}>
              <TextField
                disabled={formSuccess}
                fullWidth
                id="claimRefNotImportant"
                name="claimRef"
                label="Claim Reference"
                value={formik.values.claimRef}
                onChange={formik.handleChange}
                error={formik.touched.claimRef && Boolean(formik.errors.claimRef)}
                helperText={
                  formik.touched.claimRef
                    ? formik.errors.claimRef
                    : 'Enter a claim reference e.g. booking number, name, car plate etc.'
                }
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={formSuccess}
              >
                Submit
              </Button>
              <Button
                color="primary"
                disabled={formSuccess}
                variant="contained"
                fullWidth
                onClick={formik.handleReset}
              >
                Clear
              </Button>
            </Stack>
          </form>
        </>
      )}
      <br />
    </>
  );
};

// ----------------------------------------------------------------------

export default function FcAppVouchers() {
  const fsCtx = useContext(FirestoreContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(true);
  const [vouchersList, setVouchersList] = React.useState([]);
  const tableInitialSort = {
    sorting: {
      sortModel: [{ field: 'code', sort: 'desc' }]
    }
  };

  // Message Details modal logic
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const handleOpen = (msgId) => {
    setOpen(true);
    setModalInfo(msgId);
  };
  const handleClose = () => setOpen(false);

  const columns = [
    {
      field: 'claimed', headerName: 'Claim', width: 100,
      renderCell: (params) => {
        const expDate = params.row.expiry.toDate();
        const expired = expDate < new Date();
        const { claimed } = params.row;
        let buttonStr = 'Claim';
        if (claimed) {
          buttonStr = 'Redeemed';
        }
        if (expired && !claimed) {
          buttonStr = 'Expired';
        }
        return (
          <Button
            disabled={expired || claimed}
            size="small"
            variant="contained"
            checked={params.value}
            onClick={() => {
              handleOpen(
                <VoucherDetails
                  voucherDetails={vouchersList.find((element) => element.id === params.row.id)}
                />
              );
            }}
          >
            {buttonStr}
          </Button>
        );
      }
    },
    {
      field: 'code', headerName: 'Code', width: 70, type: 'string',
      renderCell: (params) => <strong>{params.row.code}</strong>,
      sortComparator: (v1, v2) => {
        const v1Isnumber = /^\d+$/.test(v1.charAt(0));
        const v2Isnumber = /^\d+$/.test(v2.charAt(0));
        if (v1Isnumber && !v2Isnumber) {
          return 1;
        }
        if (!v1Isnumber && v2Isnumber) {
          return -1;
        }
        return v1.localeCompare(v2);
      }
    },
    { field: 'email', headerName: 'Email', width: 250, type: 'string', sortable: true, editable: false },
    {
      field: 'issued', headerName: 'Issued', width: 120, type: 'date', sortable: true,
      valueFormatter: (params) =>
        params.value.toDate().toLocaleDateString('en-sg', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })
    },
    {
      field: 'claimedDate', headerName: 'Claim Date', width: 140, type: 'date', sortable: true,
      valueFormatter: (params) =>
        params.value === null
          ? undefined
          : params.value.toDate().toLocaleDateString('en-sg', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
    },
    { field: 'name', headerName: 'Title', width: 150, editable: false },
    { field: 'amount', headerName: 'Amount', type: 'number', width: 80, editable: false },
    { field: 'category', headerName: 'Category', width: 150, type: 'string', editable: false },
    {
      field: 'expiry', headerName: 'Expiry', width: 120, type: 'date', editable: false,
      valueFormatter: (params) =>
        params.value.toDate().toLocaleDateString('en-sg', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        })
    },
    { field: 'description', headerName: 'Description', width: 250, type: 'string', editable: false },
    { field: 'claimRef', headerName: 'Claim Reference', width: 250, type: 'string', editable: false },
    { field: 'imageUrl', headerName: 'Image URL', type: 'string', width: 200, editable: false }
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let isMounted = true;

    const q = query(collectionGroup(fsCtx.fsObject, 'vouchers'));
    getDocs(q)
      .then((querySnapshot) => {
        if (isMounted)
          setVouchersList(querySnapshot.docs.map((el) => ({ ...el.data(), id: el.id })));
        if (isMounted) setOpenBackdrop(false);
      })
      .catch((error) => {
        const errorStr = `Failed to fetch vouchers: ${error}\n${error.stack}`;
        console.error(errorStr);
        alert(errorStr);
      });

    return () => {
      isMounted = false;
    };
  }, [fsCtx.fsObject]);

  return (
    <Container maxWidth="xl">
      <PopupModal
        openState={open}
        closeHandler={handleClose}
        modalBody={modalInfo}
        closeConfirmation
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          App | Vouchers
        </Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => handleOpen(<NewVoucherCategory />)}
        >
          New Voucher Category
        </Button>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            // variant="fullWidth"
          >
            <Tab label="Voucher Categories" {...a11yProps(0)} />
            <Tab label="Vouchers List" {...a11yProps(1)} />
            <Tab label="Settings" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <FcAppVoucherCategories />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <DataTable
            dataRows={vouchersList}
            dataColumns={columns}
            tableInitialSort={tableInitialSort}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </Container>
  );
}
