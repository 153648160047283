const XLSX = require("xlsx")
const dayjs = require("dayjs")

class WorkshopJob {
  constructor(name = "", day = "", time = "", vehicleNumber = "", task = "", remark = "", cost = "") {
    this.Name = name;
    this.Date = day;
    this.Time = time;
    this.VehicleNumber = vehicleNumber;
    this.Task = task;
    this.Remark = remark;
    this.Cost = cost;
  }
}

// I know this is very inefficient
// But I do not have any other way to do this without massively increasing the complexity
export default function workshopJobJsonToExcel(originalData, isAdmin = false) {
  const parsedData = [];

  const data = originalData.sort((a, b) => {
    const nameCompare = a.name.localeCompare(b.name);
    if (nameCompare !== 0) {
      return nameCompare;
    }

    const dayCompare = a.date - b.date;
    if (dayCompare !== 0) {
      return dayCompare;
    }

    const timeCompare = a.time - b.time;
    if (timeCompare !== 0) {
      return timeCompare;
    }

    return 0;
  })

  data.forEach((job) => {
    job.task.forEach((task) => {
      parsedData[parsedData.length] = new WorkshopJob(
        job.name,
        dayjs(job.createDay).format("DD MMM YYYY"),
        dayjs(job.createdAt).format("HH:mm:ss"),
        job.vehicleNumber,
        task.name,
        job.remark ?? "",
        task.cost
      )
    })

    if (job.other !== "") {
      parsedData[parsedData.length] = new WorkshopJob(
        job.name,
        "",
        "",
        job.vehicleNumber,
        job.other
      )
    }
  })

  for (let i = parsedData.length - 1; i > 0; i--) {
    if (parsedData[i].Name === parsedData[i - 1].Name) {
      parsedData[i].Name = "";
    }

    if (parsedData[i].Date === parsedData[i - 1].Date && parsedData[i].Name === "") {
      parsedData[i].Date = "";
    }

    if (parsedData[i].Time === parsedData[i - 1].Time) {
      parsedData[i].Time = "";
    }

    if (parsedData[i].VehicleNumber === parsedData[i - 1].VehicleNumber) {
      parsedData[i].VehicleNumber = "";
    }

    if (parsedData[i].Remark === parsedData[i - 1].Remark) {
      parsedData[i].Remark = "";
    }
  }

  if (!isAdmin) {
    for (let i = 0; i < parsedData.length; i++) {
      delete parsedData[i].Cost;
    }
  }

  const worksheet = XLSX.utils.json_to_sheet(parsedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Job");

  XLSX.writeFile(workbook, `Job ${dayjs().format("DD MMM YYYY HH-mm-ss")}.xlsx`, { compression: true });
}