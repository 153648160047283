import {Grid, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment/moment";
import * as Yup from "yup";
import {FcDateField, FcTextArea, FcTextField, FcTimeField, SubmitBtnGroup} from "../../../components/Form/FormFields";
import useHttpPost from "../../../hooks/http/useHttpPost";

export default function RentalAgreementPreview() {
  const { onPost } = useHttpPost("/api/file/rental-agreement/preview");

  const onSubmit = async values => {
    const res = await onPost(values);
    window.open(URL.createObjectURL(res), "_blank");
  }

  const formik = useFormik({
    initialValues: {
      bookingNo: "Bk202401020001",
      rentalAgreementNo: "RA202401020001",
      username: "Alvin",
      vehicleCompany: "Fresh Cars Pte Ltd",
      vehicleCompanyUEN: "201608540Z",
      vehicleCompanyBank: "DBS Bank",
      vehicleCompanyBankAccount: "017-905073-1",
      customerType: "Individual",
      customerName: "Tan Aik Chong",
      customerEmail: "test@freshcars.sg",
      customerPhone: "88888888",
      customerAddress: "#08-08 Shunli industrial park",
      customerPostal: "888888",
      customerIdentity: "S8888888",
      customerBirthdate: moment(),
      customerDrivingLicenseEffectiveDate: moment(),
      rentalUsage: "Private Hire",
      startDate: moment(),
      startTime: moment().format("HH:mm"),
      startDateTime: moment(),
      endDateTime: moment(),
      vehicleMake: "Honda",
      vehicleModel: "Fit",
      vehicleColor: "Red",
      deposit: 200,
      rentalPrice: 350,
      totalUpfront: 1400,
      pricePeriodName: "Monthly",
      remarks: "",
    },
    validationSchema: Yup.object({
      bookingNo: Yup.string().required(),
      rentalAgreementNo: Yup.string().required(),
      username: Yup.string().required(),
      vehicleCompany: Yup.string().required(),
      vehicleCompanyUEN: Yup.string().required(),
      vehicleCompanyBank: Yup.string().required(),
      vehicleCompanyBankAccount: Yup.string().required(),
      customerType: Yup.string().required(),
      customerName: Yup.string().required(),
      customerEmail: Yup.string().required(),
      customerPhone: Yup.string().required(),
      customerAddress: Yup.string().required(),
      customerPostal: Yup.string().required(),
      customerIdentity: Yup.string().required(),
      customerBirthdate: Yup.date().required(),
      customerDrivingLicenseEffectiveDate: Yup.date().required(),
      rentalUsage: Yup.string().required(),
      startDate: Yup.date().required(),
      startTime: Yup.string().required(),
      endDateTime: Yup.date().required(),
      vehicleMake: Yup.string().required(),
      vehicleModel: Yup.string().required(),
      vehicleColor: Yup.string().required(),
      deposit: Yup.number().required(),
      rentalPrice: Yup.number().required(),
      totalUpfront: Yup.number().required(),
      pricePeriodName: Yup.string().required(),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit({
        ...values,
        startDateTime: moment(`${values.startDate.format("YYYY-MM-DD")}T${values.startTime}:00+08:00`)
      });
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Rental Agreement Form</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="bookingNo" label="Booking No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="rentalAgreementNo" label="Rental Agreement No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="username" label="Staff"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleCompany" label="Vehicle Company"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleCompanyUEN" label="Vehicle Company UEN"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleCompanyBank" label="Vehicle Company Bank"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleCompanyBankAccount" label="Vehicle Company Bank Account No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="pricePeriodName" label="Price Period Name"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerType" label="Customer Type"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerName" label="Customer Name"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerEmail" label="Customer Email"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerPhone" label="Customer Phone"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerPostal" label="Customer Postal"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerIdentity" label="Customer NRIC/FIN/ACRA"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="customerAddress" label="Customer Address"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="customerBirthdate" label="Customer Birth date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="customerDrivingLicenseEffectiveDate" label="Customer Driving License Effective Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="rentalUsage" label="Rental Usage"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDate" label="Start Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTimeField formik={formik} name="startTime" label="Start Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDateTime" label="Return Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleMake" label="Vehicle Make"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleModel" label="Vehicle Model"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleColor" label="Vehicle Color"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="deposit" label="Deposit" type="number"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="rentalPrice" label="Rental Price" type="number"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="totalUpfront" label="Total Upfront" type="number"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Create" onCancel={console.log}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}