import {
  Box,
  Button,
  Container, Dialog, DialogContent, DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import CustomerForm from "../../../components/Form/Customer/CustomerForm";
import CustomerTable from "../../../components/Table/Customer/CustomerTable";
import useHttpGet from "../../../hooks/http/useHttpGet";
import AuthContext from "../../../store/AuthContext";
import {CUSTOMER_PERMISSIONS} from "../../../utils/authorization/permissions/customerPermissions";
import {permissionRequired} from "../../../utils/authorization/roles";

const CreateAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Create Customer</DialogTitle>
        <DialogContent>
          <Box py={1}>
            <CustomerForm onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Create Customer
      </Button>
    </>
  );
}

CreateAction.propTypes = {
  onReload: PropTypes.func,
}

const FilterGroup = ({ onReload }) => {
  const authCtx = useContext(AuthContext);

  const [filter, setFilter] = useState({
    name: "",
    customerType: "",
  });

  const onClear = e => {
    e.preventDefault();
    setFilter({ name: "", customerType: "" });
  }

  const onSearch = async e => {
    e.preventDefault();
    await onReload(filter);
  }

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextField
          fullWidth
          value={filter.name} label="Name"
          onChange={e => setFilter({ ...filter, name: e.target.value})}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormControl fullWidth>
          <InputLabel>Customer Type</InputLabel>
          <Select
            label="Customer Type" value={filter.customerType}
            onChange={e => setFilter({ ...filter, customerType: e.target.value })}
          >
            <MenuItem value="">Select Customer Type</MenuItem>
            <MenuItem value="Individual">Individual</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="outlined" onClick={onClear}>Clear</Button>
          <Button variant="contained" onClick={onSearch}>Search</Button>
          {permissionRequired(authCtx, CUSTOMER_PERMISSIONS.CREATE) && <CreateAction onReload={onReload}/>}
        </Stack>
      </Grid>
    </Grid>
  );
}

FilterGroup.propTypes = {
  onReload: PropTypes.func,
}

export default function CustomerList() {
  const { data, onGet } = useHttpGet("/api/customer");

  return (
    <Container maxWidth="xl">
      <Typography variant="h4">Customer Listing</Typography>
      <Box mt={3}>
        <FilterGroup onReload={onGet}/>
        <CustomerTable data={data} onReload={onGet}/>
      </Box>
    </Container>
  );
}