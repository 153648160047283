import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel, MenuItem, Select,
  Stack,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import RecurringInvoiceForm from "../../../components/Form/Invoice/RecurringInvoiceForm";
import RecurringInvoiceTable from "../../../components/Table/Invoice/RecurringInvoiceTable";
import useHttpGet from "../../../hooks/http/useHttpGet";

const NewAction = ({ booking, onReload }) => {
  const [open, setOpen] = useState(false);
  const [agreement, setAgreement] = useState(null);
  const [type, setType] = useState("normal");

  const { data: agreements } = useHttpGet(`/api/booking/${booking?.bookingNo}/agreement`);
  const invoiceTypes = [
    { label: "Normal", value: "normal" },
    { label: "CDW", value: "cdw" },
  ];

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>Create Recurring Invoice</DialogTitle>
        <DialogContent>
          <Grid container pt={1} spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Agreements</InputLabel>
                <Select
                  label="Agreements"
                  value={agreement}
                  onChange={e => setAgreement(e.target.value)}
                >
                  {agreements?.map((agreement, index) => (
                    <MenuItem value={JSON.stringify(agreement)} key={index}>
                      {agreement.agreementNo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Invoice Type</InputLabel>
                <Select
                  label="Type"
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  {invoiceTypes.map(({ label, value }, index) => (
                    <MenuItem value={value} key={index}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {agreement && <Grid item xs={12}>
              <RecurringInvoiceForm type={type} agreement={JSON.parse(agreement)} onReload={onReload} onClose={() => setOpen(false)}/>
            </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>New</Button>
    </>
  );
}

NewAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

export default function BookingScheduleTab({ booking, recurringInvoices, onReload }) {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">{booking?.bookingNo} Recurring Invoices</Typography>
        <NewAction booking={booking} onReload={onReload}/>
      </Stack>
      <RecurringInvoiceTable data={recurringInvoices} onReload={onReload}/>
    </>
  );
}

BookingScheduleTab.propTypes = {
  booking: PropTypes.object,
  recurringInvoices: PropTypes.array,
  onReload: PropTypes.func,
}