import React, {useState} from "react";
import {
  Alert,
  Box,
  Container,
  Snackbar,
  Typography,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import useHttpGet from "../../hooks/http/useHttpGet";
import VehicleForm from "../../components/Form/Vehicle/VehicleForm";

export default function EditVehicle() {
  const { id } = useParams();
  const { data } = useHttpGet(`/api/vehicles/${id}`);
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });

  const onOpenSnackbar = (message, type = "success") => {
    setOpenSnackbar(true);
    setSnackbarInfo({ message, type });
  }

  return (
    <Container maxWidth="xl">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '100%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      <Typography
        sx={{ cursor: "pointer", marginBottom: 3 }} variant="h4" gutterBottom
        onClick={() => navigate("../")}
      >
        <span style={{position: "relative", top: 3}}>
          <ArrowBackSharpIcon />
        </span>
        &nbsp;&nbsp;
        Back
      </Typography>
      <Box sx={{ marginTop: 3 }}>
        <VehicleForm
          data={data}
          onOpenSnackbar={onOpenSnackbar}
          onCancel={() => navigate("../")}
        />
      </Box>
    </Container>
  );
}