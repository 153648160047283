import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { FcTextArea, FcTextField, FcToggleButton } from '../FormFields';
import FcWebsiteFileDropzone from './FcWebsiteFileDropzone';

const FcWebsitePromotionForm = ({ formik }) => {
  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}><FcTextField formik={formik} name="title" label="Title" /></Grid>
      <Grid item xs={12}><FcTextArea formik={formik} name="description" label="Description" rows={5} /></Grid>
      <Grid item xs={12}><FcToggleButton formik={formik} name="isActive" label="Visible on Fresh Cars Website" /></Grid>
      <Grid item xs={6}><FcWebsiteFileDropzone formik={formik} name="imgUrl" label="Image" /></Grid>
      <Grid item xs={6}><FcWebsiteFileDropzone formik={formik} name="mobileImgUrl"
                                               label="Image (For Mobile)" /></Grid>
      {formik.values.imgUrl && (<Grid item xs={6}><Box component="img" src={formik.values.imgUrl}
                                                       sx={{ height: 200, borderRadius: 1, overflow: 'clip' }} /></Grid>)}

      {formik.values.mobileImgUrl && (<Grid item xs={6}><Box component="img" src={formik.values.mobileImgUrl}
                                                             sx={{ height: 200, borderRadius: 1, overflow: 'clip' }} /></Grid>)}
    </Grid>
  );
};

FcWebsitePromotionForm.propTypes = { formik: PropTypes.object };


export default FcWebsitePromotionForm;