import {Backdrop, CircularProgress} from "@mui/material";
import PropTypes from "prop-types";

export default function FcBackdrop({ open }) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit"/>
    </Backdrop>
  );
}

FcBackdrop.propTypes = {
  open: PropTypes.bool
}