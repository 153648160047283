import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useState} from "react";
import * as Yup from "yup";
import {getDayjsValue} from "../../../utils/date";
import {FcTextArea, FcTextField, SubmitBtnGroup} from "../FormFields";

const templates = [
  { value: "rental", label: "Rental" },
  { value: "accident", label: "Accident" },
  { value: "cdw", label: "CDW" },
  { value: "deposit", label: "Deposit" },
  { value: "other", label: "Other" },
];

const recurringTemplates = [
  { value: "rental", label: "Rental" },
  { value: "cdw", label: "CDW" },
  { value: "other", label: "Other" },
];

const paymentPeriodTypes = {
  0: "",
  3: "Weekend",
  7: "Weekly",
  30: "Monthly",
};

const dateFormat = (date) =>  getDayjsValue(date).format("DD MMM YYYY");
const descriptionTemplates = {
  rental: (agreement, type) => `${agreement?.vehicleNumber ?? "{vehicleNumber}"} ${agreement?.vehicleMakeName ?? "{make}"} ${agreement?.vehicleModelName ?? "{model}"} ${agreement?.vehiclePetrol ?? "{petrol}"} ${agreement?.vehicle?.manufacturingYear ? getDayjsValue(agreement?.vehicle?.manufacturingYear).format("YYYY") : "{manufacturingYear}"}\n` +
    `Rental Period ${type === "recurring" ? "{startDate}" : dateFormat(agreement?.startDateTime)} - ${type === "recurring" ? "{endDate}" : dateFormat(agreement?.endDateTime)}\n` +
    `Rental Rate ($${agreement?.rentalPrice ?? "{rental price}"} ${agreement?.paymentPeriod ? paymentPeriodTypes[agreement.paymentPeriod] : "{payment period type}"})`,
  accident: (agreement) => `${agreement?.vehicleNumber ?? "{vehicle number}"} Accident excess - {reason} - {result}`,
  cdw: (agreement, type) => `${agreement?.vehicleNumber ?? "{vehicleNumber}"} ${agreement?.vehicleMakeName ?? "{make}"} ${agreement?.vehicleModelName ?? "{model}"} ${agreement?.vehiclePetrol ?? "{petrol}"} ${agreement?.vehicle?.manufacturingYear ? getDayjsValue(agreement?.vehicle?.manufacturingYear).format("YYYY") : "{manufacturingYear}"}\n` +
    `Rental Period ${type === "recurring" ? "{startDate}" : dateFormat(agreement?.startDateTime)} - ${type === "recurring" ? "{endDate}" : dateFormat(agreement?.endDateTime)}\n` +
    `CDW ($${agreement?.cdwPrice ?? "{CDW Price}"} Monthly)`,
  deposit: () => `Deposit`,
  other: () => "",
};

const unitPrices = {
  rental: (agreement) => agreement?.rentalPrice ?? 0,
  accident: () => 2180,
  cdw: (agreement) => agreement?.cdwPrice ?? 0,
  deposit: (agreement) => agreement?.deposit ?? 0,
  other: () => 0,
};

export default function InvoiceItemForm({ type = "normal", agreement, onClose, onAdd }) {
  const [template, setTemplate] = useState("");

  const formik = useFormik({
    initialValues: {
      type: "",
      description: "",
      quantity: 1,
      unitPrice: 0,
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Invoice item type is required!"),
      description: Yup.string().required("Description is required!"),
      quantity: Yup.number().required("Quantity is required!"),
      unitPrice: Yup.number().required("Unit price is required!"),
    }),
    onSubmit: values => {
      onAdd(values);
      onClose();
    }
  });

  const handleChange = e => {
    e.preventDefault();
    const invoiceItemType = e.target.value;
    formik.setFieldValue("type", invoiceItemType);
    formik.setFieldValue("description", descriptionTemplates[invoiceItemType](agreement, type));
    formik.setFieldValue("quantity", 1);
    formik.setFieldValue("unitPrice", unitPrices[invoiceItemType](agreement));
    setTemplate(invoiceItemType);
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Invoice Item Type</InputLabel>
              <Select value={template} onChange={handleChange}>
                {[...type === "normal" ? templates : recurringTemplates].map(({label, value}, index) => (
                  <MenuItem key={index} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FcTextField formik={formik} name="quantity" label="Quantity"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FcTextField formik={formik} name="unitPrice" label="Unit Price"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="description" label="Description"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} onCancel={onClose} method="Add"/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

InvoiceItemForm.propTypes = {
  type: PropTypes.string,
  agreement: PropTypes.object,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
}