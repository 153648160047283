import { LoadingButton } from "@material-ui/lab";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpPut from "../../../hooks/http/useHttpPut";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import { getOutstandingAmount } from "../../../utils/bookingAccounts";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { getDayjsValue, paymentPeriodTypes } from "../../../utils/date";
import CommissionPointDetails from "../../Details/OTR/CommissionPointDetails";
import FcTable from "../FcTable";

const ViewAction = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row.agreementNo} OTR Details</DialogTitle>
        <DialogContent>
          <CommissionPointDetails data={row} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        View
      </Button>
    </Stack>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/booking/commission/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "OTR deleted successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Delete this OTR?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const AgreeAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading } = useHttpPut(`/api/booking/commission/${row?.id}`);

  const onAgree = async e => {
    e.preventDefault();
    try {
      await onPut({ status: "agreed" });
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Commission agreed!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Agree this OTR?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" onClick={onAgree}>
            Agree
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Agree
      </Button>
    </>
  );
}

AgreeAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DisagreeAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading } = useHttpPut(`/api/booking/commission/${row?.id}`);

  const onDisagree = async e => {
    e.preventDefault();
    try {
      await onPut({ status: "disagreed" });
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Commission disagreed!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Disagree this OTR?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onDisagree}>
            Disagree
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Disagree
      </Button>
    </>
  );
}

DisagreeAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      {authCtx.userDept !== "sales" && <AgreeAction row={row} onReload={onReload}/>}
      {authCtx.userDept !== "sales" && <DisagreeAction row={row} onReload={onReload}/>}
      <ViewAction row={row} />
      <DeleteAction row={row} onReload={onReload} />
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function CommissionPointTable({ data, onReload }) {
  const getPeriodType = (minRentalPeriod) => minRentalPeriod >= 28 ? "Long Term" : "Short Term";

  const columns = [
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center", minWidth: 150, sticky: true,
      renderCell: ({ row }) => getDayjsValue(row?.booking?.startDate).format("DD MMM YYYY"),
    },
    {
      field: "customerName", headerName: "Customer", align: "center", headerAlign: "center", minWidth: 250, sticky: true,
      renderCell: ({ row }) => row?.booking?.company?.name ?? (row?.booking?.customer?.name ?? ""),
    },
    {
      field: "vehicleNumber", headerName: "Vehicle No.", align: "center", headerAlign: "center", minWidth: 150, sticky: true,
      renderCell: ({ row }) => row?.firstRentalAgreement?.vehicleNumber ?? "",
    },
    {
      field: "agreementNo", headerName: "Agreement No.", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.firstRentalAgreement?.agreementNo ?? "",
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => getDayjsValue(row?.booking?.endDate).format("DD MMM YYYY"),
    },
    {
      field: "bookingNo", headerName: "Booking No.", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.booking?.bookingNo,
    },
    { field: "username", headerName: "Sales", align: "center", headerAlign: "center", minWidth: 150 },
    {
      field: "isNewCustomer", headerName: "New / Existing", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.isNewCustomer ? "New" : "Existing",
    },
    {
      field: "rentalUsage", headerName: "Type", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.booking?.rentalUsage,
    },
    {
      field: "deposit", headerName: "Deposit", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => `S$${row?.booking?.deposit ?? 0}`,
    },
    {
      field: "rentalPrice", headerName: "Rental Price", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => `S$${row?.booking?.rentalPrice ?? 0}`,
    },
    {
      field: "pricePeriod.minRentalPeriod", headerName: "Period Type", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => getPeriodType(row?.booking?.pricePeriod?.minRentalPeriod),
    },
    {
      field: "pricePeriod.paymentPeriod", headerName: "Billing Type", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => paymentPeriodTypes[row?.booking?.pricePeriod?.paymentPeriod],
    },
    {
      field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => getOutstandingAmount(row?.booking),
    },
    {
      field: "totalUpfront", headerName: "Total Upfront", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => row?.booking?.totalUpfront ?? 0,
    },
    { field: "commissionPoint", headerName: "OTR", align: "center", headerAlign: "center", minWidth: 150 },
    {
      field: "status", headerName: "OTR Status", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => capitalizeFirstLetter(row?.status),
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload} />,
    },
  ];

  return (
    <FcTable columns={columns} rows={data ?? []}/>
  );
}

CommissionPointTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}