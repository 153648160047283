import {Button, CircularProgress, Container, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {GridToolbar} from "@mui/x-data-grid";
import {StyledDataGrid} from "./styles";
import {fetchEnhcApi} from "../../utils/fetchApi";

const FiltersGroup = ({filters, filter, onFilterChange}) => {
  const allFilters = [
    { name: 'All', value: 'all' },
    ...filters,
  ];

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" py={1}>
      Quick Filters:&nbsp;&nbsp;
      <ToggleButtonGroup
        exclusive
        value={filter}
        onChange={onFilterChange}
        aria-label="vehicle filter"
      >
        {allFilters.map((filter, index) => (
          <ToggleButton
            sx={{
              "&.Mui-selected, &.Mui-selected:hover": {
                color: "white !important",
                backgroundColor: "#00AB55 !important"
              }
            }}
            key={index} value={filter.value}>
            {filter.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}

FiltersGroup.propTypes = {
  filters: PropTypes.array,
  filter: PropTypes.string,
  onFilterChange: PropTypes.func
}

export default function VehicleListing() {
  const pageOptions = [25, 50, 100];

  const [pageSize, setPageSize] = useState(25);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const onDispose = (row) => navigate(`../disposal/${row.VehicleNo}`);
  const getDisposalStatus = ({row}) => {
    if (row.VehicleStatus === 'DISPOSAL') return "Disposal";
    if (moment(row.CarEndDate) < moment()) return "COE ended";
    if (row.TaskName) return 'Lay-up';
    return "COE not ended";
  }

  const filters = [
    { name: 'Lay-up', value: "layup" },
    { name: 'COE ended', value: "coeEnded" },
    { name: 'COE will end within 1 month', value: "oneMonth" },
    { name: 'COE will end within 3 months', value: "threeMonth" },
  ];

  const onLoadData = async (filter = 'all') => {
    const data = await fetchEnhcApi("GetAvailableVehicleListing", { filter });
    return data.map(row => (
      {...row, ActualEndDate: moment(row.CarEndDate).add(30, "days").format("YYYY-MM-DD")}
    ));
  }

  const onFilterChange = (e, newValue) => {
    setLoading(true);
    onLoadData(newValue)
      .then(res => {
        setData(res);
        setFilter(newValue);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }

  const columns = [
    {
      field: 'id', headerName: 'No.',
      align: "center", headerAlign: "center",
      width: 50, sortable: false,
      valueGetter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    { field: 'VehicleNo', headerName: 'Car Plate', align: "center", headerAlign: "center", width: 100 },
    { field: 'CompanyName', headerName: 'Company Name', align: "center", headerAlign: "center", width: 150 },
    {
      field: 'Make', headerName: 'Make / Model',
      align: "center", headerAlign: "center",
      width: 150,
      valueGetter: ({row}) => `${row.VehicleMake} ${row.VehicleModel}`
    },
    { field: 'PurchaseType', headerName: 'COE / PARF', align: "center", headerAlign: "center", width: 100 },
    {
      field: 'CarEndDate', headerName: 'COE Expiry',
      align: "center", headerAlign: "center",
      width: 150, type: 'date',
      renderCell: ({row}) => moment(row.CarEndDate).format("DD-MM-YYYY")
    },
    {
      field: 'ActualEndDate', headerName: 'DISPOSAL DUE DATE',
      align: "center", headerAlign: "center",
      width: 200, type: 'date',
      renderCell: ({row}) => moment(row.ActualEndDate).format("DD-MM-YYYY")
    },
    {
      field: 'Status', headerName: 'Disposal Status',
      align: "center", headerAlign: "center",
      width: 125,
      renderCell: getDisposalStatus
    },
    {
      field: '', headerName: 'Action',
      align: "center", headerAlign: "center",
      width: 125, sortable: false,
      renderCell: ({row}) => (
        <Button variant="contained" onClick={() => onDispose(row)}>
          Disposal
        </Button>
      )
    },
  ];

  useEffect(() => {
    setLoading(true);
    onLoadData()
      .then(res => {
        setData(res);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const themeSelect = (row) => {
    const currentDate = moment();
    const endDate = moment(row.ActualEndDate);
    const diff = endDate.diff(currentDate, "days");
    if (diff <= 3) return 'Ended';
    if (diff <= 14) return 'Handover';
    return 'primary';
  }

  console.log("loading:", loading);
  return (
    <Container maxWidth="xl">
      <FiltersGroup filters={filters} filter={filter} onFilterChange={onFilterChange}/>
      <Typography variant="h4" gutterBottom sx={{ marginY: 3 }}>
        Available Vehicle Listing
      </Typography>
      <StyledDataGrid
        components={{ Toolbar: GridToolbar }}
        getRowClassName={({row}) => `vehicle-theme--${themeSelect(row)}`}
        rows={data} columns={columns}
        pageSize={pageSize}
        loading={loading}
        rowsPerPageOptions={pageOptions}
        onPageSizeChange={size => setPageSize(size)}
        disableSelectionOnClick
        sx={{height: window.innerHeight * 0.7}}
      />
    </Container>
  );
}