import {LoadingButton} from "@material-ui/lab";
import {Button, Grid, Stack} from "@mui/material";
import dayjs from "dayjs";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect} from "react";
import * as Yup from "yup";
import {FcDateField, FcSelectField, FcTextField} from "../../FormFields";

export default function AgreementFilterForm({ onReload, paginationModel, sortModel }) {
  const statusOptions = ["Active", "Voided"].map(status => ({ label: status, value: status }));
  const typeOptions = ["Rental", "Return"].map(type => ({ label: type, value: type }));

  const formik = useFormik({
    initialValues: {
      agreementNo: "",
      bookingNo: "",
      username: "",
      customerName: "",
      status: "",
      agreementType: "",
      startCreatedAt: null,
      endCreatedAt: null,
      startDateFrom: null,
      startDateTo: null,
      endDateFrom: null,
      endDateTo: null,
    },
    validationSchema: Yup.object({
      agreementNo: Yup.string().nullable(),
      bookingNo: Yup.string().nullable(),
      username: Yup.string().nullable(),
      customerName: Yup.string().nullable(),
      status: Yup.string().nullable(),
      agreementType: Yup.string().nullable(),
      startCreatedAt: Yup.date().nullable(),
      endCreatedAt: Yup.date().nullable(),
      startDateFrom: Yup.date().nullable(),
      startDateTo: Yup.date().nullable(),
      endDateFrom: Yup.date().nullable(),
      endDateTo: Yup.date().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onReload({
        ...values, ...paginationModel,
        startCreatedAt: values?.startCreatedAt?.toISOString() ?? "",
        endCreatedAt: values?.endCreatedAt?.toISOString() ?? "",
        startDateFrom: values?.startDateFrom?.toISOString() ?? "",
        startDateTo: values?.startDateTo?.toISOString() ?? "",
        endDateFrom: values?.endDateFrom?.toISOString() ?? "",
        endDateTo: values?.endDateTo?.toISOString() ?? "",
      });
      setSubmitting(false);
    }
  });

  useEffect(() => {
    const { values } = formik;
    let model = {};
    if (sortModel.length > 0) [model] = sortModel;
    onReload({
      ...values, ...paginationModel, ...model,
      startCreatedAt: values?.startCreatedAt?.toISOString() ?? "",
      endCreatedAt: values?.endCreatedAt?.toISOString() ?? "",
      startDateFrom: values?.startDateFrom?.toISOString() ?? "",
      startDateTo: values?.startDateTo?.toISOString() ?? "",
      endDateFrom: values?.endDateFrom?.toISOString() ?? "",
      endDateTo: values?.endDateTo?.toISOString() ?? "",
    });
  }, [paginationModel, sortModel]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startCreatedAt" label="Created At From"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endCreatedAt" label="Created At To"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDateFrom" label="Start Date From"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDateTo" label="Start Date To"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDateFrom" label="End Date From"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDateTo" label="End Date To"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="bookingNo" label="Booking No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="agreementNo" label="Agreement No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="username" label="Sales Name"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerName" label="Customer Name"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="status" label="Agreement Status" items={statusOptions}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="agreementType" label="Agreement Type" items={typeOptions}/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={formik.resetForm}>
                Clear
              </Button>
              <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                Search
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

AgreementFilterForm.propTypes = {
  onReload: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.object,
}