import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  Snackbar,
  Stack,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';
import FullScreenDialog from '../components/FullScreenDialog';
import { a11yProps, TabPanel } from '../components/TabPanel';

// ----------------------------------------------------------------------

const AdminLayout = (props) => {
  AdminLayout.propTypes = {
    code: PropTypes.node,
    data: PropTypes.array,
    dataGridCheckbox: PropTypes.bool,
    filters: PropTypes.array,
    setSelectedRows: PropTypes.func,
    selectedRows:PropTypes.array,
    tableColumns: PropTypes.array,
    tabNames: PropTypes.array,
    title: PropTypes.string,
    tooltipNotes: PropTypes.array,
    topRightOptions: PropTypes.node
  };

  const [dataFilter, setDataFilter] = useState({ items: [] });
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [dataGridPageSize, setDataGridPageSize] = useState(50);
  const [alignment, setAlignment] = useState('web');
  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleToggleButtonsChange = (event, newAlignment) => {
    const data = JSON.parse(event.target.value);
    if (event.target.value === 'All') {
      setDataFilter({ items: [] });
    } else {
      setDataFilter({
        items: [{
          columnField: data.column,
          operatorValue: data.operatorValue,
          value: data.value
        }]
      });
    }
    setAlignment(newAlignment);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  //   }, [fetchHirerListing]);

  // Tabs Logic

  const [activeTabValue, setActiveTabValue] = useState(0);

  const tabHandleChange = (event, newValue) => {
    setActiveTabValue(newValue);
  };

  // ----------------------------------------------------------------------

  // Transactions Notes

  const TooltipNotes = () => (
    <div style={{ fontSize: 14 }}>
      Notes
      <br />
      <br />
      {props.tooltipNotes.map((element) => (
        <div key={element}>
          {element}
          <br />
        </div>
      ))}
    </div>
  );

  // ----------------------------------------------------------------------

  // + New menu button logic

  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    if (window.confirm('Close without saving?')) {
      setDialogOpen(false);
    }
  };

  // ----------------------------------------------------------------------

  // New Document Full Screen Dialog Content

  const currentDateGetter = (offset) => {
    const x2 = new Date();
    const dayNo2 = x2.getDate();
    const x = new Date(x2.setDate(dayNo2 + Math.round(offset)));
    const dayNo = x.getDate();
    const day = dayNo < 10 ? `0${dayNo}` : dayNo;
    const monthNo = x.getMonth() + 1;
    const month = monthNo < 10 ? `0${monthNo}` : monthNo;
    const date = `${x.getFullYear()}-${month}-${day}`;
    return date;
  };

  const DialogContent = () => (
    <>
      <Typography variant="h4" gutterBottom>
        dialogContentTitle
      </Typography>
    </>
  );

  // ----------------------------------------------------------------------

  // No data overlay

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
    }
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No data to display</Box>
      </StyledGridOverlay>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ height: 10 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '100%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      <FullScreenDialog
        dialogOpen={dialogOpen}
        handleDialogClose={closeDialog}
        data={{}}
        code={DialogContent}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
        <Typography variant="h4" gutterBottom>
          {props.title}
        </Typography>
        {props.topRightOptions}
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs value={activeTabValue} onChange={tabHandleChange} aria-label="basic tabs example">
            <Tab
              icon={
                <Tooltip title={<TooltipNotes />}>
                  <QuestionMarkIcon />
                </Tooltip>
              }
              iconPosition="end"
              label={props.tabNames === undefined ? '' : props.tabNames[0]}
              {...a11yProps(0)}
            />
            <Tab label={props.tabNames === undefined ? '' : props.tabNames[1]} {...a11yProps(1)} />
            <Tab label={props.tabNames === undefined ? '' : props.tabNames[2]} {...a11yProps(2)} />
          </Tabs>
        </Stack>

        {activeTabValue === 0 && props.filters && (
          <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ py: 1 }}>
            Quick Filters:&nbsp;&nbsp;
            <ToggleButtonGroup
              sx={{ height: 35 }}
              color="primary"
              value={alignment}
              exclusive
              onChange={handleToggleButtonsChange}
              aria-label="Platform"
            >
              {[{ name: 'All', value: { column: '', operatorValue: '', value: 'All' } }]
                .concat(props.filters)
                .map((el) => (
                  <ToggleButton key={el.value.value} value={JSON.stringify(el.value)}>
                    {el.name}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </Stack>
        )}
        {props.code}
        <TabPanel value={activeTabValue} index={0}>
          <div style={{ display: 'flex', height: windowDimensions.height * 0.67 }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                checkboxSelection={props.dataGridCheckbox}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: GridToolbar
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 }
                  }
                }}
                sx={{ m: 0, p: 0 }}
                onSelectionModelChange={(newSelectionModel) =>props.setSelectedRows(newSelectionModel)}
                selectionModel={props.selectedRows}
                rows={props.data}
                columns={props.tableColumns}
                pageSize={dataGridPageSize}
                onPageSizeChange={(newPageSize) => setDataGridPageSize(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      carReturnHandover: false,
                      carRentalDocForReturn: false
                    }
                  },
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }]
                  }
                }}
                filterModel={dataFilter}
                onFilterModelChange={(newFilterModel) => setDataFilter(newFilterModel)}
                disableSelectionOnClick
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={activeTabValue} index={1}>
          second tab panel
        </TabPanel>
        <TabPanel value={activeTabValue} index={2}>
          Under construction
        </TabPanel>
      </Box>
    </Container>
  );
};

export default AdminLayout;
