import { useState } from "react";
import { getAuth } from "firebase/auth";
import { apiFuncFetch } from "../../../services/api";

/*
 * data: object
 * loading: boolean
 * error: string
 * onDelete: function
 */
export default function useCloudFuncDelete(path, searchParams = {}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onDelete = async (searchParams = {}) => {
    setLoading(true);
    try {
      const token = await getAuth().currentUser.getIdToken();
      const res = await apiFuncFetch(path, {
        method: "DELETE",
        headers: {
          token,
          Accept: "application/json",
        }
      }, searchParams);
      setData(res);
    } catch (err) {
      setError(err.message);
      throw err;
    }
    setLoading(false);
  }

  return { data, deleteLoading: loading, error, onDelete };
}