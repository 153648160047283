import {Button, Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import CdwPriceForm from "../../../components/Form/Prices/CdwPriceForm";
import CdwPriceTable from "../../../components/Table/Prices/CdwPriceTable";
import useHttpGet from "../../../hooks/http/useHttpGet";

const CreateAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Create CDW Price</DialogTitle>
        <DialogContent>
          <CdwPriceForm onReload={onReload} onClose={() => setOpen(false)}/>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Create
      </Button>
    </>
  );
}

CreateAction.propTypes = {
  onReload: PropTypes.func,
}

export default function CdwPriceList() {
  const { data, onGet } = useHttpGet("/api/booking/price/cdw");

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">CDW Prices Listing</Typography>
        <CreateAction onReload={onGet}/>
      </Stack>
      <CdwPriceTable data={data} onReload={onGet}/>
    </>
  );
}