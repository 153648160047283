import React from "react";
import {useNavigate} from "react-router-dom";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import {Box, Container, Stack, Tooltip, Typography} from "@mui/material";
import PriceTableForm from "../../../components/Form/Vehicle/PriceTableForm";
import useHttpGet from "../../../hooks/http/useHttpGet";
import palette from "../../../theme/palette";

const getWidth = col => {
  if (col.length < 10) return 100;
  if (col.length < 20) return 150;
  if (col.length < 30) return 250;
  return 300;
}

export default function EditVehiclePricingTable() {
  const navigate = useNavigate();

  const { data } = useHttpGet("/api/vehicles/price-table");
  const { data: originalData } = useHttpGet("/api/vehicles/price-table/latest");
  const { data: cols } = useHttpGet("/api/vehicles/price-period");

  const cmpFn = (v1, v2) => {
    let cmp = v1.make.name.localeCompare(v2.make.name);
    if (cmp !== 0) return cmp;
    cmp = v1.name.localeCompare(v2.name);
    if (cmp !== 0) return cmp;
    return v1.petrolType.localeCompare(v2.petrolType);
  }

  const columns = cols?.map(col => ({
    field: col.columnName, headerName: col.columnName,
    align: "center", headerAlign: "center",
    width: getWidth(col.columnName), editable: true,
    valueGetter: ({ row }) => row.priceObj[col.columnName]?.price ?? 0,
    renderHeader: (params) => (
      <Tooltip title={col?.description ?? ""}>
        <b>{params.colDef.headerName}</b>
      </Tooltip>
    ),
    renderCell: ({ row }) => {
      const price = row.priceObj[col.columnName]?.price;
      const originalPrice = row.priceObj[col.columnName]?.originalPrice;
      return (
        <Stack
          sx={{
            width: "100%", height: "100%",
            alignItems: "center", justifyContent: "center",
            backgroundColor: price !== originalPrice ? palette.primary.lighter : "white",
          }}
        >
          {price ?? 0}
        </Stack>
      );
    }
  })) ?? [];

  columns.unshift({
    field: "isParf", headerName: "COE / PARF",
    align: "center", headerAlign: "center",
    width: 100, sortable: false,
    valueFormatter: ({value}) => value ? "PARF" : "COE",
    renderHeader: (params) => <b>{params.colDef.headerName}</b>
  });
  columns.unshift({
    field: "model", headerName: "Make / Model",
    align: "center", headerAlign: "center",
    width: 250,
    sortComparator: cmpFn,
    valueFormatter: ({value}) => `${value?.make?.name} ${value?.name}, ${value?.petrolType}`,
    renderHeader: (params) => <b>{params.colDef.headerName}</b>
  });

  let version = "";
  if (data?.length > 0 && data[0].prices?.length > 0)
    version = `, Version: ${data[0].prices[0].version}`;

  return (
    <Container maxWidth="xl">
      <Typography
        sx={{ cursor: "pointer", marginBottom: 3 }} variant="h4" gutterBottom
        onClick={() => navigate("../")}
      >
        <span style={{position: "relative", top: 3}}>
          <ArrowBackSharpIcon />
        </span>
        &nbsp;&nbsp;
        Back To Table
      </Typography>
      <Typography variant="h4" gutterBottom>
        Edit Pricing Table {version}
      </Typography>
      <Box mt={3}>
        <PriceTableForm
          originalData={originalData ?? []}
          data={data ?? []}
          columns={columns}
        />
      </Box>
    </Container>
  );
}