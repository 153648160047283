import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getFirestore } from 'firebase/firestore';

const FirestoreContext = React.createContext({ fsObject: null });

export const FirestoreContextProvider = ({ app, children }) => {
  FirestoreContextProvider.propTypes = {
    app: PropTypes.object
  };

  const db = getFirestore(app);

  const contextValue = useMemo(() => ({
    fsObject: db
  }), [db]);

  FirestoreContextProvider.propTypes = { children: PropTypes.node };

  return (
    <FirestoreContext.Provider value={contextValue}>
      {children}
    </FirestoreContext.Provider>
  );
};

export default FirestoreContext;
