import {Stack} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useContext} from "react";
import * as Yup from "yup";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import DataContext from "../../../store/DataContext";
import {FcTextField, SubmitBtnGroup} from "../FormFields";

export default function InsuranceCompanyForm({ data, onReload, onClose }) {
  const method = data ? "Update" : "Create";
  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/insurances/company");
  const { onPut } = useHttpPut(`/api/insurances/company/${data?.id}`);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onCreate = async (values) => {
    await onPost(values);
    await onReload();
    onOpenSnackbar("Insurance company created successfully!");
    onClose();
  }

  const onUpdate = async (values) => {
    await onPut({ ...values, id: data?.id });
    await onReload();
    onOpenSnackbar("Insurance company updated successfully!");
    onClose();
  }

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please insert company name!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onCreate(values);
        else await onUpdate(values);
      } catch (error) {
        onOpenSnackbar(error.message, "error");
      }
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack spacing={3}>
          <FcTextField formik={formik} name="name" label="Company Name"/>
          <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

InsuranceCompanyForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}