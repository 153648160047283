import { Button, Stack } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React from "react";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="contained">Edit</Button>
      <Button variant="contained" color="error">Delete</Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function InsuranceCompanyTable({ data, onReload }) {
  const columns = [
    {
      field: 'id', headerName: 'No.',
      align: 'center', headerAlign: 'center',
      width: 50, sortable: false,
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    {
      field: 'name', headerName: 'Name',
      align: 'center', headerAlign: 'center',
      width: 400,
    },
    {
      field: '', headerName: 'Action',
      align: 'center', headerAlign: 'center',
      width: 353,
      renderCell: ({row}) => <ActionCol row={row} onReload={onReload}/>
    }
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      columns={columns} rows={data}
      pageSize={100} rowsPerPageOptions={[100]}
      disableSelectionOnClick
      autoHeight
    />
  );
}

InsuranceCompanyTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}