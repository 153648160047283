import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Stack, TextField, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import authContext from '../../../store/AuthContext';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { permissionRequired } from '../../../utils/authorization/roles';
import { VEHICLE_PERMISSIONS } from '../../../utils/authorization/permissions/vehiclePermissions';
import WorkshopTaskTable from '../../../components/Table/Workshop/WorkshopTaskTable';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import { xlsxToJson } from '../../../utils/xlsxToJson';
import DataContext from '../../../store/DataContext';

function convertToObject(data) {
  return data.map((item) => ({
    name: item.name,
    chineseName: item.chineseName,
    cost: item.cost,
    hasSpecific: item.hasSpecific === "Yes"
  }));
}

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 5,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  textAlign: 'center',
};

function ImportJobAction({ open, setOpen }) {
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();
  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/task/import`);
  const [data, setData] = useState([]);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const data = xlsxToJson(await file.arrayBuffer());
    const rows = convertToObject(data);
    setData(rows);
  }, [])

  const submit = async () => {
    try {
      await onPost(data);
      onOpenSnackbar("All job imported successfully");
      setTimeout(() => navigate(0), 1000);
    } catch (e) {
      onOpenSnackbar(JSON.parse(e.message).message, "error")
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ multiple: false, onDrop })

  return (
    <>
      <Dialog open={open} onClose={closeDialog} maxWidth="lg">
        <DialogTitle>Importing Tasks</DialogTitle>

        <DialogContent>
          <Typography mt={3} mb={3}>
            <b>Instruction:</b> Please make sure you have 4 columns named
            'name' (General), 'chineseName' (General), 'cost' (Number) and 'hasSpecific' (General) ('Yes' or 'No')
            with the appropriate values.
          </Typography>
          <Box {...getRootProps({ style })} mb={3}>
            <input {...getInputProps()}/>
            {isDragActive ? <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files.</p>}
          </Box>
          <WorkshopTaskTable data={data} action={false} />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>Cancel</Button>
          <Button variant="contained" onClick={submit}>Import</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={openDialog}>Import Tasks</Button>
    </>
  );
}

ImportJobAction.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}

export default function WorkshopTaskListing() {
  const authCtx = useContext(authContext);
  const { data: getData, onGet } = useCloudFuncGet(`${cloudFunctionPath}/workshop/task`)

  const [data, setData] = useState(getData.data ?? []);
  const [open, setOpen] = useState(false);

  const filterData = (str) => {
    setData((getData.data ?? []).filter((task) => task.name.toLowerCase().includes(str)));
  }

  useEffect(() => {
    setData(getData.data ?? []);
  }, [getData]);
  
  return (
    <Container maxWidth="xl">
      <Stack spacing={3} mb={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Workshop Task Listing</Typography>
          <Stack direction="row" spacing={2}>
            {permissionRequired(authCtx, VEHICLE_PERMISSIONS.CREATE) &&
              <ImportJobAction open={open} setOpen={setOpen} />
            }
            {permissionRequired(authCtx, VEHICLE_PERMISSIONS.CREATE) && <Button
              component={Link} to="./new"
              startIcon={<Icon icon={plusFill}/>}
              variant="contained" color="secondary"
            >
              Add Task
            </Button>}
          </Stack>
        </Stack>
      </Stack>

      <TextField
        label="Search Task"
        onChange={(e) => filterData(e.target.value.toLowerCase())}
        sx={{ mb: 2 }}
      />

      <WorkshopTaskTable data={data} onReload={onGet} />
    </Container>
  );
}