import React, { useCallback, useContext, useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import PropTypes from 'prop-types';

import { Button, Card, Container, FormControl, Grid, Stack, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AccidentPrivateSettlement from "../../components/OTR/Tabs/AccidentPrivateSettlement";
import CDWIncentive from "../../components/OTR/Tabs/CDWIncentive";

import { a11yProps, TabPanel } from '../../components/TabPanel';
import Commission from '../../components/OTR/Tabs/Commission';
import LongTerm from '../../components/OTR/Tabs/LongTerm';
import PriceIncrease from '../../components/OTR/Tabs/PriceIncrease';

import FirestoreContext from '../../store/FirestoreContext';
import AuthContext from '../../store/AuthContext';

import { fetchEnhcApi } from '../../utils/fetchApi';
import FullReportExport from '../../components/OTR/Tabs/FullReportExport';

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const dayjs = require('dayjs');

dayjs.extend(isSameOrBefore);

const convertRawDataToCustomersObject = (data) => {
  const customers = {};

  data.forEach((row) => {
    if (row.HirerName in customers) {
      if (row.BookNo in customers[row.HirerName]) {
        customers[row.HirerName][row.BookNo].agreements.push(row);
      } else {
        customers[row.HirerName][row.BookNo] = {
          bookingNo: row.BookNo,
          rentalAgreementNo: row.RentalAgreementNo,
          hirerId: row.HirerID,
          hirerName: row.HirerName,
          staff: row.Staff,
          startDate: row.DateFrom,
          endDate: row.DateTo,
          customerType: row.CustomerType,
          carPlate: row.CarPlate,
          carMake: row.CarMake,
          carModel: row.CarModel,
          petrolType: row.FuelType,
          purchaseType: row.PurchaseType,
          depositAmount: row.DepositAmount,
          rentalRate: row.RentalRate,
          billingType: row.BillingType,
          periodType: '',
          numberofMonths: 0,
          osAmount: row.OSAmount,
          totalUpfront: row.TotalUpfront,
          agreements: [row]
        };
      }
    } else {
      customers[row.HirerName] = {
        [row.BookNo]: {
          bookingNo: row.BookNo,
          rentalAgreementNo: row.RentalAgreementNo,
          hirerId: row.HirerID,
          hirerName: row.HirerName,
          staff: row.Staff,
          startDate: row.DateFrom,
          endDate: row.DateTo,
          customerType: row.CustomerType,
          carPlate: row.CarPlate,
          carMake: row.CarMake,
          carModel: row.CarModel,
          petrolType: row.FuelType,
          purchaseType: row.PurchaseType,
          depositAmount: row.DepositAmount,
          rentalRate: row.RentalRate,
          billingType: row.BillingType,
          periodType: '',
          numberofDays: 0,
          numberofMonths: 0,
          osAmount: row.OSAmount,
          totalUpfront: row.TotalUpfront,
          agreements: [row]
        }
      };
    }
  });

  Object.values(customers).forEach((customer) => {
    Object.values(customer).forEach((booking) => {
      let startDateSub = null;
      let endDateSub = null;

      booking.agreements.forEach((agreement) => {
        const agreementStartDate = dayjs(agreement.DateFrom);
        const agreementEndDate = dayjs(agreement.DateTo);

        if (startDateSub === null || agreementStartDate < startDateSub) {
          startDateSub = agreementStartDate;
        }

        if (endDateSub === null || agreementEndDate > endDateSub) {
          endDateSub = agreementEndDate;
        }
      });

      booking.startDate = startDateSub;
      booking.endDate = endDateSub;

      // according to Russell, one month = 4 weeks x 7 = 28 days
      const diff = endDateSub.add(1, 'day').diff(startDateSub, 'day', true);
      booking.periodType = diff >= 28 ? 'LongTerm' : 'ShortTerm';
      booking.numberofDays = diff;
      booking.numberofMonths = diff >= 28 ? Math.floor(diff / 28) : 1;
    });
  });
  return customers;
};

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(2, 2),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const CardDisplay = (props) => (
  <RootStyle>
    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      <Button
        sx={{ padding: 4, margin: 1 }}
        size="large"
        variant="contained"
        onClick={() => {
          props.updateParentSelectedSalesperson(props.salesperson);
          console.log('currentsalesperson:', props.salesperson);
        }}
      >
        {props.salespersonfullname}
      </Button>
      <br />
      {dayjs(props.startDate).format('MMM YYYY')}
    </Typography>
  </RootStyle>
);

CardDisplay.propTypes = {
  filterHandler: PropTypes.func,
  salesperson: PropTypes.string,
  salespersonfullname: PropTypes.string,
  startDate: PropTypes.string,
  updateParentSelectedSalesperson: PropTypes.func
};

const Otr = () => {
  const fsCtx = useContext(FirestoreContext);
  const [salespersonList, setSalespersonList] = useState([]);
  const [initial, setInitial] = useState(true);

  const startDate = dayjs().startOf('month').add(-1, 'month').format('YYYY-MM-DD');
  const [date, setDate] = useState(startDate);
  const [endDate, setEndDate] = useState(dayjs(date).add(1, 'month').format('YYYY-MM-DD'));

  const [loading, setLoading] = useState(false);
  const [currentSalesPerson, setCurrentSalesPerson] = useState('placeholder');

  const [carData, setCarData] = useState([]);

  const authCtx = useContext(AuthContext);
  const currentUserDept = authCtx.userDept;

  const updateParentLoadingState = (boolean) => {
    // this is used for disabling the date picker when db is loading
    setLoading(boolean);
  };

  const updateParentSelectedSalesperson = (name) => {
    // this is used for updating selected salesperson when the sales card is clicked
    setCurrentSalesPerson(name);
  };

  const fetchOtrHandler = useCallback(async () => {
    const q2 = query(
      collection(fsCtx.fsObject, 'fcadmin/fcadmin/users'),
      where('department', '==', 'sales')
    );
    const qSnapSalespersonList = await getDocs(q2);
    const tempArr2 = [];
    qSnapSalespersonList.forEach((doc) => {
      tempArr2.push({ name: doc.data().name, alias: doc.data().alias, uid: doc.data().uid });
    });
    setSalespersonList(tempArr2);
  }, [fsCtx.fsObject]);

  useEffect(() => fetchOtrHandler(), [fetchOtrHandler]);

  useEffect(() => {
    if (initial && salespersonList.length > 0) {
      setCurrentSalesPerson(salespersonList[0].alias);

      setInitial(false);
    }
  });

  useEffect(async () => {
    const data = await fetchEnhcApi('GetAllVehicleNo');
    setCarData(data);
  }, []);

  // const [filterModel, setFilterModel] = useState({ items: [] });

  const salesPerformanceCards = salespersonList.map((element) => {
    return (
      <Grid key={element.name} item xs>
        <CardDisplay
          salespersonfullname={element.name}
          salesperson={element.alias}
          // filterHandler={setFilterModel}
          updateParentSelectedSalesperson={updateParentSelectedSalesperson}
          startDate={date}
        />
      </Grid>
    );
  });

  // Tabs Navigation
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => setTabValue(newValue);

  const DateField = ({ label, defaultView = 'month' }) => {
    const format = defaultView === 'year' ? 'YYYY' : 'MMMM YYYY';
    const [view, setView] = useState(defaultView);

    const onViewChange = (view) => {
      if (defaultView === 'year') return;
      setView(view);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          disabled={loading}
          view={view}
          onViewChange={onViewChange}
          disableMaskedInput
          label={label}
          inputFormat={format}
          value={date}
          onChange={(newValue) => {
            setDate(dayjs(newValue).format('YYYY-MM-DD'));
            setEndDate(dayjs(newValue).add(1, 'month').format('YYYY-MM-DD'));
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
    );
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Sales | OTR
        </Typography>
        <FormControl variant="standard" sx={{ maxWidth: '280px', width: '100%', marginBottom: 1 }}>
          <DateField label="Month" />
        </FormControl>
      </Stack>
      <Grid container spacing={4}>
        {salesPerformanceCards}
      </Grid>

      <br />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Tabs value={tabValue} onChange={handleChange} aria-label="otr tabs">
            <Tab label="Commission Point - OTR" {...a11yProps(0)} />
            <Tab label="Long Term Points" {...a11yProps(1)} />
            <Tab label="Price Increase Points" {...a11yProps(2)} />
            <Tab label="Accident Private Settlement" {...a11yProps(3)} />
            <Tab label="CDW Incentive" {...a11yProps(4)} />
          </Tabs>
          <FullReportExport dayjs={dayjs} startDate={date} />
        </Stack>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <div>
          <Commission
            startDate={date}
            endDate={endDate}
            salespersonList={salespersonList}
            updateParentLoadingState={updateParentLoadingState}
            currentSalesPerson={currentSalesPerson}
            dayjs={dayjs}
            convertRawDataToCustomersObject={convertRawDataToCustomersObject}
            carData={carData}
            currentUserDept={currentUserDept}
          />
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <div>
          <LongTerm
            startDate={date}
            endDate={endDate}
            updateParentLoadingState={updateParentLoadingState}
            currentSalesPerson={currentSalesPerson}
            dayjs={dayjs}
            convertRawDataToCustomersObject={convertRawDataToCustomersObject}
          />
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <PriceIncrease
          startDate={date}
          endDate={endDate}
          updateParentLoadingState={updateParentLoadingState}
          currentSalesPerson={currentSalesPerson}
          dayjs={dayjs}
          convertRawDataToCustomersObject={convertRawDataToCustomersObject}
          carData={carData}
          currentUserDept={currentUserDept}
        />
      </TabPanel>
      <TabPanel index={3} value={tabValue}>
        <AccidentPrivateSettlement />
      </TabPanel>
      <TabPanel index={4} value={tabValue}>
        <CDWIncentive />
      </TabPanel>
    </Container>
  );
};

export default Otr;
