import {useState} from "react";

export default function usePaginationModel(initialSortModel = []) {
  const [paginationModel, setPaginationModel] = useState({ pageIndex: 1, pageSize: 100 });
  const [sortModel, setSortModel] = useState(initialSortModel);

  const handlePageChange = (newPage) => {
    setPaginationModel({ pageIndex: newPage + 1, pageSize: paginationModel.pageSize });
  }

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ pageIndex: paginationModel.pageIndex, pageSize: newPageSize });
  }

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  }

  return { paginationModel, sortModel, handlePageSizeChange, handleSortModelChange, handlePageChange };
}