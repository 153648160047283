import {DialogContent} from "@material-ui/core";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  Select,
  MenuItem
} from "@mui/material";
import {GridToolbar} from "@mui/x-data-grid";
import {debounce} from "lodash";
import PropTypes from "prop-types";
import {useCallback, useState} from "react";
import RecurringInvoiceFilterForm from "../../../../components/Form/Invoice/RecurringInvoiceFilterForm";
import RecurringInvoiceForm from "../../../../components/Form/Invoice/RecurringInvoiceForm";
import RecurringInvoiceTable from "../../../../components/Table/Invoice/RecurringInvoiceTable";
import useHttpGet from "../../../../hooks/http/useHttpGet";

const NewRecurringInvoiceAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [booking, setBooking] = useState(null);
  const [agreement, setAgreement] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [type, setType] = useState("normal");

  const { data: customers, loading: customerLoading, onGet: onCustomerGet } = useHttpGet("/api/customer", false);
  const { data: bookings, loading: bookingLoading, onGet: onBookingGet } = useHttpGet("/api/booking", false);
  const { data: agreements, loading: agreementLoading, onGet: onAgreementGet } = useHttpGet("/api/agreement", false);

  const invoiceTypes = [
    { label: "Normal", value: "normal" },
    { label: "CDW", value: "cdw" },
  ];

  const debounceOnChange = useCallback(debounce(async value => {
    if (value.length > 0) {
      await onCustomerGet({ name: value });
    }
  }, 500), []);

  const handleCustomerChange = (e, newValue) => {
    setCustomer(newValue);
    onBookingGet({ customerIdentity: newValue?.id });
  }

  const handleCustomerInputChange = (e, newInputValue) => {
    setCustomerName(newInputValue);
    debounceOnChange(newInputValue);
  }

  const handleBookingChange = (e, newValue) => {
    setBooking(newValue);
    onAgreementGet({ bookingNo: newValue?.bookingNo });
  }

  const handleAgreementChange = (e, newValue) => {
    setAgreement(newValue);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>Create New Recurring Invoice</DialogTitle>
        <DialogContent>
          <Grid container pt={1} spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                value={customer} onChange={handleCustomerChange}
                inputValue={customerName} onInputChange={handleCustomerInputChange}
                options={customers ?? []} loading={customerLoading}
                getOptionLabel={option => `${option?.name}, ${option?.identity?.substring(option?.identity?.length - 4, option?.identity?.length)}`}
                renderInput={params => <TextField {...params} label="Customer" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                value={booking} onChange={handleBookingChange}
                options={bookings ?? []} loading={bookingLoading}
                getOptionLabel={option => option?.bookingNo}
                renderInput={params => <TextField {...params} label="Booking" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                value={agreement} onChange={handleAgreementChange}
                options={agreements ?? []} loading={agreementLoading}
                getOptionLabel={option => option?.agreementNo}
                renderInput={params => <TextField {...params} label="Agreement" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel>Invoice Type</InputLabel>
                <Select
                  label="Type"
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  {invoiceTypes.map(({ label, value }, index) => (
                    <MenuItem value={value} key={index}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {agreement && <Grid item xs={12}>
              <RecurringInvoiceForm agreement={agreement} type={type} onReload={onReload} onClose={() => setOpen(false)}/>
            </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>New</Button>
    </>
  );
}

NewRecurringInvoiceAction.propTypes = {
  onReload: PropTypes.func,
}

export default function RecurringInvoiceTab({ recurringInvoices, onReload }) {
  const [selectionModel, setSelectionModel] = useState([]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Recurring Invoice Listing</Typography>
        <NewRecurringInvoiceAction onReload={onReload}/>
      </Stack>
      <Box mb={3}>
        <RecurringInvoiceFilterForm onReload={onReload}/>
      </Box>
      <RecurringInvoiceTable
        data={recurringInvoices} onReload={onReload}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
      />
    </>
  );
}

RecurringInvoiceTab.propTypes = {
  recurringInvoices: PropTypes.array,
  onReload: PropTypes.func,
}