import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import PropTypes from "prop-types";

export default function BookingDepositQuestionForm({ formik }) {
  const bookingCosts = [50, 100, 150, 200, 500, 600, 700, 800, 1000];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Booking Deposit?</Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          name="deposit"
          value={String(formik.values.bookingCost)}
          onChange={e => formik.setFieldValue("bookingCost", Number(e.target.value))}
        >
          {bookingCosts.map((bookingCost, index) => (
            <FormControlLabel key={index} control={<Radio />} label={`$${bookingCost}`} value={bookingCost}/>
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

BookingDepositQuestionForm.propTypes = {
  formik: PropTypes.object,
}