import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogContent, DialogActions, DialogTitle, Stack, Box} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncDelete from "../../../hooks/firebase/cloudFunction/useCloudFuncDelete";
import DataContext from "../../../store/DataContext";
import {getDayjsValue} from "../../../utils/date";
import ShellUserDiscountForm from "../../Form/ShellCard/ShellUserDiscountForm";
import FcTable from "../FcTable";

const EditAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Edit user discount record</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            {row && <ShellUserDiscountForm data={row} onReload={onReload} onClose={() => setOpen(false)}/>}
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Edit</Button>
    </>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useCloudFuncDelete(`${cloudFunctionPath}/wallet/card/discount/${row.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Deleted successfully!", severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Delete Rate</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <EditAction row={row} onReload={onReload}/>
      <DeleteAction row={row} onReload={onReload}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function AppUserDiscountRateTable({ data, onReload }) {
  const columns = [
    { field: "rate", headerName: "Discount Rate (%)", align: "center", headerAlign: "center", width: 200 },
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => getDayjsValue(row.startDate).format("DD MMM YYYY"),
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => row?.endDate ? getDayjsValue(row.endDate).format("DD MMM YYYY") : null,
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload} />
    },
  ];

  return (
    <FcTable columns={columns} rows={data ?? []}/>
  );
}

AppUserDiscountRateTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}