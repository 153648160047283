import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JobDateAcc from './JobDateAcc';

export default function JobDateNameAcc({ data, showPrice, hideAction, hideChinese, onReload }) {
  return (
    [... new Set(data.map((doc) => doc.name))].sort((a, b) => a.localeCompare(b)).map((name) => {
      const filtered = data.filter((doc) => doc.name === name);
      return (
        <Accordion key={name} elevation={10} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
            { showPrice && <Typography variant="h5" width={110}>${filtered.reduce((x, y) => x + y.cost, 0).toFixed(2)}</Typography> }
            <Typography variant="h5">{name.replaceAll("-", " ")}</Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ marginTop: -1.5 }}>
            <JobDateAcc data={filtered} showPrice={showPrice} hideAction={hideAction} hideChinese={hideChinese} onReload={onReload} />
          </AccordionDetails>
        </Accordion>
      );})
  );
}

JobDateNameAcc.propTypes = {
  data: PropTypes.array,
  showPrice: PropTypes.bool,
  hideAction: PropTypes.bool,
  hideChinese: PropTypes.bool,
  onReload: PropTypes.func
}