export const VEHICLE_PERMISSIONS = {
  VIEW: 'view_vehicle',
  CREATE: 'create_vehicle',
  UPDATE: 'update_vehicle',
  DELETE: 'delete_vehicle',
  DISPOSE: 'dispose_vehicle',
};

export const VEHICLE_LOGCARD_PERMISSIONS = {
  VIEW: 'view_vehicle_logcard',
  CREATE: 'create_vehicle_logcard',
  UPDATE: 'update_vehicle_logcard',
  DELETE: 'delete_vehicle_logcard',
}

export const VEHICLE_INSURANCE_PERMISSIONS = {
  VIEW: 'view_vehicle_insurance',
  CREATE: 'create_vehicle_insurance',
  UPDATE: 'update_vehicle_insurance',
  DELETE: 'delete_vehicle_insurance',
}

export const VEHICLE_SETTINGS_PERMISSIONS = {
  VIEW: 'view_vehicle_settings',
  CREATE: 'create_vehicle_settings',
  UPDATE: 'update_vehicle_settings',
  DELETE: 'delete_vehicle_settings',
}

export const VEHICLE_PRICE_PERMISSIONS = {
  VIEW: 'view_vehicle_price',
  CREATE: 'create_vehicle_price',
  UPDATE: 'update_vehicle_price',
  DELETE: 'delete_vehicle_price',
  PUBLISH: 'publish_vehicle_price',
}

export const setupVehicleRolePermissions = (ROLES) => ({
  [ROLES.SUPER_ADMIN]: [
    ...Object.values(VEHICLE_PERMISSIONS),
    ...Object.values(VEHICLE_LOGCARD_PERMISSIONS),
    ...Object.values(VEHICLE_INSURANCE_PERMISSIONS),
    ...Object.values(VEHICLE_SETTINGS_PERMISSIONS),
    ...Object.values(VEHICLE_PRICE_PERMISSIONS),
  ],
  [ROLES.MANAGER]: [
    ...Object.values(VEHICLE_PERMISSIONS),
    ...Object.values(VEHICLE_LOGCARD_PERMISSIONS),
    ...Object.values(VEHICLE_INSURANCE_PERMISSIONS),
    ...Object.values(VEHICLE_SETTINGS_PERMISSIONS),
    ...Object.values(VEHICLE_PRICE_PERMISSIONS),
  ],
  [ROLES.ACCOUNT]: [
    ...Object.values(VEHICLE_PERMISSIONS),
    ...Object.values(VEHICLE_LOGCARD_PERMISSIONS),
    ...Object.values(VEHICLE_INSURANCE_PERMISSIONS),
    ...Object.values(VEHICLE_SETTINGS_PERMISSIONS),
    ...Object.values(VEHICLE_PRICE_PERMISSIONS),
  ],
  [ROLES.SALES]: [
    VEHICLE_PERMISSIONS.VIEW,
    VEHICLE_LOGCARD_PERMISSIONS.VIEW,
    VEHICLE_INSURANCE_PERMISSIONS.VIEW,
    VEHICLE_SETTINGS_PERMISSIONS.VIEW,
    VEHICLE_PRICE_PERMISSIONS.VIEW,
    VEHICLE_PRICE_PERMISSIONS.CREATE,
    VEHICLE_PRICE_PERMISSIONS.UPDATE,
  ],
  [ROLES.OPERATION]: [
    ...Object.values(VEHICLE_PERMISSIONS),
    ...Object.values(VEHICLE_LOGCARD_PERMISSIONS),
    ...Object.values(VEHICLE_INSURANCE_PERMISSIONS),
    ...Object.values(VEHICLE_SETTINGS_PERMISSIONS),
    VEHICLE_PRICE_PERMISSIONS.VIEW,
  ],
  [ROLES.MARKETING]: [
    VEHICLE_PERMISSIONS.VIEW,
    VEHICLE_LOGCARD_PERMISSIONS.VIEW,
    VEHICLE_INSURANCE_PERMISSIONS.VIEW,
    VEHICLE_SETTINGS_PERMISSIONS.VIEW,
    VEHICLE_PRICE_PERMISSIONS.VIEW,
  ],
});