import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography
} from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import AssignerRequestAcc from '../../../components/Workshop/AssignerRequestAcc';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import LowerUpperBoundDateField from '../../../components/Workshop/LowerUpperBoundDateField';
import DataContext from '../../../store/DataContext';

export default function WorkshopRequestListing() {
  const dataCtx = useContext(DataContext);
  const { data, onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/request/get`);

  const [startDate, setStartDate] = useState(dayjs(new Date().toDateString()));
  const [endDate, setEndDate] = useState(dayjs(new Date().toDateString()).add(5, "day"));

  const onReload = async () => { await onPost({ lowerBoundTime: startDate.unix() * 1000, upperBoundTime: endDate.unix() * 1000 }); }

  useEffect(() => {
    try {
      onPost({ lowerBoundTime: startDate.unix() * 1000, upperBoundTime: endDate.unix() * 1000 });
    } catch (error) {
      console.log(error.message)
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }, [startDate, endDate]);
  
  return (
    <>
      <Stack spacing={3} mb={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Workshop Request Listing</Typography>
          <Button
            component={Link} to="./new"
            startIcon={<Icon icon={plusFill}/>}
            variant="contained" color="secondary"
          >
            Add Request
          </Button>
        </Stack>
      </Stack>

      <LowerUpperBoundDateField
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      { [... new Set(data.map((r) => r.assignedDate))].map((date) => {
        const filtered = data.filter((r) => r.assignedDate === date);
        return (
          <Accordion defaultExpanded={new Date(new Date().toDateString()).getTime() === date} key={date} elevation={10}>
            <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
              <Typography fontSize={20} fontWeight={700}>
                { moment(date).format("DD MMM YYYY") }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              { filtered.map((r) => <AssignerRequestAcc key={r.id} request={r} onReload={onReload} />) }
            </AccordionDetails>
          </Accordion>
        );
      }) }
    </>
  );
}