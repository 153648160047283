import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useContext} from "react";
import * as Yup from "yup";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import useHttpPut from "../../../../hooks/http/useHttpPut";
import DataContext from "../../../../store/DataContext";
import {FcTextArea, FcTextField, SubmitBtnGroup} from "../../FormFields";

export default function DisposalTaskForm({ data, onReload, onClose }) {
  const method = data ? "Update" : "Create";

  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/vehicles/disposal/task");
  const { onPut } = useHttpPut(`/api/vehicles/disposal/task/${data?.id}`);

  const formik = useFormik({
    initialValues: {
      task: data?.task ?? "",
      defaultStaff: data?.defaultStaff ?? "",
      order: data?.order ?? 0,
    },
    validationSchema: Yup.object({
      task: Yup.string().required("Task is required!"),
      defaultStaff: Yup.string().nullable(),
      order: Yup.number().required("Order is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onPost(values);
        else await onPut(values);
        await onReload();
        onClose();
        dataCtx.setSnackbarConfig({ open: true, message: `Task ${method}ed`, severity: "error" });
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "success" });
      }
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="task" label="Task"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="defaultStaff" label="Assign To"/>
          </Grid>
          {data && <Grid item xs={12}>
            <FcTextField formik={formik} name="order" label="Order" type="number"/>
          </Grid>}
          <Grid item xs={12}>
            <SubmitBtnGroup method={method} formik={formik} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

DisposalTaskForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}