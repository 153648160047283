import {FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import AgreementInvoiceForm from "./AgreementInvoiceForm";
import RecurringInvoiceForm from "./RecurringInvoiceForm";

export default function MultipleTypeInvoiceForm({ booking, agreement, onReload, onClose }) {
  const formTypes = ["Invoice", "CDW Invoice", "Recurring Invoice"];

  const [formType, setFormType] = useState("Invoice");

  return (
    <Stack spacing={3}>
      <FormControl>
        <InputLabel>Invoice Form Type</InputLabel>
        <Select
          label="Invoice Form Type"
          value={formType}
          onChange={e => setFormType(e.target.value)}
        >
          {formTypes?.map((type, index) => (
            <MenuItem value={type} key={index}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {formType === "Invoice" && <AgreementInvoiceForm agreement={agreement} onReload={onReload} onClose={onClose}/>}
      {formType === "Recurring Invoice" && <RecurringInvoiceForm booking={booking} agreement={agreement} onReload={onReload} onClose={onClose}/>}
      {formType === "CDW Invoice" && <AgreementInvoiceForm type="cdw" agreement={agreement} onReload={onReload} onClose={onClose}/>}
    </Stack>
  );
}

MultipleTypeInvoiceForm.propTypes = {
  booking: PropTypes.object,
  agreement: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}