import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WorkshopVehicleForm from '../../../components/Form/Workshop/WorkshopVehicleForm';
import BackButton from '../../../components/BackButton';

export default function NewWorkshopVehicle() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      <BackButton />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>Creating Workshop Vehicle</Typography>
      </Stack>

      <WorkshopVehicleForm onCancel={() => navigate("../")} />
    </Container>
  );
}