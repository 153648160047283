import React, { useEffect, useState } from 'react';
import { GridToolbar } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { getDayjsValue } from '../../../utils/date';
import { StyledDataGrid } from '../../../pages/Vehicle/styles';
import { FcDateField, SubmitBtnGroup } from '../../Form/FormFields';
import useHttpPut from '../../../hooks/http/useHttpPut';

const ActionCol = ({ row, onReload, onOpenSnackbar }) => {
  const [open, setOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState('6');
  const [newInspectionDueDate, setNewInspectionDueDate] = useState(getDayjsValue(row.inspectionDueDate).add(6, 'months'));

  const { onPut } = useHttpPut(`/api/vehicles/inspection/${row?.id}`);

  const OnUpdate = async (values) => {
    try {
      await onPut({
        inspectionDueDate: getDayjsValue(values.newInspectionDueDate).toJSON()
      });
      await onReload();
      onOpenSnackbar('Inspection Due Date updated');
      console.log('Updated successfully');
    } catch (e) {
      onOpenSnackbar(`An error occurred! ${e.message}`, 'error');
      console.log('error: ', e);
    }
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      newInspectionDueDate: getDayjsValue(row.inspectionDueDate).format('DD MMM YYYY')
    },
    validationSchema: Yup.object({
      newInspectionDueDate: Yup.date()
        .required('Please select the new inspection due date!').test(
          'isLater',
          (d) => `${d.path} is not later than current inspection due date`,
          (value) => getDayjsValue(value).isAfter(getDayjsValue(row.inspectionDueDate))
        )

    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await OnUpdate(values);
      setSubmitting(false);
    }
  });

  const handleChange = (event, value) => {
    setSelectedDuration(value);
    if (value !== 'others') {
      const newDate = getDayjsValue(row.inspectionDueDate).add(parseInt(value, 10), 'months');
      formik.setFieldValue('newInspectionDueDate', newDate);
    } else {
      formik.setFieldValue('newInspectionDueDate', '');
    }
  };

  useEffect(() => {
    if (selectedDuration !== 'others') {
      const newDate = getDayjsValue(row.inspectionDueDate).add(parseInt(selectedDuration, 10), 'months');
      setNewInspectionDueDate(newDate);
    }
  }, [selectedDuration, row.inspectionDueDate]);

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography variant="h5" mb={2}> Update Inspection Due Date </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} mb={2}>
            <Grid item>
              <Typography variant="h6">Vehicle No.</Typography>
              {row.vehicleNumber}
            </Grid>
            <Grid item>
              <Typography variant="h6">Make / Model</Typography>
              {row.model.make.name} {row.model.name}
            </Grid>
            <Grid item>
              <Typography variant="h6">Current Inspection Due Date</Typography>
              {getDayjsValue(row.inspectionDueDate).format('DD MMM YYYY')}
            </Grid>
          </Grid>
          <FormikProvider value={formik}>
            <Form autoComplete="off">
              <FormLabel> Renew Duration</FormLabel>
              <RadioGroup row value={selectedDuration} onChange={handleChange}>
                <FormControlLabel value="6" control={<Radio />} label="6 Months" />
                <FormControlLabel value="12" control={<Radio />} label="1 Year" />
                <FormControlLabel value="24" control={<Radio />} label="2 Years" />
                <FormControlLabel value="others" control={<Radio />} label="Others" />
              </RadioGroup>
              <Box my={3}>
                {selectedDuration === 'others' ? (
                  <FcDateField formik={formik} name="newInspectionDueDate" label="New Inspection Due Date" />
                ) : (
                  <Typography variant="h6">
                    New Inspection Due Date: {newInspectionDueDate.format('DD MMM YYYY')}
                  </Typography>
                )}
              </Box>
              <SubmitBtnGroup formik={formik} method="Update" onCancel={() => setOpen(false)} />
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}
      >Update Inspection Due Date</Button>
    </Stack>
  );
};

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
  onOpenSnackbar: PropTypes.func
};

export default function InspectionVehicleTable({ data, onReload, onOpenSnackbar }) {
  const columns = [
    {
      field: 'vehicleNumber',
      headerName: 'Vehicle No.',
      align: 'center',
      headerAlign: 'center',
      width: 125,
      sortable: true,
      sticky: true
    },
    {
      field: 'model.make.name',
      headerName: 'Make / Model',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: true,
      sticky: true,
      renderCell: ({ row }) => `${row.model.make.name} ${row.model.name}`
    },
    {
      field: 'vehicleStatus', headerName: 'Vehicle Status', align: 'center', headerAlign: 'center', width: 120
    },
    {
      field: 'inspectionDueDate', headerName: 'Inspection Due Date', align: 'center', headerAlign: 'center', width: 175,
      renderCell: ({ row }) => getDayjsValue(row.inspectionDueDate).format('DD MMM YYYY')
    },
    {
      field: 'bookingNo',
      headerName: 'Booking No.',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: true
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: true
    },
    {
      field: 'customerPhone',
      headerName: 'Customer Phone',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: true
    },
    {
      field: 'salesperson',
      headerName: 'Salesperson',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: true
    },
    {
      field: 'action', headerName: 'Action', align: 'center', headerAlign: 'center', width: 240, sortable: false,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload} onOpenSnackbar={onOpenSnackbar} />
    }
  ];
  const themeSelect = (row) => {
    const currentDate = getDayjsValue();
    const dueDate = getDayjsValue(row.inspectionDueDate);
    const diff = dueDate.diff(currentDate, 'day');

    if (diff <= 7) return 'Ended';
    if (diff > 7 && diff <= 14) return 'Handover';
    return 'primary';
  };

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      getRowClassName={({ row }) => `vehicle-theme--${themeSelect(row)}`}
      rows={data}
      columns={columns}
      disableSelectionOnClick
      sx={{ height: window.innerHeight * 0.75 }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'inspectionDueDate', sort: 'asc' }]
        }
      }}
    />
  );
}

InspectionVehicleTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
  onOpenSnackbar: PropTypes.func
};