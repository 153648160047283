import React from 'react';
import PropTypes from 'prop-types';
import MajorJobAcc from './MajorJobAcc';
import NormalJobAcc from './NormalJobAcc';

export default function JobAcc({ data, showPrice, hideAction, hideChinese, onReload }) {
  return data.startDate
    ? <MajorJobAcc data={data} showPrice={showPrice} hideAction={hideAction} hideChinese={hideChinese} onReload={onReload} />
    : <NormalJobAcc data={data} showPrice={showPrice} hideAction={hideAction} hideChinese={hideChinese} onReload={onReload} />;
}

JobAcc.propTypes = {
  data: PropTypes.object,
  showPrice: PropTypes.bool,
  hideAction: PropTypes.bool,
  hideChinese: PropTypes.bool,
  onReload: PropTypes.func
}