import { getAuth } from 'firebase/auth';

export default async function fetchApi(requestData) {
  const idToken = await getAuth().currentUser.getIdToken();

  const promiseArray = [];
  const api = process.env.REACT_APP_FIREBASE_RRBE_API;

  requestData.forEach((el) => {
    const multipleRequests = el.multipleRequests ? `&isMR=true` : '';
    promiseArray.push(
      fetch(`${api}?q=${el.method}${multipleRequests}`, {
        headers: {
          accept: 'application/json',
          token: idToken
        },
        body: JSON.stringify(el.requestBody),
        method: 'POST',
        mode: 'cors',
        credentials: 'omit',
        'upgrade-insecure-requests': 1
      })
    );
  });

  const response = await Promise.allSettled(promiseArray);

  const respNotOk = response.filter((el) => el.value.ok === false);

  if (respNotOk.length !== 0) {
    throw Error(
      `fetchApi - Some were rejected (0) - ${JSON.stringify(
        respNotOk.map((el2) => ({
          requestData: JSON.stringify(requestData),
          status: el2.status,
          value: {
            ok: el2.value.ok,
            redirect: el2.value.redirected,
            status: el2.value.status,
            statusText: el2.value.statusText,
            url: el2.value.url
          }
        }))
      )}`
    );
  }

  const rejectedPromises = response.filter((el) => el.status === 'rejected');

  if (rejectedPromises.length !== 0) {
    throw Error(
      `fetchApi - Some were rejected (1) - ${JSON.stringify(
        rejectedPromises.map((el2) => ({
          requestData: JSON.stringify(requestData),
          status: el2.status,
          value: {
            ok: el2.value.ok,
            redirect: el2.value.redirected,
            status: el2.value.status,
            statusText: el2.value.statusText,
            url: el2.value.url
          }
        }))
      )}`
    );
  }

  const data = await Promise.allSettled(response.map((el) => el.value.json()));

  const rejectedJsonPromises = data.filter((el) => el.status === 'rejected');

  if (rejectedJsonPromises.length !== 0) {
    throw Error(`fetchApi error: Some were rejected (2) - ${JSON.stringify(rejectedJsonPromises)}`);
  }

  const rrErrorResponses = [];

  // If Road Runner responds with error, throw
  if (requestData[0].multipleRequests) {
    // If block for multiple requests handled server side

    const mrData = data[0].value.map((el) => JSON.parse(el.d));

    mrData.forEach((el) => {
      if (Object.keys(el).includes('error')) {
        rrErrorResponses.push(el);
      }
    });

    if (rrErrorResponses.length !== 0) {
      throw Error(`fetchApi error: Some were rejected (3) - ${JSON.stringify(rrErrorResponses)}`);
    }
    return mrData;
  }

  data.forEach((el) => {
    if (Object.keys(el.value).includes('error') || !Object.keys(el.value).includes('d')) {
      rrErrorResponses.push(el);
    }
  });

  if (rrErrorResponses.length !== 0) {
    throw Error(`fetchApi error: Some were rejected (3) - ${JSON.stringify(rrErrorResponses)}`);
  }

  // if just setting things, do not parse because RRBE just returns the result that isn't JSONnified - JSON.parse will result in an error
  if (requestData[0].method.includes('Set') || requestData[0].method.includes('Send')) return data;

  return data.map((el) => JSON.parse(el.value.d));
}

export async function fetchEnhcApi(method, request) {
  const idToken = await getAuth().currentUser.getIdToken();
  const api = `https://renty.com.sg/${process.env.REACT_APP_ROAD_RUNNER_ROUTE}/enhc2/enhc_meth.php/${method}`;
  // const api = `https://renty.com.sg/RoadRunner/enhc2/enhc_meth.php/${method}`;

  try {
    const response = await fetch(api, {
      headers: {
        accept: 'application/json, text/javascript, */*; q=0.01',
        'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
        'content-type': 'application/json; charset=UTF-8',
        token: idToken
      },
      body: JSON.stringify(request),
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      'upgrade-insecure-requests': 1
    });
    return (await response.json()).d;
  } catch (error) {
    console.error(`fetchEnhcApi error: ${error.message}\n${error.stack}`);
  }
}
