import { Icon } from '@iconify/react';
import backspaceFill from '@iconify/icons-ant-design/arrow-left';
import { Button, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const CarDetail = () => {
  const params = useParams();

  const navigate = useNavigate();

  return (
    <section>
      <Stack direction="column" alignItems="flex-start" justify-content="flex-start" spacing={2}>
        <Button
          variant="contained"
          onClick={() => navigate('./dashboard/cars')}
          to="#"
          startIcon={<Icon icon={backspaceFill} />}
        >
          Back
        </Button>
        <h1>{params.carPlate}</h1>
      </Stack>
    </section>
  );
};

export default CarDetail;
