import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {GridToolbar} from "@mui/x-data-grid";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import {INVOICE_PERMISSIONS} from "../../../utils/authorization/permissions/invoicePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import {defaultTimezone} from "../../../utils/date";
import RecurringInvoiceDetails from "../../Details/Invoice/RecurringInvoiceDetails";

dayjs.extend(utc);
dayjs.extend(timezone);

const VoidAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const onVoid = async e => {
    e.preventDefault();
    try {
      dataCtx.setSnackbarConfig({ open: true, message: "Recurring Invoice Voided Successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Void Recurring Invoice?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={false} variant="contained" color="error" onClick={onVoid}>Void</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Void</Button>
    </>
  );
}

VoidAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/invoice/recurring/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Recurring Invoice Deleted Successfully!", severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Recurring Invoice?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ViewAction = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Recurring Invoice Details</DialogTitle>
        <DialogContent>
          <RecurringInvoiceDetails data={row}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" spacing={1}>
      {permissionRequired(authCtx, INVOICE_PERMISSIONS.VIEW) && <ViewAction row={row}/>}
      {permissionRequired(authCtx, INVOICE_PERMISSIONS.VOID) && <VoidAction row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, INVOICE_PERMISSIONS.DELETE) && <DeleteAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function RecurringInvoiceTable({ data, onReload }) {
  const weeklyValues = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const columns = [
    { field: "title", headerName: "Title", align: "center", headerAlign: "center", width: 200, sortable: false },
    {
      field: "agreementNo", headerName: "Agreement No.", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => row?.agreement?.agreementNo,
    },
    {
      field: "totalAmount", headerName: "Total Amount", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => `S$${row.totalAmount}`,
    },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 100, sortable: false },
    { field: "paymentType", headerName: "Payment Type", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "scheduleType", headerName: "Schedule Type", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "scheduleValue", headerName: "Day/Date", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => row.scheduleType === "Weekly" ? weeklyValues[row.scheduleValue] : row.scheduleValue,
    },
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => dayjs(row.startDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => dayjs(row.endDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    {
      field: "lastIssuedDate", headerName: "Last Issued Date", align: "center", headerAlign: "center", width: 200, sortable: false,
      renderCell: ({ row }) => row?.lastIssuedDate ? dayjs(row.lastIssuedDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY") : "",
    },
    {
      field: "nextIssueDate", headerName: "Next Issued Date", align: "center", headerAlign: "center", width: 200, sortable: false,
      renderCell: ({ row }) => row?.nextIssueDate ? dayjs(row.nextIssueDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY") : "",
    },
    {
      field: "company.name", headerName: "Company", align: "center", headerAlign: "center", width: 200, sortable: false,
      renderCell: ({ row }) => row?.agreement?.vehicleCompanyName,
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 350, sortable: false,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>,
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      columns={columns} rows={data ?? []}
      autoHeight
      disableSelectionOnClick
    />
  );
}

RecurringInvoiceTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}