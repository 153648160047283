import {Box, Divider, Stack} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useEffect, useState} from "react";
import {Collapse, Typography} from "@material-ui/core";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useLocation} from "react-router-dom";
import NavSection from "./NavSection";
import AuthContext from "../store/AuthContext";

export default function SidebarDropdown({ permissions, title, navConfig }) {
  const { pathname } = useLocation();
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  permissions.push('superadmin');
  if (!permissions.includes(authCtx.userDept)) return <Box/>;

  const sx = {
    width: '100%',
    padding: '15px 20px 10px 20px',
    cursor: 'pointer',
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (open) return;
    for (let i = 0; i < navConfig.length; i++) {
      let { path } = navConfig[i];
      if (path.includes(".")) path = path.substring(1);
      if (pathname.endsWith(path)) {
        setOpen(true);
        break;
      }
    }
  }, [pathname]);

  return (
    <>
      <Divider/>
      <Box sx={sx} onClick={() => setOpen(!open)}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">{title}</Typography>
          {open ? <ExpandMoreIcon /> : <ExpandLessIcon/>}
        </Stack>
        <Collapse in={open}>
          <NavSection navConfig={navConfig} dropdownItem/>
        </Collapse>
      </Box>
    </>
  );
}

SidebarDropdown.propTypes = {
  permissions: PropTypes.array,
  title: PropTypes.string,
  navConfig: PropTypes.array,
}
