import {
  Button,
  Typography,
  TextField,
  Stack,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useContext, useState } from 'react';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import AuthContext from '../../store/AuthContext';
import FirestoreContext from '../../store/FirestoreContext';

const NewVoucherCategory = () => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [newVoucherImg, setNewVoucherImg] = useState();
  const [voucherFixedEndDate, setVoucherFixedEndDate] = useState(false);
  const authCtx = useContext(AuthContext);
  const fsCtx = useContext(FirestoreContext);
  const [initialValues, setInitialValues] = useState([
    {
      key: 'amount',
      label: 'Amount',
      defaultVal: 0,
      dataType: 'numeric',
      placeholder: '',
      validationText: 'An amount is required.'
    },
    {
      key: 'category',
      label: 'Category',
      defaultVal: '',
      dataType: 'text',
      placeholder:
        'A category code for Fresh Cars admin to identify voucher categories. This will not be shown in the App.',
      validationText: 'A category code is required.'
    },
    {
      key: 'description',
      label: 'Description',
      defaultVal: '',
      dataType: 'text',
      placeholder: '',
      validationText: 'A description is required.'
    },
    {
      key: 'imageUrl',
      label: 'Image',
      defaultVal: '',
      dataType: 'text',
      validationText: 'A voucher image is required.'
    },
    {
      key: 'name',
      label: 'Name',
      defaultVal: '',
      dataType: 'text',
      placeholder:
        'A name for the voucher. This is displayed in the App when users view the voucher details.',
      validationText: 'A voucher name is required.'
    },
    !voucherFixedEndDate && {
      key: 'validity',
      label: 'Validity',
      defaultVal: 0,
      dataType: 'numeric',
      placeholder: 'Voucher validity.',
      validationText: 'A validity is required.'
    }
  ]);

  const validationSchema = yup.object({
    amount: yup
      .number('Enter an amount in SGD.')
      .positive('The entered amount must be a positive number.')
      .required('An amount is required.'),
    category: yup.string('Enter a category code.').required('A category code is required.'),
    description: yup.string('Enter a description.').required('A description is required.'),
    imageUrl: yup.string('Upload a voucher image.').required('A voucher image is required.'),
    name: yup.string('Enter a name.').required('A name is required.'),
    ...(!voucherFixedEndDate && {
      validity: yup
        .number()
        .positive('The entered amount must be a positive number.')
        .required(initialValues.find((el) => el.key === 'validity').validationText)
    })
  });

  const formik = useFormik({
    initialValues: Object.assign(
      {
        validityDenomination: 'months',
        voucherEndDate: null
      },
      ...initialValues.map((el) => ({ [el.key]: el.defaultVal }))
    ),
    validationSchema,
    onSubmit: async (values) => {
      console.log(JSON.stringify(values, null, 2));
      try {
        const fbStorage = getStorage();
        const fileName = `${values.category.replace('/', '')}-${new Date().getTime().toString()}`;
        await uploadString(ref(fbStorage, `vouchers/${fileName}`), newVoucherImg, 'data_url');
        const voucherImgUrl = await getDownloadURL(ref(fbStorage, `vouchers/${fileName}`));
        await addDoc(collection(fsCtx.fsObject, 'fcadmin/fcadmin/voucherCategories'), {
          ...values,
          amount: parseInt(values.amount, 10),
          creationDate: Timestamp.fromDate(new Date()),
          createdBy: authCtx.authObj.currentUser.displayName,
          description: encodeURIComponent(values.description),
          validity: parseInt(values.validity, 10),
          active: true,
          imageUrl: voucherImgUrl,
          voucherEndDate:
            values.voucherEndDate === null ||
            values.voucherEndDate === undefined ||
            values.voucherEndDate === ''
              ? null
              : Timestamp.fromDate(new Date(values.voucherEndDate)),
          tnc: termsArray2.map((el) => el.val)
        });
        alert(`New voucher category created!`);

        clearForm();
      } catch (e) {
        console.error(`An error occurred: ${e}\n${e.stack}`);
        window.alert(`An error occurred: ${e}`);
      }
    }
  });

  const selectFileHandler = (event) => {
    setSelectedFileName(event.target.value.split('\\').pop());
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function x(ev) {
        setNewVoucherImg(ev.target.result);
        formik.setFieldValue('imageUrl', ev.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const clearForm = () => {
    formik.handleReset();
    setNewVoucherImg();
    setSelectedFileName('');
    clearTermsHandler();
  };

  const [termsArray2, setTermsArray2] = useState([
    { key: new Date().getTime().toString(), val: '' }
  ]);

  const addTermsHandler = () => {
    const newKey = new Date().getTime().toString();
    setTermsArray2((old) => [...old, { key: newKey, value: '' }]);
  };

  const deleteTermHandler = (termKey) => {
    setTermsArray2((old) => [...old.filter((el) => el.key !== termKey)]);
  };

  const clearTermsHandler = () => {
    setTermsArray2([{ key: new Date().getTime().toString(), val: '' }]);
  };

  return (
    <>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        New Category
      </Typography>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          Select whether the voucher should have a specific end date regardless of date of issue or
          it should have a standard validity from date of issue.
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={false}
          name="radio-buttons-group"
          onChange={(ev) => {
            const x = ev.target.value === 'true';
            setVoucherFixedEndDate(x);

            if (x) {
              // true means set specific end date
              formik.setFieldValue('validity', 0);
              formik.setFieldValue('validityDenomination', '');
              formik.setFieldValue('voucherEndDate', new Date());
            } else {
              formik.setFieldValue('voucherEndDate', null);
            }

            setInitialValues((old) =>
              x
                ? [...old.filter((el) => el.key !== 'validity')]
                : [
                    ...old,
                    {
                      key: 'validity',
                      label: 'Validity',
                      defaultVal: 0,
                      dataType: 'numeric',
                      placeholder: 'Voucher validity.',
                      validationText: 'A validity is required.'
                    }
                  ]
            );
          }}
        >
          <FormControlLabel value control={<Radio />} label="Set specific end date" />
          <FormControlLabel value={false} control={<Radio />} label="Set validity" />
        </RadioGroup>
      </FormControl>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2}>
          {initialValues.map((el) =>
            el.key === 'imageUrl' ? undefined : (
              <TextField
                inputProps={{
                  type: el.dataType === 'numeric' ? 'number' : undefined
                }}
                key={el.key}
                // eslint-disable-next-line
                InputProps={
                  el.key === 'amount'
                    ? {
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }
                    : undefined
                }
                inputMode={el.dataType}
                fullWidth
                id="claimRefNotImportant"
                name={el.key}
                multiline={el.key === 'description'}
                rows={el.key === 'description' ? 3 : undefined}
                label={el.label}
                value={formik.values[el.key]}
                onChange={formik.handleChange}
                error={formik.touched[el.key] && Boolean(formik.errors[el.key])}
                helperText={formik.touched[el.key] ? formik.errors[el.key] : el.placeholder}
              />
            )
          )}
          {!voucherFixedEndDate && (
            <FormControl>
              <InputLabel id="demo-simple-select-label">Validity Denomination</InputLabel>
              <Select
                placeholder="Select whether the validity is in terms of days or months."
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="validityDenomination"
                value={formik.values.validityDenomination}
                label="Validity Denomination"
                error={
                  formik.touched.validityDenomination && Boolean(formik.errors.validityDenomination)
                }
                onChange={formik.handleChange}
              >
                <MenuItem value="days">Days</MenuItem>
                <MenuItem value="months">Months</MenuItem>
              </Select>
            </FormControl>
          )}
          {voucherFixedEndDate && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                name="voucherEndDate"
                id="voucherEndDate"
                disableMaskedInput
                label="Voucher End Date"
                inputFormat="D/MM/yyyy"
                value={formik.values.voucherEndDate}
                onChange={(val) => {
                  formik.setFieldValue('voucherEndDate', val);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          )}
          {termsArray2.map((el, i) => (
            <Stack maxWidth key={el.key} direction="row">
              <TextField
                fullWidth
                value={termsArray2.find((valEl) => valEl.key === el.key).val}
                onChange={(e) => {
                  setTermsArray2((old) =>
                    old.map((el2) =>
                      el2.key === el.key ? { key: el2.key, val: e.target.value } : el2
                    )
                  );
                }}
                id="outlined-basic"
                label="Terms"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {termsArray2.length - 1 === i ? (
                        <IconButton
                          onClick={() => {
                            addTermsHandler();
                            console.log('button pressed');
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => deleteTermHandler(el.key)}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
          ))}
          <Button variant="contained" onError={() => console.log('erropejrwo')} component="label">
            Upload
            <input
              name="imageUrl"
              onChange={selectFileHandler}
              hidden
              accept="image/*"
              type="file"
            />
          </Button>
          {formik.errors.imageUrl && formik.touched.imageUrl ? (
            <div style={{ color: 'red' }}>{formik.errors.imageUrl}</div>
          ) : null}
          Selected file: {selectedFileName}
          <i>*Voucher image dimension must be 2:1</i>
          {newVoucherImg && <img src={newVoucherImg} alt="new voucher" />}
          <Button color="primary" variant="contained" fullWidth type="submit">
            Submit
          </Button>
          <Button color="primary" variant="contained" fullWidth onClick={clearForm}>
            Clear
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default NewVoucherCategory;
