import { Box, Button } from "@mui/material";
import { useState } from "react";

/* eslint-disable no-plusplus */
export default function MultiSelectChecksboxs({list,selectAmount,itemSelected,SelectItem}){
    const listCount=list.length;
    const checkboxCount=Math.ceil(listCount/selectAmount);
    const checkItem=[];
    const [clickItem,setClickItem]=useState([]);
    
    for(let i=0;i<checkboxCount;i++){

        const start=i*selectAmount+1;
        let end=(i+1)*selectAmount;
        if(i===checkboxCount-1){
            end=listCount;
        }
        // console.log(`${start} ${end}`);
        checkItem.push(<Button sx={{mr:1}} variant={clickItem.includes(`${start}-${end}`)?"contained":"outlined"} onClick={()=>selectList(start,end)} key={`${start}-${end}`}>{start}-{end}</Button>);
    }

    function selectList(start,end){
        // console.log(list[0].status);
        const itemkey=`${start}-${end}`;
        let additem=false;

        if(clickItem.includes(itemkey)){
            setClickItem(prevItem=>prevItem.filter(item=>item!==itemkey));
        }else{
            additem=true;
            setClickItem(prevItem=>[...prevItem,itemkey]);
        }


        for(let i=start-1;i<end;i++){
            // console.log(list[i].status);
            const {status,description}=list[i];
            if(status.toLowerCase()!=='voided'){
                if(additem&&!description.toLowerCase().includes('deposit')&&!description.toLowerCase().includes('balancing')){
                    SelectItem(prevArray=>[...prevArray,list[i].id]);
                }else{
                    SelectItem(prevArray=>prevArray.filter(item=>item!==list[i].id));
                }
            }
        }
    }
    return (
        <Box className='multi-checkboxs' sx={{flexDirection:'row'}}>
            {checkItem}
        </Box>
    );

}