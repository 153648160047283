import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useHttpGet from '../../../../hooks/http/useHttpGet';
import { dateRangeIncludeRange, defaultTimezone } from '../../../../utils/date';

dayjs.extend(utc);
dayjs.extend(timezone);

const SurchargeItem = ({ formik, index, data, surchargeTypes }) => {
  const [onWaive, setOnWaive] = useState(false);
  const [surchargeObj, setSurchargeObj] = useState({
    surchargeTypeId: '',
    amount: 0
  });

  const handleChange = (field, value) => {
    if (field === 'surchargeTypeId') {
      const surchargeType = surchargeTypes.find(e => e.id === value);
      if (data) {
        const { surcharges } = formik.values;
        surcharges[index] = { surchargeTypeId: value, amount: surchargeType.price };
        formik.setFieldValue('surcharges', surcharges);
      }
      setSurchargeObj({ surchargeTypeId: value, amount: surchargeType.price });
    } else if (field === 'amount') {
      if (data) {
        const { surcharges } = formik.values;
        surcharges[index] = { ...surchargeObj, amount: value };
        formik.setFieldValue('surcharges', surcharges);
      }
      setSurchargeObj({ ...surchargeObj, amount: value });
    } else {
      if (data) {
        const { surcharges } = formik.values;
        surcharges[index] = { ...surchargeObj, [field]: value };
        formik.setFieldValue('surcharges', surcharges);
      }
      setSurchargeObj({ ...surchargeObj, [field]: value });
    }
  };

  const handleWaive = e => {
    e.preventDefault();
    if (onWaive) {
      const surchargeType = surchargeTypes?.find(type => type.id === surchargeObj.surchargeTypeId);
      const { surcharges } = formik.values;
      const surcharge = { ...surchargeObj, amount: surchargeType?.price, waiveReason: '' };
      surcharges[index] = surcharge;
      setSurchargeObj(surcharge);
      formik.setFieldValue('surcharges', surcharges);
    }
    setOnWaive(!onWaive);
  };

  useEffect(() => {
    if (data && surchargeTypes) {
      const surchargeType = surchargeTypes?.find(type => type.id === data.surchargeTypeId);
      if (surchargeType?.price > data.amount) setOnWaive(true);
      else setOnWaive(false);
      setSurchargeObj(data);
    }
  }, [data, surchargeTypes]);

  return (
    <Box>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
        <FormControl sx={{ width: 300 }} disabled>
          <InputLabel>Surcharges Type</InputLabel>
          <Select
            label="Surcharges Type" value={surchargeObj.surchargeTypeId}
            onChange={e => handleChange('surchargeTypeId', e.target.value)}
          >
            {surchargeTypes?.map(({ name, id }, index) => (
              <MenuItem value={id} key={index}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Price" type="number" value={surchargeObj.amount}
          onChange={e => handleChange('amount', Number(e.target.value))}
        />
        <Button variant="contained" onClick={handleWaive}>{onWaive ? 'Unwaive' : 'Waive'}</Button>
      </Stack>
      {onWaive && <TextField
        fullWidth sx={{ mt: 2 }}
        label="Waive Reason"
        value={surchargeObj.waiveReason}
        onChange={e => handleChange('waiveReason', e.target.value)}
      />}
    </Box>
  );
};

SurchargeItem.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  formik: PropTypes.object,
  surchargeTypes: PropTypes.array
};

export default function HolidaySurchargesQuestionForm({ formik }) {
  const { data: surchargeTypes } = useHttpGet('/api/surcharge/type');
  const nonHolidaySurcharges = surchargeTypes?.filter(s => s.publicHolidayId === null)?.map(s => s.id) ?? [];

  const applySurcharges = () => {
    const startDate = dayjs(formik.values.startDate).utc(true).tz(defaultTimezone);
    const endDate = dayjs(formik.values.endDate).utc(true).tz(defaultTimezone);

    const { surcharges } = formik.values;
    surchargeTypes.forEach(surchargeType => {
      if (surchargeType.startDate && surchargeType.endDate && surcharges.filter(s => s.surchargeTypeId === surchargeType.id).length === 0 &&
        dateRangeIncludeRange(startDate, endDate, surchargeType.startDate, surchargeType.endDate)) {
        surcharges.push({
          surchargeTypeId: surchargeType.id,
          amount: surchargeType.price
        });
      }
    });

    formik.setFieldValue('surcharges', surcharges);
  };

  useEffect(() => {
    if (surchargeTypes) applySurcharges();
  }, [surchargeTypes]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Holiday Surcharges</Typography>
      </Grid>
      {formik.values.surcharges.length > 0 ? formik.values.surcharges.map((surcharge, index) => {
        if (nonHolidaySurcharges.includes(surcharge.surchargeTypeId)) return <></>;
        return (
          <Grid item xs={12} key={index}>
            <SurchargeItem index={index} formik={formik} data={surcharge} surchargeTypes={surchargeTypes} />
          </Grid>
        );
      }) : <Grid item xs={12}>No Holiday Surcharges</Grid>}
      <Grid item xs={12}>
        <List sx={{ listStyleType: 'disc' }}>
          <ListItemText
            primary="How to waive surcharge?"
            primaryTypographyProps={{ variant: 'caption', fontWeight: 'bold' }}
          />
          <ListItemText
            primary="To fully waive the surcharge for your hirer:"
            primaryTypographyProps={{ variant: 'caption' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Set the price value to 0."
            primaryTypographyProps={{ variant: 'caption' }}
          />
          <ListItemText
            sx={{ display: 'list-item', ml: 3 }}
            primary="Provide a reason for the waiver."
            primaryTypographyProps={{ variant: 'caption' }}
          />
        </List>
      </Grid>
    </Grid>
  );
}

HolidaySurchargesQuestionForm.propTypes = {
  formik: PropTypes.object
};