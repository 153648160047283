import moment from "moment";
import PropTypes from "prop-types";
import { Form, FormikProvider, useFormik } from "formik";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import { useContext, useEffect } from 'react';
import { Grid } from "@mui/material";
import useHttpPut from "../../../hooks/http/useHttpPut";
import {
  FcDateField,
  FcPdfUpload,
  FcSelectField,
  FcTextArea,
  FcTextField,
  SubmitBtnGroup
} from "../FormFields";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from '../../../store/DataContext';

export default function VehicleInsuranceForm({ vehicleId, data, companies, onReload, onClose }) {
  const dataCtx = useContext(DataContext);
  const method = data ? "Update" : "Create";

  const { id } = useParams();
  if (vehicleId === undefined || vehicleId === null) {
    vehicleId = id;
  }

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const { onPost } = useHttpPost("/api/insurances");
  const { onPut } = useHttpPut(`/api/insurances/${data?.id}`);

  companies = companies?.map(company => ({ label: company.name, value: company.id }));

  const fleets = [
    { label: "Fleet", value: true },
    { label: "Individual", value: false },
  ];

  const insuranceTypes = [
    { label: "Z10/Z11", value: true },
    { label: "Others", value: false },
  ];

  const onCreate = async (values) => {
    await onPost({ ...values, vehicleId });
    onOpenSnackbar("Insurance inserted successfully!");
    await onReload();
    onClose();
  }

  const onUpdate = async (values) => {
    await onPut({ ...values, id: data.id, vehicleId });
    onOpenSnackbar("Insurance updated successfully!");
    await onReload();
    onClose();
  }

  const formik = useFormik({
    initialValues: {
      companyId: "",
      startDate: null,
      endDate: null,
      isFleet: true,
      isZ10Z11: true,
      minAgeCoverage: null,
      maxAgeCoverage: null,
      insuranceCi: "",
      remarks: "",
    },
    validationSchema: Yup.object({
      companyId: Yup.string().required("Please select insurance company name."),
      startDate: Yup.date().required("Please select insurance start date.").typeError("Please select insurance start date."),
      endDate: Yup.date().required("Please select insurance end date.").typeError("Please select insurance start date."),
      isFleet: Yup.boolean().required("Please select insurance fleet or individual."),
      isZ10Z11: Yup.boolean().required("Please select insurance type."),
      minAgeCoverage: Yup.number().nullable(),
      maxAgeCoverage: Yup.number().nullable(),
      insuranceCi: Yup.string().required("Please upload insurance CI."),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onCreate(values);
        else await onUpdate(values);
      } catch (error) {
        onOpenSnackbar(error.message, "error");
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("companyId", data.companyId);
      formik.setFieldValue("startDate", moment(data.startDate));
      formik.setFieldValue("endDate", moment(data.endDate));
      formik.setFieldValue("isFleet", data.isFleet);
      formik.setFieldValue("isZ10Z11", data.isZ10Z11);
      formik.setFieldValue("minAgeCoverage", data?.minAgeCoverage);
      formik.setFieldValue("maxAgeCoverage", data?.maxAgeCoverage);
      formik.setFieldValue("insuranceCi", data?.insuranceCi);
      formik.setFieldValue("remarks", data?.remarks);
    }
  }, [data]);

  useEffect(() => {
    if (formik.values.startDate) {
      const date = moment(formik.values.startDate);
      if (!date.isValid()) return;

      formik.setFieldValue("startDate", moment(date).toISOString());
      if (!formik.values.endDate)
        formik.setFieldValue("endDate", moment(date).add(1, "year").toISOString());
    }
  }, [formik.values.startDate]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={6} mb={3} py={1}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="companyId" label="Company Name" items={companies}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDate" label="Start Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDate" label="End Date"/>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField
              formik={formik}
              name="isFleet"
              label="Fleet / Individual"
              items={fleets}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField
              formik={formik}
              name="isZ10Z11"
              label="Insurance Type"
              items={insuranceTypes}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField
              formik={formik} type="number"
              name="minAgeCoverage" label="Min Age Coverage"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField
              formik={formik} type="number"
              name="maxAgeCoverage" label="Max Age Coverage"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcPdfUpload formik={formik} name="insuranceCi" label="Insurance CI"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
        </Grid>
        <SubmitBtnGroup method={method} formik={formik} onCancel={onClose}/>
      </Form>
    </FormikProvider>
  );
}

VehicleInsuranceForm.propTypes = {
  vehicleId: PropTypes.string,
  data: PropTypes.object,
  companies: PropTypes.array,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}