import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpPut from "../../../hooks/http/useHttpPut";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import {AGREEMENT_PERMISSIONS} from "../../../utils/authorization/permissions/bookingPermission";
import {permissionRequired} from "../../../utils/authorization/roles";
import {defaultTimezone} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";

dayjs.extend(utc);
dayjs.extend(timezone);

const ExtensionAddendumDetails = ({ row }) => {
  const details = [
    { label: "Addendum Type", value: row?.addendumType },
    { label: "Vehicle Number", value: row?.vehicleNumber },
    { label: "Original Start Date", value: dayjs(row?.originalStartDateTime).utc(true).tz(defaultTimezone).format("DD MMM YYYY") },
    { label: "Original End Date", value: dayjs(row?.originalEndDateTime).utc(true).tz(defaultTimezone).format("DD MMM YYYY") },
    { label: "Start Date", value: dayjs(row?.startDateTime).utc(true).tz(defaultTimezone).format("DD MMM YYYY") },
    { label: "End Date", value: dayjs(row?.endDateTime).utc(true).tz(defaultTimezone).format("DD MMM YYYY") },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({ label, value, props = { xs: 12, sm: 6, md: 4, lg: 3 } }, index) => (
        <Grid item key={index} {...props}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
    </Grid>
  );
}

ExtensionAddendumDetails.propTypes = {
  row: PropTypes.object,
}

const AddendumDetails = ({ row }) => {
  return <ExtensionAddendumDetails row={row}/>
}

AddendumDetails.propTypes = {
  row: PropTypes.object,
}

const ViewAction = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row.addendumNo} Details</DialogTitle>
        <DialogContent>
          <AddendumDetails row={row}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)} sx={{ mr: 1 }}>Close</Button>
          <Button component={Link} to={getFile(row.attachment)} target="_blank" variant="contained">
            Print
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/addendum/${row.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Addendum deleted successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Delete {row.addendumNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const VoidAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading } = useHttpPut(`/api/addendum/${row.id}/void`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onPut();
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Addendum voided successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Void {row.addendumNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>
            Void
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)} disabled={row.status === "Voided"}>
        Void
      </Button>
    </>
  );
}

VoidAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" spacing={1}>
      <ViewAction row={row}/>
      {permissionRequired(authCtx, AGREEMENT_PERMISSIONS.DELETE) && <DeleteAction row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, AGREEMENT_PERMISSIONS.VOID) && <VoidAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function AgreementAddendumTable({ data, onReload }) {
  const columns = [
    { field: "addendumNo", headerName: "Addendum No.", align: "center", headerAlign: "center", width: 150 },
    { field: "addendumType", headerName: "Addendum Type", align: "center", headerAlign: "center", width: 300 },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 100 },
    {
      field: "originalStartDateTime", headerName: "Original Start Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row?.originalStartDateTime ? dayjs(row.originalStartDateTime).utc(true).tz(defaultTimezone).format("DD MMM YYYY") : "",
    },
    {
      field: "originalEndDateTime", headerName: "Original End Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row?.originalEndDateTime ? dayjs(row.originalEndDateTime).utc(true).tz(defaultTimezone).format("DD MMM YYYY") : "",
    },
    {
      field: "startDateTime", headerName: "Start Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.startDateTime).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    {
      field: "endDateTime", headerName: "End Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.endDateTime).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 300,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>,
    },
  ];

  return (
    <StyledDataGrid columns={columns} rows={data ?? []} autoHeight pageSize={10}/>
  );
}

AgreementAddendumTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}