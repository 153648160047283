import { useCallback, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { Box, Stack, Typography } from '@mui/material';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { FcDateField, SubmitBtnGroup } from '../FormFields';
import useCloudFilePost from '../../../hooks/firebase/cloudFunction/useCloudFilePost';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import DataContext from '../../../store/DataContext';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

export const FcPriceListFileDropzone = ({ label, onDataReceived, onOpenSnackbar }) => {
  const { data, onPost } = useCloudFilePost(`${cloudFunctionPath}/otr/upload`);

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('attachment', file);
      await onPost(formData);
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop
  });

  const style = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center'
  };

  useEffect(() => {
    // console.log('data object in excel: ', data?.data?.dataByPurchase);
    onDataReceived(data?.data?.dataByPurchase);
  }, [data, onDataReceived]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <Box {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </Box>
      {data?.data?.filename ? (
        <Box mt={3}>{data?.data?.filename}</Box>
      ) : (
        <Link to={`${process.env.REACT_APP_GOOGLE_CLOUD_STORAGE_API}/OTR/pricetable_template.xlsx`} target="_blank">
          <Box mt={3}>Download Price Table Template</Box>
        </Link>
      )}
    </Box>
  );
};

FcPriceListFileDropzone.propTypes = {
  label: PropTypes.string,
  onDataReceived: PropTypes.func,
  onOpenSnackbar: PropTypes.func
};

export default function UploadPriceTableForm({ onClose, dayjs }) {
  const dataCtx = useContext(DataContext);
  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/otr/create`);

  const [priceTableData, setPriceTableData] = useState(null);

  const handleDataObjectFromChild = (data) => {
    setPriceTableData(data);
  };

  const onCreate = async (values) => {
    try {
      const data = {
        ...values,
        effectiveDate: dayjs(values.effectiveDate).startOf('month').toISOString(),
        priceTable: priceTableData
      };
      await onPost(data);
      onOpenSnackbar(
        `Price Table for ${values.effectiveDate.format('YYYY-MM-DD')} Uploaded Successfully`,
        'success'
      );
      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      priceTable: {},
      effectiveDate: dayjs()
    },
    validationSchema: Yup.object({
      priceTable: Yup.object().required('Please upload the price table!'),
      effectiveDate: Yup.date().required('Please select the date!')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (priceTableData) {
        setSubmitting(true);
        await onCreate(values);
        setSubmitting(false);
        setPriceTableData(null);
      } else {
        onOpenSnackbar(`ERROR: Please add the Price Table Attachment!`, 'error');
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Stack spacing={2} py={2}>
          <FcDateField formik={formik} name="effectiveDate" label="Effective Date" />
        </Stack>
        <FcPriceListFileDropzone
          label="Price Table Attachment"
          onDataReceived={handleDataObjectFromChild}
          onOpenSnackbar={onOpenSnackbar}
        />
        <SubmitBtnGroup formik={formik} method="Upload" onCancel={onClose} />
      </Form>
    </FormikProvider>
  );
}

UploadPriceTableForm.propTypes = { onClose: PropTypes.func, dayjs: PropTypes.func };
