import {Box, Button} from "@mui/material";
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";

const ActionCol = ({ row, onRemove }) => {
  const onClick = e => {
    e.preventDefault();
    onRemove(row.id);
  }

  return (
    <>
      <Button variant="contained" color="error" onClick={onClick}>
        Remove
      </Button>
    </>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onRemove: PropTypes.func,
}

export default function InvoiceItemTable({ data, onRemove }) {
  const columns = [
    { field: "type", headerName: "Type", width: 100, align: "center", headerAlign: "center", sortable: false },
    {
      field: "description", headerName: "Description", width: 1200, sortable: false,
      renderCell: ({ row }) => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>
          {row.description}
        </Box>
      )
    },
    { field: "quantity", headerName: "Quantity", width: 150, align: "center", headerAlign: "center", sortable: false },
    { field: "unitPrice", headerName: "Unit Price", width: 150, align: "center", headerAlign: "center", sortable: false },
    {
      field: "", headerName: "Action", width: 150, align: "center", headerAlign: "center", sortable: false,
      renderCell: ({ row }) => <ActionCol row={row} onRemove={onRemove}/>
    },
  ];

  return (
    <StyledDataGrid rows={data ?? []} columns={columns} autoHeight/>
  );
}

InvoiceItemTable.propTypes = {
  data: PropTypes.array,
  onRemove: PropTypes.func,
}