import Tab from "@mui/material/Tab";
import dayjs from "dayjs";
import React, {useState} from "react";
import {
  Box,
  Container,
} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import VehicleFilterForm from "../../components/Form/Vehicle/VehicleFilterForm";
import useHttpGet from "../../hooks/http/useHttpGet";
import palette from "../../theme/palette";
import {getDayjsValue} from "../../utils/date";
import AvailableVehicles from "./Tabs/AvailableVehicles";
import DisposalVehicles from "./Tabs/DisposalVehicles";
import InspectionVehicles from "./Tabs/InspectionVehicles";

export default function VehicleListing() {
  const [tabValue, setTabValue] = useState("available");

  const { data, onGet } = useHttpGet("/api/vehicles");
  const {data: inspectionVehicles, onGet: onGetInspectionVehicles} = useHttpGet("/api/vehicles/inspection");

  const availableVehicles = data?.filter(v => getDayjsValue(v?.logcard?.coeExpiryDate) >= dayjs() && !["Disposal", "Disposed"].includes(v.vehicleStatus));
  const disposalVehicles = data?.filter(v => getDayjsValue(v?.logcard?.coeExpiryDate) < dayjs() && v.vehicleStatus !== "Disposed");

  return (
    <Container maxWidth="xl">
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: palette.divider }}>
          <TabList onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="Available" value="available"/>
            <Tab label="Disposal" value="disposal"/>
            <Tab label="Inspection" value="inspection"/>
          </TabList>
        </Box>

        <Box mt={3}>
          <VehicleFilterForm onReload={tabValue === 'inspection'? onGetInspectionVehicles : onGet}/>
        </Box>

        <TabPanel value="available">
          <AvailableVehicles data={availableVehicles} onReload={onGet}/>
        </TabPanel>
        <TabPanel value="disposal">
          <DisposalVehicles data={disposalVehicles} onReload={onGet}/>
        </TabPanel>
        <TabPanel value="inspection">
          <InspectionVehicles data={inspectionVehicles} onReload={onGetInspectionVehicles}/>
        </TabPanel>
      </TabContext>
    </Container>
  );
}