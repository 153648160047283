import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useContext, useEffect} from "react";
import * as Yup from "yup";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import {FcTextField, SubmitBtnGroup} from "../FormFields";

export default function CdwPriceForm({ data, onReload, onClose }) {
  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/booking/price/cdw");

  const onCreate = async values => {
    await onPost(values);
    await onReload();
    onClose();
    dataCtx.setSnackbarConfig({ open: true, message: "CDW price create successfully!", severity: "success" });
  }

  const formik = useFormik({
    initialValues: {
      price: 0,
    },
    validationSchema: Yup.object({
      price: Yup.number().required("Please fill in surcharge price!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onCreate(values);
      } catch (e) {
        dataCtx.setSnackbarConfig({ open: true, message: e.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("price", data?.price);
    }
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3} py={1}>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="price" label="CDW Price / Month"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Create" onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

CdwPriceForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}