import {LoadingButton} from "@material-ui/lab";
import React, {useContext, useState} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import {VEHICLE_LOGCARD_PERMISSIONS} from "../../../utils/authorization/permissions/vehiclePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import VehicleLogcardForm from "../../Form/Vehicle/VehicleLogcardForm";
import DataContext from "../../../store/DataContext";
import {getPdf} from "../../../utils/getFile";
import LogcardDialogDetails from '../../Details/Vehicle/LogcardDialogDetails';
import useHttpGet from '../../../hooks/http/useHttpGet';

const ViewLogcard = ({ row, onReload }) => {
  const { data: owners } = useHttpGet("/api/vehicles/owner");

  const [open, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const Details = () => (
    <Box sx={{ p: 2 }}>
      <DialogTitle>Logcard Info</DialogTitle>
      <DialogContent>
        <LogcardDialogDetails row={row} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setEdit(true)}>
          Edit
        </Button>
      </DialogActions>
    </Box>
  );

  const EditLogcard = () => {
    return (
      <Box sx={{ p: 2 }}>
        <DialogTitle>Edit Logcard</DialogTitle>
        <DialogContent>
          <VehicleLogcardForm
            data={row} onReload={onReload}
            owners={owners} onClose={() => setEdit(false)}
          />
        </DialogContent>
      </Box>
    );
  };

  return (
    <>
      <Dialog
        open={open} onClose={() => setOpen(false)}
        fullWidth maxWidth="lg"
      >
        {isEdit ? <EditLogcard owners={owners} /> : <Details/>}
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        View
      </Button>
    </>
  );
}

ViewLogcard.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const { onDelete, loading } = useHttpDelete(`/api/logcard/${row?.id}`);
  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({
      open: true,
      message,
      severity,
    });
  }

  const onClick = async e => {
    e.preventDefault();
    try {
      await onDelete();
      onOpenSnackbar("Logcard deleted successfully!");
      await onReload();
    } catch (error) {
      onOpenSnackbar(e.message, "error");
    }
    setOpen(false);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 2 }}>
          <DialogTitle>Do you want to delete logcard?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpen(false)} type="submit" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              variant="contained" color="error"
              loading={loading} onClick={onClick}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" spacing={2}>
      <ViewLogcard row={row} onReload={onReload}/>
      <Button
        variant="contained" color="secondary"
        component={Link} to={getPdf(row?.logcard)} target="_blank"
      >
        PDF
      </Button>
      {permissionRequired(authCtx, VEHICLE_LOGCARD_PERMISSIONS) && <DeleteAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function LogcardTable({ data, onReload }) {
  const columns = [
    {
      field: 'id', headerName: 'No.',
      align: 'center', headerAlign: 'center',
      width: 50, sortable: false,
      valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
    },
    {
      field: 'vehicleOwner', headerName: 'Company Name',
      align: 'center', headerAlign: 'center',
      width: 228,
      valueFormatter: ({value}) => value.name
    },
    {
      field: 'coeExpiryDate', headerName: 'COE End Date',
      align: 'center', headerAlign: 'center',
      width: 150,
      valueFormatter: ({value}) => moment(value).format("DD MMM YYYY")
    },
    {
      field: 'coeRenewalDate', headerName: 'COE Renewal Date',
      align: 'center', headerAlign: 'center',
      width: 180,
      valueFormatter: ({value}) => value ? moment(value).format("DD MMM YYYY") : ""
    },
    {
      field: 'isActive', headerName: 'Status',
      align: 'center', headerAlign: 'center',
      width: 100, sortable: false,
      renderCell: ({row}) => row.isActive ? "Active" : "Inactive"
    },
    {
      field: 'isParf', headerName: 'PARF / COE',
      align: 'center', headerAlign: 'center',
      width: 135, sortable: false,
      renderCell: ({row}) => row.isParf ? "PARF" : "COE"
    },
    {
      field: 'vehicleType', headerName: 'Vehicle Type',
      align: 'center', headerAlign: 'center',
      width: 150, sortable: false,
    },
    {
      field: 'iuNo', headerName: 'IU Label No.',
      align: 'center', headerAlign: 'center',
      width: 200, sortable: false,
    },
    {
      field: '', headerName: 'Action',
      align: 'center', headerAlign: 'center',
      width: 350, sortable: false,
      renderCell: ({row}) => <ActionCol row={row} onReload={onReload}/>
    }
  ];

  return (
    <Box>
      <StyledDataGrid
        components={{ Toolbar: GridToolbar }}
        columns={columns} rows={data}
        pageSize={10} rowsPerPageOptions={[10]}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
}

LogcardTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}