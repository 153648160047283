import { initializeApp } from 'firebase/app';
import Router from './routes';
import ThemeConfig from './theme';
import ScrollToTop from './components/ScrollToTop';
import { AuthContextProvider } from './store/AuthContext';
import { FirestoreContextProvider } from './store/FirestoreContext';
import { DataContextProvider } from './store/DataContext';
import { SnackbarAlert } from './components/SnackbarAlert';

export default function App() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);

  return (
    <AuthContextProvider app={app}>
      <FirestoreContextProvider app={app}>
        <DataContextProvider>
          <ThemeConfig>
            <SnackbarAlert />
            <ScrollToTop />
            <Router />
          </ThemeConfig>
        </DataContextProvider>
      </FirestoreContextProvider>
    </AuthContextProvider>
  );
}
