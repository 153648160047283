import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { Container, Stack, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import AuthContext from '../store/AuthContext';
import Page from '../components/Page';

export default function Profile() {
  const authCtx = React.useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!'),
    hp: Yup.number('Please enter a valid number.').min(1),
    password: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      hp: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, { setSubmitting }) => setSubmitting(false)
  });

  const { errors, touched, handleSubmit, isSubmitting } = formik;
  return (
    <Page title="Profile | Fresh Cars">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Profile
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
           <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  disabled
                  label="Name"
                  onBlur={(event) => formik.setFieldValue('name', event.target.value)}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  defaultValue={authCtx.authObj.currentUser.displayName}
                />
              </Stack>

              <TextField
                disabled
                fullWidth
                autoComplete="username"
                type="number"
                label="HP"
                onBlur={(event) => formik.setFieldValue('hp', event.target.value)}
                error={Boolean(touched.hp && errors.hp)}
                helperText={touched.hp && errors.hp}
              />

              <TextField
                fullWidth
                disabled
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                onBlur={(event) => formik.setFieldValue('password', event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Update Profile
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
