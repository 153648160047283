import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {FcTextArea, FcTextField, SubmitBtnGroup} from "../FormFields";

export default function CreditNoteItemForm({ onClose, onAdd }) {
  const formik = useFormik({
    initialValues: {
      description: "",
      quantity: 1,
      unitPrice: 0,
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Description is required!"),
      quantity: Yup.number().required("Quantity is required!"),
      unitPrice: Yup.number().required("Unit price is required!"),
    }),
    onSubmit: values => {
      onAdd(values);
      onClose();
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <FcTextField formik={formik} name="quantity" label="Quantity"/>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FcTextField formik={formik} name="unitPrice" label="Unit Price"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="description" label="Description"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} onCancel={onClose} method="Add"/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

CreditNoteItemForm.propTypes = {
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
}