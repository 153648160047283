import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Link as RouterLink, useParams} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {Stack} from "@material-ui/core";
import {LoadingButton} from "@material-ui/lab";
import PropTypes from "prop-types";
import { collection, getDocs, query } from "firebase/firestore";
import FirestoreContext from "../../../store/FirestoreContext";
import {fetchEnhcApi} from "../../../utils/fetchApi";

const DeleteAction = ({row, setData}) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  const onDelete = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await fetchEnhcApi("DeleteVehicleDisposalTask", {id: row.id});
      const data = await fetchEnhcApi("GetVehicleDisposalTask", {statusId: id});
      setData(data);
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
    setOpen(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure want to delete this disposal task?
          </Typography>
          <Typography id="modal-modal-description" sx={{my: 2}}>
            {row.TaskName}
          </Typography>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={onDelete}
          >
            Delete
          </LoadingButton>
        </Box>
      </Modal>
      <Button variant="contained" onClick={() => handleOpen()}>Delete</Button>
    </div>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  setData: PropTypes.func
}

const EditAction = ({row, setData, users}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(row.TaskName);
  const [assign, setAssign] = useState(row.Assign);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  const onUpdate = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await fetchEnhcApi("UpdateVehicleDisposalTask", {
        id: row.id,
        TaskName: name,
        Assign: assign,
      });
      const data = await fetchEnhcApi("GetVehicleDisposalTask");
      setData(data);
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
    setOpen(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack spacing={3}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Task Name
            </Typography>

            <TextField
              variant="filled"
              fullWidth
              label="Task Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <FormControl fullWidth>
              <InputLabel id="assign-label">Assign To</InputLabel>
              <Select
                labelId="assign-label"
                id="assign-user-select"
                value={assign}
                label="Assign To"
                onChange={(e) => setAssign(e.target.value)}
              >
                <MenuItem value="NIL">NIL</MenuItem>
                {users.sort().map((user, index) => (
                  <MenuItem key={index} value={user}>
                    {user}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={onUpdate}
            >
              Update
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <Button variant="contained" onClick={() => handleOpen()}>
        Edit
      </Button>
    </div>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  setData: PropTypes.func,
  users: PropTypes.array
}

export default function VehicleDisposalTask() {
  const fsCtx = useContext(FirestoreContext);

  const pageOptions = [25];

  const [pageSize, setPageSize] = useState(25);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      width: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    {field: 'TaskName', headerName: 'Task', sortable: false, width: 775},
    {field: 'Assign', headerName: 'Assign To', align: "center", headerAlign: "center", sortable: false, width: 200},
    {
      field: '',
      headerName: 'Action',
      width: 225,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({row}) => (
        <Stack direction="row" spacing={1}>
          <EditAction row={row} setData={setData} users={users}/>
          <DeleteAction row={row} setData={setData}/>
        </Stack>
      )
    },
  ];

  useEffect(() => {
    fetchEnhcApi("GetVehicleDisposalTask")
      .then(res => setData(res))
      .catch(console.error);
  }, []);

  useEffect(() => {
    const q = query(collection(fsCtx.fsObject, 'fcadmin/fcadmin/users'));
    getDocs(q)
      .then(querySnapshot => {
        const usersDocs = querySnapshot.docs;
        setUsers(usersDocs.map(doc => doc.data().name));
      })
      .catch(console.error);
  }, [fsCtx.fsObject]);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Disposal Task Listing
        </Typography>

        <Button
          variant="contained"
          component={RouterLink}
          to="./new"
          startIcon={<Icon icon={plusFill}/>}
        >
          Add Task
        </Button>
      </Stack>
      <DataGrid
        sx={{
          "& .MuiDataGrid-row": {
            whiteSpace: "normal !important",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            lineHeight: "normal"
          },
        }}
        rows={data} columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={pageOptions}
        onPageSizeChange={size => setPageSize(size)}
        autoHeight
      />
    </Container>
  );
}