import { Container, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet } from 'react-router-dom';

// const drawerWidth = 240;

const Chat = () => (
  <Container maxWidth="xl" sx={{ padding: 0 }}>
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0} ml={2}>
      <Typography variant="h4" gutterBottom>
        App | Chat
      </Typography>
    </Stack>

    <Outlet />
  </Container>
);

Chat.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default Chat;
