import { Icon } from '@iconify/react';
import windowsFilled from '@iconify/icons-ant-design/windows-filled';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';

import FirestoreContext from '../../../store/FirestoreContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppItemOrders() {
  const fsCtx = useContext(FirestoreContext);

  const [otr, setOtr] = useState();

  useEffect(() => {
    let isMounted = true;

    const q = query(collection(fsCtx.fsObject, 'fcadmin/fcadmin/otr'));
    getDocs(q)
      .then((querySnapshot) => {
        if (isMounted)
          setOtr(
            querySnapshot.docs.reduce(
              (prevVal, currVal) =>
                currVal.data().startDate.toDate().getMonth() === new Date().getMonth()
                  ? prevVal + currVal.data().otr
                  : 0,
              0
            )
          );
      })
      .catch((error) => {
        const errorStr = `Failed to fetch OTR: ${error}`;
        console.error(errorStr);
        alert(errorStr);
      });

    return () => {
      isMounted = false;
    };
  }, [fsCtx.fsObject]);

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={windowsFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{otr}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        OTR
      </Typography>
    </RootStyle>
  );
}
