import {Typography} from "@mui/material";
import PropTypes from "prop-types";
import RefundVoucherTable from "../../../../components/Table/RefundVoucher/RefundVoucherTable";

export default function RefundVoucherTab({ refundVouchers, onReload }) {
  return (
    <>
      <Typography variant="h4" mb={3}>Refund Voucher Listing</Typography>
      <RefundVoucherTable data={refundVouchers} onReload={onReload}/>
    </>
  );
}

RefundVoucherTab.propTypes = {
  refundVouchers: PropTypes.array,
  onReload: PropTypes.func,
}