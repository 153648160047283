import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import {Box, Container, Typography} from "@mui/material";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import BookingQuestionForms from "../../components/Form/Booking/QuestionForm/BookingQuestionForms";
import useHttpGet from "../../hooks/http/useHttpGet";

export default function EditBooking() {
  const { bookingNo } = useParams();
  const navigate = useNavigate();

  const { data } = useHttpGet(`/api/booking/${bookingNo}`);

  return (
    <Container maxWidth="xl">
      <Typography
        sx={{ cursor: "pointer", marginBottom: 3 }} variant="h4" gutterBottom
        onClick={() => navigate("../")}
      >
        <span style={{ position: "relative", top: 3 }}>
          <ArrowBackSharpIcon />
        </span>
        &nbsp;&nbsp;
        Back To Details
      </Typography>

      <Typography variant="h4">Edit Booking {bookingNo}</Typography>

      <Box py={3}>
        {data && <BookingQuestionForms data={data}/>}
      </Box>
    </Container>
  );
}