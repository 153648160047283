import {Container, Typography} from "@mui/material";
import UserLogTable from "../../components/Table/User/UserLogTable";
import useHttpGet from "../../hooks/http/useHttpGet";

export default function UserLogs() {
  const { data } = useHttpGet("/api/user/log");

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mb={3}>User Action Logs</Typography>
      <UserLogTable data={data} />
    </Container>
  );
}