import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import dayjs from 'dayjs';
import ClearIcon from '@mui/icons-material/Clear';
import {cloudFunctionPath} from "../../../../cloudFunctionApiConfig";
import authContext from '../../../../store/AuthContext';
import useCloudFuncPost from '../../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import useCloudFuncGet from '../../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import useCloudFuncPut from '../../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import WorkshopVehicleDetails from '../../../Details/Workshop/WorkshopVehicleDetails';
import { FcAutocomplete, FcTextField } from '../../FormFields';
import DataContext from '../../../../store/DataContext';

function ExpiryWarning() {
  return (
    <Alert severity="warning">This car expiry date is in less than 3 month</Alert>
  );
}

export default function WorkshopJobForm({ data }) {
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const { data: taskData } = useCloudFuncGet(`${cloudFunctionPath}/workshop/task`);
  const { data: vehicleData } = useCloudFuncGet(`${cloudFunctionPath}/workshop/vehicle`);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/job`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/workshop/job/${data?.id}`);

  const method = data ? "Update" : "Create"
  
  const [open, setOpen] = useState(false);
  const [warning, setWarning] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const formik = useFormik({
    initialValues: {
      vehicle: null,
      task: [],
      other: "",
      remark: "",
      parkingTicket: "No"
    },
    validationSchema: Yup.object({
      vehicle: Yup.object().required("Please fill in Vehicle Number").nullable("never"),
      task: Yup.array().required("Please fill in a task").min(1, "Please fill in at least a task"),
      other: Yup.string(),
      remark: Yup.string()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const uploadData = {
          name: data?.name ?? auth.name,
          vehicleNumber: formik.values.vehicle.vehicleNumber,
          task: formik.values.task.map((task) => task.id),
          other: formik.values.other,
          remark: formik.values.remark,
          parkingTicket: formik.values.parkingTicket === "Yes",
          salesPerson: formik.values.vehicle.salesPerson,
          createDay: data === undefined ? new Date(new Date().toDateString()) : data.createDay,
          createdAt: data === undefined ? new Date() : data.createdAt,
          updatedAt: new Date(),
        };

        if (method === "Create") {
          await onPost(uploadData);
          onOpenSnackbar(`Successfully Created Job`);
          setTimeout(() => navigate("../"), 1000);
        } else {
          await onPut(uploadData)
          onOpenSnackbar(`Successfully Updated Job`);
          setTimeout(() => navigate(-1), 1000);
        }
      } catch (error) {
        console.log(error.message);
        onOpenSnackbar(JSON.parse(error.message).message, "error");
      }
      setSubmitting(false);
    }
  })

  useEffect(() => {
    if (data && vehicleData.data?.length && taskData.data?.length) {
      formik.setFieldValue("vehicle", vehicleData.data?.find((item) => item.vehicleNumber === data.vehicleNumber));
      formik.setFieldValue("task", data.task);
      formik.setFieldValue("other", data.other);
      // ?? is compulsory because older data does not include remark and parkingTicket field
      formik.setFieldValue("remark", data.remark ?? "");

      let parkingTicket = "No";
      if (data.parkingTicket) {
        parkingTicket = "Yes";
      }
      formik.setFieldValue("parkingTicket", parkingTicket);
    }
  }, [data, vehicleData.data?.length, taskData.data?.length]);

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <>
      <Dialog open={open} onClose={closeDialog} fullWidth>
        {/* For some reason variant affect only Desktop and fontSize only affect Mobile so need to set both */}
        <DialogTitle variant="h4" fontSize={26}>Confirmation | 确认</DialogTitle>

        { warning && <ExpiryWarning /> }

        <DialogContent>
          <Typography variant="h5">Vehicle Number | 车牌: </Typography>
          <Typography sx={{ fontSize: 18 }}>{formik.values.vehicle?.vehicleNumber}</Typography>

          <Typography variant="h5" mt={1}>Tasks | 任务: </Typography>
          {
            formik.values.task?.map((task, index) => (
              <Typography key={index} sx={{ fontSize: 18 }}>
                {task.name} | {task.chineseName}
                {task.specific ? `(${task.specific})` : ""}
              </Typography>
            ))
          }

          { formik.values.other && <Typography variant="h5" mt={1}>Other Tasks | 其他任务: </Typography> }
          { formik.values.other && <Typography sx={{ fontSize: 18 }}>{formik.values.other}</Typography> }

          { formik.values.remark && <Typography variant="h5" mt={1}>Special Remarks | 特别备注: </Typography> }
          { formik.values.remark && <Typography sx={{ fontSize: 18 }}>{formik.values.remark}</Typography> }

          <Typography variant="h5" mt={1}>Gave out Parking Ticket:</Typography>
          <Typography
            color={ formik.values.parkingTicket === "Yes" ? "primary" : "error" }
            sx={{ fontSize: 18, fontWeight: 700 }}
          >{formik.values.parkingTicket}</Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            {method}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      
      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FcAutocomplete
                disabled={data}
                formik={formik}
                name="vehicle"
                label="Vehicle Number | 车牌"
                options={vehicleData.data ?? []}
                getOptionLabel={(option) => option.vehicleNumber}
                onChange={(value) =>
                  dayjs(value?.coeExpiryDate ?? Number.MAX_VALUE).unix() - dayjs().unix() < 90 * 86400 ? setWarning(true) : setWarning(false)
                }
              />
            </Grid>

            { warning && <Grid item xs={12}><ExpiryWarning /></Grid> }

            <Grid item xs={12}>
              <WorkshopVehicleDetails vehicle={formik.values.vehicle} />
            </Grid>

            <Grid item xs={12}>
              <FcAutocomplete
                formik={formik}
                name="task"
                label="Task | 任务"
                multiple
                options={taskData.data ?? []}
                getOptionLabel={(option) => `${option.name} | ${option.chineseName}`}
                // Do not render tags, this autocomplete is merely for selecting, displaying is handled outside
                renderTags={() => <></>}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name &&
                  option.cost === value.cost &&
                  option.chineseName === value.chineseName
                }
              />
            </Grid>

            { formik.values.task.map((task) =>
              <Grid key={task.name} item xs={12}>
                <Grid p={1} bgcolor="#F0F0F0" borderRadius={1} justifyContent="center" alignItems="center" container>
                  <Grid item xs>
                    <Typography sx={{ fontSize: 18, whiteSpace: "pre-line" }}>
                      {`${task.name} ${task.chineseName}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      children={<ClearIcon />}
                      onClick={() => formik.setFieldValue("task", formik.values.task.filter((t) => t.name !== task.name))}
                    />
                  </Grid>
                  { task.hasSpecific &&
                    <Grid item xs={12} mt={2}>
                      <TextField
                        fullWidth
                        label={`${task.name} Specific`}
                        defaultValue={formik.values.task?.find((t) => t.name === task.name)?.specific ?? ""}
                        onChange={(event) => {
                          formik.values.task.find((t) => t.name === task.name).specific = event.target.value;
                        }}
                      />
                    </Grid> }
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <FcTextField formik={formik} name="other" label="Other Tasks | 其他任务" sx={{ marginTop: 1.5 }} />
            </Grid>

            <Grid item xs={12}>
              <FcAutocomplete
                formik={formik}
                name="remark"
                label="Special Remarks | 特别备注"
                options={ ["Some repair not done due to COE ending"] }
                freeSolo
              />
            </Grid>

            <Grid item xs={12} ml={1}>
              <Typography>Gave out Parking Ticket?</Typography>
              <RadioGroup
                row
                defaultValue="No"
                value={formik.values.parkingTicket}
                onChange={(event) => formik.setFieldValue("parkingTicket", event.target.value)}
              >
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel value="Yes" label="Yes" control={<Radio/>} />
              </RadioGroup>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            onClick={openDialog}
            sx={{ marginTop: 1.5, width: { xs: "100%", md: "auto" }}}
          >
            {method}
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}

WorkshopJobForm.propTypes = {
  data: PropTypes.object
}