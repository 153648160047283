import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import useHttpGet from "../../../../hooks/http/useHttpGet";

export default function PriceModelQuestionForm({ formik, handlePricePeriodChange }) {
  const { data } = useHttpGet("/api/vehicles/price-period", true, {
    period: formik.values.periodType,
    paymentMethod: formik.values.paymentMethod,
  });

  const handleChange = (e) => {
    formik.setFieldValue("pricePeriodId", e.target.value);
    const pricePeriod = data?.find(p => p.id === e.target.value);
    handlePricePeriodChange(pricePeriod);
  }

  const rentalPeriod = moment(formik.values.endDate).diff(formik.values.startDate, "days");
  const priceModels = data?.filter(pricePeriod => pricePeriod.minRentalPeriod <= rentalPeriod) ?? [];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Choose the price model?</Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup name="pricePeriodId" value={formik.values.pricePeriodId} onChange={handleChange}>
          {priceModels.length === 0 ? "No suitable price model" : priceModels.map((pricePeriod, index) => (
            <FormControlLabel key={index} control={<Radio/>} label={pricePeriod.columnName} value={pricePeriod.id}/>
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

PriceModelQuestionForm.propTypes = {
  formik: PropTypes.object,
  handlePricePeriodChange: PropTypes.func,
}