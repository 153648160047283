import React, { isValidElement } from 'react';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const renderValue = (col, row, index) => {
  if (col.showIndex) return index + 1;
  if (col.renderCell) return col.renderCell({ row });
  return row[col.field];
};

const generateXLSX = (columns, rows, undefinedColumns, dayjs, startDate, label) => {
  const salesPersons = [...new Set(rows.map((row) => row.staff))];

  const summaryWorksheetData = [];
  const undefinedWorksheetData = [];
  const definedWorksheetData = [];

  salesPersons.forEach((salesPerson) => {
    const longTermRows = rows
      .filter(
        (row) =>
          row.staff === salesPerson &&
          row.contractType === 'LongTerm' &&
          row.newOrExistingCustomer === 'New' &&
          dayjs(row.startDate) >= dayjs(startDate).startOf('month')
      )
      .map((booking) => {
        const duration = dayjs(booking.endDate).diff(booking.startDate, 'day');
        const numberOfMonths = Math.floor(duration / 28);
        const longTerm = Math.min(numberOfMonths, 3);
        return { ...booking, numberOfMonths, longTerm };
      });

    const undefinedRows = rows.filter(
      (row) => row.staff === salesPerson && row.contractType === 'Undefined'
    );

    const definedRows = rows.filter(
      (row) =>
        row.staff === salesPerson &&
        (row.contractType === 'LongTerm' || row.contractType === 'ShortTerm')
    );

    summaryWorksheetData.push([]);
    summaryWorksheetData.push([salesPerson]);
    summaryWorksheetData.push([
      label === 'OTR' ? 'OTR' : 'Price Increase Points',
      definedRows.reduce((total, row) => {
        return total + row.points;
      }, 0)
    ]);
    if (label === 'OTR') {
      summaryWorksheetData.push([
        'Long Term Points',
        longTermRows.reduce((total, row) => {
          return total + row.longTerm;
        }, 0)
      ]);
    }

    // for undefined
    undefinedWorksheetData.push([]);
    undefinedWorksheetData.push(['', salesPerson]);
    undefinedWorksheetData.push([]);
    undefinedWorksheetData.push(undefinedColumns.map((col) => col.headerName));
    undefinedRows.forEach((row, rowIndex) => {
      const exportRow = {};
      undefinedColumns.forEach((col) => {
        const val = renderValue(col, row, rowIndex);
        if (!isValidElement(val)) exportRow[col.headerName] = val;
      });
      undefinedWorksheetData.push(Object.values(exportRow));
    });
    undefinedWorksheetData.push([]);

    // for defined
    definedWorksheetData.push([]);
    definedWorksheetData.push(['', salesPerson]);
    definedWorksheetData.push([]);
    definedWorksheetData.push(columns.map((col) => col.headerName));
    definedRows.forEach((row, rowIndex) => {
      const exportRow = {};
      columns.forEach((col) => {
        const val = renderValue(col, row, rowIndex);
        if (!isValidElement(val)) exportRow[col.headerName] = val;
      });
      definedWorksheetData.push(Object.values(exportRow));
    });
    definedWorksheetData.push([]);
  });

  const undefinedWorksheet = XLSX.utils.aoa_to_sheet(undefinedWorksheetData);
  const definedWorksheet = XLSX.utils.aoa_to_sheet(definedWorksheetData);
  const summaryWorksheet = XLSX.utils.aoa_to_sheet(summaryWorksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'Summary');
  XLSX.utils.book_append_sheet(workbook, undefinedWorksheet, 'Undefined Section');
  XLSX.utils.book_append_sheet(
    workbook,
    definedWorksheet,
    label === 'OTR' ? 'LT,ST Section' : 'Defined Section'
  );
  XLSX.writeFile(workbook, `${dayjs(startDate).format('MMMM YYYY')} ${label} Report.xlsx`);
};

const OTRReportExport = ({ columns, rows, undefinedColumns, dayjs, startDate, label }) => {
  const onExport = (e) => {
    e.preventDefault();
    generateXLSX(columns, rows, undefinedColumns, dayjs, startDate, label);
  };

  return (
    <Button startIcon={<DownloadIcon />} onClick={onExport}>
      {`Download ${label} Report Only`}
    </Button>
  );
};

OTRReportExport.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  undefinedColumns: PropTypes.array,
  dayjs: PropTypes.func,
  startDate: PropTypes.string,
  label: PropTypes.string
};

export default OTRReportExport;
