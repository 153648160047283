import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@material-ui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React, { useState } from "react";
import VehicleMakeForm from "../../../components/Form/Vehicle/VehicleMakeForm";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpGet from "../../../hooks/http/useHttpGet";

const AddAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Add Make</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <VehicleMakeForm onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" startIcon={<Icon icon={plusFill}/>} onClick={() => setOpen(true)}>
        Add Make
      </Button>
    </>
  );
}

AddAction.propTypes = {
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/vehicles/make/${row.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
    } catch (error) {
      console.error(error);
    }
    setOpen(false);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Are you sure want to delete {row.name}?</DialogTitle>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton variant="contained" color="error" onClick={onSubmit} loading={loading}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)} color="error">Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const EditAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Edit {row.name}?</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <VehicleMakeForm onReload={onReload} onClose={() => setOpen(false)} makeData={row}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Edit</Button>
    </>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onGet }) => {
  return (
    <Stack direction="row" spacing={2}>
      <EditAction row={row} onReload={onGet}/>
      <DeleteAction row={row} onReload={onGet}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onGet: PropTypes.func,
}

const DataTable = ({ data, onReload }) => {
  const [pageSize, setPageSize] = useState(100);
  const pageOptions = [100];

  const columns = [
    {
      field: 'id', headerName: 'No.',
      align: "center", headerAlign: "center",
      width: 150, sortable: false,
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    { field: "name", headerName: "Make", width: 250, align: "center", headerAlign: "center" },
    {
      field: "", headerName: "Action",
      align: "center", headerAlign: "center",
      width: 250, sortable: false,
      renderCell: ({ row }) => <ActionCol row={row} onGet={onReload}/>
    }
  ];

  return (
    <DataGrid
      components={{ Toolbar: GridToolbar }}
      rows={data ?? []} columns={columns}
      pageSize={pageSize} rowsPerPageOptions={pageOptions}
      onPageSizeChange={size => setPageSize(size)}
      autoHeight
    />
  );
}

DataTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}

export default function VehicleMakeList() {
  const { data, onGet } = useHttpGet('/api/vehicles/make');

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Make Listing</Typography>
        <AddAction onReload={onGet}/>
      </Stack>
      <DataTable data={data} onReload={onGet}/>
    </Stack>
  );
}