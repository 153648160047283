import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import moment from "moment/moment";
import {LoadingButton} from "@material-ui/lab";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from 'yup';
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { collection, getDocs, query } from "firebase/firestore";
import FirestoreContext from "../../../store/FirestoreContext";
import {fetchEnhcApi} from "../../../utils/fetchApi";

const loadCheckList = (vehicle, setCheckList) => {
  fetchEnhcApi("GetVehicleDisposalTaskCheckList", { vehicle })
    .then(res => setCheckList(res))
    .catch(console.error);
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

const SaveAction = ({row, vehicleId, setMessage, setOpenSnackbar, setCheckList}) => {
  const { vehicle } = useParams();
  const [open, setOpen] = useState(false);
  const fsCtx = useContext(FirestoreContext);
  const [users, setUsers] = useState([]);

  const onSave = async (data) => {
    try {
      if (row.DoneId) {
        await fetchEnhcApi("UpdateVehicleDisposalTaskDone" , {
          id: row.DoneId,
          Remarks: data.Remarks,
          Assign: data.Assign,
          DoneAt: moment(data.DoneAt).endOf("days")
        });
      } else {
        await fetchEnhcApi("CreateVehicleDisposalTaskDone" , {
          TaskId: row.id,
          VehicleId: vehicleId,
          Remarks: data.Remarks,
          Assign: data.Assign,
          DoneAt: moment(data.DoneAt).endOf("days")
        });
      }
      loadCheckList(vehicle, setCheckList);
      setMessage("Save Successfully");
      setOpenSnackbar(true);
    } catch (error) {
      console.log(error);
      setMessage("Something go wrong!");
      setOpenSnackbar(true);
    }
  }

  const formik = useFormik({
    initialValues: {
      DoneAt: row?.DoneAt,
      Remarks: row?.Remarks,
      Assign: row?.Assign ?? row.Default_Assign,
    },
    validationSchema: Yup.object().shape({
      DoneAt: Yup.date().nullable(),
      Remarks: Yup.string().nullable(),
      Assign: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
      await onSave(values);
      setSubmitting(true);
    }
  });

  useEffect(() => {
    const q = query(collection(fsCtx.fsObject, 'fcadmin/fcadmin/users'));
    getDocs(q)
      .then(querySnapshot => {
        const usersDocs = querySnapshot.docs;
        setUsers(usersDocs.map(doc => doc.data().name));
      })
      .catch(console.error);
  }, [fsCtx.fsObject]);

  const {errors, touched, handleSubmit, isSubmitting, getFieldProps} = formik;
  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Save Task
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    disableMaskedInput
                    label="Done Date"
                    inputFormat="DD-MM-YYYY"
                    value={formik.values.DoneAt}
                    onChange={val => formik.setFieldValue('DoneAt', val)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <TextField
                  variant="filled" fullWidth
                  label="Remarks"
                  {...getFieldProps("Remarks")}
                  error={Boolean(touched.Remarks && errors.Remarks)}
                  helperText={touched.Remarks && errors.Remarks}
                />

                <FormControl fullWidth>
                  <InputLabel id="assign-label">Assign To</InputLabel>
                  <Select
                    labelId="assign-label"
                    id="assign-user-select"
                    label="Assign To"
                    {...getFieldProps("Assign")}
                  >
                    <MenuItem value="NIL">NIL</MenuItem>
                    {users.sort().map((user, index) => (
                      <MenuItem key={index} value={user}>
                        {user}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
      <Button variant="contained" onClick={() => setOpen(true)}>Update</Button>
    </div>
  );
}

SaveAction.propTypes = {
  row: PropTypes.object,
  vehicleId: PropTypes.number,
  setMessage: PropTypes.func,
  setOpenSnackbar: PropTypes.func,
  setCheckList: PropTypes.func,
}

export default function VehicleDisposalCheckList() {
  const pageOptions = [25];
  const navigate = useNavigate();

  const { vehicle } = useParams();
  const [pageSize, setPageSize] = useState(25);
  const [checkList, setCheckList] = useState([]);
  const [vehicleId, setVehicleId] = useState(0);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [disposed, setDisposed] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const columns = [
    {
      field: 'id', headerName: 'No.',
      headerAlign: "center", align: "center",
      width: 50,
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    { field: 'TaskName', headerName: 'Task Name', sortable: false, width: 500 },
    {
      field: 'Assign', headerName: 'Assign To',
      headerAlign: 'center', align: 'center',
      width: 150, sortable: false,
      renderCell: ({row}) => row?.Assign ?? row.Default_Assign
    },
    { field: 'Remarks', headerName: 'Remarks', sortable: false, width: 350 },
    {
      field: 'DoneAt', headerName: 'Done Date',
      headerAlign: "center", align: "center",
      type: 'date', width: 125, sortable: false,
      renderCell: ({row}) => row?.DoneAt ? moment(row.DoneAt).format("DD-MM-YYYY") : ''
    },
    {
      field: '', headerName: 'Action',
      headerAlign: "center", align: "center",
      width: 125, sortable: false,
      renderCell: ({row}) => (
        <Stack direction="row" spacing={1}>
          <SaveAction
            row={row} vehicleId={vehicleId}
            setMessage={setMessage} setOpenSnackbar={setOpenSnackbar}
            setCheckList={setCheckList}
          />
        </Stack>
      )
    },
  ];

  useEffect(() => {
    if (vehicle) {
      fetchEnhcApi("GetVehicle", { vehicle })
        .then(res => {
          if (res.length > 0) {
            setVehicleId(res[0].id);
            setVehicleDetails(res[0]);
            setDisposed(res[0].VehicleStatus === "DISPOSED");
            loadCheckList(vehicle, setCheckList);
          }
        })
        .catch(console.error);
    }
  }, []);

  const VehicleNotExists = () => (
    <Typography variant="h6" gutterBottom>
      Vehicle {vehicle.toUpperCase()} is not exists!
    </Typography>
  );

  const CheckList = () => {
    const onDispose = async (e) => {
      e.preventDefault();
      try {
        await fetchEnhcApi("DisposeVehicle", { id: vehicleId });
        setOpenSnackbar(true);
        setMessage("Vehicle Disposed done");
        setDisposed(true);
      } catch (error) {
        console.log(error);
      }
      setOpen(false);
    }

    return (
      <Stack spacing={3}>
        <DataGrid
          sx={{
            '& .MuiDataGrid-row': {
              whiteSpace: 'normal !important',
              lineHeight: 'normal',
              py: 1
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal !important',
              lineHeight: 'normal',
              py: 1
            }
          }}
          getRowHeight={() => 'auto'}
          rows={checkList} columns={columns}
          pageSize={pageSize} rowsPerPageOptions={pageOptions}
          onPageSizeChange={(size) => setPageSize(size)}
          autoHeight
        />
        <Modal
          open={open} onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: 550 }}>
            <Stack spacing={3}>
              <Typography sx={{ textAlign: 'center' }} variant="h4" gutterBottom>
                Do you want to mark vehicle disposed?
              </Typography>
              <Stack direction="row" sx={{ justifyContent: 'center' }} spacing={1}>
                <Button color="error" variant="contained" onClick={onDispose}>
                  Confirm
                </Button>
                <Button variant="contained" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>
        <Stack direction="row" sx={{ justifyContent: 'right' }}>
          <Button
            variant="contained"
            sx={{ maxWidth: 150, marginLeft: 'auto' }}
            onClick={() => setOpen(true)}
            disabled={disposed}
          >
            Mark Disposed
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Container maxWidth="xl">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(false)}
        message={message}
      />
      <Stack spacing={3}>
        <Typography style={{cursor: "pointer"}} variant="h4" gutterBottom onClick={() => navigate("../../disposing")}>
          <span style={{position: "relative", top: 3}}><ArrowBackSharpIcon /></span>&nbsp;&nbsp;Back To Listing
        </Typography>
        <Typography variant="h4" gutterBottom>
          Disposal {vehicle.toUpperCase()}
        </Typography>
        <Typography variant="h6" gutterBottom>
          COE End Date: {moment(vehicleDetails.CarEndDate, "YYYY-MM-DD").format("DD MMM YYYY")}
        </Typography>
        <Typography variant="h6" gutterBottom>
          FINAL DISPOSAL DATE: {moment(vehicleDetails.CarEndDate, "YYYY-MM-DD").add(29, "days").format("DD MMM YYYY")}
        </Typography>
        { checkList.length > 0 ? <CheckList/> : <VehicleNotExists/> }
      </Stack>
    </Container>
  );
}