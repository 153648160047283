import { FormikProvider, Form, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Grid, Stack, Button } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FcDateField, FcTextField } from '../FormFields';

export default function RefundVoucherFilterForm({ onReload }) {
  const formik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      bookingNo: ''
    },
    validationSchema: Yup.object({
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
      bookingNo: Yup.string().nullable()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await onReload(values);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        window.alert(error);
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDate" label="Refund Date From" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDate" label="Refund Date To" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="bookingNo" label="Booking No." />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} alignSelf="end">
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={formik.resetForm}>
                Clear
              </Button>
              <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                Search
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

RefundVoucherFilterForm.propTypes = { onReload: PropTypes.func };
