import React, {useEffect, useState} from "react";
import {Box, Button, Container, Modal, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {Stack} from "@material-ui/core";
import {LoadingButton} from "@material-ui/lab";
import PropTypes from "prop-types";
import {fetchEnhcApi} from "../../../utils/fetchApi";

const DeleteAction = ({row, setData}) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  const onDelete = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await fetchEnhcApi("DeleteDisposalStatus", {id: row.id});
      const data = await fetchEnhcApi("GetDisposalStatusList");
      setData(data);
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
    setOpen(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure want to delete this disposal status?
          </Typography>
          <Typography id="modal-modal-description" sx={{my: 2}}>
            {row.Status}
          </Typography>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={onDelete}
          >
            Delete
          </LoadingButton>
        </Box>
      </Modal>
      <Button variant="contained" onClick={() => handleOpen()}>Delete</Button>
    </div>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  setData: PropTypes.func
}

const EditAction = ({row}) => {
  const navigate = useNavigate();
  return (
    <Button variant="contained" onClick={() => navigate(`./edit/${row.id}`)}>Edit</Button>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
}

export default function VehicleDisposalStatus() {
  const pageOptions = [25];

  const [pageSize, setPageSize] = useState(25);
  const [data, setData] = useState([]);

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    {field: 'Status', headerName: 'Disposal Status', sortable: false, width: 750},
    {
      field: '',
      headerName: 'Action',
      width: 250,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({row}) => (
        <Stack direction="row" spacing={1}>
          <EditAction row={row} setData={setData}/>
          <DeleteAction row={row} setData={setData}/>
        </Stack>
      )
    },
  ];

  useEffect(() => {
    fetchEnhcApi("GetDisposalStatusList")
      .then(res => setData(res))
      .catch(console.error);
  }, []);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Disposal Status
        </Typography>

        <Button
          variant="contained"
          component={RouterLink}
          to="./new"
          startIcon={<Icon icon={plusFill}/>}
        >
          Add Status
        </Button>
      </Stack>
      <DataGrid
        sx={{
          "& .MuiDataGrid-row": {
            whiteSpace: "normal !important",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            lineHeight: "normal"
          },
        }}
        rows={data} columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={pageOptions}
        onPageSizeChange={size => setPageSize(size)}
        autoHeight
      />
    </Container>
  );
}