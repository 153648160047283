import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import PropTypes from "prop-types";

export default function PaymentMethodQuestionForm({ formik }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">What is the payment method?</Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup row name="paymentMethod" value={formik.values.paymentMethod} onChange={formik.handleChange}>
          <FormControlLabel control={<Radio/>} label="Prepaid" value="Prepaid"/>
          <FormControlLabel control={<Radio/>} label="Postpaid" value="Postpaid"/>
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

PaymentMethodQuestionForm.propTypes = {
  formik: PropTypes.object,
}