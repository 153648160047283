import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";

export default function CDWQuestionForm({ formik, cdwPrices }) {
  const [cdw, setCdw] = useState(formik.values.cdwPrice > 0 ? "yes" : "no");

  const handleSelect = e => {
    e.preventDefault();
    setCdw(e.target.value);
    formik.setFieldValue("cdwPrice", e.target.value === "yes" ? 150 : 0);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Is customer want to buy CDW?</Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup row value={cdw} onChange={handleSelect}>
          <FormControlLabel control={<Radio/>} label="Yes" value="yes"/>
          <FormControlLabel control={<Radio/>} label="No" value="no"/>
        </RadioGroup>
      </Grid>
      {cdw === "yes" && (
        <Grid item xs={12}>
          <RadioGroup
            name="cdwPrice"
            value={String(formik.values.cdwPrice)}
            onChange={e => formik.setFieldValue("cdwPrice", Number(e.target.value))}
          >
            {cdwPrices?.map(({ price }, index) => (
              <FormControlLabel key={index} control={<Radio />} label={`S$${price}`} value={price}/>
            ))}
          </RadioGroup>
        </Grid>
      )}
    </Grid>
  );
}

CDWQuestionForm.propTypes = {
  formik: PropTypes.object,
  cdwPrices: PropTypes.array,
}