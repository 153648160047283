import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useContext} from "react";
import * as Yup from "yup";
import DataContext from "../../../store/DataContext";
import {FcSelectField, FcTextField, SubmitBtnGroup} from "../FormFields";

export default function ShellCardFilterForm({ onReload }) {
  const dataCtx = useContext(DataContext);

  // TODO: add accounts in firestore
  const accounts = [
    { label: "SG10000201", value: "SG10000201" },
    { label: "SG00000016", value: "SG00000016" },
  ];

  // TODO: add payers in firestore
  const payers = [
    { label: "SG10000201", value: "SG10000201" },
    { label: "SG00000016", value: "SG00000016" },
  ];

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      payerNumber: "",
      pan: "",
    },
    validationSchema: Yup.object().shape({
      accountNumber: Yup.string().nullable(),
      payerNumber: Yup.string().nullable(),
      pan: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onReload(values);
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} label="Account No." name="accountNumber" items={accounts}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} label="Payer No." name="payerNumber" items={payers}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} label="PAN" name="pan"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Search" onCancel={formik.resetForm}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

ShellCardFilterForm.propTypes = {
  onReload: PropTypes.func,
}