import React, { isValidElement, useEffect, useState, useContext } from 'react';
import * as XLSX from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import {
  otrColumns,
  otrUndefinedColumns,
  priceIncreaseColumns,
  priceIncreaseUndefinedColumns
} from './ReportColumns';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import DataContext from '../../../store/DataContext';

const renderValue = (col, row, index) => {
  if (col.showIndex) return index + 1;
  if (col.renderCell) return col.renderCell({ row });
  return row[col.field];
};

const generateXLSX = (otrRows, priceIncreaseRows, dayjs, startDate) => {
  const salesPersons = [...new Set(otrRows.map((row) => row.staff))];

  const summaryWorksheetData = [];
  const otrUndefinedWorksheetData = [];
  const otrDefinedWorksheetData = [];
  const priceIncreaseUndefinedWorksheetData = [];
  const priceIncreasedefinedWorksheetData = [];

  salesPersons.forEach((salesPerson) => {
    const longTermRows = otrRows
      .filter(
        (row) =>
          row.staff === salesPerson &&
          row.contractType === 'LongTerm' &&
          row.newOrExistingCustomer === 'New' &&
          dayjs(row.startDate) >= dayjs(startDate).startOf('month')
      )
      .map((booking) => {
        const duration = dayjs(booking.endDate).diff(booking.startDate, 'day');
        const numberOfMonths = Math.floor(duration / 28);
        const longTerm = Math.min(numberOfMonths, 3);
        return { ...booking, numberOfMonths, longTerm };
      });

    const priceIncreaseUndefinedRows = priceIncreaseRows.filter(
      (row) => row.staff === salesPerson && row.contractType === 'Undefined'
    );

    const otrUndefinedRows = otrRows.filter(
      (row) => row.staff === salesPerson && row.contractType === 'Undefined'
    );

    const priceIncreaseDefinedRows = priceIncreaseRows.filter(
      (row) =>
        row.staff === salesPerson &&
        (row.contractType === 'LongTerm' || row.contractType === 'ShortTerm')
    );

    const otrDefinedRows = otrRows.filter(
      (row) =>
        row.staff === salesPerson &&
        (row.contractType === 'LongTerm' || row.contractType === 'ShortTerm')
    );

    summaryWorksheetData.push([]);
    summaryWorksheetData.push([salesPerson]);
    summaryWorksheetData.push([
      'OTR',
      otrDefinedRows.reduce((total, row) => {
        return total + row.points;
      }, 0)
    ]);
    summaryWorksheetData.push([
      'Long Term Points',
      longTermRows.reduce((total, row) => {
        return total + row.longTerm;
      }, 0)
    ]);
    summaryWorksheetData.push([
      'Price Increase Points',
      priceIncreaseDefinedRows.reduce((total, row) => {
        return total + row.points;
      }, 0)
    ]);

    // Build undefined and defined worksheet data
    const buildWorksheetData = (rows, worksheetData, columns) => {
      worksheetData.push([]);
      worksheetData.push(['', salesPerson]);
      worksheetData.push([]);
      worksheetData.push(columns.map((col) => col.headerName));
      rows.forEach((row, rowIndex) => {
        const exportRow = {};
        columns.forEach((col) => {
          const val = renderValue(col, row, rowIndex);
          if (!isValidElement(val)) exportRow[col.headerName] = val;
        });
        worksheetData.push(Object.values(exportRow));
      });
      worksheetData.push([]);
    };

    // for undefined (otr)
    buildWorksheetData(otrUndefinedRows, otrUndefinedWorksheetData, otrUndefinedColumns);
    // for defined (otr)
    buildWorksheetData(otrDefinedRows, otrDefinedWorksheetData, otrColumns);
    // for undefined (price increase)
    buildWorksheetData(
      priceIncreaseUndefinedRows,
      priceIncreaseUndefinedWorksheetData,
      priceIncreaseUndefinedColumns
    );
    // for defined (price increase)
    buildWorksheetData(
      priceIncreaseDefinedRows,
      priceIncreasedefinedWorksheetData,
      priceIncreaseColumns
    );
  });

  const otrUndefinedWorksheet = XLSX.utils.aoa_to_sheet(otrUndefinedWorksheetData);
  const otrDefinedWorksheet = XLSX.utils.aoa_to_sheet(otrDefinedWorksheetData);
  const priceIncreaseUndefinedWorksheet = XLSX.utils.aoa_to_sheet(
    priceIncreaseUndefinedWorksheetData
  );
  const priceIncreaseDefinedWorksheet = XLSX.utils.aoa_to_sheet(priceIncreasedefinedWorksheetData);
  const summaryWorksheet = XLSX.utils.aoa_to_sheet(summaryWorksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, summaryWorksheet, 'Summary');
  XLSX.utils.book_append_sheet(workbook, otrDefinedWorksheet, 'Defined (OTR)');
  XLSX.utils.book_append_sheet(workbook, priceIncreaseDefinedWorksheet, 'Defined (Price Increase)');
  XLSX.utils.book_append_sheet(workbook, otrUndefinedWorksheet, 'Undefined (OTR)');
  XLSX.utils.book_append_sheet(
    workbook,
    priceIncreaseUndefinedWorksheet,
    'Undefined (Price Increase)'
  );
  XLSX.writeFile(workbook, `${dayjs(startDate).format('MMMM YYYY')} Full OTR Report.xlsx`);
};

const FullReportExport = ({ dayjs, startDate }) => {
  const dataCtx = useContext(DataContext);
  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { data: rawOtrData, onGet: onGetOtrData } = useCloudFuncGet(
    `${cloudFunctionPath}/otr/list`,
    true,
    { startDate }
  );
  const { data: rawPriceIncreaseData, onGet: onGetPriceIncreaseData } = useCloudFuncGet(
    `${cloudFunctionPath}/otr/priceIncrease`,
    true,
    { startDate }
  );

  const [otrRows, setOtrRows] = useState([]);
  const [priceIncreaseRows, setPriceIncreaseRows] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const onReload = async () => {
    await onGetOtrData({ startDate });
    await onGetPriceIncreaseData({ startDate });
  };

  const onExport = (e) => {
    e.preventDefault();

    if (otrRows.length > 0 && priceIncreaseRows.length > 0) {
      generateXLSX(otrRows, priceIncreaseRows, dayjs, startDate);
    } else {
      onOpenSnackbar('Something went wrong...', 'error');
    }
  };

  useEffect(async () => {
    await onReload();
  }, [startDate]);

  useEffect(() => {
    setOtrRows([]);
    setPriceIncreaseRows([]);
    if (initialized) {
      if (rawOtrData?.data && rawPriceIncreaseData?.data) {
        setOtrRows(rawOtrData.data.flatMap((currentValue) => currentValue.data) || []);
        setPriceIncreaseRows(rawPriceIncreaseData?.data || []);
      }
    }
    setInitialized(true);
  }, [rawOtrData, rawPriceIncreaseData, startDate]);

  return (
    <>
      {otrRows.length > 0 && priceIncreaseRows.length > 0 && (
        <Button startIcon={<DownloadIcon />} onClick={onExport}>
          Download Full Report
        </Button>
      )}
    </>
  );
};

FullReportExport.propTypes = {
  dayjs: PropTypes.func,
  startDate: PropTypes.string
};

export default FullReportExport;
