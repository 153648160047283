import {DialogContent} from "@material-ui/core";
import {Box, Button, Dialog, DialogTitle, Stack} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import {StyledDataGrid} from "../../../../pages/Vehicle/styles";
import {getDayjsValue} from "../../../../utils/date";
import DisposalTaskCheckForm from "../../../Form/Vehicle/Disposal/DisposalTaskCheckForm";

const UpdateAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Update Task</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            {row && <DisposalTaskCheckForm data={row} onReload={onReload} onClose={() => setOpen(false)}/>}
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Update</Button>
    </>
  );
}

UpdateAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {

  return (
    <Stack direction="row" spacing={1}>
      <UpdateAction row={row} onReload={onReload}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function DisposalTaskCheckTable({ data, onReload }) {
  const columns = [
    {
      field: "task.task", headerName: "Task Name", align: "center", headerAlign: "center", width: 450,
      renderCell: ({ row }) => row?.task?.task,
    },
    { field: "assign", headerName: "Assign To", align: "center", headerAlign: "center", width: 150 },
    {
      field: "doneAt", headerName: "Done Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row?.doneAt ? getDayjsValue(row?.doneAt).format("DD MMM YYYY") : "",
    },
    { field: "remarks", headerName: "Remarks", align: "center", headerAlign: "center", width: 640 },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>
    },
  ];

  return (
    <StyledDataGrid columns={columns} rows={data ?? []} autoHeight/>
  );
}

DisposalTaskCheckTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}