import {LoadingButton} from "@material-ui/lab";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncDelete from "../../../hooks/firebase/cloudFunction/useCloudFuncDelete";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import DataContext from "../../../store/DataContext";
import PublicHolidayForm from "../../Form/Leave/PublicHolidayForm";

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useCloudFuncDelete(`${cloudFunctionPath}/holiday/delete`);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onClick = async e => {
    e.preventDefault();
    try {
      await onDelete({ holidayId: row?.id });
      onOpenSnackbar("Public holiday deleted successfully!");
      await onReload();
    } catch (error) {
      onOpenSnackbar(error.message, "error");
    }
    setOpen(false);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box p={2}>
          <DialogTitle>Do you want to delete {row?.title}?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpen(false)} type="submit" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              variant="contained" color="error"
              loading={loading} onClick={onClick}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const EditAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Public Holiday</DialogTitle>
        <DialogContent>
          <PublicHolidayForm data={row} onReload={onReload} onClose={() => setOpen(false)}/>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Edit
      </Button>
    </>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" spacing={2}>
      <EditAction row={row} onReload={onReload}/>
      <DeleteAction row={row} onReload={onReload}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function PublicHolidayTable({ data, onReload }) {
  const columns = [
    {
      field: 'id', headerName: 'No.',
      align: 'center', headerAlign: 'center',
      width: 50, sortable: false,
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    {
      field: 'title', headerName: 'Title',
      align: 'center', headerAlign: 'center',
      width: 250, sortable: false,
    },
    {
      field: 'startDate', headerName: 'Start Date',
      align: 'center', headerAlign: 'center',
      width: 150, sortable: false,
      valueFormatter: ({ value }) => moment(value).format("DD MMM YYYY"),
    },
    {
      field: 'endDate', headerName: 'End Date',
      align: 'center', headerAlign: 'center',
      width: 150, sortable: false,
      valueFormatter: ({ value }) => moment(value).format("DD MMM YYYY"),
    },
    {
      field: '', headerName: 'Action',
      align: 'center', headerAlign: 'center',
      width: 300,
      renderCell: ({row}) => <ActionCol row={row} onReload={onReload}/>
    }
  ];

  return (
    <StyledDataGrid columns={columns} rows={data ?? []} autoHeight/>
  );
}

PublicHolidayTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}