import PropTypes from 'prop-types';
// material
import { Box, Card, Typography, Stack, Chip } from '@mui/material';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
// utils
import { fCurrency } from '../../../utils/formatNumber';
//
// import Label from '../../Label';
// import ColorPreview from '../../ColorPreview';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const { favourites, make, model, gen, rentalRates, imageLinks, propertyBadges } = product;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )} */}
        <Stack direction="column" spacing={5}>
          {propertyBadges.map((el) => (
            <Chip
              key={el.label}
              label={el.label}
              variant="filled"
              sx={{
                backgroundColor: `#${el.colour.slice(2)}`,
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase'
              }}
            />
          ))}
        </Stack>
        <ProductImgStyle alt={`${make} ${model} ${gen}`} src={imageLinks[0]} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="subtitle2" noWrap>
          {make} {model}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          Gen {gen}
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1">
            {/* <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through'
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography> */}
            {fCurrency(rentalRates.daily)} per day
          </Typography>
          <Stack direction="row">
            <FavoriteIcon sx={{ color: 'red' }} />
            &nbsp;
            {favourites}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
