import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import authContext from '../../../store/AuthContext';
import workshopJobJsonToExcel from '../../../utils/workshopJobJsonToExcel';
import JobAcc from '../../../components/Workshop/Job/JobAcc';
import ReturnJobDisplayItem from '../../../components/Workshop/ReturnJobDisplayItem';

export default function ViewJobWorkshop() {
  const navigate = useNavigate();
  const auth = useContext(authContext);
  const { data: jobData, onGet: onGetJob } = useCloudFuncGet(`${cloudFunctionPath}/workshop/job/u/${auth.name.replaceAll(" ", "-")}`);

  const queryObj = { mechanic: auth.name };
  const { data: returnJobData, onGet: onGetReturnJob } = useCloudFuncGet(`${cloudFunctionPath}/workshop/return_job`, true, queryObj);
  const [filteredData, setFilteredData] = useState(jobData.data ?? []);

  const onReload = async () => {
    await onGetJob();
    await onGetReturnJob();
  }

  useEffect(() => {
    setFilteredData(jobData.data ?? []);
  }, [jobData.data]);

  return (
    <Box sx={{ padding: { xs: 1, md: 0 } }}>
      <Typography
        variant="h4"
        gutterBottom
        mb={3}
        sx={{ textAlign: { xs: 'center', md: 'start' }, fontSize: { xs: 26, md: 24 } }}
      >
        Viewing Jobs
      </Typography>

      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} md="auto">
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate("./new")}
          >
            Create New Job
          </Button>
        </Grid>

        {/* <Grid item xs={12} md="auto"> */}
        {/*  <Button */}
        {/*    fullWidth */}
        {/*    variant="contained" */}
        {/*    onClick={() => navigate("./assigned")} */}
        {/*  > */}
        {/*    View Assigned Jobs */}
        {/*  </Button> */}
        {/* </Grid> */}
      </Grid>

      <Grid item xs={12} md="auto">
        <Button
          fullWidth
          variant="contained"
          onClick={() => workshopJobJsonToExcel(jobData.data)}
        >
          Print
        </Button>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Search Vehicle Number"
          onChange={(value) => {
            setFilteredData(jobData.data.filter((job) => job.vehicleNumber.toLowerCase().includes(value.target.value.toLowerCase())));
          }}
          sx={{ marginTop: 1, marginBottom: 2 }}
        />
      </Grid>

      <Typography fontSize={18} fontWeight={700} color="red" ml={1} mb={1}>RETURNED</Typography>
      {
        returnJobData.data?.map((returnJob) => <ReturnJobDisplayItem key={returnJob.id} returnJob={returnJob} />)
      }

      <Typography fontSize={18} fontWeight={700} color="orange" mt={3} ml={1}>ONGOING</Typography>
      {
        filteredData
          .filter((job) => job.completed === false)
          .map((job) => <JobAcc key={job.id} data={job} onReload={onReload}/>)
      }

      <Typography fontSize={18} fontWeight={700} color="green" mt={3} ml={1}>COMPLETED</Typography>
      {
        [... new Set(filteredData.map((doc) => doc.createDay))]
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
          .map((createDay) => {
            const filtered = filteredData.filter((doc) => doc.createDay === createDay && doc.completed !== false);
            return (
              <Accordion key={createDay} elevation={10} defaultExpanded={new Date(new Date().toDateString()).getTime() === createDay}>
                <AccordionSummary>
                  <Typography fontSize={18} fontWeight={700} textAlign="start">
                    {dayjs(createDay).format("ddd, D MMM YYYY")}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ marginTop: -1.5 }}>
                  { filtered.sort((a, b) => a.createdAt - b.createdAt).map((job) => <JobAcc key={job.id} data={job} onReload={onReload}/>) }
                </AccordionDetails>
              </Accordion>
            );
        })
      }
    </Box>
  );
}