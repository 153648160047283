import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportAgreementVehicleConditionsForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);
  const { onPost, loading } = useHttpPost("/api/agreement/vehicle-condition/import");

  const columns = [
    { field: "checked", headerName: "Checked", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "agreementNo", headerName: "Agreement No", width: 150 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    checked: row.checked === '1',
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Agreements Vehicle Conditions" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

export default ImportAgreementVehicleConditionsForm;