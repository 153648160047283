import {setupBookingRolePermissions} from "./permissions/bookingPermission";
import {setupCustomerRolePermissions} from "./permissions/customerPermissions";
import {setupInvoiceRolePermissions} from "./permissions/invoicePermissions";
import {setupVehicleRolePermissions} from "./permissions/vehiclePermissions";

export const setupPermissions = (ROLES) => ({
  [ROLES.SUPER_ADMIN]: [
    ...setupBookingRolePermissions(ROLES)[ROLES.SUPER_ADMIN],
    ...setupCustomerRolePermissions(ROLES)[ROLES.SUPER_ADMIN],
    ...setupVehicleRolePermissions(ROLES)[ROLES.SUPER_ADMIN],
    ...setupInvoiceRolePermissions(ROLES)[ROLES.SUPER_ADMIN],
  ],
  [ROLES.MANAGER]: [
    ...setupBookingRolePermissions(ROLES)[ROLES.MANAGER],
    ...setupCustomerRolePermissions(ROLES)[ROLES.MANAGER],
    ...setupVehicleRolePermissions(ROLES)[ROLES.MANAGER],
    ...setupInvoiceRolePermissions(ROLES)[ROLES.MANAGER],
  ],
  [ROLES.ACCOUNT]: [
    ...setupBookingRolePermissions(ROLES)[ROLES.ACCOUNT],
    ...setupCustomerRolePermissions(ROLES)[ROLES.ACCOUNT],
    ...setupVehicleRolePermissions(ROLES)[ROLES.ACCOUNT],
    ...setupInvoiceRolePermissions(ROLES)[ROLES.ACCOUNT],
  ],
  [ROLES.SALES]: [
    ...setupBookingRolePermissions(ROLES)[ROLES.SALES],
    ...setupCustomerRolePermissions(ROLES)[ROLES.SALES],
    ...setupVehicleRolePermissions(ROLES)[ROLES.SALES],
    ...setupInvoiceRolePermissions(ROLES)[ROLES.SALES],
  ],
  [ROLES.OPERATION]: [
    ...setupBookingRolePermissions(ROLES)[ROLES.OPERATION],
    ...setupCustomerRolePermissions(ROLES)[ROLES.OPERATION],
    ...setupVehicleRolePermissions(ROLES)[ROLES.OPERATION],
    ...setupInvoiceRolePermissions(ROLES)[ROLES.OPERATION],
  ],
  [ROLES.MARKETING]: [
    ...setupBookingRolePermissions(ROLES)[ROLES.MARKETING],
    ...setupCustomerRolePermissions(ROLES)[ROLES.MARKETING],
    ...setupVehicleRolePermissions(ROLES)[ROLES.MARKETING],
    ...setupInvoiceRolePermissions(ROLES)[ROLES.MARKETING],
  ],
});
