import {Box, Grid, Stack, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import {useContext, useEffect, useState} from "react";
import * as Yup from "yup";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import {FcDateField, FcTextField, SubmitBtnGroup} from "../../../components/Form/FormFields";
import ShellRefuelTransactionTable from "../../../components/Table/ShellCard/ShellRefuelTransactionTable";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";
import DataContext from "../../../store/DataContext";

const FilterForm = ({ handleSearch }) => {
  const formik = useFormik({
    initialValues: {
      pan: "",
      startDate: moment().add(-1, "month").startOf("day").format(),
      endDate: moment().add(1, "day").startOf("day").format(),
      customerName: "",
    },
    validationSchema: Yup.object({
      pan: Yup.string().nullable(),
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
      customerName: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await handleSearch({
        ...values,
        startDate: values?.startDate ? values.startDate.format() : "",
        endDate: values?.endDate ? values.endDate.format() : "",
      });
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="pan" label="PAN"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDate" label="Start Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDate" label="End Date"/>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerName" label="Customer Name"/>
          </Grid> */}
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Search" onCancel={formik.resetForm} cancelText="Clear"/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

FilterForm.propTypes = {
  handleSearch: PropTypes.func,
}

export default function ShellRefuelTransactionListingTab() {
  const dataCtx = useContext(DataContext);

  const [values, setValues] = useState({
    startDate: moment().add(-1, "month").startOf("day").format(),
    endDate: moment().add(1, "day").startOf("day").format(),
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 100,
  });
  const { data, loading, onGet } = useCloudFuncGet(`${cloudFunctionPath}/wallet/transaction/shell`, false);

  const handleSearch = async values => {
    try {
      await onGet({ ...values, ...paginationModel });
      setValues(values);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  useEffect(() => {
    onGet({ ...values, ...paginationModel });
  }, [paginationModel]);

  return (
    <>
      <Box mb={3}>
        <FilterForm handleSearch={handleSearch}/>
      </Box>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Refuel Transactions</Typography>
      </Stack>
      <ShellRefuelTransactionTable
        data={data?.data?.transactions}
        onReload={onGet}
        loading={loading}
        rowCount={data?.data?.rowCount}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  );
}