import {LoadingButton} from "@material-ui/lab";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import {VEHICLE_INSURANCE_PERMISSIONS} from "../../../utils/authorization/permissions/vehiclePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import {getPdf} from "../../../utils/getFile";
import InsuranceDialogDetails from '../../Details/Vehicle/InsuranceDialogDetails';
import VehicleInsuranceForm from '../../Form/Vehicle/VehicleInsuranceForm';
import useHttpGet from '../../../hooks/http/useHttpGet';

const ViewInsurance = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({
      open: true,
      message,
      severity,
    });
  }

  const Details = () => (
    <Box sx={{ p: 2 }}>
      <DialogTitle>Insurance Info</DialogTitle>
      <DialogContent>
        <InsuranceDialogDetails row={row} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setEdit(true)}>
          Edit
        </Button>
      </DialogActions>
    </Box>
  );

  const EditInsurance = () => {
    const { data: companies } = useHttpGet("/api/insurances/companies");

    return (
      <Box sx={{ p: 2 }}>
        <DialogTitle>Edit Insurance</DialogTitle>
        <DialogContent>
          <VehicleInsuranceForm
            data={row} onReload={onReload}
            companies={companies} onClose={() => setEdit(false)}
          />
        </DialogContent>
      </Box>
    );
  };

  return (
    <>
      <Dialog
        open={open} onClose={() => setOpen(false)}
        fullWidth maxWidth="lg"
      >
        {isEdit ? <EditInsurance/> : <Details/>}
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        View
      </Button>
    </>
  );
}

ViewInsurance.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const { onDelete, loading } = useHttpDelete(`/api/insurances/${row?.id}`);
  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({
      open: true,
      message,
      severity,
    });
  }

  const onClick = async e => {
    e.preventDefault();
    try {
      await onDelete();
      onOpenSnackbar("Insurance deleted successfully!");
      await onReload();
    } catch (error) {
      onOpenSnackbar(e.message, "error");
    }
    setOpen(false);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 2 }}>
          <DialogTitle>Do you want to delete insurance?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpen(false)} type="submit" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              variant="contained" color="error"
              loading={loading} onClick={onClick}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" spacing={2}>
      <ViewInsurance row={row} onReload={onReload}/>
      <Button
        variant="contained" color="secondary"
        component={Link} to={getPdf(row?.insuranceCi)} target="_blank"
      >
        PDF
      </Button>
      {permissionRequired(authCtx, VEHICLE_INSURANCE_PERMISSIONS.DELETE) && <DeleteAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function InsuranceTable({ data, onReload }) {

  const columns = [
    {
      field: 'id', headerName: 'No.',
      align: 'center', headerAlign: 'center',
      width: 50, sortable: false,
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    {
      field: 'company', headerName: 'Company Name',
      align: 'center', headerAlign: 'center',
      width: 350,
      valueFormatter: ({ value }) => value?.name,
    },
    {
      field: 'startDate', headerName: 'Start Date',
      align: 'center', headerAlign: 'center',
      width: 125,
      renderCell: ({row}) => moment(row.startDate).format("DD MMM YYYY")
    },
    {
      field: 'endDate', headerName: 'End Date',
      align: 'center', headerAlign: 'center',
      width: 125,
      renderCell: ({row}) => moment(row.endDate).format("DD MMM YYYY")
    },
    {
      field: 'isFleet', headerName: 'Fleet / Individual',
      align: 'center', headerAlign: 'center',
      width: 150,
      renderCell: ({row}) => row.isFleet ? "Fleet" : "Individual"
    },
    {
      field: 'isZ10Z11', headerName: 'Insurance Type',
      align: 'center', headerAlign: 'center',
      width: 150,
      renderCell: ({row}) => row.isZ10Z11 ? "Z10/Z11" : "Other"
    },
    {
      field: 'minAgeCoverage', headerName: 'Min Age Coverage',
      align: 'center', headerAlign: 'center',
      width: 170,
    },
    {
      field: 'maxAgeCoverage', headerName: 'Max Age Coverage',
      align: 'center', headerAlign: 'center',
      width: 170,
    },
    {
      field: '', headerName: 'Action',
      align: 'center', headerAlign: 'center',
      width: 300,
      renderCell: ({row}) => <ActionCol row={row} onReload={onReload}/>
    }
  ];

  return (
    <Box>
      <StyledDataGrid
        components={{ Toolbar: GridToolbar }}
        columns={columns} rows={data}
        pageSize={10} rowsPerPageOptions={[10]}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
}

InsuranceTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}