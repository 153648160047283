import {Box, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import {getDayjsValue} from "../../utils/date";

/*
 * data API:
 *  - label
 *  - data:
 *    - startDate
 *    - endDate nullable
 *    - backgroundColor
 *    - description
 *    - hoverComponent
 *    - url
 *    - metadata
 */
export default function FcGanttChart({ data, range, width }) {
  const headerRowHeight = 60;
  const barHeight = 20;
  const borderColor = "#9E9E9E";

  const labelRef = useRef(null);
  const contentRef = useRef(null);

  const [labelWidth, setLabelWidth] = useState(128);
  const [contentWidth, setContentWidth] = useState(1024);

  const updateWidth = () => {
    setLabelWidth(labelRef.current?.offsetWidth ?? 128);
    setContentWidth(contentRef.current?.offsetWidth < 1024 ? 1024 : contentRef.current?.offsetWidth);
  }

  let len = 0;
  const dates = [];
  for (const date = new Date(range[0]); date <= new Date(range[1]); date.setDate(date.getDate() + 1)) {
    len++;
    dates.push(new Date(date));
  }

  const records = data?.map((vehicle, index) => {
    const width = contentWidth / len;
    const height = (vehicle.data.length > 0 ? vehicle.data.length : 1) * barHeight;

    const grids = dates.map((date, index) => (
      <Box
        key={index}
        width={width} height={height} minHeight={26}
        border={1} borderColor={borderColor}
      />
    ));

    const RangeBar = ({record, top = 0}) => {
      const [isHovering, setHovering] = useState(false);

      const startDate = dayjs(range[0]) > getDayjsValue(record.startDate) ? dayjs(range[0]) : getDayjsValue(record.startDate);

      const diff = record?.endDate ? getDayjsValue(record.endDate).diff(startDate, "days") + 1 : 35;
      const offset = record?.startDate ? getDayjsValue(record.startDate).diff(range[0], "days") : 0;

      const onClick = e => {
        e.preventDefault();
        if (record?.url) window.open(`${record.url}`, "_blank");
      }

      return (
        <>
          <Box
            position="absolute"
            left={labelWidth + (offset <= 0 ? 0 : offset * width)} top={top}
            width={diff * width} height={barHeight}
            color="white" fontWeight={800}
            sx={{
              backgroundColor: record.backgroundColor,
              px: 1,
              zIndex: 2,
            }}
          >
            <Stack
              direction="row" height="100%" alignItems="center"
              onMouseEnter={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}
              onClick={onClick}
              sx={{ cursor: "pointer" }}
            >
              <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {record.description}
              </Box>
            </Stack>
          </Box>
          {record?.hoverComponent !== null && (
            <Box
              sx={{
                display: isHovering ? "block" : "none",
                backgroundColor: "white", color: "black",
                p: 1, borderRadius: 1, boxShadow: 5,
                position: "absolute",
                top: top + barHeight, left: labelWidth + (offset <= 0 ? 0 : offset * width),
                zIndex: 5,
              }}
            >
              {record.hoverComponent}
            </Box>
          )}
        </>
      );
    };

    RangeBar.propTypes = {
      record: PropTypes.object,
      top: PropTypes.number,
    }

    return (
      <Box position="relative" key={index}>
        <Stack direction="row">
          <Stack direction="row" width="10%" justifyContent="center" alignItems="center" border={1} borderColor={borderColor}>
            <Typography variant="subtitle1">{vehicle.label}</Typography>
          </Stack>
          <Stack direction="row" width="90%">
            {grids}
            {vehicle.data.map((record, index) => (
              <RangeBar key={index} record={record} top={index * barHeight}/>
            ))}
          </Stack>
        </Stack>
      </Box>
    );
  }) ?? [];

  useEffect(() => {
    updateWidth()
  }, [contentRef.current?.offsetWidth, labelRef.current?.offsetWidth]);

  const Header = () => (
    <Stack
      direction="row"
      sx={{
        position: "sticky",
        top: 0, left: 0,
        backgroundColor: "white",
        zIndex: 30,
      }}
    >
      <Box ref={labelRef} width="10%" border={1} borderColor={borderColor} height={headerRowHeight}>
        <Stack direction="row" justifyContent="center" alignItems="center" height="100%">
          <Typography variant="h6">Vehicle No.</Typography>
        </Stack>
      </Box>
      <Box ref={contentRef} width="90%">
        <Stack direction="row" height={headerRowHeight / 2} justifyContent="center" alignItems="center" border={1} borderColor={borderColor}>
          <Typography variant="subtitle1">{dayjs(dates[0]).format("MMM YYYY")}</Typography>
        </Stack>
        <Stack direction="row" height={headerRowHeight / 2}>
          {dates.map((date, index) => (
            <Stack
              key={index}
              direction="row" justifyContent="center" alignItems="center"
              width={width} height="100%"
              border={1} borderColor={borderColor}
            >
              {dayjs(date).format("DD")}
            </Stack>
          ))}
        </Stack>
      </Box>
    </Stack>
  );

  return (
    <Box
      position="relative"
      height="75vh" width={width - 20}
      sx={{ overflowX: "hidden", overflowY: "auto" }}
    >
      <Header/>
      {records}
    </Box>
  );
}

FcGanttChart.propTypes = {
  data: PropTypes.array,
  range: PropTypes.array,
  width: PropTypes.number,
}