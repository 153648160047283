import {Box, Grid, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {getDayjsValue} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";

export default function RefundVoucherDetails({ refundVoucher }) {
  const details = [
    { label: "Title", value: refundVoucher?.title, props: { xs: 12, sm: 6 } },
    { label: "Reason", value: refundVoucher?.reason, props: { xs: 12, sm: 6 } },
    { label: "Refund Date", value: getDayjsValue(refundVoucher?.refundDate).format("DD MMM YYYY") },
    { label: "Refund Amount", value: `S$${refundVoucher?.amount}` },
    { label: "Payment Method", value: refundVoucher?.paymentMethod },
    { label: "Company", value: refundVoucher?.companyName },
  ];

  if (refundVoucher?.remarks) details.push({ label: "Remarks", value: refundVoucher?.remarks, props: { xs: 12 } });

  if (refundVoucher?.salesSignature !== null && refundVoucher.salesSignature !== "" && refundVoucher?.customerSignature !== null && refundVoucher.customerSignature !== "") {
    details.push({
      label: "Sales Signature",
      value: <Box component="img" src={getFile(refundVoucher.salesSignature)} border={1} borderRadius={2} mt={1}/>,
      props: { xs: 12, sm: 6 },
    });
    details.push({
      label: "Customer Signature",
      value: <Box component="img" src={getFile(refundVoucher.customerSignature)} border={1} borderRadius={2} mt={1}/>,
      props: { xs: 12, sm: 6 },
    });
  }

  if (refundVoucher?.evidenceAttachment !== null && refundVoucher.evidenceAttachment !== "") {
    details.push({
      label: "Evidence Attachment",
      value: <Link to={getFile(refundVoucher?.evidenceAttachment)} target="_blank">View File</Link>,
      props: { xs: 12 },
    });
  }

  return (
    <Grid container spacing={3}>
      {details.map(({ label, value, props = { xs: 12, sm: 6, md: 4, lg: 3 } }, index) => (
        <Grid item key={index} {...props}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
    </Grid>
  );
}

RefundVoucherDetails.propTypes = {
  refundVoucher: PropTypes.object,
}