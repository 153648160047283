import PropTypes from "prop-types"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@material-ui/core';
import dayjs from 'dayjs';
import useCloudFuncPost from '../../hooks/firebase/cloudFunction/useCloudFuncPost';
import DataContext from '../../store/DataContext';
import authContext from '../../store/AuthContext';

export default function WorkshopRequestAcc({ request, onReload }) {
  const dataCtx = useContext(DataContext);
  const authCtx = useContext(authContext);
  const { onPost } = useCloudFuncPost(`/workshop/request/complete/${request.id}`);
  const [openComplete, setOpenComplete] = useState(false);
  const [openUncomplete, setOpenUncomplete] = useState(false);

  const [remark, setRemark] = useState("");

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const completeOnClick = async () => {
    try {
      await onPost({
        completed: true,
        remark: remark,
        completedBy: authCtx.name
      });
      onOpenSnackbar(`Successfully Completed ${request.vehicleNumber}`)
      setOpenComplete(false);
      onReload();
    } catch (error) {
      console.log(error.message)
      onOpenSnackbar(error.message, "error");
    }
  }

  const uncompleteOnClick = async () => {
    try {
      if (authCtx.name !== request.completedBy) {
        onOpenSnackbar("The request can only be un-completed by who completed it", "error");
        return;
      }
      await onPost({ completed: false });
      onOpenSnackbar(`Successfully Un-Completed ${request.vehicleNumber}`);
      onReload();
    } catch (error) {
      console.log(error.message)
      onOpenSnackbar(error.message, "error");
    }
  }

  return (
    <>
      <Dialog open={openComplete} onClose={() => setOpenComplete(false)} fullWidth>
        <DialogTitle>
          <Typography variant="h4">
            Completing {request.vehicleNumber}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField label="Remarks" fullWidth sx={{ marginTop: 1 }}
                     value={remark} onChange={(value) => setRemark(value.target.value)} />
          <Button
            variant="contained"
            fullWidth
            onClick={completeOnClick}
            sx={{ marginTop: 2 }}
          >Complete</Button>
        </DialogContent>
      </Dialog>

      <Dialog open={openUncomplete} onClose={() => setOpenUncomplete(false)} fullWidth>
        <DialogTitle>
          <Typography variant="h4">
            Un-complete {request.vehicleNumber}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Button
            fullWidth
            color="error"
            variant="contained"
            onClick={uncompleteOnClick}
            sx={{ marginTop: 2 }}
          >Un-Complete</Button>
        </DialogContent>
      </Dialog>

      <Accordion defaultExpanded elevation={10}>
        <AccordionSummary>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={18} fontWeight={700}>
                {request.assigner} | {request.vehicleNumber}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {request.updatedAt !== request.createdAt && `Updated at ${dayjs(request.updatedAt).format("ddd, D MMM | HH:mm:ss")}`}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails sx={{ marginTop: -1 }}>
          { request.task.map((task) => <Typography>{task.name}</Typography>) }
          { request.other && <Typography mt={1}><b>Others:</b> {request.other}</Typography> }
          { request.assignerRemark && <Typography><b>Remarks:</b> {request.assignerRemark}</Typography> }

          {
            request.completed &&
            <>
              <Divider sx={{ mt: 1, mb: 1 }} />

              <Typography>
                Completed by {request.completedBy} at <br /> {dayjs(request.updatedAt).format('ddd, D MMM | HH:mm:ss')}
              </Typography>
              {
                request.remark &&
                <Typography>
                  <b>Remarks:</b> {request.remark}
                </Typography>
              }
            </>
          }
          <Button
            variant="contained"
            fullWidth
            color={ request.completed ? "primary" : "error" }
            onClick={ () => request.completed ? setOpenUncomplete(true) : setOpenComplete(true) }
            sx={{ marginTop: 2 }}
          >
            { request.completed ? "Completed" : "Ongoing" }
          </Button>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

WorkshopRequestAcc.propTypes = {
  request: PropTypes.object,
  onReload: PropTypes.func
}