import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Stack, Typography } from '@mui/material';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import WorkshopVehicleForm from '../../../components/Form/Workshop/WorkshopVehicleForm';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import BackButton from '../../../components/BackButton';

export default function EditWorkshopVehicle() {
  const navigate = useNavigate();
  const { vehicleNumber } = useParams();
  const { data } = useCloudFuncGet(`${cloudFunctionPath}/workshop/vehicle/${vehicleNumber}`);

  return (
    <Container maxWidth="xl">
      <BackButton />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>{`Updating Workshop Vehicle No. ${vehicleNumber}`}</Typography>
      </Stack>

      <WorkshopVehicleForm data={data.data} onCancel={() => navigate("../")} />
    </Container>
  );
}