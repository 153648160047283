import { LoadingButton } from "@material-ui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { cloudFunctionPath } from "../../../cloudFunctionApiConfig";
import useCloudFuncDelete from "../../../hooks/firebase/cloudFunction/useCloudFuncDelete";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import useCloudFuncPut from "../../../hooks/firebase/cloudFunction/useCloudFuncPut";
import useGetCollections from "../../../hooks/firebase/useGetCollections";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { fetchEnhcApi } from "../../../utils/fetchApi";

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useCloudFuncDelete(`${cloudFunctionPath}/otr/accident/${row.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Delete successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Delete record?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const AgreeAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading } = useCloudFuncPut(`${cloudFunctionPath}/otr/accident/${row?.id}/status`);

  const onAgree = async e => {
    e.preventDefault();
    try {
      await onPut({ status: "agreed", reason: "" });
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Record agreed", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Agree record?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" onClick={onAgree}>
            Agree
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Agree
      </Button>
    </>
  );
}

AgreeAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DisagreeAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading } = useCloudFuncPut(`${cloudFunctionPath}/otr/accident/${row?.id}/status`);

  const onDisagree = async e => {
    e.preventDefault();
    try {
      await onPut({ status: "disagreed", reason: "" });
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Record disagreed", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Disagree record?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onDisagree}>
            Disagree
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Disagree
      </Button>
    </>
  );
}

DisagreeAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ConfirmActionCol = ({ row, onReload, type = 'account' }) => {
  const authCtx = useContext(AuthContext);

  const SalesAction = () => {
    return (
      <>
        <DeleteAction row={row} onReload={onReload} />
      </>
    );
  }

  const AccountAction = () => {
    if (type === "manager" && authCtx.userDept === "accounts") return "";
    return (
      <>
        <AgreeAction row={row} onReload={onReload} />
        <DisagreeAction row={row} onReload={onReload} />
      </>
    );
  }

  return (
    <Stack direction="row" spacing={1}>
      {authCtx.userDept === "sales" && <SalesAction />}
      {authCtx.userDept !== "sales" && <AccountAction />}
    </Stack>
  );
}

ConfirmActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const CheckAndApproveAccidentTable = ({ data, loading, onReload, type = "account" }) => {
  const { data: users } = useGetCollections("fcadmin/fcadmin/users");
  const userMap = {};
  users.forEach(user => userMap[user.uid] = user);

  const columns = [
    { field: "accidentNo", headerName: "Accident No.", align: "center", headerAlign: "center", width: 150 },
    {
      field: "accidentDateTime", headerName: "Accident Date", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => dayjs(row.accidentDateTime).format("DD MMM YYYY hh:mm A"),
    },
    { field: "hirerName", headerName: "Hirer Name", align: "center", headerAlign: "center", width: 250 },
    { field: "hirerHP", headerName: "Hirer Phone", align: "center", headerAlign: "center", width: 150 },
    { field: "carPlate", headerName: "Vehicle No.", align: "center", headerAlign: "center", width: 150 },
    { field: "accidentStatus", headerName: "Accident Status", align: "center", headerAlign: "center", width: 150 },
    { field: "insurance", headerName: "Insurance", align: "center", headerAlign: "center", width: 350 },
    { field: "companyName", headerName: "Company Name", align: "center", headerAlign: "center", width: 150 },
    {
      field: "userId", headerName: "Created By", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => userMap[row.userId]?.name,
    },
    {
      field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => (type !== "account" && !row.isManagerApproved ? "Account " : "") + capitalizeFirstLetter(row.status),
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 250,
      renderCell: ({ row }) => <ConfirmActionCol row={row} onReload={onReload} type={type}/>,
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      columns={columns} rows={data ?? []}
      loading={loading}
      autoHeight
      disableSelectionOnClick
    />
  );
}

CheckAndApproveAccidentTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
}

const AccidentActionCol = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const { onPost, loading } = useCloudFuncPost(`${cloudFunctionPath}/otr/accident`);

  const onClaim = async e => {
    e.preventDefault();
    try {
      await onPost({
        accidentNo: row?.AccidentNo,
        accidentDateTime: row?.AccidentDateTime.toISOString(),
        accidentDate: row?.AccidentDate.toISOString(),
        hirerName: row?.HirerName,
        hirerHP: row?.HirerHP,
        carPlate: row?.Carplate,
        accidentStatus: row?.AccidentStatus,
        insurance: row?.Insurance,
        companyName: row?.CompanyName,
        userId: await getAuth().currentUser.uid,
      });
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Added", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <Stack>
      <LoadingButton loading={loading} variant="contained" onClick={onClaim}>Claim</LoadingButton>
    </Stack>
  );
}

AccidentActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const AccidentTable = ({ data, loading, onReload }) => {
  const columns = [
    { field: "AccidentNo", headerName: "Accident No", align: "center", headerAlign: "center", width: 150 },
    {
      field: "AccidentDateTime", headerName: "Accident Date", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => dayjs(row.AccidentDateTime).format("DD MMM YYYY hh:mm A"),
    },
    { field: "HirerName", headerName: "Hirer Name", align: "center", headerAlign: "center", width: 250 },
    { field: "HirerHP", headerName: "Hirer Phone", align: "center", headerAlign: "center", width: 150 },
    { field: "Carplate", headerName: "Vehicle No.", align: "center", headerAlign: "center", width: 150 },
    { field: "AccidentStatus", headerName: "Accident Status", align: "center", headerAlign: "center", width: 150 },
    { field: "Insurance", headerName: "Insurance", align: "center", headerAlign: "center", width: 350 },
    { field: "CompanyName", headerName: "Company Name", align: "center", headerAlign: "center", width: 150 },
    { field: "CreateBy", headerName: "Created By", align: "center", headerAlign: "center", width: 150 },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => <AccidentActionCol row={row} onReload={onReload} />
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      columns={columns} rows={data ?? []}
      loading={loading}
      autoHeight
      disableSelectionOnClick
    />
  );
}

AccidentTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
}

const Filter = ({ filter, handleFilterChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              view="month"
              disableMaskedInput
              label="Date"
              inputFormat="MM-YYYY"
              onChange={(val) => handleFilterChange("date", val)}
              value={filter?.date}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
    </Grid>
  );
}

Filter.propTypes = {
  filter: PropTypes.object,
  handleFilterChange: PropTypes.func,
}

const AccidentPrivateSettlement = () => {
  const [filter, setFilter] = useState({
    date: dayjs().startOf("month"),
  });
  const [loading, setLoading] = useState(false);
  const [accidents, setAccidents] = useState([]);

  const { data, loading: confirmLoading, onGet } = useCloudFuncGet(`${cloudFunctionPath}/otr/accident`);

  const handleFilterChange = (field, value) => {
    setFilter({ ...filter, [field]: value });
  }

  useEffect(() => {
    setLoading(true);
    fetchEnhcApi("GetAllAccidentListing")
      .then(data => {
        let rows = JSON.parse(data)?.aaData ?? [];
        rows = rows.map(row => ({
          ...row,
          AccidentDate: dayjs(row.AccidentDate, "DD/MM/YYYY"),
          AccidentDateTime: dayjs(`${dayjs(row.AccidentDate, "DD/MM/YYYY").format("YYYY-MM-DD")}T${row.AccidentTime}:00`),
        }));
        setAccidents(rows);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const filteredAccidents = accidents.filter(accident =>
    filter.date <= accident.AccidentDate && accident.AccidentDate <= dayjs(filter.date).endOf("month"));

  const checkAndApproveAccidents = data?.data?.filter(accident => accident.status === "pending") ?? [];
  const confirmedAccidents = data?.data?.filter(accident =>
    accident.status !== "pending" && accident.isAccountApproved) ?? [];

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Private Settlement With Incentives</Typography>
      </Stack>
      <Box mb={3}>
        <Filter filter={filter} handleFilterChange={handleFilterChange} />
      </Box>
      <Box mb={3}>
        <AccidentTable data={filteredAccidents} loading={loading} onReload={onGet} />
      </Box>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Private Settlement With Incentives (To Check & Approve)</Typography>
      </Stack>
      <Box mb={3}>
        <CheckAndApproveAccidentTable data={checkAndApproveAccidents} loading={confirmLoading} onReload={onGet} />
      </Box>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Private Settlement With Incentives (Confirmed)</Typography>
      </Stack>
      <Box mb={3}>
        <CheckAndApproveAccidentTable data={confirmedAccidents} loading={confirmLoading} onReload={onGet} type="manager" />
      </Box>
    </>
  );
}

export default AccidentPrivateSettlement;