import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// material
import { Grid } from '@material-ui/core';
import { Link } from '@mui/material';

import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired
};

export default function ProductList({ products, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <Link component={NavLink} underline="hover" to={product.id} replace>
            <ShopProductCard product={product} />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}
