import { Button, Stack } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";
import { getDayjsValue } from "../../../utils/date";
import { PrintAgreementAction } from "../Booking/BookingAgreementTable";

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Button component={Link} to={`../../sales/booking/${row.bookingNo}`} target="_blank" variant="contained">Booking</Button>
      <PrintAgreementAction row={row}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function AgreementTable({ paginatedData, loading, onReload, sortModel, handlePageChange, handlePageSizeChange, handleSortModelChange, selectionModel, setSelectionModel }) {
  const paymentPeriods = {
    0: 'One time',
    3: 'Weekend',
    7: 'Weekly',
    30: 'Monthly',
  };

  const getShortcode = (row) => {
    const codes = [
      [
        ["PB",""],
        ["PBLM","PBLW"],
      ],
      [
        ["SB",""],
        ["SB (M)","GB"],
      ],
    ];
    const isLongTerm = row?.pricePeriod?.minRentalPeriod >= 28 ? 1 : 0;
    const isPrivateHire = row.rentalUsage === "Private Hire" ? 1 : 0;
    const isPostpaid = row.paymentMethod === "Postpaid" ? 1 : 0;
    return codes[isPrivateHire][isPostpaid][isLongTerm];
  }

  const getAgreementType = (row) => {
    if (row.agreementType === "Return") return row.agreementType;
    return row.cdwPrice > 0 ? `${row.agreementType} & CDW` : row.agreementType;
  }

  const columns = [
    { field: 'agreementNo', headerName: 'Agreement No.', align: 'center', headerAlign: 'center', width: 150 },
    {
      field: 'agreementType', headerName: 'Agreement Type', align: 'center', headerAlign: 'center', width: 150,
      renderCell: ({ row }) => getAgreementType(row),
    },
    { field: 'agreementState', headerName: 'Agreement State', align: 'center', headerAlign: 'center', width: 150 },
    { field: 'bookingNo', headerName: 'Booking No.', align: 'center', headerAlign: 'center', width: 150, sortable: true },
    { field: 'cdwPrice', headerName: 'CDW Price', align: 'center', headerAlign: 'center', width: 150 },
    {
      field: 'customerName', headerName: 'Customer Name', align: 'center', headerAlign: 'center', width: 200, sortable: true,
      valueGetter: ({ row }) => row?.companyName ? row?.companyName : row?.customerName
    },
    {
      field: 'startDateTime', headerName: 'Start Date', align: 'center', headerAlign: 'center', width: 150, sortable: true,
      valueGetter: ({ row }) => getDayjsValue(row.startDateTime).format("DD MMM YYYY")
    },
    {
      field: 'endDateTime', headerName: 'End Date', align: 'center', headerAlign: 'center', width: 150, sortable: true,
      valueGetter: ({ row }) => getDayjsValue(row.endDateTime).format("DD MMM YYYY")
    },
    { field: 'username', headerName: 'Sales Name', align: 'center', headerAlign: 'center', width: 150, sortable: true },
    { field: 'vehicleCompanyName', headerName: 'Company Name', align: 'center', headerAlign: 'center', width: 175, sortable: true },
    { field: 'deposit', headerName: 'Deposit', align: 'center', headerAlign: 'center', width: 100, sortable: true },
    { field: 'vehicleNumber', headerName: 'Vehicle No.', align: 'center', headerAlign: 'center', width: 100, sortable: true },
    { field: 'vehicleMakeName', headerName: 'Make', align: 'center', headerAlign: 'center', width: 125, sortable: true },
    { field: 'vehicleModelName', headerName: 'Model', align: 'center', headerAlign: 'center', width: 125, sortable: true },
    { field: 'rentalPrice', headerName: 'Rental Rate', align: 'center', headerAlign: 'center', width: 100, sortable: true },
    { field: 'totalUpfront', headerName: 'Total Upfront', align: 'center', headerAlign: 'center', width: 125, sortable: true },
    { field: 'status', headerName: 'Status', align: 'center', headerAlign: 'center', width: 100, sortable: false },
    {
      field: 'pricePeriod.paymentMethod', headerName: 'Payment Method', align: 'center', headerAlign: 'center', width: 150, sortable: true,
      valueGetter: ({ row }) => row?.pricePeriod?.paymentMethod,
    },
    {
      field: 'pricePeriod.paymentPeriod', headerName: 'Payment Period', align: 'center', headerAlign: 'center', width: 150, sortable: true,
      valueGetter: ({ row }) => paymentPeriods[row?.pricePeriod?.paymentPeriod],
    },
    {
      field: 'shortcode', headerName: 'Short Code', align: 'center', headerAlign: 'center', width: 100, sortable: false,
      valueGetter: ({ row }) => getShortcode(row),
    },
    {
      field: 'createdAt', headerName: 'Created Date', align: 'center', headerAlign: 'center', width: 150, sortable: true,
      valueGetter: ({ row }) => getDayjsValue(row.createdAt).format("DD MMM YYYY")
    },
    { field: 'remarks', headerName: 'Remarks', headerAlign: "center", width: 350, sortable: false },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 200, sortable: false,
      renderCell: ({row}) => <ActionCol row={row} onReload={onReload}/>
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      rows={paginatedData?.data ?? []}
      columns={columns}
      autoHeight
      disableSelectionOnClick
      checkboxSelection
      loading={loading}
      rowCount={paginatedData?.totalRows ?? 0}
      pageSizeOptions={[25, 50, 100]}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      paginationMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      sortingMode="server"
      onSelectionModelChange={newRowSelectionModel => setSelectionModel(newRowSelectionModel)}
      selectionModel={selectionModel}
    />
  );
}

AgreementTable.propTypes = {
  paginatedData: PropTypes.object,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
  sortModel: PropTypes.object,
  handlePageChange: PropTypes.func,
  handlePageSizeChange: PropTypes.func,
  handleSortModelChange: PropTypes.func,
  selectionModel: PropTypes.array,
  setSelectionModel: PropTypes.func,
  data: PropTypes.array,
}