import {Box, Button, Container, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import * as XLSX from "xlsx";
import BulkApplyCollectionTable from "../../components/Table/Collection/BulkApplyCollectionTable";
import {fetchEnhcApi} from "../../utils/fetchApi";
import {xlsxRawDateToDate, xlsxToJsonRaw} from "../../utils/xlsxToJson";
import {StyledDataGrid} from "../Vehicle/styles";

const DownloadAction = () => {
  const buttonRef = useRef(null);

  const onDownload = async e => {
    e.preventDefault();

    const headers = ['customerName', 'collectDate', 'collectAmount'];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const wb = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wb], { type: "application/octet-stream" });

    buttonRef.current.href = URL.createObjectURL(blob);
    buttonRef.current.download = 'Template.xlsx';
    buttonRef.current.click();
  }

  return (
    <>
      <Button variant="contained" color="secondary" onClick={onDownload}>Download Template</Button>
      <a ref={buttonRef} style={{ display: "none" }}>...</a>
    </>
  );
}

const UploadAction = ({ handleUpload }) => {
  const VisuallyHiddenInput = styled('input')({
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    cursor: 'pointer',
  });

  return (
    <>
      <Button variant="contained" component="label">
        Upload
        <VisuallyHiddenInput type="file" accept="application/xlsx" onChange={handleUpload} />
      </Button>
    </>
  );
}

UploadAction.propTypes = {
  handleUpload: PropTypes.func,
}

const ExportAction = ({ collections }) => {
  const buttonRef = useRef(null);

  const onDownload = async e => {
    e.preventDefault();

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(collections);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const wb = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wb], { type: "application/octet-stream" });

    buttonRef.current.href = URL.createObjectURL(blob);
    buttonRef.current.download = 'Results.xlsx';
    buttonRef.current.click();
  }

  return (
    <>
      <Button variant="contained" color="info" onClick={onDownload}>
        Export
      </Button>
      <a ref={buttonRef} style={{ display: "none" }}>...</a>
    </>
  );
}

ExportAction.propTypes = {
  collections: PropTypes.array,
}

const ExceptionTable = ({ data }) => {
  const columns = [
    { field: "customerName", headerName: "Customer Name", align: "center", headerAlign: "center", width: 250 },
    {
      field: "collectDate", headerName: "Collect Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row?.collectDate ? dayjs(row.collectDate).format("DD MMM YYYY") : "",
    },
    { field: "collectAmount", headerName: "Collect Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 150 },
    { field: "totalBillAmount", headerName: "Bill Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "invoiceNo", headerName: "Invoice No.", align: "center", headerAlign: "center", width: 150 },
    { field: "reason", headerName: "Reason", align: "center", headerAlign: "center", width: 300 },
  ];

  return (
    <StyledDataGrid columns={columns} rows={data ?? []} autoHeight disableSelectionOnClick />
  );
}

ExceptionTable.propTypes = {
  data: PropTypes.array,
}

export default function BulkApplyCollection() {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [collections, setCollections] = useState([]);
  const [exceptionRecords, setExceptionRecords] = useState([]);
  const [payload, setPayload] = useState({});

  const handleUpload = async e => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    let uploadCollections = xlsxToJsonRaw(await file.arrayBuffer());
    uploadCollections = uploadCollections.map(c => ({ ...c, collectDate: dayjs(xlsxRawDateToDate(c.collectDate)).format() }));
    console.log(uploadCollections);

    const exceptionRecords = [];
    const newRecords = [];
    invoices.forEach(inv => {
      const records = uploadCollections.filter(col => col.customerName === inv.customerName);
      if (records.length !== 1) {
        records.forEach((record) => {
          record.reason = "Same customer name";
          record.status = "rejected";
        });
        exceptionRecords.push({ ...inv, reason: records.length === 0 ? "Customer not found" : "Same customer name" });
        return;
      }

      const record = records[0];
      if (Number(record.collectAmount) > inv.outstanding) {
        record.reason = "Overpaid";
        record.status = "rejected";
        exceptionRecords.push({
          ...record,
          id: inv.id,
          outstanding: inv.outstanding,
          totalBillAmount: inv.totalBillAmount,
          invoiceNo: inv.invoiceNo,
          reason: "Overpaid",
        });
        return;
      }

      record.status = "accepted";
      newRecords.push({
        id: inv.id,
        bookingNo: inv.bookingNo,
        rentalAgreementNo: inv.rentalAgreementNo,
        customerName: inv.customerName,
        invoiceNo: inv.invoiceNo,
        outstanding: inv.outstanding,
        totalBillAmount: inv.totalBillAmount,
        applyAmount: Number(record.collectAmount),
        paymentMethod: "PayNow 201608540Z",
        companyName: inv.companyName,
        collectDate: dayjs(xlsxRawDateToDate(record.collectDate)),
      });
    });

    setInvoices(newRecords);
    setCollections(uploadCollections);
    setExceptionRecords(exceptionRecords);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await fetchEnhcApi("GetOpenInvoices");
      setInvoices(res);
      setLoading(false);
    }
    fetchData().catch(console.error);
  }, []);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Bulk Apply Collections</Typography>
        <Stack direction="row" spacing={1}>
          <ExportAction collections={collections} />
          <UploadAction handleUpload={handleUpload} />
          <DownloadAction/>
        </Stack>
      </Stack>

      <Box mb={3}>
        <BulkApplyCollectionTable data={invoices} loading={loading}/>
      </Box>
      <Box mb={3}>
        <Typography variant="h5">Exception Records</Typography>
      </Box>
      <Box>
        <ExceptionTable data={exceptionRecords}/>
      </Box>
    </Container>
  );
}