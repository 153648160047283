import { Box, Button, Container, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Icon from '@iconify/react';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';

import DataTable from '../../components/DataTable';
import { PopupModal } from '../../components/PopupModal';
import { TabPanel, a11yProps } from '../../components/TabPanel';
import FirestoreContext from '../../store/FirestoreContext';
import NewNews from './NewNews';
import errorHandler from '../../utils/errorHandler';

const NewsList = () => {
  // Tabs panel logic
  const [value, setValue] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const fsCtx = useContext(FirestoreContext);
  const tableInitialSort = {
    sorting: {
      sortModel: [{ field: 'date', sort: 'desc' }]
    }
  };
  const columns = [
    {
      field: 'id',
      headerName: 'Actions',
      width: 90,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => {
              handleOpen(
                <NewNews
                  editMode
                  initialFormData={newsList.find((el) => el.id === params.value)}
                  newsId={params.value}
                  closeHandler={() => console.log('f')}
                />
              );
            }}
          >
            <Icon icon={edit2Fill} height={20} />
          </IconButton>
          <IconButton
            color="error"
            onClick={async () => {
              if (
                window.confirm(
                  `Are you sure you want to delete this news article "${params.row.title}"?`
                )
              ) {
                try {
                  await deleteDoc(doc(fsCtx.fsObject, 'fcadmin/fcadmin/news', params.value));
                  setNewsList((oldNewsList) => oldNewsList.filter((el) => el.id !== params.value));
                  alert('The news article has been deleted. It will no longer appear on the App.');
                } catch (error) {
                  errorHandler(params.value, 'Delete news article', error);
                }
              }
            }}
          >
            <Icon icon={trash2Fill} height={20} />
          </IconButton>
        </>
      ),
      type: 'number'
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      type: 'date',
      sortable: true,
      renderCell: (param) => moment(param.value.toDate()).format('D MMM YYYY')
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 200,
      renderCell: (params) => (
        <a href={params.row.imgUrl} target="_blank" rel="noopener noreferrer">
          <img src={params.row.imgUrl} alt="" />
        </a>
      ),
      type: 'date',
      sortable: true
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 500,
      type: 'string',
      editable: false
    },
    {
      field: 'url',
      headerName: 'News Url',
      width: 400,
      type: 'string',
      editable: false
    }
  ];

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let isCancelled = false;
    const q = query(collection(fsCtx.fsObject, 'fcadmin/fcadmin/news'));
    getDocs(q).then((querySnapshot) => {
      if (!isCancelled)
        setNewsList(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
        );
    });
    return () => {
      isCancelled = true;
    };
  }, [fsCtx.fsObject]);

  // Modal logic
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const handleOpen = (msgId) => {
    setOpen(true);
    setModalInfo(msgId);
  };
  const handleClose = () => setOpen(false);

  return (
    <Container maxWidth="xl">
      <PopupModal
        openState={open}
        closeHandler={handleClose}
        modalBody={modalInfo}
        closeConfirmation
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          App | News List
        </Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => handleOpen(<NewNews closeHandler={() => console.log('f')} />)}
        >
          New News Article
        </Button>
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="News List" {...a11yProps(0)} />
          {/* <Tabs label="Item Two" {...a11yProps(1)} />
          <Tabs label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DataTable dataRows={newsList} dataColumns={columns} tableInitialSort={tableInitialSort} />
      </TabPanel>

      {/* <TabPanel value={value} index={1}>
        hi
      </TabPanel>

      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
    </Container>
  );
};

export default NewsList;
