import {DialogContent} from "@material-ui/core";
import {LoadingButton} from "@material-ui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  Select,
  MenuItem
} from "@mui/material";
import {debounce} from "lodash";
import PropTypes from "prop-types";
import {useCallback, useContext, useState} from "react";
import MailIcon from '@mui/icons-material/Mail';
import InvoiceForm from "../../../../components/Form/Invoice/InvoiceForm";
import InvoiceFilterForm from "../../../../components/Form/Invoice/InvoiceFilterForm";
import InvoiceTable from "../../../../components/Table/Invoice/InvoiceTable";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import AuthContext from "../../../../store/AuthContext";
import DataContext from "../../../../store/DataContext";

const NewInvoiceAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [booking, setBooking] = useState(null);
  const [agreement, setAgreement] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [type, setType] = useState("normal");

  const { data: customers, loading: customerLoading, onGet: onCustomerGet } = useHttpGet("/api/customer", false);
  const { data: bookings, loading: bookingLoading, onGet: onBookingGet } = useHttpGet("/api/booking", false);
  const { data: agreements, loading: agreementLoading, onGet: onAgreementGet } = useHttpGet("/api/agreement", false);

  const invoiceTypes = [
    { label: "Normal", value: "normal" },
    { label: "CDW", value: "cdw" },
  ];

  const debounceOnChange = useCallback(debounce(async value => {
    if (value.length > 0) {
      await onCustomerGet({ name: value });
    }
  }, 500), []);

  const handleCustomerChange = (e, newValue) => {
    setCustomer(newValue);
    setBooking(null);
    setAgreement(null);
    onBookingGet({ customerId: newValue?.id });
  }

  const handleCustomerInputChange = (e, newInputValue) => {
    setCustomerName(newInputValue);
    debounceOnChange(newInputValue);
  }

  const handleBookingChange = (e, newValue) => {
    setBooking(newValue);
    setAgreement(null);
    onAgreementGet({ bookingNo: newValue?.bookingNo });
  }

  const handleAgreementChange = (e, newValue) => {
    setAgreement(newValue);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>Create New Invoice</DialogTitle>
        <DialogContent>
          <Grid container pt={1} spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                value={customer} onChange={handleCustomerChange}
                inputValue={customerName} onInputChange={handleCustomerInputChange}
                options={customers ?? []} loading={customerLoading}
                getOptionLabel={option => `${option?.name}, ${option?.identity?.substring(option?.identity?.length - 4, option?.identity?.length)}`}
                renderInput={params => <TextField {...params} label="Customer" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                value={booking} onChange={handleBookingChange}
                options={bookings ?? []} loading={bookingLoading}
                getOptionLabel={option => option?.bookingNo}
                renderInput={params => <TextField {...params} label="Booking" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                value={agreement} onChange={handleAgreementChange}
                options={agreements ?? []} loading={agreementLoading}
                getOptionLabel={option => option?.agreementNo}
                renderInput={params => <TextField {...params} label="Agreement" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel>Invoice Type</InputLabel>
                <Select
                  label="Type"
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  {invoiceTypes.map(({ label, value }, index) => (
                    <MenuItem value={value} key={index}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {agreement && <Grid item xs={12}>
              <InvoiceForm agreement={agreement} type={type} onReload={onReload} onClose={() => setOpen(false)}/>
            </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>New</Button>
    </>
  );
}

NewInvoiceAction.propTypes = {
  onReload: PropTypes.func,
}

export default function InvoiceTab() {
  const authCtx = useContext(AuthContext);
  const dataCtx = useContext(DataContext);

  const [selectionModel, setSelectionModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({ pageIndex: 1, pageSize: 100 });
  const [sortModel, setSortModel] = useState([{ field: "issueDate", sort: "desc" }]);

  const { data, loading: invoiceLoading, onGet } = useHttpGet("/api/invoice/paginated", true, paginationModel);
  const { onPost, loading: generateLoading } = useHttpPost("/api/invoice/generate");

  const handlePageChange = (newPage) => {
    setPaginationModel({ pageIndex: newPage + 1, pageSize: paginationModel.pageSize });
  }

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ pageIndex: paginationModel.pageIndex, pageSize: newPageSize });
  }

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  }

  // TODO: integrate batch email api
  const onEmail = async e => {
    e.preventDefault();
    console.log(selectionModel);
    try {
      dataCtx.setSnackbarConfig({ open: true, message: "Email sent successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  const onGenerate = async e => {
    e.preventDefault();
    try {
      for (let i = 0; i < selectionModel.length; i += 10) {
        // eslint-disable-next-line no-await-in-loop
        await onPost(selectionModel.slice(i, i + 10));
      }
      dataCtx.setSnackbarConfig({ open: true, message: "Invoices generated successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Invoice Listing</Typography>
        <Stack direction="row" spacing={1}>
          {authCtx.userDept === "superadmin" && <LoadingButton variant="contained" onClick={onGenerate} loading={generateLoading}>Generate</LoadingButton>}
          <NewInvoiceAction onReload={onGet}/>
          <Button startIcon={<MailIcon/>} variant="contained" onClick={onEmail}>Email</Button>
        </Stack>
      </Stack>
      <Box mb={3}>
        <InvoiceFilterForm onReload={onGet} paginationModel={paginationModel} sortModel={sortModel} />
      </Box>
      <InvoiceTable
        paginatedData={data}
        loading={invoiceLoading} onReload={onGet}
        sortModel={sortModel}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        handleSortModelChange={handleSortModelChange}
        selectionModel={selectionModel} setSelectionModel={setSelectionModel}
      />
    </>
  );
}