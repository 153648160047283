import { Pulsar } from '@uiball/loaders';
import FcLogo from '../assets/fc-logo.png';

export default function FcLoader() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <table>
        <tbody>
          <tr>
            <td>
              <img src={FcLogo} width="100px" alt="Fresh Cars" />
            </td>
          </tr>
          <tr>
            <td
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Pulsar size={40} speed={1.75} color="green" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
