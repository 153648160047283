import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import InspectionVehicleTable from '../../../components/Table/Vehicle/InspectionVehicleTable';
import DataContext from '../../../store/DataContext';

export default function InspectionVehicles({ data, onReload }) {
  const dataCtx= useContext(DataContext);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  return (
    <>
      <Typography variant="h4" mb={3}>Inspection Vehicles</Typography>
      <InspectionVehicleTable data={data ?? []} onReload={onReload} onOpenSnackbar={onOpenSnackbar} />
    </>
  );
}

InspectionVehicles.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func
};