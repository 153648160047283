import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { GridRowModes, DataGrid, GridToolbarContainer } from '@mui/x-data-grid';

function EditToolbar(props) {
  const { newId, setNewId, setRows, setRowModesModel } = props;

  const handleClick = useCallback(() => {
    setRows((oldRows) => [
      ...oldRows,
      { id: newId, type: '', description: '', qty: 1, isNew: true }
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newId]: { mode: GridRowModes.Edit, fieldToFocus: 'type' }
    }));
    setNewId((prev) => prev + 1);
  }, [newId, setNewId, setRows, setRowModesModel]);

  if (props.isCollection) return null;

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  isCollection: PropTypes.bool,
  newId: PropTypes.number,
  setNewId: PropTypes.func,
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired
};

export default function NewDocumentTable(props) {
  const [newId, setNewId] = useState(1);

  const [rowModesModel, setRowModesModel] = useState({});

  const processRowUpdate = useCallback((newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    props.setOsInvoiceData(
      props.osInvoiceData.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  }, [props]);

  const columns = [
    { field: 'company', headerName: 'Co', width: 50, editable: false },
    {
      field: 'date', headerName: 'Date', width: 120, type: 'date',
      valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-sg', { year: 'numeric', month: 'short', day: 'numeric' }),
      editable: false
    },
    { field: 'documentNo', headerName: 'Doc No', width: 150, editable: false },
    { field: 'description', headerName: 'Description', width: 800, editable: false },
    { field: 'amount', headerName: 'Amount', width: 100, type: 'number', editable: false },
    { field: 'invOutstanding', headerName: 'Outstanding', width: 100, type: 'number', editable: false },
    { field: 'amountToApply', headerName: 'Apply', width: 100, type: 'number', editable: true }
  ];

  NewDocumentTable.propTypes = {
    isCollection: PropTypes.bool,
    osInvoiceData: PropTypes.array,
    setOsInvoiceData: PropTypes.func
  };

  return (
    <Box
      sx={{
        height: 450,
        width: '100%',
        '& .actions': {
          color: 'text.secondary'
        },
        '& .textPrimary': {
          color: 'text.primary'
        }
      }}
    >
      <DataGrid
        rows={props.osInvoiceData}
        columns={columns}
        editMode="row"
        processRowUpdate={processRowUpdate}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'asc' }]
          }
        }}
        components={{ Toolbar: EditToolbar }}
        componentsProps={{
          toolbar: {
            newId,
            setRows: props.setOsInvoiceData,
            setRowModesModel,
            setNewId,
            isCollection: props.isCollection
          }
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
