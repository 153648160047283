import dayjs from "dayjs";
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import {defaultTimezone} from "../../../utils/date";

export default function RecurringGenerateInvoiceTable({ data }) {
  const columns = [
    { field: "invoiceNo", headerName: "Invoice No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "bookingNo", headerName: "Booking No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 100, sortable: false },
    { field: "title", headerName: "Title", align: "center", headerAlign: "center", width: 500, sortable: false },
    {
      field: "totalAmount", headerName: "Total Amount", align: "center", headerAlign: "center", width: 200, sortable: false,
      renderCell: ({ row }) => `S$${row.totalAmount}`,
    },
    {
      field: "gstRate", headerName: "GST Rate", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => row.gstRate > 0 ? `${row.gstRate}%` : "",
    },
    {
      field: "gstAmount", headerName: "GST Amount", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => row.gstAmount > 0 ? `S$${row.gstAmount}` : "",
    },
    {
      field: "issueDate", headerName: "Issue Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.issueDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    { field: "terms", headerName: "Terms", align: "center", headerAlign: "center", width: 100, sortable: false },
  ];

  return (
    <StyledDataGrid
      rows={data ?? []} columns={columns}
      autoHeight disableSelectionOnClick
    />
  );
}

RecurringGenerateInvoiceTable.propTypes = {
  data: PropTypes.array,
}