import { LoadingButton } from "@material-ui/lab";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import AgreementFilterForm from "../../components/Form/Booking/Agreement/AgreementFilterForm";
import AgreementTable from "../../components/Table/Agreement/AgreementTable";
import usePaginationModel from "../../hooks/pagination/usePaginationModel";
import useHttpGet from "../../hooks/http/useHttpGet";
import useHttpPost from "../../hooks/http/useHttpPost";
import AuthContext from "../../store/AuthContext";
import DataContext from "../../store/DataContext";

export default function AgreementListing() {
  const authCtx = useContext(AuthContext);
  const dataCtx = useContext(DataContext);

  const [selectionModel, setSelectionModel] = useState([]);

  const {
    paginationModel,
    sortModel,
    handleSortModelChange,
    handlePageChange,
    handlePageSizeChange
  } = usePaginationModel();

  const { data, loading, onGet } = useHttpGet("/api/agreement/paginated", true, paginationModel);
  const { onPost, loading: generateLoading } = useHttpPost("/api/agreement/generate");

  const onGenerate = async e => {
    e.preventDefault();
    try {
      for (let i = 0; i < selectionModel.length; i += 3) {
        // eslint-disable-next-line no-await-in-loop
        await onPost(selectionModel.slice(i, i + 3));
      }
      dataCtx.setSnackbarConfig({ open: true, message: "Agreement generated successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Agreement Listing</Typography>
        {authCtx.userDept === "superadmin" && <LoadingButton variant="contained" onClick={onGenerate} loading={generateLoading}>Generate</LoadingButton>}
      </Stack>
      <Box mb={2}>
        <AgreementFilterForm onReload={onGet} paginationModel={paginationModel} sortModel={sortModel} />
      </Box>
      <AgreementTable
        paginatedData={data}
        loading={loading} onReload={onGet}
        sortModel={sortModel}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        handleSortModelChange={handleSortModelChange}
        selectionModel={selectionModel} setSelectionModel={setSelectionModel}
      />
    </Container>
  );
}