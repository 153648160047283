// material
import { Container, Stack, Typography } from '@material-ui/core';
// components
import { useCallback, useContext, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { collection, getDocs, query } from 'firebase/firestore';
import { Button, Modal } from '@mui/material';
import moment from 'moment/moment';

import FirestoreContext from '../../store/FirestoreContext';
import FcAppNewNotification from './FcAppNewNotification';
import DataTable from '../../components/DataTable';
import { a11yProps, TabPanel } from '../../components/TabPanel';

// ----------------------------------------------------------------------

let isMounted = true;

export const MsgModal = (props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };
  MsgModal.propTypes = {
    openState: PropTypes.bool,
    closeHandler: PropTypes.func,
    modalInfoHandler: PropTypes.object
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.openState}
      onClose={props.closeHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={props.openState}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {props.modalInfoHandler.msgTitle}
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            Date:{' '}
            {new Intl.DateTimeFormat('en-SG', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }).format(props.modalInfoHandler.msgDate)}
            <br />
            Type: {props.modalInfoHandler.msgType}
            <br />
            Sender: {props.modalInfoHandler.sender}
            <br />
            Receipients: {props.modalInfoHandler.receipients}
            <br />
            {props.modalInfoHandler.imageUrl !== '' &&
            props.modalInfoHandler.imageUrl !== undefined ? (
              <>
                <br />
                <b>Image</b>
                <br />
                <img src={props.modalInfoHandler.imageUrl} alt="" />
                <br />
              </>
            ) : undefined}
            Message:
            <br />
            <br />
            {props.modalInfoHandler.msgContent}
          </Typography>

          <br />
          <Button variant="contained" onClick={props.closeHandler}>
            Close
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

// ----------------------------------------------------------------------

export default function FcAppNotifications() {
  const fsCtx = useContext(FirestoreContext);

  const [msgListRows, setMsgListRows] = useState([]);
  const tableInitialSort = {
    sorting: {
      sortModel: [{ field: 'msgDate', sort: 'desc' }]
    }
  };

  const fetchMessagesHandler = useCallback(async () => {
    const q = query(collection(fsCtx.fsObject, 'fcadmin/fcadmin/notifications'));
    const querySnapshot = await getDocs(q);
    const tempArr = [];
    let x = 1;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      tempArr.push({
        ...doc.data(),
        msgContent: decodeURIComponent(doc.get('msgContent')),
        id: x,
        msgDate: new Date(doc.data().msgDate.toDate())
      });

      x += 1;
    });
    if (isMounted) setMsgListRows(tempArr);

    // const q = query(collection(db, "cities"), where("capital", "==", true));
  }, [fsCtx.fsObject]);

  useEffect(() => {
    isMounted = true;
    fetchMessagesHandler();
    return () => {
      isMounted = false;
    };
  }, [fetchMessagesHandler]);

  // Message Details modal logic
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    msgContent: '',
    msgTitle: '',
    msgDate: '',
    msgType: '',
    imageUrl: ''
  });
  const handleOpen = (msgId) => {
    setOpen(true);
    setModalInfo(msgListRows.find((element) => element.id === msgId));
  };
  const handleClose = () => setOpen(false);

  const columns = [
    {
      field: 'id',
      headerName: 'Open',
      width: 100,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen(params.value)}
            size="small"
            style={{ marginLeft: 16 }}
          >
            Open
          </Button>
        </strong>
      ),
      type: 'number'
    },
    {
      field: 'msgDate',
      headerName: 'Date',
      width: 100,
      type: 'date',
      sortable: true,
      renderCell: (param) => moment(param.value).format('D MMM YYYY')
    },
    {
      field: 'msgTitle',
      headerName: 'Title',
      width: 250,
      editable: false
    },
    {
      field: 'msgType',
      headerName: 'Type',
      width: 150,
      type: 'string',
      editable: false
    },
    {
      field: 'sender',
      headerName: 'Sender',
      width: 130,
      type: 'string',
      editable: false
    },
    {
      field: 'receipients',
      headerName: 'Receipients',
      width: 130,
      type: 'string',
      editable: false
    },
    {
      field: 'imageUrl',
      headerName: 'Image URL',
      type: 'string',
      width: 200,
      editable: false
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`
    // }
  ];
  // const tableFilter = {};

  // Tabs Navigation

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          App | Notifications
        </Typography>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Notification List" {...a11yProps(0)} />
            <Tab label="New Notification" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <DataTable
            dataRows={msgListRows}
            dataColumns={columns}
            tableInitialSort={tableInitialSort}
          >
            <MsgModal openState={open} closeHandler={handleClose} modalInfoHandler={modalInfo} />
          </DataTable>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <FcAppNewNotification />
        </TabPanel>
      </Box>
    </Container>
  );
}
