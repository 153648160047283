import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@material-ui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import useGetCollections from '../../../hooks/firebase/useGetCollections';
import { SubmitBtnGroup } from '../FormFields';
import { fetchEnhcApi } from '../../../utils/fetchApi';
import AuthContext from '../../../store/AuthContext';

export default function InspectionForm({
  inspectionData,
  onOpenSnackbar,
  onClose,
  getList,
  type = 'updateInspection'
}) {
  const [userList, setUserList] = useState([]);
  const { data } = useGetCollections('fcadmin/fcadmin/users');
  const authCtx = useContext(AuthContext);
  // const periodType = [
  //   { label: '1 Year', value: 1 },
  //   { label: '2 Year', value: 2 },
  //   { label: '6 Month', value: 0.5 }
  // ];
  const onUpdate = async (values) => {
    // await onPut({ ...values, id: data.id });
    if (type === 'updateLastInspection') {
      await fetchEnhcApi('UpdateLastInspectionDate', {
        LastInspectionDoneDate: values.lastInspectionDate.toISOString(),
        // period: values.period,
        DoneBy: values.DoneBy,
        VehicleNo: values.vehicleNo,
        UpdateBy: authCtx.name
      });
    } else if (type === 'updateInspection') {
      await fetchEnhcApi('UpdateInspectionDate', {
        InspectionDate: values.lastInspectionDate.toISOString(),
        VehicleNo: values.vehicleNo
      });
    } else if (type === 'assignStaff') {
      await fetchEnhcApi('SetOperationPerson', {
        staff: values.staff,
        VehicleNo: values.vehicleNo
      });
    } else if (type === 'revertLastInspectionDate') {
      await fetchEnhcApi('RevertInspectionDate', {
        VehicleNo: values.vehicleNo,
        UpdateBy: authCtx.name
      });
    }
  };

  let object;

  if (type === 'updateLastInspection') {
    object = {
      lastInspectionDate: Yup.date().required('Please select the inspection due date'),
      DoneBy: Yup.string().required('Please select the person done the inspection')
    };
  } else if (type === 'updateInspection') {
    object = { lastInspectionDate: Yup.date().required('Please select the inspection due date') };
  } else if (type === 'assignStaff') {
    object = { staff: Yup.string().required('Please select the staff to Inspection') };
  }

  const formik = useFormik({
    initialValues: {
      vehicleNo: '',
      lastInspectionDate: '',
      staff: '',
      DoneBy: ''
      // period: 1
    },
    validationSchema: Yup.object(object),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onUpdate(values);
        await getList();
        onOpenSnackbar('update success');
        onClose();
      } catch (error) {
        onOpenSnackbar(error.message, 'error');
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (inspectionData) {
      formik.setFieldValue('vehicleNo', inspectionData.vehicleNo);
    }
  }, [inspectionData]);

  useEffect(() => {
    if (data) {
      const temp = data.filter(
        (item) => item.department === 'operation' || item.department === 'sales'
      );
      setUserList(temp);
    }
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          label="Vehicle No"
          name="vehicleNo"
          variant="standard"
          margin="dense"
          fullWidth
          value={formik.values.vehicleNo}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.vehicleNo && formik.errors.vehicleNo)}
          helperText={formik.touched.vehicleNo && formik.errors.vehicleNo}
          disabled
        />
        {type === 'updateLastInspection' && (
          <FormControl sx={{ width: '100%', marginBottom: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disableMaskedInput
                label="Inspection Done Date"
                name="lastInspectionDate"
                inputFormat="DD-MM-YYYY"
                onChange={(val) => formik.setFieldValue('lastInspectionDate', val)}
                value={formik.values.lastInspectionDate}
                renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        )}
        {type === 'updateLastInspection' && (
          <FormControl variant="standard" sx={{ width: '100%', marginBottom: 1 }}>
            <InputLabel>Inspection Done By</InputLabel>
            <Select
              label="Inspection Done By"
              name="DoneBy"
              value={formik.values.DoneBy}
              onChange={formik.handleChange}
            >
              {userList?.map((item, index) => (
                <MenuItem key={index} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/* {type === 'updateLastInspection' && (
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Adding Year</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formik.values.period}
              onChange={(event) => {
                formik.setFieldValue('period', event.currentTarget.value);
              }}
            >
              {periodType.map((item) => (
                <FormControlLabel
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                  key={item.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )} */}
        {type === 'updateInspection' && (
          <FormControl sx={{ width: '100%', marginBottom: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disableMaskedInput
                label="Inspection Date"
                name="lastInspectionDate"
                inputFormat="DD-MM-YYYY"
                onChange={(val) => formik.setFieldValue('lastInspectionDate', val)}
                value={formik.values.lastInspectionDate}
                renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        )}
        {type === 'assignStaff' && (
          <FormControl variant="standard" sx={{ width: '100%', marginBottom: 1 }}>
            <InputLabel>Staff</InputLabel>
            <Select
              label="Staff"
              name="staff"
              value={formik.values.staff}
              onChange={formik.handleChange}
            >
              {userList?.map((item, index) => (
                <MenuItem key={index} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <SubmitBtnGroup method="Submit" formik={formik} onCancel={onClose} />
      </Form>
    </FormikProvider>
  );
}

InspectionForm.propTypes = {
  inspectionData: PropTypes.object,
  onOpenSnackbar: PropTypes.func,
  onClose: PropTypes.func,
  getList: PropTypes.func,
  isInsepctionDone: PropTypes.string
};
