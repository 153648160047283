import { Box, Button, Stack } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";
import DataContext from "../../../store/DataContext";

const priceArrayToObject = (data, originalData) => {
  if (!data || !originalData || data.length < 1 || originalData.length < 1)
    return [];

  const len = data?.length ?? 0;
  for (let i = 0; i < len; i++) {
    const pricesObj = {};
    const { prices } = data[i];
    const { prices: originalPrices } = originalData[i];
    for (let j = 0; j < prices.length; j++) {
      const { pricePeriod } = prices[j];
      pricesObj[pricePeriod.columnName] = prices[j];
    }
    for (let j = 0; j < originalPrices.length; j++) {
      const { pricePeriod } = originalPrices[j];
      pricesObj[pricePeriod.columnName].originalPrice = originalPrices[j].price;
    }
    data[i].priceObj = pricesObj;
    data[i].id = i + 1;
    data[i].isParf = prices[0]?.isParf;
  }
  return data;
}

const priceObjectToArray = (rows) => {
  const prices = [];
  for (let i = 0; i < rows.length; i++) {
    const { priceObj } = rows[i];
    const arr = Object.values(priceObj);
    for (let j = 0; j < arr.length; j++)
      prices.push(arr[j]);
  }
  return prices;
}

export default function PriceTableForm({ originalData, data, columns }) {
  const dataCtx = useContext(DataContext);
  const [rows, setRows] = useState([]);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const { onPost } = useHttpPost("/api/vehicles/price-table");

  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      await onPost(priceObjectToArray(rows));
      onOpenSnackbar("Price updated successfully!");
    } catch (error) {
      onOpenSnackbar(error.message, "error");
    }
  }

  useEffect(() => {
    if (data && originalData) {
      setRows(priceArrayToObject(data, originalData));
    }
  }, [data, originalData]);

  const handleCommit = ({ id, field, value }) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        row.priceObj[field].price = Number(value);
        return { ...row };
      }
      return row;
    });
    setRows(updatedRows);
  }

  return (
    <Box>
      <StyledDataGrid
        component={{ Toolbar: GridToolbar }}
        columns={columns} rows={rows}
        pageSize={100} rowsPerPageOptions={[100]}
        disableSelectionOnClick
        initialState={{sorting: { sortModel: [{ field: "model", sort: "asc" }] }}}
        onCellEditCommit={handleCommit}
        sx={{ height: window.innerHeight * 0.7 }}
      />
      <Stack direction="row" justifyContent="flex-end" mt={3}>
        <Button variant="contained" onClick={onUpdate}>Update</Button>
      </Stack>
    </Box>
  );
}

PriceTableForm.propTypes = {
  originalData: PropTypes.array,
  data: PropTypes.array,
  columns: PropTypes.array,
}