import * as Yup from 'yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc
} from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { fieldToTextField } from 'formik-mui';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getAuth } from 'firebase/auth';

import DataContext from '../../store/DataContext';
import FirestoreContext from '../../store/FirestoreContext';
import ModelAutocomplete from '../../components/ModelAutocomplete';
import { PopupModal } from '../../components/PopupModal';
import PropertyTooltipScreenshot from '../../assets/new-model-property-tooltip-screenshot.jpg';

import { routeName as dashboardAppRouteName } from '../DashboardApp';
import { routeName as carModelsMainRouteName } from './FcAppCarModelsMain';

const defaultChipColour = '40E0D0';
const chipColours = ['40E0D0', 'FFA500', 'CD5C5C', 'BA55D3'];
export const newModelString = 'new-model';

const fields = [
  {
    fieldKey: 'gen',
    label: 'Generation',
    placeholder: "Enter the model's generation code.",
    type: 'string'
  },
  {
    fieldKey: 'seater',
    label: 'Seater',
    placeholder: 'Enter a number.',
    type: 'number',
    InputLabelProps: { shrink: true }
  },
  {
    fieldKey: 'engineCapacity',
    label: 'Engine Capacity',
    placeholder: 'Enter a number.',
    type: 'number',
    InputLabelProps: { shrink: true }
  },
  {
    fieldKey: 'mfgYear',
    label: 'Manufacture Year',
    placeholder: 'Enter a number.',
    type: 'number',
    InputLabelProps: { shrink: true }
  },
  {
    fieldKey: 'rentalRateDaily',
    label: 'Rate - Daily',
    placeholder: 'Enter a number.',
    type: 'number',
    InputProps: { startAdornment: `$\u00A0` }
  },
  {
    fieldKey: 'rentalRateMonthly',
    label: 'Rate - Monthly',
    placeholder: 'Enter a number.',
    type: 'number',
    InputProps: { startAdornment: `$\u00A0` }
  },
  {
    fieldKey: 'rentalRateWeekend',
    label: 'Rate - Weekend',
    placeholder: 'Enter a number.',
    type: 'number',
    InputProps: { startAdornment: `$\u00A0` }
  },
  {
    fieldKey: 'rentalRateWeekly',
    label: 'Rate - Weekly',
    placeholder: 'Enter a number.',
    type: 'number',
    InputProps: { startAdornment: `$\u00A0` }
  }
];

const PropertyBadgesAutocomplete = ({
  textFieldProps,
  selectedChips,
  setSelectedChips,
  ...props
}) => {
  PropertyBadgesAutocomplete.propTypes = {
    textFieldProps: PropTypes.object,
    selectedChips: PropTypes.array,
    setSelectedChips: PropTypes.func,
    field: PropTypes.object,
    form: PropTypes.object
  };
  const {
    form: { handleBlur, setFieldTouched, setFieldValue, setFieldError },
    field: { name }
  } = props;
  const { error, helperText } = fieldToTextField(props);

  return (
    <Autocomplete
      ChipProps={{
        color: 'primary',
        onClick: (e) => (e.currentTarget.style.backgroundColor = 'orange'),
        sx: { backgroundColor: 'turquoise' }
      }}
      freeSolo
      multiple
      fullWidth
      name={name}
      onBlur={(e) => {
        handleBlur(e);
        setFieldError(name, undefined);
      }}
      options={[]}
      value={selectedChips}
      onChange={(e) => {
        if (e.target.value === '') {
          setFieldError(name, 'Property badge cannot be empty.');
          return;
        }
        if (e.target.value.search(' ') !== -1) {
          setFieldError(name, 'Property badge cannot contain spaces.');
          return;
        }
        if (e.target.value.length > 5) {
          setFieldError(name, 'Property badge cannot be longer than 5 letters.');
          return;
        }
        if (selectedChips.findIndex((el) => el.label === e.target.value) !== -1) {
          setFieldError(name, 'This property already exists.');
          return;
        }
        setFieldTouched(name, true, true);
        setFieldError(name, undefined);
        setSelectedChips((old) => {
          const newVal = [
            ...old,
            {
              key: new Date().getTime().toString(),
              colour: `FF${defaultChipColour}`,
              label: e.target.value
            }
          ];
          setFieldValue(name, newVal);
          return newVal;
        });
      }}
      renderTags={(value, getTagProps, ownerState) =>
        value.map((el) => (
          <Chip
            className="hoverOverride"
            key={el.label}
            // {...getTagProps(i)}
            color="primary"
            onClick={(e) => {
              setSelectedChips((old) => {
                const elI = old.findIndex((findEl) => findEl.key === el.key);
                const newEl = { ...old[elI] };
                const colourI = chipColours.findIndex((col) => col === newEl.colour.slice(2));
                newEl.colour = `FF${chipColours[colourI === colourI.length - 1 ? 0 : colourI + 1]}`;
                const newArr = [...old];
                newArr[elI] = newEl;
                return newArr;
              });
              e.currentTarget.blur();
            }}
            onDelete={() => {
              setSelectedChips((old) => old.filter((filterEl) => filterEl.key !== el.key));
            }}
            label={el.label}
            sx={{ backgroundColor: `#${el.colour.slice(2)}`, mx: 0.5 }}
          />
        ))
      }
      {...props}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => {
        const newParams = { ...params };
        // enterKeyHint is necessary to ensure that the "enter" button on mobile web creates a new chip. otherwise, the enter key on mobile web will be a "next" button and user will never be able to enter new chips on mobile
        newParams.inputProps = { ...newParams.inputProps, enterKeyHint: 'go' };
        return (
          <TextField
            {...newParams}
            {...textFieldProps}
            variant="standard"
            error={error}
            helperText={helperText}
          />
        );
      }}
    />
  );
};

let isMounted = false;
let carModelsFetched = false;

const FcAppNewCarModel = (props) => {
  const dataCtx = useContext(DataContext);
  useEffect(() => {
    // user might refresh this page or load this URL directly - at this time, carModels haven't been fetched yet. Need to imperatively fetch it here
    if (!carModelsFetched) {
      dataCtx.fetchCarModels();
      carModelsFetched = true;
    }
  }, [dataCtx]);
  return <FcAppNewCarModelInner props={props} />;
};
export default FcAppNewCarModel;

const FcAppNewCarModelInner = (props) => {
  const nav = useNavigate();
  const fsCtx = useContext(FirestoreContext);
  const dataCtx = useContext(DataContext);
  const storage = getStorage();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [carOptions, setCarOptions] = useState({
    carBodyStyle: [],
    make: [],
    model: [],
    propellant: [],
    rentalUse: [],
    transmission: []
  });
  const params = useParams();
  const editMode = params.carModel !== newModelString;
  const carData = editMode ? dataCtx.carModels.find((el) => el.id === params.carModel) : {};

  const fetchCarOptions = useCallback(async () => {
    setOpenBackdrop(true);
    const docSnap = await getDoc(doc(getFirestore(), 'fcadmin', 'carOptions'));
    if (isMounted) {
      setCarOptions(docSnap.data());
      setOpenBackdrop(false);
    }
  }, [setCarOptions]);

  useEffect(() => {
    window.onbeforeunload = (event) => 'sure want leave?';
    isMounted = true;

    fetchCarOptions();

    return () => {
      window.onbeforeunload = null;
      isMounted = false;
      // x(0);
      // x('', { replace: false, state: 'idle' });
    };
  }, [fetchCarOptions]);

  const uploadImageFiles = async (submission) => {
    const make = submission.make.toLowerCase();
    const model = submission.model.toLowerCase();
    const gen = submission.gen.toLowerCase();
    const carModelFolderName = `${make}-${model}-${gen}`;
    const imageUploadPromises = [];
    const imageFileNameArray = [];

    submission.imageLinks.forEach((imgDataUrl, i) => {
      const imageFileName = `img-${new Date().getTime().toString()}-${i}.jpg`;
      imageFileNameArray.push(imageFileName);
      imageUploadPromises.push(
        uploadString(
          ref(storage, `gallery-new/${carModelFolderName}/${imageFileName}`),
          imgDataUrl,
          'data_url'
        )
      );
    });

    const result = await Promise.allSettled(imageUploadPromises);

    // Validate result

    result.forEach((el) => {
      if (el.status !== 'fulfilled') {
        throw Error(`Image upload failed. Reason: ${el.reason}`);
      }
    });

    // Get reference of all uploaded files

    const imgLinkPromises = [];

    imageFileNameArray.forEach((imgFileName) => {
      imgLinkPromises.push(
        getDownloadURL(ref(storage, `gallery-new/${carModelFolderName}/${imgFileName}`))
      );
    });

    const imgLinkResult = await Promise.allSettled(imgLinkPromises);

    imgLinkResult.forEach((el) => {
      if (el.status !== 'fulfilled') {
        throw Error(`Image link retrieval failed. Reason: ${el.reason}`);
      }
    });

    const imgLinkArray = imgLinkResult.map((el) => el.value);

    return imgLinkArray;
  };

  const NewBannerSchema = Yup.object().shape({
    carBodyStyle: Yup.string().required('Please select a car body style.').ensure(),
    description: Yup.string(),
    engineCapacity: Yup.number()
      .positive('This must be a positive number.')
      .required('Please enter a number.'),
    gen: Yup.string().required(
      'Please enter the model\'s generation. If there isn\'t one, type "empty".'
    ),
    // imageLinks: [''],
    make: Yup.string().required('Please select a make.').ensure(),
    model: Yup.string().required('Please select a model.').ensure(),
    seater: Yup.number()
      .positive('This must be a positive number.')
      .required('Please enter a number.'),
    // promotions: [],
    propellant: Yup.string().required('Please select a propellant.'),
    propertyBadges: Yup.array()
      .of(
        Yup.object({
          key: Yup.string().required(),
          colour: Yup.string().required(),
          label: Yup.string().required('A label is required.').trim('Do not leave empty spaces.')
        })
      )
      .ensure(),
    // propertyBadgesFilters: [],
    rentalRateDaily: Yup.number()
      .min(0, 'This must be 0 or greater.')
      .required('Please enter a number.'),
    rentalRateMonthly: Yup.number()
      .min(0, 'This must be 0 or greater.')
      .required('Please enter a number.'),
    rentalRateWeekly: Yup.number()
      .min(0, 'This must be 0 or greater.')
      .required('Please enter a number.'),
    rentalRateWeekend: Yup.number()
      .min(0, 'This must be 0 or greater.')
      .required('Please enter a number.'),
    rentalUse: Yup.string().required('Please select a rental use.'),
    transmission: Yup.string().required('Please select a transmission.'),
    mfgYear: Yup.number()
      .positive('This must be a positive number.')
      .moreThan(2000, 'The year is invalid.')
      .lessThan(new Date().getFullYear() + 1, "We don't have cars from the future.")
      .integer('The year must be an integer.')
      .required('Please enter a number.')
  });

  const initialValues = {
    carBodyStyle: carData.carBodyStyle ?? null,
    description: carData.description ?? '',
    engineCapacity: carData.engineCapacity ?? 0,
    favourites: carData.favourites ?? 0,
    gen: carData.gen ?? '',
    imageLinks: carData.imageLinks ?? [],
    make: carData.make ?? null,
    mfgYear: carData.mfgYear ?? 0,
    model: carData.model ?? null,
    promotions: [],
    // promotions: [{ colour: '', description: '', name: '' }],
    propellant: carData.propellant ?? null,
    propertyBadges:
      carData.propertyBadges?.map((el, i) => ({
        key: i,
        ...el
      })) ?? [],
    // propertyBadges: [{ colour: '', label: '' }],
    propertyBadgesFilters: [],
    // rentalRates: { daily: 0, monthly: 0, weekly: 0, weekend: 0 },
    rentalRateDaily: carData.rentalRates?.daily ?? 0,
    rentalRateMonthly: carData.rentalRates?.monthly ?? 0,
    rentalRateWeekly: carData.rentalRates?.weekly ?? 0,
    rentalRateWeekend: carData.rentalRates?.weekend ?? 0,
    rentalUse: carData.rentalUse ?? null,
    seater: carData.seater ?? 0,
    transmission: carData.transmission ?? null
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NewBannerSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setOpenBackdrop(true);
      const submission = {
        ...values,
        description: encodeURIComponent(values.description),
        rentalRates: {
          daily: values.rentalRateDaily,
          weekend: values.rentalRateWeekend,
          weekly: values.rentalRateWeekly,
          monthly: values.rentalRateMonthly
        },
        propertyBadges: selectedChips.map((el) => ({ colour: el.colour, label: el.label }))
      };
      delete submission.rentalRateDaily;
      delete submission.rentalRateWeekend;
      delete submission.rentalRateWeekly;
      delete submission.rentalRateMonthly;

      try {
        if (window.confirm('Are you sure?')) {
          const newImageDataUrlArray = [];
          const existingImageUrlAfterSubmitArray = [];
          submission.imageLinks.forEach((el) => {
            if (el.includes('data:')) {
              newImageDataUrlArray.push(el);
            } else {
              existingImageUrlAfterSubmitArray.push(el);
            }
          });

          // Upload images to Firebase Storage first and retrieve array of image urls
          let newImageUploadLinks = [];
          if (newImageDataUrlArray.length !== 0) {
            newImageUploadLinks = [
              ...(await uploadImageFiles({ ...submission, imageLinks: newImageDataUrlArray }))
            ];
            console.log(newImageUploadLinks);
          }

          let existingImageUrlsToDelete;
          if (
            carData.imageLinks !== undefined &&
            carData.imageLinks !== null &&
            carData.imageLinks.length !== 0
          ) {
            existingImageUrlsToDelete = carData.imageLinks.filter(
              (el) => !existingImageUrlAfterSubmitArray.includes(el)
            );

            // existingImageUrlsToDelete contains image Urls which user has deleted. Delete this from firestore and firebase cloud storage.
            if (existingImageUrlsToDelete.length !== 0) {
              existingImageUrlsToDelete.forEach((imgUrl) => {
                const decodedImgUrl = decodeURIComponent(imgUrl);
              });
            }
            const deletePromises = existingImageUrlsToDelete.map((el) =>
              deleteObject(ref(storage, el))
            );

            const deleteResponse = await Promise.allSettled(deletePromises);

            // Validate result

            deleteResponse.forEach((el) => {
              if (el.status !== 'fulfilled') {
                throw Error(`Image deletion failed. Reason: ${el.reason}`);
              }
            });
            console.log(existingImageUrlsToDelete);
          }

          // This block creates/updates a document in firestore

          if (
            carData.imageLinks !== undefined &&
            carData.imageLinks !== null &&
            carData.imageLinks.length !== 0
          ) {
            // Update existing model
            await updateDoc(doc(fsCtx.fsObject, 'fcadmin/models'), {
              [params.carModel]: {
                ...submission,
                imageLinks: [...newImageUploadLinks, ...existingImageUrlAfterSubmitArray]
              }
            });
          } else {
            // Create new model
            await updateDoc(doc(fsCtx.fsObject, 'fcadmin/models'), {
              [`${submission.make}${submission.model}${submission.mfgYear}${submission.gen}`]: {
                ...submission,
                imageLinks: newImageUploadLinks
              }
            });
          }

          dataCtx.fetchCarModels();

          dataCtx.setSnackbarConfig({
            open: true,
            message: 'The model has been successfully saved.',
            severity: 'success'
          });

          resetForm();
        }
      } catch (error) {
        console.error(`${error}\n${error.stack}`);
        dataCtx.setSnackbarConfig({
          open: true,
          message: error,
          severity: 'error'
        });
      }
      setSubmitting(false);
      setOpenBackdrop(false);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, handleChange, handleReset, getFieldProps } =
    formik;

  // Upload File Name Display

  const imageFileToDataUrl = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (ev) => resolve(ev.target.result);
      fileReader.onerror = (ev) => reject(ev.target.result);
      fileReader.readAsDataURL(file);
    });

  const selectFileHandler = async (event) => {
    setOpenBackdrop(true);
    try {
      if (event.target.files) {
        const filePromises = [];

        Object.values(event.target.files).forEach((uploadFile) => {
          if (!uploadFile) return;
          filePromises.push(imageFileToDataUrl(uploadFile));
        });

        const result = await Promise.allSettled(filePromises);

        result.forEach((el) => {
          if (el.status !== 'fulfilled') {
            throw Error(`One of the images could not be loaded. Reason: ${el.reason}`);
          }
        });

        const fileDataUrls = result.map((el) => el.value);

        // Validation - Throw error if this uploaded batch has duplicated files

        if (new Set(fileDataUrls).size !== fileDataUrls.length) {
          throw Error(`Do not upload duplicate files.`);
        }

        // Validation - Throw error if this uploaded batch contains a file that's already been uploaded in the website earlier

        if (
          fileDataUrls.some(
            (el) => formik.values.imageLinks.findIndex((imgLink) => imgLink === el) > -1
          )
        ) {
          console.log('dup');
          throw Error(`You already uploaded of these images earlier.`);
        }

        formik.setFieldValue('imageLinks', [...formik.values.imageLinks, ...fileDataUrls]);
      }
    } catch (error) {
      console.error(error);
      window.alert(error);
    }
    setOpenBackdrop(false);
  };

  FcAppNewCarModel.propTypes = {
    initialFormData: PropTypes.object,
    bannerEditMode: PropTypes.bool,
    bannerId: PropTypes.string,
    closeHandler: PropTypes.func
  };

  const [selectedChips, setSelectedChips] = useState(
    carData.propertyBadges?.map((el) => ({ key: el.label, ...el })) ?? []
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState();

  return (
    <FormikProvider value={formik}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PopupModal
        openState={modalOpen}
        closeHandler={() => setModalOpen(false)}
        modalBody={modalBody}
      />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction="row" justifyContent="space-between">
          <legend>
            <Typography variant="h4" sx={{ mb: 5 }}>
              {editMode ? 'Edit' : 'New'} Car Model
              {editMode ? ` - ${carData.make} ${carData.model} ${carData.mfgYear}` : ''}
            </Typography>
          </legend>
          {editMode && (
            <Button
              variant="contained"
              color="error"
              onClick={async () => {
                if (
                  window.confirm(
                    `Are you sure you want to delete ${carData.make} ${carData.model} ${carData.gen} permanently? This will delete all user favourites and permanently remove this model from their favourite list as well!`
                  )
                ) {
                  setOpenBackdrop(true);
                  try {
                    const idToken = await getAuth().currentUser.getIdToken();
                    const response = await fetch(
                      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNC}/deleteCarModel`,
                      {
                        headers: {
                          accept: 'application/json',
                          'content-type': 'application/json',
                          token: idToken
                        },
                        method: 'POST',
                        body: JSON.stringify({
                          make: carData.make,
                          model: carData.model,
                          gen: carData.gen,
                          mfgYear: carData.mfgYear
                        })
                      }
                    );
                    const respJson = await response.json();
                    if (response.status === 500) {
                      throw Error(respJson.error);
                    }
                    console.log(respJson);
                    dataCtx.setSnackbarConfig({
                      open: true,
                      message: `${carData.make} ${carData.model} ${carData.gen} has been deleted.`,
                      severity: 'success'
                    });

                    // Route user out of the model editing page because model would have already been deleted at this stage
                    nav(`/${dashboardAppRouteName}/${carModelsMainRouteName}`);

                    // Reload data from firestore and update context so model will deleted model will no longer persist in context and confuse user.
                    await dataCtx.fetchCarModels();
                  } catch (error) {
                    console.error(`${error}\n${error.stack}`);
                    dataCtx.setSnackbarConfig({
                      open: true,
                      message: error.message,
                      severity: 'error'
                    });
                  }
                  setOpenBackdrop(false);
                }
              }}
            >
              Delete Model
            </Button>
          )}
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Stack direction="column" spacing={2}>
              <Field
                name="make"
                options={carOptions.make}
                component={ModelAutocomplete}
                textFieldProps={{
                  label: 'Make'
                }}
              />
              <Field
                name="model"
                options={carOptions.model}
                component={ModelAutocomplete}
                textFieldProps={{
                  label: 'Model'
                }}
              />
              <Field
                name="carBodyStyle"
                options={carOptions.carBodyStyle}
                component={ModelAutocomplete}
                textFieldProps={{
                  label: 'Car Body Style'
                }}
              />
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Propellant</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="propellant"
                  value={formik.values.propellant}
                  onChange={handleChange}
                >
                  {carOptions.propellant.map((el2) => (
                    <FormControlLabel key={el2} value={el2} control={<Radio />} label={el2} />
                  ))}
                </RadioGroup>
                <FormHelperText error={Boolean(touched.propellant && errors.propellant)}>
                  {touched.propellant && errors.propellant}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel id="rental-use-group-label">Rental Use</FormLabel>
                <RadioGroup
                  aria-labelledby="rental-use-group-label"
                  name="rentalUse"
                  value={formik.values.rentalUse}
                  onChange={handleChange}
                >
                  {carOptions.rentalUse.map((el3) => (
                    <FormControlLabel key={el3} value={el3} control={<Radio />} label={el3} />
                  ))}
                </RadioGroup>
                <FormHelperText error={Boolean(touched.rentalUse && errors.rentalUse)}>
                  {touched.rentalUse && errors.rentalUse}
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel id="transmission-group-label">Transmission</FormLabel>
                <RadioGroup
                  aria-labelledby="transmission-group-label"
                  name="transmission"
                  value={formik.values.transmission}
                  onChange={handleChange}
                >
                  {carOptions.transmission.map((el4) => (
                    <FormControlLabel key={el4} value={el4} control={<Radio />} label={el4} />
                  ))}
                </RadioGroup>
                <FormHelperText error={Boolean(touched.transmission && errors.transmission)}>
                  {touched.transmission && errors.transmission}
                </FormHelperText>
              </FormControl>
              {fields.map((el) => (
                <TextField
                  key={el.fieldKey}
                  label={el.label}
                  name={el.fieldKey}
                  placeholder={el.placeholder}
                  type={el.type}
                  value={formik.values[el.fieldKey]}
                  onChange={handleChange}
                  InputLabelProps={el.InputLabelProps}
                  InputProps={el.InputProps}
                  error={Boolean(touched[el.fieldKey] && errors[el.fieldKey])}
                  helperText={touched[el.fieldKey] && errors[el.fieldKey]}
                />
              ))}
              {/* <TextField
                id="outlined-multiline-static"
                label="Rate - Daily"
                name="rentalRateDaily"
                placeholder="Enter a number"
                type="number"
                value={formik.values.rentalRateDaily}
                onChange={handleChange}
                InputProps={{ startAdornment: `$\u00A0` }}
                error={Boolean(touched.rentalRateDaily && errors.rentalRateDaily)}
                helperText={touched.rentalRateDaily && errors.rentalRateDaily}
              /> */}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Stack direction="column" spacing={2}>
              <ImageList
                cols={5}
                rowHeight={200}
                sx={
                  {
                    // height: formik.values.imageLinks.length === 0 ? 0 : 200
                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center'
                  }
                }
              >
                {formik.values.imageLinks.map((imgUrl) => (
                  <ImageListItem key={imgUrl}>
                    <ImageListItemBar
                      title=""
                      position="below"
                      actionIcon={
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            console.log('delete image');
                            formik.setFieldValue(
                              'imageLinks',
                              formik.values.imageLinks.filter((el) => el !== imgUrl)
                            );
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    />
                    <button
                      type="button"
                      style={{
                        outline: 'none',
                        border: 'none',
                        height: '100%',
                        backgroundColor: 'transparent'
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.cursor = 'pointer';
                      }}
                      onFocus={() => {}}
                      onClick={() => {
                        setModalOpen(true);
                        setModalBody(
                          <Carousel>
                            {formik.values.imageLinks.map((imgUrl) => (
                              <div key={imgUrl}>
                                <img
                                  src={imgUrl}
                                  // width={Math.min(500, window.innerWidth / 2)}
                                  alt={imgUrl}
                                  loading="lazy"
                                  style={{ borderRadius: '10px' }}
                                />
                                {/* <p className="legend">Legend 1</p> */}
                              </div>
                            ))}
                          </Carousel>
                        );
                      }}
                    >
                      <img
                        src={imgUrl}
                        alt={imgUrl}
                        loading="lazy"
                        style={{ borderRadius: '10px' }}
                      />
                    </button>
                  </ImageListItem>
                ))}
              </ImageList>
              <label htmlFor="contained-button-file">
                <input
                  style={{ display: 'none' }}
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={selectFileHandler}
                />
                <Button variant="contained" component="span">
                  Upload
                </Button>
              </label>
              <Field
                name="propertyBadges"
                component={PropertyBadgesAutocomplete}
                selectedChips={selectedChips}
                setSelectedChips={setSelectedChips}
                textFieldProps={{
                  label: (
                    <>
                      Property Badges
                      <Tooltip
                        title={
                          <>
                            These property badges show up on the top-right corner on the car image
                            in the Catalog Screen
                            <img src={PropertyTooltipScreenshot} alt="screenshot" />
                          </>
                        }
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                  placeholder:
                    'Type something, press enter. Click on the badge to change its colour.'
                }}
              />
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                {...getFieldProps('description')}
                multiline
                rows={7}
                placeholder="Type your description here"
                onChange={handleChange}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
              <Box sx={{ '& button': { m: 1 } }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {props.bannerEditMode ? 'Save' : 'Submit'}
                </LoadingButton>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    formik.resetForm({ values: initialValues });
                    // handleReset();
                    setSelectedChips(initialValues.propertyBadges);
                  }}
                >
                  {props.bannerEditMode ? 'Reset' : 'Clear'}
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
