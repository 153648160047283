import PropTypes from "prop-types";
import moment from 'moment';
import { Typography } from '@mui/material';
import FcTable from '../FcTable';

const AmountCol = ({ row }) => {
  return (
    <Typography color={row.amount >= 0 ? "rgb(0, 220, 0)" : "rgb(240, 0, 0)"}>
      {row.amount >= 0 ? "+" : "–"} ${Number(Math.abs(row.amount) / 100).toFixed(2)}
    </Typography>
  );
}

AmountCol.propTypes = {
  row: PropTypes.object,
}

export default function ShellTransactionTable({ data }) {
  const columns = [
    {
      field: "amount", headerName: "Amount", align: "center", headerAlign: "center", minWidth: 125, sortable: true,
      renderCell: ({ row }) => <AmountCol row={row} />,
    },
    {
      field: 'fullName', headerName: "Full Name", align: "center", headerAlign: "center", minWidth: 150, sortable: true,
      renderCell: ({ row }) => row.user?.fullName,
    },
    {
      field: 'phone', headerName: "Phone", align: "center", headerAlign: "center", minWidth: 150, sortable: true,
      renderCell: ({ row }) => row.user?.phone,
    },
    {
      field: 'identity', headerName: "NRIC / FIN", align: "center", headerAlign: "center", minWidth: 150, sortable: true,
      renderCell: ({ row }) => row.user?.identity,
    },
    {
      field: 'email', headerName: "Email", align: "center", headerAlign: "center", minWidth: 150, sortable: true,
      renderCell: ({ row }) => row.user?.email,
    },
    { field: 'pan', headerName: "PAN", align: "center", headerAlign: "center", minWidth: 150, sortable: true },
    { field: "description", headerName: "Description", align: "center", headerAlign: "center", minWidth: 250, sortable: false },
    {
      field: "createdAt", headerName: "Created At", align: "center", headerAlign: "center", minWidth: 250, sortable: true,
      renderCell: ({row}) => moment(row.createdAt).format("DD MMM YYYY hh:mm:ss A"),
    },
  ];

  return (
    <FcTable columns={columns} rows={data} />
  );
}

ShellTransactionTable.propTypes = {
  data: PropTypes.array
}