import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Box, Container, Tab} from "@mui/material";
import {useState} from "react";
import palette from "../../theme/palette";
import DisposalTaskList from "./Disposal/DisposalTaskList";
import InsuranceCompanyList from "./Insurance/InsuranceCompanyList";
import VehicleMakeList from "./Make/VehicleMakeList";
import VehicleOwnerList from "./Owner/VehicleOwnerList";
import VehicleConditionTypeListing from "./VehicleConditionTypeListing";

export default function VehicleSettings() {
  const [tabValue, setTabValue] = useState("make");

  return (
    <Container maxWidth="xl">
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: palette.divider }}>
          <TabList onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="Makes/Models" value="make"/>
            <Tab label="Vehicle Conditions" value="condition"/>
            <Tab label="Owner Companies" value="owner"/>
            <Tab label="Insurance Companies" value="insurance"/>
            <Tab label="Disposal Tasks" value="disposal"/>
          </TabList>
        </Box>

        <TabPanel value="make">
          <VehicleMakeList/>
        </TabPanel>
        <TabPanel value="condition">
          <VehicleConditionTypeListing/>
        </TabPanel>
        <TabPanel value="owner">
          <VehicleOwnerList/>
        </TabPanel>
        <TabPanel value="insurance">
          <InsuranceCompanyList/>
        </TabPanel>
        <TabPanel value="disposal">
          <DisposalTaskList/>
        </TabPanel>
      </TabContext>
    </Container>
  )
}