import { DialogContent } from "@material-ui/core";
import { Box, Button, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import CommissionPointForm from "../../../../components/Form/Sales/OTR/CommissionPointForm";
import CommissionPointTable from "../../../../components/Table/OTR/CommissionPointTable";
import PreviewCommissionPointTable from "../../../../components/Table/OTR/PreviewCommissionPointTable";
import useHttpGet from "../../../../hooks/http/useHttpGet";

const AddAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Add Missing Contract</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <CommissionPointForm onReload={onReload} onClose={() => setOpen(false)} />
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Add</Button>
    </>
  );
}

AddAction.propTypes = {
  onReload: PropTypes.func,
}

export default function CommissionPoints({ user, date }) {
  const { data: commissions, onGet } = useHttpGet("/api/booking/commission", false);
  const { data: previewCommissions, onGet: onPreviewGet } = useHttpGet("/api/booking/commission/preview", false);

  const onReload = () => {
    onGet({ userId: user?.uid ?? "", startDate: dayjs(date).toISOString() });
    onPreviewGet({ userId: user?.uid ?? "", startDate: dayjs(date).toISOString() });
  }

  useEffect(() => {
    onReload();
  }, [user, date]);

  const longTermCommissions = previewCommissions?.filter(commission => commission?.booking?.pricePeriod?.minRentalPeriod >= 28);
  const shortTermCommissions = previewCommissions?.filter(commission => commission?.booking?.pricePeriod?.minRentalPeriod < 28);

  return (
    <>
      <Typography variant="h4" mb={3}>Preview Contracts</Typography>
      <Typography variant="h5" mb={3}>Long Term Contracts (> 1 Month) - OTR</Typography>
      <PreviewCommissionPointTable data={longTermCommissions} onReload={onReload} />
      <Typography variant="h5" my={3}>Short Term Contracts - OTR</Typography>
      <PreviewCommissionPointTable data={shortTermCommissions} onReload={onReload} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" my={3}>
        <Typography variant="h4">Added Contracts</Typography>
        <AddAction onReload={onReload} />
      </Stack>
      <CommissionPointTable data={commissions} onReload={onReload} />
    </>
  );
}

CommissionPoints.propTypes = {
  user: PropTypes.object,
  date: PropTypes.object,
}