import {Button, Stack} from "@mui/material";
import {GridToolbar} from "@mui/x-data-grid";
import dayjs from "dayjs";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import useHttpGet from "../../../hooks/http/useHttpGet";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import {getDayjsValue} from "../../../utils/date";
import FcTable from "../FcTable";

const ActionCol = ({ row }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <Button variant="contained" component={Link} to={`./${row.id}/dispose`} target="_blank">Dispose</Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
}

export default function DisposalVehicleTable({ data }) {
  const { data: tasks } = useHttpGet("/api/Vehicles/disposal/task");
  const handoverTask = tasks?.find(task => task.task === "Handover car to exporter");
  const uploadTask = tasks?.find(task => task.task === "Upload disposal documents in Onemotoring");

  const columns = [
    { field: 'vehicleNumber', headerName: 'Vehicle No.', align: "center", headerAlign: "center", width: 125, sortable: false, sticky: true },
    {
      field: "model.make.name", headerName: "Make / Model", align: "center", headerAlign: "center", width: 200, sortable: false, sticky: true,
      renderCell: ({row}) => `${row.model.make.name} ${row.model.name}`,
    },
    {
      field: 'logcard.isParf', headerName: 'PARF / COE', align: "center", headerAlign: "center", width: 120,
      renderCell: ({row}) => row?.logcard?.isParf ? "PARF" : "COE",
    },
    {
      field: 'logcard.vehicleOwner.name', headerName: 'Company Name', align: "center", headerAlign: "center", width: 175,
      renderCell: ({row}) => row?.logcard?.vehicleOwner?.name,
    },
    {
      field: 'logcard.coeExpiryDate', headerName: 'COE Expiry Date', align: "center", headerAlign: "center", width: 175,
      renderCell: ({row}) => getDayjsValue(row?.logcard?.coeExpiryDate).format("DD MMM YYYY"),
    },
    {
      field: 'logcard.finalDisposalDueDate', headerName: 'DISPOSAL DUE DATE', align: "center", headerAlign: "center", width: 200,
      renderCell: ({row}) => getDayjsValue(row?.logcard?.coeExpiryDate).add(1, "month").add(-1, "day").format("DD MMM YYYY"),
    },
    { field: 'vehicleColor', headerName: 'Vehicle Color', align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: 'vehicleShape', headerName: 'Vehicle Shape', align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: '', headerName: 'Action', align: "center", headerAlign: "center", width: 125, sortable: false,
      renderCell: ({row}) => <ActionCol row={row}/>
    },
  ];

  const themeSelect = (row) => {
    const currentDate = dayjs();
    const endDate = getDayjsValue(row?.logcard?.coeExpiryDate).add(1, "month").add(-1, "day");
    const diff = endDate.diff(currentDate, "days");

    const { disposalTaskChecks } = row;
    const latestTaskCheck = disposalTaskChecks.length > 0 ?
      disposalTaskChecks
        .filter(c => c.doneAt != null)
        .sort((a, b) => b.order - a.order)[0] : null;

    if (diff <= 7 && (latestTaskCheck?.task?.order ?? 0) < uploadTask?.order) return 'Ended';
    if (diff <= 14 && (latestTaskCheck?.task?.order ?? 0) < handoverTask?.order) return 'Handover';
    return 'primary';
  }

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      getRowClassName={({row}) => `vehicle-theme--${themeSelect(row)}`}
      rows={data} columns={columns}
      disableSelectionOnClick
      sx={{ height: window.innerHeight * 0.75 }}
    />
  );
}

DisposalVehicleTable.propTypes = {
  data: PropTypes.array,
}