import React from "react";
import {
  Box,
  Container,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import VehicleForm from "../../components/Form/Vehicle/VehicleForm";

export default function NewVehicle() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      <Typography
        sx={{ cursor: "pointer", marginBottom: 3 }} variant="h4" gutterBottom
        onClick={() => navigate("../")}
      >
        <span style={{position: "relative", top: 3}}>
          <ArrowBackSharpIcon />
        </span>
        &nbsp;&nbsp;
        Back
      </Typography>
      <Box mt={3}>
        <VehicleForm onCancel={() => navigate("../")}/>
      </Box>
    </Container>
  );
}