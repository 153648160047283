import {LoadingButton} from "@material-ui/lab";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {GridToolbar} from "@mui/x-data-grid";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPut from "../../../hooks/http/useHttpPut";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import DataContext from "../../../store/DataContext";
import {defaultTimezone, getDayjsValue} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";
import CollectionDetails from "../../Details/Collection/CollectionDetails";

dayjs.extend(utc);
dayjs.extend(timezone);

const ViewAction = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row?.receiptNo} Details</DialogTitle>
        <DialogContent>
          <CollectionDetails collection={row}/>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to={getFile(row?.receiptAttachment)} target="_blank" variant="contained">Print</Button>
          <Button variant="outlined" onClick={() => setOpen(false)} sx={{ ml: 1 }}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  )
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const VerifyAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const { onPut, loading } = useHttpPut(`/api/collection/${row?.id}/verify`);

  const onVerify = async e => {
    e.preventDefault();
    try {
      await onPut();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: `${row?.isVerified ? "Unverify" : "Verify"} successfully!`, severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <LoadingButton loading={loading} variant="contained" color="secondary" onClick={onVerify}>
      {row?.isVerified ? "Unverify" : "Verify"}
    </LoadingButton>
  );
}

VerifyAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <VerifyAction row={row} onReload={onReload}/>
      <Button component={Link} to={`/dashboard/sales/booking/${row.bookingNo}`} target="_blank" variant="contained" color="secondary">
        Booking
      </Button>
      <ViewAction row={row}/>
      <Button component={Link} to={getFile(row?.receiptAttachment)} target="_blank" variant="contained">Print</Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function CollectionTable({ paginatedData, loading, onReload, sortModel, handleSortModelChange, handlePageChange, handlePageSizeChange, selectionModel, setSelectionModel, type = "collection" }) {
  const { data: users } = useHttpGet("/api/user/list");
  const userMap = {};
  users?.forEach(user => userMap[user.uid] = user);

  const columns = [
    { field: "receiptNo", headerName: type === "credit-note" ? "Credit No." : "Receipt No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "bookingNo", headerName: "Booking No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 100, sortable: false },
    {
      field: "amount", headerName: "Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => `S$${row.amount}`,
    },
    { field: "paymentMethod", headerName: "Payment Method", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "customerName", headerName: "Customer Name", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "userId", headerName: "User", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => userMap[row.userId]?.name,
    },
    {
      field: "collectDate", headerName: type === "credit-note" ? "Credit Date" : "Collect Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => getDayjsValue(row.collectDate).format("DD MMM YYYY"),
    },
    {
      field: "isVerified", headerName: "Verified", align: "center", headerAlign: "center", width: 100, sortable: false,
      renderCell: ({ row }) => <Checkbox checked={row.isVerified}/>
    },
    {
      field: "verifiedUserId", headerName: "Verified User", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => userMap[row.verifiedUserId]?.name,
    },
    {
      field: "createdAt", headerName: "Created Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => getDayjsValue(row.createdAt).format("DD MMM YYYY"),
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 350,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>,
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      rows={paginatedData?.data ?? []}
      columns={columns}
      autoHeight
      disableSelectionOnClick
      checkboxSelection
      loading={loading}
      rowCount={paginatedData?.totalRows ?? 0}
      pageSizeOptions={[25, 50, 100]}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      paginationMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      sortingMode="server"
      onSelectionModelChange={newRowSelectionModel => setSelectionModel(newRowSelectionModel)}
      selectionModel={selectionModel}
    />
  );
}

CollectionTable.propTypes = {
  paginatedData: PropTypes.object,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
  sortModel: PropTypes.array,
  handlePageChange: PropTypes.func,
  handlePageSizeChange: PropTypes.func,
  handleSortModelChange: PropTypes.func,
  selectionModel: PropTypes.array,
  setSelectionModel: PropTypes.func,
  type: PropTypes.string,
}