import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import ImportAddendumForm from "../../components/Form/DataImport/ImportAddendumForm";
import ImportAgreementForm from "../../components/Form/DataImport/ImportAgreementForm";
import ImportAgreementSurchargeForm from "../../components/Form/DataImport/ImportAgreementSurchargeForm";
import ImportAgreementVehicleConditionsForm from "../../components/Form/DataImport/ImportAgreementVehicleConditionsForm";
import ImportBookingForm from "../../components/Form/DataImport/ImportBookingForm";
import ImportBookingSurchargeForm from "../../components/Form/DataImport/ImportBookingSurchargeForm";
import ImportCollectionForm from "../../components/Form/DataImport/ImportCollectionForm";
import ImportCreditNoteItemForm from "../../components/Form/DataImport/ImportCreditNoteItemForm";
import ImportCustomerDocumentForm from "../../components/Form/DataImport/ImportCustomerDocumentForm";
import ImportCustomerForm from "../../components/Form/DataImport/ImportCustomerForm";
import ImportInvoiceForm from "../../components/Form/DataImport/ImportInvoiceForm";
import ImportInvoiceItemCollectionForm from "../../components/Form/DataImport/ImportInvoiceItemCollectionForm";
import ImportInvoiceItemForm from "../../components/Form/DataImport/ImportInvoiceItemForm";
import ImportRefundVoucherForm from "../../components/Form/DataImport/ImportRefundVoucherForm";
import ImportVehicleOwnersForm from '../../components/Form/DataImport/ImportVehicleOwnersForm';
import ImportVehicleMakesForm from '../../components/Form/DataImport/ImportVehicleMakesForm';
import ImportVehicleModelsForm from '../../components/Form/DataImport/ImportVehicleModelsForm';
import ImportVehiclesForm from '../../components/Form/DataImport/ImportVehiclesForm';
import ImportVehicleLogcardsForm from '../../components/Form/DataImport/ImportVehicleLogcardsForm';
import ImportVehicleInsurancesForm from '../../components/Form/DataImport/ImportVehicleInsurancesForm';
import ImportVehicleUsageRecordsForm from "../../components/Form/DataImport/ImportVehicleUsageRecordsForm";
import useHttpGet from "../../hooks/http/useHttpGet";

export default function DataImport() {
  const { data: users } = useHttpGet("/api/user/list");

  const userMap = {};
  users?.forEach(user => userMap[user.uid] = user);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Data Import</Typography>
      </Stack>
      <Stack spacing={3}>
        <ImportVehicleOwnersForm />
        <ImportVehicleMakesForm />
        <ImportVehicleModelsForm />
        <ImportVehiclesForm />
        <ImportVehicleLogcardsForm />
        <ImportVehicleInsurancesForm />
        <ImportCustomerForm />
        <ImportCustomerDocumentForm />
        <ImportBookingForm userMap={userMap} />
        <ImportBookingSurchargeForm />
        <ImportAgreementForm />
        <ImportAgreementSurchargeForm />
        <ImportAgreementVehicleConditionsForm />
        <ImportAddendumForm />
        <ImportInvoiceForm userMap={userMap} />
        <ImportInvoiceItemForm />
        <ImportCollectionForm userMap={userMap} />
        <ImportInvoiceItemCollectionForm />
        <ImportCreditNoteItemForm />
        <ImportRefundVoucherForm userMap={userMap} />
        <ImportVehicleUsageRecordsForm />
      </Stack>
    </Container>
  );
}