import { Grid, Divider, Typography, Box, Autocomplete } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Warning } from '@mui/icons-material';

import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import {
  FcDateField,
  FcSelectField,
  FcTextArea,
  FcTextField,
  SubmitBtnGroup
} from '../../FormFields';
import palette from '../../../../theme/palette';
import DataContext from '../../../../store/DataContext';
import useCloudFuncPut from '../../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { cloudFunctionPath } from '../../../../cloudFunctionApiConfig';

export default function CreateMissingPriceIncreaseForm({
  dayjs,
  onClose,
  onReload,
  currentSalesPerson,
  date,
  carData
}) {
  const dataCtx = useContext(DataContext);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/createPriceIncrease`);

  const OnAdd = async (values) => {
    console.log('values: ', values);
    try {
      const data = {
        ...values,
        currentSalesPerson,
        startDate: dayjs(values.startDate).toISOString(),
        endDate: dayjs(values.endDate).toISOString(),
        date
      };

      await onPut(data);
      await onReload();
      onOpenSnackbar('Missing Contract added successfully');

      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      startDate: dayjs(date).toISOString(),
      endDate: dayjs(date).toISOString(),
      bookingNo: '',
      rentalAgreementNo: '',
      hirerName: '',
      baseRentalRate: '',
      rentalRate: '',
      periodType: 'LongTerm',
      billingType: '',
      customerType: '',
      newOrExistingCustomer: '',
      carPlate: '',
      remarks: '',
      carMake: '',
      carModel: '',
      purchaseType: ''
    },
    validationSchema: Yup.object({
      startDate: Yup.date().required('Please select the start date!'),
      endDate: Yup.date()
        .required('Please select the end date!')
        .test('is-in-current-otr-month', 'End date must be in the current OTR month', (value) => {
          const currentDate = dayjs(date);
          const endDate = dayjs(value);
          return (
            currentDate.year() === endDate.year() && currentDate.month() === endDate.month()
          );
        }),
      bookingNo: Yup.string().required('Please fill in the booking no!'),
      rentalAgreementNo: Yup.string().required('Please fill in the agreement no!'),
      hirerName: Yup.string().required('Please fill in the hirer name!'),
      baseRentalRate: Yup.number().required('Please fill in the base rental rate!'),
      rentalRate: Yup.number().required('Please fill in the rental rate!'),
      periodType: Yup.string().required('Please select the contract type!'),
      billingType: Yup.string().required('Please select the billing type!'),
      customerType: Yup.string().required('Please select the customer type!'),
      newOrExistingCustomer: Yup.string().required('Please select the new or existing!'),
      carPlate: Yup.string().required('Please fill in the car plate!'),
      remarks: Yup.string().required('Please fill in remarks!'),
      carMake: Yup.string().required('Please fill in the car make!'),
      carModel: Yup.string().required('Please fill in the car model!'),
      purchaseType: Yup.string().required('Please fill in the purchaseType')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await OnAdd(values);

      setSubmitting(false);
    }
  });

  const handleCarPlateChange = (event, value) => {
    if (value) {
      formik.setFieldValue('carPlate', value.VehicleNo);
      formik.setFieldValue('carMake', value.VehicleMake);
      formik.setFieldValue('carModel', value.VehicleModel);
      formik.setFieldValue('purchaseType', value.PurchaseType);
    } else {
      formik.setFieldValue('carPlate', '');
      formik.setFieldValue('carMake', '');
      formik.setFieldValue('carModel', '');
    }
  };

  const calculateNumberOfMonths = (startDate, endDate) => {
    const diff = dayjs(endDate).add(1, 'day').diff(startDate, 'day', true);
    return diff >= 28 ? Math.floor(diff / 28) : 1;
  };

  calculateNumberOfMonths.propTypes = { startDate: PropTypes.string, endDate: PropTypes.string };

  const calculatePriceIncrease = (billingType, rentalRate, baseRentalRate) => {
    const priceIncreasePerDay =
      billingType === 'Weekly'
        ? (rentalRate - baseRentalRate) / 7
        : (rentalRate - baseRentalRate) / 30;

    return 3 * Math.floor(priceIncreasePerDay.toFixed(2) / 3);
  };

  calculatePriceIncrease.propTypes = {
    billingType: PropTypes.string,
    rentalRate: PropTypes.number,
    baseRentalRate: PropTypes.number
  };

  const calculatePriceIncreasePoints = (priceIncrease, diffInMonth) => {
    return (priceIncrease / 3) * Math.min(3, diffInMonth) * 30;
  };

  calculatePriceIncreasePoints.propTypes = {
    priceIncrease: PropTypes.number,
    diffInMonth: PropTypes.number
  };

  useEffect(() => {
    const { billingType, rentalRate, baseRentalRate, startDate, endDate } = formik.values;
    const diffInMonth = calculateNumberOfMonths(startDate, endDate);
    formik.setFieldValue('numberOfMonths', diffInMonth);
    const priceIncrease = calculatePriceIncrease(billingType, rentalRate, baseRentalRate);
    formik.setFieldValue('priceIncrease', priceIncrease);
    const points = calculatePriceIncreasePoints(priceIncrease, diffInMonth);
    formik.setFieldValue('pointsPerDay', priceIncrease / 3);
    formik.setFieldValue('points', points);
  }, [
    formik.values.billingType,
    formik.values.rentalRate,
    formik.values.baseRentalRate,
    formik.values.startDate,
    formik.values.endDate
  ]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Typography variant="subtitle1">Agreement Details</Typography>
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcDateField formik={formik} name="startDate" label="Start Date" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcDateField formik={formik} name="endDate" label="End Date" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField formik={formik} name="bookingNo" label="Booking No." />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField formik={formik} name="rentalAgreementNo" label="Agreement No." />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcSelectField
              formik={formik}
              name="periodType"
              label="Contract Type"
              items={[
                { label: 'Short Term Contract', value: 'ShortTerm' },
                { label: 'Long Term Contract', value: 'LongTerm' }
              ]}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcSelectField
              formik={formik}
              name="billingType"
              label="Billing Type"
              items={[
                { label: 'Daily', value: 'Daily', disabled: true },
                { label: 'Weekend', value: 'Weekend', disabled: true },
                { label: 'Weekly', value: 'Weekly' },
                { label: 'Monthly', value: 'Monthly' }
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Autocomplete
              fullWidth
              options={carData}
              getOptionLabel={(option) => option.VehicleNo}
              onChange={handleCarPlateChange}
              renderInput={(params) => <TextField {...params} label="CarPlate" />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              name="carMake"
              label="Car Make"
              type="text"
              value={formik.values.carMake}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              name="carModel"
              label="Car Model"
              type="text"
              value={formik.values.carModel}
              disabled
            />
          </Grid>
        </Grid>
        <Divider sx={{ border: 1, borderColor: palette.divider, my: 2 }} />
        <Typography variant="subtitle1">Customer Details</Typography>
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FcTextField formik={formik} name="hirerName" label="Hirer Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcSelectField
              formik={formik}
              name="newOrExistingCustomer"
              label="New/Existing Customer"
              items={[
                { label: 'New', value: 'New' },
                { label: 'Existing', value: 'Existing' }
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcSelectField
              formik={formik}
              name="customerType"
              label="Customer Type"
              items={[
                { label: 'Private Hire', value: 'Private Hire' },
                { label: 'Private Use', value: 'Private Use' }
              ]}
            />
          </Grid>
        </Grid>
        <Divider sx={{ border: 1, borderColor: palette.divider, my: 2 }} />
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField
              formik={formik}
              name="rentalRate"
              label="Rental Rate (SGD)"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField
              formik={formik}
              name="baseRentalRate"
              label="Base Rental Rate (SGD)"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              name="priceIncrease"
              label="Price Increase Per Day (SGD)"
              type="number"
              value={formik.values.priceIncrease}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              name="points"
              label="Price Increase Points"
              type="number"
              value={formik.values.points}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks" />
          </Grid>
        </Grid>
        <SubmitBtnGroup formik={formik} method="Submit" onCancel={onClose} />
        <Divider sx={{ border: 1, borderColor: palette.divider, my: 2 }} />

        <Box display="flex" alignItems="center">
          <Warning color="warning" sx={{ mr: 1 }} />
          <Typography variant="caption">
            Warning: Please verify all details before submission. This action is irreversible.
          </Typography>
        </Box>
      </Form>
    </FormikProvider>
  );
}

CreateMissingPriceIncreaseForm.propTypes = {
  dayjs: PropTypes.func,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  currentSalesPerson: PropTypes.string,
  date: PropTypes.string,
  carData: PropTypes.array
};
