import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { cloudFunctionPath } from '../../cloudFunctionApiConfig';
import useCloudFuncPost from '../../hooks/firebase/cloudFunction/useCloudFuncPost';
import LowerUpperBoundDateField from '../../components/Workshop/LowerUpperBoundDateField';
import WorkshopRequestAcc from '../../components/Workshop/WorkshopRequestAcc';

export default function ViewAssignedJobWorkshop() {
  const { data, onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/request/get`);

  const [startDate, setStartDate] = useState(dayjs(new Date().toDateString()));
  const [endDate, setEndDate] = useState(dayjs(new Date().toDateString()).add(5, 'day'));

  const onReload = async () => {
    await onPost({ lowerBoundTime: startDate.unix() * 1000, upperBoundTime: endDate.unix() * 1000 })
  }

  useEffect(() => {
    onPost({ lowerBoundTime: startDate.unix() * 1000, upperBoundTime: endDate.unix() * 1000 });
  }, [startDate, endDate]);

  return (
    <Box sx={{ padding: { xs: 1, md: 0 } }}>
      <Typography
        variant="h4"
        gutterBottom
        mb={3}
        sx={{ textAlign: { xs: 'center', md: 'start' }, fontSize: { xs: 26, md: 24 } }}
      >
        Viewing Assigned Jobs
      </Typography>

      <LowerUpperBoundDateField
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      { [... new Set(data.map((r) => r.assignedDate))].map((date) => {
        const filtered = data.filter((r) => r.assignedDate === date);
        return (
          <Accordion defaultExpanded={new Date(new Date().toDateString()).getTime() === date} key={date} elevation={10}>
            <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
              <Typography fontSize={18} fontWeight={700}>
                { moment(date).format("DD MMM YYYY") }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              { filtered.map((r) => <WorkshopRequestAcc key={r.id} request={r} onReload={onReload} />) }
            </AccordionDetails>
          </Accordion>
        );
      }) }
    </Box>
  );
}