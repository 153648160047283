import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useHttpGet from "../../../hooks/http/useHttpGet";
import CommissionPoints from "./Tabs/CommissionPoints";
import LongTermPoints from "./Tabs/LongTermPoints";
import PriceIncreasePoints from "./Tabs/PriceIncreasePoints";

const FilterForm = ({ users, filter, setFilter }) => {
  const userItems = users?.filter(user => user.department === "sales")
    ?.map(user => ({ label: user.name, value: user.uid })) ?? [];
  userItems.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormControl fullWidth>
          <InputLabel>User</InputLabel>
          <Select
            label="User"
            value={filter.userId}
            onChange={e => setFilter({ ...filter, userId: e.target.value })}
          >
            {userItems?.map(({ label, value, disabled = false }, index) => (
              <MenuItem value={value} key={index} disabled={disabled}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              view="month"
              disableMaskedInput
              label="Date"
              inputFormat="MM-YYYY"
              onChange={(val) => setFilter({ ...filter, startDate: val })}
              value={filter.startDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
    </Grid>
  );
}

FilterForm.propTypes = {
  users: PropTypes.array,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
}

export default function OtrListing() {
  const [tabValue, setTabValue] = useState("commission");
  const [filter, setFilter] = useState({
    userId: null,
    startDate: dayjs().startOf("month"),
  });

  const { data: users } = useHttpGet("/api/user/list");
  const user = users?.find(u => u.uid === filter.userId);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4">Sales | OTR</Typography>
      <Box my={3}>
        <FilterForm users={users} filter={filter} setFilter={setFilter} />
      </Box>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="Commissions Point - OTR" value="commission"/>
            <Tab label="Long Term Points" value="long-term"/>
            <Tab label="Price Increase Points" value="price-increase"/>
          </TabList>
        </Box>

        <TabPanel value="commission">
          <CommissionPoints user={user} date={filter.startDate} />
        </TabPanel>
        <TabPanel value="long-term">
          <LongTermPoints user={user} date={filter.startDate} />
        </TabPanel>
        <TabPanel value="price-increase">
          <PriceIncreasePoints user={user} date={filter.startDate} />
        </TabPanel>
      </TabContext>
    </Container>
  );
}