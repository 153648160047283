import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import moment from 'moment';

import FirestoreContext from '../../store/FirestoreContext';
import errorHandler from '../../utils/errorHandler';

/**
 * @param {{initialFormData: PropTypes.object, editMode: PropTypes.bool, newsId: PropTypes.string, closeHandler: PropTypes.func}} props
 */

const NewNews = (props) => {
  // Send messages firebase and App users
  const fsCtx = useContext(FirestoreContext);

  // New News Form logic

  const newNewsSchema = Yup.object().shape({
    date: Yup.date().required('A date is required.'),
    imgUrl: Yup.string()
      .url('Please enter a valid url.')
      .required('A url link to an image is required.'),
    title: Yup.string().required('A title is required.'),
    url: Yup.string()
      .url('Please enter a valid url.')
      .required('A url link to the news article is required.')
  });

  const initialValues = {
    date: moment(
      props.initialFormData === undefined ? new Date() : props.initialFormData.date.toDate()
    ).format('YYYY-MM-DD'),
    imgUrl: props.initialFormData === undefined ? '' : props.initialFormData.imgUrl,
    title: props.initialFormData === undefined ? '' : props.initialFormData.title,
    url: props.initialFormData === undefined ? '' : props.initialFormData.url
  };

  const formik = useFormik({
    initialValues,
    validationSchema: newNewsSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (window.confirm('Are you sure?')) {
        try {
          if (props.editMode) {
            await updateDoc(doc(fsCtx.fsObject, 'fcadmin/fcadmin/news', props.newsId), {
              ...values,
              date: new Date(values.date)
            });
          } else {
            await addDoc(collection(fsCtx.fsObject, 'fcadmin', 'fcadmin', 'news'), {
              ...values,
              date: new Date(values.date)
            });
            resetForm();
          }
          alert(`The news article has been ${props.editMode ? 'saved' : 'created'}.`);
          window.location.reload();
        } catch (error) {
          errorHandler(JSON.stringify(values), 'New News Article', error);
          setSubmitting(false);
          return;
        }
      }
      setSubmitting(false);
    }
  });

  const { errors, touched, isSubmitting, handleBlur, handleReset, handleSubmit, getFieldProps } =
    formik;

  NewNews.propTypes = {
    initialFormData: PropTypes.object,
    editMode: PropTypes.bool,
    newsId: PropTypes.string,
    closeHandler: PropTypes.func
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {props.editMode ? 'Edit' : 'New'} News
          </Typography>

          {Object.keys(initialValues).map((el) =>
            el === 'date' ? (
              <TextField
                label={el}
                key={el}
                type="date"
                {...getFieldProps(el)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : (
              <TextField
                key={el}
                type="text"
                value={formik.values[el]}
                onBlur={handleBlur}
                onChange={(e) => {
                  formik.setFieldValue(el, e.target.value);
                }}
                label={el}
                // {...getFieldProps(el)}
                error={Boolean(touched[el] && errors[el])}
                helperText={touched[el] && errors[el]}
              />
            )
          )}

          <img src={formik.values.imgUrl} alt="" style={{ objectFit: 'contain' }} />

          <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
            {props.editMode ? 'Save' : 'Submit'}
          </LoadingButton>

          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              handleReset();
            }}
          >
            {props.editMode ? 'Reset' : 'Clear'}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
export default NewNews;
