import { Icon } from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Link as ReactLink } from 'react-router-dom';
import { useContext } from 'react';
import { Card, Link, Typography } from '@mui/material';

import DataContext from '../../../store/DataContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppNewUsers() {
  const dataCtx = useContext(DataContext);

  return (
    <Link component={ReactLink} to="/dashboard/fc-app/app-users" sx={{ textDecoration: 'none' }}>
      <RootStyle>
        <IconWrapperStyle>
          <Icon icon={peopleFill} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{dataCtx.appUsersCount}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          App Users
        </Typography>
      </RootStyle>
    </Link>
  );
}
