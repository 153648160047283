import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { GridToolbar } from '@mui/x-data-grid';
import { a11yProps, TabPanel } from '../../components/TabPanel';
import { StyledDataGrid } from './styles';
import { fetchEnhcApi } from '../../utils/fetchApi';
import InspectionForm from '../../components/Form/Vehicle/InspectionForm';
import AuthContext from '../../store/AuthContext';

const FiltersGroup = ({ filters, filter, onFilterChange, isPermit }) => {
  const allFilters = filters;

  if (isPermit) {
    allFilters.unshift({ name: 'All', value: 'all' });
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ py: 1 }}>
      Quick Filters:&nbsp;&nbsp;
      <ToggleButtonGroup
        exclusive
        value={filter}
        onChange={onFilterChange}
        aria-label="vehicle filter"
      >
        {allFilters.map((filter, index) => (
          <ToggleButton
            sx={{
              '&.Mui-selected, &.Mui-selected:hover': {
                color: 'white !important',
                backgroundColor: '#00AB55 !important'
              }
            }}
            key={index}
            value={filter.value}
          >
            {filter.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};

FiltersGroup.propTypes = {
  filters: PropTypes.array,
  filter: PropTypes.string,
  onFilterChange: PropTypes.func
};

const UpdateInspectionAction = ({ data, onOpenSnackbar, getList }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update Vehicle Inspection Done Date</DialogTitle>
        <DialogContent>
          <DialogContentText>Fill in the form below.</DialogContentText>
          <InspectionForm
            inspectionData={data}
            onClose={() => setOpen(false)}
            onOpenSnackbar={onOpenSnackbar}
            getList={getList}
            type="updateLastInspection"
          />
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Update Inspection Done Date
      </Button>
    </>
  );
};

UpdateInspectionAction.propTypes = {
  data: PropTypes.object,
  onOpenSnackbar: PropTypes.func,
  getList: PropTypes.func
};

const RevertInspectionAction = ({ data, onOpenSnackbar, getList }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Revert Vehicle Inspection Date</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you sure you want to revert this vehicle inspection date?
          </DialogContentText>
          <InspectionForm
            inspectionData={data}
            onClose={() => setOpen(false)}
            onOpenSnackbar={onOpenSnackbar}
            getList={getList}
            type="revertLastInspectionDate"
          />
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Revert
      </Button>
    </>
  );
};

RevertInspectionAction.propTypes = {
  data: PropTypes.object,
  onOpenSnackbar: PropTypes.func,
  getList: PropTypes.func
};

const EditInspectionAction = ({ data, onOpenSnackbar, getList }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Vehicle Inspection Date</DialogTitle>
        <DialogContent>
          <DialogContentText>Fill in the form below.</DialogContentText>
          <InspectionForm
            inspectionData={data}
            onClose={() => setOpen(false)}
            onOpenSnackbar={onOpenSnackbar}
            getList={getList}
          />
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Edit Inspection Date
      </Button>
    </>
  );
};

EditInspectionAction.propTypes = {
  data: PropTypes.object,
  onOpenSnackbar: PropTypes.func,
  getList: PropTypes.func
};

const AssignStaffAction = ({ data, onOpenSnackbar, getList }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Assign Staff for Inspection</DialogTitle>
        <DialogContent>
          <DialogContentText>Fill in the form below.</DialogContentText>
          <InspectionForm
            inspectionData={data}
            onClose={() => setOpen(false)}
            onOpenSnackbar={onOpenSnackbar}
            getList={getList}
            type="assignStaff"
          />
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Assign Staff
      </Button>
    </>
  );
};

AssignStaffAction.propTypes = {
  data: PropTypes.object,
  onOpenSnackbar: PropTypes.func,
  getList: PropTypes.func
};

const InsepctionListing = ({ isPermit }) => {
  const pageOptions = [25, 50, 100];

  const [pageSize, setPageSize] = useState(25);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('oneMonth');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });
  const [loading, setLoading] = useState(false);

  const onOpenSnackbar = (message, type = 'success') => {
    setOpenSnackbar(true);
    setSnackbarInfo({ message, type });
  };

  const setFilterDate = (v) => {
    const curDate = moment();
    let dateBefore = null;
    switch (v) {
      case 'oneMonth':
        dateBefore = curDate.add('1', 'month').toISOString();
        break;
      case 'threeMonth':
        dateBefore = curDate.add('3', 'month').toISOString();
        break;
      default:
        dateBefore = null;
    }
    return dateBefore;
  };

  const getList = async (val = null) => {
    setLoading(true);
    const data = await fetchEnhcApi('GetInspectionListing', { dateBefore: val });
    setData(data);
    setLoading(false);
  };

  const onFilterChange = async (e, newValue) => {
    const date = setFilterDate(newValue);
    await getList(date);
    setFilter(newValue);
  };

  const filters = [
    { name: 'Inspection Within 1 Month', value: 'oneMonth' },
    { name: 'Inspection Within 3 Month', value: 'threeMonth' }
  ];

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      align: 'center',
      headerAlign: 'center',
      width: 50,
      sortable: false,
      valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
    },
    {
      field: 'VehicleNo',
      headerName: 'Car Plate',
      align: 'center',
      headerAlign: 'center',
      width: 100
    },
    {
      field: 'Model',
      headerName: 'Make / Model',
      align: 'center',
      headerAlign: 'center',
      width: 170
    },
    {
      field: 'CompanyName',
      headerName: 'Company',
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'InspectionDate',
      headerName: 'Inspection Before',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD MMM YYYY') : '')
    },
    {
      field: 'Staff',
      headerName: 'Staff',
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'HirerName',
      headerName: 'Hirer',
      align: 'left',
      headerAlign: 'center',
      width: 180
    },
    {
      field: 'HandPhone',
      headerName: 'Hirer Contact',
      align: 'center',
      headerAlign: 'center',
      width: 120
    },
    {
      field: 'LastHandleBy',
      headerName: 'Last Done By',
      align: 'center',
      headerAlign: 'center',
      width: 130
    }
  ];

  const actionColumn = {
    field: '',
    headerName: 'Action',
    align: 'center',
    headerAlign: 'center',
    width: 600,
    sortable: false,
    disableExport: true,
    renderCell: ({ row }) => {
      return (
        <Stack direction="row" spacing={2}>
          <UpdateInspectionAction
            data={{ vehicleNo: row.VehicleNo }}
            onOpenSnackbar={onOpenSnackbar}
            getList={getList}
          />
          {filter === 'all' && (
            <AssignStaffAction
              data={{ vehicleNo: row.VehicleNo }}
              onOpenSnackbar={onOpenSnackbar}
              getList={getList}
            />
          )}

          {filter === 'all' && (
            <EditInspectionAction
              data={{ vehicleNo: row.VehicleNo }}
              onOpenSnackbar={onOpenSnackbar}
              getList={getList}
            />
          )}
        </Stack>
      );
      // if (row.LastInspectionDoneDate) {
      // }
      // return (
      //   <Stack direction="row" spacing={2}>
      //     <EditLastInspectionAction
      //       data={{ vehicleNo: row.VehicleNo }}
      //       onOpenSnackbar={onOpenSnackbar}
      //       getList={getList}
      //     />
      //   </Stack>
      // );
    }
  };

  if (isPermit) {
    columns.push(actionColumn);
    console.log(columns);
  }

  useEffect(() => {
    const date = setFilterDate(filter);
    let cancel = false;
    setLoading(true);
    fetchEnhcApi('GetInspectionListing', { dateBefore: date })
      .then((data) => {
        if (cancel) return;
        setData(data);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });

    return () => {
      cancel = true;
    };
  }, []);

  const themeSelect = (row) => {
    const currentDate = moment();
    const endDate = moment(row.InspectionDate);
    const diff = endDate.diff(currentDate, 'days');

    if (diff <= 3) return 'Ended';
    if (diff <= 10) return 'Handover';
    return 'primary';
  };

  return (
    <Container maxWidth="xl">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '100%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      <FiltersGroup
        filters={filters}
        filter={filter}
        onFilterChange={onFilterChange}
        isPermit={isPermit}
      />
      <StyledDataGrid
        components={{ Toolbar: GridToolbar }}
        getRowClassName={({ row }) => `vehicle-theme--${themeSelect(row)}`}
        rows={data} columns={columns}
        loading={loading}
        pageSize={pageSize}
        rowsPerPageOptions={pageOptions}
        onPageSizeChange={(size) => setPageSize(size)}
        // initialState={{ pinnedColumns: { right: ['id'] } }}
        disableSelectionOnClick
        sx={{ height: window.innerHeight * 0.75 }}
      />
    </Container>
  );
};
InsepctionListing.propTypes = {
  isPermit: PropTypes.bool
};

const InsepctionDoneListing = () => {
  const pageOptions = [25, 50, 100];

  const [pageSize, setPageSize] = useState(25);
  const [data, setData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });

  const onOpenSnackbar = (message, type = 'success') => {
    setOpenSnackbar(true);
    setSnackbarInfo({ message, type });
  };

  const getList = async (val = null) => {
    fetchEnhcApi('GetInspectionDoneListing')
      .then((data) => {
        setData(data);
      })
      .catch(console.error);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      align: 'center',
      headerAlign: 'center',
      width: 50,
      sortable: false,
      valueFormatter: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.value)
    },
    {
      field: 'VehicleNo',
      headerName: 'Car Plate',
      align: 'center',
      headerAlign: 'center',
      width: 100
    },
    {
      field: 'Model',
      headerName: 'Make / Model',
      align: 'center',
      headerAlign: 'center',
      width: 170
    },
    {
      field: 'CompanyName',
      headerName: 'Company',
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'LastInspectionDoneDate',
      headerName: 'Last Inspection Date',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD MMM YYYY') : '')
    },
    {
      field: 'LastHandleBy',
      headerName: 'Last Done By',
      align: 'left',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'InspectionUpdateBy',
      headerName: 'Update By',
      align: 'left',
      headerAlign: 'center',
      width: 150
    },
    {
      field: '',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      disableExport: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={2}>
            <RevertInspectionAction
              data={{ vehicleNo: row.VehicleNo }}
              onOpenSnackbar={onOpenSnackbar}
              getList={getList}
            />
          </Stack>
        );
      }
    }
  ];

  useEffect(async () => {
    let cancel = false;
    await getList();

    return () => {
      cancel = true;
    };
  }, []);

  return (
    <Container maxWidth="xl">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '100%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      <StyledDataGrid
        components={{ Toolbar: GridToolbar }}
        rows={data}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={pageOptions}
        onPageSizeChange={(size) => setPageSize(size)}
        // initialState={{ pinnedColumns: { right: ['id'] } }}
        disableSelectionOnClick
        sx={{ height: window.innerHeight * 0.75 }}
      />
    </Container>
  );
};

export default function VehicleInsepctionListing() {
  const [tabValue, setTabValue] = useState(0);

  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const authCtx = useContext(AuthContext);
  const dept = authCtx.userDept;
  const permissions = ['manager', 'superadmin', 'accounts'];
  const ispermit = permissions.includes(dept) || authCtx.email === 'elyn@freshcars.sg';
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom>
        Inspection Vehicle Listing
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={tabHandleChange}
            aria-label="basic tabs example"
            // variant="fullWidth"
          >
            <Tab label="Inspection Ongoing" {...a11yProps(0)} />
            {ispermit && <Tab label="Inspection Done" {...a11yProps(1)} />}
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <InsepctionListing isPermit={ispermit} />
        </TabPanel>

        {ispermit && (
          <TabPanel value={tabValue} index={1}>
            <InsepctionDoneListing />
          </TabPanel>
        )}
      </Box>
    </Container>
  );
}
