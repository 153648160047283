import { Button, Container, Stack, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import moment from 'moment';
import FirestoreContext from '../../store/FirestoreContext';
import DataTable from '../../components/DataTable';
import { a11yProps, TabPanel } from '../../components/TabPanel';
import {StyledDataGrid} from "../Vehicle/styles";

export default function FcAppUsers() {
  const fsCtx = useContext(FirestoreContext);
  const [appUsers, setAppUsers] = useState([]);

  const tableInitialSort = {
    sorting: {
      sortModel: [{ field: 'registration', sort: 'desc' }]
    }
  };

  const fetchAppUsers = useCallback(async () => {
    const qSnap = await getDocs(collection(fsCtx.fsObject, 'users'));
    setAppUsers(qSnap.docs.map((el) => ({ ...el.data(), id: el.id })));
  }, [fsCtx.fsObject]);

  useEffect(() => fetchAppUsers(), [fetchAppUsers]);

  // Message Details modal logic
  const columns = [
    {
      field: 'id', headerName: 'Id', width: 100, type: 'number',
      renderCell: (params) => (
        <Button onClick={() => {}}>
          <img src={params.row.imageUrl} alt={params.row.category} />
        </Button>
      )
    },
    { field: 'username', headerName: 'Username', width: 200, type: 'string', sortable: false },
    {
      field: 'registration', headerName: 'Reg Date', sortable: true, width: 200, type: 'date', editable: false,
      valueFormatter: (params) => moment(params.value.toDate()).format('D MMM YYYY, h:mm a')
    },
    { field: 'mobile', headerName: 'HP No.', width: 160, type: 'string', editable: false },
    { field: 'email', headerName: 'Email', width: 300, type: 'string', editable: false }
  ];

  // Tabs Navigation
  const [value, setValue] = useState(0);

  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          App | Users
        </Typography>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={tabHandleChange}
            aria-label="basic tabs example"
          >
            <Tab label="App Users" {...a11yProps(0)} />
            <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <StyledDataGrid
            rows={appUsers}
            columns={columns}
            initialState={tableInitialSort}
            autoHeight
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography variant="h5" gutterBottom>
            Current COE Prices in App
          </Typography>
        </TabPanel>

        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </Container>
  );
}
