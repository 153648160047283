import dayjs from "dayjs";
import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportInvoiceItemCollectionForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);
  const { onPost, loading } = useHttpPost("/api/invoice/item/collection/import");

  const columns = [
    { field: "amountApplied", headerName: "Amount Applied", width: 150 },
    { field: "invoiceNo", headerName: "Invoice No", width: 150 },
    { field: "invoiceItemDescription", headerName: "Description", width: 150 },
    { field: "receiptNo", headerName: "Receipt No", width: 250 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs().toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Invoice Item Collections" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

export default ImportInvoiceItemCollectionForm;