import { TextField, Typography, Grid, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {cloudFunctionPath} from "../../cloudFunctionApiConfig";
import useCloudFuncGet from '../../hooks/firebase/cloudFunction/useCloudFuncGet';
import useCloudFuncPut from '../../hooks/firebase/cloudFunction/useCloudFuncPut';
import DataContext from '../../store/DataContext';

export default function OperationSettings() {
  const dataCtx = useContext(DataContext);
  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/workshop/settings`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/workshop/settings`)
  const [maxNumberOfCarPerDay, setMaxNumberOfCarPerDay] = useState("");
  const [parkingTicketPerMonth, setParkingTicketPerMonth] = useState("");

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const updateOnClick = async () => {
    try {
      await onPut({
        maxNumberOfCarPerDay: maxNumberOfCarPerDay,
        parkingTicketPerMonth: parkingTicketPerMonth
      });
      onOpenSnackbar("Successfully Updated Settings");
    } catch (e) {
      console.log(e.message);
      onOpenSnackbar(e.message);
    }
  }

  useEffect(() => {
    setMaxNumberOfCarPerDay(data.maxNumberOfCarPerDay);
    setParkingTicketPerMonth(data.parkingTicketPerMonth);
  }, [data]);

  return (
    <>
      <Typography variant="h4" mb={3}>Operation Settings</Typography>

      <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <Typography>Maximum Number of Request per Day:</Typography>
          <Typography color="grey">*Immediate effect</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            fullWidth
            label="Maximum Number of Request per Day"
            value={maxNumberOfCarPerDay}
            onChange={(event) => setMaxNumberOfCarPerDay(event.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography>Parking Ticket Per Sales Person Per Month:</Typography>
          <Typography color="grey">*Will take effect next month for any sales person whose parking ticket
            has been deducted in the current month and immediate for everyone else</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            fullWidth
            label="Parking Ticket Per Sales Person Per Month"
            value={parkingTicketPerMonth}
            onChange={(event) => setParkingTicketPerMonth(event.target.value)}
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="end">
          <Button variant="contained" onClick={updateOnClick}>Update</Button>
        </Grid>
      </Grid>
    </>
  );
}