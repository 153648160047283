import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from "moment";

dayjs.extend(utc);
dayjs.extend(timezone);

export const dateIncludeRange = (curDate, startDate, endDate) => {
  curDate = dayjs(curDate).utc(true).tz(defaultTimezone).startOf("day");
  startDate = dayjs(startDate).utc(true).tz(defaultTimezone).startOf("day");
  endDate = dayjs(endDate).utc(true).tz(defaultTimezone).startOf("day");

  return startDate <= curDate && curDate <= endDate;
}

export const dateRangeIncludeRange = (curStartDate, curEndDate, startDate, endDate) => {
  curStartDate = dayjs(curStartDate).utc(true).tz(defaultTimezone).startOf("day");
  curEndDate = dayjs(curEndDate).utc(true).tz(defaultTimezone).startOf("day");
  startDate = dayjs(startDate).utc(true).tz(defaultTimezone).startOf("day");
  endDate = dayjs(endDate).utc(true).tz(defaultTimezone).startOf("day");

  return (startDate >= curStartDate && startDate <= curEndDate) || (endDate >= curStartDate && endDate <= curEndDate);
}

export const periodTypes = {
  1: "Daily",
  3: "Weekend",
  7: "Weekly",
  30: "Monthly",
};

export const paymentPeriodTypes = {
  0: "Day",
  3: "Weekend",
  7: "Week",
  30: "Month",
};

export const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Asia/Singapore";

export const getDayjsValue = (date) => dayjs(date).utc(true).tz(defaultTimezone);

export const getMomentValue = (date) => moment(dayjs(date).utc(true).tz(defaultTimezone).format());