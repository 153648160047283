import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@material-ui/core';
//
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import FirestoreContext from '../../../store/FirestoreContext';

import { BaseOptionChart } from '../../Charts';

// ----------------------------------------------------------------------
let isMounted = true;
// const CHART_DATA = [
//   {
//     name: 'Team A',
//     type: 'column',
//     data: [23, 11, 22, 27, 13, 22]
//   },
//   {
//     name: 'Team B',
//     type: 'area',
//     data: [44, 55, 41, 67, 22, 43]
//   },
//   {
//     name: 'Team C',
//     type: 'line',
//     data: [30, 25, 36, 30, 45, 35]
//   }
// ];

const dateArr = Array.from(Array(12).keys())
  .reverse()
  .map((el) => moment(new Date()).subtract(el, 'w'));

export default function AppRegistrations() {
  const [chartData, setChartData] = useState([{ name: 'Team A', type: 'column', data: [] }]);
  const fsCtx = useContext(FirestoreContext);

  const fetchAppUsers = useCallback(async () => {
    const qSnap = await getDocs(collection(fsCtx.fsObject, 'users'));
    const appUsers = qSnap.docs.map((el) => ({ ...el.data(), id: el.id }));
    if (isMounted)
      setChartData([
        {
          name: 'App Registrations',
          type: 'area',
          data: dateArr.map(
            (dateEl) =>
              appUsers.filter((el) => {
                // moment().subtract mutates the original date in the array itself, so every future iteration of the map would be subtracting 7 from the already 7 days. I need to create a new instance of the date object to avoid this problem
                const subtractedDate = moment(dateEl.toDate()).subtract(7, 'days').toDate();
                return (
                  el.registration.toDate() < dateEl.toDate() &&
                  el.registration.toDate() >= subtractedDate
                );
              }).length
          )
          // data: [1, 2, 3, 4, 5, 6]
        }
      ]);
  }, [fsCtx.fsObject]);

  useEffect(() => {
    isMounted = true;
    fetchAppUsers(isMounted);
    return () => {
      isMounted = false;
    };
  }, [fetchAppUsers]);

  const chartOptions = merge(BaseOptionChart(), {
    // stroke: { width: [0, 2, 3] },
    stroke: { width: [2] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    // fill: { type: ['solid', 'gradient', 'solid'] },
    fill: { type: ['gradient'] },
    labels: dateArr.map((el) => el.format('D MMM')),
    xaxis: {
      tickPlacement: 'on'
    },
    yaxis: {
      min: 0
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} new registrations`;
          }
          return y;
        }
      }
    }
  });
  return (
    <Card>
      <CardHeader
        title="App Registrations"
        subheader="New App User registrations in the week preceding the date"
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
