import {Grid, Typography} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import {defaultTimezone, getDayjsValue, periodTypes} from "../../../../utils/date";

const SurchargesTable = ({surcharges}) => {
  const {data: surchargeTypes} = useHttpGet("/api/surcharge/type");

  const getSurchargeName = (id) => surchargeTypes?.find(s => s.id === id)?.name;

  return (
    <Grid container spacing={1}>
      {surcharges.map((surcharge, index) => (
        <Grid key={index} item xs={12}>
          {getSurchargeName(surcharge.surchargeTypeId)}: S${surcharge.amount} {surcharge?.waiveReason && `(${surcharge.waiveReason})`}
        </Grid>
      ))}
    </Grid>
  );
}

SurchargesTable.propTypes = {
  surcharges: PropTypes.array,
}

export default function BookingDetailsAccordion({ data }) {
  const { data: vehicle } = useHttpGet(`/api/vehicles/${data?.vehicleId}`);
  const price = vehicle?.model?.prices?.find(p => p.pricePeriodId === data?.pricePeriodId);
  const getDuration = () => {
    const startDate = dayjs(data?.startDate).utc(true).utc(true).tz(defaultTimezone);
    const endDate = dayjs(data?.endDate).utc(true).utc(true).tz(defaultTimezone);
    return endDate.diff(startDate, 'day') / price?.pricePeriod?.period;
  }
  const durationFormat = () => {
    const duration = getDuration();
    const rentalPeriods = {
      1: 'Day',
      3: 'Weekend',
      7: 'Week',
      30: 'Month',
    };

    return `${duration.toFixed(2)} ${rentalPeriods[price?.pricePeriod?.period]}${duration > 1 ? 's' : ''}`;
  }

  const details = [
    { label: "Booking No.", value: data?.bookingNo },
    { label: "Status", value: data?.status },
    { label: "Booking Deposit", value: `S$${data?.bookingCost}` },
    { label: "Deposit", value: data?.deposit ? `S$${data?.deposit}` : "No Deposit" },
    { label: "CDW Price", value: data?.cdwPrice ? `S$${data?.cdwPrice}` : "No CDW" },
    { label: "Rental Price", value: `S$${data?.rentalPrice}, ${price?.pricePeriod?.columnName}` },
    { label: "Period Type", value: periodTypes[data?.periodType] },
    { label: "Start Date", value: getDayjsValue(data?.startDate).format("DD MMM YYYY") },
    { label: "End Date", value: getDayjsValue(data?.endDate).format("DD MMM YYYY") },
    { label: "Payment Method", value: data?.pricePeriod?.paymentMethod },
    { label: "Duration", value: durationFormat() },
    { label: "Rental Usage", value: data?.rentalUsage },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({label, value}, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      {data?.surcharges?.length > 0 && <Grid item xs={12}>
        <Typography variant="h6">Surcharges</Typography>
        <SurchargesTable surcharges={data?.surcharges}/>
      </Grid>}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Total Upfront</Typography>
        S${data?.totalUpfront}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Outstanding</Typography>
        S${data?.totalBillAmount - data?.totalReceivedAmount}
      </Grid>
      {data?.remarks && <Grid item xs={12}>
        <Typography variant="h6">Remarks</Typography>
        {data.remarks}
      </Grid>}
    </Grid>
  );
}

BookingDetailsAccordion.propTypes = {
  data: PropTypes.object,
}