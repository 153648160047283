import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {Stack} from "@mui/material";
import PropTypes from "prop-types";
import useHttpPut from "../../../hooks/http/useHttpPut";
import useHttpPost from "../../../hooks/http/useHttpPost";
import {FcTextField, SubmitBtnGroup} from "../FormFields";

export default function VehicleOwnerForm({ data = null, onClose, onGet }) {
  const method = data ? "Update" : "Create";

  const { onPost } = useHttpPost(`/api/vehicles/owner`);
  const { onPut } = useHttpPut(`/api/vehicles/owner/${data?.id}`);

  const onCreate = async (values) => {
    try {
      await onPost(values);
      await onGet();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }
  const onUpdate = async (values) => {
    try {
      await onPut({ ...values, id: data.id });
      await onGet();
      onClose();
    } catch (error) {
      console.error(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: data?.name ?? "",
      registerNo: data?.registerNo ?? "",
      uenNo: data?.uenNo ?? "",
      bankName: data?.bankName ?? "",
      bankAccountNo: data?.bankAccountNo ?? "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please fill in company name!"),
      registerNo: Yup.string().required("Please fill in register number!"),
      uenNo: Yup.string().required("Please insert UEN number!"),
      bankName: Yup.string().required("Please fill in Bank Name!"),
      bankAccountNo: Yup.string().required("Please fill in bank account number!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (method === "Create") await onCreate(values);
      else await onUpdate(values);
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack spacing={3}>
          <FcTextField formik={formik} name="name" label="Company Name"/>
          <FcTextField formik={formik} name="registerNo" label="Register No."/>
          <FcTextField formik={formik} name="uenNo" label="UEN No."/>
          <FcTextField formik={formik} name="bankName" label="Bank Name"/>
          <FcTextField formik={formik} name="bankAccountNo" label="Bank Account No."/>
          <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

VehicleOwnerForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onGet: PropTypes.func,
}