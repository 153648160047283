import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useContext, useEffect, useState } from 'react';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import image2Fill from '@iconify/icons-eva/image-2-fill';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import { fieldToTextField } from 'formik-mui';

import DataContext from '../../store/DataContext';
import FirestoreContext from '../../store/FirestoreContext';

const ModelAutocomplete = ({
  // field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const {
    form: { setTouched, setFieldValue }
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  ModelAutocomplete.propTypes = {
    props: PropTypes.object,
    form: PropTypes.object,
    setTouched: PropTypes.func,
    setFieldValue: PropTypes.func
  };
  return (
    <Autocomplete
      {...props}
      {...field}
      autoComplete
      autoHighlight
      autoSelect
      onChange={(_, value) => {
        setFieldValue(name, value);
      }}
      onBlur={() => {
        setTouched({ [name]: true });
      }}
      getOptionLabel={(option) => option}
      renderInput={(props2) => <TextField {...props2} helperText={helperText} error={error} />}
    />
  );
};

const FcAppNewBanner = (props) => {
  // Send messages firebase and App users
  const fsCtx = useContext(FirestoreContext);
  const bannerCategories = [
    'Model Details Screen',
    'External Link/Video Link',
    'Banner Details Screen'
  ];
  const dataCtx = useContext(DataContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dataCtx.fetchCarModels();
  }, [dataCtx]);

  const createBannerHandler = async (submission) => {
    try {
      await uploadString(ref(storage, `banners/${submission.fileName}`), newBanner, 'data_url');
      const bannerUrl = await getDownloadURL(ref(storage, `banners/${submission.fileName}`));
      const docRef = await addDoc(
        collection(fsCtx.fsObject, 'fcadmin/fcadmin/carouselBanners'),
        Object.assign(submission, { bannerUrl })
      );

      if (submission.category === bannerCategories[2]) {
        await updateDoc(docRef, { link: `/content?id=${docRef.id}` });
      }

      alert(`New banner created! It is now online!`);
    } catch (e) {
      throw new Error(e);
    }
  };

  const editBannerHandler = async (submission) => {
    try {
      console.log('never update img block');

      if (selectedFileName === '' || selectedFileName === null || selectedFileName === undefined) {
        delete submission.fileName;
        await updateDoc(
          doc(fsCtx.fsObject, 'fcadmin/fcadmin/carouselBanners', props.bannerId),
          submission
        );
      } else {
        console.log('updated image block');
        try {
          await deleteObject(ref(storage, `banners/${props.initialFormData.fileName}`));
        } catch (error) {
          alert(`An error occurred deleting previous file: ${error}`);
        }

        await uploadString(ref(storage, `banners/${submission.fileName}`), newBanner, 'data_url');
        const bannerUrl = await getDownloadURL(ref(storage, `banners/${submission.fileName}`));
        await updateDoc(
          doc(fsCtx.fsObject, 'fcadmin/fcadmin/carouselBanners', props.bannerId),
          Object.assign(submission, { bannerUrl })
        );
      }

      alert(`Banner saved! It is now online!`);
      props.closeHandler();
    } catch (error) {
      alert(`An error occurred updating the banner! ${error.message}`);
    }
  };

  // List Generator
  const bannerCategoriesElements = bannerCategories.map((element) => (
    <MenuItem key={element} value={String(element)}>
      {String(element)}
    </MenuItem>
  ));

  const carModelIds = dataCtx.carModels.map((element) => (
    <MenuItem key={element.id} value={String(element.id)}>
      {String(`${element.make} ${element.model} ${element.year}`)}
    </MenuItem>
  ));

  // New Banner Form logic

  const newBannerSchema = Yup.object().shape({
    title: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Please enter a title.'),
    category: Yup.string().required('Please choose a category.'),
    link: Yup.string().when('category', {
      is: (cat) => cat !== bannerCategories[2],
      then: (schema) => schema.required('This field must not be empty.'),
      otherwise: (schema) => schema.nullable()
    }),
    description: Yup.string().when('category', {
      is: bannerCategories[2],
      then: (schema) => schema.required('Description must not be empty.'),
      otherwise: (schema) => schema.nullable()
    })
  });

  const initialValTransform = () => {
    if (props.initialFormData.category === bannerCategories[0] && props.bannerEditMode) {
      const transformedObj = {};
      Object.entries(props.initialFormData).forEach(([key, val]) => {
        transformedObj[key] =
          key === 'link' ? new URLSearchParams(val.split('?')[1]).get('id') : val;
      });
      // for (const [key, val] in Object.entries(props.initialFormData)) {
      //   transformedObj[key] =
      //     key === 'link' ? new URLSearchParams(val.split('?')[1]).get('id') : val;
      // }
      return transformedObj;
    }

    return props.initialFormData;
  };

  const formik = useFormik({
    initialValues: initialValTransform(),
    validationSchema: newBannerSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (window.confirm('Are you sure?')) {
        const tempFileName = `banner${new Date().getTime().toString()}`;

        let link = '';

        switch (values.category) {
          case bannerCategories[0]:
            link = `/details?id=${values.link}`;
            break;
          case bannerCategories[1]:
            link = values.link;
            break;
          case bannerCategories[2]:
            link = `/content?id=${props.bannerId ?? ''}`;
            break;
          default:
            console.log('default switch block');
            break;
        }

        try {
          if (props.bannerEditMode) {
            await editBannerHandler({
              id: props.bannerId,
              category: values.category,
              description: encodeURIComponent(values.description),
              fileName: tempFileName,
              // externalUrl: values.externalUrl,
              // bannerUrl: values.bannerUrl,
              // linkText: values.linkText,
              // linkTo: values.linkTo,
              link,
              published: true,
              title: values.title
            });
          } else {
            if (
              selectedFileName === '' ||
              selectedFileName === null ||
              selectedFileName === undefined
            ) {
              window.alert('Please upload a banner image.');
              setSubmitting(false);
              return;
            }
            await createBannerHandler({
              category: values.category,
              description: encodeURIComponent(values.description),
              date: new Date(),
              fileName: tempFileName,
              // externalUrl: values.externalUrl,
              // bannerUrl: values.bannerUrl,
              // linkTo: values.linkTo,
              link,
              published: true,
              title: values.title
            });
          }
        } catch (error) {
          const errMsg = `An error occurred while creating a new banner: ${error.message}`;
          window.alert(errMsg);
          console.error(errMsg);
          setSubmitting(false);
          return;
        }

        setNewBanner(null);
        setSelectedFileName(null);

        resetForm();
      }
      setSubmitting(false);
    }
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleReset,
    getFieldProps,
    setFieldValue
  } = formik;

  // Upload File Name Display

  const [newBanner, setNewBanner] = useState();
  const [selectedFileName, setSelectedFileName] = useState();

  const selectFileHandler = (event) => {
    setSelectedFileName(event.target.value.split('\\').pop());
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onloadstart = () => {
        setLoading(true);
      };
      reader.onload = function x(ev) {
        setLoading(false);
        setNewBanner(ev.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  // Upload image file to Firebase Storage

  // Create a root reference
  const storage = getStorage();

  FcAppNewBanner.propTypes = {
    initialFormData: PropTypes.object,
    bannerEditMode: PropTypes.bool,
    bannerId: PropTypes.string,
    closeHandler: PropTypes.func
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {props.bannerEditMode ? 'Edit' : 'New'} Banner
          </Typography>
          {/* <legend>
            {props.bannerEditMode
              ? ''
              : "Create a banner to be displayed on the App's home screen."}
          </legend> */}

          <TextField
            type="text"
            label="Title"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
          <FormControl>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              {...getFieldProps('category')}
              label="Category"
              onChange={(event) => {
                setFieldValue('link', '');
                handleChange(event);
              }}
              error={Boolean(touched.category && errors.category)}
            >
              {bannerCategoriesElements}
            </Select>
            {errors.category && touched.category && (
              <FormHelperText style={{ color: 'red' }}>{errors.category}</FormHelperText>
            )}
          </FormControl>
          {formik.values.category === bannerCategories[0] && (
            <Field
              name="link"
              options={dataCtx.carModels.map((element) => element.id)}
              component={ModelAutocomplete}
              label="Movie"
            />

            // <FormControl>
            //   <InputLabel id="demo-simple-select-label">Car Model</InputLabel>
            //   <Select
            //     labelId="demo-car-model-id-label"
            //     id="demo-car-model-id"
            //     {...getFieldProps('link')}
            //     label="Car Model ID"
            //     onChange={handleChange}
            //     error={Boolean(touched.link && errors.link)}
            //   >
            //     {carModelIds}
            //   </Select>
            //   {errors.link && touched.link && (
            //     <FormHelperText style={{ color: 'red' }}>{errors.link}</FormHelperText>
            //   )}
            // </FormControl>
          )}
          {formik.values.category === bannerCategories[1] && (
            <TextField
              type="text"
              label="Link"
              {...getFieldProps('link')}
              error={Boolean(touched.link && errors.link)}
              helperText={touched.link && errors.link}
            />
          )}

          <label htmlFor="contained-button-file">
            <input
              style={{ display: 'none' }}
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={selectFileHandler}
            />
            <Button variant="contained" component="span">
              Upload
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;Selected file: {selectedFileName}
          </label>

          {(newBanner === null || newBanner === undefined) && props.initialFormData.length === 0 ? (
            <Box sx={{ border: '1px solid black' }}>
              <Icon icon={image2Fill} width="100%" height={100} />
            </Box>
          ) : (
            <Stack direction="column" alignItems="center">
              {loading && <CircularProgress color="primary" />}
              <img
                src={
                  newBanner === null || newBanner === undefined
                    ? props.initialFormData.bannerUrl
                    : newBanner
                }
                {...getFieldProps('bannerUrl')}
                alt=""
                style={{ maxHeight: '200px', objectFit: 'contain' }}
              />
            </Stack>
          )}

          {formik.values.category === bannerCategories[2] && (
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Description"
              {...getFieldProps('description')}
              multiline
              rows={7}
              placeholder="Type your description here"
              onChange={handleChange}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          )}

          <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
            {props.bannerEditMode ? 'Save' : 'Submit'}
          </LoadingButton>

          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              handleReset();
              setNewBanner(null);
              setSelectedFileName(null);
            }}
          >
            {props.bannerEditMode ? 'Reset' : 'Clear'}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
export default FcAppNewBanner;
