import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import AccountRefundVoucherForm from "../RefundVoucher/AccountRefundVoucherForm";
import AccountCollectionForm from "./AccountCollectionForm";

export default function MultipleTypeCollectionForm({ booking, onReload, onClose }) {
  const formTypes = ["Collection", "Credit Note", "Refund Voucher", "Refund Deposit"];

  const [formType, setFormType] = useState("Collection");

  return (
    <Stack spacing={3}>
      <FormControl>
        <InputLabel>Collection Form Type</InputLabel>
        <Select
          label="Collection Form Type"
          value={formType}
          onChange={e => setFormType(e.target.value)}
        >
          {formTypes?.map((type, index) => (
            <MenuItem value={type} key={index}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {formType === "Collection" && <AccountCollectionForm booking={booking} isFinalized onReload={onReload} onClose={onClose}/>}
      {formType === "Credit Note" && <AccountCollectionForm type="credit-note" booking={booking} isFinalized onReload={onReload} onClose={onClose}/>}
      {formType === "Refund Voucher" && <AccountRefundVoucherForm booking={booking} onReload={onReload} onClose={onClose}/>}
      {formType === "Refund Deposit" && <AccountCollectionForm type="refund-deposit" booking={booking} isFinalized onReload={onReload} onClose={onClose}/>}
    </Stack>
  );
}

MultipleTypeCollectionForm.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}