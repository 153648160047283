import plusFill from "@iconify/icons-eva/plus-fill";
import {Icon} from "@iconify/react";
import {LoadingButton} from "@material-ui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import {GridToolbar} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import VehicleConditionTypeForm from "../../components/Form/Vehicle/VehicleConditionTypeForm";
import useHttpDelete from "../../hooks/http/useHttpDelete";
import useHttpGet from "../../hooks/http/useHttpGet";
import DataContext from "../../store/DataContext";
import {StyledDataGrid} from "./styles";

const EditAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Vehicle Condition Type</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <VehicleConditionTypeForm data={row} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Edit</Button>
    </>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/vehicles/condition/type/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Delete Successfully!", severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Vehicle Condition Type</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" spacing={2}>
      <EditAction row={row} onReload={onReload}/>
      <DeleteAction row={row} onReload={onReload}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const VehicleConditionTable = ({ data, onReload }) => {
  const columns = [
    {
      field: 'id', headerName: 'No.',
      align: 'center', headerAlign: 'center',
      width: 50, sortable: false,
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    { field: "name", headerName: "Name", align: "center", headerAlign: "center", width: 250 },
    {
      field: "", headerName: "Action",
      align: "center", headerAlign: "center",
      width: 250, sortable: false,
      renderCell: ({row}) => <ActionCol row={row} onReload={onReload}/>
    },
  ];

  return (
    <StyledDataGrid
      components={{ Toolbar: GridToolbar }}
      columns={columns} rows={data ?? []}
      pageSize={10} rowsPerPageOptions={[10]}
      disableSelectionOnClick
      autoHeight
    />
  );
}

VehicleConditionTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}

const AddAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Add Condition Type</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <VehicleConditionTypeForm onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" startIcon={<Icon icon={plusFill}/>} onClick={() => setOpen(true)}>
        Add Condition
      </Button>
    </>
  );
}

AddAction.propTypes = {
  onReload: PropTypes.func,
}

export default function VehicleConditionTypeListing() {
  const { data, onGet } = useHttpGet("/api/vehicles/condition/type");

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Vehicle Condition Types</Typography>
        <AddAction onReload={onGet}/>
      </Stack>
      <VehicleConditionTable data={data} onReload={onGet}/>
    </>
  );
}