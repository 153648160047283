import {Box} from "@mui/material";
import PropTypes from "prop-types";
import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import {xlsxDateToDate, xlsxToJson} from "../../../utils/xlsxToJson";

const convertToObject = (data) => {
  return data.map((item, index) => ({
    ...item,
    id: index,
    registerDate: xlsxDateToDate(item.registerDate).toISOString(),
    origRegisterDate: xlsxDateToDate(item.origRegisterDate).toISOString(),
    inspectionDueDate: xlsxDateToDate(item.inspectionDueDate).toISOString(),
    roadTaxDueDate: xlsxDateToDate(item.roadTaxDueDate).toISOString(),
    manufacturingYear: xlsxDateToDate(item.manufacturingYear).toISOString(),
    coeExpiryDate: xlsxDateToDate(item.coeExpiryDate).toISOString(),
    isParf: Number(item.isParf),
  }));
}

export default function ImportVehicleForm({ rows, setRows }) {

  const columns = [
    { field: "vehicleNumber", headerName: "Vehicle Number", width: 150 },
    { field: "make", headerName: "Make", width: 150 },
    { field: "model", headerName: "Model", width: 150 },
    { field: "petrolType", headerName: "Petrol Type", width: 150 },
    { field: "vehicleColor", headerName: "Color", width: 150 },
    { field: "vehicleShape", headerName: "Shape", width: 150 },
    { field: "registerDate", headerName: "Register Date", width: 200 },
    { field: "origRegisterDate", headerName: "Original Register Date", width: 200 },
    { field: "manufacturingYear", headerName: "Manufacturing Year", width: 150 },
    { field: "inspectionDueDate", headerName: "Inspection Due Date", width: 200 },
    { field: "roadTaxDueDate", headerName: "Road Tax Due Date", width: 200 },
    { field: "vehicleStatus", headerName: "Vehicle Status", width: 150 },
    { field: "vehicleOwner", headerName: "Vehicle Owner", width: 150 },
    { field: "vehicleType", headerName: "Vehicle Type", width: 150 },
    { field: "coeExpiryDate", headerName: "COE Expiry Date", width: 150 },
    { field: "isParf", headerName: "COE / PARF", width: 100, renderCell: ({ row }) => row.isParf ? "PARF" : "COE" },
    { field: "remarks", headerName: "Remarks", width: 300 },
  ];

  const onDrop = useCallback(async acceptedFiles => {
    const file = acceptedFiles[0];
    const data = xlsxToJson(await file.arrayBuffer());
    const rows = convertToObject(data);
    console.log(rows);
    setRows(rows);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ multiple: false, onDrop });

  const style = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center',
  };

  return (
    <>
      <Box {...getRootProps({ style })} mb={3}>
        <input {...getInputProps()}/>
        {isDragActive ? <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>}
      </Box>
      <StyledDataGrid columns={columns} rows={rows} autoHeight/>
    </>
  );
}

ImportVehicleForm.propTypes = {
  rows: PropTypes.array,
  setRows: PropTypes.func,
}