import { DataGrid } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .vehicle-theme--Ended': {
    backgroundColor: lighten(theme.palette.error.main, 0.7),
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.6)
    },
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.error.main, 0.5),
      '&:hover': {
        backgroundColor: lighten(theme.palette.error.main, 0.4)
      }
    }
  },
  '& .vehicle-theme--Handover': {
    backgroundColor: lighten(theme.palette.warning.main, 0.7),
    '&:hover': {
      backgroundColor: lighten(theme.palette.warning.main, 0.6)
    },
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.warning.main, 0.5),
      '&:hover': {
        backgroundColor: lighten(theme.palette.warning.main, 0.4)
      }
    }
  },
  '& .vehicle-theme--primary': {
    backgroundColor: darken('#FFFFFF', 0),
    '&:hover': {
      backgroundColor: darken('#FFFFFF', 0.1)
    },
    '&.Mui-selected': {
      backgroundColor: darken('#FFFFFF', 0.2),
      '&:hover': {
        backgroundColor: darken('#FFFFFF', 0.3)
      }
    }
  },
  '& .MuiDataGrid-row': {
    whiteSpace: 'normal !important',
    lineHeight: 'normal'
  },
  '& .MuiDataGrid-cell': {
    whiteSpace: 'normal !important',
    lineHeight: 'normal'
  },
  // row highlighting for checking (same as handover theme)
  '& .highlighted-row': {
    backgroundColor: lighten(theme.palette.warning.main, 0.7),
    '&:hover': {
      backgroundColor: lighten(theme.palette.warning.main, 0.6)
    },
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.warning.main, 0.5),
      '&:hover': {
        backgroundColor: lighten(theme.palette.warning.main, 0.4)
      }
    }
  }
}));

export { StyledDataGrid };
