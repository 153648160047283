import dayjs from "dayjs";
import React, {useContext, useState} from 'react';
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from './DataImportForm';

const ImportVehicleLogcardsForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);

  const { onPost, loading } = useHttpPost('/api/logcard/import');

  const columns = [
    { field: 'isActive', headerName: 'Is Active?', width: 150 },
    { field: 'logcard', headerName: 'Logcard', width: 150 },
    { field: 'isParf', headerName: 'Is Parf?', width: 150 },
    { field: 'coeExpiryDate', headerName: 'COE Expiry Date', width: 250 },
    { field: 'coeRenewalDate', headerName: 'COE Renewal Date', width: 250 },
    { field: 'vehicleType', headerName: 'Vehicle Type', width: 150 },
    { field: 'vehicleOwnerName', headerName: 'Vehicle Owner Name', width: 200 },
    { field: 'vehicleNumber', headerName: 'Vehicle Number', width: 150 },
    { field: 'chassisNo', headerName: 'Chassis No', width: 150 },
    { field: 'engineNo', headerName: 'Engine No', width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    coeExpiryDate: row?.coeExpiryDate ? dayjs(row.coeExpiryDate).toISOString() : null,
    coeRenewalDate: row?.coeRenewalDate ? dayjs(row.coeRenewalDate).toISOString() : null,
    isActive: row.isActive === "TRUE",
    isParf: row.isParf === "TRUE",
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs(row.updatedAt).toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Vehicle Logcards" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

export default ImportVehicleLogcardsForm;
