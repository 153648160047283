import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  Box,
  Stack,
  Toolbar,
  Button,
  IconButton,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import {isValidElement, useEffect, useState} from "react";

const renderValue = (col, row, index) => {
  if (col.showIndex) return index + 1;
  if (col.renderCell) return col.renderCell({ row });
  return row[col.field];
}

const ColumnsTool = () => {
  return (
    <Button startIcon={<ViewColumnIcon/>}>Columns</Button>
  );
}

const FiltersTool = ({ columns, filterModel, handleOnFilterChange }) => {
  const [open, setOpen] = useState(false);

  const styles = {
    display: open ? "block" : "none",
    background: "white",
    position: "absolute",
    top: 85,
    zIndex: 901,
    p: 1,
    minWidth: 500,
    boxShadow: "0px 5px 5px -3px rgba(145, 158, 171, 0.2), 0px 8px 10px 1px rgba(145, 158, 171, 0.14), 0px 3px 14px 2px rgba(145, 158, 171, 0.12)",
  };

  const operators = [
    { label: "Equals", value: "equals" },
    { label: "Contains", value: "contains" },
  ];

  return (
    <>
      <Box sx={styles}>
        <Stack direction="row" spacing={1}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Columns</InputLabel>
            <Select
              value={filterModel.items[0]?.columnField ?? columns[0].field}
              onChange={e => handleOnFilterChange({...filterModel.items[0], columnField: e.target.value})}
            >
              {columns.map(({ field, headerName }, index) => (
                <MenuItem value={field} key={index}>
                  {headerName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel>Operator</InputLabel>
            <Select
              value={filterModel.items[0]?.operatorValue ?? "equals"}
              onChange={e => handleOnFilterChange({...filterModel.items[0], operatorValue: e.target.value})}
            >
              {operators.map(({ value, label }, index) => (
                <MenuItem value={value} key={index}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Value" fullWidth variant="standard"
            value={filterModel.items[0]?.value}
            onChange={e => handleOnFilterChange({ ...filterModel.items[0], value: e.target.value })}
          />
        </Stack>
      </Box>
      <Button startIcon={<FilterListIcon/>} onClick={() => setOpen(!open)}>Filters</Button>
    </>
  );
}

FiltersTool.propTypes = {
  columns: PropTypes.array,
  filterModel: PropTypes.object,
  handleOnFilterChange: PropTypes.func,
}

const generateXLSX = (columns, rows) => {
  const exportRows = rows.map((row, rowIndex) => {
    const exportRow = {};
    columns.forEach(col => {
      const val = renderValue(col, row, rowIndex);
      if (!isValidElement(val)) exportRow[col.headerName] = val;
    });
    return exportRow;
  });

  const worksheet = XLSX.utils.json_to_sheet(exportRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Table");

  XLSX.writeFile(workbook, "Table.xlsx");
}

const ExportTool = ({ columns, rows }) => {
  const onExport = e => {
    e.preventDefault();
    generateXLSX(columns, rows);
  }

  return (
    <Button startIcon={<DownloadIcon/>} onClick={onExport}>
      Export
    </Button>
  );
}

ExportTool.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
}

const handleFilter = (data, filterModel = {}) => {
  if (data === undefined || filterModel === {}) return true;

  if (filterModel?.operatorValue === 'equals') return data[filterModel.columnField] === filterModel.value;
  if (filterModel?.operatorValue === 'contains') {
    if (data[filterModel.columnField] !== undefined)
      return data[filterModel.columnField].includes(filterModel.value);
  }
  return true;
}

export default function FcTable({ columns, rows, filterModel: initFilterModel = null, onFilterModelChange = null }) {
  const rowsPerPageOptions = [25, 50, 100];
  const [filterModel, setFilterModel] = useState({ items: [] });

  const [tableRows, setTableRows] = useState(rows);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortOptions, setSortOptions] = useState(null);

  const handleOnPageChange = (e, newPage) => setPage(newPage);
  const handleOnChangeRowsPerPage = e => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  }

  const handleOnFilterChange = (newFilterModel) => {
    console.log(newFilterModel);
    if (onFilterModelChange) onFilterModelChange({ items: [newFilterModel] });
    else setFilterModel({ items: [newFilterModel] });
  }

  const cellStyles = (col) => {
    let minWidth = col?.minWidth;
    if (col.field === sortOptions?.field) {
      minWidth += 25;
    }

    return {
      minWidth: minWidth,
      maxWidth: col?.maxWidth,
    }
  };

  const rowStyles = {
    border: "1px solid rgba(241, 243, 244, 1)",
  };

  const visibleRows = tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const getIndex = (index) => page * rowsPerPage + index;
  const adjustStickyColumn = (index) => {
    if (index === 0) return 0;
    return columns.slice(0, index)
      .reduce((accumulator, col) => accumulator + col.minWidth, 0);
  }

  const headerStyles = (col, index) => {
    const styles = {
      ...cellStyles(col),
      border: "1px solid rgba(241, 243, 244, 1)",
      background: "white",
      cursor: "pointer",
    };

    if (col.sticky) {
      styles.position = 'sticky';
      styles.left = adjustStickyColumn(index);
      styles.zIndex = 999;
    }

    return styles;
  };

  const tableStyles = (col, index, backgroundColor) => {
    const styles = {
      ...cellStyles(col),
      background: backgroundColor ?? "white",
    };

    if (col.sticky) {
      styles.position = 'sticky';
      styles.left = adjustStickyColumn(index);
      styles.zIndex = 900;
    }

    return styles;
  };

  const handleSort = (col) => {
    let data = rows.slice();

    if (filterModel?.items?.length > 0) {
      filterModel.items.forEach(item => {
        data = data.filter(d => handleFilter(d, item));
      });
    }

    const orderBy = sortOptions?.field === col.field && sortOptions?.order === 'asc' ? 'desc' : 'asc';
    const cmpFn = (a, b) => {
      if (a[col.field] < b[col.field]) return (orderBy === 'asc' ? -1 : 1);
      if (a[col.field] > b[col.field]) return (orderBy === 'asc' ? 1 : -1);
      return 0;
    }
    setSortOptions({ field: col.field, order: orderBy });
    setTableRows(data.sort(cmpFn));
  }

  const renderSortOption = (col) => {
    if (col.field === sortOptions?.field) {
      return (
        <IconButton sx={{ m: 0, p: 0 }}>
          { sortOptions?.order === 'asc' ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}
        </IconButton>
      );
    }
    return <></>;
  }

  useEffect(() => {
    setFilterModel(initFilterModel);
  }, [initFilterModel]);

  useEffect(() => {
    console.log("filterModel:", filterModel);
    let data = rows.slice();
    if (filterModel?.items?.length > 0) {
      filterModel.items.forEach(item => {
        data = data.filter(d => handleFilter(d, item));
      });
    }

    if (sortOptions !== null) {
      const orderBy = sortOptions?.order === 'asc' ? 'asc' : 'desc';
      const cmpFn = (a, b) => {
        if (a[sortOptions.field] < b[sortOptions.field]) return (orderBy === 'asc' ? -1 : 1);
        if (a[sortOptions.field] > b[sortOptions.field]) return (orderBy === 'asc' ? 1 : -1);
        return 0;
      }
      data.sort(cmpFn);
    }

    setTableRows(data);
  }, [rows, filterModel]);

  return (
    <Box sx={{ border: "1px solid rgba(241, 243, 244, 1)", backgroundColor: "white" }}>
      <Toolbar variant="dense" disableGutters sx={{ ml: 0.5 }}>
        {/* <ColumnsTool/> */}
        {/* <FiltersTool columns={columns ?? []} filterModel={filterModel} handleOnFilterChange={handleOnFilterChange}/> */}
        <ExportTool columns={columns} rows={rows}/>
      </Toolbar>
      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        <Table width="100%" size="small" stickyHeader>
          <TableHead>
            <TableRow sx={rowStyles}>
              {columns.map((col, index) => (
                <TableCell
                  align={col.headerAlign} key={index}
                  sx={headerStyles(col, index)}
                  onClick={() => handleSort(col)}
                >
                  <Stack direction="row" alignItems="center" justifyContent="center">
                    <Box sx={{ overflowX: "hidden" }}>{col.headerName}</Box>
                    {renderSortOption(col)}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {visibleRows.map((row, rowIndex) => (
              <TableRow key={rowIndex} sx={rowStyles}>
                {columns.map((col, index) => (
                  <TableCell
                    key={index}
                    align={col.align}
                    sx={tableStyles(col, index, row.backgroundColor)}
                  >
                    {renderValue(col, row, getIndex(rowIndex))}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="flex-end">
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnChangeRowsPerPage}
        />
      </Stack>
    </Box>
  );
}

FcTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  filterModel: PropTypes.object,
  onFilterModelChange: PropTypes.func,
}