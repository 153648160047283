import {Grid, Typography} from "@mui/material";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React from "react";
import {FcPdfDocument} from "../../Document/Documents";

export default function BookingVehicleDetails({ vehicle }) {
  const details = [
    { label: "Vehicle No.", value: vehicle?.vehicleNumber },
    { label: "Make / Model", value: `${vehicle?.model?.make?.name} ${vehicle?.model?.name}, ${vehicle?.model?.petrolType}` },
    { label: "Insurance Company", value: vehicle?.insurance?.company?.name ?? "None" },
    { label: "Owner Company", value: vehicle?.logcard?.vehicleOwner?.name ?? "None" },
    { label: "Vehicle Color", value: vehicle?.vehicleColor ?? "None" },
    { label: "Inspection Date", value: moment(vehicle?.inspectionDate).format("DD MMM YYYY") },
    {
      label: "Logcard",
      value: vehicle?.logcard?.logcard ? <FcPdfDocument objectName={vehicle?.logcard?.logcard}/> : "None"
    },
    {
      label: "Insurance CI",
      value: vehicle?.insurance?.insuranceCi ? <FcPdfDocument objectName={vehicle?.insurance?.insuranceCi}/> : "None"
    },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({ label, value }, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
    </Grid>
  );
}

BookingVehicleDetails.propTypes = {
  vehicle: PropTypes.object,
}