import dayjs from "dayjs";
import {getAuth} from "firebase/auth";
import moment from "moment";
import {useContext, useEffect} from "react";
import * as Yup from "yup";
import {Grid, Typography} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import DataContext from "../../../../store/DataContext";
import {getMomentValue, paymentPeriodTypes} from "../../../../utils/date";
import SignatureBoard from "../../../SignatureBoard";
import {FcDateField, FcTextArea, FcTextField, SubmitBtnGroup} from "../../FormFields";

export default function ExtensionForm({ booking, agreements, onReload, onClose }) {
  const method = "Create";
  const paymentPeriodType = paymentPeriodTypes[booking?.pricePeriod?.paymentPeriod];
  const rentalAgreements = agreements ? [...agreements?.filter(agreement => agreement.agreementType === "Rental")] : [];
  rentalAgreements.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt));
  const rentalAgreement = rentalAgreements.length > 0 ? rentalAgreements[0] : null;

  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/addendum");

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);

  const formik = useFormik({
    initialValues: {
      addendumType: "Extension",
      userId: getAuth().currentUser.uid,
      reason: "",
      startDateTime: getMomentValue(booking?.startDate),
      endDateTime: getMomentValue(booking?.endDate),
      bookingId: booking?.id,
      bookingNo: booking?.bookingNo,
      agreementId: rentalAgreement?.id,
      customerType: booking?.companyId ? "Company" : "Individual",
      companyName: booking?.company?.name,
      companyAddress: booking?.company?.address,
      companyPostal: booking?.company?.postal,
      companyPhone: booking?.company?.phone,
      companyEmail: booking?.company?.email,
      companyIdentity: booking?.company?.identity,
      companyId: booking?.companyId,
      customerName: booking?.customer?.name ?? "",
      customerAddress: booking?.customer?.address ?? "",
      customerPostal: booking?.customer?.postal ?? "",
      customerPhone: booking?.customer?.phone ?? "",
      customerEmail: booking?.customer?.email ?? "",
      customerIdentity: booking?.customer?.identity ?? "",
      customerBirthDate: booking?.customer?.birthDate,
      drivingLicenseEffectiveDate: booking?.customer?.drivingLicenseEffectiveDate,
      customerId: booking?.customerId,
      vehicleCompanyName: booking?.vehicle?.logcard?.vehicleOwner?.name ?? "",
      vehicleCompanyAddress: booking?.vehicle?.logcard?.vehicleOwner?.address ?? "",
      vehicleCompanyPostal: booking?.vehicle?.logcard?.vehicleOwner?.postal ?? "",
      vehicleCompanyUen: booking?.vehicle?.logcard?.vehicleOwner?.uenNo ?? "",
      vehicleCompanyBank: booking?.vehicle?.logcard?.vehicleOwner?.bankName ?? "",
      vehicleCompanySignature: booking?.vehicle?.logcard?.vehicleOwner?.signature ?? "",
      vehicleCompanyBankAccount: booking?.vehicle?.logcard?.vehicleOwner?.bankAccountNo ?? "",
      vehicleCompanyRegisterNo: booking?.vehicle?.logcard?.vehicleOwner?.registerNo ?? "",
      vehicleCompanyId: booking?.vehicle?.logcard?.vehicleOwnerId,
      vehicleNumber: booking?.vehicle?.vehicleNumber ?? "",
      vehicleColor: booking?.vehicle?.vehicleColor ?? "",
      vehicleModelName: booking?.vehicle?.model?.name ?? "",
      vehiclePetrol: booking?.vehicle?.model?.petrolType ?? "",
      vehicleMakeName: booking?.vehicle?.model?.make?.name ?? "",
      vehicleModelId: booking?.vehicle?.modelId,
      vehicleId: booking?.vehicleId,
      rentalPrice: booking?.rentalPrice,
      totalUpfront: 0,
      pricePeriodId: booking?.pricePeriodId,
      salesSignature: "",
      remarks: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Reason is required!"),
      endDateTime: Yup.date().required("End Date is required!"),
      rentalPrice: Yup.number().required("Rental Price is required!"),
      salesSignature: Yup.string().required("Sales Signature is required!"),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost({
          ...values,
          startDateTime: values.startDateTime.toISOString(),
          endDateTime: values.endDateTime.toISOString(),
        });
        await onReload();
        onClose();
        dataCtx.setSnackbarConfig({ open: true, message: "Extension created successfully", severity: "success" });
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    const { rentalPrice, endDateTime } = formik.values;
    const paymentPeriod = booking?.pricePeriod?.paymentPeriod === 0 ? 1 : booking?.pricePeriod?.paymentPeriod;
    const diff = dayjs(endDateTime).diff(booking.endDate, "days");
    formik.setFieldValue("totalUpfront", diff * rentalPrice / paymentPeriod);
  }, [formik.values.rentalPrice, formik.values.endDateTime]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FcDateField formik={formik} name="endDateTime" label="End Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FcTextField formik={formik} name="rentalPrice" label={`Rental Rate (${paymentPeriodType})`} type="number"/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FcTextField formik={formik} name="reason" label="Reason"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">Extend Days</Typography>
            {moment(formik.values.endDateTime).diff(booking?.endDate, "days")} days
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">Rental Rate (per day)</Typography>
            S${(formik.values.rentalPrice / (booking?.pricePeriod?.paymentPeriod === 0 ? 1 : booking?.pricePeriod?.paymentPeriod)).toFixed(0)}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">Total Upfront</Typography>
            S${formik.values.totalUpfront.toFixed(0)}
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" mb={1}>Sales Signature</Typography>
            <SignatureBoard field="salesSignature" objectName={formik.values.salesSignature} handleUploadCanvas={handleUploadCanvas}/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

ExtensionForm.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}