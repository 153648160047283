import {Container, Typography} from "@mui/material";
import {cloudFunctionPath} from "../../cloudFunctionApiConfig";
import AppUserWalletTable from "../../components/Table/User/AppUserWalletTable";
import useCloudFuncGet from "../../hooks/firebase/cloudFunction/useCloudFuncGet";

export default function UserWallet() {
  const { data, loading, onGet } = useCloudFuncGet(`${cloudFunctionPath}/user`);
  const users = data?.data;

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mb={3}>User Wallet Table</Typography>
      <AppUserWalletTable data={users} loading={loading} onReload={onGet}/>
    </Container>
  );
}