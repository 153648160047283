import { TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useHttpGet from '../../hooks/http/useHttpGet';
import BulkUpsertVehicleTable from '../../components/Table/Vehicle/BulkUpsertVehicleTable';

export default function VehicleLogcardInsuranceUpsert() {
  const { data: vehicles } = useHttpGet("/api/vehicles");
  vehicles?.forEach((vehicle, index) => vehicle.index = index);

  const [data, setData] = useState(vehicles);

  useEffect(() => {
    setData(vehicles);
  }, [vehicles]);

  const changeOriginalData = (index, newData) => {
    vehicles[index] = newData;
  }

  return (
    <>
      <Typography variant="h4" gutterBottom mb={3}>
        Logcard & Insurance
      </Typography>

      <TextField
        fullWidth
        placeholder="Vehicle Number"
        onChange={(value) => {
          setData(vehicles.filter((vehicle) => vehicle.vehicleNumber.toLowerCase().includes(value.target.value.toLowerCase())));
        }}
        sx={{ marginBottom: 2 }}
      />
      
      <BulkUpsertVehicleTable data={data} changeOriginalData={changeOriginalData} />
    </>
  );
}