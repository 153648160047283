import { Paper, Typography, Grid } from '@mui/material';
import React from 'react';
import PropTypes from "prop-types";

export default function WorkshopAppointmentLive({ data }) {
  // These two must match in length
  const timeSlot = [
    '9AM - 10AM',
    '10AM - 11AM',
    '11AM - 12PM',
    '12PM - 1PM',
    '1PM - 2PM',
    '2PM - 3PM',
    '3PM - 4PM',
    '4PM - 5PM',
    '5PM - 6PM',
  ];
  const timeNumber = [9, 10, 11, 12, 1, 2, 3, 4, 5];

  return (
    <Paper elevation={10} sx={{ marginBottom: 2 }}>
      <Grid container>
        {
          timeSlot.map((time, index) => {
            const apmt = data.find((apmt) => new Date(apmt.time).getHours() === timeNumber[index]);
            return (
              <Grid item xs display="flex" flexDirection="column" alignItems="center" bgcolor={index % 2 === 0 ? "#F3F5F8" : "white"}>
                <Typography>{time}</Typography>
                {
                  apmt && <Typography mt={1}>
                    {apmt.vehicleNumber} {apmt.customerName} {apmt.customerPhone}
                  </Typography>
                }
              </Grid>
            )
          })
        }
      </Grid>
    </Paper>
  );
}

WorkshopAppointmentLive.propTypes = {
  data: PropTypes.object,
}