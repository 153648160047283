import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { cloudFunctionPath } from '../../cloudFunctionApiConfig';
import JobAcc from '../../components/Workshop/Job/JobAcc';
import useCloudFuncGet from '../../hooks/firebase/cloudFunction/useCloudFuncGet';
import useGetCollections from '../../hooks/firebase/useGetCollections';
import ReturnJobDisplayItem from '../../components/Workshop/ReturnJobDisplayItem';
import WorkshopAppointmentLive from '../../components/Workshop/Appointment/WorkshopAppointmentLive';

export default function ViewJobLive() {
  const todayEpoch = new Date(new Date().toDateString()).getTime();
  const queryObj = { lowerBoundTime: todayEpoch, upperBoundTime: todayEpoch + 86400000 };
  const { data: jobData, onGet: onGetJob } = useCloudFuncGet(`${cloudFunctionPath}/workshop/job/live`, true, queryObj);
  const { data: returnJobData, onGet: onGetReturnJob } = useCloudFuncGet(`${cloudFunctionPath}/workshop/return_job`);
  const { data: appointmentData, onGet: onGetAppointment } = useCloudFuncGet(`${cloudFunctionPath}/workshop/appointment`, true, queryObj);
  const { data: user } = useGetCollections("fcadmin/fcadmin/users");

  const [reload, setReload] = useState(new Date().getTime());
  const mechanicNames = user
    .filter((user) => user.department === "workshop" && user.name !== "")
    .map((user) => user.name)
    .sort();

  useEffect(() => {
    onGetJob(queryObj);
    onGetReturnJob();
    onGetAppointment(queryObj);
    setTimeout(() => setReload(new Date().getTime()), 60000);
  }, [reload]);

  return (
    <Container sx={{ position: 'absolute', top: 0, left: 0, mt: 2, zIndex: 999999 }} maxWidth>
      <Typography variant="h4" gutterBottom>
        Viewing Live Jobs (Last Reload: {new Date(reload).toTimeString().substring(0, 8)})
      </Typography>

      <WorkshopAppointmentLive data={appointmentData.data ?? []} />

      <Grid container spacing={1}>
        {
          mechanicNames.map((name) => {
            const filtered = (jobData.data ?? [])
              .filter((job) => job.name === name)
              .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            return (
              <Grid item key={name} xs={(12 / mechanicNames.length)}>
                <Accordion elevation={10} defaultExpanded>
                  <AccordionSummary>
                    <Typography variant="h5">{name}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography fontSize={18} fontWeight={700} color="orange" ml={1} >ONGOING</Typography>
                    {
                      filtered
                        .filter((job) => job.completed === false)
                        .map((job) => <JobAcc key={job.id} data={job} hideAction hideChinese />)
                    }

                    <Typography fontSize={18} fontWeight={700} color="green" mt={3} ml={1}>COMPLETED</Typography>
                    {
                      filtered
                        .filter((job) => job.completed !== false)
                        .map((job) => <JobAcc key={job.id} data={job} hideAction hideChinese />)
                    }

                    <div style={{ position: "sticky", bottom: "0%" }}>
                      <Typography fontSize={18} fontWeight={700} color="red" mt={3} ml={1}>RETURNED</Typography>
                      {
                        returnJobData.data?.filter((returnJob) => returnJob.mechanic === name)
                        .map((returnJob) => <ReturnJobDisplayItem key={returnJob.id} returnJob={returnJob} />)
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
          })
        }
      </Grid>
    </Container>
  );
}