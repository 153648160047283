import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { doc, updateDoc } from 'firebase/firestore';
import { useContext } from 'react';

import errorHandler from '../utils/errorHandler';
import { fTitleCase } from '../utils/formatTitleCase';
import FirestoreContext from '../store/FirestoreContext';

/**
 * @param {{fcadminDoc: Object}} props
 */

const AppAdminSettings = (props) => {
  const fsCtx = useContext(FirestoreContext);

  const initialValues = Object.fromEntries(
    Object.entries(props.fcadminDoc.appSettings.serviceContacts).sort((a, b) => a[0] - b[0])
  );
  Object.keys(initialValues).forEach((key) => {
    if (key !== 'whatsapp' && key !== 'towme') {
      initialValues[key] = initialValues[key].slice(4);
    }
  });
  const appSettingsSchema = Yup.object().shape({
    accident: Yup.number().positive().integer().required('A phone number is required.'),
    servicing: Yup.number().positive().integer().required('A phone number is required.'),
    towing: Yup.number().positive().integer().required('A phone number is required.'),
    towme: Yup.string().url().required('A website url is required.'),
    whatsapp: Yup.number().positive().integer().required('A phone number is required.')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: appSettingsSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        if (window.confirm('Are you sure?')) {
          const transformedValues = { ...values };
          Object.keys(transformedValues).forEach((key) => {
            transformedValues[key] = transformedValues[key].toString();
            if (key !== 'whatsapp' && key !== 'towme') {
              transformedValues[key] = `tel:${transformedValues[key]}`;
            }
          });
          const fcadminDoc = doc(fsCtx.fsObject, 'fcadmin', 'fcadmin');
          await updateDoc(fcadminDoc, {
            'appSettings.serviceContacts': transformedValues
          });
          Object.keys(values).forEach((key) => {
            values[key] = values[key].toString();
          });
          resetForm({ values });
        }
      } catch (e) {
        errorHandler(values, 'AppAdminSettings', e);
      }
      setSubmitting(false);
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, handleReset, getFieldProps } = formik;

  // ----------------------------------------------------------------------

  return (
    <>
      <Typography variant="h6" component="h2">
        Update phone numbers in the App
      </Typography>
      <br />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {Object.keys(initialValues).map((el) => (
              <TextField
                key={el}
                type={el === 'towme' ? 'text' : 'number'}
                label={`${fTitleCase(el)}${
                  el === 'whatsapp'
                    ? " - All enquiries from the App will be directed to this phone number's whatsapp"
                    : ''
                }`}
                {...getFieldProps(el)}
                error={Boolean(touched[el] && errors[el])}
                helperText={touched[el] && errors[el]}
              />
            ))}
            <Box sx={{ '& button': { m: 1 } }}>
              <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                Save
              </LoadingButton>
              <Button size="large" variant="contained" onClick={handleReset}>
                Clear
              </Button>
            </Box>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AppAdminSettings;
