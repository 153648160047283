import {Stack, Tooltip} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React from "react";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";
import palette from "../../../theme/palette";

const getWidth = col => {
  if (col.length < 10) return 100;
  if (col.length < 20) return 150;
  if (col.length < 30) return 250;
  return 300;
}

const priceArrayToObject = (data, originalData) => {
  if (!data || !originalData || data.length < 1 || originalData.length < 1)
    return [];

  console.log(data.length, originalData.length);

  const len = data?.length ?? 0;
  for (let i = 0; i < len; i++) {
    const pricesObj = {};
    const { prices } = data[i];
    const { prices: originalPrices } = originalData[i];

    for (let j = 0; j < prices.length; j++) {
      const { pricePeriod } = prices[j];
      pricesObj[pricePeriod.columnName] = prices[j];
    }
    for (let j = 0; j < originalPrices.length; j++) {
      const { pricePeriod } = originalPrices[j];
      pricesObj[pricePeriod.columnName].originalPrice = originalPrices[j].price;
    }
    data[i].priceObj = pricesObj;
    data[i].id = i + 1;
    data[i].isParf = prices[0]?.isParf;
  }
  return data;
}

export default function PricingTable({ data, originalData, cols }) {
  const cmpFn = (v1, v2) => {
    let cmp = v1.make.name.localeCompare(v2.make.name);
    if (cmp !== 0) return cmp;
    cmp = v1.name.localeCompare(v2.name);
    if (cmp !== 0) return cmp;
    return v1.petrolType.localeCompare(v2.petrolType);
  }

  const columns = cols?.map(col => ({
    field: col.columnName, headerName: col.columnName,
    align: "center", headerAlign: "center",
    width: getWidth(col.columnName),
    valueGetter: ({ row }) => row?.priceObj[col.columnName]?.price ?? 0,
    renderHeader: (params) => (
      <Tooltip title={col?.description ?? ""}>
        <b>{params.colDef.headerName}</b>
      </Tooltip>
    ),
    renderCell: ({ row }) => {
      const price = row?.priceObj[col.columnName]?.price;
      const originalPrice = row?.priceObj[col.columnName]?.originalPrice;
      return (
        <Stack
          sx={{
            width: "100%", height: "100%",
            alignItems: "center", justifyContent: "center",
            backgroundColor: price !== originalPrice ? palette.primary.lighter : "white",
          }}
        >
          {price ?? 0}
        </Stack>
      );
    }
  })) ?? [];
  columns.unshift({
    field: "isParf", headerName: "COE / PARF",
    align: "center", headerAlign: "center",
    width: 100, sortable: false,
    valueFormatter: ({value}) => value ? "PARF" : "COE",
    renderHeader: (params) => <b>{params.colDef.headerName}</b>
  });
  columns.unshift({
    field: "model", headerName: "Make / Model",
    align: "center", headerAlign: "center",
    width: 250,
    sortComparator: cmpFn,
    valueFormatter: ({value}) => `${value?.make?.name} ${value?.name}, ${value?.petrolType}`,
    renderHeader: (params) => <b>{params.colDef.headerName}</b>
  });

  const prices = priceArrayToObject(data, originalData);
  return (
    <StyledDataGrid
      component={{ Toolbar: GridToolbar }}
      columns={columns} rows={prices}
      pageSize={100} rowsPerPageOptions={[100]}
      initialState={{sorting: { sortModel: [{ field: "model", sort: "asc" }] }}}
      disableSelectionOnClick
      sx={{ height: window.innerHeight * 0.7 }}
    />
  );
}

PricingTable.propTypes = {
  data: PropTypes.array,
  originalData: PropTypes.array,
  cols: PropTypes.array,
}