import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import BookingFilterForm from "../../components/Form/Booking/BookingFilterForm";
import BookingTable from "../../components/Table/Booking/BookingTable";
import useHttpGet from "../../hooks/http/useHttpGet";
import AuthContext from "../../store/AuthContext";
import {BOOKING_PERMISSIONS} from "../../utils/authorization/permissions/bookingPermission";
import {permissionRequired} from "../../utils/authorization/roles";

const AddAction = () => {
  return (
    <Button
      variant="contained" startIcon={<Icon icon={plusFill}/>}
      component={Link} to="./new" target="_blank"
    >
      Create Booking
    </Button>
  );
}

const Filters = ({ onReload, paginationModel, sortModel }) => {
  return (
    <Box mb={3}>
      <BookingFilterForm onReload={onReload} paginationModel={paginationModel} sortModel={sortModel} />
    </Box>
  );
}

Filters.propTypes = {
  onReload: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
}

export default function BookingList() {
  const authCtx = useContext(AuthContext);

  const [paginationModel, setPaginationModel] = useState({ pageIndex: 1, pageSize: 100 });
  const [sortModel, setSortModel] = useState([{ field: "createdAt", sort: "desc" }]);

  const { data, loading, onGet } = useHttpGet("/api/booking/paginate", true, paginationModel);

  const handlePageChange = (newPage) => {
    setPaginationModel({ pageIndex: newPage + 1, pageSize: paginationModel.pageSize });
  }

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ pageIndex: paginationModel.pageIndex, pageSize: newPageSize });
  }

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  }

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Booking Listing</Typography>
        {permissionRequired(authCtx, BOOKING_PERMISSIONS.CREATE) && <AddAction/>}
      </Stack>
      <Filters onReload={onGet} paginationModel={paginationModel} sortModel={sortModel} />
      <BookingTable
        paginatedData={data}
        loading={loading} onReload={onGet}
        sortModel={sortModel}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        handleSortModelChange={handleSortModelChange}
      />
    </Container>
  );
}