import React from 'react';
import PropTypes from "prop-types"
import moment from 'moment/moment';
import { Grid, Typography } from '@mui/material';

export default function LogcardDialogDetails({ row }) {
  const details = [
    { label: "Vehicle No.", value: row?.vehicle?.vehicleNumber },
    { label: "Company", value: row?.vehicleOwner?.name },
    { label: "COE Expiry Date", value: moment(row.coeExpiryDate).format("DD MMM YYYY") },
    { label: "COE Renewal Date", value: row?.coeRenewalDate ? moment(row.coeRenewalDate).format("DD MMM YYYY") : "No renewal date" },
    { label: "PARF / COE", value: row.isParf ? "PARF" : "COE" },
    { label: "Vehicle Type", value: row.vehicleType },
    { label: "IU Number", value: row.iuNumber },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({label, value}, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      {row?.remarks && <Grid item xs={12}>
        <Typography variant="h6">Remarks</Typography>
        {row.remarks}&nbsp;
      </Grid>}
    </Grid>
  );
}

LogcardDialogDetails.propTypes = {
  row: PropTypes.object
}