import {Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {StyledDataGrid} from "../../../../pages/Vehicle/styles";

const ActionCol = ({ row, handleAddRow, handleDeleteRow }) => {
  return (
    <Stack direction="row" justifyContent="center" spacing={2}>
      {row.id === 0 && <Button variant="contained" onClick={() => handleAddRow(row)}>Add</Button>}
      {row.id !== 0 && <Button variant="contained" color="error" onClick={() => handleDeleteRow(row)}>Delete</Button>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  handleAddRow: PropTypes.func,
  handleDeleteRow: PropTypes.func,
}

export default function AdditionalChargesTableForm({ data, handleAddRow, handleDeleteRow, handleChangesCommit }) {
  const [rows, setRows] = useState([{ id: 0, description: "", unitPrice: 0, quantity: 1 }]);

  const SelectDescription = () => {
    const items = [
      { label: "Petrol Charge", value: "Petrol Charge" },
      { label: "Repair Charge", value: "Repair Charge" },
    ];

    const handleChange = e => {
      rows[0].description = e.target.value;
      setRows([...rows]);
    }

    return (
      <FormControl sx={{ maxWidth: "25%", pl: "24px", pt: "24px" }} fullWidth>
        <InputLabel sx={{ pl: "24px", pt: "24px" }}>Additional Charges</InputLabel>
        <Select label="Additional Charges" value={rows[0].description} onChange={handleChange}>
          {items?.map(({ label, value }, index) => <MenuItem value={value} key={index}>{label}</MenuItem>)}
        </Select>
      </FormControl>
    );
  }

  const columns = [
    { field: "description", headerName: "Description", align: "center", headerAlign: "center", width: 600, editable: true },
    { field: "unitPrice", headerName: "Unit Price", align: "center", headerAlign: "center", width: 150, editable: true, type: "number" },
    { field: "quantity", headerName: "Quantity", align: "center", headerAlign: "center", width: 150, editable: true, type: "number" },
    {
      field: "amount", headerName: "Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => `$${row.unitPrice * row.quantity}`,
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => <ActionCol row={row} handleAddRow={handleAddRow} handleDeleteRow={handleDeleteRow}/>
    },
  ];

  useEffect(() => {
    const newRow = rows.find(row => row.id === 0);
    setRows([newRow].concat(data ?? []));
  }, [data]);

  const handleCommit = ({ id, field, value }) => {
    const updateRows = rows.map(row => {
      if (row.id === id) {
        const column = columns.find(col => col.field === field);
        if (column?.type === "number") value = Number(value);
        return {...row, [field]: value};
      }
      return row;
    });
    handleChangesCommit(updateRows.filter(row => row.id !== 0));
    setRows(updateRows);
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Additional Charges</Typography>
        <SelectDescription/>
      </Stack>
      <StyledDataGrid
        columns={columns} rows={rows}
        autoHeight disableSelectionOnClick
        pageSize={100} rowsPerPageOptions={[100]}
        onCellEditCommit={handleCommit}
      />
    </>
  );
}

AdditionalChargesTableForm.propTypes = {
  data: PropTypes.array,
  handleAddRow: PropTypes.func,
  handleDeleteRow: PropTypes.func,
  handleChangesCommit: PropTypes.func,
}