import {Box, Stack} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect, useRef} from "react";
import {Calendar, momentLocalizer} from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import palette from "../theme/palette";

const FcEventWrapper = ({ event, children }) => {
  const ref = useRef(null);

  const theme = {
    al: palette.primary.main,
    mc: palette.secondary.main,
  };

  useEffect(() => {
    if (ref.current) {
      const eventElement = ref.current.querySelector('.rbc-event');
      if (eventElement) {
        if (event.eventType === "holiday") {
          eventElement.style.backgroundColor = palette.info.main;
        } else if (event.leaveStatus === "pending") {
            eventElement.style.backgroundColor = palette.action.disabled;
        } else {
          eventElement.style.backgroundColor = theme[event.leaveType];
        }
      }
    }
  }, []);

  return (
    <Box ref={ref}>
      {children}
    </Box>
  );
}

FcEventWrapper.propTypes = {
  event: PropTypes.object,
  children: PropTypes.element,
}

const FcEvent = ({ event }) => {
  return (
    <span>
      <strong>{event.title} {event.isHalfDay ? event.halfDayType.toUpperCase() : ""}</strong>
    </span>
  );
}

FcEvent.propTypes = {
  event: PropTypes.object,
}

const LabelBox = () => {
  const colors = [
    { label: "Pending Request", color: palette.action.disabled },
    { label: "Annual Leave", color: palette.primary.main },
    { label: "Medical Leave", color: palette.secondary.main },
    { label: "Public Holiday", color: palette.info.main },
  ];

  return (
    <Box p={1} mt={1} sx={{ border: 1, borderColor: "black", borderRadius: 1 }}>
      {colors.map(({ label, color }, index) => (
        <Stack direction="row" spacing={1} key={index} sx={{ alignItems: "center" }}>
          <Box sx={{ backgroundColor: color, width: 15, height: 15 }}/>
          <Box>{label}</Box>
        </Stack>
      ))}
    </Box>
  );
}

export default function LeaveCalendar({ events }) {
  const localizer = momentLocalizer(moment);
  return (
    <Box>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ minHeight: 1000 }}
        components={{
          event: FcEvent,
          eventWrapper: FcEventWrapper
        }}
      />
      <LabelBox/>
    </Box>
  );
}

LeaveCalendar.propTypes = {
  events: PropTypes.array,
}