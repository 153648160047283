import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import {useContext} from "react";
import * as Yup from "yup";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import useCloudFuncPut from "../../../hooks/firebase/cloudFunction/useCloudFuncPut";
import DataContext from "../../../store/DataContext";
import {getMomentValue} from "../../../utils/date";
import {FcDateField, FcTextField, SubmitBtnGroup} from "../FormFields";

export default function ShellUserDiscountForm({ user, data, onReload, onClose }) {
  const method = data ? "Update" : "Create";

  const dataCtx = useContext(DataContext);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/wallet/card/discount`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/wallet/card/discount/${data?.id}`);

  const formik = useFormik({
    initialValues: {
      discountRate: data?.rate ?? 0,
      startDate: data?.startDate ? getMomentValue(data?.startDate) : moment().startOf('day'),
      endDate: data?.endDate,
      userId: data?.userId ?? user?.id,
    },
    validationSchema: Yup.object().shape({
      discountRate: Yup.number().required("Discount rate is required!"),
      startDate: Yup.date().min(moment().startOf("day")).required("Start date is required!"),
      endDate: Yup.date().nullable(),
      userId: Yup.string().required("User is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onPost({ ...values, startDate: values.startDate.toISOString(), endDate: values?.endDate?.toISOString() });
        else await onPut({ ...values, startDate: values.startDate.toISOString(), endDate: values?.endDate?.toISOString() });
        await onReload();
        onClose();
        dataCtx.setSnackbarConfig({ open: true, message: "Discount rate updated successfully!", severity: "success" });
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcTextField formik={formik} label="Discount rate (%)" name="discountRate" type="number"/>
          </Grid>
          <Grid item xs={12}>
            <FcDateField formik={formik} label="Start Date" name="startDate"/>
          </Grid>
          <Grid item xs={12}>
            <FcDateField formik={formik} label="End Date" name="endDate"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

ShellUserDiscountForm.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}