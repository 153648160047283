import {Checkbox} from "@mui/material";
import {useGridApiRef} from "@mui/x-data-grid";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";

export default function ShellRefuelTransactionTable({ data, loading, onReload, paginationModel, setPaginationModel, rowCount, totalPages }) {
  const apiRef = useGridApiRef();

  const columns = [
    { field: "pan", headerName: "PAN", align: "center", headerAlign: "center", width: 200 },
    {
      field: "amount", headerName: "Amount", align: "center", headerAlign: "center", width: 100,
      renderCell: ({ row }) => row.amount / 100,
    },
    { field: "type", headerName: "Transaction Type", align: "center", headerAlign: "center", width: 200 },
    { field: "unitPrice", headerName: "Unit Price", align: "center", headerAlign: "center", width: 100 },
    { field: "fuelVolume", headerName: "Fuel Volume", align: "center", headerAlign: "center", width: 100 },
    { field: "declinedReason", headerName: "Declined Reason", align: "center", headerAlign: "center", width: 250 },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 120 },
    {
      field: "isConfirmed", headerName: "Confirmed", align: "center", headerAlign: "center", width: 100,
      renderCell: ({ row }) => <Checkbox checked={row.isConfirmed}/>,
    },
    { field: "invoiceAmount", headerName: "Invoice Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "invoiceGSTAmount", headerName: "Invoice GST Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "invoiceGSTRate", headerName: "Invoice GST Rate (%)", align: "center", headerAlign: "center", width: 175 },
    { field: "accountNumber", headerName: "Account No.", align: "center", headerAlign: "center", width: 120 },
    { field: "payerNumber", headerName: "Payer No.", align: "center", headerAlign: "center", width: 120 },
    {
      field: "timestamp", headerName: "Timestamp", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => dayjs(row.timestamp).format("DD MMM YYYY hh:mm:ss A"),
    },
  ];

  return (
    <StyledDataGrid
      apiRef={apiRef}
      columns={columns} rows={data ?? []}
      autoHeight
      disableSelectionOnClick
      loading={loading}
      rowCount={rowCount ?? 0}
      pageSizeOptions={[25, 50, 100]}
      onPageSizeChange={(newPageSize) => setPaginationModel({ page: paginationModel.page, pageSize: newPageSize })}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
}

ShellRefuelTransactionTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
  paginationModel: PropTypes.object,
  setPaginationModel: PropTypes.func,
  rowCount: PropTypes.number,
  totalPages: PropTypes.number,
}