import {LoadingButton} from "@material-ui/lab";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import DataContext from "../../../store/DataContext";
import {defaultTimezone} from "../../../utils/date";
import ReplacementExtensionForm from "../../Form/Booking/Extension/ReplacementExtensionForm";
import VehicleReplacementForm from "../../Form/Booking/VehicleReplacement/VehicleReplacementForm";
import FcTable from "../FcTable";

dayjs.extend(utc);
dayjs.extend(timezone);

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/vehicles/usage/${row.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Vehicle Usage Record deleted successfully!", severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Vehicle Usage Record</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ReplaceAction = ({ booking, agreements, row, onReload }) => {
  const [open, setOpen] = useState(false);

  const rentalAgreements = agreements ? [...agreements?.filter(agreement => agreement.agreementType === "Rental")] : [];
  rentalAgreements.sort((a, b) => dayjs(b.createdAt).utc(true).tz(defaultTimezone) - dayjs(a.createdAt).utc(true).tz(defaultTimezone));
  const rentalAgreement = rentalAgreements.length > 0 ? rentalAgreements[0] : null;

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Create TCOV Addendum</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <VehicleReplacementForm
              booking={booking} rentalAgreement={rentalAgreement} record={row} addendumType="Handover Replacement Vehicle"
              onReload={onReload} onClose={() => setOpen(false)}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Replace</Button>
    </>
  );
}

ReplaceAction.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ExtendAction = ({ booking, agreements, onReload }) => {
  const [open, setOpen] = useState(false);

  const rentalAgreements = [...agreements?.filter(agreement => agreement.agreementType === "Rental")];
  rentalAgreements.sort((a, b) => dayjs(b.createdAt).utc(true).tz(defaultTimezone) - dayjs(a.createdAt).utc(true).tz(defaultTimezone));
  const rentalAgreement = rentalAgreements.length > 0 ? rentalAgreements[0] : null;

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Extension Replacement Vehicle</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <ReplacementExtensionForm booking={booking} rentalAgreement={rentalAgreement} onClose={() => setOpen(false)} onReload={onReload}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Extend</Button>
    </>
  );
}

ExtendAction.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  onReload: PropTypes.func,
}

const ReturnAction = ({ booking, agreements, row, onReload }) => {
  const [open, setOpen] = useState(false);

  const rentalAgreements = agreements ? [...agreements.filter(agreement => agreement.agreementType === "Rental")] : [];
  rentalAgreements.sort((a, b) => dayjs(b.createdAt).utc(true).tz(defaultTimezone) - dayjs(a.createdAt).utc(true).tz(defaultTimezone));
  const rentalAgreement = rentalAgreements.length > 0 ? rentalAgreements[0] : null;

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Return Replacement Vehicle</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <VehicleReplacementForm
              booking={booking} rentalAgreement={rentalAgreement} record={row} addendumType="Return Replacement Vehicle"
              onReload={onReload} onClose={() => setOpen(false)}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Return</Button>
    </>
  );
}

ReturnAction.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ booking, agreements, row, onReload }) => {
  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      {(row.rentalPrice === 0 && row.deletable) && <ReplaceAction booking={booking} agreements={agreements} row={row} onReload={onReload}/>}
      {row.deletable && <DeleteAction row={row} onReload={onReload}/>}
      {row.returnable && <ExtendAction booking={booking} agreements={agreements} onReload={onReload}/>}
      {row.returnable && <ReturnAction booking={booking} agreements={agreements} row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function BookingVehicleUsageRecordTable({ booking, agreements, data, onReload, type = "main" }) {
  const columns = [
    { field: "id", showIndex: true, headerName: "No.", align: "center", headerAlign: "center", maxWidth: 10 },
    {
      field: "vehicleNumber", headerName: "Vehicle No.", align: "center", headerAlign: "center",
      renderCell: ({ row }) => row?.vehicle?.vehicleNumber,
    },
    {
      field: "originalRentalPrice", headerName: "Original Rental Rate", align: "center", headerAlign: "center",
      renderCell: ({ row }) => `S$${row?.originalRentalPrice}`,
    },
    {
      field: "rentalPrice", headerName: "Rental Rate", align: "center", headerAlign: "center",
      renderCell: ({ row }) => `S$${row?.rentalPrice}`,
    },
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center",
      renderCell: ({ row }) => dayjs(row.startDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center",
      renderCell: ({ row }) => dayjs(row.endDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center",
      renderCell: ({ row }) => <ActionCol booking={booking} agreements={agreements} row={row} onReload={onReload}/>
    },
  ];

  return (
    <FcTable
      rows={data ?? []}
      columns={columns.filter(col => type === "main" || col.field !== "rentalPrice" && col.field !== "originalRentalPrice")}
    />
  );
}

BookingVehicleUsageRecordTable.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  data: PropTypes.array,
  onReload: PropTypes.func,
}