import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import useCloudFilePost from '../../../hooks/firebase/cloudFunction/useCloudFilePost';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

const FcWebsiteFileDropzone = ({ formik, label, name }) => {
  const { data, onPost } = useCloudFilePost(`${cloudFunctionPath}/fcwebsite/promotion/upload`);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('attachment', file);
    if (file) {
      await onPost(formData);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false, onDrop, accept: {
      'image/jpeg': [], 'image/png': []
    }
  });

  const style = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center'
  };

  useEffect(() => {
    if (data?.data) formik.setFieldValue(name, data?.data?.imgUrl);
  }, [data]);

  return (<Box>
    <Typography variant="h6" gutterBottom>
      {label}
    </Typography>
    <Box {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {isDragActive ? (<p>Drop the files here ...</p>) : (
        <p>Drag 'n' drop some files here, or click to select files</p>)}
      <em>(Only *.jpeg and *.png images will be accepted)</em>
    </Box>
    {formik.values[name] && (<Box mt={3}>
      <Link to={formik.values[name]} target="_blank">
        View File
      </Link>
    </Box>)}
    {formik.errors[name] && formik.touched[name] && (<Box mt={2} color="red">{formik.errors[name]}</Box>)}
  </Box>);
};

FcWebsiteFileDropzone.propTypes = { formik: PropTypes.object, label: PropTypes.string, name: PropTypes.string };

export default FcWebsiteFileDropzone;