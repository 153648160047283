import {DialogContent} from "@material-ui/core";
import {Box, Button, Dialog, DialogTitle, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import DisposalTaskForm from "../../../components/Form/Vehicle/Disposal/DisposalTaskForm";
import DisposalTaskTable from "../../../components/Table/Vehicle/Disposal/DisposalTaskTable";
import useHttpGet from "../../../hooks/http/useHttpGet";

const CreateAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Add Disposal Task</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <DisposalTaskForm onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>New Task</Button>
    </>
  );
}

CreateAction.propTypes = {
  onReload: PropTypes.func,
}

export default function DisposalTaskList() {
  const { data, onGet } = useHttpGet("/api/vehicles/disposal/task");

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Disposal Tasks</Typography>
        <CreateAction onReload={onGet}/>
      </Stack>
      <DisposalTaskTable data={data} onReload={onGet}/>
    </>
  );
}