import {useContext, useState} from "react";
import {deleteDoc, doc} from "firebase/firestore";
import FirestoreContext from "../../store/FirestoreContext";

export default function useDeleteDocument(path) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fsCtx = useContext(FirestoreContext);
  const db = fsCtx.fsObject;

  const onDelete = async (data) => {
    setLoading(true);
    try {
      await deleteDoc(doc(db, path, data.id));
    } catch (err) {
      setError(err.message);
      throw err;
    }
    setLoading(false);
  }

  return { loading, error, onDelete };
}