import {Box, Container, Typography} from "@mui/material";
import BookingQuestionForms from "../../components/Form/Booking/QuestionForm/BookingQuestionForms";

export default function NewBooking() {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4">Create New Booking</Typography>
      <Box py={3}>
        <BookingQuestionForms/>
      </Box>
    </Container>
  );
}