import dayjs from "dayjs";
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";

export default function ShellCardAssignRecordTable({ data, loading, onReload }) {
  const columns = [
    { field: "pan", headerName: "PAN", align: "center", headerAlign: "center", width: 200 },
    {
      field: "fullName", headerName: "Full Name", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row.user.fullName,
    },
    {
      field: "email", headerName: "Email", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => row.user.email,
    },
    {
      field: "phone", headerName: "Phone", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => row.user.phone,
    },
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => dayjs(row.startDate).format("DD MMM YYYY hh:mm:ss A"),
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => row?.endDate ? dayjs(row.endDate).format("DD MMM YYYY hh:mm:ss A") : "",
    },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={data ?? []}
      loading={loading}
      autoHeight
      disableSelectionOnClick
    />
  );
}

ShellCardAssignRecordTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
}