export const INVOICE_PERMISSIONS = {
  VIEW: 'view_invoice',
  CREATE: 'create_invoice',
  UPDATE: 'update_invoice',
  VOID: 'void_invoice',
  DELETE: 'delete_invoice',
};

export const COLLECTION_PERMISSIONS = {
  VIEW: 'view_collection',
  CREATE: 'create_collection',
  UPDATE: 'update_collection',
  VOID: 'void_collection',
  DELETE: 'delete_collection',
}

export const setupInvoiceRolePermissions = (ROLES) => ({
  [ROLES.SUPER_ADMIN]: [...Object.values(INVOICE_PERMISSIONS), ...Object.values(COLLECTION_PERMISSIONS)],
  [ROLES.MANAGER]: [
    INVOICE_PERMISSIONS.VIEW,
    COLLECTION_PERMISSIONS.VIEW,
  ],
  [ROLES.ACCOUNT]: [...Object.values(INVOICE_PERMISSIONS), ...Object.values(COLLECTION_PERMISSIONS)],
  [ROLES.SALES]: [
    INVOICE_PERMISSIONS.VIEW,
    COLLECTION_PERMISSIONS.VIEW,
  ],
  [ROLES.OPERATION]: [
    INVOICE_PERMISSIONS.VIEW,
    COLLECTION_PERMISSIONS.VIEW,
  ],
  [ROLES.MARKETING]: [
    INVOICE_PERMISSIONS.VIEW,
    COLLECTION_PERMISSIONS.VIEW,
  ],
});