import {LoadingButton} from "@material-ui/lab";
import {Button, Grid, Stack} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useEffect} from "react";
import * as Yup from "yup";
import {FcSelectField, FcTextField} from "../FormFields";

export default function VehicleGanttChartFilterForm({ onReload, month, year, getDateRange, setStartDate, setEndDate }) {
  const formik= useFormik({
    initialValues: {
      make: "",
      model: "",
      vehicleNumber: "",
      month: month === 0 ? new Date().getMonth() + 1 : Number(month),
      year: year === 0 ? new Date().getFullYear() : Number(year),
    },
    validationSchema: Yup.object({
      make: Yup.string().nullable(),
      model: Yup.string().nullable(),
      vehicleNumber: Yup.string().nullable(),
      month: Yup.number().required("Month is required!"),
      year: Yup.number().required("Year is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const startDate = new Date(values.year, values.month - 1, 1);
      const [start, end] = getDateRange(startDate);
      setStartDate(start);
      setEndDate(end);
      await onReload({ ...values, startDate: start.toISOString(), endDate: end.toISOString() });
      setSubmitting(false);
    }
  });

  const months = [
    { label: "Jan", value: 1 },
    { label: "Feb", value: 2 },
    { label: "Mar", value: 3 },
    { label: "Apr", value: 4 },
    { label: "May", value: 5 },
    { label: "Jun", value: 6 },
    { label: "Jul", value: 7 },
    { label: "Aug", value: 8 },
    { label: "Sep", value: 9 },
    { label: "Oct", value: 10 },
    { label: "Nov", value: 11 },
    { label: "Dec", value: 12 },
  ];

  const curYear = new Date().getFullYear();
  const years = [
    { label: curYear - 2, value: curYear - 2 },
    { label: curYear - 1, value: curYear - 1 },
    { label: curYear, value: curYear },
    { label: curYear + 1, value: curYear + 1 },
    { label: curYear + 2, value: curYear + 2 },
  ];

  useEffect(() => {
    formik.setFieldValue("month", month);
    formik.setFieldValue("year", year);
  }, [month, year]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="month" label="Month" items={months}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="year" label="Year" items={years}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="make" label="Make"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="model" label="Model"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleNumber" label="Vehicle No."/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={formik.resetForm}>
                Clear
              </Button>
              <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                Search
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

VehicleGanttChartFilterForm.propTypes = {
  onReload: PropTypes.func,
  month: PropTypes.number,
  year: PropTypes.number,
  getDateRange: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
}