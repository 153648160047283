import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
// material
import { Card, Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { collection, getDocs, query } from 'firebase/firestore';
import { Box } from '@mui/material';

// components

import FirestoreContext from '../../store/FirestoreContext';

const CarsMain = () => {
  // Get Window Dimensions ----------------------------------------------------------------------

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ----------------------------------------------------------------------

  const fsCtx = useContext(FirestoreContext);

  const [carsRows, setCarsRows] = useState([]);

  const fetchCarsHandler = useCallback(async () => {
    const q = query(collection(fsCtx.fsObject, 'vehicles'));
    const querySnapshot = await getDocs(q);
    const tempArr = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      tempArr.push(
        Object.assign(doc.data(), { id: doc.id, company: doc.data().company.toUpperCase() })
      );
    });
    setCarsRows(tempArr);

    // const q = query(collection(db, "cities"), where("capital", "==", true));
  }, [fsCtx.fsObject]);

  useEffect(() => fetchCarsHandler(), [fetchCarsHandler]);

  const columns = [
    {
      field: 'id',
      headerName: 'Car Plate',
      width: 130,
      renderCell: (params) => (
        <strong>
          <NavLink to={params.value} replace>
            <Button variant="contained" color="primary" size="small" style={{ marginLeft: 16 }}>
              {params.value}
            </Button>
          </NavLink>
        </strong>
      ),
      type: 'string'
    },
    {
      field: 'make',
      headerName: 'Make',
      width: 100,
      type: 'string',

      sortable: true
    },
    {
      field: 'model',
      headerName: 'Model',
      width: 140,
      editable: false
    },
    {
      field: 'colour',
      headerName: 'Colour',
      width: 100,
      type: 'string',
      editable: false
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 80,
      type: 'string',
      editable: false
    },
    {
      field: 'mfgYear',
      headerName: 'Year',
      type: 'string',
      width: 80,
      editable: false
    },
    {
      field: 'iu',
      headerName: 'IU',
      type: 'string',
      width: 110,
      editable: false
    },
    {
      field: 'rentalUse',
      headerName: 'Rental Use',
      type: 'string',
      width: 120,
      editable: false
    }
  ];

  return (
    <Card>
      <Box sx={{ height: windowDimensions.height - 350, width: '100%' }}>
        <DataGrid
          rows={carsRows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'msgDate', sort: 'desc' }]
            }
          }}
          // checkboxSelection
          // components={{
          //   Toolbar: GridToolbar
          // }}
          // disableSelectionOnClick
        />
      </Box>
    </Card>
  );
};

export default CarsMain;
