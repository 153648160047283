import PropTypes from "prop-types";
import * as Yup from "yup";
import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import {useContext, useEffect} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import DataContext from "../../../store/DataContext";
import {FcSelectField, FcTextArea, FcTextField, SubmitBtnGroup} from "../FormFields";

export default function PriceColumnForm({ data, onClose, onReload }) {
  const dataCtx = useContext(DataContext);

  const method = data ? "Update" : "Create";

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const { onPost } = useHttpPost("/api/vehicles/price-period");
  const { onPut } = useHttpPut(`/api/vehicles/price-period/${data?.id}`);

  const periodTypes = [
    { label: "Daily", value: 1 },
    { label: "Weekend", value: 3 },
    { label: "Weekly", value: 7 },
    { label: "Monthly", value: 30 },
  ];
  const paymentPeriodTypes = [
    { label: "One Time", value: 0 },
    { label: "Weekend", value: 3 },
    { label: "Weekly", value: 7 },
    { label: "Monthly", value: 30 },
  ];
  const paymentMethods = [
    { label: "Prepaid", value: "Prepaid" },
    { label: "Postpaid", value: "Postpaid" },
  ];

  const onCreate = async (values) => {
    try {
      await onPost(values);
      await onReload();
      onOpenSnackbar("Column created successfully!");
      onClose();
    } catch (error) {
      onOpenSnackbar(error.message, "error");
    }
  }

  const onUpdate = async (values) => {
    try {
      await onPut({ ...values, id: data?.id });
      await onReload();
      onOpenSnackbar("Column updated successfully!");
      onClose();
    } catch (error) {
      onOpenSnackbar(error.message, "error");
    }
  }

  const formik = useFormik({
    initialValues: {
      columnName: "",
      minRentalPeriod: 1,
      period: 1,
      paymentPeriod: 0,
      paymentMethod: "",
      description: "",
    },
    validationSchema: Yup.object({
      columnName: Yup.string().required("Please fill in the column name!"),
      period: Yup.number().required("Please select rental period!"),
      paymentPeriod: Yup.number().required("Please select payment period!"),
      paymentMethod: Yup.string().required("Please select payment method!"),
      description: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (method === "Create") await onCreate(values);
      else await onUpdate(values);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("columnName", data?.columnName);
      formik.setFieldValue("minRentalPeriod", data?.minRentalPeriod);
      formik.setFieldValue("period", data?.period);
      formik.setFieldValue("paymentPeriod", data?.paymentPeriod);
      formik.setFieldValue("paymentMethod", data?.paymentMethod);
      formik.setFieldValue("description", data?.description);
    }
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3} mb={3} py={1}>
          <Grid item xs={6}>
            <FcTextField formik={formik} name="columnName" label="Column Name"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FcTextField type="number" formik={formik} name="minRentalPeriod" label="Min Rental Period"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FcSelectField formik={formik} name="period" label="Period" items={periodTypes}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FcSelectField formik={formik} name="paymentPeriod" label="Payment Period" items={paymentPeriodTypes}/>
          </Grid>
          <Grid item xs={12}>
            <FcSelectField formik={formik} name="paymentMethod" label="Payment Period" items={paymentMethods}/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="description" label="Description"/>
          </Grid>
        </Grid>
        <SubmitBtnGroup method={method} formik={formik} onCancel={onClose}/>
      </Form>
    </FormikProvider>
  )
}

PriceColumnForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
}