import {Button, Radio, Stack} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import useHttpGet from "../../../hooks/http/useHttpGet";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";

const getWidth = col => {
  if (col.length < 10) return 100;
  if (col.length < 20) return 150;
  if (col.length < 30) return 250;
  return 300;
}

const priceArrayToObject = (prices) => {
  const pricesObj = {};
  for (let j = 0; j < prices.length; j++) {
    const { pricePeriod } = prices[j];
    pricesObj[pricePeriod.columnName] = prices[j];
  }
  return pricesObj;
}

const ActionCol = ({ row }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        component={Link} to={`../../../vehicles/${row.id}`} target="_blank"
      >
        View
      </Button>
    </Stack>
  );
};

ActionCol.propTypes = {
  row: PropTypes.object,
}

export default function BookingVehicleTable({ formik, data, handleVehicleSelect }) {
  const { data: priceCols } = useHttpGet("/api/vehicles/price-period");

  data = data?.map(v => ({
    ...v,
    model: {
      ...v?.model,
      priceObj: priceArrayToObject(v?.model?.prices)
    }
  }));

  const columns = [
    {
      field: 'id', headerName: '', align: "center", headerAlign: "center", width: 100, sortable: false,
      renderCell: ({ row }) => <Radio checked={formik.values.vehicleId === row.id} onClick={() => handleVehicleSelect(row)}/>
    },
    { field: 'vehicleNumber', headerName: 'Vehicle No.', align: "center", headerAlign: "center", width: 100, sortable: false },
    {
      field: "make", headerName: "Make / Model", align: "center", headerAlign: "center", width: 250, sortable: false,
      renderCell: ({row}) => `${row.model.make.name} ${row.model.name}, ${row.model.petrolType}`
    },
    {
      field: 'logcard', headerName: 'Vehicle Type', align: "center", headerAlign: "center", width: 125, sortable: false,
      valueGetter: ({value}) => value?.vehicleType
    },
    {
      field: 'inspectionDueDate', headerName: 'Inspection Due Date', align: "center", headerAlign: "center", width: 175,
      valueFormatter: ({value}) => moment(value).format("DD MMM YYYY")
    },
  ];

  priceCols?.forEach(col => {
    columns.push({
      field: `model.priceObj.${col.columnName}`, headerName: col.columnName,
      align: "center", headerAlign: "center", width: getWidth(col.columnName),
      valueGetter: ({ row }) => row?.model?.priceObj[col.columnName]?.price ?? 0,
    });
  });

  columns.push({
    field: '', headerName: 'Action', align: "center", headerAlign: "center", width: 125, sortable: false,
    renderCell: ({ row }) => <ActionCol row={row}/>
  });

  return (
    <StyledDataGrid
      pageSize={5} rowsPerPageOptions={[5]}
      rows={data} columns={columns}
      autoHeight disableSelectionOnClick
    />
  );
}

BookingVehicleTable.propTypes = {
  formik: PropTypes.object,
  data: PropTypes.array,
  handleVehicleSelect: PropTypes.func,
}