import PropTypes from 'prop-types';
import { Button, Typography, TextField, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useContext, useState } from 'react';
import { deleteObject, getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';

import FirestoreContext from '../../store/FirestoreContext';

/**
 * @param {{editMode: boolean, promoData: object}} props
 */

const NewHotPromo = ({ editMode, promoData }) => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [newHotPromoImg, setNewHotPromoImg] = useState(
    promoData !== undefined ? promoData.imageUrl : undefined
  );
  const fsCtx = useContext(FirestoreContext);

  const validationSchema = yup.object({
    imageUrl: yup.string('Upload a voucher image.').required('A voucher image is required.'),
    link: yup.string(),
    title: yup.string().required('A title is required.')
  });

  const initialValues = [
    {
      key: 'title',
      label: 'Title',
      defaultVal: editMode ? promoData.title : '',
      dataType: 'string',
      validationText: 'A title is required.'
    },
    {
      key: 'imageUrl',
      label: 'Image Url',
      defaultVal: editMode ? promoData.imageUrl : '',
      dataType: 'string',
      validationText: 'An image is required.'
    },
    {
      key: 'link',
      label: 'Link',
      defaultVal: editMode ? promoData.link : '',
      dataType: 'string',
      validationText: 'A link is required.'
    }
  ];

  const formik = useFormik({
    initialValues: Object.assign({}, ...initialValues.map((el) => ({ [el.key]: el.defaultVal }))),
    validationSchema,
    onSubmit: async (values) => {
      try {
        let hotPromoImgUrl = values.imageUrl;
        if (promoData === undefined || values.imageUrl !== promoData.imageUrl) {
          const fbStorage = getStorage();
          const fileName = `hot-promo-${new Date().getTime().toString()}`;
          await uploadString(ref(fbStorage, `hot_promos/${fileName}`), newHotPromoImg, 'data_url');
          hotPromoImgUrl = await getDownloadURL(ref(fbStorage, `hot_promos/${fileName}`));
          if (editMode) {
            const promoImgUrl = new URL(promoData.imageUrl);
            const pathName = decodeURIComponent(promoImgUrl.pathname.split('/').slice(-1)[0]);
            const imgRef = ref(fbStorage, pathName);
            await deleteObject(imgRef);
          }
        }
        if (editMode) {
          await updateDoc(doc(fsCtx.fsObject, 'fcadmin/fcadmin/hotPromos', promoData.id), {
            date: new Date(),
            ...values,
            imageUrl: hotPromoImgUrl
          });
          // Check if image was changed
        } else {
          await addDoc(collection(fsCtx.fsObject, 'fcadmin/fcadmin/hotPromos'), {
            date: new Date(),
            ...values,
            imageUrl: hotPromoImgUrl
          });
        }

        alert(`New hot promo created!`);
        clearForm();
      } catch (e) {
        console.error(`Error: ${e}\n${e.stack}`);
        window.alert(`An error occurred: ${e}`);
      }
    }
  });

  NewHotPromo.propTypes = {
    editMode: PropTypes.bool,
    promoData: PropTypes.object
  };

  const selectFileHandler = (event) => {
    setSelectedFileName(event.target.value.split('\\').pop());
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function x(ev) {
        setNewHotPromoImg(ev.target.result);
        formik.setFieldValue('imageUrl', ev.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const clearForm = () => {
    formik.resetForm({
      values: {
        title: '',
        imageUrl: '',
        link: ''
      }
    });
    setNewHotPromoImg();
    setSelectedFileName('');
  };

  return (
    <>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        New Hot Promo
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          spacing={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {initialValues.map((el) =>
            el.key === 'imageUrl' ? undefined : (
              <TextField
                key={el.key}
                fullWidth
                id="claimRefNotImportant"
                name={el.key}
                label={el.label}
                value={formik.values[el.key]}
                onChange={formik.handleChange}
                error={formik.touched[el.key] && Boolean(formik.errors[el.key])}
                helperText={formik.touched[el.key] && formik.errors[el.key]}
              />
            )
          )}
          <Button variant="contained" onError={() => console.log('erropejrwo')} component="label">
            Upload
            <input
              name="imageUrl"
              onChange={selectFileHandler}
              hidden
              accept="image/*"
              type="file"
            />
          </Button>
          {formik.errors.imageUrl && formik.touched.imageUrl ? (
            <div style={{ color: 'red' }}>{formik.errors.imageUrl}</div>
          ) : null}
          Selected file: {selectedFileName}
          {newHotPromoImg && (
            <img
              src={newHotPromoImg}
              alt="new hot promo"
              style={{
                maxWidth: Math.min(500, window.innerWidth * 0.8)
              }}
            />
          )}
          <Button color="primary" variant="contained" fullWidth type="submit">
            Submit
          </Button>
          <Button color="primary" variant="contained" fullWidth onClick={clearForm}>
            Clear
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default NewHotPromo;
