import {Box, Button} from "@mui/material";
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";

const ActionCol = ({ row, onRemove }) => {
  const onClick = e => {
    e.preventDefault();
    onRemove(row.id);
  }

  return (
    <>
      <Button variant="contained" color="error" onClick={onClick}>
        Remove
      </Button>
    </>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onRemove: PropTypes.func,
}

export default function CreditNoteItemTableForm({ data, onRemove }) {
  const columns = [
    {
      field: "description", headerName: "Description", headerAlign: "center", width: 1100,
      renderCell: ({ row }) => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>
          {row.description}
        </Box>
      )
    },
    { field: "quantity", headerName: "Quantity", align: "center", headerAlign: "center", width: 150 },
    { field: "unitPrice", headerName: "Unit Price", align: "center", headerAlign: "center", width: 150 },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => <ActionCol row={row} onRemove={onRemove}/>
    },
  ];

  return (
    <StyledDataGrid
      rows={data ?? []} columns={columns}
      pageSize={100} rowsPerPageOptions={[100]}
      autoHeight disableSelectionOnClick
    />
  );
}

CreditNoteItemTableForm.propTypes = {
  data: PropTypes.array,
  onRemove: PropTypes.func,
}