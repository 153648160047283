import { LoadingButton } from "@material-ui/lab";
import { Button, Grid, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import PropTypes from "prop-types";
import {useEffect} from "react";
import * as Yup from "yup";
import useHttpGet from "../../../hooks/http/useHttpGet";
import { FcDateField, FcSelectField, FcTextField } from "../FormFields";

export default function CollectionFilterForm({ onReload, paginationModel, sortModel, type = "collection" }) {
  const statusOptions = ["Active", "Voided"].map(status => ({ label: status, value: status }));

  const { data: userData } = useHttpGet("/api/user/list");
  const users = userData
    ?.map(user => ({ label: user.name, value: user.uid }))
    ?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const formik= useFormik({
    initialValues: {
      bookingNo: "",
      receiptNo: "",
      customerName: "",
      status: "",
      startDate: null,
      endDate: null,
      userId: "",
    },
    validationSchema: Yup.object({
      bookingNo: Yup.string().nullable(),
      receiptNo: Yup.string().nullable(),
      customerName: Yup.string().nullable(),
      status: Yup.string().nullable(),
      startDate: Yup.date().nullable(),
      endDate: Yup.date().nullable(),
      userId: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      let model = {};
      if (sortModel.length > 0) [model] = sortModel;
      values = {
        ...values, ...paginationModel, ...model,
        type: type,
        startDate: values?.startDate?.toISOString() ?? "",
        endDate: values?.endDate?.toISOString() ?? "",
      };
      if (type === "credit-note") values.paymentMethod = "Credit Note";
      await onReload(values);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    const { values } = formik;
    let model = {};
    if (sortModel.length > 0) [model] = sortModel;
    onReload({
      ...values, ...paginationModel, ...model,
      type: type,
      startDate: values?.startDate?.toISOString() ?? "",
      endDate: values?.endDate?.toISOString() ?? "",
    });
  }, [paginationModel, sortModel]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDate" label="Start Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDate" label="End Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="bookingNo" label="Booking No."/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="receiptNo" label={type === "credit-note" ? "Credit No." : "Receipt No."}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="customerName" label="Customer Name"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="status" label="Status" items={statusOptions}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="userId" label="User" items={users}/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={formik.resetForm}>
                Clear
              </Button>
              <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                Search
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

CollectionFilterForm.propTypes = {
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
  onReload: PropTypes.func,
  type: PropTypes.string,
}