import { Autocomplete, Box, Divider, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Warning } from '@mui/icons-material';

import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import { FcDateField, FcSelectField, FcTextField, SubmitBtnGroup } from '../../FormFields';
import palette from '../../../../theme/palette';
import DataContext from '../../../../store/DataContext';
import useCloudFuncPut from '../../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { cloudFunctionPath } from '../../../../cloudFunctionApiConfig';

export default function CreateMissingOTRForm({ dayjs, onClose, onReload, currentSalesPerson, date, carData }) {
  const dataCtx = useContext(DataContext);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/create`);

  const OnAdd = async (values) => {
    console.log('values: ', values);
    try {
      const data = {
        ...values,
        currentSalesPerson,
        startDate: dayjs(values.startDate).toISOString(),
        endDate: dayjs(values.endDate).toISOString(),
        date
      };

      await onPut(data);
      await onReload();
      onOpenSnackbar('Missing Contract added successfully');

      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      startDate: dayjs(date).toISOString(),
      endDate: dayjs(date).toISOString(),
      bookingNo: '',
      rentalAgreementNo: '',
      hirerName: '',
      depositAmount: '',
      rentalRate: '',
      totalUpfront: '',
      periodType: '',
      billingType: '',
      customerType: '',
      newOrExistingCustomer: '',
      points: '',
      carPlate: '',
      remarks: '',
      carMake: '',
      carModel: '',
      purchaseType: ''
    },
    validationSchema: Yup.object({
      startDate: Yup.date()
        .required('Please select the start date!')
        .test(
          'is-before-or-current-otr-month',
          'Start date must be in or before the current OTR month',
          (value) => {
            const currentMonthStart = dayjs(date).startOf('month');
            const startDate = dayjs(value);
            return startDate.isSameOrBefore(currentMonthStart, 'month');
          }
        ),
      endDate: Yup.date().required('Please select the end date!'),
      bookingNo: Yup.string().required('Please fill in the booking no!'),
      rentalAgreementNo: Yup.string().required('Please fill in the agreement no!'),
      hirerName: Yup.string().required('Please fill in the hirer name!'),
      depositAmount: Yup.number().required('Please fill in the deposit amount!'),
      rentalRate: Yup.number().required('Please fill in the rental rate!'),
      totalUpfront: Yup.number().required('Please fill in the total upfront amount!'),
      periodType: Yup.string().required('Please select the contract type!'),
      billingType: Yup.string().required('Please select the billing type!'),
      customerType: Yup.string().required('Please select the customer type!'),
      newOrExistingCustomer: Yup.string().required('Please select the new or existing!'),
      points: Yup.number().required('Please fill in the proposed OTR!'),
      carPlate: Yup.string().required('Please fill in the car plate!'),
      remarks: Yup.string().nullable(),
      carModel: Yup.string().required('Please fill in the car model!'),
      carMake: Yup.string().required('Please fill in the car make!'),
      purchaseType: Yup.string().required('Please fill in the purchase type!')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await OnAdd(values);
      setSubmitting(false);
    }
  });

  const handleCarPlateChange = (event, value) => {
    if (value) {
      formik.setFieldValue('carPlate', value.VehicleNo);
      formik.setFieldValue('carMake', value.VehicleMake);
      formik.setFieldValue('carModel', value.VehicleModel);
      formik.setFieldValue('purchaseType', value.PurchaseType);
    } else {
      formik.setFieldValue('carPlate', '');
      formik.setFieldValue('carMake', '');
      formik.setFieldValue('carModel', '');
      formik.setFieldValue('purchaseType', '');
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcDateField formik={formik} name="startDate" label="Start Date" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcDateField formik={formik} name="endDate" label="End Date" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField formik={formik} name="bookingNo" label="Booking No." />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField formik={formik} name="rentalAgreementNo" label="Agreement No." />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcSelectField
              formik={formik}
              name="periodType"
              label="Contract Type"
              items={[
                { label: 'Short Term Contract', value: 'ShortTerm' },
                { label: 'Long Term Contract', value: 'LongTerm' }
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcSelectField
              formik={formik}
              name="billingType"
              label="Billing Type"
              items={[
                { label: 'Daily', value: 'Daily' },
                { label: 'Weekend', value: 'Weekend' },
                { label: 'Weekly', value: 'Weekly' },
                { label: 'Monthly', value: 'Monthly' }
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Autocomplete
              fullWidth
              options={carData}
              getOptionLabel={(option) => option.VehicleNo}
              onChange={handleCarPlateChange}
              renderInput={(params) => <TextField {...params} label="CarPlate" />}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              name="carMake"
              label="Car Make"
              type="text"
              value={formik.values.carMake}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              name="carModel"
              label="Car Model"
              type="text"
              value={formik.values.carModel}
              disabled
            />
          </Grid>
        </Grid>
        <Divider sx={{ border: 1, borderColor: palette.divider, my: 2 }} />
        <Typography variant="subtitle1">Customer Details</Typography>
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FcTextField formik={formik} name="hirerName" label="Hirer Name" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcSelectField
              formik={formik}
              name="newOrExistingCustomer"
              label="New/Existing Customer"
              items={[
                { label: 'New', value: 'New' },
                { label: 'Existing', value: 'Existing' }
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcSelectField
              formik={formik}
              name="customerType"
              label="Customer Type"
              items={[
                { label: 'Private Hire', value: 'Private Hire' },
                { label: 'Private Use', value: 'Private Use' }
              ]}
            />
          </Grid>
        </Grid>
        <Divider sx={{ border: 1, borderColor: palette.divider, my: 2 }} />
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField
              formik={formik}
              name="rentalRate"
              label="Rental Rate (SGD)"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField
              formik={formik}
              name="depositAmount"
              label="Deposit Amount (SGD)"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField
              formik={formik}
              name="totalUpfront"
              label="Total Upfront (SGD)"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField formik={formik} name="points" label="Proposed OTR" type="number" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FcTextField formik={formik} name="remarks" label="Remarks" />
          </Grid>
        </Grid>
        <SubmitBtnGroup formik={formik} method="Submit" onCancel={onClose} />
        <Divider sx={{ border: 1, borderColor: palette.divider, my: 2 }} />

        <Box display="flex" alignItems="center">
          <Warning color="warning" sx={{ mr: 1 }} />
          <Typography variant="caption">
            Warning: Please verify all details before submission. This action is irreversible.
          </Typography>
        </Box>
      </Form>
    </FormikProvider>
  );
}

CreateMissingOTRForm.propTypes = {
  dayjs: PropTypes.func,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  currentSalesPerson: PropTypes.string,
  date: PropTypes.string,
  carData: PropTypes.array
};
