import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { SubmitBtnGroup } from '../FormFields';

import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import DataContext from '../../../store/DataContext';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import FcWebsitePromotionForm from './FcWebsitePromotionForm';

export default function EditFcWebsitePromotionForm({ onClose, onReload, row }) {
  const dataCtx = useContext(DataContext);
  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/fcwebsite/promotion/edit`);
  const onSubmit = async (values) => {
    try {
      await onPut({ ...values, description: encodeURIComponent(values.description), id: row.id });
      await onReload();
      onOpenSnackbar('Promotion Saved');
    } catch (e) {
      onOpenSnackbar(`An error occurred: ${e.message}`, 'error');
      console.error(e);
    }
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      title: row.title,
      description: decodeURIComponent(row.description),
      imgUrl: row.imgUrl,
      mobileImgUrl: row.mobileImgUrl,
      isActive: row.isActive
    }, validationSchema: Yup.object({
      title: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Title is required'),
      description: Yup.string().required('Description is required'),
      imgUrl: Yup.string().required('Image is required'),
      mobileImgUrl: Yup.string().required('Image is required')
    }), onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit(values);
      setSubmitting(false);
    }
  });

  return (<FormikProvider value={formik}>
    <Form autoComplete="off">
      <FcWebsitePromotionForm formik={formik}/>
      <SubmitBtnGroup formik={formik} method="Save" onCancel={onClose} />
    </Form>
  </FormikProvider>);
}

EditFcWebsitePromotionForm.propTypes = { onClose: PropTypes.func, onReload: PropTypes.func, row: PropTypes.array };