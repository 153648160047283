import { Box, Divider, FormControlLabel, Grid, Stack, Typography, Checkbox } from "@mui/material";
import { getAuth } from "firebase/auth";
import { Form, FormikProvider, useFormik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import * as Yup from "yup";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import DataContext from "../../../../store/DataContext";
import palette from "../../../../theme/palette";
import { getDayjsValue, getMomentValue } from "../../../../utils/date";
import CustomerDetails from "../../../Details/Customer/CustomerDetails";
import BookingVehicleDetails from "../../../Details/Vehicle/BookingVehicleDetails";
import { FcCustomerDocuments } from "../../../Document/Documents";
import SignatureBoard from "../../../SignatureBoard";
import { FcDateField, FcSelectField, FcTextArea, FcTextField, FcTimeField, SubmitBtnGroup } from "../../FormFields";
import CarImageCanvas from "../CarImageCanvas";
import AdditionalChargesTableForm from "./AdditionalChargesTableForm";
import AgreementOutstandingInvoicesTable from "./AgreementOutstandingInvoicesTable";

const initialData = (booking, agreementType, rentalAgreement = null, isChangeOfVehicle = false) => {
  let records = booking ? [...booking?.vehicleUsageRecords] : [];
  records = records.filter(record => record.rentalPrice > 0)
    .sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
  const record = records.length > 0 ? records[0] : null;

  let agreementState;
  if (rentalAgreement && agreementType === "Return") agreementState = rentalAgreement.agreementState;
  else if (records.length > 1) agreementState = "Change Of Vehicle";
  else agreementState = booking?.previousBookingId === null ? "New" : "Renew";

  return {
    isChangeOfVehicle: isChangeOfVehicle,
    agreementType: agreementType,
    agreementState: agreementState,
    userId: getAuth().currentUser.uid,
    bookingId: booking?.id,
    bookingNo: booking?.bookingNo ?? "",
    rentalUsage: booking?.rentalUsage ?? "",
    customerType: booking?.company?.customerType ?? booking?.customer?.customerType,
    companyId: booking?.companyId,
    companyName: booking?.company?.name,
    companyAddress: booking?.company?.address,
    companyPostal: booking?.company?.postal,
    companyPhone: booking?.company?.phone,
    companyEmail: booking?.company?.email,
    companyIdentity: booking?.company?.identity,
    customerId: booking?.customerId,
    customerName: booking?.customer?.name,
    customerAddress: booking?.customer?.address,
    customerPostal: booking?.customer?.postal,
    customerPhone: booking?.customer?.phone,
    customerEmail: booking?.customer?.email,
    customerIdentity: booking?.customer?.identity,
    customerBirthDate: booking?.customer?.birthDate,
    customerDrivingLicenseEffectiveDate: booking?.customer?.drivingLicenseEffectiveDate,
    vehicleId: agreementType === "Return" ? rentalAgreement?.vehicleId : record?.vehicleId,
    vehicleColor: agreementType === "Return" ? rentalAgreement?.vehicleColor : record?.vehicle?.vehicleColor ?? "",
    vehicleModelId: agreementType === "Return" ? rentalAgreement?.vehicleModelId : record?.vehicle?.modelId,
    vehicleModelName: agreementType === "Return" ? rentalAgreement?.vehicleModelName : record?.vehicle?.model?.name ?? "",
    vehiclePetrol: agreementType === "Return" ? rentalAgreement?.vehiclePetrol : record?.vehicle?.model?.petrolType ?? "",
    vehicleMakeId: agreementType === "Return" ? rentalAgreement?.vehicleMakeId : record?.vehicle?.model?.makeId,
    vehicleMakeName: agreementType === "Return" ? rentalAgreement?.vehicleMakeName : record?.vehicle?.model?.make?.name ?? "",
    vehicleNumber: agreementType === "Return" ? rentalAgreement?.vehicleNumber : record?.vehicle?.vehicleNumber ?? "",
    vehicleCompanyId: agreementType === "Return" ? rentalAgreement?.vehicleCompanyId : record?.vehicle?.logcard?.vehicleOwnerId ?? "",
    vehicleCompanyName: agreementType === "Return" ? rentalAgreement?.vehicleCompanyName : record?.vehicle?.logcard?.vehicleOwner?.name ?? "",
    vehicleCompanyUen: agreementType === "Return" ? rentalAgreement?.vehicleCompanyUen : record?.vehicle?.logcard?.vehicleOwner?.uenNo ?? "",
    vehicleCompanyBank: agreementType === "Return" ? rentalAgreement?.vehicleCompanyBank : record?.vehicle?.logcard?.vehicleOwner?.bankName ?? "",
    vehicleCompanyBankAccount: agreementType === "Return" ? rentalAgreement?.vehicleCompanyBankAccount : record?.vehicle?.logcard?.vehicleOwner?.bankAccountNo ?? "",
    vehicleCompanyAddress: agreementType === "Return" ? rentalAgreement?.vehicleCompanyAddress : record?.vehicle?.logcard?.vehicleOwner?.address ?? "",
    vehicleCompanyPostal: agreementType === "Return" ? rentalAgreement?.vehicleCompanyPostal : record?.vehicle?.logcard?.vehicleOwner?.postal ?? "",
    vehicleCompanySignature: agreementType === "Return" ? rentalAgreement?.vehicleCompanySignature : record?.vehicle?.logcard?.vehicleOwner?.signature ?? "",
    vehicleCompanyRegisterNo: agreementType === "Return" ? rentalAgreement?.vehicleCompanyRegisterNo : record?.vehicle?.logcard?.vehicleOwner?.registerNo ?? "",
    vehicleConditions: [],
    deposit: booking?.deposit ?? 0,
    cdwPrice: agreementType === "Return" ? rentalAgreement?.cdwPrice : booking?.cdwPrice,
    rentalPrice: agreementType === "Return" ? rentalAgreement?.rentalPrice : record?.rentalPrice ?? 0,
    totalUpfront: records.length === 1 ? (booking?.totalUpfront ?? 0) : 0,
    surcharges: booking?.surcharges?.map(surcharge => ({ ...surcharge, name: surcharge?.surchargeType?.name })) ?? [],
    pricePeriodId: booking?.pricePeriodId,
    pricePeriodName: booking?.pricePeriod?.columnName ?? "",
    period: booking?.pricePeriod?.period ?? 0,
    paymentPeriod: booking?.pricePeriod?.paymentPeriod ?? 0,
    paymentMethod: booking?.pricePeriod?.paymentMethod ?? "",
    startDateTime: agreementType === "Return" && rentalAgreement ? getMomentValue(rentalAgreement.startDateTime) : getMomentValue(record?.startDate),
    time: moment().format("HH:mm"),
    endDateTime: agreementType === "Return" ? moment() : getMomentValue(record?.endDate),
    companySignature: null,
    customerSignature: null,
    salesSignature: null,
    vehicleConditionImage: null,
    additionalCharges: [],
    outstandingInvoices: booking?.invoices?.map(row => ({
      ...row, outstanding: row?.items.reduce((total, curr) => total + curr.quantity * curr.unitPrice - curr.amountPaid, 0),
    })).filter(invoice => invoice.outstanding > 0) ?? [],
    isRefundDeposit: false,
    refundMethod: "",
    refundAmount: 0,
    reasonOfReturn: "",
    petrol: 0,
    currentMileage: "",
    nextServiceMileage: "",
    remarks: "",
  };
};

const getDuration = (start, end) => moment(end).diff(start, "day");
const BookingDetails = ({ formik }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Booking No.</Typography>
        {formik.values.bookingNo}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">{formik.values.agreementType === "Return" ? "Start Date Time" : "Start Date"}</Typography>
        {getDayjsValue(formik.values.startDateTime).format(formik.values.agreementType === "Return" ? "DD MMM YYYY hh:mm A" : "DD MMM YYYY")}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">End Date</Typography>
        {getDayjsValue(formik.values.endDateTime).format("DD MMM YYYY")}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Duration</Typography>
        {getDuration(formik.values.startDateTime, formik.values.endDateTime)} days
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Rental Usage</Typography>
        {formik.values.rentalUsage}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Payment Method</Typography>
        {formik.values.paymentMethod}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Deposit</Typography>
        {formik.values.deposit ? `S$${formik.values.deposit}` : "No deposit"}
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Rental Rate</Typography>
        S${formik.values.rentalPrice}
      </Grid>
      {formik.values.cdwPrice > 0 && <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">CDW Price</Typography>
        S${formik.values.cdwPrice}
      </Grid>}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="h6">Total Upfront</Typography>
        S${formik.values.totalUpfront}
      </Grid>
      {formik.values.surcharges.length > 0 && <Grid item xs={12}>
        <Typography variant="h6">Surcharges</Typography>
        {formik.values.surcharges.map((surcharge, index) => (
          <Box key={index}>
            {surcharge?.surchargeType?.name}: S${surcharge.amount}
          </Box>
        ))}
      </Grid>}
    </Grid>
  );
}

BookingDetails.propTypes = {
  formik: PropTypes.object,
}

const VehicleStatusDetails = ({ formik, booking }) => {
  const petrolOptions = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(p => ({ label: `${p}%`, value: p }));
  const paymentMethods = ["Cash", "PayNow", "Bank Transfer", "NETS", "Internal Transfer", "Others"].map(p => ({
    label: p, value: p,
  }));
  const refundAmount = booking?.deposit - (booking?.totalBillAmount ?? 0) + (booking?.totalReceivedAmount ?? 0) -
    formik.values.additionalCharges.reduce((total, cur) => total + cur.unitPrice * cur.quantity, 0);
  const enableRefundDeposit = refundAmount > 0;

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);
  const handleConditionCheckboxClick = id => {
    const conditions = formik.values.vehicleConditions.map(condition => {
      if (condition.vehicleConditionTypeId === id) return { ...condition, checked: !condition.checked };
      return condition;
    });
    formik.setFieldValue("vehicleConditions", conditions);
  }

  const handleAddAdditionalCharge = row => {
    const id = (formik.values.additionalCharges[0]?.id ?? 0) + 1;
    formik.setFieldValue("additionalCharges", [{...row, id }, ...formik.values.additionalCharges]);
  }

  const handleDeleteAdditionalCharge = row => {
    const additionalCharges = [...formik.values.additionalCharges];
    const index = additionalCharges.findIndex(additionalCharge => additionalCharge.id === row.id);
    additionalCharges.splice(index, 1);
    formik.setFieldValue("additionalCharges", additionalCharges);
  }

  const handleAdditionalChargesChangesCommit = (rows) => formik.setFieldValue("additionalCharges", rows);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <CarImageCanvas
          objectName={formik.values.vehicleConditionImage}
          field="vehicleConditionImage" handleUploadCanvas={handleUploadCanvas}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {formik.values.vehicleConditions?.map(({vehicleConditionTypeId, name, checked}, index) => (
          <Box key={index}>
            <FormControlLabel
              label={name}
              control={<Checkbox checked={checked} onClick={() => handleConditionCheckboxClick(vehicleConditionTypeId)}/>}
            />
          </Box>
        ))}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FcDateField
          formik={formik}
          label={formik.values.agreementType === "Return" ? "End Date" : "Start Date"}
          name={formik.values.agreementType === "Return" ? "endDateTime" : "startDateTime"}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FcTimeField formik={formik} label={formik.values.agreementType === "Return" ? "Return Time" : "Pick up Time"} name="time"/>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FcSelectField formik={formik} label="Petrol" name="petrol" items={petrolOptions}/>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FcTextField formik={formik} label="Current Mileage" name="currentMileage"/>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FcTextField formik={formik} label="Next Service Mileage" name="nextServiceMileage"/>
      </Grid>
      {formik.values.agreementType === "Return" && (
        <>
          <Grid item xs={12}>
            <AdditionalChargesTableForm
              data={formik.values.additionalCharges}
              handleAddRow={handleAddAdditionalCharge}
              handleDeleteRow={handleDeleteAdditionalCharge}
              handleChangesCommit={handleAdditionalChargesChangesCommit}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Outstanding Invoices</Typography>
              {!formik.values.isChangeOfVehicle && <FormControlLabel
                name="isRefundDeposit" label="Refund Deposit"
                control={<Checkbox
                  disabled={!enableRefundDeposit}
                  checked={formik.values.isRefundDeposit}
                  onClick={formik.handleChange}
                />}
              />}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">Outstanding</Typography>
            S${(booking?.totalBillAmount ?? 0) - (booking?.totalReceivedAmount ?? 0)}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">Deposit</Typography>
            S${booking.deposit}
          </Grid>
          {formik.values.isRefundDeposit && (
            <>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography variant="h6">Refund Amount</Typography>
                S${refundAmount}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FcSelectField formik={formik} name="refundMethod" label="Refund Method" items={paymentMethods}/>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <AgreementOutstandingInvoicesTable data={formik.values.outstandingInvoices}/>
          </Grid>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="reasonOfReturn" label="Reason Of Return"/>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FcTextArea formik={formik} label="Remarks" name="remarks"/>
      </Grid>
    </Grid>
  );
}

VehicleStatusDetails.propTypes = {
  formik: PropTypes.object,
  booking: PropTypes.object,
}

export default function AgreementForm({ booking, rentalAgreement, onReload, onClose, agreementType, isChangeOfVehicle }) {
  const method = "Create";

  const dataCtx = useContext(DataContext);

  const records = booking ? [...booking?.vehicleUsageRecords?.filter(record => record.rentalPrice > 0)] : [];
  records.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
  const record = records?.length > 0 ? records[0] : null;

  const { onPost } = useHttpPost("/api/booking/agreement");
  const { data: customer } = useHttpGet(`/api/customer/${booking.customerId}`);
  const { data: company } = useHttpGet(`/api/customer/${booking.companyId}`);
  const { data: conditions } = useHttpGet("/api/vehicles/condition/type");

  const tncPreviewUrl = `${process.env.REACT_APP_BACKEND_ENTRY}/api/file/tnc/preview?rentalUsage=${booking?.rentalUsage}`;
  const cdwPreviewUrl = `${process.env.REACT_APP_BACKEND_ENTRY}/api/file/cdw/preview`;

  const onCreate = async (values) => {
    try {
      await onPost(values);
      dataCtx.setSnackbarConfig({ open: true, message: "Agreement Created Successfully", severity: "success" });
      await onReload();
      onClose();
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  const formik = useFormik({
    initialValues: initialData(booking, agreementType, rentalAgreement, isChangeOfVehicle),
    validationSchema: Yup.object({
      vehicleConditionImage: Yup.string().required("Please upload the vehicle condition image!"),
      additionalCharges: Yup.array().of(
        Yup.object({
          description: Yup.string().required("Description is required!"),
          quantity: Yup.number().required("Quantity is required!"),
          unitPrice: Yup.number().required("Unit Price is required!"),
        }),
      ),
      serviceRequests: Yup.array().of(
        Yup.object({
          description: Yup.string().required("Description is required!"),
        }),
      ),
      reasonOfReturn: Yup.string().nullable(),
      petrol: Yup.number().required("Please select the petrol percentage!"),
      time: Yup.string().required("Please fill in the pick up/return time!"),
      currentMileage: Yup.string().required("Please fill in the current mileage!"),
      nextServiceMileage: Yup.string().required("Please fill in the next service mileage!"),
      refundMethod: Yup.string().nullable()
        .test("required", "Please select Refund Method!",
          (value, context) => context.parent.isRefundDeposit ? value !== "" : true),
      companySignature: Yup.string().nullable()
        .test("required", "Please upload company signature!",
          (value, context) => context.parent.customerType === "Company" && context.parent.agreementType === "Rental" ? value != null : true,
        ),
      customerSignature: Yup.string().required("Please upload customer signature!"),
      salesSignature: Yup.string().required("Please upload sales signature!"),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (agreementType === "Rental") {
        await onCreate({
          ...values,
          startDateTime: moment(`${values.startDateTime.format("YYYY-MM-DD")}T${values.time}:00+08:00`).toISOString(),
          endDateTime: values.endDateTime.toISOString(),
        });
      } else {
        await onCreate({
          ...values,
          startDateTime: values.startDateTime.toISOString(),
          endDateTime: moment(`${values.endDateTime.format("YYYY-MM-DD")}T${values.time}:00+08:00`).toISOString(),
        });
      }
      setSubmitting(false);
    }
  });

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);

  useEffect(() => {
    if (conditions) {
      formik.setFieldValue("vehicleConditions", conditions.map(condition => ({
        vehicleConditionTypeId: condition.id,
        name: condition.name,
        checked: false,
      })));
    }
  }, [conditions]);

  useEffect(() => {
    if (formik.values.isRefundDeposit) {
      formik.setFieldValue("refundAmount", booking?.deposit - (booking?.totalBillAmount ?? 0) + (booking?.totalReceivedAmount ?? 0) -
        formik.values.additionalCharges.reduce((total, cur) => total + cur.unitPrice * cur.quantity, 0));
    } else {
      formik.setFieldValue("refundAmount", 0);
    }
  }, [formik.values.isRefundDeposit]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3} py={1}>
          <Grid item xs={12}>
            <BookingDetails formik={formik}/>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ border: 1, borderColor: palette.divider }}/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" mb={2}>
              <Typography variant="h5">{company ? "Company" : "Customer"} Details</Typography>
              {(company || customer) && <FcCustomerDocuments customer={company ?? customer}/>}
            </Stack>
            {(company || customer) && <CustomerDetails data={company ?? customer}/>}
          </Grid>
          {company && <Grid item xs={12}>
            <Divider sx={{ border: 1, borderColor: palette.divider }}/>
          </Grid>}
          {(company && customer) && <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" mb={2}>
              <Typography variant="h5">Additional Driver Details</Typography>
              {customer && <FcCustomerDocuments customer={customer}/>}
            </Stack>
            {customer && <CustomerDetails data={customer}/>}
          </Grid>}
          <Grid item xs={12}>
            <Divider sx={{ border: 1, borderColor: palette.divider }}/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" mb={3}>Vehicle Details</Typography>
            <BookingVehicleDetails vehicle={agreementType === "Rental" ? record?.vehicle : booking?.vehicle}/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Vehicle Status</Typography>
          </Grid>
          <Grid item xs={12}>
            <VehicleStatusDetails formik={formik} booking={booking}/>
          </Grid>
          {formik.values.agreementType === "Rental" && <Grid item xs={12}>
            <Typography variant="h6" mb={3}>Terms and Conditions</Typography>
            <iframe src={tncPreviewUrl} width="100%" height={1000} title="Terms and Conditions"/>
          </Grid>}
          {formik.values.agreementType === "Rental" && booking?.cdwPrice > 0 && <Grid item xs={12}>
            <Typography variant="h6" mb={3}>CDW Agreement</Typography>
            <iframe src={cdwPreviewUrl} width="100%" height={1000} title="CDW Agreement"/>
          </Grid>}
          {(formik.values.agreementType === "Rental" && company) && <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" mb={2}>Company Signature</Typography>
            <SignatureBoard field="companySignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.companySignature}/>
          </Grid>}
          <Grid item xs={12} sm={6} md={formik.values.agreementType === "Rental" && company ? 4 : 6}>
            <Typography variant="h6" mb={2}>Customer Signature</Typography>
            <SignatureBoard field="customerSignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.customerSignature}/>
          </Grid>
          <Grid item xs={12} sm={6} md={formik.values.agreementType === "Rental" && company ? 4 : 6}>
            <Typography variant="h6" mb={2}>Sales Signature</Typography>
            <SignatureBoard field="salesSignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.salesSignature}/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

AgreementForm.propTypes = {
  booking: PropTypes.object,
  rentalAgreement: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
  agreementType: PropTypes.string,
  isChangeOfVehicle: PropTypes.bool,
}