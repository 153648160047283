import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Icon from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { Button, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Stack, Typography } from '@material-ui/core';
// components
import Page from '../../components/Page';
import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../../components/_dashboard/products';
import { newModelString } from './FcAppNewCarModel';
//
import DataContext from '../../store/DataContext';

// ----------------------------------------------------------------------

let carModelsFetched = false;

export const routeName = 'car-models';

export default function CarModelsMain() {
  const [openFilter, setOpenFilter] = useState(false);
  const dataCtx = useContext(DataContext);

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      make: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const [filteredCarModels, setFilteredCarModels] = useState([]);

  useEffect(() => {
    // Always re-fetch because the favourites property of every model can change every second as our user base grows
    if (!carModelsFetched) {
      dataCtx.fetchCarModels();
      carModelsFetched = true;
    }
  }, [dataCtx]);

  useEffect(() => {
    if (!openFilter) {
      if (formik.values.make !== '') {
        setFilteredCarModels(
          dataCtx.carModels.filter((element) =>
            formik.values.make.some((selectedMake) => element.make === selectedMake)
          )
        );
      }
      if (formik.values.make.length === 0) {
        setFilteredCarModels(dataCtx.carModels);
      }
    }
  }, [formik.values, openFilter, dataCtx.carModels]);

  return (
    <Page title="Car Models: Fresh Cars">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Car Models
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 5 }}
        >
          <Link component={RouterLink} to={`./${newModelString}`}>
            <Button variant="contained">
              <Icon icon={plusFill} />
              &nbsp;Add New Model
            </Button>
          </Link>
          <Stack direction="row">
            <ProductFilterSidebar
              carModelsList={dataCtx.carModels}
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        {/* <ProductList products={PRODUCTS} /> */}
        <ProductList products={filteredCarModels} />
        <ProductCartWidget />
      </Container>
    </Page>
  );
}
