import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { createUserWithEmailAndPassword, getAuth, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@material-ui/lab';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import useGetDocument from '../../../hooks/firebase/useGetDocument';
import FirestoreContext from '../../../store/FirestoreContext';

const configApp = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID2,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID2
  };

  const apps = getApps();
  if (!apps.find((item) => item.name === 'secondary')) {
    initializeApp(firebaseConfig, 'secondary');
  }

  const app = getApp('secondary');
  const auth = getAuth(app);

  return { app, auth };
};

const createUser = async (app, data, uid) => {
  const db = getFirestore(app);

  const ref = doc(db, 'fcadmin/fcadmin/users', uid);
  await setDoc(ref, data);
};

export default function UserForm({ userData = null, onClose, onReload, onOpenSnackbar }) {
  const method = userData ? 'Update' : 'Create';
  const fsCtx = useContext(FirestoreContext);

  const { data } = useGetDocument('fcadmin/fcadmin');
  const departments = data?.departments ? Object.entries(data?.departments) : [];

  const [showPassword, setShowPassword] = useState(false);

  const onCreate = async (values) => {
    try {
      const { app, auth } = configApp();
      const { user } = await createUserWithEmailAndPassword(auth, values.email, values.pwd);

      const data = {
        alias: values.name,
        department: values.department,
        email: values.email,
        name: values.name,
        uid: user.uid,
        startDate: values.startDate.toISOString(),
        entitlementLeave: values.entitlementLeave
      };
      await createUser(app, data, user.uid);
      await signOut(auth);
      await onReload();
      onOpenSnackbar('User created successfully');
      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.error(error);
    }
  };

  const onUpdate = async (values) => {
    try {
      const data = {
        alias: values.name,
        department: values.department,
        email: values.email,
        name: values.name,
        uid: userData.uid,
        startDate: values.startDate.toISOString(),
        entitlementLeave: values.entitlementLeave
      };

      const db = fsCtx.fsObject;
      const ref = doc(db, 'fcadmin/fcadmin/users', userData.id);
      await updateDoc(ref, data);
      await onReload();
      onOpenSnackbar('User updated successfully');
      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      pwd: '',
      name: '',
      department: '',
      startDate: '',
      entitlementLeave: 0
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      name: Yup.string().required('Name is required'),
      pwd: method === 'Create' && Yup.string().required('Password is required'),
      department: Yup.string().required('Department is required'),
      startDate: Yup.date(),
      entitlementLeave: Yup.number()
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      if (method === 'Create') {
        await onCreate(values);
        resetForm();
      } else {
        await onUpdate(values);
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (userData) {
      formik.setFieldValue('email', userData.email);
      formik.setFieldValue('name', userData.name);
      formik.setFieldValue('department', userData.department);
      formik.setFieldValue('startDate', moment(userData?.startDate));
      formik.setFieldValue('entitlementLeave', userData?.entitlementLeave);
    }
  }, [userData]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <TextField
          label="Email"
          type="email"
          name="email"
          variant="standard"
          margin="dense"
          fullWidth
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.email && formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          disabled={method === 'Update'}
        />

        <TextField
          label="Password"
          type={showPassword ? 'string' : 'password'}
          name="pwd"
          variant="standard"
          margin="dense"
          fullWidth
          value={formik.values.pwd}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.pwd && formik.errors.pwd)}
          helperText={formik.touched.pwd && formik.errors.pwd}
          disabled={method === 'Update'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <TextField
          label="Name"
          name="name"
          variant="standard"
          margin="dense"
          fullWidth
          value={formik.values.name}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.name && formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <FormControl variant="standard" sx={{ width: '100%', marginBottom: 1 }}>
          <InputLabel>Department</InputLabel>
          <Select
            label="Department"
            name="department"
            value={formik.values.department}
            onChange={formik.handleChange}
          >
            {departments?.map(([val, department], index) => (
              <MenuItem key={index} value={val}>
                {department}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '100%', marginBottom: 1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              disableMaskedInput
              label="Start Date"
              name="startDate"
              inputFormat="DD-MM-YYYY"
              onChange={(val) => formik.setFieldValue('startDate', val)}
              value={formik.values.startDate}
              renderInput={(params) => <TextField variant="standard" fullWidth {...params} />}
            />
          </LocalizationProvider>
        </FormControl>

        <TextField
          label="Entitlement leave"
          name="entitlementLeave"
          type="number"
          variant="standard"
          margin="dense"
          fullWidth
          value={formik.values.entitlementLeave}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.entitlementLeave && formik.errors.entitlementLeave)}
          helperText={formik.touched.entitlementLeave && formik.errors.entitlementLeave}
        />

        <Box sx={{ marginTop: 3 }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={() => onClose()}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
              Submit
            </LoadingButton>
          </Stack>
        </Box>
      </Form>
    </FormikProvider>
  );
}

UserForm.propTypes = {
  userData: PropTypes.object,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  onOpenSnackbar: PropTypes.func
};
