import React, { useContext, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from 'yup';
import {LoadingButton} from "@material-ui/lab";
import { collection, getDocs, query } from "firebase/firestore";
import FirestoreContext from "../../../store/FirestoreContext";
import {fetchEnhcApi} from "../../../utils/fetchApi";

export default function NewDisposalTask() {
  const navigate = useNavigate();
  const fsCtx = useContext(FirestoreContext);
  const [users, setUsers] = useState([]);

  const createDisposalTask = async (values) => {
    try {
      await fetchEnhcApi("CreateVehicleDisposalTask", values)
      navigate("../");
    } catch (error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      TaskName: "",
      Assign: "",
    },
    validationSchema: Yup.object().shape({
      TaskName: Yup.string().required("Please fill in the task name"),
      Assign: Yup.string(),
    }),
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(false);
      await createDisposalTask(values);
      setSubmitting(true);
    }
  });

  useEffect(() => {
    const q = query(collection(fsCtx.fsObject, 'fcadmin/fcadmin/users'));
    getDocs(q)
      .then(querySnapshot => {
        const usersDocs = querySnapshot.docs;
        setUsers(usersDocs.map(doc => doc.data().name));
      })
      .catch(console.error);
  }, [fsCtx.fsObject]);

  const {errors, touched, handleSubmit, isSubmitting, getFieldProps} = formik;
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom>
        Add Disposal Task
      </Typography>

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              variant="filled" fullWidth
              label="Task Name"
              {...getFieldProps("TaskName")}
              error={Boolean(touched.TaskName && errors.TaskName)}
              helperText={touched.TaskName && errors.TaskName}
            />

            <FormControl fullWidth>
              <InputLabel id="assign-label">Assign To</InputLabel>
              <Select
                labelId="assign-label"
                id="assign-user-select"
                label="Assign To"
                {...getFieldProps("Assign")}
              >
                {users.map((user, index) => (
                  <MenuItem key={index} value={user}>
                    {user}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Add Task
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Container>
  );
}
