import { Icon } from '@iconify/react';
import mailFilled from '@iconify/icons-ant-design/mail-filled';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Link, Typography } from '@material-ui/core';
// utils
// import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { Link as ReactLink } from 'react-router-dom';

import FirestoreContext from '../../../store/FirestoreContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function MailingListCard() {
  const fsCtx = useContext(FirestoreContext);
  const [mailingListSignUps, setMailingListSignUps] = useState(0);

  useEffect(() => {
    let isMounted = true;
    getDoc(doc(fsCtx.fsObject, 'fcadmin/mailingList'))
      .then((docSnap) => {
        if (isMounted) setMailingListSignUps(docSnap.data().mailingList.length);
      })
      .catch((error) => {
        const errString = `Unable to fetch mailing list: ${error}\n${error.stack}`;
        console.error(errString);
        alert(errString);
      });

    return () => (isMounted = false);
  }, [fsCtx.fsObject]);

  return (
    <Link
      component={ReactLink}
      to="/dashboard/fc-app/app-mailing-list"
      sx={{ textDecoration: 'none' }}
    >
      <RootStyle>
        <IconWrapperStyle>
          <Icon icon={mailFilled} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{mailingListSignUps}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {/* Refunds Today | {moment(new Date()).format('d MMM yyyy')} */}
          Mailing List Sign Ups
        </Typography>
      </RootStyle>
    </Link>
  );
}
