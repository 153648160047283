import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Typography
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import ReturnJobItem from '../../../components/Workshop/ReturnJobItem';

export default function ReturnJobListing() {
  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/workshop/return_job`);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom mb={3}>
        This Month Return Job Listing
      </Typography>

      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button
          variant="contained" color="secondary"
          startIcon={<Icon icon={plusFill} />}
          component={Link} to="./new"
        >
          Create Return Job
        </Button>
      </div>

      {
        [...new Set(data.data?.map((returnJob) => returnJob.mechanic))].sort().map((name) => {
          const filtered = data.data?.filter((returnJob) => returnJob.mechanic === name);
          return (
            <Accordion key={name} elevation={10} defaultExpanded>
              <AccordionSummary>
                <Typography variant="h5">{name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {filtered.map((returnJob) => <ReturnJobItem key={returnJob.id} returnJob={returnJob} onReload={() => onGet()} />)}
              </AccordionDetails>
            </Accordion>
          );
        })
      }
    </Container>
  );
}