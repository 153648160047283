import {
  Box,
  Grid,
  Backdrop,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Stack,
  Button,
  DialogActions,
} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useEffect, useState} from "react";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import DataContext from "../../../../store/DataContext";
import CustomerDetails from "../../../Details/Customer/CustomerDetails";
import {FcCustomerDocument} from "../../../Document/Documents";
import CustomerForm from "../../Customer/CustomerForm";

const CustomerDocuments = ({ customer }) => {
  const [open, setOpen] = useState(false);
  const { data, onGet } = useHttpGet(`/api/customer/${customer?.id}/documents`);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{customer?.name} Documents</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {data?.map((document, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <Typography variant="h6">{document.label}</Typography>
                <FcCustomerDocument document={document} onReload={onGet}/>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Documents
      </Button>
    </>
  );
}

CustomerDocuments.propTypes = {
  customer: PropTypes.object,
}

export default function CustomerQuestionForm({ formik, identity, type, onBackPreviousPage }) {
  const title = type === "driver" ? "Additional Driver" : "Customer";

  const dataCtx = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const { data, loading, error, onGet } = useHttpGet(`/api/customer/identity/${identity}`);
  const isNullOrEmpty = val => val == null || typeof(val) === "string" && val.trim() === "";

  const isCustomerInfoComplete = (customer) => {
    let requiredKeys = ["name", "email", "phone", "address", "postal", "identity", "isNotBlacklisted", "isIdentityVerified"];
    let requiredLabels = [];
    if (customer.customerType === "Individual") {
      requiredKeys = requiredKeys.concat(["birthDate", "drivingLicenseEffectiveDate", "isDrivingLicenseVerified"]);
      requiredLabels = requiredLabels.concat(["Identity Front", "Identity Back", "Driving License Front", "Driving License Back"]);
    } else {
      requiredLabels = requiredLabels.concat(["Company ACRA"]);
    }

    const labels = customer.documents.map(doc => doc.label);
    const isDocumentComplete = requiredLabels.every(label => labels.includes(label));

    return isDocumentComplete && requiredKeys.every(key => key in customer && !isNullOrEmpty(customer[key]));
  }

  useEffect(() => {
    if (error) {
      setOpen(true);
      if (type !== "driver") formik.setFieldValue("companyId", null);
      formik.setFieldValue("customerId", null);
    } else if (!loading && data !== null) {
      if (!isCustomerInfoComplete(data)) {
        setOpen(true);
        dataCtx.setSnackbarConfig({ open: true, message: "Customer info not complete!", severity: "error" });
        formik.setFieldValue("customerId", null);
      } else if (data.customerType === "Company" && type !== "driver") {
        formik.setFieldValue("companyId", data.id);
        formik.setFieldValue("customerType", data.customerType);
      } else if (data.customerType === "Company" && type === "driver") {
        formik.setFieldValue("customerId", null);
        dataCtx.setSnackbarConfig({ open: true, message: "This is company identity!", severity: "error" });
        onBackPreviousPage();
      } else {
        formik.setFieldValue("customerId", data.id);
        formik.setFieldValue("customerType", data.customerType);
      }
    }
  }, [data, loading, error]);

  return (
    <Grid container spacing={3}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Create Customer</DialogTitle>
        <DialogContent>
          <Box py={1}>
            <CustomerForm data={data} onClose={() => setOpen(false)} onReload={onGet} identity={identity}/>
          </Box>
        </DialogContent>
      </Dialog>

      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{title} Details</Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={() => setOpen(true)}>Edit</Button>
            {data && <CustomerDocuments customer={data}/>}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {data && <CustomerDetails data={data}/>}
      </Grid>
    </Grid>
  );
}

CustomerQuestionForm.propTypes = {
  formik: PropTypes.object,
  identity: PropTypes.string,
  type: PropTypes.string,
  onBackPreviousPage: PropTypes.func,
}