import { Container, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import BackButton from '../../../components/BackButton';
import WorkshopAppointmentForm from '../../../components/Form/Workshop/WorkshopAppointmentForm';

export default function EditWorkshopAppointment() {
  const params = useParams();
  const { data } = useCloudFuncGet(`${cloudFunctionPath}/workshop/appointment/${params.id}`)
  return (
    <Container maxWidth="xl">
      <BackButton />

      <Typography variant="h4" gutterBottom mb={3}>
        Editing Appointment of {data.data?.customerName} at {dayjs(data.data?.time).format("ddd, DD MMM HH:mm A")}
      </Typography>

      <WorkshopAppointmentForm data={data.data} />
    </Container>
  );
}