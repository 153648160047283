import {DialogContent} from "@material-ui/core";
import {Box, Button, Dialog, DialogTitle, Stack} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import {StyledDataGrid} from "../../../../pages/Vehicle/styles";
import DisposalTaskForm from "../../../Form/Vehicle/Disposal/DisposalTaskForm";

const EditAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <DisposalTaskForm data={row} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Edit</Button>
    </>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" spacing={1}>
      <EditAction row={row} onReload={onReload}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function DisposalTaskTable({ data, onReload }) {
  const columns = [
    { field: "task", headerName: "Task", align: "center", headerAlign: "center", width: 800 },
    { field: "defaultStaff", headerName: "Assign to", align: "center", headerAlign: "center", width: 150 },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 200,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>
    },
  ];

  return (
    <StyledDataGrid columns={columns} rows={data ?? []} autoHeight/>
  );
}

DisposalTaskTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}