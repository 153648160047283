import {LoadingButton} from "@material-ui/lab";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container, Grid,
  Stack,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import {Form, FormikProvider, useFormik} from "formik";
import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import * as Yup from "yup";
import FcGanttChart from "../../components/Charts/FcGanttChart";
import {FcSelectField, FcTextField} from "../../components/Form/FormFields";
import VehicleGanttChartFilterForm from "../../components/Form/Vehicle/VehicleGanttChartFilterForm";
import useHttpGet from "../../hooks/http/useHttpGet";
import {getDayjsValue} from "../../utils/date";

const statusColor = {
  "Booked": "#0288D1",
  "COE Expired": "#353531",
  "Insurance Expired": "#FFAD05",
  "Inspection Expired / Road Tax Expired": "#A4BAB7",
  "Accident / Workshop / Washing": "#BC8DA7",
};

const VehicleUsageRecordDetails = ({ record, vehicle, users }) => {
  const user = users?.find(user => user.uid === record?.booking?.userId);

  return (
    <Stack>
      <Typography>Booking No.: {record?.booking?.bookingNo}</Typography>
      <Typography>Vehicle No.: {vehicle?.vehicleNumber}</Typography>
      <Typography>Make / Model: {vehicle?.model?.make?.name} {vehicle?.model?.name}, {vehicle?.model?.petrolType}</Typography>
      <Typography>Customer Name: {record?.booking?.customerType === "Company" ? record?.booking?.company?.name : record?.booking?.customer?.name}</Typography>
      <Typography>Sales Name: {user?.name}</Typography>
      <Typography>Vehicle Company Name: {vehicle?.logcard?.vehicleOwner?.name}</Typography>
      <Typography>Rental Period: {getDayjsValue(record?.booking?.startDate).format("DD MMM YYYY")} - {getDayjsValue(record?.booking?.endDate).format("DD MMM YYYY")}</Typography>
      <Typography>Booking Status: {record?.booking?.status}</Typography>
      <Typography>Deposit: S${record?.booking?.deposit}</Typography>
      <Typography>Booking Deposit: S${record?.booking?.bookingCost}</Typography>
      <Typography>Rental Usage: {record?.booking?.rentalUsage}</Typography>
      <Typography>Rental Rate: S${record?.booking?.rentalPrice} ({record?.booking?.pricePeriod?.columnName})</Typography>
    </Stack>
  );
}

VehicleUsageRecordDetails.propTypes = {
  record: PropTypes.object,
  vehicle: PropTypes.object,
  users: PropTypes.array,
}

const FilterForm = ({ formik }) => {
  const months = [
    { label: "Jan", value: 1 },
    { label: "Feb", value: 2 },
    { label: "Mar", value: 3 },
    { label: "Apr", value: 4 },
    { label: "May", value: 5 },
    { label: "Jun", value: 6 },
    { label: "Jul", value: 7 },
    { label: "Aug", value: 8 },
    { label: "Sep", value: 9 },
    { label: "Oct", value: 10 },
    { label: "Nov", value: 11 },
    { label: "Dec", value: 12 },
  ];

  const curYear = new Date().getFullYear();
  const years = [
    { label: curYear - 2, value: curYear - 2 },
    { label: curYear - 1, value: curYear - 1 },
    { label: curYear, value: curYear },
    { label: curYear + 1, value: curYear + 1 },
    { label: curYear + 2, value: curYear + 2 },
  ];

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="month" label="Month" items={months}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="year" label="Year" items={years}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="make" label="Make"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="model" label="Model"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleNumber" label="Vehicle No."/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={formik.resetForm}>
                Clear
              </Button>
              <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                Search
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

FilterForm.propTypes = {
  formik: PropTypes.object,
}

export default function VehicleUsageRecord() {
  const [searchParams] = useSearchParams();
  const [year, setYear] = useState(Number(searchParams.get("year")));
  const [month, setMonth] = useState(Number(searchParams.get("month")));

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(1024);

  const { data, loading, onGet } = useHttpGet("/api/vehicles/usage/gantt-chart", false);
  const { data: users } = useHttpGet("/api/user/list");
  const [startDate, setStartDate] = useState(year && month ? new Date(year, month, 0) : new Date());
  const [endDate, setEndDate] = useState(new Date());

  const getDateRange = (start) => {
    const startDate = new Date(start.getFullYear(), start.getMonth(), 1);
    const endDate = new Date(start.getFullYear(), start.getMonth() + 1, 0);

    return [startDate, endDate];
  }

  const formik = useFormik({
    initialValues: {
      make: "",
      model: "",
      vehicleNumber: "",
      month: Number(searchParams.get("month")),
      year: Number(searchParams.get("year")),
    },
    validationSchema: Yup.object({
      make: Yup.string().nullable(),
      model: Yup.string().nullable(),
      vehicleNumber: Yup.string().nullable(),
      month: Yup.number().required("Month is required!"),
      year: Yup.number().required("Year is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const startDate = new Date(values.year, values.month - 1, 1);
      const [start, end] = getDateRange(startDate);
      setStartDate(start);
      setEndDate(end);
      await onGet({ ...values, startDate: start.toISOString(), endDate: end.toISOString() });
      setSubmitting(false);
    }
  });

  const records = data?.map(vehicle => {
    let bookingRecords = vehicle.vehicleUsageRecords.map(record => ({
      startDate: record.startDate,
      endDate: record.endDate,
      backgroundColor: statusColor.Booked,
      hoverComponent: <VehicleUsageRecordDetails record={record} vehicle={vehicle} users={users}/>,
      url: `/dashboard/sales/booking/${record?.booking?.bookingNo}`,
      description: `${record?.booking?.bookingNo}: ${vehicle?.vehicleNumber}, ${record?.booking?.customer?.name} (${getDayjsValue(record.endDate).diff(getDayjsValue(record.startDate), "days")} days)`,
    }));

    bookingRecords = bookingRecords.sort((a, b) => dayjs(a.startDate) - dayjs(b.startDate));
    const records = [...bookingRecords];

    if (getDayjsValue(vehicle?.logcard?.coeExpiryDate) <= getDayjsValue(endDate))
      records.push({
        startDate: vehicle?.logcard?.coeExpiryDate,
        endDate: null,
        hoverComponent: null,
        backgroundColor: statusColor["COE Expired"],
        description: "COE Expired",
      });

    if (getDayjsValue(vehicle?.inspectionDueDate) <= getDayjsValue(endDate))
      records.push({
        startDate: vehicle?.inspectionDueDate,
        endDate: null,
        hoverComponent: null,
        backgroundColor: statusColor["Inspection Expired / Road Tax Expired"],
        description: "Inspection Expired",
      });

    if (vehicle?.insurance === null || getDayjsValue(vehicle?.insurance?.endDate) <= getDayjsValue(endDate))
      records.push({
        startDate: vehicle?.insurance?.endDate,
        endDate: null,
        hoverComponent: null,
        backgroundColor: statusColor["Insurance Expired"],
        description: "Insurance Expired",
      });

    return { label: vehicle?.vehicleNumber, data: records };
  }) ?? [];

  const handleChangeStartDate = async num => {
    const newDate = new Date(startDate);
    setMonth(newDate.getMonth() + num + 1);
    setYear(newDate.getFullYear());
    newDate.setMonth(newDate.getMonth() + num);
    const [start, end] = getDateRange(newDate);
    await onGet({ startDate: start.toISOString(), endDate: end.toISOString() });
    setStartDate(start);
    setEndDate(end);
  }

  const handleClickToday = async e => {
    e.preventDefault();
    const newDate = new Date();
    setMonth(newDate.getMonth() + 1);
    setYear(newDate.getFullYear());
    const [start, end] = getDateRange(newDate);
    await onGet({ startDate: start.toISOString(), endDate: end.toISOString() });
    setStartDate(start);
    setEndDate(end);
  }

  const updateWidth = () => {
    if (parentRef.current?.offsetWidth > 1024) setParentWidth(parentRef.current.offsetWidth);
    else setParentWidth(1024);
  }

  useEffect(() => {
    handleChangeStartDate(0);
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const FootNotes = () => {
    const statusList = [
      { status: "Booked", color: statusColor.Booked },
      { status: "COE Expired", color: statusColor["COE Expired"] },
      { status: "Insurance Expired", color: statusColor["Insurance Expired"] },
      { status: "Inspection Expired / Road Tax Expired", color: statusColor["Inspection Expired / Road Tax Expired"] },
      { status: "Accident / Workshop / Washing", color: statusColor["Accident / Workshop / Washing"] },
    ];

    return (
      <Stack spacing={1} p={1} border={1} borderColor="#9E9E9E" width={parentWidth}>
        {statusList.map(({ status, color }, index) => (
          <Stack key={index} direction="row" spacing={1} alignItems="center">
            <Box sx={{ display: "inline", backgroundColor: color, width: 14, height: 14 }}/>
            <Typography variant="body2">{status}</Typography>
          </Stack>
        ))}
      </Stack>
    );
  }

  return (
    <Container maxWidth="xl" ref={parentRef}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <VehicleGanttChartFilterForm
        year={year} month={month}
        onReload={onGet} getDateRange={getDateRange}
        setStartDate={setStartDate} setEndDate={setEndDate}
        setMonth={setMonth} setYear={setYear}
      />
      <Stack direction="row" justifyContent="space-between" my={3}>
        <Typography variant="h4">Vehicle Usage Gantt Chart</Typography>
        <ButtonGroup variant="contained" color="info">
          <Button onClick={() => handleChangeStartDate(-1)}>Previous</Button>
          <Button color="secondary" onClick={handleClickToday}>Today</Button>
          <Button onClick={() => handleChangeStartDate(1)}>Next</Button>
        </ButtonGroup>
      </Stack>
      <FcGanttChart data={records} range={[startDate, endDate]} width={parentWidth}/>
      <FootNotes/>
    </Container>
  );
}