import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import DataContext from "../../../../store/DataContext";
import {getDayjsValue, getMomentValue} from "../../../../utils/date";
import BookingVehicleTable from "../../../Table/Booking/BookingVehicleTable";
import {FcDateField, SubmitBtnGroup} from "../../FormFields";

export default function ChangeOfVehicleForm({ type = "COV", booking, onReload, onClose }) {
  const method = "Create";

  const dataCtx = useContext(DataContext);

  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicle, setVehicle] = useState(null);

  const { data: vehicles, onGet } = useHttpGet("/api/booking/vehicle", true, {
    startDate: moment(moment().format("DD MMM YYYY")).toISOString(),
    endDate: getMomentValue(booking?.endDate).toISOString(),
    rentalUsage: booking?.rentalUsage,
  });
  const { data: pricePeriod } = useHttpGet(`/api/vehicles/price-period/${booking?.pricePeriodId}`);
  const { data: makes } = useHttpGet("/api/vehicles/make");
  const { data: models } = useHttpGet("/api/vehicles/model");
  const { onPost } = useHttpPost("/api/vehicles/usage");

  const records = booking?.vehicleUsageRecords ?? [];
  const lastRecord = records.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))[0];

  const formik = useFormik({
    initialValues: {
      type: type,
      startDate: moment(moment().format("DD MMM YYYY")),
      endDate: getMomentValue(booking?.endDate),
      rentalPrice: 0,
      originalRentalPrice: 0,
      vehicleId: null,
      bookingId: booking?.id,
    },
    validationSchema: Yup.object({
      startDate: Yup.date()
        .min(getMomentValue(lastRecord?.startDate), `start date must later than ${getMomentValue(lastRecord?.startDate).format("DD-MM-YYYY")}`)
        .required("Start Date is required!"),
      endDate: Yup.date().required("End Date is required!"),
      rentalPrice: Yup.number()
        .when('type', {
          is: "COV",
          then: (schema) => schema.positive("Rental Price should more than 0")
            .required("Rental Price is required!"),
          otherwise: (schema) => schema.required("Rental Price is required!"),
        }),
      originalRentalPrice: Yup.number().required("Original Rental Price is required!"),
      vehicleId: Yup.string().required("Vehicle is required!"),
      bookingId: Yup.string().required("Booking is required!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost({
          ...values,
          startDate: values.startDate.toISOString(),
          endDate: values.endDate.toISOString(),
        });
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Vehicle changed successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const handleVehicleSelect = vehicle => {
    const price = vehicle?.model?.prices?.find(p => p.pricePeriodId === booking?.pricePeriodId);
    setVehicle(vehicle);
    formik.setFieldValue("vehicleId", vehicle.id);
    if (type === "COV") {
      formik.setFieldValue("rentalPrice", price?.price ?? 0);
      formik.setFieldValue("originalRentalPrice", price?.price ?? 0);
    }
  }

  const onSearch = async e => {
    e.preventDefault();

    const { startDate, endDate } = formik.values;

    if (model?.id) await onGet({ modelId: model.id, startDate: startDate.toISOString(), endDate: endDate.toISOString(), rentalUsage: booking?.rentalUsage });
    else if (make?.id) await onGet({ makeId: make.id, startDate: startDate.toISOString(), endDate: endDate.toISOString(), rentalUsage: booking?.rentalUsage });
    else await onGet({ startDate: startDate.toISOString(), endDate: endDate.toISOString(), rentalUsage: booking?.rentalUsage });
  }

  const getMonth = () => getDayjsValue(formik.values?.startDate).format("MM");
  const getYear = () => getDayjsValue(formik.values?.startDate).format("YYYY");

  const getRentalPrice = (increase) => {
    const price = vehicle?.model?.prices?.find(p => p.pricePeriodId === booking?.pricePeriodId);
    const pricePeriod = price?.pricePeriod?.paymentPeriod;
    const pricePerDay = formik.values.originalRentalPrice / pricePeriod;
    return (pricePerDay + increase) * pricePeriod;
  }

  const getMonthlyRentalPrice = (increase) => formik.values.originalRentalPrice + increase;

  let prices = [
    { value: getRentalPrice(0), label: "+0" },
    { value: getRentalPrice(3), label: "+3" },
    { value: getRentalPrice(6), label: "+6" },
    { value: getRentalPrice(9), label: "+9" },
    { value: getRentalPrice(12), label: "+12" },
  ];
  if (pricePeriod?.paymentPeriod === 30) {
    prices = [
      { value: getMonthlyRentalPrice(0), label: "+0" },
      { value: getMonthlyRentalPrice(100), label: "+100" },
      { value: getMonthlyRentalPrice(200), label: "+200" },
      { value: getMonthlyRentalPrice(300), label: "+300" },
      { value: getMonthlyRentalPrice(400), label: "+400" },
    ];
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="startDate" label="Start Date"/>
          </Grid>
          {type === "Replacement" && <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="endDate" label="End Date"/>
          </Grid>}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              value={make}
              onChange={(e, newValue) => {
                setMake(newValue);
                setModel(null);
              }}
              options={makes ?? []}
              getOptionLabel={make => make.name}
              renderInput={params => <TextField {...params} label="Make"/>}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              value={model}
              onChange={(e, newValue) => {
                setModel(newValue);
              }}
              options={models?.filter(m => m.makeId === make?.id) ?? []}
              getOptionLabel={model => model.name}
              renderInput={params => <TextField {...params} label="Model"/>}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              label="Vehicle Number"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
            />
          </Grid>
          {formik.values.rentalPrice > 0 && <Grid item xs={12}>
            <Typography variant="h6">Rental Rate</Typography>
            S${formik.values.rentalPrice}
            <RadioGroup
              row
              name="rentalPrice" value={formik.values.rentalPrice}
              onChange={e => formik.setFieldValue("rentalPrice", Number(e.target.value))}
            >
              {prices.map(({value, label}, index) => (
                <FormControlLabel key={index} control={<Radio/>} label={label} value={value}/>
              ))}
            </RadioGroup>
          </Grid>}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" mb={2} spacing={2}>
              <Button
                component={Link} to={`../../booking-chart?month=${getMonth()}&year=${getYear()}`} target="_blank"
                startIcon={<CalendarMonthIcon/>} variant="contained" color="info"
              >
                Gantt Chart
              </Button>
              <Button startIcon={<SearchIcon/>} variant="contained" onClick={onSearch}>Search</Button>
            </Stack>
            {vehicles && (
              <BookingVehicleTable formik={formik} data={vehicles.filter(vehicle => vehicle.vehicleNumber.includes(vehicleNumber) && vehicle.id !== booking.vehicleId)} vehicle={null} handleVehicleSelect={handleVehicleSelect}/>
            )}
          </Grid>
          {formik.values.rentalPrice <= 0 && type === "COV" && <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <Typography color="red">{formik.errors.rentalPrice}</Typography>
            </Stack>
          </Grid>}
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

ChangeOfVehicleForm.propTypes = {
  type: PropTypes.string,
  booking: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}