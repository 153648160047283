import {Checkbox} from "@material-ui/core";
import {Box, Grid, Typography} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import React from "react";
import {defaultTimezone} from "../../../utils/date";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function CustomerDetails({ data }) {
  const details = [
    { label: "Name", value: data?.name },
    { label: "Customer Type", value: data?.customerType },
    { label: "Phone", value: data?.phone },
    { label: "Email", value: data?.email },
    { label: "NRIC/FIN/ACRA", value: data?.identity },
  ];

  if (data?.customerType === "Individual") {
    details.push({ label: "Birth Date", value: dayjs(data?.birthDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY") });
    details.push({
      label: "Driving License Effective Date",
      value: dayjs(data?.drivingLicenseEffectiveDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY")
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={9}>
        <Grid container spacing={3}>
          {details.map(({ label, value }, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Typography variant="h6">{label}</Typography>
              {value}
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6">Address</Typography>
            {data?.address}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6">Postal</Typography>
            {data?.postal}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="h6">Verifications</Typography>
        <Box>
          <Checkbox checked={data?.isNotBlacklisted ?? false}/> Customer not in blacklist
        </Box>
        <Box>
          <Checkbox checked={data?.isIdentityVerified ?? false}/> NRIC/FIN/ACRA verified
        </Box>
        {data?.customerType === "Individual" && <Box>
          <Checkbox checked={data?.isDrivingLicenseVerified ?? false}/> Driving License verified
        </Box>}
      </Grid>
    </Grid>
  );
}

CustomerDetails.propTypes = {
  data: PropTypes.object,
}