import {useContext, useEffect, useState} from "react";
import {doc, getDoc} from "firebase/firestore";
import FirestoreContext from "../../store/FirestoreContext";

export default function useGetDocument(path) {
  const fsCtx = useContext(FirestoreContext);
  const db = fsCtx.fsObject;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    const ref = doc(db, path);
    getDoc(ref)
      .then(docSnap => {
        if (docSnap.exists()) setData(docSnap.data());
        else setError("Not such document!");
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, [db, path]);

  return { data, loading, error };
}