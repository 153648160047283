import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpPut from "../../../hooks/http/useHttpPut";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import {COLLECTION_PERMISSIONS} from "../../../utils/authorization/permissions/invoicePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import {defaultTimezone} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";
import RefundVoucherDetails from "../../Details/RefundVoucher/RefundVoucherDetails";

dayjs.extend(utc);
dayjs.extend(timezone);

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/refund-voucher/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Refund Voucher deleted successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete {row.refundNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setOpen(true)} variant="contained" color="error">Delete</Button>
    </>
  )
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const VoidAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading } = useHttpPut(`/api/refund-voucher/${row?.id}/void`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onPut();
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Refund Voucher voided successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Void {row.refundNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Void</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setOpen(true)} variant="contained" color="error" disabled={row.status === "Voided"}>
        Void
      </Button>
    </>
  )
}

VoidAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ViewAction = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Refund Voucher {row.refundNo}</DialogTitle>
        <DialogContent>
          <RefundVoucherDetails refundVoucher={row}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <Button sx={{ ml: 2 }} component={Link} to={getFile(row.attachment)} target="_blank" variant="contained">
            Print
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <ViewAction row={row}/>
      {permissionRequired(authCtx, COLLECTION_PERMISSIONS.DELETE) && <DeleteAction row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, COLLECTION_PERMISSIONS.VOID) && <VoidAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function AccountRefundVoucherTable({ data, onReload }) {
  const columns = [
    { field: "refundNo", headerName: "Refund No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 100, sortable: false },
    { field: "title", headerName: "Title", align: "center", headerAlign: "center", width: 250, sortable: false },
    { field: "reason", headerName: "Reason", align: "center", headerAlign: "center", width: 250, sortable: false },
    {
      field: "amount", headerName: "Total Amount", align: "center", headerAlign: "center", width: 150, sortable: false,
      renderCell: ({ row }) => `S$${row.amount}`,
    },
    {
      field: "refundDate", headerName: "Refund Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.refundDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY"),
    },
    { field: "remarks", headerName: "Remarks", align: "center", headerAlign: "center", width: 350, sortable: false },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 300,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>,
    },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={data ?? []}
      disableSelectionOnClick autoHeight
      pageSize={100} rowsPerPageOptions={[100]}
    />
  )
}

AccountRefundVoucherTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}