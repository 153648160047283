import { Divider, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import palette from "../../../theme/palette";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import {getDayjsValue, paymentPeriodTypes} from "../../../utils/date";

export default function CommissionPointDetails({ data }) {
  const details = [
    { label: "Rental Agreement No.", value: data?.firstRentalAgreement?.agreementNo },
    { label: "Booking No.", value: data?.booking?.bookingNo },
    { label: "Start Date", value: getDayjsValue(data?.booking?.startDate).format("DD MMM YYYY") },
    { label: "End Date", value: getDayjsValue(data?.booking?.endDate).format("DD MMM YYYY") },
    { label: "Customer", value: data?.booking?.company?.name ?? data?.booking?.customer?.name },
    { label: "New/Existing Customer", value: data?.isNewCustomer ? "New" : "Existing" },
    { label: "Type of Billing", value: paymentPeriodTypes[data?.booking?.pricePeriod?.paymentPeriod] },
    { label: "Deposit", value: `S$${data?.booking?.deposit}` },
    { label: "Rental Rate", value: data?.firstRentalAgreement?.rentalPrice },
    { label: "Total Upfront", value: `S$${data?.booking?.totalUpfront}` },
    { label: "Sales", value: data?.username },
    { label: "Vehicle No.", value: data?.firstRentalAgreement?.vehicleNumber },
  ];

  const otrDetails = [
    { label: "OTR Status", value: capitalizeFirstLetter(data?.status) },
    { label: "OTR", value: data?.commissionPoint },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({ label, value, props = { xs: 12, sm: 6, md: 4, lg: 3 } }, index) => (
        <Grid item key={index} {...props}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Divider sx={{ border: 1, borderColor: palette.divider }}/>
      </Grid>
      {otrDetails.map(({ label, value, props = { xs: 12, sm: 6, md: 4, lg: 3 } }, index) => (
        <Grid item key={index} {...props}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
    </Grid>
  );
}

CommissionPointDetails.propTypes = {
  data: PropTypes.object,
}