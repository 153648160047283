import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import {
  Box,
  Container,
  Tab,
  Typography
} from "@mui/material";
import React, {useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import FcBackdrop from "../../components/FcBackdrop";
import useHttpGet from "../../hooks/http/useHttpGet";
import AuthContext from "../../store/AuthContext";
import {INVOICE_PERMISSIONS} from "../../utils/authorization/permissions/invoicePermissions";
import {permissionRequired} from "../../utils/authorization/roles";
import BookingAccountTab from "./Tabs/BookingAccountTab";
import BookingDetailsTab from "./Tabs/BookingDetailsTab";
import BookingScheduleTab from "./Tabs/BookingScheduleTab";

export default function BookingDetails() {
  const { bookingNo } = useParams();

  const authCtx = useContext(AuthContext);

  const { data: booking, loading, onGet } = useHttpGet(`/api/booking/${bookingNo}`);
  const { data: agreements, onGet: onAgreementGet } = useHttpGet(`/api/booking/${bookingNo}/agreement`);
  const { data: recurringInvoices, onGet: onRecurringInvoicesGet } = useHttpGet(`/api/invoice/recurring`, true, {
    bookingNo: bookingNo,
  })
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState(authCtx.userDept === "accounts" ? "account" : "details");

  const onReload = async () => {
    await onGet();
    await onAgreementGet();
    await onRecurringInvoicesGet({ bookingNo: bookingNo });
  }

  return (
    <Container maxWidth="xl">
      <FcBackdrop open={loading}/>
      <Typography
        sx={{cursor: "pointer", marginBottom: 3}} variant="h4" gutterBottom
        onClick={() => navigate("../")}
      >
        <span style={{position: "relative", top: 3}}>
          <ArrowBackSharpIcon/>
        </span>
        &nbsp;&nbsp;
        Back To Table
      </Typography>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="Details" value="details"/>
            <Tab label="Account" value="account"/>
            <Tab label="Recurring" value="recurring"/>
          </TabList>
        </Box>

        <TabPanel value="details">
          <BookingDetailsTab booking={booking} agreements={agreements} onReload={onReload}/>
        </TabPanel>
        <TabPanel value="account">
          {permissionRequired(authCtx, INVOICE_PERMISSIONS.VIEW) && <BookingAccountTab booking={booking} onReload={onReload}/>}
        </TabPanel>
        <TabPanel value="recurring">
          {permissionRequired(authCtx, INVOICE_PERMISSIONS.VIEW) && <BookingScheduleTab booking={booking} recurringInvoices={recurringInvoices} onReload={onReload}/>}
        </TabPanel>
      </TabContext>
    </Container>
  );
}