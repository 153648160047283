import { LoadingButton } from "@material-ui/lab";
import { Box, Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import useHttpPost from "../hooks/http/useHttpPost";
import useImageGet from "../hooks/http/useImageGet";

export default function SignatureBoard({ objectName, field, handleUploadCanvas }) {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [open, setOpen] = useState(false);

  const { onPost, loading } = useHttpPost("/api/file/upload-canvas");
  const { data, onGet } = useImageGet("/api/file/getFile", false);

  const getCoordinates = (event) => {
    if (event.touches && event.touches.length > 0) {
      const touch = event.touches[0];
      const canvasRect = canvasRef.current.getBoundingClientRect();
      return {
        offsetX: touch.clientX - canvasRect.left,
        offsetY: touch.clientY - canvasRect.top,
      };
    }
    return { offsetX: event.nativeEvent.offsetX, offsetY: event.nativeEvent.offsetY };
  };

  const startDrawing = (event) => {
    const { offsetX, offsetY } = getCoordinates(event);
    const ctx = canvasRef.current.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = (event) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = getCoordinates(event);
    const ctx = canvasRef.current.getContext('2d');
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  const stopDrawing = () => setIsDrawing(false);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const canvas = canvasRef.current;
      const dataUrl = canvas.toDataURL();
      const { objectName } = await onPost({ dataUrl, type: "signature" });

      await onGet({ objectName });
      handleUploadCanvas(field, objectName);

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (objectName) {
      onGet({ objectName });
    }
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
        <DialogTitle>Signature</DialogTitle>
        <DialogContent>
          <Stack direction="row" justifyContent="center">
            <Stack spacing={3}>
              <canvas
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={stopDrawing}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseOut={stopDrawing}
                onBlur={stopDrawing}
                ref={canvasRef}
                width={800}
                height={320}
                style={{ border: '1px solid #637381', borderRadius: 10 }}
              />
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="outlined" onClick={clearCanvas}>
                  Clear
                </Button>
                <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {data ? (
        <Stack direction="row" justifyContent="center" border={1} borderRadius={2}>
          <Box component="img" src={URL.createObjectURL(data)} alt="signature"/>
        </Stack>
      ) : "Please click the button to sign"}
      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
          Sign
        </Button>
      </Stack>
    </>
  );
}

SignatureBoard.propTypes = {
  objectName: PropTypes.string,
  field: PropTypes.string,
  handleUploadCanvas: PropTypes.func,
}