import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Container, Grid, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link } from 'react-router-dom';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import WorkshopAppointmentGrid from '../../../components/Workshop/Appointment/WorkshopAppointmentGrid';

export default function WorkshopAppointmentListing() {
  const today = new Date();
  const [startOfWeek, setStartOfWeek] = useState(new Date(today.toDateString()).getTime() - (today.getDay() - 1) * 86400000);
  const getSearchQuery = () => {
    return {
      lowerBoundTime: startOfWeek,
      upperBoundTime: startOfWeek + 7 * 86400000
    }
  };

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/workshop/appointment`, true, getSearchQuery());

  useEffect(() => {
    onGet(getSearchQuery());
  }, [startOfWeek]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom mb={3}>
        Workshop Appointment Listing
      </Typography>

      <Grid container mb={4}>
        <Grid item>
          <ButtonGroup variant="contained">
            <Button onClick={() => {
              setStartOfWeek(startOfWeek - 7 * 86400000);
            }}>
              Last Week
            </Button>
            <Button onClick={() => {
              setStartOfWeek(startOfWeek + 7 * 86400000);
            }}>
              Next Week
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs />

        <Grid item>
          <Button
            variant="contained" color="secondary"
            startIcon={<Icon icon={plusFill} />}
            component={Link} to="./new"
          >
            Create Appointment
          </Button>
        </Grid>
      </Grid>

      <WorkshopAppointmentGrid
        startOfWeek={startOfWeek}
        data={data.data ?? []}
        onReload={() => onGet(getSearchQuery())}
      />
    </Container>
  );
}