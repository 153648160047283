import {LoadingButton} from "@material-ui/lab";
import {Box, Button, Dialog, DialogActions, DialogTitle, Stack} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import { Link } from "react-router-dom";
import useHttpPut from "../../../hooks/http/useHttpPut";
import DataContext from "../../../store/DataContext";
import {getDayjsValue} from "../../../utils/date";
import FcTable from "../FcTable";

const PublishAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const { onPut, loading } = useHttpPut(`/api/vehicles/${row?.id}/status`);
  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({
      open: true,
      message,
      severity,
    });
  }

  const onClick = async e => {
    e.preventDefault();
    try {
      await onPut({ vehicleStatus: "Available" });
      onOpenSnackbar("Vehicle published successfully!");
      await onReload();
    } catch (error) {
      onOpenSnackbar(e.message, "error");
    }
    setOpen(false);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 2 }}>
          <DialogTitle>Do you want to delete insurance?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpen(false)} type="submit" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading} onClick={onClick}
            >
              Publish
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Publish
      </Button>
    </>
  );
}

PublishAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <Button variant="contained" component={Link} to={`./${row.id}`}>View</Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
}

export default function VehicleTable({ data }) {
  const columns = [
    { field: 'vehicleNumber', headerName: 'Vehicle No.', align: "center", headerAlign: "center", minWidth: 125, sortable: false, sticky: true },
    {
      field: "make", headerName: "Make / Model", align: "center", headerAlign: "center", minWidth: 200, sortable: false, sticky: true,
      renderCell: ({row}) => `${row.model.make.name} ${row.model.name}`
    },
    {
      field: 'model', headerName: 'Fuel Type', align: "center", headerAlign: "center", minWidth: 125, sortable: false,
      renderCell: ({row}) => row.model.petrolType,
    },
    { field: 'vehicleStatus', headerName: 'Vehicle Status', align: "center", headerAlign: "center", minWidth: 150, sortable: false },
    {
      field: 'registerDate', headerName: 'Registration Date', align: "center", headerAlign: "center", minWidth: 150,
      renderCell: ({row}) => getDayjsValue(row.registerDate).format("DD MMM YYYY"),
    },
    {
      field: 'logcard.vehicleOwner.name', headerName: 'Company Name', align: "center", headerAlign: "center", minWidth: 175,
      renderCell: ({row}) => row?.logcard?.vehicleOwner?.name,
    },
    {
      field: 'logcard.coeExpiryDate', headerName: 'COE Expiry Date', align: "center", headerAlign: "center", minWidth: 175,
      renderCell: ({row}) => getDayjsValue(row?.logcard?.coeExpiryDate).format("DD MMM YYYY"),
    },
    {
      field: 'roadTaxDueDate', headerName: 'Road Tax Due Date', align: "center", headerAlign: "center", minWidth: 175,
      renderCell: ({row}) => getDayjsValue(row.roadTaxDueDate).format("DD MMM YYYY"),
    },
    {
      field: 'inspectionDueDate', headerName: 'Inspection Due Date', align: "center", headerAlign: "center", minWidth: 175,
      renderCell: ({row}) => getDayjsValue(row.roadTaxDueDate).format("DD MMM YYYY"),
    },
    {
      field: 'manufacturingYear', headerName: 'Manufacturing Year', align: "center", headerAlign: "center", minWidth: 175,
      renderCell: ({row}) => getDayjsValue(row.manufacturingYear).format("YYYY"),
    },
    { field: 'vehicleColor', headerName: 'Vehicle Color', align: "center", headerAlign: "center", minWidth: 150, sortable: false },
    { field: 'vehicleShape', headerName: 'Vehicle Shape', align: "center", headerAlign: "center", minWidth: 150, sortable: false },
    {
      field: '', headerName: 'Action', align: "center", headerAlign: "center", minWidth: 125, sortable: false,
      renderCell: ({row}) => <ActionCol row={row}/>
    },
  ];

  return (
    <FcTable rows={data ?? []} columns={columns}/>
  );
}

VehicleTable.propTypes = {
  data: PropTypes.array,
}