import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";

const UploadForm = ({ handleUpload }) => {
  const extractData = (file) => {
    const workbook = XLSX.read(file);
    const data = {};
    workbook.SheetNames.forEach((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      data[sheetName] = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF:'dd/mm/yyyy' });
    });
    return data;
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const data = extractData(await file.arrayBuffer());
    data["Customer data"] = data["Customer data"].map((item, index) => ({ ...item, id: index }));
    data["Invoice data"] = data["Invoice data"].map((item, index) => ({ ...item, id: index }));
    handleUpload(data);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ multiple: false, onDrop });

  const style = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center'
  };

  return (
    <Box {...getRootProps({ style: style })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </Box>
  );
}

UploadForm.propTypes = {
  handleUpload: PropTypes.func,
}

const CDWIncentiveTable = ({ data }) => {
  const columns = [
    { field: "customerName", headerName: "Customer Name", align: "center", headerAlign: "center", width: 300 },
    { field: "invoiceNo", headerName: "Invoice No.", align: "center", headerAlign: "center", width: 150 },
    { field: "sales", headerName: "Sales", align: "center", headerAlign: "center", width: 150 },
    { field: "originalAmount", headerName: "Original Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 150 },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 150 },
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.startDate).format("DD MMM YYYY"),
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.endDate).format("DD MMM YYYY"),
    },
  ];

  return (
    <StyledDataGrid columns={columns} rows={data ?? []} autoHeight disableSelectionOnClick />
  );
}

CDWIncentiveTable.propTypes = {
  data: PropTypes.array,
}

const SummaryTable = ({ data }) => {
  const columns = [
    { field: "sales", headerName: "Sales", align: "center", headerAlign: "center", width: 150 },
    { field: "customerName", headerName: "Customer Name", align: "center", headerAlign: "center", width: 250 },
    {
      field: "startDate", headerName: "Start Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.startDate).format("DD MMM YYYY"),
    },
    {
      field: "endDate", headerName: "End Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.endDate).format("DD MMM YYYY"),
    },
    { field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 150 },
    { field: "billAmount", headerName: "Bill Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "duration", headerName: "Duration", align: "center", headerAlign: "center", width: 150 },
    { field: "point", headerName: "Actual Point", align: "center", headerAlign: "center", width: 150 },
  ];

  return (
    <StyledDataGrid columns={columns} rows={data ?? []} autoHeight disableSelectionOnClick />
  );
}

SummaryTable.propTypes = {
  data: PropTypes.array,
}

const buildSummary = (rows) => {
  const customers = {};
  const data = [];
  rows.forEach(invoice => {
    if (!(invoice.customerName in customers)) {
      customers[invoice.customerName] = {
        dates: [],
        invoices: [],
      };
    }

    const { dates, invoices } = customers[invoice.customerName];

    if (dates.length > 0 && dates[dates.length - 1].endDate.format() === invoice.startDate.format()) {
      dates[dates.length - 1].endDate = invoice.endDate;
      dates[dates.length - 1].billAmount += invoice.originalAmount;
      dates[dates.length - 1].outstanding += invoice.outstanding;
    } else {
      dates.push({
        startDate: invoice.startDate,
        endDate: invoice.endDate,
        billAmount: invoice.originalAmount,
        outstanding: invoice.outstanding,
      });
    }
    invoices.push(invoice);
  });

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const customerName in customers) {
    const customer = customers[customerName];

    customer.dates.forEach(date => {
      const duration = dayjs(date.endDate).diff(date.startDate, "month");
      data.push({
        id: data.length,
        customerName: customerName,
        sales: customer.invoices[0].sales,
        startDate: date.startDate,
        endDate: date.endDate,
        billAmount: date.billAmount,
        outstanding: date.outstanding,
        duration: duration,
        point: date.billAmount - duration * 90,
      });
    });
  }

  return data;
}

const CDWIncentive = () => {
  const [rows, setRows] = useState([]);
  const [summary, setSummary] = useState([]);

  const handleUpload = (data) => {
    const customerData = data['Customer data'];
    const invoiceData = data['Invoice data'];

    const customers = customerData.map((data, index) => ({
      id: index,
      name: data.Name,
      identity: data["NRIC number"],
      sales: data.Salesperson,
    }));
    const invoices = invoiceData.map((data, index) => {
      const customer = customers.find(c => c.name === data["Hirer name"]);

      return {
        id: index,
        customerName: data["Hirer name"],
        sales: customer?.sales ?? "",
        invoiceNo: data["Invoice No"],
        originalAmount: Number(data["Original amount"]),
        outstanding: Number(data["Outstanding amount"]),
        status: data.Status,
        startDate: dayjs(data["Start date"]),
        endDate: dayjs(data["End date"]),
      };
    });

    invoices.sort((a, b) => a.startDate - b.startDate);

    setSummary(buildSummary(invoices));
    setRows(invoices);
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">CDW Incentive</Typography>
      </Stack>
      <Box mb={3}>
        <UploadForm handleUpload={handleUpload} />
      </Box>
      <Box mb={3}>
        <CDWIncentiveTable data={rows} />
      </Box>
      <Box mb={3}>
        <Typography variant="h4">CDW Incentive Summary</Typography>
      </Box>
      <Box mb={3}>
        <SummaryTable data={summary} />
      </Box>
    </>
  );
}

export default CDWIncentive;