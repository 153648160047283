import { Form, FormikProvider, useFormik } from "formik";
import PropTypes from "prop-types";
import {useContext, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@material-ui/lab";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import DataContext from "../../../store/DataContext";

export default function VehicleMakeForm({ makeData, onReload, onClose }) {
  const method = makeData ? "Update" : "Create";

  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/vehicles/make");
  const { onPut } = useHttpPut(`/api/vehicles/make/${makeData?.id}`);

  const onCreate = async (values) => {
    await onPost(values);
    await onReload();
    dataCtx.setSnackbarConfig({ open: true, message: "Created success", severity: "success" });
  }

  const onUpdate = async (values) => {
    await onPut({ ...values, id: makeData.id });
    await onReload();
    dataCtx.setSnackbarConfig({ open: true, message: "Updated success", severity: "success" });
  }

  const formik = useFormik({
    initialValues: {
      name: makeData?.name ?? "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please fill in the name of make."),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onCreate(values);
        else await onUpdate(values);
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (makeData) {
      formik.setFieldValue("name", makeData.name);
    }
  }, [makeData]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack spacing={3}>
          <TextField
            name="name"
            label="Vehicle Make"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
              {method}
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

VehicleMakeForm.propTypes = {
  makeData: PropTypes.object,
  onClose: PropTypes.func,
}