import { Container, Stack, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { fieldToTextField } from 'formik-mui';
import plusFill from '@iconify/icons-eva/plus-fill';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Snackbar,
  TextField
} from '@mui/material';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@material-ui/lab';
import dayjs from 'dayjs';

import AuthContext from '../../store/AuthContext';
import DataContext from '../../store/DataContext';
import { PopupModal } from '../../components/PopupModal';
import { RefundVoucherViewer } from '../../components/RefundVoucherViewer';
import fetchApi from '../../utils/fetchApi';
import RefundVoucherFilterForm from '../../components/Form/RefundVoucher/RefundVoucherFilterForm';
import FcBackdrop from '../../components/FcBackdrop';
import { getDayjsValue } from '../../utils/date';

// Global variable

let isMounted = true;
let masterHirerList = [];
let tempBookingIds = [];

const tempApiStr = process.env.REACT_APP_FIREBASE_RRBE_API;
export default function RefundVouchers() {
  const authCtx = useContext(AuthContext);
  const dataCtx = useContext(DataContext);
  const [bookingsAreLoading, setBookingsAreLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dataGridPageSize, setDataGridPageSize] = useState(100);
  const [setToRefundVoucher, setSetToRefundVoucher] = useState(true);
  const [refundMode, setRefundMode] = useState(true);
  const [refundVoucherDataForPrint, setRefundVoucherDataForPrint] = useState();
  const [depositList, setDepositList] = useState([]);

  const [msgListRows, setMsgListRows] = React.useState([]);

  const fetchRefundVouchers = useCallback(
    async ({
      startDate = dayjs().startOf('year'),
      endDate = dayjs().startOf('year').add(1, 'year'),
      bookingNo
    }) => {
      idToken.current = await authCtx.authObj.currentUser.getIdToken();
      try {
        const url = `${tempApiStr}?q=GetRefundVouchers`;
        // const url = 'http://192.168.0.187/rr_api/rrbe-get-refund-vouchers.php';
        const response = await fetch(url, {
          headers: {
            accept: 'application/json',
            token: idToken.current
          },
          body: JSON.stringify({
            startdate: startDate?.format('DD/MM/YYYY') ?? '',
            enddate: endDate?.format('DD/MM/YYYY') ?? '',
            bookno: bookingNo ?? '',
            hirer: '',
            status: '',
            refundno: '',
            active: '0',
            displaylength: 10000
          }),
          method: 'POST',
          mode: 'cors',
          credentials: 'omit'
        });
        const data = await response.json();

        if (Object.keys(data).includes('error')) {
          throw data;
        }
        if (!Object.keys(data).includes('d')) {
          throw Error('Shape of data has changed.');
        }
        if (isMounted)
          setMsgListRows(
            JSON.parse(data.d).aaData.map((element) => ({
              id: element.RefundNo,
              bookingNo: element.BookNo,
              // refundVoucherDate: new Date(
              //   parseInt(element.RefundDatets.slice(6, -2), 10) - 15 * 60 * 60 * 1000
              // ),
              refundVoucherDate: getDayjsValue(parseInt(element.RefundDatets.slice(6, -2), 10)),
              refundVoucherNo: element.RefundNo,
              paymentMode: element.PaymentMode,
              hirer: element.HirerName,
              amount: element.PayAmount,
              paidBy: element.CollectedBy,
              status: element.Status
            }))
          );
      } catch (e) {
        console.log(e.error);
        window.alert(e);
      }
    },
    [authCtx.authObj.currentUser]
  );

  // Message Details modal logic
  const [open, setOpen] = useState(false);
  const handleOpen = (isNewRefundVoucher, x) => {
    setOpen(true);
    setRefundMode(x);
    setSetToRefundVoucher(isNewRefundVoucher);
  };
  const handleClose = () => {
    if (setToRefundVoucher) {
      if (window.confirm('Close without saving?')) {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
    setFields((prev) => {
      const x = prev;
      x[1].list = [];
      x[2].list = [];
      x[3].list = [];
      return x;
    });
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Options',
      width: 200,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpen(false);
              setRefundVoucherDataForPrint(
                msgListRows.find((element) => element.id === params.value)
              );
            }}
            size="small"
            style={{ marginLeft: 16 }}
          >
            Open
          </Button>
          <Button
            disabled={
              msgListRows.find((element) => element.id === params.value).status === 'VOIDED'
            }
            variant="contained"
            color="error"
            onClick={() => voidRefundVoucher(params.value)}
            size="small"
            style={{ marginLeft: 16 }}
          >
            Void
          </Button>
        </strong>
      ),
      type: 'string',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'bookingNo',
      headerName: 'Booking No.',
      width: 150,
      type: 'string',
      sortable: true,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'refundVoucherDate',
      headerName: 'Refund Date',
      width: 150,
      type: 'date',
      sortable: true,
      valueFormatter: (params) => dayjs(params.value).format('D MMM YYYY'),
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'refundVoucherNo',
      headerName: 'Refund No.',
      width: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'paymentMode',
      headerName: 'Payment Method',
      width: 150,
      type: 'string',
      editable: false,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'hirer',
      headerName: 'Hirer',
      width: 200,
      type: 'string',
      editable: false,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      type: 'number',
      editable: false,
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (params) => `S$${params.value}`
    },
    {
      field: 'paidBy',
      headerName: 'Paid By',
      width: 180,
      type: 'string',
      editable: false,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110,
      type: 'string',
      editable: false,
      align: 'center',
      headerAlign: 'center'
    }
  ];

  // Get screen height to re-size message list table

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  // New refund voucher fields validation

  const validationSchema = Yup.object().shape({
    hirer: Yup.string().required("Please enter the hirer's name.").nullable(),
    depositInvoice: refundMode
      ? Yup.string().required('Please select a deposit invoice.').nullable()
      : Yup.string().notRequired().nullable(),
    rentalAgreement: refundMode
      ? Yup.string().notRequired().nullable()
      : Yup.string().required('Please select a rental agreement.').nullable(),
    paymentMode: Yup.string().required('Please choose a payment mode.').nullable(),
    amount: Yup.number()
      .transform((currentValue) => Number(currentValue))
      .min(0, 'Amount must not be negative.')
      .required('Please enter an amount.')
      .nullable(),
    bookingNo: Yup.string().required('Please select the booking number.').nullable(),
    remarks: Yup.string().nullable()
  });

  const initialValues = {
    hirer: null,
    depositInvoice: null,
    rentalAgreement: '',
    paymentMode: null,
    amount: '',
    bookingNo: null,
    remarks: ''
  };

  const FormikAutocomplete = ({ textFieldProps, ...props }) => {
    const {
      form: { setTouched, setFieldValue, resetForm, values }
    } = props;
    const { error, helperText, ...field } = fieldToTextField(props);
    const { name } = field;

    if (name === 'depositInvoice' && !refundMode) return null;
    if (name === 'rentalAgreement' && refundMode) return null;

    FormikAutocomplete.propTypes = {
      form: PropTypes.object,
      textFieldProps: PropTypes.object
    };

    return name === 'amount' || name === 'remarks' ? (
      <TextField
        {...(name === 'amount'
          ? {
              ...fieldToTextField(props),
              InputLabelProps: {
                ...fieldToTextField(props).InputLabelProps,
                shrink: values.amount !== null
              }
            }
          : fieldToTextField(props))}
        {...textFieldProps}
        // InputLabelProps={name === 'amount' ? { shrink: refundMode && values.amount } : null}
        disabled={name === 'amount' && refundMode}
        multiline={name === 'amount' ? undefined : true}
        rows={name !== 'amount' ? 3 : undefined}
        type={name === 'amount' ? 'number' : 'text'}
        helperText={helperText}
        error={error}
      />
    ) : (
      <Autocomplete
        {...props}
        {...field}
        autoComplete
        autoHighlight
        autoSelect
        disabled={bookingsAreLoading}
        disableClearable
        onChange={async (_, value) => {
          if (name === 'hirer') {
            setBookingsAreLoading(true);
            tempBookingIds = masterHirerList
              .filter((element) => element.Name === value)
              .map((element) => element.id);
            const bookingArray = [];
            await asyncForEach(tempBookingIds, async (element) => {
              /* eslint-disable no-await-in-loop */
              const x = await fetchBookings(element);
              bookingArray.push(...x);
            });

            setFields((prev) => {
              const x = prev;
              const bookingListSet = new Set(bookingArray);
              x[1].list = Array.from(bookingListSet);
              return x;
            });
            resetForm(initialValues);
            setDepositList([]);
            setFields((prev) => {
              const x = prev;
              x[2].list = [];
              return x;
            });
            setBookingsAreLoading(false);
          }

          if (name === 'bookingNo') {
            setBookingsAreLoading(true);
            if (refundMode) {
              const depositData = await fetchApi([
                {
                  method: 'GetDepositListing',
                  requestBody: {
                    startdate: '',
                    enddate: '',
                    bookno: value,
                    hirer: '',
                    status: '',
                    invoiceno: '',
                    refunded: '',
                    active: '1',
                    displaylength: 100
                  }
                }
              ]);
              setDepositList(depositData[0].aaData);
              setFields((prev) => {
                const x = prev;
                x[2].list = depositData[0].aaData.map((el) => el.invoicecode);
                return x;
              });
              setFieldValue('depositInvoice', null);
            } else {
              const rentalAgreementListing = await fetchApi([
                {
                  method: 'GetRentalAgreementListing',
                  requestBody: {
                    fromDateStart: '',
                    fromDateEnd: '',
                    toDateStart: '',
                    toDateEnd: '',
                    bookno: value,
                    hirer: '',
                    rentalagreementno: '',
                    carplate: '',
                    company: '',
                    active: 1,
                    displaylength: 200
                  }
                }
              ]);
              setFields((prev) => {
                const x = prev;
                x[3].list = rentalAgreementListing[0].aaData.map((el) => el.RentalAgreementNo);
                return x;
              });
              setFieldValue('rentalAgreement', null);
            }

            setFieldValue('paymentMode', null);
            setFieldValue('amount', '');
            setFieldValue('remarks', '');
            setBookingsAreLoading(false);
          }

          if (name === 'depositInvoice' && refundMode) {
            setFieldValue(
              'amount',
              depositList.find((el) => el.invoicecode === value).depositamount
            );
          }

          setFieldValue(name, value);
        }}
        onBlur={() => {
          setTouched({ [name]: true });
        }}
        getOptionLabel={(option) => option}
        renderInput={(props2) => (
          <TextField
            {...props2}
            {...textFieldProps}
            helperText={helperText}
            InputProps={
              bookingsAreLoading && (name === 'hirer' || name === 'bookingNo')
                ? {
                    ...props2.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress />
                      </InputAdornment>
                    )
                  }
                : {
                    ...props2.InputProps
                  }
            }
            error={error}
          />
        )}
      />
    );
  };

  const [fields, setFields] = useState([
    { fieldName: 'Hirer', freeSolo: false, list: [] },
    {
      fieldName: 'Booking No',
      freeSolo: false,
      list: []
    },
    {
      fieldName: 'Deposit Invoice',
      freeSolo: false,
      list: []
    },
    {
      fieldName: 'Rental Agreement',
      freeSolo: false,
      list: []
    },
    {
      fieldName: 'Payment Mode',
      freeSolo: false,
      list: dataCtx.paymentTypes
    },
    {
      fieldName: 'Amount',
      freeSolo: true,
      list: []
    },
    {
      fieldName: 'Remarks',
      freeSolo: true,
      list: []
    }
  ]);

  const camelize = (str) =>
    str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, '');

  const ModalContent = () => (
    <>
      {setToRefundVoucher ? (
        <>
          <Typography variant="h4" gutterBottom>
            New Refund Voucher{refundMode ? ' (Deposit)' : ' (Others)'}
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              try {
                let raNo = '';

                if (refundMode) {
                  // Fetch the rental agreement number that is linked to this deposit invoice. Need to include this RA no. in the POST request in order for the deposit amount to reflect as refunded in the deposit listing.
                  raNo = (
                    await fetchApi([
                      {
                        method: 'GetInvoiceListing',
                        requestBody: {
                          BookNo: '',
                          StartDate: '',
                          EndDate: '',
                          InvoiceNo: values.depositInvoice,
                          Company: '',
                          Hirer: '',
                          CarPlate: '',
                          Status: '',
                          displaylength: 100
                        }
                      }
                    ])
                  )[0].aaData[0].rentalagreementno;
                } else {
                  raNo = values.rentalAgreement;
                }

                const url = `${tempApiStr}?q=SetRefundVoucher`;
                const refundvoucherxml = `<refundvoucherlist><item><issignatureaccount>1</issignatureaccount><issignaturesales>0</issignaturesales><issignaturehirer>0</issignaturehirer><bookno>${
                  values.bookingNo
                }</bookno><rentalagreementno>${raNo ?? ''}</rentalagreementno><paymenttype>${
                  values.paymentMode
                }</paymenttype><refundvoucherno></refundvoucherno><invoiceno>${
                  values.depositInvoice
                }</invoiceno><invoicedate></invoicedate><paymentmadeby>${
                  authCtx.authObj.currentUser.displayName
                }</paymentmadeby><paymentamount>${
                  values.amount
                }</paymentamount><osamount>0</osamount><depositamount>${
                  refundMode ? values.amount : 0
                }</depositamount><remarks>${values.remarks}</remarks></item></refundvoucherlist>`;
                const createRefundVoucher = await fetch(url, {
                  headers: {
                    accept: 'application/json',
                    token: idToken.current
                  },
                  body: JSON.stringify({
                    refundvoucherxml,
                    signrefundvoucheraccount: '',
                    signrefundvouchersales: '',
                    signrefundvoucherhirer: ''
                  }),
                  method: 'POST',
                  mode: 'cors',
                  credentials: 'omit'
                });
                const response = await createRefundVoucher.json();

                if (!('d' in response)) {
                  throw Error('Submission request failed');
                }

                console.log(response);
                console.log(values);
                setFields((prev) => {
                  const x = prev;
                  x[1].list = [];
                  x[2].list = [];
                  x[3].list = [];
                  return x;
                });
                resetForm();
                setDepositList([]);
                setSubmitting(false);
                setOpen(false);
                handleSnackbar('Your refund voucher has been saved!', 'success');
                setOpenBackdrop(true);
                await fetchRefundVouchers({});
                setOpenBackdrop(false);
                setOpenSnackbar(true);
              } catch (e) {
                console.error(e);
                window.alert(e);
              }
            }}
          >
            {({ resetForm, isSubmitting }) => (
              <Form>
                <fieldset disabled={bookingsAreLoading} style={{ border: '0px' }}>
                  <Stack direction={{ xs: 'column' }} spacing={1}>
                    {fields.map((element) => (
                      <Field
                        key={element.fieldName}
                        name={camelize(element.fieldName)}
                        component={FormikAutocomplete}
                        options={element.list}
                        textFieldProps={{
                          fullWidth: true,
                          label: element.fieldName,
                          variant: 'outlined'
                        }}
                      />
                    ))}
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                    <Button
                      variant="contained"
                      onClick={() =>
                        resetForm({
                          values: initialValues
                        })
                      }
                    >
                      Reset
                    </Button>
                  </Stack>
                </fieldset>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <RefundVoucherViewer data={refundVoucherDataForPrint} />
          <br />
        </>
      )}
    </>
  );

  // Date fetching for form fields

  const idToken = useRef();

  const fetchLists = useCallback(async () => {
    idToken.current = await authCtx.authObj.currentUser.getIdToken();
    try {
      const url = `${tempApiStr}?q=GetHirerListing`;
      const responseHirerList = await fetch(url, {
        headers: {
          accept: 'application/json',
          token: idToken.current
        },
        body: JSON.stringify({ name: '', count: 20, columnname: 'name' }),
        method: 'POST',
        mode: 'cors',
        credentials: 'omit'
      });
      const dataHirerList = await responseHirerList.json();
      if (Object.keys(dataHirerList).includes('error')) {
        throw dataHirerList;
      }
      if (!Object.keys(dataHirerList).includes('d')) {
        throw Error('Shape of data has changed.');
      }

      masterHirerList = JSON.parse(dataHirerList.d);

      if (isMounted)
        setFields((prev) => {
          const x = prev;
          const hirerListSet = new Set();
          JSON.parse(dataHirerList.d).forEach((element) => hirerListSet.add(element.Name));
          x[0].list = Array.from(hirerListSet);
          return x;
        });
    } catch (e) {
      console.log(e.error);
      window.alert(e);
    }
  }, [authCtx.authObj.currentUser]);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    isMounted = true;
    if (isMounted) setOpenBackdrop(true);
    Promise.all([fetchRefundVouchers({}), fetchLists()]).then((resp) => {
      if (isMounted) setOpenBackdrop(false);
    });

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      isMounted = false;
      window.removeEventListener('resize', handleResize);
    };
  }, [fetchLists, fetchRefundVouchers]);

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index += 1) {
      await callback(array[index], index, array);
    }
  }

  const voidRefundVoucher = async (refundno) => {
    if (window.confirm(`Are you sure you want to void refund voucher ${refundno}?`)) {
      setOpenBackdrop(true);
      idToken.current = await authCtx.authObj.currentUser.getIdToken();
      try {
        const response = await fetch(`${tempApiStr}?q=VoidRefundVoucher`, {
          headers: {
            accept: 'application/json',
            token: idToken.current
          },
          body: JSON.stringify({ refundno }),
          method: 'POST',
          mode: 'cors',
          credentials: 'omit'
        });
        const data = await response.json();
        if (Object.keys(data).includes('error')) {
          throw data;
        }
        if (!Object.keys(data).includes('d')) {
          throw Error('Shape of data has changed.');
        }

        setMsgListRows((prev) =>
          prev.map((element) => {
            if (element.id === refundno) {
              return { ...element, status: 'VOIDED' };
            }
            return element;
          })
        );
        handleSnackbar(`Refund voucher ${refundno} has been voided successfully!`, 'success');
      } catch (e) {
        console.log(e.error);
        window.alert(e);
      }
    }
    setOpenBackdrop(false);
  };

  const fetchBookings = async (hirerid) => {
    const idToken = await authCtx.authObj.currentUser.getIdToken();
    const url = `${tempApiStr}?q=GetHirerBookingNoListing`;
    // const url = 'https://orangeexpress.sg/rr_api/rrbe-get-bookings.php';
    const responseBookingsList = await fetch(url, {
      headers: {
        accept: 'application/json',
        token: idToken
      },
      body: JSON.stringify({ hirerid }),
      method: 'POST',
      mode: 'cors',
      credentials: 'omit'
    });
    const dataBookingsList = await responseBookingsList.json();
    if (Object.keys(dataBookingsList).includes('error')) {
      throw dataBookingsList;
    }
    if (!Object.keys(dataBookingsList).includes('d')) {
      throw Error('Shape of data has changed.');
    }

    return JSON.parse(dataBookingsList.d).map((element) => element.BookNo);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });

  const handleSnackbar = (message, type) => {
    setSnackbarInfo({ message, type });
    setOpenSnackbar(true);
  };

  const Filters = ({ onReload }) => {
    return (
      <Box mb={3}>
        <RefundVoucherFilterForm onReload={onReload} />
      </Box>
    );
  };

  return (
    <Container maxWidth="xl">
      {/* <Button onClick={() => setOpenSnackbar((prev) => !prev)}>clickhere</Button> */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarInfo.type}
          sx={{ width: '100%' }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
      <FcBackdrop open={openBackdrop} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>
          Refund Vouchers
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => {
              handleOpen(true, false);
            }}
          >
            Refund Others
          </Button>
          <Button
            variant="contained"
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => {
              handleOpen(true, true);
            }}
          >
            Refund Deposit
          </Button>
        </Stack>
      </Stack>

      <PopupModal openState={open} closeHandler={handleClose} code={ModalContent} />
      <Filters onReload={fetchRefundVouchers} />
      <DataGrid
        rows={msgListRows ?? []}
        columns={columns}
        pageSize={dataGridPageSize}
        onPageSizeChange={(newPageSize) => setDataGridPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'refundVoucherDate', sort: 'desc' }]
          }
        }}
        disableSelectionOnClick
        autoHeight
      />
    </Container>
  );
}
