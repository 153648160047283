import {getAuth} from "firebase/auth";
import { useState } from "react";
import { apiFetch } from "../../services/api";

/*
 * data: object
 * loading: boolean
 * error: string
 * onPost:
 * - body: object
 */
export default function useFilePost(path) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onPost = async (body) => {
    setLoading(true);
    try {
      const token = await getAuth().currentUser.getIdToken();
      const res = await apiFetch(path, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body
      });
      setData(res);
    } catch (err) {
      setError(err.message);
      throw err;
    }
    setLoading(false);
  }

  return { data, loading, error, onPost };
}