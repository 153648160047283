import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { StyledDataGrid } from "../../../pages/Vehicle/styles";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { getDayjsValue } from "../../../utils/date";

const InvoiceItemTable = ({ invoice }) => {
  const columns = [
    {
      field: "type", headerName: "Type", align: "center", headerAlign: "center", width: 100,
      renderCell: ({ row }) => capitalizeFirstLetter(row.type),
    },
    {
      field: "description", headerName: "Description", align: "left", headerAlign: "center", width: 500,
      renderCell: ({ row }) => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>
          {row.description}
        </Box>
      ),
    },
    { field: "billAmount", headerName: "Bill Amount", align: "center", headerAlign: "center", width: 150 },
    { field: "amountPaid", headerName: "Amount Paid", align: "center", headerAlign: "center", width: 150 },
  ];

  return (
    <StyledDataGrid columns={columns} rows={invoice?.items ?? []} autoHeight/>
  );
}

InvoiceItemTable.propTypes = {
  invoice: PropTypes.object,
}

export default function InvoiceDetails({ invoice }) {
  const invoiceDetails = [
    { label: "Booking No.", value: invoice?.bookingNo },
    { label: "Agreement No.", value: invoice?.agreementNo },
    { label: "Issue Date", value: getDayjsValue(invoice?.issueDate).format("DD MMM YYYY") },
    { label: "Due Date", value: getDayjsValue(invoice?.issueDate).add(invoice?.terms, "day").format("DD MMM YYYY") },
    { label: "Terms", value: invoice?.terms },
    { label: "Customer Name", value: invoice?.customerType === "Company" ? invoice?.companyName : invoice?.customerName },
  ];

  let paymentDetails = [
    { label: "Company", value: invoice?.vehicleCompanyName },
    { label: "Company UEN", value: invoice?.vehicleCompanyUen },
    { label: "Total Amount", value: `S$${invoice?.totalAmount}` },
    { label: "Apply GST", value: invoice.applyGst ? "Yes" : "No" },
  ];

  if (invoice?.gstRate > 0) paymentDetails = paymentDetails.concat([
    { label: "GST Rate", value: `${invoice?.gstRate}%` },
    { label: "GST Amount", value: `S$${invoice?.gstAmount}` },
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Title</Typography>
        {invoice?.title}
      </Grid>
      {invoiceDetails.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12} sm={9}>
        <Typography variant="h6">Customer Address</Typography>
        {invoice?.customerAddress}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="h6">Customer Postal</Typography>
        {invoice?.customerPostal}
      </Grid>
      {paymentDetails.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      {invoice?.remarks && <Grid item xs={12}>
        <Typography variant="h6">Remarks</Typography>
        {invoice?.remarks}
      </Grid>}
      <Grid item xs={12}>
        <Typography variant="h6" mb={3}>Invoice Items</Typography>
        <InvoiceItemTable invoice={invoice}/>
      </Grid>
    </Grid>
  );
}

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
}