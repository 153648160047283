import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import { FcTextField, SubmitBtnGroup } from '../../FormFields';
import DataContext from '../../../../store/DataContext';
import useCloudFuncPut from '../../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { cloudFunctionPath } from '../../../../cloudFunctionApiConfig';

export default function ProposeOTRForm({ onClose, onReload, row }) {
  const dataCtx = useContext(DataContext);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/propose`);
  const onPropose = async (values) => {
    try {
      const data = {
        ...values,
        otrRecordId: row.docId,
        rentalAgreementNo: row.rentalAgreementNo
      };
      await onPut(data);
      await onReload();
      onOpenSnackbar('OTR proposed successfully!');
      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      remarks: '',
      points: ''
    },
    validationSchema: Yup.object({
      remarks: Yup.string().required('Please fill in Remarks!'),
      points: Yup.number().required('Please fill in Proposed OTR!')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onPropose(values);
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Box py={1}>
          <FcTextField type="number" formik={formik} name="points" label="Proposed OTR" />
        </Box>
        <Box py={1}>
          <FcTextField formik={formik} name="remarks" label="Remarks" />
        </Box>
        <Box py={1}>
          <SubmitBtnGroup formik={formik} method="Propose" onCancel={onClose} />
        </Box>
      </Form>
    </FormikProvider>
  );
}

ProposeOTRForm.propTypes = {
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  row: PropTypes.object
};
