import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import {defaultTimezone} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";
import InvoiceDetails from "../../Details/Invoice/InvoiceDetails";
import FcTable from "../FcTable";

dayjs.extend(utc);
dayjs.extend(timezone);

const ViewAction = ({ row }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const { onPost, loading } = useHttpPost("/api/email");

  const onPrint = e => {
    e.preventDefault();
    window.open(getFile(row?.attachment), "_blank");
  }

  const onEmail = async e => {
    e.preventDefault();

    try {
      await onPost({
        subject: `${row.invoiceNo} Invoice`,
        body: `This is your ${row.invoiceNo} Invoice, please pay the invoice.`,
        emailFrom: {
          email: "noreply@freshcars.sg",
          name: "Fresh Cars Pte Ltd",
        },
        emailToList: [ { email: row.customerEmail, name: row.customerName } ],
        attachments: [ { filename: `${row.invoiceNo}.pdf`, path: row.attachment } ],
        isBodyHtml: false,
      });
      dataCtx.setSnackbarConfig({ open: true, message: "Email sent successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row.invoiceNo} Details</DialogTitle>
        <DialogContent>
          <InvoiceDetails invoice={row}/>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={onPrint}>Print</Button>
          <LoadingButton loading={loading} variant="contained" onClick={onEmail}>Email</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button fullWidth variant="contained" onClick={() => setOpen(true)}>View</Button>
    </>
  );
}

ViewAction.propTypes = {
  row: PropTypes.object,
}

const ActionCol = ({ row }) => {
  return (
    <Stack direction="row" spacing={1} justifyContent="center">
      <ViewAction row={row}/>
      <Button fullWidth component={Link} to={getFile(row?.attachment)} target="_blank" variant="contained">Print</Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
}

export default function BookingInvoiceTable({ data }) {
  const columns = [
    { field: "title", headerName: "Title", align: "center", headerAlign: "center" },
    { field: "invoiceNo", headerName: "Invoice No.", align: "center", headerAlign: "center", maxWidth: 150 },
    { field: "agreementNo", headerName: "AgreementNo", align: "center", headerAlign: "center", maxWidth: 150 },
    {
      field: "issueDate", headerName: "Issue Date", align: "center", headerAlign: "center", maxWidth: 150,
      renderCell: ({ row }) => dayjs(row.issueDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY")
    },
    { field: "terms", headerName: "Terms", align: "center", headerAlign: "center", maxWidth: 100 },
    {
      field: "totalAmount", headerName: "Total Amount", align: "center", headerAlign: "center", maxWidth: 150,
      renderCell: ({ row }) => `S$${row.totalAmount}`
    },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", maxWidth: 150,
      renderCell: ({ row }) => <ActionCol row={row}/>
    },
  ];

  return (
    <FcTable columns={columns} rows={data ?? []}/>
  );
}

BookingInvoiceTable.propTypes = {
  data: PropTypes.array,
}