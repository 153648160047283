import plusFill from "@iconify/icons-eva/plus-fill";
import {Icon} from "@iconify/react";
import {Box, Button, Modal, Stack, Typography} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import React, {useState} from "react";
import PropTypes from "prop-types";
import useHttpGet from "../../../hooks/http/useHttpGet";
import VehicleOwnerForm from "../../../components/Form/Vehicle/VehicleOwnerForm";
import useHttpDelete from "../../../hooks/http/useHttpDelete";

const AddAction = ({onGet}) => {
  const [open, setOpen] = useState(false);

  const style = {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  }

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Stack spacing={3}>
            <Typography variant="h4">Add New Company</Typography>
            <VehicleOwnerForm
              onClose={() => setOpen(false)}
              onGet={onGet}
            />
          </Stack>
        </Box>
      </Modal>
      <Button variant="contained" onClick={() => setOpen(true)} startIcon={<Icon icon={plusFill} />}>
        Add Company
      </Button>
    </>
  );
}

AddAction.propTypes = {
  onGet: PropTypes.func
}

const ActionCol = ({row, onGet}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { onDelete: onHttpDelete } = useHttpDelete(`/api/vehicles/owner/${row.id}`);

  const style = {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  }

  const onDelete = async e => {
    e.preventDefault();
    try {
      await onHttpDelete();
      await onGet();
      setOpenDeleteModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Stack direction="row" spacing={2}>
      <Modal open={openEditModal}>
        <Box sx={style}>
          <Stack spacing={3}>
            <Typography variant="h4">Edit Company</Typography>
            {row && <VehicleOwnerForm
              onClose={() => setOpenEditModal(false)}
              onGet={onGet}
              data={row}
            />}
          </Stack>
        </Box>
      </Modal>
      <Modal open={openDeleteModal}>
        <Box sx={style}>
          <Stack spacing={3}>
            <Typography variant="h4">Do you want to delete {row.name}?</Typography>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="contained"
                onClick={() => setOpenDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={onDelete}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Button
        variant="contained"
        onClick={() => setOpenEditModal(true)}
      >
        Edit
      </Button>
      <Button
        variant="contained" color="error"
        onClick={() => setOpenDeleteModal(true)}
      >
        Delete
      </Button>
    </Stack>
  )
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onGet: PropTypes.func,
}

const DataTable = ({ rows, onGet }) => {
  const [pageSize, setPageSize] = useState(100);
  const pageOptions = [100];

  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => 1 + params.api.getRowIndexRelativeToVisibleRows(params.row.id)
    },
    {
      field: "name", headerName: "Company Name",
      align: "center", headerAlign: "center",
      width: 150, sortable: false
    },
    {
      field: "registerNo", headerName: "Register No.",
      align: "center", headerAlign: "center",
      width: 150, sortable: false
    },
    {
      field: "uenNo", headerName: "UEN No.",
      align: "center", headerAlign: "center",
      width: 150, sortable: false
    },
    {
      field: "bankName", headerName: "Bank Name",
      align: "center", headerAlign: "center",
      width: 150, sortable: false
    },
    {
      field: "bankAccountNo", headerName: "Bank Account No.",
      align: "center", headerAlign: "center",
      width: 200, sortable: false
    },
    {
      field: "", headerName: "Action",
      align: "center", headerAlign: "center",
      width: 250, sortable: false,
      renderCell: ({row}) => <ActionCol row={row} onGet={onGet}/>
    },
  ];

  return (
    <DataGrid
      components={{ Toolbar: GridToolbar }}
      rows={rows} columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={pageOptions}
      onPageSizeChange={setPageSize}
      disableSelectionOnClick
      autoHeight
    />
  );
}

DataTable.propTypes = {
  rows: PropTypes.array,
  onGet: PropTypes.func,
}

export default function VehicleOwnerList() {
  const { data, onGet } = useHttpGet("/api/vehicles/owner");

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Companies Listing</Typography>
        <AddAction onGet={onGet}/>
      </Stack>
      <DataTable rows={data ?? []} onGet={onGet}/>
    </Stack>
  );
}