import {Grid} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import {useContext, useEffect} from "react";
import * as Yup from "yup";
import useCloudFuncPost from "../../../hooks/firebase/cloudFunction/useCloudFuncPost";
import useCloudFuncPut from "../../../hooks/firebase/cloudFunction/useCloudFuncPut";
import DataContext from "../../../store/DataContext";
import {FcDateField, FcTextField, SubmitBtnGroup} from "../FormFields";
import { cloudFunctionPath } from "../../../cloudFunctionApiConfig";

export default function PublicHolidayForm({ data, onReload, onClose }) {
  const method = data ? "Update" : "Create";
  const dataCtx = useContext(DataContext);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/holiday/create`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/holiday/edit`);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onCreate = async values => {
    try {
      await onPost(values);
      await onReload();
      onClose();
      onOpenSnackbar("Public Holiday created successfully!");
    } catch (error) {
      onOpenSnackbar(error.message, "error");
    }
  }

  const onUpdate = async values => {
    try {
      await onPut({ ...values, holidayId: data?.id });
      await onReload();
      onOpenSnackbar("Public Holiday updated successfully!");
      onClose();
    } catch (error) {
      onOpenSnackbar(error.message, "error");
    }
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      startDate: null,
      endDate: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please insert the title!"),
      startDate: Yup.date().required("Please select the start date!"),
      endDate: Yup.date().required("Please select the end date!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (method === "Create") await onCreate(values);
      else await onUpdate(values);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("title", data?.title);
      formik.setFieldValue("startDate", data?.startDate);
      formik.setFieldValue("endDate", data?.endDate);
    }
  }, []);

  useEffect(() => {
    if (formik.values.startDate) {
      const date = moment(formik.values.startDate);
      if (date.isValid()) {
        formik.setFieldValue("startDate", date.endOf("day").format());
        if (formik.values.endDate === null) {
          formik.setFieldValue("endDate", date.endOf("day").format());
        }
      }
    }
    if (formik.values.endDate) {
      const date = moment(formik.values.endDate);
      if (date.isValid()) {
        formik.setFieldValue("endDate", date.endOf("day").format());
      }
    }
  }, [formik.values.startDate, formik.values.endDate]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcTextField formik={formik} label="Title" name="title"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FcDateField formik={formik} label="Start Date" name="startDate"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FcDateField formik={formik} label="End Date" name="endDate"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method={method} onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

PublicHolidayForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}