import {Typography} from "@mui/material";
import PropTypes from "prop-types";

export default function PriceIncreasePoints({ user, date }) {
  return (
    <>
      price increase points
    </>
  );
}

PriceIncreasePoints.propTypes = {
  user: PropTypes.object,
  date: PropTypes.object,
}