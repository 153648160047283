import * as Yup from 'yup';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { addDoc, collection, doc, getDoc, Timestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';

import AuthContext from '../../store/AuthContext';
import FirestoreContext from '../../store/FirestoreContext';
import errorHandler from '../../utils/errorHandler';
import useCloudFuncPost from '../../hooks/firebase/cloudFunction/useCloudFuncPost';
import { cloudFunctionPath } from '../../cloudFunctionApiConfig';
import { FcAutocomplete } from '../../components/Form/FormFields';

const FcAppNewNotification = () => {
  // Send messages firebase and App users
  const fsCtx = useContext(FirestoreContext);
  const authCtx = useContext(AuthContext);
  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/fcapp/notification`);

  const sendMessageHandler = async (submission) => {
    // Upload the notification image first. If notification image upload fails, the whole notification submission should fail to prevent triggering of the cloud function push notification

    let imageUrl;

    if (uploadImg !== undefined) {
      const storage = getStorage();
      const imgFileName = `notification${new Date().getTime().toString()}`;
      await uploadString(ref(storage, `notifications/${imgFileName}`), uploadImg, 'data_url');
      imageUrl = await getDownloadURL(ref(storage, `notifications/${imgFileName}`));
      submission.imageUrl = imageUrl;
    }

    // Create notification document in fcadmin/fcadmin/notifications
    // await addDoc(collection(fsCtx.fsObject, 'fcadmin/fcadmin/notifications'), {
    //   ...submission,
    //   imageUrl: uploadImg === undefined ? '' : imageUrl
    // });
    await onPost(submission);
    alert(`Your notification has been sent!`);
  };

  // List Generator
  const [msgOptions, setMsgOptions] = useState(['']);
  const [recipientOptions, setRecipientOptions] = useState(['']);

  const fetchListsHandler = useCallback(async () => {
    const docRef = doc(fsCtx.fsObject, 'fcadmin/fcadmin');
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();

    if (docSnap.exists()) {
      setMsgOptions(
        docData.notificationType.map((element) => (
          <MenuItem key={element} value={element === 'All Users' ? 'fcAppUser' : element}>
            {String(element)}
          </MenuItem>
        ))
      );
      setRecipientOptions(
        docData.receipientTypes.map((element) => {
          let userType = '';

          switch (element) {
            case 'fcAppUser':
              userType = 'All Users';
              break;
            case 'verifiedUser':
              userType = 'Verified Users';
              break;
            case 'existingHirer':
              userType = 'Existing Hirer';
              break;
            case 'jaydenTestGroup':
              userType = 'jaydenTestGroup';
              break;
            default:
          }

          return (
            <MenuItem key={element} value={String(element)}>
              {String(userType)}
            </MenuItem>
          );
        })
      );
    }
    // doc.data() will be undefined in this case
  }, [fsCtx.fsObject]);

  useEffect(() => fetchListsHandler(), [fetchListsHandler]);

  // New Message Form logic

  const NewMessageSchema = Yup.object().shape({
    link: Yup.string().url('Please enter a valid url or leave it empty.'),
    title: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Please enter a title.'),
    type: Yup.string().required('Please choose a type.'),
    receipients: Yup.string().required('Please choose a receipient group to send to.'),
    message: Yup.string().required('Message must not be empty.')
  });

  const formik = useFormik({
    initialValues: {
      date: Date(),
      link: '',
      message: '',
      receipients: recipientOptions[0],
      title: '',
      type: msgOptions[0]
    },
    validationSchema: NewMessageSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (window.confirm('Are you sure you want to send out this message?')) {
        const notificationObject = {
          link: values.link,
          imageUrl: '',
          msgTitle: values.title,
          msgDate: new Date(values.date),
          msgType: values.type,
          msgContent: encodeURIComponent(values.message),
          receipients: values.receipients,
          sender: authCtx.name
        };
        try {
          await sendMessageHandler(notificationObject);

          resetForm();
          setUploadImg();
          setSelectedFileName();
          window.location.reload();
        } catch (error) {
          errorHandler(notificationObject, 'CreateNewNotification', error);
        }
      }
      setSubmitting(false);
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, handleChange, handleReset, getFieldProps } = formik;

  // Upload File Name Display
  const [selectedFileName, setSelectedFileName] = useState();
  const [imgLoading, setImgLoading] = useState(false);
  const [uploadImg, setUploadImg] = useState();
  const selectFileHandler = (event) => {
    setSelectedFileName(event.target.value.split('\\').pop());
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onloadstart = () => {
        setImgLoading(true);
      };
      reader.onload = (ev) => {
        setImgLoading(false);
        setUploadImg(ev.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <legend>Send a new message to selected App users.</legend>

          <TextField
            type="text"
            label="Title"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
          <FormControl>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              {...getFieldProps('type')}
              label="Type"
              onChange={handleChange}
              error={Boolean(touched.type && errors.type)}
            >
              {msgOptions}
            </Select>
            {errors.type && touched.type ? (
              <FormHelperText style={{ color: 'red' }}>{errors.type}</FormHelperText>
            ) : null}
          </FormControl>

          <FormControl>
            <FcAutocomplete
              formik={formik}
              name="receipients"
              label="Recepients"
              options={ ["fcAppUser", "jaydenTestGroup", "test"] }
              freeSolo
            />
          </FormControl>

          <TextField
            type="text"
            label="Link"
            {...getFieldProps('link')}
            error={Boolean(touched.link && errors.link)}
            helperText={errors.link ? errors.link : "If this is not empty, a button will be displayed in the App user's notification. The user will be able to tap on the link and visit this website."}
          />

          <label htmlFor="contained-button-file">
            <input
              style={{ display: 'none' }}
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={selectFileHandler}
            />
            <Button variant="contained" component="span">
              Upload
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;Selected file: {selectedFileName}
          </label>
          <i>*Image dimension should be landscape</i>

          <img src={uploadImg} alt="" style={{ maxHeight: '200px', objectFit: 'contain' }} />

          <i>
            *The App currently does not support emojis or line breaks. Please do not use these in
            your message.
          </i>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label="Message"
            {...getFieldProps('message')}
            multiline
            rows={7}
            placeholder="Type your message here"
            onChange={handleChange}
            error={Boolean(touched.message && errors.message)}
            helperText={touched.message && errors.message}
          />
          <Box sx={{ '& button': { m: 1 } }}>
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              Send
            </LoadingButton>
            <Button size="large" variant="contained" onClick={handleReset}>
              Clear
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
export default FcAppNewNotification;
