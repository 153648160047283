import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportInvoiceForm = ({ userMap }) => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);
  const { onPost, loading } = useHttpPost("/api/invoice/import");

  const columns = [
    { field: "invoiceNo", headerName: "Invoice No", width: 150 },
    { field: "bookingNo", headerName: "Booking No", width: 150 },
    { field: "agreementNo", headerName: "Agreement No", width: 150 },
    { field: "title", headerName: "Title", width: 250 },
    { field: "issueDate", headerName: "Issue Date", width: 250 },
    { field: "terms", headerName: "Terms", width: 150 },
    { field: "totalAmount", headerName: "Total Amount", width: 150 },
    { field: "gstRate", headerName: "GST Rate", width: 150 },
    { field: "gstAmount", headerName: "GST Amount", width: 150 },
    { field: "customerName", headerName: "Customer Name", width: 150 },
    { field: "customerAddress", headerName: "Customer Address", width: 250 },
    { field: "customerPostal", headerName: "Customer Postal", width: 150 },
    { field: "vehicleCompanyName", headerName: "Company Name", width: 150 },
    { field: "vehicleCompanyAddress", headerName: "Company Address", width: 150 },
    { field: "vehicleCompanyPostal", headerName: "Company Postal", width: 150 },
    { field: "vehicleCompanyUen", headerName: "Company UEN", width: 150 },
    { field: "remarks", headerName: "Remarks", width: 250 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
    { field: 'applyGst', headerName: 'Apply GST', width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "userId", headerName: "User", width: 150,
      renderCell: ({ row }) => userMap[row.userId]?.name,
    },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    issueDate: row?.issueDate ? dayjs(row.issueDate).toISOString() : null,
    applyGst: row.applyGst === "TRUE",
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs().toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Invoices" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

ImportInvoiceForm.propTypes = {
  userMap: PropTypes.object,
}

export default ImportInvoiceForm;