import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import PropTypes from "prop-types";

export default function RentalUsageQuestionForm({ formik }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">What customer rent for?</Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup row name="rentalUsage" value={formik.values.rentalUsage} onChange={formik.handleChange}>
          <FormControlLabel control={<Radio/>} label="Private Hire" value="Private Hire"/>
          <FormControlLabel control={<Radio/>} label="Personal Use" value="Private Use"/>
          <FormControlLabel control={<Radio/>} label="Others" value="Others"/>
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

RentalUsageQuestionForm.propTypes = {
  formik: PropTypes.object,
}