import dayjs from "dayjs";
import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportInvoiceItemForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);
  const { onPost, loading } = useHttpPost("/api/invoice/item/import");

  const columns = [
    { field: "description", headerName: "Description", width: 150 },
    { field: "quantity", headerName: "Quantity", width: 150 },
    { field: "unitPrice", headerName: "Unit Price", width: 250 },
    { field: "invoiceNo", headerName: "Invoice No", width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
    { field: "amountPaid", headerName: "Amount Paid", width: 150 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs().toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Invoice Items" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

export default ImportInvoiceItemForm;