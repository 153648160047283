import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import useHttpGet from "../../../hooks/http/useHttpGet";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import palette from "../../../theme/palette";
import {paymentPeriodTypes, getDayjsValue} from "../../../utils/date";
import InvoiceItemTable from "../../Table/Invoice/InvoiceItemTable";
import {FcDateField, FcSelectField, FcTextArea, FcTextField, FcToggleButton, SubmitBtnGroup} from "../FormFields";
import InvoiceItemForm from "./InvoiceItemForm";

const AgreementDetails = ({ agreement }) => {
  const customerDetails = [
    { label: "Booking No.", value: agreement.bookingNo },
    { label: "Start Date", value: getDayjsValue(agreement.startDateTime).format("DD MMM YYYY") },
    { label: "End Date", value: getDayjsValue(agreement.endDateTime).format("DD MMM YYYY") },
    { label: "Customer Type", value: agreement.customerType },
    { label: "Customer Name", value: agreement?.customerType === "Company" ? agreement?.companyName : agreement?.customerName },
    { label: "Customer Email", value: agreement?.customerType === "Company" ? agreement?.companyEmail : agreement?.customerEmail },
    { label: "Customer Phone", value: agreement?.customerType === "Company" ? agreement?.companyPhone : agreement?.customerPhone },
    { label: "Customer NRIC/FIN/ACRA", value: agreement?.customerType === "Company" ? agreement?.companyIdentity : agreement?.customerIdentity },
  ];

  const paymentDetails = [
    { label: "Vehicle Number", value: agreement.vehicleNumber },
    { label: "Make / Model", value: `${agreement.vehicleMakeName} / ${agreement.vehicleModelName} ${agreement.vehiclePetrol}` },
    { label: "Deposit", value: agreement.deposit > 0 ? `S$${agreement.deposit}` : "No Deposit" },
    { label: "Rental Rate", value: `S$${agreement.rentalPrice} / ${paymentPeriodTypes[agreement.paymentPeriod].toLowerCase()}` },
  ];

  return (
    <Grid container spacing={3}>
      {customerDetails.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12} sm={9}>
        <Typography variant="h6">Customer Address</Typography>
        {agreement.customerType === "Company" ? agreement.companyAddress : agreement.customerAddress}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="h6">Customer Postal</Typography>
        {agreement.customerType === "Company" ? agreement.companyPostal : agreement.customerPostal}
      </Grid>
      {paymentDetails.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
    </Grid>
  );
}

AgreementDetails.propTypes = {
  agreement: PropTypes.object,
}

const OwnerDetails = ({ formik }) => {
  const paymentDetails = [
    { label: "Company", value: formik.values.vehicleCompanyName },
    { label: "Company UEN", value: formik.values.vehicleCompanyUen },
  ];

  return (
    <Grid container spacing={3}>
      {paymentDetails.map(({ label, value }, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Company Address</Typography>
        {formik.values.vehicleCompanyAddress} {formik.values.vehicleCompanyPostal}
      </Grid>
    </Grid>
  );
}

OwnerDetails.propTypes = {
  formik: PropTypes.object,
}

const AddItemAction = ({ agreement, onAdd }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>Add Invoice Item</DialogTitle>
        <DialogContent>
          <Box py={1}>
            <InvoiceItemForm agreement={agreement} onClose={() => setOpen(false)} onAdd={onAdd}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Add</Button>
    </>
  );
}

AddItemAction.propTypes = {
  agreement: PropTypes.object,
  onAdd: PropTypes.func,
}

export default function AgreementInvoiceForm({ type = "normal", agreement, onReload, onClose }) {
  const method = "Create";
  const companyEditable = false;
  const dataCtx = useContext(DataContext);

  const { data: owner } = useHttpGet(`/api/vehicles/owner/${agreement.vehicleCompanyId}`);
  const { data: owners } = useHttpGet(`/api/vehicles/owner`);
  const { onPost } = useHttpPost("/api/invoice");

  const cdwCompany = owners?.find(owner => owner.name === "Renty Pte Ltd");

  const formik = useFormik({
    initialValues: {
      applyGst: true,
      bookingNo: agreement?.bookingNo,
      bookingId: agreement?.bookingId,
      agreementNo: agreement?.agreementNo,
      agreementId: agreement?.id,
      title: "",
      issueDate: moment(),
      terms: 3,
      totalAmount: 0,
      gstAmount: 0,
      gstRate: 0,
      customerName: agreement?.customerName,
      customerAddress: agreement?.customerAddress,
      customerPostal: agreement?.customerPostal,
      customerId: agreement?.customerId,
      vehicleCompanyName: agreement?.vehicleCompanyName,
      vehicleCompanyAddress: agreement?.vehicleCompanyAddress,
      vehicleCompanyPostal: agreement?.vehicleCompanyPostal,
      vehicleCompanyUen: agreement?.vehicleCompanyUen,
      vehicleCompanyId: agreement?.vehicleCompanyId,
      items: [],
      remarks: "",
    },
    validationSchema: Yup.object({
      applyGst: Yup.boolean().required("Please determine this invoice need apply GST or not!"),
      title: Yup.string().required("Title is required!"),
      issueDate: Yup.date().required("Issue Date is required!"),
      terms: Yup.number().required("Terms is required!"),
      items: Yup.array().of(
        Yup.object({
          description: Yup.string().required("Description is required!"),
          quantity: Yup.number().required("Quantity is required!"),
          unitPrice: Yup.number().required("Unit Price is required!"),
        }),
      ),
      gstAmount: Yup.number().required("Please calculate GST amount"),
      gstRate: Yup.number().required("Please calculate GST rate"),
      totalAmount: Yup.number().required("Please calculate total amount"),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost({
          ...values,
          issueDate: values.issueDate.toISOString(),
        });
        dataCtx.setSnackbarConfig({ open: true, message: "Invoice created successfully", severity: "success" });
        await onReload();
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const onAddItem = values => formik.setFieldValue("items", [...formik.values.items, {...values, id: formik.values.items.length + 1}]);
  const onRemoveItem = id => formik.setFieldValue("items", formik.values.items.filter(item => item.id !== id));

  useEffect(() => {
    const amount = formik.values.items.reduce((total, curr) => total + curr.quantity * curr.unitPrice, 0);
    formik.setFieldValue("totalAmount", amount);
    if (owner?.isRegisterGst && formik.values.applyGst) {
      const currentGSTRate = dataCtx.gstRateList.find(gst => gst.dateEnd === null)?.rate ?? 0;
      formik.setFieldValue("gstAmount", amount * currentGSTRate / 100);
      formik.setFieldValue("gstRate", currentGSTRate);
    } else {
      formik.setFieldValue("gstAmount", 0);
      formik.setFieldValue("gstRate", 0);
    }
  }, [formik.values.items, formik.values.applyGst]);

  useEffect(() => {
    if (cdwCompany && type === "cdw") {
      formik.setFieldValue("vehicleCompanyId", cdwCompany.id);
    }
  }, [cdwCompany]);

  useEffect(() => {
    if (owners) {
      const owner = owners.find(owner => owner.id === formik.values.vehicleCompanyId);
      if (owner) {
        formik.setFieldValue("applyGst", owner.isRegisterGst);
        formik.setFieldValue("vehicleCompanyName", owner.name);
        formik.setFieldValue("vehicleCompanyAddress", owner.address);
        formik.setFieldValue("vehicleCompanyPostal", owner.postal);
        formik.setFieldValue("vehicleCompanyUen", owner.uenNo);
      }
    }
  }, [formik.values.vehicleCompanyId, owners]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AgreementDetails agreement={agreement}/>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ border: 1, borderColor: palette.divider }}/>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FcTextField formik={formik} name="title" label="Title"/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FcDateField formik={formik} name="issueDate" label="Issue Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FcTextField formik={formik} name="terms" label="Terms" type="number"/>
          </Grid>
          {companyEditable && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Bill from</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FcSelectField
                  formik={formik} name="vehicleCompanyId" label="Select Company"
                  items={owners?.map(owner => ({label: owner.name, value: owner.id}))}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <OwnerDetails formik={formik}/>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Invoice Items</Typography>
              <AddItemAction agreement={agreement} onAdd={onAddItem}/>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <InvoiceItemTable data={formik.values.items || []} onRemove={onRemoveItem}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">Total Amount</Typography>
            S${formik.values.totalAmount}
          </Grid>
          {formik.values.gstRate > 0 && <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcToggleButton formik={formik} name="applyGst" label="Apply GST"/>
          </Grid>}
          {formik.values.gstRate > 0 && formik.values.applyGst && <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">GST Rate</Typography>
            {formik.values.gstRate}%
          </Grid>}
          {formik.values.gstAmount > 0 && formik.values.applyGst && <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="h6">GST Amount</Typography>
            S${formik.values.gstAmount}
          </Grid>}
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} onCancel={onClose} method={method}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

AgreementInvoiceForm.propTypes = {
  type: PropTypes.string,
  agreement: PropTypes.object,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
}