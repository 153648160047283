import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useCallback, useState } from 'react';
import { Backdrop, Button, ButtonGroup, CircularProgress, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';

import AdminLayout from '../../layouts/AdminLayout';
import coConverter from '../../utils/coConverter';
import fetchApi from '../../utils/fetchApi';
import errorHandler from '../../utils/errorHandler';

const Reports = () => {
  const [rentalAgreementListing, setRentalAgreementListing] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // useEffect(() => {
  //   async function xx() {
  //     setOpenBackdrop(true);
  //     await fetchAgreementListings();
  //     setOpenBackdrop(false);
  //   }
  //   xx();
  // }, []);

  const tableColumns = [
    {
      field: 'id',
      headerName: 'Options',
      width: 10,
      type: 'string'
    },
    {
      field: 'company',
      headerName: 'Co',
      width: 50,
      type: 'string',
      sortable: true
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 120,
      type: 'date',
      valueFormatter: (params) =>
        params.value.toLocaleDateString('en-sg', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }),
      sortable: true
    },
    {
      field: 'collectionDate',
      headerName: 'Collection Date',
      width: 120,
      type: 'date',
      valueFormatter: (params) =>
        params.value &&
        params.value.toLocaleDateString('en-sg', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }),
      sortable: true
    },
    {
      field: 'hirer',
      headerName: 'Hirer',
      width: 300,
      type: 'string',
      editable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      type: 'string',
      editable: false,
      renderCell: (params) => (
        <div style={{ color: params.value === 'VOIDED' ? 'red' : undefined }}>{params.value}</div>
      )
    },
    {
      field: 'docType',
      headerName: 'Type',
      width: 120,
      type: 'string',
      sortable: true
    },
    {
      field: 'documentNo',
      headerName: 'No.',
      width: 150,
      editable: false
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 330,
      type: 'string',
      editable: false
    },
    {
      field: 'paymentMode',
      headerName: 'Payment Mode',
      width: 160,
      type: 'string',
      editable: false
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 90,
      type: 'number',
      editable: false
    },
    {
      field: 'os',
      headerName: 'Outstanding',
      width: 90,
      type: 'number',
      editable: false
    },
    {
      field: 'paidBy',
      headerName: 'Paid By',
      width: 120,
      type: 'string',
      editable: false
    }
  ];

  const today = new Date();

  const [fromValue, setFromValue] = useState(new Date());
  const fromValueHandleChange = (newValue) => {
    setFromValue(newValue);
    setRentalAgreementListing([]);
  };
  const [toValue, setToValue] = useState(new Date());
  const toValueHandleChange = (newValue) => {
    setToValue(newValue);
    setRentalAgreementListing([]);
  };

  const fetchAgreementListings = useCallback(
    async (event) => {
      // Fetch rental agreements

      setOpenBackdrop(true);

      const mx = new Date().getMonth() + 1;
      const m1 = () => {
        if (mx - 1 === 0) {
          return '12';
        }
        return mx - 1 < 10 ? `0${mx - 1}` : (mx - 1).toString();
      };
      const m2 = mx < 10 ? `0${mx}` : mx.toString();
      const y1 = () => {
        if (mx - 1 === 0) {
          return (new Date().getFullYear() - 1).toString().slice(2, 4);
        }
        return new Date().getFullYear().toString().slice(2, 4);
      };
      const y2 = new Date().getFullYear().toString().slice(2, 4);

      const ds = [`${m1()}${y1()}0`, `${m2}${y2}0`];

      let method;
      let requestBody;

      const formattedFrom = moment(fromValue).format('DD/MM/yyyy');
      const formattedTo = moment(toValue).format('DD/MM/yyyy');
      const displaylength = 10000;

      switch (event.target.textContent) {
        case 'Invoice':
          method = 'GetInvoiceListing';
          requestBody = {
            BookNo: '',
            StartDate: formattedFrom,
            EndDate: formattedTo,
            InvoiceNo: '',
            Company: '',
            Hirer: '',
            CarPlate: '',
            Status: '',
            displaylength
          };
          break;
        case 'Collection':
          method = 'GetCollectionListing';
          requestBody = {
            startdate: formattedFrom,
            enddate: formattedTo,
            paymentstartdate: '01/01/1900',
            paymentenddate: '31/12/2030',
            bookno: '',
            hirer: '',
            paymentmode: '',
            orno: '',
            active: '0',
            displaylength
          };
          break;
        case 'Credit Note':
          method = 'GetCreditNoteListing';
          requestBody = {
            BookNo: '',
            DateFrom: formattedFrom,
            DateTo: formattedTo,
            CreditNo: '',
            Company: '',
            Hirer: '',
            displaylength
          };
          break;
        case 'Refund':
          method = 'GetRefundVouchers';
          requestBody = {
            startdate: formattedFrom,
            enddate: formattedTo,
            bookno: '',
            hirer: '',
            status: '',
            refundno: '',
            active: '0',
            displaylength
          };
          break;
        case 'Deposit':
          method = 'GetDepositListing';
          requestBody = {
            startdate: formattedFrom,
            enddate: formattedTo,
            bookno: '',
            hirer: '',
            status: '',
            invoiceno: '',
            refunded: '',
            active: '1',
            displaylength
          };
          break;
        default:
          break;
      }

      try {
        const data = await fetchApi([
          {
            method,
            requestBody
          }
        ]);

        const aggregateData = data.reduce((total, curr) => total.concat(curr.aaData), []);

        let id;
        let company;
        let docDate;
        let collectionDate;
        let hirer;
        let docType;
        let docNo;
        let description;
        let paymentMode;
        let amount;
        let status;
        let isVerified;

        switch (method) {
          case 'GetInvoiceListing':
            id = 'invoicecode';
            company = 'company';
            docDate = 'invoicedate';
            collectionDate = '';
            hirer = 'HirerName';
            docType = 'Invoice';
            docNo = 'invoicecode';
            description = 'invoicetitle';
            paymentMode = '';
            amount = 'totalsellprice';
            status = 'invoicestatus';
            break;
          case 'GetCreditNoteListing':
            id = 'creditnotecode';
            company = 'CompanyName';
            docDate = 'mCreditNoteDate';
            collectionDate = '';
            hirer = 'HirerName';
            docType = 'Credit Note';
            docNo = 'creditnotecode';
            description = 'creditnotecode';
            paymentMode = '';
            amount = 'TotalAmount';
            status = 'active';
            break;
          case 'GetCollectionListing':
            id = 'receiptcode';
            company = '';
            docDate = 'paymentdate';
            collectionDate = 'receiptdate';
            hirer = 'PaymentMadeBy';
            docType = 'Collection';
            docNo = 'receiptcode';
            description = 'particulars';
            paymentMode = 'paymentmode';
            amount = 'amtpaid';
            status = 'receiptstatus';
            isVerified = 'isVerified';
            break;
          case 'GetRefundVouchers':
            id = 'RefundNo';
            company = '';
            docDate = 'RefundDate';
            collectionDate = '';
            hirer = 'HirerName';
            docType = 'Refund';
            docNo = 'RefundNo';
            description = 'Description';
            paymentMode = 'PaymentMode';
            amount = 'PayAmount';
            status = 'Status';
            break;
          case 'GetDepositListing':
            id = 'invoicecode';
            company = 'companyname';
            docDate = 'invoicedate';
            collectionDate = '';
            hirer = 'hirername';
            docType = 'Deposit';
            docNo = 'invoicecode';
            description = '';
            paymentMode = '';
            amount = 'depositamount';
            status = 'active';
            break;
          default:
          // code block
        }

        const statusConverter = (element) => {
          if (method === 'GetCollectionListing' && element[status] !== 'VOIDED') {
            return element[isVerified] ? 'VERIFIED' : 'UNVERIFIED';
          }
          let x;
          switch (element[status]) {
            case 1:
              x = 'ACTIVE';
              break;
            case 0:
              x = 'VOIDED';
              break;
            default:
              x = element[status];
          }
          return x;
        };

        const responseArray = (element) => ({
          id: element[id],
          company: company === '' ? '' : coConverter(element[company]),
          date: new Date(element[docDate]),
          collectionDate:
            element[collectionDate] === undefined ? null : new Date(element[collectionDate]),
          hirer: element[hirer],
          docType,
          documentNo: element[docNo],
          description: element[description],
          paymentMode: paymentMode === '' ? '' : element[paymentMode],
          amount: element[amount],
          os: method === 'GetInvoiceListing' ? element.amount : null,
          paidBy: '',
          status: statusConverter(element),
          isVerified: method === 'GetCollectionListing' ? element[isVerified] : 'nil'
        });

        const mappedData = aggregateData.map((el) => responseArray(el));

        setRentalAgreementListing(mappedData);
      } catch (e) {
        errorHandler('x', 'GetFinanceDocuments', e);
        console.error(`Fetch failed: ${e}`);
      }

      setOpenBackdrop(false);
    },
    [fromValue, toValue]
  );

  const filterDayList = [-7, -6, -5, -4, -3, -2, -1, 0];
  const filters = filterDayList.map((el) => ({
    name: new Date(today.getTime() + el * 24 * 60 * 60 * 1000).toDateString(),
    value: el
  }));
  const tabNames = ['Reports'];

  const tooltipNotes = [
    '1. If you\'re searching collections, the "Date" column shows the payment date, not the collection date.'
  ];

  const searches = ['Invoice', 'Collection', 'Credit Note', 'Refund', 'Deposit'];

  const code = (
    <Stack alignItems="center" direction="row" mt={2} mb={2} spacing={2}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          disableMaskedInput
          label="From"
          inputFormat="D/MM/yyyy"
          value={fromValue}
          onChange={fromValueHandleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DesktopDatePicker
          disableMaskedInput
          label="To"
          inputFormat="D/MM/yyyy"
          value={toValue}
          onChange={toValueHandleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <ButtonGroup size="large" variant="contained" aria-label="outlined primary button group">
        {searches.map((el) => (
          <Button key={el} onClick={fetchAgreementListings}>
            {el}
          </Button>
        ))}
      </ButtonGroup>
    </Stack>
  );

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AdminLayout
        code={code}
        dataGridCheckbox
        title="Reports"
        tableColumns={tableColumns}
        data={rentalAgreementListing}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tabNames={tabNames}
        tooltipNotes={tooltipNotes}
        // filters={filters}
      />
    </>
  );
};
export default Reports;
