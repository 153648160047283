import {Button, Container, Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import SurchargeTypeForm from "../../../components/Form/Prices/SurchargeTypeForm";
import SurchargesTable from "../../../components/Table/Prices/SurchargesTable";
import useHttpGet from "../../../hooks/http/useHttpGet";

const CreateAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Create Surcharge Type</DialogTitle>
        <DialogContent>
          <SurchargeTypeForm onReload={onReload} onClose={() => setOpen(false)}/>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Create
      </Button>
    </>
  );
}

CreateAction.propTypes = {
  onReload: PropTypes.func,
}

export default function SurchargesList() {
  const { data, onGet } = useHttpGet("/api/surcharge/type");

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Surcharges Listing</Typography>
        <CreateAction onReload={onGet}/>
      </Stack>
      <SurchargesTable data={data} onReload={onGet}/>
    </>
  );
}