import { useCallback, useContext, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Icon,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { Formik, Form, Field, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { DialogTitle } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import {
  DatePicker,
  DesktopDatePicker,
  LoadingButton,
  LocalizationProvider
} from '@material-ui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment';
import { Timestamp, doc, updateDoc } from 'firebase/firestore';
import FirestoreContext from '../../store/FirestoreContext';
import DataContext from '../../store/DataContext';
import errorHandler from '../../utils/errorHandler';
import { a11yProps, TabPanel } from '../../components/TabPanel';
import { timeStampToDate } from '../../utils/formatTime';

const accountsSettings = [
  { key: 'fcGst', dataType: 'checkbox', label: 'FC GST' },
  { key: 'ocGst', dataType: 'date', label: 'OC GST' },
  { key: 'invoiceTerms', dataType: 'number', label: 'Invoice Terms (Days)' }
];

const SettingsItems = () =>
  accountsSettings.map((el) => (
    <ListItem key={el.key}>
      <ListItemText id={el.key} primary={el.label} />
      <Field
        name={el.key}
        type={el.dataType}
        component={
          el.dataType === 'number' ? FormikTextField : FormikDatePicker
        }
      />
    </ListItem>
  ));

const FormikTextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  FormikTextField.propTypes = {
    field: PropTypes.object,
    form: PropTypes.object
  };

  return (
    <TextField
      size="small"
      style={{ width: 100 }}
      {...field}
      {...props}
      error={touched.gstRate && Boolean(errors.gstRate)}
      helperText={touched.gstRate && errors.gstRate}
    />
  );
};

const FormikSwitch = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  FormikSwitch.propTypes = {
    field: PropTypes.object
  };

  return <Switch {...field} {...props} />;
};

const FormikDatePicker = ({ field, form: { setFieldValue, touched, errors }, ...props }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <DatePicker
      {...field}
      {...props}
      value={field.value ? moment(field.value) : null}
      onChange={(val) => setFieldValue(field.name, val ? val.toISOString() : null)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={touched[field.name] && Boolean(errors[field.name])}
          helperText={touched[field.name] && errors[field.name]}
          label="GST Registered Date"
        />
      )}
    />
  </LocalizationProvider>
);

export default function AccountsSettings() {
  const fsCtx = useContext(FirestoreContext);
  const dataCtx = useContext(DataContext);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const handleClickOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  const [value, setValue] = useState(1);

  const columns = [
    {
      field: 'id',
      headerName: 'Action',
      width: 180,
      renderCell: (params) =>
        params.value !== 1 && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDeleteOpen(params.row)}
              size="small"
              style={{ marginLeft: 16 }}
            >
              Delete
            </Button>
          </>
        ),
      type: 'string'
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 300,
      type: 'string',
      editable: false
    },
    {
      field: 'dateStart',
      headerName: 'Start from',
      width: 120,
      type: 'date',
      valueFormatter: (params) => params.value && timeStampToDate(params.value),
      sortable: true
    },
    {
      field: 'dateEnd',
      headerName: 'End of',
      width: 120,
      type: 'date',
      valueFormatter: (params) => params.value && timeStampToDate(params.value),
      sortable: true
    }
  ];

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedGst, setSelectedGst] = useState({});
  const handleDeleteOpen = (params) => {
    console.log(params);
    setSelectedGst(params);
    setDeleteModal(true);
  };
  const handleDeleteClose = () => setDeleteModal(false);
  const deleteGst = async () => {
    setIsLoading(true);
    const gstList = dataCtx.gstRateList;
    // console.log(selectedGst);
    const newList = gstList.filter((item) => item.id !== selectedGst.id);
    if (newList.length > 0) {
      newList[newList.length - 1].dateEnd = null;
    }
    try {
      console.log(newList);
      const docRef = doc(fsCtx.fsObject, 'fcadmin', 'fcadmin');
      await updateDoc(docRef, { gstRateList: newList });
    } catch (e) {
      errorHandler('Save button', 'Gst Settings', e);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setOpenSnackbar(true);
    // Need to transmit the settings to useContext also because user might not necessarily refresh the entire app at anytime, so updated settings won't reflect in the web app and might affect the users data if user continues to use old settings to print and email documents
    dataCtx.setGstRateList(newList);
    setSnackbarInfo({ message: `Your have remove Gst rate ${selectedGst.rate}`, type: 'success' });
    handleDeleteClose();
  };

  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const validationSchema = Yup.object().shape({
    gstRate: Yup.number().min(0, 'Percentage cannot be lesser than 0.').nullable()
  });

  const handleSubmits = useCallback(
    async (values, { setSubmitting }) => {
      console.log(values);
      setSubmitting(true);
      try {
        const docRef = doc(fsCtx.fsObject, 'fcadmin', 'fcadmin');
        await updateDoc(docRef, { adminSettings: values });
      } catch (e) {
        errorHandler('Save button', 'Accounts Settings', e);
        setSubmitting(false);
        return;
      }
      setOpenSnackbar(true);
      // Need to transmit the settings to useContext also because user might not necessarily refresh the entire app at anytime, so updated settings won't reflect in the web app and might affect the users data if user continues to use old settings to print and email documents
      dataCtx.setAdminSettings(values);
      setSnackbarInfo({ message: 'Your settings have been saved.', type: 'success' });
      setSubmitting(false);
    },
    [dataCtx, fsCtx.fsObject]
  );

  const gstSchema = Yup.object().shape({
    rate: Yup.number().min(0, 'Percentage cannot be lesser than 0.').nullable(),
    startdate: Yup.date().required('Date is required')
  });

  const submitGstHandler = useCallback(
    async (item) => {
      setIsLoading(true);
      const gstList = dataCtx.gstRateList;
      let newList = [];
      const { startdate } = item;
      const startdateTimeStamp = Timestamp.fromDate(
        item.startdate.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
      );

      if (gstList.length > 0) {
        const prevItem = gstList[gstList.length - 1];
        const lastdateTimeStamp = prevItem.dateStart;
        const lastdate = moment(
          lastdateTimeStamp.seconds * 1000 + lastdateTimeStamp.nanoseconds / 1000000
        );
        const isAfter = startdate.isAfter(lastdate);
        const isSame = startdate.format('DD/MM/YYYY') === lastdate.format('DD/MM/YYYY');
        const isSameRate = prevItem.rate === item.rate;

        if (!isAfter || isSame) {
          alert('Please select a date after the previous date');
          setIsLoading(false);
          return;
        }

        if (isSameRate) {
          alert('Please enter a new Gst rate');
          setIsLoading(false);
          return;
        }

        const prevEnddateTimeStamp = Timestamp.fromDate(
          item.startdate
            .clone()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .add(-1, 'days')
            .toDate()
        );

        prevItem.dateEnd = prevEnddateTimeStamp;
        newList = [
          ...gstList,
          {
            id: prevItem.id + 1,
            rate: item.rate,
            dateStart: startdateTimeStamp,
            dateEnd: null
          }
        ];
      } else {
        newList = [
          {
            id: 1,
            rate: item.rate,
            dateStart: startdateTimeStamp,
            dateEnd: null
          }
        ];
      }

      try {
        const docRef = doc(fsCtx.fsObject, 'fcadmin', 'fcadmin');
        await updateDoc(docRef, { gstRateList: newList });
      } catch (e) {
        errorHandler('Save button', 'Gst Settings', e);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setOpenSnackbar(true);
      handleClose();
      // Need to transmit the settings to useContext also because user might not necessarily refresh the entire app at anytime, so updated settings won't reflect in the web app and might affect the users data if user continues to use old settings to print and email documents
      dataCtx.setGstRateList(newList);
      setSnackbarInfo({ message: 'New Gst Rate is added', type: 'success' });
    },
    [dataCtx, fsCtx.fsObject]
  );

  const formik = useFormik({
    initialValues: {
      rate: '',
      startdate: null
    },
    validationSchema: gstSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      await submitGstHandler({
        rate: values.rate,
        startdate: values.startdate
      });

      resetForm();
      setSubmitting(false);
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Accounts Setting
        </Typography>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={tabHandleChange} aria-label="basic tabs example">
            <Tab label="Basic Settings" {...a11yProps(0)} />
            <Tab label="Gst Rate" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Box
            sx={{
              alignItems: 'center',
              direction: 'column',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              bgcolor: 'background.paper'
            }}
          >
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={() => setOpenSnackbar(false)}
            >
              <Alert
                onClose={() => setOpenSnackbar(false)}
                severity={snackbarInfo.type}
                sx={{ width: '100%' }}
              >
                {snackbarInfo.message}
              </Alert>
            </Snackbar>

            <Formik
              initialValues={dataCtx.adminSettings}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => handleSubmits(values, { setSubmitting })}
            >
              {({ isSubmitting }) => (
                <List
                  sx={{
                    width: '100%',
                    maxWidth: window.innerWidth * 0.8,
                    bgcolor: 'background.paper'
                  }}
                  subheader={<ListSubheader>Settings</ListSubheader>}
                >
                  <Form>
                    <SettingsItems />
                    <Button disabled={isSubmitting} type="submit" variant="contained">
                      Submit
                    </Button>
                  </Form>
                </List>
              )}
            </Formik>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box style={{ height: window.innerHeight * 0.67 }}>
            <Box sx={{ pb: 1, mb: 1, textAlign: 'right' }}>
              <Button variant="contained" onClick={handleClickOpen} to="#">
                Create New Gst Rate
              </Button>
            </Box>
            <DataGrid rows={dataCtx.gstRateList} columns={columns} disableSelectionOnClick />
          </Box>
          <Dialog open={modal} onClose={handleClose}>
            <DialogTitle>Create New Gst Rate</DialogTitle>
            <DialogContent>
              <DialogContentText>Fill in the form below.</DialogContentText>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <TextField
                    margin="dense"
                    label="Gst Rate"
                    type="number"
                    fullWidth
                    variant="standard"
                    {...getFieldProps('rate')}
                    error={Boolean(touched.rate && errors.rate)}
                    helperText={touched.rate && errors.rate}
                  />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      disableMaskedInput
                      label="Start From"
                      inputFormat="DD/MM/yyyy"
                      value={formik.values.startdate}
                      onChange={(val) => setFieldValue('startdate', val, true)}
                      renderInput={(params) => (
                        <TextField
                          margin="dense"
                          fullWidth
                          variant="standard"
                          {...getFieldProps('rate')}
                          error={Boolean(touched.rate && errors.rate)}
                          helperText={touched.rate && errors.rate}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <DialogActions>
                    <Button onClick={handleClose} type="submit" variant="standard">
                      Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" loading={isLoading}>
                      Submit
                    </LoadingButton>
                  </DialogActions>
                </Form>
              </FormikProvider>
            </DialogContent>
          </Dialog>
          <Dialog
            open={deleteModal}
            onClose={handleDeleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Do You want to delete Gst Rate {selectedGst.rate}?
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleDeleteClose}>Cancel</Button>
              <LoadingButton variant="contained" onClick={deleteGst} autoFocus loading={isLoading}>
                Delete
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </TabPanel>
      </Box>
    </Container>
  );
}
