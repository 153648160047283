import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import PublicHolidayForm from '../../../components/Form/Leave/PublicHolidayForm';
import PublicHolidayTable from '../../../components/Table/Leave/PublicHolidayTable';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';

const AddAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add Public Holiday</DialogTitle>
        <DialogContent>
          <Box py={1}>
            <PublicHolidayForm onReload={onReload} onClose={() => setOpen(false)} />
          </Box>
        </DialogContent>
      </Dialog>
      <Button
        variant="contained"
        startIcon={<Icon icon={plusFill} />}
        onClick={() => setOpen(true)}
      >
        Add Public Holiday
      </Button>
    </>
  );
};

AddAction.propTypes = {
  onReload: PropTypes.func
};

export default function PublicHoliday() {
  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/holiday/list`);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Public Holidays</Typography>
        <AddAction onReload={onGet} />
      </Stack>
      <PublicHolidayTable data={data?.data ?? []} onReload={onGet} />
    </Container>
  );
}
