import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <Typography
      sx={{ cursor: "pointer", marginBottom: 5 }} variant="h4" gutterBottom
      onClick={() => navigate(-1)}
    >
        <span style={{position: "relative", top: 3}}>
          <ArrowBackSharpIcon />
        </span>
      &nbsp;&nbsp;
      Back
    </Typography>
  );
}