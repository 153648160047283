import { Icon } from '@iconify/react';
import carFilled from '@iconify/icons-ant-design/car-filled';
import { useContext } from 'react';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Link, Typography } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';

// utils
import DataContext from '../../../store/DataContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppWeeklySales() {
  const dataCtx = useContext(DataContext);
  const fleetSize = dataCtx.carModelsCount;

  return (
    <Link component={ReactLink} to="/dashboard/car-models" sx={{ textDecoration: 'none' }}>
      <RootStyle>
        <IconWrapperStyle>
          <Icon icon={carFilled} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{fleetSize}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Models
        </Typography>
      </RootStyle>
    </Link>
  );
}
