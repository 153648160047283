import React, { useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { LoadingButton } from '@material-ui/lab';
import { getAuth } from 'firebase/auth';
import moment from 'moment';

import FirestoreContext from '../../store/FirestoreContext';
import DataTable from '../../components/DataTable';
import { PopupModal } from '../../components/PopupModal';

let isMounted = true;

export const SendVoucherModal = (props) => {
  const [formSuccess, setFormSuccess] = useState(false);
  SendVoucherModal.propTypes = {
    voucherCatDetails: PropTypes.object
  };

  const validationSchema = yup.object({
    email: yup
      .array()
      .transform((value, originalValue, ctx) => {
        if (ctx.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(',') : [];
      })
      .of(
        yup
          .string()
          .trim()
          .ensure()
          .email(({ value }) => `${value} is not a valid email`)
          .required('Email address cannot be empty.')
      ),
    voucherData: yup.object()
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      voucherData: props.voucherCatDetails
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const idToken = await getAuth().currentUser.getIdToken();

        const response = await fetch(
          `${process.env.REACT_APP_FIREBASE_CLOUD_FUNC}/sendVoucherByEmail`,
          {
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              token: idToken
            },
            body: JSON.stringify(values),
            method: 'POST',
            'upgrade-insecure-requests': 1
          }
        );

        const responseData = await response.json();

        if (responseData.result !== 'success') {
          throw new Error(responseData.error);
        }

        setFormSuccess(true);
        alert(`Voucher has been created for the entered users.`);
        window.location.reload();
      } catch (e) {
        console.error(`An error occurred creating voucher(s): ${e}\n${e.stack}`);
        alert(`An error occurred: ${e}`);
      }
    }
  });
  return (
    <Stack direction="column" spacing={2}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        Voucher Category: {props.voucherCatDetails.category}
      </Typography>
      Send this voucher to a specific set of receipients. Type their email addresses below, each
      separated by a comma.
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <TextField
            key="email"
            InputProps={{
              inputMode: 'email'
            }}
            inputMode="email"
            fullWidth
            id="claimRefNotImportant"
            name="email"
            multiline
            rows={3}
            label="Email"
            {...formik.getFieldProps('email')}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={
              formik.touched.email &&
              [...(formik.errors.email ?? [''])]
                .filter((el) => el)
                .map((el) => (
                  <React.Fragment key={el}>
                    {el}
                    <br />
                  </React.Fragment>
                ))
            }
          />
          <br />
          <LoadingButton type="submit" fullWidth variant="contained" loading={formik.isSubmitting}>
            Submit
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Stack>
  );
};

// ----------------------------------------------------------------------

export default function FcAppVoucherCategories() {
  const fsCtx = useContext(FirestoreContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(true);
  const [vouchersList, setVouchersList] = React.useState([]);
  const tableInitialSort = {
    sorting: {
      sortModel: [{ field: 'creationDate', sort: 'desc' }]
    }
  };

  // Message Details modal logic
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const handleOpen = (modalContent) => {
    setModalOpen(true);
    setModalInfo(modalContent);
  };
  const handleClose = () => setModalOpen(false);

  const columns = [
    {
      field: 'actions', headerName: 'Actions', width: 100,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          onClick={() => {
            handleOpen(
              <SendVoucherModal
                voucherCatDetails={vouchersList.find((el) => el.id === params.row.id)}
              />
            );
          }}
        >
          <SendIcon color="primary" />
        </IconButton>
      )
    },
    {
      field: 'category', headerName: 'Category', width: 100,
      renderCell: (params) => (
        <Button
          size="small"
          variant="contained"
          checked={params.value}
          onClick={() => {
            handleOpen();
          }}
        >
          {params.value}
        </Button>
      )
    },
    {
      field: 'active', headerName: 'Active', width: 80, editable: false,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={async (e) => {
            const newVal = e.target.checked;
            if (
              !window.confirm(
                `Are you sure you want to turn ${e.target.checked ? 'on' : 'off'} the voucher ${
                  params.row.category
                }?`
              )
            ) {
              return;
            }
            try {
              await updateDoc(
                doc(fsCtx.fsObject, 'fcadmin/fcadmin/voucherCategories', params.row.id),
                { active: newVal }
              );
              setVouchersList((oldList) => {
                const voucherI = oldList.findIndex((el) => el.id === params.row.id);
                return oldList.map((el, i) => (i !== voucherI ? el : { ...el, active: newVal }));
              });
            } catch (e) {
              console.error(`An error occurred: ${e}\n${e.stack}`);
              alert(`An error occurred: ${e}\n${e.stack}`);
            }
          }}
        />
      )
    },
    { field: 'name', headerName: 'Title', width: 250, editable: false },
    { field: 'amount', headerName: 'Amount', type: 'number', width: 80, editable: false },
    { field: 'validity', headerName: 'Validity', width: 130, type: 'number', editable: false },
    { field: 'validityDenomination', headerName: 'Validity Denomination', width: 130, type: 'string', editable: false },
    {
      field: 'voucherEndDate', headerName: 'Specific Expiry Date', width: 130, type: 'date',
      renderCell: (params) => {
        if (!params.value) {
          return 'null';
        }
        return moment(params.value.toDate()).format('D MMM yyyy');
      },
      editable: false
    },
    { field: 'description', headerName: 'Description', width: 400, type: 'string', editable: false },
    {
      field: 'imageUrl2', headerName: 'Image', type: 'string', width: 200, editable: false,
      renderCell: (params) => (
        <a href={params.row.imageUrl} target="_blank" rel="noreferrer">
          <img src={params.row.imageUrl} alt={params.row.category} />
        </a>
      )
    },
    { field: 'imageUrl', headerName: 'Image URL', type: 'string', width: 200, editable: false },
    {
      field: 'creationDate', headerName: 'Creation Date', type: 'date', width: 200, editable: false,
      renderCell: (params) => {
        if (!params.value) {
          return 'null';
        }
        return moment(params.value.toDate()).format('D MMM yyyy');
      }
    },
    { field: 'createdBy', headerName: 'CreatedBy', type: 'string', width: 200, editable: false }
  ];

  const fetchVoucherCategories = useCallback(async () => {
    const querySnapshot = await getDocs(
      collection(fsCtx.fsObject, 'fcadmin/fcadmin/voucherCategories')
    );
    if (isMounted) {
      setVouchersList(
        querySnapshot.docs.map((el) => ({
          ...el.data(),
          id: el.id,
          description: decodeURIComponent(el.data().description)
        }))
      );
      setOpenBackdrop(false);
    }
  }, [fsCtx.fsObject]);

  useEffect(() => {
    isMounted = true;
    fetchVoucherCategories();
    return () => {
      isMounted = false;
    };
  }, [fetchVoucherCategories]);

  const notes = [
    'You create a new voucher category. For every voucher category that is active, the App will automatically create a copy of this voucher for every new user that registers for the App and generate a unique code.',
    'If the voucher category is "active", vouchers will always be auto-created. Switch off the "active" switch if you don\'t want the voucher to be created for new users anymore.'
  ];

  return (
    <>
      How it works:
      <ol>
        {notes.map((el) => (
          <li key={el}>{el}</li>
        ))}
      </ol>
      <PopupModal openState={modalOpen} closeHandler={handleClose} modalBody={modalInfo} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: '100%' }}>
        <DataTable
          dataRows={vouchersList}
          dataColumns={columns}
          rowHeight={100}
          tableInitialSort={tableInitialSort}
        />
      </Box>
    </>
  );
}
