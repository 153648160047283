import PropTypes from "prop-types"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import dayjs from 'dayjs';
import DataContext from '../../store/DataContext';
import useCloudFuncDelete from '../../hooks/firebase/cloudFunction/useCloudFuncDelete';

function Action({ request, onReload }) {
  const navigate = useNavigate();
  const dataCtx = useContext(DataContext);
  const { onDelete } = useCloudFuncDelete(`/workshop/request/${request.id}`);

  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const editAction = () => navigate(`./edit/${request.id}`);
  const deleteAction = async () => {
    try {
      await onDelete();
      setOpen(false);
      onOpenSnackbar("Deleted Request Successfully");
      onReload();
    } catch (error) {
      console.log(error.message)
      onOpenSnackbar(error.message, "error");
    }
  }

  return (
    <Box mt={2}>
      <Button
        variant="contained"
        onClick={editAction}
      >
        Edit
      </Button>

      <Button
        variant="contained"
        color="error"
        onClick={() => setOpen(true)}
        sx={{ marginLeft: 1 }}
      >
        Delete
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Delete {request.assigner} | {request.vehicleNumber}?
        </DialogTitle>
        <DialogContent>
          This action cannot be undone
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={() => deleteAction()}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

Action.propTypes = {
  request: PropTypes.object,
  onReload: PropTypes.func
}

export default function AssignerRequestAcc({ request, onReload }) {
  return (
    <>
      <Accordion defaultExpanded elevation={10}>
        <AccordionSummary>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={18} fontWeight={700}>
                {request.assigner} | {request.vehicleNumber}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {request.updatedAt !== request.createdAt && `Updated at ${dayjs(request.updatedAt).format("ddd, D MMM | HH:mm:ss")}`}
              </Typography>
            </Grid>
          </Grid>
          <Button variant="contained" color={ request.completed ? "primary" : "error" }>
            { request.completed ? "COMPLETED" : "ONGOING" }
          </Button>
        </AccordionSummary>
        <AccordionDetails sx={{ marginTop: -1.5 }}>
          { request.task.map((task) => <Typography>{task.name}</Typography>) }
          { request.other && <Typography mt={1}><b>Others:</b> {request.other}</Typography> }
          { request.assignerRemark && <Typography><b>Remarks:</b> {request.assignerRemark}</Typography> }

          {
            request.completed &&
            <>
              <Divider sx={{ mt: 1, mb: 1 }} />

              <Typography>
                Completed by {request.completedBy} at {dayjs(request.updatedAt).format('ddd, D MMM | HH:mm:ss')}
              </Typography>
              {
                request.remark &&
                <Typography>
                  <b>Remarks:</b> {request.remark}
                </Typography>
              }
            </>
          }

          { !request.completed && <Action request={request} onReload={onReload} /> }
        </AccordionDetails>
      </Accordion>
    </>
  );
}

AssignerRequestAcc.propTypes = {
  request: PropTypes.object,
  onReload: PropTypes.func
}