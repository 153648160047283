import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Box, Container, Tab} from "@mui/material";
import {useState} from "react";
import palette from "../../theme/palette";
import CdwPriceList from "./PriceSettings/CdwPriceList";
import SurchargesList from "./PriceSettings/SurchargesList";

export default function PriceSettings() {
  const [tabValue, setTabValue] = useState("surcharges");

  return (
    <Container maxWidth="xl">
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: palette.divider }}>
          <TabList onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="Surcharges" value="surcharges"/>
            <Tab label="CDW" value="cdw"/>
          </TabList>
        </Box>

        <TabPanel value="surcharges">
          <SurchargesList/>
        </TabPanel>
        <TabPanel value="cdw">
          <CdwPriceList/>
        </TabPanel>
      </TabContext>
    </Container>
  );
}