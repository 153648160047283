import { Backdrop, Button, CircularProgress, Container, Link, Stack, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { LoadingButton } from '@material-ui/lab';
import { Warning } from '@mui/icons-material';
import dayjs from 'dayjs';

import CreateFcWebsitePromotionForm from '../../components/Form/FcWebsite/CreateFcWebsitePromotionForm';
import { StyledDataGrid } from '../Vehicle/styles';
import useCloudFuncGet from '../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../cloudFunctionApiConfig';
import useCloudFuncPut from '../../hooks/firebase/cloudFunction/useCloudFuncPut';
import DataContext from '../../store/DataContext';
import { defaultTimezone } from '../../utils/date';
import EditFcWebsitePromotionForm from '../../components/Form/FcWebsite/EditFcWebsitePromotionForm';
import useCloudFuncDelete from '../../hooks/firebase/cloudFunction/useCloudFuncDelete';

const ToggleAction = ({ row, onReload, onOpenSnackbar }) => {
  const [open, setOpen] = useState(false);
  const { loading, onPut } = useCloudFuncPut(`${cloudFunctionPath}/fcwebsite/promotion/toggle`);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await onPut({ id: row.id });
      await onReload();
      onOpenSnackbar('Visible status changed successfully!');
    } catch (e) {
      onOpenSnackbar(`An error occurred: ${e.message}`, 'error');
      console.error(e);
    }
    setOpen(false);
  };

  return (<>
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
      <Box p={2}>
        <DialogTitle><Typography variant="h5">Are you sure you want to make the following
          promotion {row.isActive ? 'private' : 'public'}?</Typography></DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>{row?.title}</Typography>
          <Typography variant="body2" color="textSecondary">This promotion will
            be {row.isActive ? 'hidden' : 'visible'} on Fresh Cars Website.</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={onSubmit} autoFocus loading={loading}>
            Yes, Make it {row.isActive ? 'private' : 'public'}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>

    <Switch checked={row.isActive} onChange={() => setOpen(true)} />
  </>);
};

ToggleAction.propTypes = { row: PropTypes.array, onReload: PropTypes.func, onOpenSnackbar: PropTypes.func };

const EditAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);
  return (<>
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Promotion</DialogTitle>
      <DialogContent>
        <EditFcWebsitePromotionForm onClose={() => setOpen(false)} onReload={onReload} row={row} />
      </DialogContent>
    </Dialog>
    <Button variant="contained" onClick={() => setOpen(true)}>Edit</Button></>);
};

EditAction.propTypes = { row: PropTypes.array, onReload: PropTypes.func };

const DeleteAction = ({ row, onReload, onOpenSnackbar }) => {
  const [open, setOpen] = useState(false);
  const { onDelete, loading } = useCloudFuncDelete(`${cloudFunctionPath}/fcwebsite/promotion/delete`);

  const deleteHandler = async (e) => {
    e.preventDefault();
    try {
      await onDelete({ promotionId: row.id });
      onOpenSnackbar('Promotion deleted successfully!');
      await onReload();
    } catch (e) {
      onOpenSnackbar(e.message, 'error');
    }
    setOpen(false);
  };

  return (<>
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
      <Box p={2}>
        <DialogTitle><Typography variant="h5">Are you sure you want to delete the following promotion?</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>{row?.title}</Typography>
        </DialogContent>
        <DialogActions>
          <Box display="flex" alignItems="center" mr={4}>
            <Warning color="warning" sx={{ mr: 1 }} />
            <Typography variant="body2" color="textSecondary">
              Warning: Once deleted, this step is irreversible.
            </Typography>
          </Box>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton variant="contained" color="error" onClick={deleteHandler} autoFocus loading={loading}>
            Yes, Delete it
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
    <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
  </>);
};

DeleteAction.propTypes = { row: PropTypes.array, onReload: PropTypes.func, onOpenSnackbar: PropTypes.func };

export default function FcWebsitePromotions() {
  const dataCtx = useContext(DataContext);
  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/fcwebsite/promotion`);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = [{
    field: 'isActive',
    headerName: 'Visible',
    align: 'center',
    headerAlign: 'center',
    width: 80,
    renderCell: ({ row }) => (<ToggleAction row={row} onReload={onGet} onOpenSnackbar={onOpenSnackbar} />)
  }, {
    field: 'imgUrl',
    headerName: 'Image',
    width: 150,
    renderCell: ({ row }) => (<Link href={row.imgUrl} underline="none" target="_blank" rel="noopener noreferrer">
      <Box component="img" src={row.imgUrl} sx={{ height: 60, borderRadius: 1, overflow: 'clip' }} />
    </Link>)
  }, {
    field: 'mobileImgUrl',
    headerName: 'Image (Mobile)',
    width: 130,
    renderCell: ({ row }) => (<Link href={row.mobileImgUrl} underline="none" target="_blank" rel="noopener noreferrer">
      <Box component="img" src={row.mobileImgUrl} sx={{ height: 60, borderRadius: 1, overflow: 'clip' }} />
    </Link>)
  }, {
    field: 'title', headerName: 'Title', align: 'start', headerAlign: 'start', width: 300
  }, {
    field: 'description',
    headerName: 'Description',
    align: 'start',
    headerAlign: 'start',
    width: 550,
    renderCell: ({ value }) => (<Box sx={{
      maxHeight: 50, overflowY: 'auto', whiteSpace: 'normal', textOverflow: 'ellipsis'
    }}>
      {decodeURIComponent(value)}
    </Box>)
  }, {
    field: 'dateCreated',
    headerName: 'Created Date',
    align: 'start',
    headerAlign: 'start',
    width: 130,
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dayjs(params.value).utc(true).tz(defaultTimezone).format('DD MMM YYYY');
    }
  }, {
    field: 'actions',
    headerName: 'Actions',
    align: 'center',
    header: 'center',
    width: 180,
    renderCell: ({ row }) => (<Stack direction="row" justifyContent="center" spacing={2}>
      <EditAction row={row} onReload={onGet} />
      <DeleteAction row={row} onReload={onGet} onOpenSnackbar={onOpenSnackbar} />
    </Stack>)
  }];

  const AddPromotions = () => {
    const [open, setOpen] = useState(false);

    return (<>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>New Promotions</DialogTitle>
        <DialogContent>
          <CreateFcWebsitePromotionForm onClose={() => setOpen(false)} onReload={onGet} />
        </DialogContent>
      </Dialog>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<Icon icon={plusFill} />}
      >
        New Promotions
      </Button>
    </>);
  };

  useEffect(() => {
    setRows(data?.data);
    setOpenBackdrop(false);
  }, [data]);

  return (<Container maxWidth="xl">
    <Stack direction="row" justifyContent="space-between" marginBottom={4}>
      <Typography variant="h4">
        Website Promotions
      </Typography>
      <AddPromotions />
    </Stack>
    <StyledDataGrid
      autoHeight
      // components={{ Toolbar: GridToolbar }}
      columns={columns}
      rows={rows ?? []}
      density="comfortable"
    />
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={openBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </Container>);
}