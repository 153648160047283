import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@material-ui/core';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import moment from 'moment';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import FcTable from '../FcTable';
import useCloudFuncDelete from '../../../hooks/firebase/cloudFunction/useCloudFuncDelete';
import DataContext from '../../../store/DataContext';

function ActionCol({ row, onReload }) {
  const dataCtx = useContext(DataContext);
  const { onDelete } = useCloudFuncDelete(`${cloudFunctionPath}/workshop/vehicle/${row.vehicleNumber}`);
  const [open, setOpen] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const deleteOnClick = async () => {
    try {
      await onDelete();
      closeDialog();
      onReload();
    } catch (error) {
      console.log(error.message);
      onOpenSnackbar(error.message, "error");
    }
  }

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <Button variant="contained" component={Link} to={`./edit/${row.vehicleNumber}`}>Edit</Button>
      <Button variant="contained" color="error" onClick={openDialog}>Delete</Button>

      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{`Delete ${row.vehicleNumber}?`}</DialogTitle>
        <DialogContent>This action cannot be undone</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={ deleteOnClick }
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func
}

export default function WorkshopVehicleTable({ data, action = true, onReload }) {
  const columns = [
    {
      field: 'vehicleNumber', headerName: 'Vehicle No.',
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false
    },
    {
      field: "make", headerName: "Make",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false
    },
    {
      field: "model", headerName: "Model",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false
    },
    {
      field: "color", headerName: "Color",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false
    },
    {
      field: "fuel", headerName: "Fuel Type",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false
    },
    {
      field: "registrationDate", headerName: "Registration Date",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false,
      renderCell: ({row}) => moment(row.registrationDate).format("DD MMM YYYY"),
    },
    {
      field: "coeExpiryDate", headerName: "COE Expiry Date",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false,
      renderCell: ({row}) => moment(row.coeExpiryDate).format("DD MMM YYYY"),
    },
    {
      field: "salesPerson", headerName: "Sales Person",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false
    }
  ];

  if (action) {
    columns.push({
      field: '', headerName: "Action",
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false,
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload} />
    });
  }

  return (
    <FcTable rows={data ?? []} columns={columns}/>
  );
}

WorkshopVehicleTable.propTypes = {
  data: PropTypes.array,
  action: PropTypes.bool,
  onReload: PropTypes.func
}