import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportCollectionForm = ({ userMap }) => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);
  const { onPost, loading } = useHttpPost("/api/collection/import");

  const columns = [
    { field: "receiptNo", headerName: "Receipt No", width: 150 },
    { field: "bookingNo", headerName: "Booking No", width: 150 },
    {
      field: "userId", headerName: "User", width: 150,
      renderCell: ({ row }) => userMap[row.userId]?.name,
    },
    { field: "isFinalized", headerName: "Finalized", width: 150 },
    { field: "isVerified", headerName: "Verified", width: 150 },
    {
      field: "verifiedUserId", headerName: "Verified User", width: 150,
      renderCell: ({ row }) => userMap[row.verifiedUserId]?.name,
    },
    { field: "amount", headerName: "Amount", width: 150 },
    { field: "currencyCode", headerName: "Currency Code", width: 150 },
    { field: "paymentMethod", headerName: "Payment Method", width: 150 },
    { field: "collectDate", headerName: "Collect Date", width: 250 },
    { field: "customerName", headerName: "Customer Name", width: 150 },
    { field: "customerAddress", headerName: "Customer Address", width: 250 },
    { field: "customerPostal", headerName: "Customer Postal", width: 150 },
    { field: "customerSignature", headerName: "Customer Signature", width: 150 },
    { field: "salesSignature", headerName: "Sales Signature", width: 150 },
    { field: "vehicleCompanyName", headerName: "Company Name", width: 150 },
    { field: "vehicleCompanyAddress", headerName: "Company Address", width: 150 },
    { field: "vehicleCompanyPostal", headerName: "Company Postal", width: 150 },
    { field: "vehicleCompanyUen", headerName: "Company UEN", width: 150 },
    { field: "remarks", headerName: "Remarks", width: 250 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
    { field: "status", headerName: "Status", width: 150 },
    { field: 'gstRate', headerName: 'GST Rate', width: 150 },
    { field: 'gstAmount', headerName: 'GST Amount', width: 150 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    collectDate: row?.collectDate === "Invalid Date" ? dayjs(row.createdAt).toISOString() : dayjs(row.collectDate).toISOString(),
    isVerified: row.isVerified === "TRUE",
    isFinalized: row.isFinalized === "TRUE",
    amount: Number.isNaN(Number(row.amount)) ? 0 : Number(row.amount),
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs().toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      for (let i = 0; i < rows.length; i += 10000) {
        // eslint-disable-next-line no-await-in-loop
        await onPost(data.slice(i, i + 10000));
      }
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      console.log(error);
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Collections" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

ImportCollectionForm.propTypes = {
  userMap: PropTypes.object,
}

export default ImportCollectionForm;