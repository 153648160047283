import { Form, FormikProvider, useFormik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import {useContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {Grid, Typography} from "@mui/material";
import useHttpPost from "../../../hooks/http/useHttpPost";
import useHttpPut from "../../../hooks/http/useHttpPut";
import useHttpGet from "../../../hooks/http/useHttpGet";
import DataContext from "../../../store/DataContext";
import {
  FcDateField,
  FcFileDropzone,
  FcSelectField,
  FcTextArea,
  FcTextField,
  SubmitBtnGroup
} from "../FormFields";

export default function VehicleForm({ data, onCancel }) {
  const method = data ? "Update" : "Create";

  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/vehicles");
  const { onPut } = useHttpPut(`/api/vehicles/${data?.id}`);
  const navigate = useNavigate();

  const { data: makes } = useHttpGet("/api/vehicles/make");
  const { data: models } = useHttpGet("/api/vehicles/model");
  const { data: owners } = useHttpGet("/api/vehicles/owner");

  const sortLabels = (a, b) => {
    a = a?.label?.toLowerCase();
    b = b?.label?.toLowerCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  const vehicleShapes = [
    { label: "Hatchback", value: "Hatchback" },
    { label: "MPV", value: "MPV" },
    { label: "Sedan", value: "Sedan" },
    { label: "SUV", value: "SUV" },
    { label: "Van", value: "Van" },
    { label: "Station Wagon", value: "Station Wagon" },
  ];

  const vehicleTypes = [
    { label: "Private Hire", value: "Private Hire" },
    { label: "Other", value: "Other" },
  ];

  const coeTypes = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const onCreate = async (values) => {
    await onPost(values);
    dataCtx.setSnackbarConfig({ open: true,  message: "Vehicle Created successfully", severity: "success" });
    setTimeout(() => navigate("../"), 1000);
  }

  const onUpdate = async (values) => {
    await onPut(values);
    dataCtx.setSnackbarConfig({ open: true,  message: "Vehicle Updated successfully!", severity: "success" });
    setTimeout(() => navigate("../"), 1000);
  }

  const formik = useFormik({
    initialValues: {
      vehicleNumber: "",
      vehicleColor: "",
      vehicleShape: "",
      registerDate: null,
      origRegisterDate: null,
      manufacturingYear: null,
      inspectionDueDate: null,
      roadTaxDueDate: null,
      remarks: "",
      makeId: "",
      modelId: "",
    },
    validationSchema: Yup.object({
      vehicleNumber: Yup.string().required("Please fill in Vehicle Number!"),
      vehicleColor: Yup.string().nullable(),
      vehicleShape: Yup.string().nullable(),
      registerDate: Yup.date().required("Please select the registration date!"),
      origRegisterDate: Yup.date().required("Please select the original registration date!"),
      manufacturingYear: Yup.string().required("Please select manufacturing year!"),
      inspectionDueDate: Yup.date().required("Please select the inspection due date!"),
      roadTaxDueDate: Yup.date().required("Please select the road tax due date!"),
      remarks: Yup.string().nullable(),
      makeId: Yup.string().required("Please select vehicle make!"),
      modelId: Yup.string().required("Please select vehicle model!"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (method === "Create") await onCreate(values);
        else await onUpdate(values);
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true,  message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("vehicleNumber", data?.vehicleNumber);
      formik.setFieldValue("makeId", data?.model?.makeId);
      formik.setFieldValue("modelId", data?.modelId);
      formik.setFieldValue("ownerId", data?.ownerId);
      formik.setFieldValue("vehicleType", data?.vehicleType);
      formik.setFieldValue("registerDate", data?.registerDate);
      formik.setFieldValue("origRegisterDate", data?.origRegisterDate);
      formik.setFieldValue("iuNo", data?.iuNo);
      formik.setFieldValue("chassisNo", data?.chassisNo);
      formik.setFieldValue("engineNo", data?.engineNo);
      formik.setFieldValue("manufacturingYear", data?.manufacturingYear);
      formik.setFieldValue("coeExpiryDate", data?.coeExpiryDate);
      formik.setFieldValue("isParf", data?.isParf);
      formik.setFieldValue("inspectionDueDate", data?.inspectionDueDate);
      formik.setFieldValue("roadTaxDueDate", data?.roadTaxDueDate);
      formik.setFieldValue("vehicleColor", data?.vehicleColor);
      formik.setFieldValue("vehicleShape", data?.vehicleShape);
      formik.setFieldValue("remarks", data?.remarks);
    }
  }, [data]);

  useEffect(() => {
    if (formik.values.registerDate) {
      const date = moment(formik.values.registerDate);
      if (!date.isValid()) return;

      formik.setFieldValue("registerDate", moment(date).toISOString());
      formik.setFieldValue("origRegisterDate", moment(date).toISOString());
      if (!formik.values.inspectionDueDate)
        formik.setFieldValue("inspectionDueDate", moment(date).add(1, "year").toISOString());
      if (!formik.values.roadTaxDueDate)
        formik.setFieldValue("roadTaxDueDate", moment(date).add(5, "year").toISOString());
      if (!formik.values.manufacturingYear)
        formik.setFieldValue("manufacturingYear", moment(date).startOf("year").toISOString());
    }
  }, [formik.values.registerDate]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleNumber" label="Vehicle No. (Logcard)"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField
              formik={formik} name="makeId" label="Vehicle Make (Logcard)"
              items={makes?.map(make => ({ label: make.name, value: make.id }))?.sort(sortLabels)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField
              formik={formik} name="modelId" label="Vehicle Model (Logcard)"
              items={
                models?.filter(m => m.makeId === formik.values.makeId)
                      ?.map(model => ({
                        label: `${model.name}, ${model.petrolType}`,
                        value: model.id
                      }))
                      ?.sort(sortLabels)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="registerDate" label="Registration Date (Logcard)"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="origRegisterDate" label="Original Registration Date (Logcard)"/>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Vehicle Specifications</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="manufacturingYear" label="Manufacturing Year (Logcard)" defaultView="year"/>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Inspection Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="inspectionDueDate" label="Inspection Due Date"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcDateField formik={formik} name="roadTaxDueDate" label="Road Tax Due Date"/>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Vehicle Appearance</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcTextField formik={formik} name="vehicleColor" label="Vehicle Color (Logcard)"/>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FcSelectField formik={formik} name="vehicleShape" label="Vehicle Shape" items={vehicleShapes}/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
        </Grid>
        <SubmitBtnGroup method={method} formik={formik} onCancel={onCancel}/>
      </Form>
    </FormikProvider>
  );
}

VehicleForm.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
}