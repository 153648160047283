import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { SubmitBtnGroup } from '../FormFields';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import DataContext from '../../../store/DataContext';
import FcWebsitePromotionForm from './FcWebsitePromotionForm';

export default function CreateFcWebsitePromotionForm({ onClose, onReload }) {
  const dataCtx = useContext(DataContext);
  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/fcwebsite/promotion/create`);
  const onCreate = async (values) => {
    await onPost({
      ...values, description: encodeURIComponent(values.description)
    });
    await onReload();
    onOpenSnackbar('Promotion created successfully');
    onClose();

  };

  const formik = useFormik({
    initialValues: {
      title: '', description: '', imgUrl: '', mobileImgUrl: '', isActive: true
    }, validationSchema: Yup.object({
      title: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Title is required'),
      description: Yup.string().required('Description is required'),
      imgUrl: Yup.string().required('Image is required'),
      mobileImgUrl: Yup.string().required('Image is required')
    }), onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onCreate(values);
      setSubmitting(false);
    }
  });
  return (<FormikProvider value={formik}>
    <Form autoComplete="off">
      <FcWebsitePromotionForm formik={formik} />
      <SubmitBtnGroup formik={formik} method="Create" onCancel={onClose} />
    </Form>
  </FormikProvider>);
}

CreateFcWebsitePromotionForm.propTypes = { onClose: PropTypes.func, onReload: PropTypes.func };