import {Box} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import {defaultTimezone} from "../../../utils/date";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function InvoiceItemCollectionTableForm({ data, handleApplyCommit }) {
  const columns = [
    { field: "invoiceNo", headerName: "Invoice No.", align: "center", headerAlign: "center", width: 200 },
    {
      field: "description", headerName: "Description", headerAlign: "center", width: 600,
      renderCell: ({ row }) => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>
          {row.description}
        </Box>
      )
    },
    {
      field: "issueDate", headerName: "Issue Date", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => dayjs(row.issueDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY")
    },
    {
      field: "amount", headerName: "Invoice Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => `$${row.amount}`
    },
    {
      field: "amountPaid", headerName: "Amount Paid", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => `$${row.amountPaid}`
    },
    {
      field: "outstanding", headerName: "Outstanding", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => `$${row.outstanding}`
    },
    { field: "amountApplied", headerName: "Amount Applied", align: "center", headerAlign: "center", width: 150, editable: true },
  ];

  return (
    <StyledDataGrid
      rows={data ?? []} columns={columns}
      pageSize={100} rowsPerPageOptions={[100]}
      autoHeight disableSelectionOnClick
      onCellEditCommit={handleApplyCommit}
    />
  );
}

InvoiceItemCollectionTableForm.propTypes = {
  data: PropTypes.array,
  handleApplyCommit: PropTypes.func,
}