import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import { FcAutocomplete, FcDateField, FcTextField, SubmitBtnGroup } from '../FormFields';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import DataContext from '../../../store/DataContext';

export default function WorkshopVehicleForm({ data, onCancel }) {
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();
  const method = data ? "Update" : "Create"
  const salesPerson = ['No Sales Person', 'Kim', 'Chee Seng', 'Leslie Lye', 'Jun Jie', 'Alvin', 'Jammuel', 'If salesPerson not listed here, please contact tech! Do not choose this value'];

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/vehicle`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/workshop/vehicle/${data?.vehicleNumber}`);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity })
  }

  const onCreate = async (values) => {
    await onPost(values);
    onOpenSnackbar("Vehicle Created Successfully");
  }

  const onUpdate = async (values) => {
    await onPut(values);
    onOpenSnackbar("Vehicle Updated Successfully");
  }

  const formik = useFormik({
    initialValues: {
      vehicleNumber: "",
      make: "",
      model: "",
      color: "",
      fuel: "",
      registrationDate: null,
      coeExpiryDate: null,
      salesPerson: null,
    },
    validationSchema: Yup.object({
      vehicleNumber: Yup.string().required("Please fill in Vehicle Number"),
      make: Yup.string().required("Please fill in Vehicle Make"),
      model: Yup.string().required("Please fill in Vehicle Model"),
      color: Yup.string().required("Please select Vehicle Colour"),
      fuel: Yup.string().required("Please fill in Vehicle Fuel"),
      registrationDate: Yup.date().required("Please fill in Registration Date").nullable(false),
      coeExpiryDate: Yup.date().required("Please fill in COE Expiry Date").nullable(false),
      salesPerson: Yup.string().required("Please fill in SalesPerson").nullable(false)
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        values.salesPerson = values.salesPerson === "No Sales Person" ? "" : values.salesPerson;
        if (method === "Create") {
          await onCreate(values);
        } else {
          await onUpdate(values);
        }
        setTimeout(() => navigate("../"), 1000);
      } catch (error) {
        console.log(error.message);
        onOpenSnackbar(JSON.parse(error.message).message, "error");
      }
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("vehicleNumber", data?.vehicleNumber);
      formik.setFieldValue("make", data?.make);
      formik.setFieldValue("model", data?.model);
      formik.setFieldValue("color", data?.color);
      formik.setFieldValue("fuel", data?.fuel);
      formik.setFieldValue("registrationDate", dayjs(data?.registrationDate).format("YYYY-MM-DD"));
      formik.setFieldValue("coeExpiryDate", dayjs(data?.coeExpiryDate).format("YYYY-MM-DD"));
      formik.setFieldValue("salesPerson", data?.salesPerson);
    }
  }, [data])

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={1.5} mb={3}>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="vehicleNumber" label="Vehicle Number" />
          </Grid>
          <Grid item xs={6}>
            <FcTextField formik={formik} name="make" label="Make" />
          </Grid>
          <Grid item xs={6}>
            <FcTextField formik={formik} name="model" label="Model" />
          </Grid>
          <Grid item xs={6}>
            <FcTextField formik={formik} name="color" label="Color" />
          </Grid>
          <Grid item xs={6}>
            <FcTextField formik={formik} name="fuel" label="Fuel" />
          </Grid>
          <Grid item xs={6}>
            <FcDateField formik={formik} name="registrationDate" label="Registration Date" />
          </Grid>
          <Grid item xs={6}>
            <FcDateField formik={formik} name="coeExpiryDate" label="COE Expiry Date" />
          </Grid>
          <Grid item xs={12}>
            <FcAutocomplete
              formik={formik}
              name="salesPerson"
              label="Sales Person"
              options={salesPerson}
            />
          </Grid>
        </Grid>
        <SubmitBtnGroup method={method} formik={formik} onCancel={onCancel} />
      </Form>
    </FormikProvider>
  );
}

WorkshopVehicleForm.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func
}