import { Container, Stack, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import Page from '../components/Page';
import ChangePasswordForm from '../components/Form/User/ChangePasswordForm';

export default function ChangePassword() {
  const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onOpenSnackbar = (message, type = 'success') => {
    setOpenSnackbar(true);
    setSnackbarInfo({ message, type });
  };

  return (
    <Page title="Change Password">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Change Password
          </Typography>
        </Stack>
        <ChangePasswordForm onOpenSnackbar={onOpenSnackbar} />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarInfo.type}
            sx={{ width: '100%' }}
          >
            {snackbarInfo.message}
          </Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
