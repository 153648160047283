import {Box, Stack, Typography} from "@mui/material";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import ShellDiscountForm from "../../../components/Form/ShellCard/ShellDiscountForm";
import AppUserDiscountTable from "../../../components/Table/User/AppUserDiscountTable";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";

export default function ShellCardDiscountTab() {
  const { data, loading, onGet } = useCloudFuncGet(`${cloudFunctionPath}/wallet/card/discount/default`);
  const { data: userData, onGet: onUserGet } = useCloudFuncGet(`${cloudFunctionPath}/user`);
  const users = userData?.data ?? [];

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Default Discount</Typography>
      </Stack>

      <Box mb={3}>
        <ShellDiscountForm data={data} onReload={onGet}/>
      </Box>

      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">User Discount</Typography>
      </Stack>

      <Box mb={3}>
        <AppUserDiscountTable data={users} loading={loading} onReload={onUserGet}/>
      </Box>
    </>
  );
}