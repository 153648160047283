import {LoadingButton} from "@material-ui/lab";
import {Button, Dialog, DialogActions, DialogTitle, Stack, Checkbox, DialogContent} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // required for timezone plugin
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpPut from "../../../hooks/http/useHttpPut";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import {COLLECTION_PERMISSIONS} from "../../../utils/authorization/permissions/invoicePermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import {defaultTimezone} from "../../../utils/date";
import {getFile} from "../../../utils/getFile";
import AccountCollectionForm from "../../Form/Collection/AccountCollectionForm";

dayjs.extend(utc);
dayjs.extend(timezone);

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onDelete, loading } = useHttpDelete(`/api/collection/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Collection deleted successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Do you want to delete this collection?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const VoidAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading } = useHttpPut(`/api/collection/${row?.id}/void`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onPut();
      await onReload();
      setOpen(false);
      dataCtx.setSnackbarConfig({ open: true, message: "Collection voided successfully!", severity: "success" });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Do you want to void this collection?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Void</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)} disabled={row.status === "Voided"}>Void</Button>
    </>
  );
}

VoidAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const FinalizeAction = ({ row, booking, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>Finalize Collection</DialogTitle>
        <DialogContent>
          <AccountCollectionForm data={row} booking={booking} onReload={onReload} onClose={() => setOpen(false)} isFinalized/>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Finalize</Button>
    </>
  );
}

FinalizeAction.propTypes = {
  row: PropTypes.object,
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, booking, onReload }) => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack direction="row" spacing={1}>
      {!row.isFinalized && <FinalizeAction row={row} booking={booking} onReload={onReload}/>}
      {row.isFinalized && <Button component={Link} to={getFile(row?.receiptAttachment)} target="_blank" variant="contained">Print</Button>}
      {permissionRequired(authCtx, COLLECTION_PERMISSIONS.DELETE) && <DeleteAction row={row} onReload={onReload}/>}
      {permissionRequired(authCtx, COLLECTION_PERMISSIONS.VOID) && <VoidAction row={row} onReload={onReload}/>}
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

export default function AccountCollectionTable({ data, booking, onReload, type = "collection" }) {
  const creditTypes = ["credit-note", "refund-credit"];

  const columns = [
    { field: "receiptNo", headerName: "Receipt No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "status", headerName: "Status", align: "center", headerAlign: "center", width: 150, sortable: false },
    { field: "bookingNo", headerName: "Booking No.", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "collectDate", headerName: creditTypes.includes(type) ? "Credit Date" : "Collect Date", align: "center", headerAlign: "center", width: 175,
      renderCell: ({ row }) => dayjs(row.collectDate).utc(true).tz(defaultTimezone).format("DD MMM YYYY")
    },
    { field: "paymentMethod", headerName: "Payment Method", align: "center", headerAlign: "center", width: 175, sortable: false },
    { field: "vehicleCompanyUen", headerName: creditTypes.includes(type) ? "Credit To" : "Pay To", align: "center", headerAlign: "center", width: 150, sortable: false },
    {
      field: "amount", headerName: "Amount", align: "center", headerAlign: "center", width: 115, sortable: false,
      renderCell: ({ row }) => `$${row.amount}`
    },
    {
      field: "isFinalized", headerName: "Finalized", align: "center", headerAlign: "center", width: 100, sortable: false,
      renderCell: ({ row }) => <Checkbox readOnly checked={row.isFinalized}/>
    },
    {
      field: "isVerified", headerName: "Verified", align: "center", headerAlign: "center", width: 100, sortable: false,
      renderCell: ({ row }) => <Checkbox checked={row.isVerified}/>
    },
    { field: "remarks", headerName: "Remarks", align: "center", headerAlign: "center", width: 275, sortable: false },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center", width: 300, sortable: false,
      renderCell: ({ row }) => <ActionCol row={row} booking={booking} onReload={onReload}/>
    },
  ];

  return (
    <StyledDataGrid
      rows={data ?? []} columns={columns} pageSize={10}
      disableSelectionOnClick autoHeight
    />
  );
}

AccountCollectionTable.propTypes = {
  data: PropTypes.array,
  booking: PropTypes.object,
  onReload: PropTypes.func,
  type: PropTypes.string,
}