import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";

export default function DepositQuestionForm({ formik }) {
  const isPostpaid = formik.values.paymentMethod === "Postpaid";

  const [hasDeposit, setHasDeposit] = useState(formik.values.deposit > 0 || isPostpaid ? "yes" : "no");

  useEffect(() => {
    if (hasDeposit === "no") formik.setFieldValue("deposit", 0);
  }, [hasDeposit]);

  const deposits = [50, 200, 500, 600, 700, 800, 1000];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Is deposit required?</Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup row value={hasDeposit} onChange={e => setHasDeposit(e.target.value)}>
          <FormControlLabel control={<Radio disabled={isPostpaid} />} label="Yes" value="yes"/>
          <FormControlLabel control={<Radio disabled={isPostpaid} />} label="No" value="no"/>
        </RadioGroup>
      </Grid>
      {hasDeposit === "yes" && (
        <Grid item xs={12}>
          <RadioGroup
            name="deposit"
            value={String(formik.values.deposit)}
            onChange={e => formik.setFieldValue("deposit", Number(e.target.value))}
          >
            {deposits.map((deposit, index) => (
              <FormControlLabel key={index} control={<Radio />} label={`$${deposit}`} value={deposit}/>
            ))}
          </RadioGroup>
        </Grid>
      )}
    </Grid>
  );
}

DepositQuestionForm.propTypes = {
  formik: PropTypes.object,
}