import PropTypes from "prop-types";
import { Box, Button, DialogActions, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@material-ui/lab';
import FcTable from '../FcTable';
import { getPdf } from '../../../utils/getFile';
import LogcardDialogDetails from '../../Details/Vehicle/LogcardDialogDetails';
import useHttpGet from '../../../hooks/http/useHttpGet';
import VehicleLogcardForm from '../../Form/Vehicle/VehicleLogcardForm';
import InsuranceDialogDetails from '../../Details/Vehicle/InsuranceDialogDetails';
import VehicleInsuranceForm from '../../Form/Vehicle/VehicleInsuranceForm';
import useHttpDelete from '../../../hooks/http/useHttpDelete';
import DataContext from '../../../store/DataContext';

function LogcardActionCol({ row, owners, changeOriginalData }) {
  const { data, onGet } = useHttpGet(`/api/vehicles/${row.id}`, false);

  // What I am about to attempt to do here can only be described as arcane sorcery
  // This is incredibly complicated and I hope I do not have to deal with this again
  // It works and let's just leave it there

  // So that this doesn't re-fetch the whole data when only one row is affected
  const [rowData, setRowData] = useState(row);

  useEffect(() => {
    // Should only execute when user filter or change page
    // FcTable reuse the same component when changing page
    // When row changes set row to rowData
    // Else the data remains unchanged after changing table page
    if (row.id !== data?.id) {
      setRowData(row);
      return;
    }

    // Should only execute when user update an item
    // Need to change original data because when user search, the old data is used and rowData is lost
    if (data !== null) {
      setRowData(data);
      changeOriginalData(row.index, data, () => {});
    }
  }, [data, row]);

  if (rowData?.logcard !== null) {
    rowData.logcard.vehicle = {
      vehicleNumber: rowData.vehicleNumber
    }
  }

  const [openView, setOpenView] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <Dialog open={openView} onClose={() => setOpenView(false)} maxWidth="lg">
        <Box p={2}>
          <DialogTitle>Logcard Info</DialogTitle>
          <DialogContent>
            <LogcardDialogDetails row={rowData.logcard} />
          </DialogContent>
        </Box>
      </Dialog>

      <Dialog open={openForm} onClose={() => setOpenForm(false)} maxWidth="lg">
        <Box sx={{ p: 2 }}>
          <DialogTitle>{row ? "Edit" : "Create"} Logcard</DialogTitle>
          <DialogContent>
            <VehicleLogcardForm
              vehicleId={row.id} data={rowData?.logcard} onReload={onGet}
              owners={owners} onClose={() => setOpenForm(false)}
            />
          </DialogContent>
        </Box>
      </Dialog>

      <Stack direction="row" spacing={2} justifyContent="center">
        {
          rowData.logcard != null
            ? <>
              <Button variant="contained" onClick={() => setOpenView(true)}>View</Button>
              <Button variant="contained" onClick={() => setOpenForm(true)}>Edit</Button>
              <Button variant="contained" color="secondary" disabled={rowData?.logcard?.logcard === null}
                      component={Link} to={getPdf(rowData?.logcard?.logcard)} target="_blank"
              >
                PDF
              </Button>
            </>
            : <Button variant="contained" onClick={() => setOpenForm(true)}>Create</Button>
        }
      </Stack>
    </>
  );
}

LogcardActionCol.propTypes = {
  row: PropTypes.object,
  owners: PropTypes.array,
  changeOriginalData: PropTypes.func
}

function InsuranceActionCol({ row, companies, changeOriginalData }) {
  const dataCtx = useContext(DataContext);
  const { data, onGet } = useHttpGet(`/api/vehicles/${row.id}`, false);
  const { onDelete, loading } = useHttpDelete(`/api/insurances/${row.insurance?.id}`);

  // Same as logcard
  const [rowData, setRowData] = useState(row);

  // Same as logcard
  useEffect(() => {
    if (row.id !== data?.id) {
      setRowData(row);
      return;
    }

    if (data !== null) {
      setRowData(data);
      changeOriginalData(row.index, data, () => {});
    }
  }, [data, row]);

  const [openView, setOpenView] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const onClickDelete = async e => {
    try {
      await onDelete();
      onOpenSnackbar("Insurance deleted successfully!");
      onGet();
      setOpenDeleteWarning(false);
    } catch (error) {
      onOpenSnackbar(e.message, "error");
    }
  }

  return (
    <>
      <Dialog open={openView} onClose={() => setOpenView(false)} maxWidth="lg">
        <Box p={2}>
          <DialogTitle>Insurance Info</DialogTitle>
          <DialogContent>
            <InsuranceDialogDetails row={rowData.insurance} />
          </DialogContent>
        </Box>
      </Dialog>

      <Dialog open={openForm} onClose={() => setOpenForm(false)} maxWidth="lg">
        <Box p={2}>
          <DialogTitle>{row ? "Edit" : "Create"} Insurance</DialogTitle>
          <DialogContent>
            <VehicleInsuranceForm
              vehicleId={rowData.id} data={rowData.insurance} onReload={onGet}
              companies={companies} onClose={() => setOpenForm(false)}
            />
          </DialogContent>
        </Box>
      </Dialog>

      <Dialog open={openDeleteWarning} onClose={() => setOpenDeleteWarning(false)}>
        <Box sx={{ p: 2 }}>
          <DialogTitle>Do you want to delete insurance?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpenDeleteWarning(false)} type="submit" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              variant="contained" color="error"
              loading={loading} onClick={onClickDelete}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Stack direction="row" spacing={2} justifyContent="center">
        {
          rowData.insurance != null
            ? <>
              <Button variant="contained" onClick={() => setOpenView(true)}>View</Button>
              <Button variant="contained" onClick={() => setOpenForm(true)}>Edit</Button>
              <Button variant="contained" color="secondary"
                      component={Link} to={getPdf(rowData?.insurance?.insuranceCi)} target="_blank"
              >
                PDF
              </Button>
              <Button variant="contained" color="error" onClick={() => setOpenDeleteWarning(true)}>Delete</Button>
            </>
            :   <Button variant="contained" onClick={() => setOpenForm(true)}>Create</Button>
        }
      </Stack>
    </>
  );
}

InsuranceActionCol.propTypes = {
  row: PropTypes.object,
  companies: PropTypes.array,
  changeOriginalData: PropTypes.func
}

export default function BulkUpsertVehicleTable({ data, changeOriginalData }) {
  const { data: owners } = useHttpGet("/api/vehicles/owner")
  const { data: companies } = useHttpGet("/api/insurances/company")

  const columns = [
    {
      field: 'vehicleNumber', headerName: 'Vehicle No.',
      align: 'center', headerAlign: 'center',
      minWidth: 125, sortable: false, sticky: true,
    },
    {
      field: 'logcard', headerName: 'Logcard',
      align: 'center', headerAlign: 'center',
      minWidth: 250, sortable: false, sticky: true,
      renderCell: ({row}) => <LogcardActionCol row={row} owners={owners} changeOriginalData={changeOriginalData} />
    },
    {
      field: 'insurance', headerName: 'Insurance',
      align: 'center', headerAlign: 'center',
      minWidth: 250, sortable: false, sticky: true,
      renderCell: ({row}) => <InsuranceActionCol row={row} companies={companies} changeOriginalData={changeOriginalData} />
    }
  ];

  return (
    <FcTable rows={ data ?? [] } columns={ columns } />
  );
}

BulkUpsertVehicleTable.propTypes = {
  data: PropTypes.array,
  changeOriginalData: PropTypes.func
}