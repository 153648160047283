import plusFill from "@iconify/icons-eva/plus-fill";
import {Icon} from "@iconify/react";
import {Box, Button, Container, Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import InsuranceCompanyForm from "../../../components/Form/Vehicle/InsuranceCompanyForm";
import InsuranceCompanyTable from "../../../components/Table/Vehicle/InsuranceCompanyTable";
import useHttpGet from "../../../hooks/http/useHttpGet";

const AddAction = ({ onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Create Insurance Company</DialogTitle>
        <DialogContent>
          <Box py={1}>
            <InsuranceCompanyForm onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button
        variant="contained" startIcon={<Icon icon={plusFill}/>}
        onClick={() => setOpen(true)}
      >
        Add Company
      </Button>
    </>
  );
}

AddAction.propTypes = {
  onReload: PropTypes.func,
}

export default function InsuranceCompanyList() {
  const { data, onGet } = useHttpGet("/api/insurances/company");

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">Insurance Companies Listing</Typography>
        <AddAction onReload={onGet}/>
      </Stack>
      <InsuranceCompanyTable data={data ?? []} onReload={onGet}/>
    </>
  );
}