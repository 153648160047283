import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { collection, doc, getDoc, getDocs, getFirestore, query } from 'firebase/firestore';
import { sub } from 'date-fns';

import AuthContext from './AuthContext';
import { fetchEnhcApi } from '../utils/fetchApi';
import FcLoader from '../components/FcLoader';

const DataContext = React.createContext({
  adminSettings: {},
  gstRateList: [],
  appUpdates: [],
  appUsersCount: 0,
  carModelsCount: 0,
  setAdminSettings: () => {},
  bannersData: [],
  carModels: [],
  paymentTypes: [],
  notificationsData: null,
  snackbarConfig: {
    open: false,
    message: '',
    severity: 'success'
  },
  departments: {},
  setSnackbarConfig: () => {},
  fetchCarModels: () => {},
  setGstRateList: () => {}
});

export const DataContextProvider = (props) => {
  const authCtx = useContext(AuthContext);
  const db = getFirestore();
  const [appUsersCount, setAppUsersCount] = useState(0);
  const [appUpdates, setAppUpdates] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [carModelsCount, setCarModelsCount] = useState(0);
  const paymentTypes = useMemo(() => [], []);
  const [adminSettings, setAdminSettings] = useState({});
  const [gstRateList, setGstRateList] = useState([]);
  const [departments, setDepartments] = useState({});
  const [loading, setLoading] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchCarModels = useCallback(async () => {
    const docSnap = await getDoc(doc(db, 'fcadmin', 'models'));
    const carModelUnmapped = docSnap.data();
    const carModels = [];
    Object.keys(carModelUnmapped).forEach((el) => {
      carModels.push({
        carBodyStyle: carModelUnmapped[el].carBodyStyle,
        engineCapacity: carModelUnmapped[el].engineCapacity,
        favourites: carModelUnmapped[el].favourites,
        gen: carModelUnmapped[el].gen,
        id: el,
        imageLinks: carModelUnmapped[el].imageLinks,
        make: carModelUnmapped[el].make,
        model: carModelUnmapped[el].model,
        seater: carModelUnmapped[el].seater,
        propellant: carModelUnmapped[el].propellant,
        propertyBadges: carModelUnmapped[el].propertyBadges,
        rentalRates: carModelUnmapped[el].rentalRates,
        // rentalRate: 2,
        rentalUse: carModelUnmapped[el].rentalUse,
        transmission: carModelUnmapped[el].transmission,
        type: carModelUnmapped[el].type,
        tags: carModelUnmapped[el].tags,
        mfgYear: carModelUnmapped[el].mfgYear
      });
    });

    setCarModels(carModels);
  }, [db]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      const fetchAdminSettings = async () => {
        setLoading(true);
        const val = await fetchEnhcApi('GetPaymentTypes', {});
        if (val) paymentTypes.push(...val.map((el) => el.PaymentType));

        const q = doc(db, 'fcadmin', 'fcadmin');
        const querySnapshot = await getDoc(q);
        setAdminSettings(querySnapshot.data().adminSettings);
        setGstRateList(querySnapshot.data().gstRateList);
        setDepartments(querySnapshot.data().departments);

        setLoading(false);

        const docSnap = getDoc(doc(db, 'fcadmin/models'));
        const docSnapUsers = getDocs(query(collection(db, 'users')));

        const x = await Promise.all([docSnap, docSnapUsers]);

        const appUpdatesList = querySnapshot
          .data()
          .appUpdates.map((el) => ({
            ...el,
            id: sub(el.createdAt.toDate(), { days: 0, hours: 0, minutes: 0 }),
            createdAt: sub(el.createdAt.toDate(), {
              days: 0,
              hours: 0,
              minutes: 0
            }),
            title: `${el.title} ${el.update}`,
            avatar: null,
            type: 'chat_message',
            isUnRead: true
          }))
          .sort((a, b) => b.update - a.update);

        setCarModelsCount(Object.keys(x[0].data()).length);
        setAppUsersCount(x[1].docs.length);
        setAppUpdates(appUpdatesList);
      };
      fetchAdminSettings();
    }
  }, [authCtx.isLoggedIn, db, paymentTypes, setAdminSettings]);

  const contextValue = useMemo(
    () => ({
      adminSettings,
      appUpdates,
      appUsersCount,
      carModelsCount,
      setAdminSettings,
      bannersData: [],
      carModels,
      paymentTypes,
      fsObject: db,
      fetchCarModels,
      snackbarConfig,
      setSnackbarConfig,
      departments,
      gstRateList,
      setGstRateList
    }),
    [
      appUpdates,
      appUsersCount,
      carModels,
      carModelsCount,
      db,
      paymentTypes,
      adminSettings,
      fetchCarModels,
      snackbarConfig,
      setSnackbarConfig,
      departments,
      gstRateList,
      setGstRateList
    ]
  );

  DataContextProvider.propTypes = {
    children: PropTypes.node
  };

  return loading ? (
    <FcLoader />
  ) : (
    <DataContext.Provider value={contextValue}>{props.children}</DataContext.Provider>
  );
};

export default DataContext;
