import * as Yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Chip, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import dayjs from 'dayjs';
import PropTypes from "prop-types"
import DataContext from '../../../store/DataContext';
import authContext from '../../../store/AuthContext';
import { FcAutocomplete, FcDateField, FcTextField, SubmitBtnGroup } from '../FormFields';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import WorkshopVehicleDetails from '../../Details/Workshop/WorkshopVehicleDetails';

function ExpiryWarning() {
  return (
    <Alert severity="warning">This car expiry date is in less than 3 month</Alert>
  );
}

export default function WorkshopRequestForm({ data }) {
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();
  const auth = useContext(authContext);

  const task = useCloudFuncGet(`${cloudFunctionPath}/workshop/task`);
  const vehicle = useCloudFuncGet(`${cloudFunctionPath}/workshop/vehicle`);

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/request`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/workshop/request/${data?.id}`);

  const method = data ? "Update" : "Create"

  const [warning, setWarning] = useState(false);

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const formik = useFormik({
    initialValues: {
      vehicle: null,
      assignedDate: null,
      task: [],
      other: "",
      assignerRemark: "",
    },
    validationSchema: Yup.object({
      vehicle: Yup.object().required("Please fill in Vehicle Number").nullable("never"),
      assignedDate: Yup.date().required("Please fill in the Assigned Date"),
      task: Yup.array().required("Please fill in a task").min(1, "Please fill in at least a task"),
      other: Yup.string(),
      assignerRemark: Yup.string()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const uploadData = {
          assigner: data?.name ?? auth.name,
          vehicleNumber: formik.values.vehicle.vehicleNumber,
          assignedDate: new Date(formik.values.assignedDate).getTime(),
          task: formik.values.task,
          other: formik.values.other,
          assignerRemark: formik.values.assignerRemark,
          cost: formik.values.task.reduce((x, y) => x + y.cost, 0),
          createdAt: data === undefined ? (new Date().getTime()) : data.createdAt,
          updatedAt: new Date().getTime(),

          remark: "",
          completed: false,
          completedAt: 0
        };

        if (method === "Create") {
          await onPost(uploadData);
          onOpenSnackbar(`Successfully Created Job`);
          setTimeout(() => navigate("../"), 1000);
        } else {
          await onPut(uploadData)
          onOpenSnackbar(`Successfully Updated Job`);
          setTimeout(() => navigate(-1), 1000);
        }
      } catch (error) {
        console.log(error.message);
        onOpenSnackbar(error.message, "error");
      }
      setSubmitting(false);
    }
  })

  useEffect(() => {
    if (data && vehicle.data.length !== 0 && task.data.length !== 0) {
      formik.setFieldValue("vehicle", vehicle.data.find((item) => item.vehicleNumber === data.vehicleNumber));
      formik.setFieldValue("assignedDate", dayjs(data.assignedDate).format("YYYY-MM-DD"));
      formik.setFieldValue("task", data.task);
      formik.setFieldValue("other", data.other);
      // ?? is compulsory because older data does not include remark field
      formik.setFieldValue("assignerRemark", data.assignerRemark ?? "")
    }
  }, [data, vehicle.data.length, task.data.length]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={6}>
              <FcAutocomplete
                formik={formik}
                name="vehicle"
                label="Vehicle Number | 车牌"
                options={vehicle.data}
                getOptionLabel={(option) => option.vehicleNumber}
                onChange={(value) =>
                  dayjs(value?.coeExpiryDate ?? Number.MAX_VALUE).unix() - dayjs().unix() < 90 * 86400 ? setWarning(true) : setWarning(false)
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FcDateField formik={formik} name="assignedDate" label="Assigned Date" />
            </Grid>

            { warning && <Grid item xs={12}><ExpiryWarning /></Grid> }

            <Grid item xs={12}>
              <WorkshopVehicleDetails vehicle={formik.values.vehicle} />
            </Grid>

            <Grid item xs={12}>
              <FcAutocomplete
                formik={formik}
                name="task"
                label="Task | 任务"
                multiple
                options={task.data}
                getOptionLabel={(option) => `${option.name} | ${option.chineseName}`}
                renderTags={(values, getTagProps) =>
                  values.map((option, index) => (
                    <Chip
                      key={index}
                      label={
                        <Typography sx={{ fontSize: 18, whiteSpace: "pre-line" }}>{`${option.name}\n${option.chineseName}`}</Typography>
                      }
                      { ...getTagProps({ index }) }
                      style={{ height: "100%" }}
                    />
                  ))
                }
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name &&
                  option.cost === value.cost &&
                  option.chineseName === value.chineseName
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FcTextField formik={formik} name="other" label="Other | 其他" sx={{ marginTop: 1.5 }} />
            </Grid>

            <Grid item xs={12}>
              <FcTextField formik={formik} name="assignerRemark" label="Remarks | 备注" sx={{ marginTop: 1.5 }} />
            </Grid>

          </Grid>

          <SubmitBtnGroup formik={formik} method={method} onCancel={() => navigate("../")} />

        </Form>
      </FormikProvider>
    </>
  );
}

WorkshopRequestForm.propTypes = {
  data: PropTypes.object
}