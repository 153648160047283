import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Container, Tab } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import useHttpGet from "../../../hooks/http/useHttpGet";
import CollectionTab from "./Tabs/CollectionTab";
import CreditNoteTab from "./Tabs/CreditNoteTab";
import InvoiceTab from "./Tabs/InvoiceTab";
import RecurringInvoiceTab from "./Tabs/RecurringInvoiceTab";
import RefundVoucherTab from "./Tabs/RefundVoucherTab";

export default function TransactionsListing() {
  const [tabValue, setTabValue] = useState("invoice");

  const { data: refundVouchers, onGet: onRefundGet } = useHttpGet("/api/refund-voucher", true, {
    startDate: dayjs().add(-1, "day").startOf("day"),
    endDate: dayjs().startOf("day"),
  });
  const { data: recurringInvoices, onGet: onRecurringInvoiceGet } = useHttpGet("/api/invoice/recurring");

  return (
    <Container maxWidth="xl">
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label="Invoice" value="invoice" />
            <Tab label="Collection" value="collection" />
            <Tab label="Credit Note" value="credit-note" />
            <Tab label="Refund Voucher" value="refund-voucher" />
            <Tab label="Recurring Invoice" value="recurring-invoice" />
          </TabList>
        </Box>

        <TabPanel value="invoice">
          <InvoiceTab />
        </TabPanel>
        <TabPanel value="collection">
          <CollectionTab />
        </TabPanel>
        <TabPanel value="credit-note">
          <CreditNoteTab />
        </TabPanel>
        <TabPanel value="refund-voucher">
          <RefundVoucherTab refundVouchers={refundVouchers} onReload={onRefundGet} />
        </TabPanel>
        <TabPanel value="recurring-invoice">
          <RecurringInvoiceTab recurringInvoices={recurringInvoices} onReload={onRecurringInvoiceGet} />
        </TabPanel>
      </TabContext>
    </Container>
  );
}