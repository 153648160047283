import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import SortIcon from '@mui/icons-material/Sort';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { deleteObject, getStorage, ref } from 'firebase/storage';

import FirestoreContext from '../../store/FirestoreContext';
import PromoCard from '../../components/PromoCard';
import { PopupModal } from '../../components/PopupModal';
import NewHotPromo from './FcNewHotPromo';

const FcAppPromotions = () => {
  const fsCtx = useContext(FirestoreContext);
  const [promoList, setPromoList] = useState([]);
  const [promoSortDescending, setPromoSortDescending] = useState(true);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    let isMounted = true;

    getDocs(collection(fsCtx.fsObject, 'fcadmin/fcadmin/hotPromos'))
      .then((qSnap) => {
        if (isMounted) setPromoList(qSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
      .catch((error) => {
        const errorStr = `Failed to fetch promos: ${error}`;
        console.error(errorStr);
        alert(errorStr);
      });

    return () => {
      isMounted = false;
    };
  }, [fsCtx.fsObject]);

  // Modal logic
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState();
  const handleOpen = (component) => {
    setOpen(true);
    setModalInfo(component);
  };

  const handleClose = () => {
    setOpen(false);
    setModalInfo();
  };

  const deletePromoHandler = async (promoData) => {
    if (!window.confirm('Are you sure you want to delete this hot promo?')) {
      return;
    }
    try {
      const promoImgUrl = new URL(promoData.imageUrl);
      const pathName = decodeURIComponent(promoImgUrl.pathname.split('/').slice(-1)[0]);

      const imgRef = ref(getStorage(), pathName);
      await Promise.all([
        deleteObject(imgRef),
        deleteDoc(doc(fsCtx.fsObject, 'fcadmin/fcadmin/hotPromos', promoData.id))
      ]);
      setPromoList((oldPromos) => oldPromos.filter((el) => el.id !== promoData.id));
    } catch (error) {
      console.log(`Error: ${error}\n${error.stack}`);
      alert(`Unable to delete: ${error}`);
    }
  };

  return (
    <Container maxWidth="xl">
      <PopupModal
        openState={open}
        closeHandler={handleClose}
        modalBody={modalInfo}
        closeConfirmation
      />
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            handleOpen(<NewHotPromo />);
          }}
          variant="contained"
        >
          New Promo
        </Button>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            id="input-with-icon-textfield"
            label="Search by Title"
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            variant="standard"
          />
          <Button
            disableRipple
            color="inherit"
            endIcon={<SortIcon />}
            onClick={() => {
              setPromoList((old) =>
                old.sort(
                  (a, b) => (promoSortDescending ? 1 : -1) * (a.date.toDate() - b.date.toDate())
                )
              );
              setPromoSortDescending((old) => !old);
            }}
          >
            Sort - {promoSortDescending ? 'Descending' : 'Ascending'}&nbsp;
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        {promoList
          .filter((el) => el.title.toLowerCase().search(searchVal.toLowerCase()) >= 0)
          .map((el) => (
            <Grid item key={el.id} xs={12} sm={6} md={3}>
              <PromoCard
                imgUrl={el.imageUrl}
                title={el.title}
                link={el.link}
                date={el.date}
                cardAction={() => {
                  handleOpen(<NewHotPromo editMode promoData={el} />);
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => {
                    deletePromoHandler(el);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </PromoCard>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

export default FcAppPromotions;
