export default function coConverter(co) {
  switch (co) {
    case 'Fresh Cars Pte Ltd':
      return 'FC';
    case 'Orange Cars':
      return 'OC';
    case 'Renty Pte Ltd':
      return 'RT';
    case 'Orange Express':
      return 'OE';
    default:
      return 'nocompany';
  }
}

export function coConverterToLong(co) {
  switch (co) {
    case 'FC':
      return 'Fresh Cars Pte Ltd';
    case 'OC':
      return 'Orange Cars';
    case 'RT':
      return 'Renty Pte Ltd';
    case 'OE':
      return 'Orange Express';
    default:
      return 'nocompany';
  }
}
