import Box from '@mui/material/Box';
import { Grid, TextField } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

import { FcTextArea, FcTextField, SubmitBtnGroup } from '../../FormFields';
import DataContext from '../../../../store/DataContext';
import useCloudFuncPut from '../../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { cloudFunctionPath } from '../../../../cloudFunctionApiConfig';

export default function ProposePriceIncreaseForm({ onClose, onReload, row }) {
  const dataCtx = useContext(DataContext);

  const onOpenSnackbar = (message, severity = 'success') => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  };

  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/otr/proposePriceIncrease`);
  const onPropose = async (values) => {
    try {
      const data = {
        ...values,
        priceIncreaseId: row.docId,
        rentalAgreementNo: row.rentalAgreementNo
      };
      await onPut(data);
      await onReload();
      onOpenSnackbar('OTR proposed successfully!');
      onClose();
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      remarks: '',
      points: row?.points,
      rentalRate: '',
      baseRentalRate: '',
      priceIncrease: ''
    },
    validationSchema: Yup.object({
      remarks: Yup.string().required('Please fill in Remarks!'),
      points: Yup.number().required('Please fill in Proposed Price Increase points!'),
      rentalRate: Yup.number().required('Please fill in the Rental Rate!'),
      baseRentalRate: Yup.number().required('Please fill in the Base Rental Rate!')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onPropose(values);
      setSubmitting(false);
    }
  });

  const calculatePriceIncrease = (billingType, rentalRate, baseRentalRate) => {
    const priceIncreasePerDay =
      billingType === 'Weekly'
        ? (rentalRate - baseRentalRate) / 7
        : (rentalRate - baseRentalRate) / 30;

    return 3 * Math.floor(priceIncreasePerDay.toFixed(2) / 3);
  };

  calculatePriceIncrease.propTypes = {
    billingType: PropTypes.string,
    rentalRate: PropTypes.number,
    baseRentalRate: PropTypes.number
  };

  const calculatePriceIncreasePoints = (priceIncrease, diffInMonth) => {
    return (priceIncrease / 3) * Math.min(3, diffInMonth) * 30;
  };

  calculatePriceIncreasePoints.propTypes = {
    priceIncrease: PropTypes.number,
    diffInMonth: PropTypes.number
  };

  useEffect(() => {
    const { rentalRate, baseRentalRate } = formik.values;

    const priceIncrease = calculatePriceIncrease(row.billingType, rentalRate, baseRentalRate);
    formik.setFieldValue('priceIncrease', priceIncrease);
    const points = calculatePriceIncreasePoints(priceIncrease, row.numberOfMonths);
    formik.setFieldValue('pointsPerDay', priceIncrease / 3);
    formik.setFieldValue('points', points);
  }, [formik.values.rentalRate, formik.values.baseRentalRate]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField
              formik={formik}
              name="rentalRate"
              label="Rental Rate (SGD)"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FcTextField
              formik={formik}
              name="baseRentalRate"
              label="Base Rental Rate (SGD)"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              name="priceIncrease"
              label="Price Increase Per Day (SGD)"
              type="number"
              value={formik.values.priceIncrease}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              name="points"
              label="Price Increase Points"
              type="number"
              value={formik.values.points}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks" />
          </Grid>
        </Grid>

        <SubmitBtnGroup formik={formik} method="Propose" onCancel={onClose} />
      </Form>
    </FormikProvider>
  );
}

ProposePriceIncreaseForm.propTypes = {
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  row: PropTypes.object
};
