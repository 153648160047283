import dayjs from "dayjs";
import React, {useContext, useState} from 'react';
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from './DataImportForm';

const ImportVehicleInsurancesForm = () => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);

  const { onPost, loading } = useHttpPost('/api/insurances/import');

  const columns = [
    { field: 'vehicleNumber', headerName: 'Vehicle Number', width: 150 },
    { field: 'name', headerName: 'Insurance Company Name', width: 300 },
    { field: 'insuranceCi', headerName: 'insuranceCi', width: 100 },
    { field: 'startDate', headerName: 'Start Date', width: 250 },
    { field: 'endDate', headerName: 'End Date', width: 250 },
    { field: 'isFleet', headerName: 'Is Fleet?', width: 150 },
    { field: 'isZ10Z11', headerName: 'Is Z10/Z11?', width: 150 },
    { field: 'minAgeCoverage', headerName: 'minAgeCoverage', width: 150 },
    { field: 'maxAgeCoverage', headerName: 'maxAgeCoverage', width: 150 },
    { field: 'remarks', headerName: 'remarks', width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    startDate: dayjs(row.startDate).toISOString(),
    endDate: dayjs(row.endDate).toISOString(),
    isFleet: row.isFleet === "TRUE",
    isZ10Z11: row.isZ10Z11 === "TRUE",
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs(row.updatedAt).toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Vehicle Insurances" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

export default ImportVehicleInsurancesForm;
