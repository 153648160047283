import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

/**
 * @param {{date: date, imgUrl: string, title: string, link: string, cardAction: function}} props
 */

export default function PromoCard(props) {
  return (
    <Card>
      <CardActionArea onClick={props.cardAction}>
        <CardMedia
          component="img"
          alt={props.title}
          // height="140"
          image={props.imgUrl}
        />
      </CardActionArea>
      <CardContent>
        <Typography>Title: {props.title}</Typography>
        <Typography>
          Link:{' '}
          <a href={props.link} target="_blank" rel="noreferrer">
            {props.link}
          </a>
        </Typography>
        <Typography>Date: {moment(props.date.toDate()).format('D MMM yyyy')}</Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {props.children}
      </CardActions>
    </Card>
  );
}
