import React, { useEffect, useState } from 'react';
import {
  Button, Container, Grid,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import {cloudFunctionPath} from "../../cloudFunctionApiConfig";
import JobDateNameAcc from '../../components/Workshop/Job/JobDateNameAcc';
import LowerUpperBoundDateField from '../../components/Workshop/LowerUpperBoundDateField';
import workshopJobJsonToExcel from '../../utils/workshopJobJsonToExcel';
import useCloudFuncGet from '../../hooks/firebase/cloudFunction/useCloudFuncGet';

export default function JobListing() {
  const [startDate, setStartDate] = useState(dayjs(new Date().toDateString()));
  const [endDate, setEndDate] = useState(dayjs(new Date().toDateString()));

  const getSearchParams = () => {
    return {
      lowerBoundTime: startDate.unix() * 1000,
      upperBoundTime: endDate.unix() * 1000
    };
  }

  const { data, onGet } = useCloudFuncGet(`${cloudFunctionPath}/workshop/job/all`, true, getSearchParams());

  useEffect(() => {
    onGet(getSearchParams());
  }, [startDate, endDate]);

  return (
    <Container maxWidth="xl">
      <Typography
        variant="h4"
        gutterBottom
        mb={3}
        sx={{ textAlign: { xs: 'center', md: 'start' }, fontSize: { xs: 30, md: 24 } }}
      >
        Viewing Jobs
      </Typography>

      <Grid container mb={2} spacing={2}>
        <Grid item>
          <LowerUpperBoundDateField
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Grid>

        <Grid item ml={2} mt={1}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => workshopJobJsonToExcel(data.data)}
          >Print</Button>
        </Grid>
      </Grid>

      <JobDateNameAcc data={data.data ?? []} hideAction hideChinese />
    </Container>
  );
}