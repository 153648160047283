import { Alert, IconButton, Snackbar } from '@mui/material';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import DataContext from '../store/DataContext';

export const SnackbarAlert = () => {
  const dataCtx = useContext(DataContext);

  const handleSnackbarClose = () => {
    dataCtx.setSnackbarConfig({
      open: false,
      message: '',
      severity: 'success'
    });
  };

  return (
    <Snackbar
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={dataCtx.snackbarConfig.open}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={dataCtx.snackbarConfig.severity}
        sx={{ width: '100%' }}
      >
        {dataCtx.snackbarConfig.message}
      </Alert>
    </Snackbar>
  );
};
