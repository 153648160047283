import {DialogContent} from "@material-ui/core";
import {LoadingButton} from "@material-ui/lab";
import {Box, Button, Dialog, DialogTitle, Divider, Grid, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncGet from "../../../hooks/firebase/cloudFunction/useCloudFuncGet";
import palette from "../../../theme/palette";
import UpdateRestrictionShellCardForm from "../../Form/ShellCard/UpdateRestrictionShellCardForm";

const UpdateRestrictionAction = ({ card, lifeTimeSpend, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Update Card Restriction</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <UpdateRestrictionShellCardForm pan={card?.pan} lifeTimeSpend={lifeTimeSpend} onReload={onReload} onClose={() => setOpen(false)}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>Update Restriction</Button>
    </>
  );
}

UpdateRestrictionAction.propTypes = {
  card: PropTypes.object,
  lifeTimeSpend: PropTypes.number,
  onReload: PropTypes.func,
}

export default function ShellCardDetails({ card }) {
  const { data, loading, onGet } = useCloudFuncGet(`${cloudFunctionPath}/wallet/card/details`, true, {
    pan: card?.pan,
  });
  const restrictionData = data?.data?.restriction?.Restrictions;

  const onRefresh = async e => {
    e.preventDefault();
    await onGet({ pan: card?.pan });
  }

  const onReload = async () => {
    await onGet({ pan: card?.pan });
  }

  const details = [
    { label: "PAN", value: card?.pan },
    { label: "Expiry Date", value: dayjs(card?.expiryDate).format("DD MMM YYYY") },
    { label: "Main Reference", value: card?.mainReference },
    { label: "Order Card Reference", value: card?.orderCardReference },
    { label: "Status", value: card?.statusDescription },
    { label: "Assigned User Email", value: card?.assign?.email },
    { label: "Assigned User Name", value: card?.assign?.fullName },
    { label: "Assigned User Phone", value: card?.assign?.mobile },
  ];

  const restrictions = [
    { label: "Lifetime Top Up Accumulated", value: restrictionData?.UsageRestrictions?.LifeTimeSpend ?? "" },
    { label: "Lifetime Usage Accumulated", value: restrictionData?.UsageRestrictions?.LifeTimeSpendAccumulated ?? "" },
    { label: "Lifetime Balance", value: restrictionData?.UsageRestrictions?.LifeTimeSpendBalance ?? "" },
  ];

  return (
    <Grid container spacing={3}>
      {details.map(({ label, value, props = { xs: 12, sm: 6, md: 4, lg: 3 } }, index) => (
        <Grid item key={index} {...props}>
          <Typography variant="h6">{label}</Typography>
          {value}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Divider sx={{ border: 1, borderColor: palette.divider }}/>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">Card Restrictions</Typography>
          <Stack direction="row" spacing={1}>
            <UpdateRestrictionAction card={card} lifeTimeSpend={restrictionData?.UsageRestrictions?.LifeTimeSpend ?? 0} onReload={onReload}/>
            <LoadingButton loading={loading} variant="contained" onClick={onRefresh}>Fetch</LoadingButton>
          </Stack>
        </Stack>
      </Grid>
      {restrictionData && (
        <>
          {restrictions.map(({ label, value, props = { xs: 12, sm: 6, md: 4, lg: 3 } }, index) => (
            <Grid item key={index} {...props}>
              <Typography variant="h6">{label}</Typography>
              {value}
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
}

ShellCardDetails.propTypes = {
  card: PropTypes.object,
}