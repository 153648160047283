import {Box} from "@mui/material";
import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";

export default function CreditNoteItemTable({ data }) {
  const columns = [
    {
      field: "description", headerName: "Description", width: 900, sortable: false,
      renderCell: ({ row }) => (
        <Box sx={{ whiteSpace: "pre-wrap" }}>
          {row.description}
        </Box>
      )
    },
    { field: "quantity", headerName: "Quantity", width: 150, align: "center", headerAlign: "center", sortable: false },
    { field: "unitPrice", headerName: "Unit Price", width: 150, align: "center", headerAlign: "center", sortable: false },
  ];

  return (
    <StyledDataGrid rows={data ?? []} columns={columns} autoHeight pageSize={100} rowsPerPageOptions={[100]}/>
  );
}

CreditNoteItemTable.propTypes = {
  data: PropTypes.array,
}