import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import DataContext from "../../../store/DataContext";
import SurchargeTypeForm from "../../Form/Prices/SurchargeTypeForm";
import FcTable from "../FcTable";

const DeleteAction = ({ row, onReload }) => {
  const dataCtx = useContext(DataContext);
  const [open, setOpen] = useState(false);

  const { onDelete } = useHttpDelete(`/api/surcharge/type/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onDelete();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Surcharge Type deleted successfully!", severity: "success" });
      setOpen(false);
    } catch (e) {
      dataCtx.setSnackbarConfig({ open: true, message: e.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Delete {row.name}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={onSubmit}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
    </>
  );
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const EditAction = ({ row, onReload }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Edit Surcharge Type</DialogTitle>
        <DialogContent>
          <SurchargeTypeForm data={row} onReload={onReload} onClose={() => setOpen(false)}/>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Edit
      </Button>
    </>
  );
}

EditAction.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

const ActionCol = ({ row, onReload }) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <EditAction row={row} onReload={onReload}/>
      <DeleteAction row={row} onReload={onReload}/>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function SurchargesTable({ data, onReload }) {
  const columns = [
    { field: "id", headerName: "No.", showIndex: true, align: "center", headerAlign: "center" },
    { field: "name", headerName: "Name", align: "center", headerAlign: "center" },
    { field: "price", headerName: "Price", align: "center", headerAlign: "center" },
    {
      field: '', headerName: 'Action',
      align: "center", headerAlign: "center",
      minWidth: 125, sortable: false,
      renderCell: ({row}) => <ActionCol row={row} onReload={onReload}/>
    },
  ];

  return (
    <FcTable rows={data ?? []} columns={columns}/>
  );
}

SurchargesTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}