import {LoadingButton} from "@material-ui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import CustomerDetails from "../../../components/Details/Customer/CustomerDetails";
import {FcCustomerDocuments} from "../../../components/Document/Documents";
import useHttpPut from "../../../hooks/http/useHttpPut";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import palette from "../../../theme/palette";
import {BOOKING_PERMISSIONS} from "../../../utils/authorization/permissions/bookingPermission";
import {permissionRequired} from "../../../utils/authorization/roles";
import BillingDetailsAccordion from "./Accordions/BillingDetailsAccordion";
import BookingDetailsAccordion from "./Accordions/BookingDetailsAccordion";
import BookingDocumentsAccordion from "./Accordions/BookingDocumentsAccordion";
import VehicleDetailsAccordion from "./Accordions/VehicleDetailsAccordion";

const enableEditStatus = ["Booked", "Pending"];

const AccordionItem = ({summary, details}) => {
  const [open, setOpen] = useState(false);

  return (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{color: "white"}}/>}
        sx={{backgroundColor: palette.grey[500]}}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails sx={{backgroundColor: palette.grey[300]}}>
        <Box py={2}>
          {details}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

AccordionItem.propTypes = {
  summary: PropTypes.element,
  details: PropTypes.element,
}

const CancelAction = ({booking, onReload}) => {
  const [open, setOpen] = useState(false);

  const {onPut, loading} = useHttpPut(`/api/booking/${booking?.id}/cancel`);

  const onCancel = async e => {
    e.preventDefault();
    await onPut();
    await onReload();
    setOpen(false);
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Do you want cancel {booking?.bookingNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="warning" onClick={onCancel}>Confirm</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained" color="warning" onClick={() => setOpen(true)}
        disabled={!enableEditStatus.includes(booking?.status)}
      >
        Cancel
      </Button>
    </>
  );
}

CancelAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

const VoidAction = ({ booking, onReload }) => {
  const dataCtx = useContext(DataContext);

  const [open, setOpen] = useState(false);

  const { onPut, loading} = useHttpPut(`/api/booking/${booking?.id}/void`);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await onPut();
      await onReload();
      dataCtx.setSnackbarConfig({ open: true, message: "Booking Voided successfully!", severity: "success" });
      setOpen(false);
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Do you want void {booking?.bookingNo}?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton loading={loading} variant="contained" color="error" onClick={onSubmit}>Void</LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)} disabled={booking?.status === "Voided"}>
        Void
      </Button>
    </>
  );
}

VoidAction.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
}

export default function BookingDetailsTab({ booking, agreements, onReload }) {
  const authCtx = useContext(AuthContext);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Typography variant="h4">{booking?.bookingNo} Booking: {booking?.status}</Typography>
        <Stack direction="row" spacing={2}>
          {permissionRequired(authCtx, BOOKING_PERMISSIONS.UPDATE) && (
            <Button variant="contained" component={Link} to="./edit" disabled={!enableEditStatus.includes(booking?.status)}>Edit</Button>
          )}
          {permissionRequired(authCtx, BOOKING_PERMISSIONS.UPDATE) && <CancelAction booking={booking} onReload={onReload}/>}
          {permissionRequired(authCtx, BOOKING_PERMISSIONS.VOID) && <VoidAction booking={booking} onReload={onReload}/>}
        </Stack>
      </Stack>

      <AccordionItem
        summary={<Typography variant="h5" color="white">Booking Details</Typography>}
        details={booking && <BookingDetailsAccordion data={booking}/>}
      />
      <AccordionItem
        summary={<Typography variant="h5" color="white">Booking Documents</Typography>}
        details={booking && <BookingDocumentsAccordion booking={booking} agreements={agreements} onReload={onReload}/>}
      />
      <AccordionItem
        summary={<Typography variant="h5" color="white">Billing Details</Typography>}
        details={booking && <BillingDetailsAccordion data={booking} onReload={onReload}/>}
      />
      {booking?.company && <AccordionItem
        summary={<Typography variant="h5" color="white">Company Details</Typography>}
        details={booking && (
          <>
            <Box mb={3}>
              <FcCustomerDocuments customer={booking?.company}/>
            </Box>
            <CustomerDetails data={booking?.company}/>
          </>
        )}
      />}
      <AccordionItem
        summary={<Typography variant="h5" color="white">Customer Details</Typography>}
        details={booking && (
          <>
            <Box mb={3}>
              <FcCustomerDocuments customer={booking?.customer}/>
            </Box>
            <CustomerDetails data={booking?.customer}/>
          </>
        )}
      />
      <AccordionItem
        summary={<Typography variant="h5" color="white">Vehicle Details</Typography>}
        details={booking && <VehicleDetailsAccordion booking={booking} agreements={agreements} onReload={onReload}/>}
      />
    </>
  );
}

BookingDetailsTab.propTypes = {
  booking: PropTypes.object,
  agreements: PropTypes.array,
  onReload: PropTypes.func,
}