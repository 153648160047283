import {Grid} from "@mui/material";
import PropTypes from "prop-types";
import {useContext} from "react";
import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import DataContext from "../../../../store/DataContext";
import {getMomentValue} from "../../../../utils/date";
import {FcDateField, FcSelectField, FcTextArea, SubmitBtnGroup} from "../../FormFields";

export default function DisposalTaskCheckForm({ data, onReload, onClose }) {
  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost(`/api/vehicles/disposal/${data?.vehicleId}`);
  const { data: userData } = useHttpGet("/api/user/list");
  const users = userData?.map(user => ({ label: user.name, value: user.name })) ?? [];
  users.sort((a, b) => a.value.localeCompare(b.value));

  const formik = useFormik({
    initialValues: {
      assign: data?.assign ?? "",
      remarks: data?.remarks ?? "",
      doneAt: data?.doneAt ? getMomentValue(data?.doneAt) : null,
      taskId: data?.taskId,
    },
    validationSchema: Yup.object({
      assign: Yup.string().nullable(),
      remarks: Yup.string().nullable(),
      doneAt: Yup.date().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost({ ...values, doneAt: values?.doneAt ? values?.doneAt?.toISOString() : null });
        await onReload();
        onClose();
        dataCtx.setSnackbarConfig({ open: true, message: "Task done updated", severity: "success" });
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  console.log(formik.values);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcSelectField formik={formik} label="Assign" name="assign" items={users}/>
          </Grid>
          <Grid item xs={12}>
            <FcDateField formik={formik} label="Done Date" name="doneAt"/>
          </Grid>
          <Grid item xs={12}>
            <FcTextArea formik={formik} label="Remarks" name="remarks"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Update" onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

DisposalTaskCheckForm.propTypes = {
  data: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}