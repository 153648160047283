import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import {
  updatePassword,
  getAuth,
  signOut,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth';
import PropTypes from 'prop-types';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@material-ui/lab';
import useGetDocument from '../../../hooks/firebase/useGetDocument';
import FirestoreContext from '../../../store/FirestoreContext';

export default function ChangePasswordForm({ onOpenSnackbar }) {
  const [showPassword, setShowPassword] = useState(false);

  const onUpdate = async (values) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, values.oldpwd);
      await reauthenticateWithCredential(user, credential)
        .then(() => {
          updatePassword(user, values.pwd).then(() => {
            onOpenSnackbar('User Pwd updated successfully');
          });
        })
        .catch((error) => {
          onOpenSnackbar(error.message, 'error');
        });
    } catch (error) {
      onOpenSnackbar(`An error occurred! ${error.message}`, 'error');
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      oldpwd: '',
      pwd: '',
      confirmPwd: ''
    },
    validationSchema: Yup.object({
      oldpwd: Yup.string().required('Please enter your password'),
      pwd: Yup.string().min(6, 'Too Short!').required('Please enter your new password'),
      confirmPwd: Yup.string()
        .required('Please enter your new password again')
        .oneOf([Yup.ref('pwd'), null], 'Passwords must match')
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      // if (method === 'Create') {
      //   await onCreate(values);
      //   resetForm();
      // } else {
      // }
      await onUpdate(values);
      resetForm();
      setSubmitting(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <TextField
          label="Old Password"
          type={showPassword ? 'string' : 'password'}
          name="oldpwd"
          variant="standard"
          margin="dense"
          fullWidth
          value={formik.values.oldpwd}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.oldpwd && formik.errors.oldpwd)}
          helperText={formik.touched.oldpwd && formik.errors.oldpwd}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          label="Password"
          type={showPassword ? 'string' : 'password'}
          name="pwd"
          variant="standard"
          margin="dense"
          fullWidth
          value={formik.values.pwd}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.pwd && formik.errors.pwd)}
          helperText={formik.touched.pwd && formik.errors.pwd}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          label="Confirm Password"
          type={showPassword ? 'string' : 'password'}
          name="confirmPwd"
          variant="standard"
          margin="dense"
          fullWidth
          value={formik.values.confirmPwd}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.confirmPwd && formik.errors.confirmPwd)}
          helperText={formik.touched.confirmPwd && formik.errors.confirmPwd}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <Box sx={{ marginTop: 3 }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
              Submit
            </LoadingButton>
          </Stack>
        </Box>
      </Form>
    </FormikProvider>
  );
}

ChangePasswordForm.propTypes = {
  onOpenSnackbar: PropTypes.func
};
