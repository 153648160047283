import PropTypes from "prop-types";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";

export default function AppUserWalletTable({ data, loading = false, onReload }) {
  const columns = [
    { field: "email", headerName: "Email", align: "center", headerAlign: "center", width: 250 },
    { field: "fullName", headerName: "Full Name", align: "center", headerAlign: "center", width: 250 },
    { field: "phone", headerName: "Phone", align: "center", headerAlign: "center", width: 150 },
    {
      field: "wallet.balance", headerName: "User Wallet", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => (row?.wallet?.balance ?? 0) / 100,
    },
    {
      field: "wallet.discountBalance", headerName: "Discount Wallet", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => (row?.wallet?.discountBalance ?? 0) / 100,
    },
    {
      field: "totalAmount", headerName: "Total Amount", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => ((row?.wallet?.discountBalance ?? 0) + (row?.wallet?.balance ?? 0)) / 100,
    },
  ];

  return (
    <StyledDataGrid
      columns={columns} rows={data ?? []}
      loading={loading}
      autoHeight
      disableSelectionOnClick
    />
  )
}

AppUserWalletTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
}