import {LoadingButton} from "@material-ui/lab";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Box, IconButton, LinearProgress, Stack, Typography} from "@mui/material";
import {getAuth} from "firebase/auth";
import {useFormik} from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import useHttpGet from "../../../../hooks/http/useHttpGet";
import useHttpPost from "../../../../hooks/http/useHttpPost";
import useHttpPut from "../../../../hooks/http/useHttpPut";
import DataContext from "../../../../store/DataContext";
import {getMomentValue} from "../../../../utils/date";
import BookingDepositQuestionForm from "./BookingCostForm";
import BookingRemarksQuestionForm from "./BookingRemarksQuestionForm";
import BookingSummaryQuestionForm from "./BookingSummaryQuestionForm";
import CDWQuestionForm from "./CDWQuestionForm";
import CustomerIdentityQuestionForm from "./CustomerIdentityQuestionForm";
import CustomerQuestionForm from "./CustomerQuestionForm";
import MalaysiaSurchargeQuestionForm from "./DriveToMalaysiaQuestionForm";
import PaymentMethodQuestionForm from "./PaymentMethodQuestionForm";
import PeriodTypeQuestionForm from "./PeriodTypeQuestionForm";
import RentalUsageQuestionForm from "./RentalUsageQuestionForm";
import DepositQuestionForm from "./DepositQuestionForm";
import PriceModelQuestionForm from "./PriceModelQuestionForm";
import RentalPeriodQuestionForm from "./RentalPeriodQuestionForm";
import HolidaySurchargesQuestionForm from "./HolidaySurchargesQuestionForm";
import VehicleQuestionForm from "./VehicleQuestionForm";

const LinearProgressWithLabel = (props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={3} mb={3}>
      <Box sx={{ width: '100%' }} mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
      {props.value === 100 && <LoadingButton loading={props.formik.isSubmitting} variant="contained" onClick={props.onSubmit}>{props.method}</LoadingButton>}
    </Stack>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  formik: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  method: PropTypes.string,
}

export default function BookingQuestionForms({ data }) {
  let method = "Create";
  if (data) method = data?.previousBookingId ? "Renew" : "Update";

  const navigate = useNavigate();

  const rentalUsages = ["Private Hire", "Private Use", "Others"];
  const paymentTypes = ["Prepaid", "Postpaid"];
  const periodTypes = ["1", "3", "7", "30"];

  const dataCtx = useContext(DataContext);
  const [vehicle, setVehicle] = useState(null);
  const [pricePeriod, setPricePeriod] = useState(null);
  const [identity, setIdentity] = useState("");
  const [driverIdentity, setDriverIdentity] = useState("");

  const { onPost } = useHttpPost("/api/booking");
  const { onPut } = useHttpPut(`/api/booking/${data?.id}`);

  const { data: vehicleData } = useHttpGet(`/api/vehicles/${data?.vehicleId}`);
  const { data: pricePeriodData } = useHttpGet(`/api/vehicles/price-period/${data?.pricePeriodId}`);
  const { data: customer } = useHttpGet(`/api/customer/${data?.customerId}`);
  const { data: company } = useHttpGet(`/api/customer/${data?.companyId}`);
  const { data: cdwPrices } = useHttpGet("/api/booking/price/cdw");

  const handleIdentityChange = e => setIdentity(e.target.value);
  const handleDriverIdentityChange = e => setDriverIdentity(e.target.value);
  const handlePricePeriodChange = value => setPricePeriod(value);

  const handleVehicleSelect = data => {
    const price = data?.model?.prices?.find(p => p.pricePeriodId === formik.values.pricePeriodId);
    formik.setFieldValue("vehicleId", data.id);
    formik.setFieldValue("rentalPrice", price?.price ?? 0);
    formik.setFieldValue("originalRentalPrice", price?.price ?? 0);
    setVehicle(data);
  }

  const onOpenSnackbar = (message, severity = "success") => dataCtx.setSnackbarConfig({ open: true, message, severity });

  const onCreate = async values => {
    try {
      const res = await onPost({
        ...values,
        userId: getAuth().currentUser.uid,
        startDate: values.startDate.toISOString(),
        endDate: values.endDate.toISOString(),
      });
      onOpenSnackbar("Booking Created successfully!");

      const navigateRoute = method === "Renew" ? `../../${res.bookingNo}` : `../${res.bookingNo}`;
      setTimeout(() => navigate(navigateRoute), 1000);
    } catch (e) {
      onOpenSnackbar(e.message, "error");
    }
  }

  const onUpdate = async values => {
    try {
      await onPut({
        ...values,
        startDate: values.startDate.toISOString(),
        endDate: values.endDate.toISOString(),
      });
      onOpenSnackbar("Booking Updated successfully!");
      setTimeout(() => navigate(`../`), 1000);
    } catch (e) {
      onOpenSnackbar(e.message, "error");
    }
  }

  const formik = useFormik({
    initialValues: {
      previousBookingId: data?.previousBookingId ?? null,
      customerId: data?.customerId ?? null,
      companyId: data?.companyId ?? null,
      customerType: data?.customerType ?? null,
      rentalUsage: data?.rentalUsage ?? "",
      paymentMethod: data?.paymentMethod ?? "",
      periodType: data?.periodType ?? "",
      pricePeriodId: data?.pricePeriodId ?? null,
      vehicleId: data?.vehicleId ?? null,
      originalVehicleId: data?.vehicleId ?? null,
      originalRentalPrice: data?.originalRentalPrice ?? 0,
      rentalPrice: data?.rentalPrice ?? 0,
      startDate: data?.startDate ? moment(getMomentValue(data?.startDate).format("DD MMM YYYY")) : null,
      endDate: data?.endDate ? moment(getMomentValue(data?.endDate).format("DD MMM YYYY")) : null,
      surcharges: data?.surcharges ?? [],
      haveCdw: data?.cdwPrice ? data.cdwPrice > 0 : false,
      cdwPrice: data?.cdwPrice ?? 0,
      haveDeposit: data?.deposit ? data.deposit > 0 : false,
      deposit: data?.deposit ?? 0,
      bookingCost: data?.bookingCost ?? 0,
      totalUpfront: data?.totalUpfront ?? 0,
      remarks: data?.remarks ?? "",
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required("Please select Customer!"),
      companyId: Yup.string().nullable(),
      customerType: Yup.string().required("Please select customer type!"),
      rentalUsage: Yup.string()
        .oneOf(rentalUsages, "Please select correct rental usage!")
        .required("Please select rental usage!"),
      paymentMethod: Yup.string()
        .oneOf(paymentTypes, "Please select correct payment type!")
        .required("Please select payment type!"),
      periodType: Yup.string()
        .oneOf(periodTypes, "Please select correct period type!")
        .required("Please select period type!"),
      rentalPrice: Yup.string().required("Rental Price is required!"),
      pricePeriodId: Yup.string().required("Please select price model!"),
      vehicleId: Yup.string().required("Please select Vehicle!"),
      startDate: Yup.date().required("Please select Start Date!"),
      endDate: Yup.date().required("Please select End Date!"),
      surcharges: Yup.array(),
      haveCdw: Yup.boolean().required("Please check this booking need CDW or not!"),
      cdwPrice: Yup.number().required("Please fill in CDW amount!"),
      haveDeposit: Yup.boolean().required("Please check this booking need deposit or not!"),
      deposit: Yup.number().required("Please select deposit amount!"),
      bookingCost: Yup.number().required("Please select booking deposit!"),
      totalUpfront: Yup.number().required("Please fill in total upfront!"),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (method === "Update") await onUpdate(values);
      else await onCreate(values);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (vehicleData) setVehicle(vehicleData);
  }, [vehicleData]);

  useEffect(() => {
    if (vehicle) {
      const price = vehicle?.model?.prices?.find(p => p.pricePeriodId === formik.values.pricePeriodId);
      formik.setFieldValue("rentalPrice", price?.price);
      formik.setFieldValue("originalRentalPrice", price?.price);
    }
  }, [formik.values.pricePeriodId]);

  useEffect(() => {
    const { startDate, endDate, paymentMethod, surcharges, deposit, rentalPrice } = formik.values;
    let totalUpfront = deposit + surcharges.reduce((sum, a) => sum + a.amount, 0);
    if (paymentMethod === "Prepaid") {
      const paymentPeriod = pricePeriod?.paymentPeriod === 0 ? 1 : pricePeriod?.paymentPeriod;
      const paymentDuration = moment(endDate).diff(moment(startDate), 'day') / paymentPeriod;
      // payment period === 0 mean one time payment
      if (pricePeriod?.paymentPeriod === 0) totalUpfront += paymentDuration * rentalPrice;
      else totalUpfront += rentalPrice;
    }
    formik.setFieldValue("totalUpfront", totalUpfront);
  }, [formik.values.startDate, formik.values.endDate, formik.values.paymentMethod, formik.values.surcharges, formik.values.deposit, formik.values.rentalPrice, formik.values.pricePeriodId]);

  useEffect(() => {
    if (company && customer) {
      setIdentity(company.identity);
      setDriverIdentity(customer.identity);
    } else if (customer) {
      setIdentity(customer.identity);
    }
  }, [customer, company]);

  useEffect(() => {
    if (pricePeriodData) {
      setPricePeriod(pricePeriodData);
      formik.setFieldValue("paymentMethod", pricePeriodData.paymentMethod);
    }
  }, [pricePeriodData]);

  const [page, setPage] = useState(0);
  const questions = [
    {
      element: (
        <CustomerIdentityQuestionForm
          key="customer-identity" type="customer"
          identity={identity} handleIdentityChange={handleIdentityChange}
        />
      ),
      validate: () => identity.length > 0,
      errorMessage: "Please fill in customer Identity!",
    },
    {
      element: (
        <CustomerQuestionForm
          key="customer" type="customer"
          identity={identity} formik={formik}
        />
      ),
      validate: () => formik.values.customerId !== null || formik.values.companyId !== null,
      errorMessage: "Please select customer!",
    },
    {
      element: (
        <CustomerIdentityQuestionForm
          key="additional-driver-identity" type="driver"
          identity={driverIdentity} handleIdentityChange={handleDriverIdentityChange}
        />
      ),
      validate: () => driverIdentity.length > 0,
      skip: () => formik.values.customerType !== "Company",
      errorMessage: "Please fill in additional driver Identity!",
    },
    {
      element: (
        <CustomerQuestionForm
          key="additional-driver" type="driver"
          identity={driverIdentity} formik={formik}
          onBackPreviousPage={() => setPage(page - 1)}
        />
      ),
      validate: () => formik.values.customerId !== null,
      skip: () => formik.values.customerType !== "Company",
      errorMessage: "Please select driver!",
    },
    {
      element: <RentalUsageQuestionForm key="rental-usage" formik={formik}/>,
      validate: () => rentalUsages.includes(formik.values.rentalUsage),
      errorMessage: "Please select rental usage!",
    },
    {
      element: <PaymentMethodQuestionForm key="payment-method" formik={formik}/>,
      validate: () => paymentTypes.includes(formik.values.paymentMethod),
      errorMessage: "Please select payment method!",
    },
    {
      element: <RentalPeriodQuestionForm key="rental-period" formik={formik} pricePeriod={pricePeriod}/>,
      validate: () => formik.values.startDate !== null && formik.values.endDate !== null,
      errorMessage: "Please select start date and end date!",
    },
    {
      element: <PeriodTypeQuestionForm key="period-type" formik={formik}/>,
      validate: () => periodTypes.includes(formik.values.periodType),
      errorMessage: "Please select period type!",
    },
    {
      element: <PriceModelQuestionForm key="price-model" formik={formik} handlePricePeriodChange={handlePricePeriodChange}/>,
      validate: () => formik.values.pricePeriodId !== null,
      errorMessage: "Please select price model!",
    },
    {
      element: <DepositQuestionForm key="deposit" formik={formik}/>,
      validate: () => formik.values.paymentMethod === "Prepaid" || formik.values.paymentMethod === "Postpaid" && formik.values.deposit > 0,
      errorMessage: "Please select deposit amount!",
    },
    {
      element: <BookingDepositQuestionForm key="booking-cost" formik={formik}/>,
      validate: () => formik.values.bookingCost > 0,
      errorMessage: "Please select booking deposit!",
    },
    {
      element: <HolidaySurchargesQuestionForm key="holiday-surcharges" formik={formik} navigateToNext={() => setPage(page + 1)}/>,
      validate: () => true,
      errorMessage: "",
    },
    {
      element: <MalaysiaSurchargeQuestionForm key="malaysia-surcharges" formik={formik}/>,
      validate: () => true,
      errorMessage: "",
    },
    {
      element: <CDWQuestionForm key="cdw" formik={formik} cdwPrices={cdwPrices}/>,
      validate: () => true,
      errorMessage: "",
    },
    {
      element: <VehicleQuestionForm key="vehicle" formik={formik} vehicle={vehicle} handleVehicleSelect={handleVehicleSelect}/>,
      validate: () => formik.values.vehicleId !== null,
      errorMessage: "Please select vehicle!",
    },
    {
      element: <BookingRemarksQuestionForm key="remarks" formik={formik}/>,
      validate: () => true,
      errorMessage: "",
    },
    {
      element: <BookingSummaryQuestionForm key="summary" formik={formik} vehicle={vehicle} pricePeriod={pricePeriod}/>,
      validate: () => true,
      errorMessage: "",
    },
  ];

  const handleClickPreviousPage = e => {
    e.preventDefault();
    if (page <= 0) return;
    let p = page - 1;
    while (typeof(questions[p].skip) === "function" && questions[p].skip()) p--;
    setPage(p);
  }

  const handleClickNextPage = e => {
    e.preventDefault();
    if (page >= questions.length - 1) return;
    if (questions[page].validate()) {
      let p = page + 1;
      while (typeof(questions[p].skip) === "function" && questions[p].skip()) p++;
      setPage(p);
    } else {
      onOpenSnackbar(questions[page].errorMessage, "error");
    }
  }

  const calculateProgress = () => {
    if (!questions[page].validate()) return page / questions.length * 100;
    return (page + 1) / questions.length * 100;
  }

  return (
    <Box>
      <LinearProgressWithLabel formik={formik} value={calculateProgress()} onSubmit={formik.handleSubmit} method={method}/>
      <Box p={3} mb={3} boxShadow={12}>
        {questions[page].element}
      </Box>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <IconButton
          disabled={page <= 0}
          onClick={handleClickPreviousPage}
        >
          <KeyboardArrowLeftIcon/>
        </IconButton>
        <IconButton
          disabled={page >= questions.length - 1}
          onClick={handleClickNextPage}
        >
          <KeyboardArrowRightIcon/>
        </IconButton>
      </Stack>
    </Box>
  );
}

BookingQuestionForms.propTypes = {
  data: PropTypes.object,
}