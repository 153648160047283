import {
  FormControl,
  Grid, IconButton, TextField, Typography
} from '@mui/material';

import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { LoadingButton } from '@material-ui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import DataContext from '../../../store/DataContext';
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import { cloudFunctionPath } from '../../../cloudFunctionApiConfig';
import useCloudFuncPost from '../../../hooks/firebase/cloudFunction/useCloudFuncPost';
import useCloudFuncPut from '../../../hooks/firebase/cloudFunction/useCloudFuncPut';
import { FcAutocomplete, SubmitBtnGroup } from '../FormFields';
import useGetCollections from '../../../hooks/firebase/useGetCollections';
import authContext from '../../../store/AuthContext';

export default function WorkshopReturnJobForm({ data }) {
  const authCtx = useContext(authContext);
  const dataCtx = useContext(DataContext);
  const navigate = useNavigate();

  const onOpenSnackbar = (message, severity = "success") => {
    dataCtx.setSnackbarConfig({ open: true, message, severity });
  }

  const method = data ? "Update" : "Create"

  const { data: vehicle } = useCloudFuncGet(`${cloudFunctionPath}/workshop/vehicle`);
  const { data: user } = useGetCollections("fcadmin/fcadmin/users");

  const { onPost } = useCloudFuncPost(`${cloudFunctionPath}/workshop/return_job`);
  const { onPut } = useCloudFuncPut(`${cloudFunctionPath}/workshop/return_job/${data?.id}`);

  const formik = useFormik({
    initialValues: {
      vehicleNumber: null,
      dates: [],
      mechanic: null,
    },
    validationSchema: Yup.object({
      vehicleNumber: Yup.string().required("Please fill in Vehicle Number"),
      dates: Yup.array().required("Please fill in a date").min(1, "Please fill in at least a date"),
      mechanic: Yup.string(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const uploadData = {
          salesPerson: authCtx.name,
          vehicleNumber: formik.values.vehicleNumber,
          dates: formik.values.dates,
          mechanic: formik.values.mechanic
        };

        if (method === "Create") {
          await onPost(uploadData);
          onOpenSnackbar("Successfully Created Return Jobs");
        } else {
          await onPut(uploadData);
          onOpenSnackbar("Successfully Updated Return Jobs");
        }

        setTimeout(() => navigate(-1), 1000);
      } catch (error) {
        console.log(error.message);
        onOpenSnackbar(JSON.parse(error.message).message, "error");
      }
    }
  })

  useEffect(() => {
    if (data && vehicle.data?.length && user.length !== null) {
      formik.setFieldValue("vehicleNumber", data.vehicleNumber);
      formik.setFieldValue("mechanic", data.mechanic);
      formik.setFieldValue("dates", data.dates.map((date) => dayjs(date)))
    }
  }, [data, vehicle, user]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FcAutocomplete
              formik={formik}
              name="vehicleNumber"
              label="Vehicle Number"
              options={vehicle.data?.map((vehicle) => vehicle.vehicleNumber)}
            />
          </Grid>

          <Grid item xs={6}>
            <FcAutocomplete
              formik={formik}
              name="mechanic"
              label="Mechanic Name"
              options={user.filter((u) => u.department === "workshop").map((u) => u.name)}
            />
          </Grid>

          <Grid item>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  disableMaskedInput
                  label="Return Dates"
                  name="dates"
                  onChange={() => {}}
                  onAccept={(val) => {
                    formik.setFieldValue("dates", [...formik.values.dates, val]);
                  }}
                  value={null}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
              {Boolean(formik.touched.dates && formik.errors.dates) && (
                <Typography p sx={{ fontSize: 14, color: 'red' }}>
                  {formik.touched.dates && formik.errors.dates}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {
            formik.values.dates.map((date) => <Grid key={date.unix()} item>
              <Grid p={1} bgcolor="#F0F0F0" borderRadius={1} justifyContent="center" alignItems="center" container>
                <Grid item xs>
                  <Typography sx={{ fontSize: 18, whiteSpace: "pre-line" }} ml={1}>
                    { date.format("DD MMM YYYY") }
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    children={<ClearIcon />}
                    onClick={() => formik.setFieldValue("dates", formik.values.dates.filter((t) => t.unix() !== date.unix()))}
                  />
                </Grid>
              </Grid>
            </Grid>)
          }
        </Grid>

        <SubmitBtnGroup method={method} formik={formik} onCancel={() => navigate(-1)} />
      </Form>
    </FormikProvider>
  );
}

WorkshopReturnJobForm.propTypes = {
  data: PropTypes.object,
}