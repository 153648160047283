import {getAuth} from "firebase/auth";

const host = process.env.REACT_APP_BACKEND_ENTRY;
const cloudFuncHost = process.env.REACT_APP_FIREBASE_CLOUD_FUNC;

export const apiFetch = async (path, options = {}, searchParams = {}) => {
  let resp;
  try {
    const url = new URL(host + path);
    url.search = new URLSearchParams(searchParams).toString();
    console.log(url, options);
    resp = await fetch(url, options);
    console.log(resp);
  } catch (error) {
    console.log("fetch error:", error);
    throw error;
  }

  if (!resp.ok) {
    const errMessage = await resp.text();
    console.log(`API ${path}: ${resp.status} ${resp.statusText}`);
    console.log("error message:", errMessage);
    if (resp.status === 500) {
      throw new Error("Something went wrong...");
    } else {
      throw new Error(errMessage);
    }
  }

  if (resp.status === 204) return "";

  let res;
  try {
    const contentType = resp.headers.get("Content-Type");
    if (contentType.includes("application/json")) {
      res = await resp.json();
    } else if (contentType.includes("application/pdf") || contentType.includes("image")) {
      res = await resp.blob();
    }
  } catch (error) {
    console.log("json parse error:", error);
    throw error;
  }

  return res;
}

export const get = async (path, searchParams) => {
  const token = await getAuth().currentUser.getIdToken();
  const res = await apiFetch(path, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }, searchParams);
  return res;
}

export const post = async (path, body) => {
  const token = await getAuth().currentUser.getIdToken();
  const res = await apiFetch(path, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body)
  });
  return res;
}

export const apiFileFetch = async (path, options = {}, searchParams = {}) => {
  let resp;
  try {
    const url = new URL(host + path);
    url.search = new URLSearchParams(searchParams).toString();
    console.log(url, options);
    resp = await fetch(url, options);
  } catch (error) {
    console.log("fetch error:", error);
    throw error;
  }

  if (!resp.ok) {
    const errMessage = await resp.text();
    console.log(`API ${path}: ${resp.status} ${resp.statusText}`);
    throw new Error(errMessage);
  }

  if (resp.status === 204) return "";

  let res;
  try {
    res = resp.blob();
  } catch (error) {
    console.log("File read error:", error);
    throw error;
  }

  return res;
}

export const apiFuncFetch = async (path, options = {}, searchParams = {}) => {
  let resp;
  try {
    const url = new URL(cloudFuncHost + path);
    url.search = new URLSearchParams(searchParams).toString();
    console.log(url, options);
    resp = await fetch(url, options);
  } catch (error) {
    console.log("fetch error:", error);
    throw error;
  }

  if (!resp.ok) {
    const errMessage = await resp.text();
    console.log(`API ${path}: ${resp.status} ${resp.statusText}`);
    throw new Error(errMessage);
  }

  if (resp.status === 204) return "";

  let res;
  try {
    res = await resp.json();
  } catch (error) {
    console.log("json parse error:", error);
    throw error;
  }

  return res;
}