import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {useContext, useState} from "react";
import useHttpDelete from "../../../hooks/http/useHttpDelete";
import useHttpGet from "../../../hooks/http/useHttpGet";
import AuthContext from "../../../store/AuthContext";
import DataContext from "../../../store/DataContext";
import {CUSTOMER_PERMISSIONS} from "../../../utils/authorization/permissions/customerPermissions";
import {permissionRequired} from "../../../utils/authorization/roles";
import CustomerDetails from "../../Details/Customer/CustomerDetails";
import {FcCustomerDocument} from "../../Document/Documents";
import CustomerForm from "../../Form/Customer/CustomerForm";
import FcTable from "../FcTable";

const DeleteAction = ({ row, onReload, onClose }) => {
  const dataCtx = useContext(DataContext);
  const [open, setOpen] = useState(false);

  const { onDelete } = useHttpDelete(`/api/customer/${row?.id}`);

  const onSubmit = async e => {
    e.preventDefault();
    await onDelete();
    dataCtx.setSnackbarConfig({
      open: true,
      message: `${row.name} deleted successfully!`,
      severity: "success"
    });
    await onReload();
    setOpen(false);
    onClose();
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Do you want to delete {row.name}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={onSubmit}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
    </>
  )
}

DeleteAction.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
}

const CustomerDocuments = ({ row }) => {
  const [open, setOpen] = useState(false);
  const { data, onGet } = useHttpGet(`/api/customer/${row?.id}/documents`);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>{row?.name} Documents</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {data?.map((document, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <Typography variant="h6">{document.label}</Typography>
                <FcCustomerDocument document={document} onReload={onGet}/>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Documents
      </Button>
    </>
  );
}

CustomerDocuments.propTypes = {
  row: PropTypes.object,
}

const ActionCol = ({ row, onReload }) => {
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  return (
    <Stack direction="row" justifyContent="center" spacing={2}>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <Box p={1}>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">Customer Details</Typography>
              <CustomerDocuments row={row}/>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box py={1}>
              {edit ? (
                <CustomerForm
                  data={row}
                  onClose={() => setOpen(false)}
                  onReload={onReload}
                />
              ) : <CustomerDetails data={row}/>}
            </Box>
          </DialogContent>
          {!edit && <DialogActions>
            {permissionRequired(authCtx, CUSTOMER_PERMISSIONS.UPDATE) && <Button variant="contained" onClick={() => setEdit(!edit)}>{edit ? "View" : "Edit"}</Button>}
            {permissionRequired(authCtx, CUSTOMER_PERMISSIONS.DELETE) && <DeleteAction row={row} onReload={onReload} onClose={() => setOpen(false)}/>}
          </DialogActions>}
        </Box>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View</Button>
    </Stack>
  );
}

ActionCol.propTypes = {
  row: PropTypes.object,
  onReload: PropTypes.func,
}

export default function CustomerTable({ data, onReload }) {
  const columns = [
    { field: "id", headerName: "No.", align: "center", headerAlign: "center", showIndex: true },
    { field: "name", headerName: "Name", align: "center", headerAlign: "center" },
    { field: "customerType", headerName: "Customer Type", align: "center", headerAlign: "center", minWidth: 150 },
    { field: "phone", headerName: "Phone", align: "center", headerAlign: "center" },
    { field: "email", headerName: "Email", align: "center", headerAlign: "center" },
    { field: "identity", headerName: "NRIC/FIN/ACRA", align: "center", headerAlign: "center" },
    {
      field: "", headerName: "Action", align: "center", headerAlign: "center",
      renderCell: ({ row }) => <ActionCol row={row} onReload={onReload}/>
    },
  ];

  return (
    <FcTable columns={columns} rows={data ?? []}/>
  );
}

CustomerTable.propTypes = {
  data: PropTypes.array,
  onReload: PropTypes.func,
}