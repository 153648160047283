import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LoadingButton } from '@material-ui/lab';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { Autocomplete } from '@material-ui/core';
import useFilePost from '../../hooks/http/useFilePost';
import { getFile, getImage, getPdf } from '../../utils/getFile';

const errorMessageStyle = {
  fontSize: 14,
  color: 'red'
};

export const FcTextField = ({ formik, name, label, type = 'text', disabled = false }) => {
  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      type={type}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      disabled={disabled}
    />
  );
};

FcTextField.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string
};

export const FcTextArea = ({ formik, name, label, rows = 0 }) => {
  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        multiline
        rows={rows}
        name={name}
        label={label}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={Boolean(formik.touched[name] && formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      />
    </Grid>
  );
};

FcTextArea.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string
};

export const FcDateField = ({ formik, name, label, defaultView = 'day' }) => {
  const format = defaultView === 'year' ? 'YYYY' : 'DD-MM-YYYY';
  const [view, setView] = useState(defaultView);

  const onViewChange = (view) => {
    if (defaultView === 'year') return;
    setView(view);
  };

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          view={view}
          onViewChange={onViewChange}
          disableMaskedInput
          label={label}
          name={name}
          inputFormat={format}
          onChange={(val) => formik.setFieldValue(name, val)}
          value={formik.values[name]}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
      {Boolean(formik.touched[name] && formik.errors[name]) && (
        <Typography p sx={errorMessageStyle}>
          {formik.touched[name] && formik.errors[name]}
        </Typography>
      )}
    </FormControl>
  );
};

FcDateField.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  defaultView: PropTypes.string
};

export const FcTimeField = ({ formik, name, label }) => {
  return (
    <TextField
      fullWidth
      type="time"
      name={name}
      label={label}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
    />
  );
};

FcTimeField.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string
};

export const FcSelectField = ({ formik, name, label, items, disabled = false }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        disabled={disabled}
      >
        {items?.map(({ label, value, disabled = false }, index) => (
          <MenuItem value={value} key={index} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {Boolean(formik.touched[name] && formik.errors[name]) && (
        <Typography p sx={errorMessageStyle}>
          {formik.touched[name] && formik.errors[name]}
        </Typography>
      )}
    </FormControl>
  );
};

FcSelectField.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  disabled: PropTypes.bool
};

export const FcToggleButton = ({ formik, label, name }) => {
  return (
    <FormControlLabel
      label={label}
      name={name}
      onChange={formik.handleChange}
      control={<Switch checked={formik.values[name]} />}
    />
  );
};

FcToggleButton.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string
};

export const FcAutocomplete = ({ formik, name, label, options, multiple, getOptionLabel, renderTags, isOptionEqualToValue, onChange, freeSolo, disabled }) => {
  return (
    <>
      <Autocomplete
        disabled={disabled}
        disablePortal
        freeSolo={freeSolo}
        value={formik.values[name]}
        options={options}
        multiple={multiple}
        renderInput={(params) => (
          <TextField {...params} label={label} />
        )}
        getOptionLabel={getOptionLabel}
        onInputChange={freeSolo ? (_, value) => formik.setFieldValue(name, value) : undefined}
        onChange={(_, value) => {
          formik.setFieldValue(name, value);
          if (onChange !== undefined && onChange !== null) {
            onChange(value);
          }
        }}
        renderTags={renderTags}
        isOptionEqualToValue={isOptionEqualToValue}
      />
      {Boolean(formik.touched[name] && formik.errors[name]) && (
        <Typography p sx={errorMessageStyle}>
          {formik.touched[name] && formik.errors[name]}
        </Typography>
      )}
    </>
  );
}

FcAutocomplete.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  renderTags: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  onChange: PropTypes.func,
  freeSolo: PropTypes.bool,
  disabled: PropTypes.bool
}

export const FcPdfUpload = ({ formik, label, name }) => {
  const { data, onPost } = useFilePost('/api/file/upload');

  let fileUrl = null;
  if (formik.values[name]) fileUrl = getPdf(formik.values[name]);

  const VisuallyHiddenInput = styled('input')({
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0
  });

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    await onPost(formData);
  };

  useEffect(() => {
    if (data) formik.setFieldValue(name, data.objectName);
  }, [data]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={fileUrl ? 8 : 12}>
          <Button component="label" variant="contained" fullWidth>
            Upload {label}
            <VisuallyHiddenInput type="file" accept="application/pdf" onChange={handleFileUpload} />
          </Button>
        </Grid>
        {Boolean(formik.touched[name] && formik.errors[name]) && (
          <Typography p sx={errorMessageStyle} ml={1}>
            {formik.touched[name] && formik.errors[name]}
          </Typography>
        )}
        {fileUrl && (
          <Grid item xs={12} md={4}>
            <Button
              component={Link}
              to={fileUrl}
              target="_blank"
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ height: '100%' }}
            >
              View
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

FcPdfUpload.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string
};

export const FcImageUpload = ({ formik, label, name }) => {
  const { data, onPost } = useFilePost('/api/file/upload');

  let fileUrl = null;
  if (formik.values[name]) fileUrl = getImage(formik.values[name]);

  const VisuallyHiddenInput = styled('input')({
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0
  });

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log(file);

    const formData = new FormData();
    formData.append('file', file);
    await onPost(formData);
  };

  useEffect(() => {
    if (data) formik.setFieldValue(name, data.objectName);
  }, [data]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={fileUrl ? 8 : 12}>
          <Button component="label" variant="contained" fullWidth>
            Upload {label}
            <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
          </Button>
        </Grid>
        {fileUrl && (
          <Grid item xs={12} md={4}>
            <Button
              component={Link}
              to={fileUrl}
              target="_blank"
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ height: '100%' }}
            >
              View
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

FcImageUpload.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string
};

export const FcFileDropzone = ({ formik, label, name }) => {
  const { data, onPost } = useFilePost('/api/file/upload');

  let fileUrl = null;
  if (formik.values[name]) fileUrl = getFile(formik.values[name]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);
    await onPost(formData);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ multiple: false, onDrop });

  const style = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center'
  };

  useEffect(() => {
    if (data) formik.setFieldValue(name, data.objectName);
  }, [data]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <Box {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </Box>
      {formik.values[name] && (
        <Box mt={3}>
          <Link to={fileUrl} target="_blank">
            {formik.values[name]?.split('/').slice(-1)}
          </Link>
        </Box>
      )}
    </Box>
  );
};

FcFileDropzone.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string
};

export const SubmitBtnGroup = ({ method, onCancel, formik, color = 'primary', cancelText = 'Cancel' }) => {
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      <Button variant="outlined" onClick={onCancel}>
        {cancelText}
      </Button>
      <LoadingButton type="submit" variant="contained" color={color} loading={formik.isSubmitting}>
        {method}
      </LoadingButton>
    </Stack>
  );
};

SubmitBtnGroup.propTypes = {
  method: PropTypes.string,
  formik: PropTypes.object,
  onCancel: PropTypes.func,
  color: PropTypes.string
};