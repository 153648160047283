import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Stack, Typography } from '@mui/material';
import {cloudFunctionPath} from "../../../cloudFunctionApiConfig";
import useCloudFuncGet from '../../../hooks/firebase/cloudFunction/useCloudFuncGet';
import WorkshopTaskForm from '../../../components/Form/Workshop/WorkshopTaskForm';
import BackButton from '../../../components/BackButton';

export default function EditWorkshopTask() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useCloudFuncGet(`${cloudFunctionPath}/workshop/task/${id}`);

  return (
    <Container maxWidth="xl">
      <BackButton />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>{`Updating Task ${data.data?.name}`}</Typography>
      </Stack>

      <WorkshopTaskForm data={data.data ?? []} onCancel={() => navigate("../")} />
    </Container>
  );
}