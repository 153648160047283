import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import DataImportForm from "./DataImportForm";

const ImportRefundVoucherForm = ({ userMap }) => {
  const dataCtx = useContext(DataContext);

  const [rows, setRows] = useState([]);
  const { onPost, loading } = useHttpPost("/api/refund-voucher/import");

  const columns = [
    { field: "refundNo", headerName: "Refund No", width: 150 },
    {
      field: "userId", headerName: "User", width: 150,
      renderCell: ({ row }) => userMap[row.userId]?.name,
    },
    { field: "amount", headerName: "Amount", width: 150 },
    { field: "refundDate", headerName: "Refund Date", width: 250 },
    { field: "customerName", headerName: "Customer Name", width: 150 },
    { field: "customerAddress", headerName: "Customer Address", width: 250 },
    { field: "customerPostal", headerName: "Customer Postal", width: 150 },
    { field: "bookingNo", headerName: "Booking No", width: 150 },
    { field: "paymentMethod", headerName: "Payment Method", width: 150 },
    { field: "remarks", headerName: "Remarks", width: 250 },
    { field: 'createdAt', headerName: 'Created At', width: 250 },
    { field: 'updatedAt', headerName: 'Updated At', width: 250 },
    { field: "customerSignature", headerName: "Customer Signature", width: 150 },
    { field: "salesSignature", headerName: "Sales Signature", width: 150 },
    { field: 'isDeposit', headerName: 'Is Deposit', width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "companyName", headerName: "Company Name", width: 150 },
    { field: "companyAddress", headerName: "Company Address", width: 150 },
    { field: "companyPostal", headerName: "Company Postal", width: 150 },
    { field: "companyUen", headerName: "Company UEN", width: 150 },
    { field: "reason", headerName: "Reason", width: 250 },
    { field: "title", headerName: "Title", width: 250 },
  ];

  const convertJson = (rows) => rows.map(row => ({
    ...row,
    refundDate: row?.refundDate ? dayjs(row.refundDate).toISOString() : null,
    isDeposit: row.isDeposit === "TRUE",
    createdAt: dayjs(row.createdAt).toISOString(),
    updatedAt: dayjs().toISOString(),
  }));

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const data = convertJson(rows);
      await onPost(data);
      dataCtx.setSnackbarConfig({ open: true, message: "data imported successfully!", severity: 'success' });
    } catch (error) {
      dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: 'error' });
    }
  }

  return (
    <DataImportForm
      title="Refund Vouchers" columns={columns}
      rows={rows} setRows={setRows}
      onSubmit={onSubmit} loading={loading}
    />
  );
}

ImportRefundVoucherForm.propTypes = {
  userMap: PropTypes.object,
}

export default ImportRefundVoucherForm;