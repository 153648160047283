import {Grid, Typography} from "@mui/material";
import {getAuth} from "firebase/auth";
import {Form, FormikProvider, useFormik} from "formik";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, {useContext} from "react";
import * as Yup from "yup";
import useHttpPost from "../../../hooks/http/useHttpPost";
import DataContext from "../../../store/DataContext";
import SignatureBoard from "../../SignatureBoard";
import {FcDateField, FcFileDropzone, FcSelectField, FcTextArea, FcTextField, SubmitBtnGroup} from "../FormFields";

export default function BookingCollectionForm({ booking, onReload, onClose }){
  const paymentMethods = ["Cash", "PayNow", "Bank Transfer", "NETS", "Internal Transfer", "Others"].map(p => ({
    label: p, value: p,
  }));

  const dataCtx = useContext(DataContext);

  const { onPost } = useHttpPost("/api/collection");

  const formik = useFormik({
    initialValues: {
      bookingNo: booking?.bookingNo ?? "",
      bookingId: booking?.id ?? "",
      userId: getAuth().currentUser.uid,
      isFinalized: false,
      amount: 0,
      currencyCode: "SGD",
      paymentMethod: "PayNow",
      collectDate: moment(),
      customerName: booking?.company?.name ?? booking?.customer?.name ?? "",
      customerAddress: booking?.company?.address ?? booking?.customer?.address ?? "",
      customerPostal: booking?.company?.postal ?? booking?.customer?.postal ?? "",
      customerId: booking?.companyId ?? booking?.customerId,
      vehicleCompanyName: booking?.vehicle?.logcard?.vehicleOwner?.name ?? "",
      vehicleCompanyAddress: booking?.vehicle?.logcard?.vehicleOwner?.address ?? "",
      vehicleCompanyPostal: booking?.vehicle?.logcard?.vehicleOwner?.postal ?? "",
      vehicleCompanyUen: booking?.vehicle?.logcard?.vehicleOwner?.uenNo ?? "",
      vehicleOwnerId: booking?.vehicle?.logcard?.vehicleOwnerId ?? "",
      invoiceItemCollections: [],
      customerSignature: null,
      salesSignature: null,
      evidenceAttachment: null,
      remarks: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number().required("Amount is required!"),
      currencyCode: Yup.string().required("Currency code is required!"),
      paymentMethod: Yup.string().required("Payment Method is required!"),
      collectDate: Yup.date().required("Collect Date is required!"),
      customerSignature: Yup.string().nullable(),
      salesSignature: Yup.string().nullable(),
      evidenceAttachment: Yup.string().nullable(),
      remarks: Yup.string().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await onPost({ ...values, collectDate: values.collectDate.toISOString() });
        await onReload();
        dataCtx.setSnackbarConfig({ open: true, message: "Collection created successfully!", severity: "success" });
        onClose();
      } catch (error) {
        dataCtx.setSnackbarConfig({ open: true, message: error.message, severity: "error" });
      }
      setSubmitting(false);
    }
  });

  const handleUploadCanvas = (field, objectName) => formik.setFieldValue(field, objectName);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FcTextField formik={formik} name="amount" label="Collect Amount" type="number"/>
          </Grid>
          <Grid item xs={12}>
            <FcDateField formik={formik} name="collectDate" label="Collect Date"/>
          </Grid>
          <Grid item xs={12}>
            <FcSelectField formik={formik} name="paymentMethod" label="Payment Method" items={paymentMethods}/>
          </Grid>
          <Grid item xs={12}>
            <FcFileDropzone formik={formik} name="evidenceAttachment" label="Evidence Attachment (Optional)"/>
          </Grid>
          {formik.values.paymentMethod === "Cash" && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Customer Signature</Typography>
                <SignatureBoard field="customerSignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.salesSignature}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Sales Signature</Typography>
                <SignatureBoard field="salesSignature" handleUploadCanvas={handleUploadCanvas} objectName={formik.values.salesSignature}/>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FcTextArea formik={formik} name="remarks" label="Remarks"/>
          </Grid>
          <Grid item xs={12}>
            <SubmitBtnGroup formik={formik} method="Create" onCancel={onClose}/>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}

BookingCollectionForm.propTypes = {
  booking: PropTypes.object,
  onReload: PropTypes.func,
  onClose: PropTypes.func,
}