import {DialogContent} from "@material-ui/core";
import {Button, Dialog, DialogActions, DialogTitle, Stack} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";
import {StyledDataGrid} from "../../../pages/Vehicle/styles";
import {getDayjsValue} from "../../../utils/date";

const ViewJson = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack direction="row" spacing={1}>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle>View JSON</DialogTitle>
        <DialogContent>
          <pre>
            {JSON.stringify(JSON.parse(row.additionalInfo), null, 2)}
          </pre>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" onClick={() => setOpen(true)}>View JSON</Button>
    </Stack>
  );
}

ViewJson.propTypes = {
  row: PropTypes.object,
}

export default function UserLogTable({ data }) {
  const columns = [
    { field: "action", headerName: "Action", align: "center", headerAlign: "center", width: 150 },
    { field: "entity", headerName: "Entity", align: "center", headerAlign: "center", width: 250 },
    { field: "ip", headerName: "IP", align: "center", headerAlign: "center", width: 150 },
    { field: "username", headerName: "User", align: "center", headerAlign: "center", width: 150 },
    {
      field: "createdAt", headerName: "Created At", align: "center", headerAlign: "center", width: 250,
      renderCell: ({ row }) => getDayjsValue(row.createdAt).format("DD MMM YYYY hh:mm:ss A"),
    },
    {
      field: "additionalInfo", headerName: "Additional Info", align: "center", headerAlign: "center", width: 150,
      renderCell: ({ row }) => <ViewJson row={row} />,
    },
  ];

  return (
    <StyledDataGrid columns={columns} rows={data ?? []} autoHeight disableSelectionOnClick />
  );
}

UserLogTable.propTypes = {
  data: PropTypes.array,
}